import { confirm } from 'lib'
import { BillingAccount } from 'src/types'

type Params = {
  billingAccount: BillingAccount
}

export const confirmSelectBillingAccount = ({ billingAccount }: Params) =>
  confirm({
    message: `Are you sure you want to use billing account "${billingAccount.name}" for your game?`,
    confirmText: 'Yes'
  })
