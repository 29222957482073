import { h } from 'preact'
import { InlineBlock } from 'jsxstyle/preact'
import { H2, List, ListItem, OpenInNewIcon, SpacerVertical } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import { Session } from '../../types'
import { useStore } from '../../store'
import { useEffect } from 'preact/hooks'
import { get } from '../../api'
import { setError } from '../../actions'
import { useSignal } from '@preact/signals'
import { formatSessionStatus } from '../../formatSessionStatus'
import { formatScheduled } from 'lib'

type Props = {
  voiceId: string
}

export const Sessions = ({ voiceId }: Props) => {
  const { currentGame } = useStore('currentGame')
  const sessions = useSignal<Session[]>([])

  useEffect(() => {
    const now = new Date()
    now.setUTCHours(now.getUTCHours() - 1)
    get(`/games/${currentGame!.id}/sessions`, {
      scheduledAfter: now,
      orderBy: 'scheduled',
      sortOrder: 'asc',
      voiceId
    })
      .then(({ data }) => {
        sessions.value = data
      })
      .catch(setError)
  }, [])

  if (sessions.value.length === 0) {
    return null
  }

  return (
    <InlineBlock background="var(--container-background)" paddingBottom="20px" width="100%">
      <H2 size="6" paddingTop="20px" paddingLeft="20px">
        Upcoming sessions
      </H2>
      <SpacerVertical />
      <List>
        {sessions.value.map((session) => {
          return (
            <ListItem
              text={`#${session.num}: ${formatScheduled(session.scheduled)}`}
              secondaryText={formatSessionStatus(session)}
              link={{
                href: `/session/${session.id}`,
                onRoute: routeTo
              }}
              actionIcon={session.meetingLink && OpenInNewIcon}
              onActionClick={session.meetingLink && (() => window.open(session.meetingLink))}
            />
          )
        })}
      </List>
    </InlineBlock>
  )
}
