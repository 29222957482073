import { h } from 'preact'
import { v4 as uuid } from 'uuid'
import { useEffect, useMemo, useRef, useState } from 'preact/hooks'
import {
  Button,
  DeleteIcon,
  Dialog,
  IconButton,
  PlayIcon,
  RadioButton,
  RefreshIcon,
  SelectChip,
  SpacerHorizontal,
  SpacerVertical,
  StopIcon,
  Switch,
  TextArea,
  UploadIcon,
  VoiceIcon
} from '@sodra/bongo-ui'

import { useAIVoice } from '../../use-ai-voice'
import { createTake, setError, showSnackbar, updateGame, updateLine } from '../../actions'

import SelectAIVoice from '../SelectAIVoice'
import { AIAvatar } from '../AIAvatar'
import { Block, Col, Row } from 'jsxstyle/preact'
import { confirm, pluralize, useAudioPlayer } from 'lib'
import { get, patch, post } from '../../api'
import { GameAiQuota } from '../GameDashboard'
import Spinner from '../Spinner'
import { AIVoice, AiQuota } from '../../types'
import { checkBuyAiTimePreconditions } from '../check-buy-ai-time-preconditions'
import { BuyExtraAiQuotaTimeDialog } from '../BuyExtraAiQuotaTimeDialog'
import { fetchBillingAccount, fetchBillingAccounts } from '../../actions/billing-accounts'
import { getGameAiQuotaRemainingSeconds, isGameAiQuotaEmpty } from '../is-game-ai-quota-empty'
import { useStore } from '../../store'
import { routeTo } from '@sodra/prutt'
import SelectBillingAccount from '../SelectBillingAccount'
import { confirmSelectBillingAccount } from '../confirmSelectBillingAccount'
import { formatSeconds } from 'src/format-seconds'
import { secondsFromCharacters } from 'src/seconds-from-characters'
import { RecordButton } from 'line-recorder/src/components/LineRecorder/RecordButton'
import { AudioFileSelect } from '../AudioFileSelect'

const uploadRecording = async (audio: Blob) => {
  const {
    data: { signedUrl, uri }
  } = await post('/sign-url', {
    prefix: 'sps',
    contentType: 'audio/wav',
    ext: 'wav'
  })

  await fetch(signedUrl, {
    method: 'PUT',
    body: audio,
    headers: {
      'Content-Type': 'audio/wav'
    }
  })

  return uri
}

type AudioHistoryEntry = {
  id: string
  aiVoice: AIVoice
  text: string
  emotion?: string
  audioUri: string
  num: number
}

type Props = {
  onClose: () => void
  onSuccess: () => void
  lineId: string
  initialAIVoiceId?: string
  translations: {
    language: string
    line: string
    aiLine?: string
    aiLineTemplate?: string
  }[]
}

export const GenerateAITake = ({
  onClose,
  lineId,
  initialAIVoiceId,
  translations,
  onSuccess
}: Props) => {
  const { line, isUpdatingLine, currentGame, currentLanguage, billingAccount, billingAccounts } =
    useStore(
      'isUpdatingLine',
      'currentGame',
      'currentUser',
      'currentLanguage',
      'billingAccount',
      'billingAccounts',
      'line'
    )
  const [buyExtraTimeAiQuota, setBuyExtraTimeAiQuota] = useState<AiQuota | undefined>(undefined)
  const [selectAIVoiceVisible, setSelectAIVoiceVisible] = useState(false)
  const { aiVoiceId, aiVoice, setAIVoice, clearAIVoice } = useAIVoice(initialAIVoiceId)
  const [selectedEmotion, setSelectedEmotion] = useState<string>()
  const [gameAiQuota, setGameAiQuota] = useState<GameAiQuota | undefined>()
  const [hasRemainingAiQuota, setHasRemainingAiQuota] = useState<boolean | undefined>(undefined)
  const [hasFetchedAiQuota, setHasFetchedAiQuota] = useState(false)

  const [initialLine, setInitialLine] = useState<string | undefined>(undefined)
  const [currentLine, setCurrentLine] = useState<string | undefined>(undefined)
  const [editedLine, setEditedLine] = useState<string | undefined>(undefined)
  const [isUsingAiLine, setIsUsingAiLine] = useState(false)

  const [isFetching, setIsFetching] = useState(false)
  const [audioUri, setAudioUri] = useState<string | undefined>()
  const [audioHistory, setAudioHistory] = useState<AudioHistoryEntry[]>([])
  const [historyEntryToUseAsSelectedTake, setHistoryEntryToUseAsSelectedTake] = useState<
    AudioHistoryEntry | undefined
  >(undefined)
  const [showSelectBillingAccountDialog, setShowSelectBillingAccountDialog] = useState(false)

  const [autoGenerateEnabled, setAutoGenerateEnabled] = useState(true)
  const [autoplayEnabled, setAutoplayEnabled] = useState(false)
  const [canGenerate, setCanGenerate] = useState(false)
  const [hoverHistoryEntry, setHoverHistoryEntry] = useState<AudioHistoryEntry | undefined>(
    undefined
  )

  const [isRecording, setIsRecording] = useState(false)
  const [stsInProgress, setStsInProgress] = useState(false)

  const [showSelectAudioFile, setShowSelectAudioFile] = useState(false)

  const { player } = useAudioPlayer()

  const fetchGameAiQuota = async () => {
    if (currentGame) {
      get(`/games/${currentGame.id}/ai-quota`).then(({ data: aiQuota }) => {
        setGameAiQuota(aiQuota)
      })
    }
  }

  useEffect(() => {
    fetchGameAiQuota()
    if (currentGame?.billingAccount) {
      fetchBillingAccount(currentGame.billingAccount.id)
    }
    fetchBillingAccounts()

    const translation = translations.find((t) => t.language === currentLanguage)
    if (translation) {
      if (translation.aiLine) {
        setIsUsingAiLine(true)
        setInitialLine(translation.aiLine)
        setCurrentLine(translation.aiLine)
        setEditedLine(translation.aiLine)
      } else {
        setIsUsingAiLine(false)
        setInitialLine(translation.line)
        setCurrentLine(translation.line)
        setEditedLine(translation.line)
      }
    }
  }, [])

  useEffect(() => {
    setCanGenerate(!!(currentLine?.trim().length && aiVoice))
  }, [currentLine, aiVoice])

  useEffect(() => {
    if (!gameAiQuota) {
      return
    }

    const hasRemainingQuota = !isGameAiQuotaEmpty(gameAiQuota)

    setHasRemainingAiQuota(hasRemainingQuota)
  }, [gameAiQuota])

  // This effect is needed to be able to trigger automatic AI generation after the first time the AI quota has been fetched
  useEffect(() => {
    if (hasRemainingAiQuota !== undefined) {
      setHasFetchedAiQuota(true)
    }
  }, [hasRemainingAiQuota])

  useEffect(() => {
    if (autoGenerateEnabled && aiVoice && hasFetchedAiQuota && currentLine?.trim()) {
      setAutoGenerateEnabled(false)
      setAudioUri(undefined)
      fetchAudio()
    }
  }, [autoGenerateEnabled, aiVoice, selectedEmotion, hasFetchedAiQuota, currentLine])

  if (!player) {
    return null
  }

  const { playerState, play, initPlayer, stopPlayer } = player

  const toggleEmotion = (emotion: string) => {
    setSelectedEmotion((selectedEmotion) => (selectedEmotion === emotion ? undefined : emotion))
    setAutoGenerateEnabled(true)
    setAutoplayEnabled(true)
  }

  const fetchAudio = async (): Promise<string | undefined> => {
    if (hasRemainingAiQuota === undefined) {
      return
    }
    if (hasRemainingAiQuota === false) {
      if (
        await confirm({
          title: 'Your AI voices quota is empty',
          message: 'Would you like to buy some more hours to keep the magic going?',
          confirmText: 'Buy more hours'
        })
      ) {
        await buyExtraAiTimeButtonClicked()
        onClose()
      } else {
        onClose()
      }
      return
    }
    if (!aiVoice) {
      setError('Please select a voice first')
      return
    }
    if (!currentLine || currentLine?.trim() === '') {
      setError('Please enter line first')
      return
    }

    stopPlayer()

    try {
      setIsFetching(true)

      const { data: uri } = await post('/ai-voices/tts', {
        gameId: currentGame!.id,
        aiVoiceId: aiVoice.id,
        text: currentLine,
        extraParams: {
          style: selectedEmotion,
          seed: Date.now()
        }
      })

      fetchGameAiQuota()

      const newHistoryEntry: AudioHistoryEntry = {
        id: uuid(),
        aiVoice,
        text: currentLine,
        emotion: aiVoice.emotions && selectedEmotion ? selectedEmotion : undefined,
        audioUri: uri,
        num: audioHistory.length > 0 ? audioHistory[0].num + 1 : 1
      }
      setAudioHistory((history) => {
        return [newHistoryEntry, ...history]
      })
      selectHistoryEntry(newHistoryEntry)

      setAudioUri(uri)
      if (autoplayEnabled) {
        playAudio({ overrideAudioUri: uri })
        setAutoplayEnabled(false)
      }

      return uri
    } catch (err: any) {
      if (err.message.includes('AI quota')) {
        if (
          await confirm({
            title: 'Not enough AI voices quota',
            message: (
              <>
                <Block>
                  You don't have enough AI voices quota left to generate an AI take for this line.
                </Block>
                <SpacerVertical />
                <Block>
                  Please buy more hours to keep the magic going, or contact our sales team at
                  support@speechless.games if you have any questions.
                </Block>
                <SpacerVertical />
              </>
            ),
            confirmText: 'Buy more hours'
          })
        ) {
          await buyExtraAiTimeButtonClicked()
          onClose()
        } else {
          onClose()
        }
      } else {
        setError(err)
      }
    } finally {
      setIsFetching(false)
    }
  }

  type PlayAudioParams = { overrideAudioUri: string }
  const playAudio = (params?: PlayAudioParams) => {
    if (params?.overrideAudioUri || audioUri) {
      initPlayer({
        audioTracks: [{ uri: params?.overrideAudioUri ? params.overrideAudioUri : audioUri! }]
      })
      play({ audioTrackIndex: 0 })
    }
  }

  const handlePlay = async () => {
    if (audioHistory.length === 0) {
      setAutoplayEnabled(true)
      fetchAudio()
    } else {
      playAudio()
    }
  }

  const handleStop = () => {
    stopPlayer()
  }

  const handleSaveAllTakes = async () => {
    stopPlayer()

    for (const entry of audioHistory) {
      await createTake({
        language: currentLanguage,
        uri: entry.audioUri,
        aiVoiceId: entry.aiVoice.id,
        lineText: entry.text,
        isAiLineText: isUsingAiLine,
        makeDefault: historyEntryToUseAsSelectedTake?.audioUri === entry.audioUri
      })
    }

    showSnackbar(`${audioHistory.length} ${pluralize('take', audioHistory.length)} saved`)
    onSuccess()
    handleClose()
  }

  const handleSaveTake = async () => {
    stopPlayer()
    if (audioUri) {
      if (
        await createTake({
          language: currentLanguage,
          uri: audioUri,
          aiVoiceId,
          lineText: currentLine,
          isAiLineText: isUsingAiLine,
          makeDefault: historyEntryToUseAsSelectedTake?.audioUri === audioUri
        })
      ) {
        showSnackbar('Take saved')

        // If the character doesn't already have an AI voice, assign the selected one
        if (line?.character && !line.character.aiVoices[currentLanguage] && aiVoice) {
          // console.log(
          //   `Updating ${currentLanguage} AI voice for character ${line.character.name} to:`,
          //   aiVoice.name
          // )
          await patch(`/characters/${line.character.id}`, {
            aiVoices: [{ language: currentLanguage, aiVoiceId: aiVoice.id }]
          })
        }

        onSuccess()

        handleClose()
      }
    }
  }

  const handleSayItDifferently = () => {
    setAudioUri(undefined)
    fetchAudio().then((uri) => {
      if (uri) {
        playAudio({ overrideAudioUri: uri })
      }
    })
  }

  const selectHistoryEntry = (entry: AudioHistoryEntry) => {
    if (audioUri !== entry.audioUri) {
      setAudioUri(entry.audioUri)
      setAIVoice(entry.aiVoice)
      setCurrentLine(entry.text)
      setEditedLine(entry.text)
      setSelectedEmotion(entry.emotion)
    }
  }

  const deleteHistoryEntry = (entry: AudioHistoryEntry) => {
    const isSelectedEntry = entry.audioUri === audioUri
    if (isSelectedEntry) {
      const newSelectedEntry = audioHistory.find((entry2) => entry2.audioUri !== entry.audioUri)
      if (newSelectedEntry) {
        selectHistoryEntry(newSelectedEntry)
      } else {
        setAudioUri(undefined)
      }
    }

    if (historyEntryToUseAsSelectedTake?.audioUri === entry.audioUri) {
      setHistoryEntryToUseAsSelectedTake(undefined)
    }

    setAudioHistory((audioHistory) =>
      audioHistory.filter((entry2) => entry2.audioUri !== entry.audioUri)
    )
  }

  const clearAudioHistory = () => {
    const selectedEntry = audioHistory.find((entry) => entry.audioUri === audioUri)
    if (selectedEntry) {
      setAudioHistory([selectedEntry])
    } else {
      setAudioHistory([])
    }
  }

  const handleCancel = async () => {
    if (audioHistory.length > 0) {
      if (
        await confirm({
          title: 'Close without saving?',
          message: `Are you sure you want to close the dialog without saving? The generated AI ${pluralize(
            'take',
            audioHistory.length
          )} will be lost.`,
          confirmText: 'Yes',
          rejectText: 'Cancel'
        })
      ) {
        onClose()
      }
    } else {
      onClose()
    }
  }

  const handleClose = async () => {
    stopPlayer()

    let hasError = false
    if (currentLine !== initialLine) {
      if (
        await confirm({
          title: isUsingAiLine ? 'AI TTS prompt has been modified' : 'Line has been modified',
          message: 'Do you want to keep the changes?',
          confirmText: 'Yes',
          rejectText: 'No'
        })
      ) {
        if (
          !(await updateLine(lineId, {
            translations: translations.map((t) => {
              if (t.language !== currentLanguage) {
                return t
              }

              if (isUsingAiLine) {
                return { ...t, aiLine: currentLine, aiLineTemplate: t.line }
              }

              return { ...t, line: currentLine }
            })
          }))
        ) {
          hasError = true
        }
      }
    }
    if (!hasError) {
      onClose()
    }
  }

  const createBillingAccount = () => {
    if (!currentGame) {
      return
    }
    const params = new URLSearchParams({
      onSuccessUrl: `/line/${lineId}/generate-ai-take?game=${currentGame.shortId}`
    })
    routeTo(`/settings/billing/add-billing-account?${params.toString()}`)
  }

  const addCreditCard = () => {
    if (!currentGame?.billingAccount) {
      return
    }
    const params = new URLSearchParams({
      onSuccessUrl: `/line/${lineId}/generate-ai-take?game=${currentGame.shortId}`
    })
    routeTo(
      `/settings/billing/${currentGame.billingAccount.id}/add-credit-card?${params.toString()}`
    )
  }

  const buyExtraAiTimeButtonClicked = async () => {
    if (!currentGame) {
      return
    }
    await checkBuyAiTimePreconditions({
      game: currentGame,
      onCreditCardRequired: addCreditCard,
      onBillingAccountRequired: () => {
        if (billingAccounts?.length) {
          setShowSelectBillingAccountDialog(true)
        } else {
          createBillingAccount()
        }
      },
      onSuccess: () => {
        // Open a dialog to buy extra time
        if (gameAiQuota?.billingAccount?.basic?.[0]) {
          setBuyExtraTimeAiQuota(gameAiQuota.billingAccount.basic[0])
        }
      }
    })
  }

  const expectedSeconds = secondsFromCharacters(editedLine?.length || currentLine?.length)
  const remainingSeconds = getGameAiQuotaRemainingSeconds(gameAiQuota)

  const isPlayingSelectedAudio =
    playerState.value.isPlaying && playerState.value.currentTrackUri === audioUri

  const audioRecorder = useRef<MediaRecorder>()
  const audioChunks = useRef<Blob[]>([])

  const generateSpeechToSpeechTake = async (blob: Blob) => {
    const MAX_STS_BLOB_SIZE_MB = 50

    const sizeMb = Number((blob.size / 1024 / 1024).toFixed(2))
    // console.log({ size: blob.size, sizeMb, maxSize: MAX_STS_BLOB_SIZE_MB })

    if (sizeMb > MAX_STS_BLOB_SIZE_MB) {
      throw new Error(
        `Maximum file size for speech to speech is 50 MB. Current file size is ${sizeMb} MB.`
      )
    }

    const srcUri = await uploadRecording(blob)
    const { data: uri } = await post('/ai-voices/speech-to-speech', {
      uri: srcUri,
      aiVoiceId: aiVoice?.id,
      gameId: currentGame?.id
    })

    fetchGameAiQuota()

    const newHistoryEntry: AudioHistoryEntry = {
      id: uuid(),
      aiVoice: aiVoice!,
      text: currentLine!,
      audioUri: uri,
      num: audioHistory.length > 0 ? audioHistory[0].num + 1 : 1
    }
    setAudioHistory((history) => {
      return [newHistoryEntry, ...history]
    })
    selectHistoryEntry(newHistoryEntry)
    setAudioUri(uri)
    playAudio({ overrideAudioUri: uri })
  }

  const handleUploadSpeechToSpeechFile = async (file: File) => {
    setStsInProgress(true)
    try {
      const blob = new Blob([file], { type: file.type })
      await generateSpeechToSpeechTake(blob)
    } catch (err: any) {
      setError(err)
    } finally {
      setStsInProgress(false)
    }
  }

  const handleRecordButtonClick = () => {
    if (!isRecording) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        audioRecorder.current = new MediaRecorder(stream)
        audioRecorder.current.addEventListener('dataavailable', (e) => {
          audioChunks.current.push(e.data)
        })
        audioRecorder.current.addEventListener('stop', async (e) => {
          setIsRecording(false)
          setStsInProgress(true)
          try {
            const blob = new Blob(audioChunks.current, { type: 'audio/wav' })
            await generateSpeechToSpeechTake(blob)
          } catch (err: any) {
            setError(err)
          } finally {
            setStsInProgress(false)
          }
        })
        audioChunks.current = []
        audioRecorder.current.start(1000)
        setIsRecording(true)
      })
    } else if (audioRecorder.current) {
      audioRecorder.current.stop()
    }
  }

  return (
    <Dialog
      medium
      autoWidth
      dismissable={false}
      title="Generate AI take"
      disableAutofocus
      actions={[
        {
          text: 'Save active take',
          contained: true,
          disabled: !aiVoice || !audioUri,
          loading: isUpdatingLine,
          onClick: handleSaveTake
        },
        {
          text: `Save all ${audioHistory.length > 1 ? audioHistory.length + ' ' : ''}takes`,
          contained: true,
          disabled: !aiVoice || !audioUri || audioHistory.length < 2,
          loading: isUpdatingLine,
          onClick: handleSaveAllTakes
        },
        {
          text: 'Cancel',
          onClick: handleCancel
        }
      ]}
    >
      {gameAiQuota === undefined && <Spinner />}
      {gameAiQuota && (
        <>
          <Row gap="20px">
            <Block width="400px">
              {aiVoice && (
                <SelectChip
                  visual={<AIAvatar size={30} src={aiVoice.picture} name={aiVoice.name} />}
                  value={aiVoice.name}
                  onClick={() => {
                    setSelectAIVoiceVisible(true)
                  }}
                  onClear={() => {
                    stopPlayer()
                    clearAIVoice()
                  }}
                />
              )}
              {!aiVoice && (
                <SelectChip label="Select AI voice" onClick={() => setSelectAIVoiceVisible(true)} />
              )}
              {selectAIVoiceVisible && (
                <SelectAIVoice
                  onClose={() => setSelectAIVoiceVisible(false)}
                  onSelect={(voice) => {
                    setAIVoice(voice)
                    setAutoGenerateEnabled(true)
                    setAutoplayEnabled(true)
                  }}
                />
              )}
              {aiVoice && aiVoice.emotions && (
                <Row alignItems="center" gap="5px" flexWrap="wrap" margin="5px 0">
                  {aiVoice.emotions.map((emotion) => {
                    const isSelected = selectedEmotion === emotion
                    return (
                      <Block
                        background={
                          isSelected ? 'var(--accent-surface)' : 'var(--container-background)'
                        }
                        color={isSelected ? 'var(--on-accent)' : 'var(--on-surface-light)'}
                        padding="3px 9px"
                        borderRadius="12px"
                        fontSize="12px"
                        textTransform="capitalize"
                        cursor="pointer"
                        props={{ onClick: () => toggleEmotion(emotion) }}
                      >
                        {emotion}
                      </Block>
                    )
                  })}
                </Row>
              )}
              <SpacerVertical />
              {isUsingAiLine && (
                <TextArea label="AI TTS prompt" value={editedLine} onChange={setEditedLine} />
              )}
              {!isUsingAiLine && (
                <TextArea label="Line" value={editedLine} onChange={setEditedLine} />
              )}
              {editedLine !== currentLine && (
                <>
                  <SpacerVertical small />
                  <Row alignItems="center" gap="10px">
                    <Button
                      small
                      contained
                      onClick={() => {
                        setCurrentLine(editedLine)
                        setAutoGenerateEnabled(true)
                        setAutoplayEnabled(true)
                      }}
                      loading={isFetching}
                    >
                      Apply
                    </Button>
                    <Button small onClick={() => setEditedLine(currentLine)}>
                      Cancel
                    </Button>
                  </Row>
                </>
              )}
              <Block
                textAlign="left"
                fontSize="14px"
                color="var(--on-surface-light)"
                margin="5px 0"
              >
                Approximately {expectedSeconds} second{expectedSeconds !== 1 ? 's' : ''} of
                remaining {formatSeconds(remainingSeconds)}
              </Block>
              {aiVoice && (
                <>
                  <SpacerVertical />
                  <Row alignItems="center" gap="10px">
                    <Button
                      outlined
                      width="100px"
                      icon={isPlayingSelectedAudio ? StopIcon : PlayIcon}
                      disabled={!canGenerate || stsInProgress}
                      loading={isFetching}
                      onClick={isPlayingSelectedAudio ? handleStop : handlePlay}
                    >
                      {isPlayingSelectedAudio ? 'Stop' : 'Play'}
                    </Button>
                    {aiVoice.type === 'elevenlabs' && (
                      <>
                        <Button
                          icon={RefreshIcon}
                          disabled={!audioUri || isFetching || stsInProgress}
                          onClick={handleSayItDifferently}
                        >
                          Say it differently
                        </Button>
                      </>
                    )}
                  </Row>
                  {aiVoice.type === 'elevenlabs' && (
                    <>
                      <SpacerVertical />
                      <Block border="1px solid var(--container-outline-lighter)" padding="10px">
                        <Col>
                          <Row gap="10px" alignItems="center">
                            <Block
                              color="var(--on-surface-light)"
                              fontSize="14px"
                              whiteSpace="nowrap"
                            >
                              Speech to speech (max 50 MB)
                            </Block>
                            <Block flex="1" />
                            <IconButton
                              small
                              icon={isRecording ? StopIcon : VoiceIcon}
                              outlined
                              disabled={!audioUri || isFetching}
                              loading={stsInProgress}
                              onClick={handleRecordButtonClick}
                              tooltipText="Record audio"
                            />
                            <SpacerHorizontal tiny />
                            <AudioFileSelect
                              color="var(--accent)"
                              disabled={!audioUri || isFetching}
                              loading={stsInProgress}
                              onFileLoaded={handleUploadSpeechToSpeechFile}
                            />
                            <SpacerHorizontal tiny />
                          </Row>
                        </Col>
                      </Block>
                    </>
                  )}
                  <SpacerVertical />
                  <Row alignItems="center" gap="10px">
                    <Switch
                      on={
                        historyEntryToUseAsSelectedTake &&
                        historyEntryToUseAsSelectedTake.audioUri === audioUri
                      }
                      onChange={(isOn: boolean) => {
                        if (isOn) {
                          setHistoryEntryToUseAsSelectedTake(
                            audioHistory.find((entry) => entry.audioUri === audioUri)
                          )
                        } else {
                          setHistoryEntryToUseAsSelectedTake(undefined)
                        }
                      }}
                    />
                    Use as selected take for the line
                  </Row>
                  <SpacerVertical />
                </>
              )}
            </Block>
            <Block
              borderLeft="1px solid var(--container-outline-lighter)"
              width="550px"
              marginLeft="20px"
              paddingLeft="20px"
            >
              <Row color="var(--on-surface)" alignItems="center">
                Temporary takes
                <SpacerHorizontal />
                {/* {audioHistory.length > 1 && (
                  <Button small icon={DeleteIcon} onClick={clearAudioHistory}>
                    Remove all unused
                  </Button>
                )} */}
              </Row>
              <SpacerVertical />

              <Block maxHeight="400px" overflow="auto">
                {audioHistory.length === 0 && (
                  <Block color="var(--on-surface-light)" fontSize="14px" paddingTop="10px">
                    No audio generated yet...
                  </Block>
                )}

                {audioHistory.length > 0 &&
                  audioHistory.map((entry, i) => {
                    const isPlayingThisEntry =
                      playerState.value.isPlaying &&
                      playerState.value.currentTrackUri === entry.audioUri

                    const isSelectedEntry = audioUri === entry.audioUri
                    const color = isSelectedEntry ? 'var(--accent)' : 'var(--on-surface-light)'

                    const isHovered = hoverHistoryEntry?.id === entry.id

                    return (
                      <>
                        <Row
                          backgroundColor={isHovered ? 'var(--inverted-surface)' : ''}
                          cursor="default"
                          color={color}
                          alignItems="center"
                          gap="0px"
                          paddingLeft="20px"
                          paddingRight="20px"
                          props={{
                            onmouseenter: () => {
                              setHoverHistoryEntry(entry)
                            },
                            onmouseleave: () => {
                              setHoverHistoryEntry(undefined)
                            }
                          }}
                        >
                          <Block width="30px">{entry.num}.</Block>
                          <IconButton
                            icon={isPlayingThisEntry ? StopIcon : PlayIcon}
                            color={color}
                            onClick={() => {
                              if (isPlayingThisEntry) {
                                stopPlayer()
                              } else {
                                playAudio({ overrideAudioUri: entry.audioUri })
                                selectHistoryEntry(entry)
                              }
                            }}
                          />
                          <SpacerHorizontal small />
                          <Block>
                            <AIAvatar
                              size={24}
                              src={entry.aiVoice.picture}
                              name={entry.aiVoice.name}
                            />
                          </Block>
                          <SpacerHorizontal small />
                          <Block flex="1">
                            {entry.aiVoice.name}
                            {entry.emotion ? ` (${entry.emotion})` : ''}
                          </Block>
                          {audioHistory.length > 0 && (
                            <Row alignItems="center">
                              {isSelectedEntry && (
                                <>
                                  <Block color="var(--on-surface-light)" fontSize="14px">
                                    Active
                                  </Block>
                                  <SpacerHorizontal small />
                                </>
                              )}
                              <RadioButton
                                checked={isSelectedEntry}
                                onChange={(isOn: boolean) => {
                                  if (isOn) {
                                    selectHistoryEntry(entry)
                                  }
                                }}
                              />
                            </Row>
                          )}
                          <SpacerHorizontal large />
                          <IconButton
                            icon={DeleteIcon}
                            color="var(--on-surface-light)"
                            onClick={() => deleteHistoryEntry(entry)}
                          />
                          <SpacerHorizontal small />
                        </Row>
                      </>
                    )
                  })}
              </Block>
            </Block>
          </Row>
        </>
      )}
      {buyExtraTimeAiQuota && billingAccount && (
        <>
          <BuyExtraAiQuotaTimeDialog
            aiQuota={buyExtraTimeAiQuota}
            billingAccount={billingAccount}
            onSuccess={(aiQuota: AiQuota, hours: number) => {
              fetchGameAiQuota()
            }}
            onClose={() => {
              setBuyExtraTimeAiQuota(undefined)
            }}
          />
        </>
      )}

      {showSelectBillingAccountDialog && billingAccounts?.length && (
        <SelectBillingAccount
          billingAccounts={billingAccounts}
          onCreateBillingAccount={createBillingAccount}
          onClose={() => setShowSelectBillingAccountDialog(false)}
          onSelect={async (billingAccount) => {
            if (await confirmSelectBillingAccount({ billingAccount })) {
              updateGame({ billingAccountId: billingAccount.id }).then(fetchGameAiQuota)
              return true
            }
            return false
          }}
        />
      )}
    </Dialog>
  )
}

export default GenerateAITake
