import { h } from 'preact'
import {
  Checkbox,
  Dialog,
  Form,
  NativeSelect,
  SpacerVertical,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { useState } from 'preact/hooks'
import { createLineAttribute, showSnackbar } from '../../../actions'
import { LineAttribute } from '../../../types'

export const typeOptions = [
  { label: 'Text', value: 'text' },
  { label: 'Number', value: 'number' },
  { label: 'Checkbox', value: 'boolean' },
  { label: 'Date', value: 'date' },
  { label: 'URL', value: 'url' }
]

export const formatType = (type: string) => {
  const option = typeOptions.find((option) => option.value === type)
  return option?.label || type
}

type Props = {
  backUrl?: string
  onSuccess?: (attribute: LineAttribute) => void
}

export const CreateLineAttribute = ({ backUrl = '/lines/line-attributes', onSuccess }: Props) => {
  const [name, setName] = useState('')
  const [type, setType] = useState('text')
  const [description, setDescription] = useState('')
  const [required, setRequired] = useState(false)

  const [nameError, setNameError] = useState('')

  const onBack = () => goBack(backUrl)

  const handleSubmit = async () => {
    if (name.trim() === '') {
      setNameError('Please enter a name')
      return
    }

    const lineAttribute = await createLineAttribute({ name, type, description, required })

    if (lineAttribute) {
      showSnackbar(`Line attribute ${name} created`)
      if (onSuccess) {
        onSuccess(lineAttribute)
      }
      onBack()
    }
  }

  return (
    <Dialog
      title="Add line attribute"
      onClose={onBack}
      actions={[
        {
          text: 'Add',
          contained: true,
          onClick: handleSubmit
        },
        {
          text: 'Cancel',
          onClick: onBack
        }
      ]}
    >
      <Form onSubmit={handleSubmit}>
        <TextField
          width="100%"
          autoComplete="off"
          label="Name"
          value={name}
          onInput={setName}
          errorText={nameError}
        />
        <SpacerVertical />
        <TextArea
          width="100%"
          autoComplete="off"
          label="Description"
          value={description}
          onInput={setDescription}
          autoHeight
          minRows={3}
          maxRows={10}
        />
        <SpacerVertical />
        <NativeSelect label="Type" value={type} onChange={setType} options={typeOptions} />
        <SpacerVertical />
        <Checkbox label="Required" checked={required} onChange={setRequired} />
      </Form>
    </Dialog>
  )
}
