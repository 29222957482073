import {
  ArrowDownwardIcon,
  ArrowUpwardIcon,
  BackArrowIcon,
  Button,
  IconButton,
  PdfIcon,
  SettingsIcon,
  SpacerHorizontal,
  Switch
} from '@sodra/bongo-ui'
import { h } from 'preact'
import { Block, Col, Row } from 'jsxstyle/preact'
import { useEffect, useState } from 'preact/hooks'
import { AudioDeviceSelector, useRecorder } from '../AudioRecorder'
import type { Recorder as RecorderType } from '../AudioRecorder'
import Avatar from '../Avatar'

import {
  createLineRecorderState,
  Line,
  LineRecorderState,
  TakeUser,
  UpdateLine,
  Take,
  useKeyboardEvents,
  Game,
  Scene
} from './lineRecorderState'

import { LineRecorderSettingsDialog } from './LineRecorderSettingsDialog'
import { LineStats } from './LineStats'
import { LineTakes } from './LineTakes'
import { Recorder } from './Recorder'
import { LinesList } from './LinesList'
import { signal, useSignal, useSignalEffect } from '@preact/signals'
import { blurActiveElement } from './blur-active-element'
import { formatLanguage } from 'lib'
import { LinesContextView } from './LinesContextView'

export type RemoteRecordingStatus =
  | 'recording-requested'
  | 'recording'
  | 'stop-requested'
  | 'not-recording'

export type LineRecorderRemoteControl = {
  onSelectLine: (lineId: string) => void
  onSelectCharacter: (characterId?: string) => void
  onRecStart: (lineId: string) => void
  onRecStop: (lineId: string) => void
  recordingStatus: RemoteRecordingStatus
  onPlayTake: (params: { lineId: string; takeId: string; startTime?: number }) => void
  onStopTake: (params: { lineId: string; takeId: string }) => void
  onPlayReferenceAudio: (params: { lineId: string }) => void
  onStopReferenceAudio: (params: { lineId: string }) => void
  onShowAllTakes: (showAllTakes: boolean) => void
}

export type LineRecorderOnCreateTake = (
  lineId: string,
  take: Required<Pick<Take, 'id' | 'uri' | 'peakWarning' | 'trimStart' | 'trimEnd'>>
) => Promise<Take | undefined>

type LineRecorderComponentProps = {
  sessionId?: string
  title: string
  game: Game
  lines?: Line[]
  scenes?: Scene[]
  onBack?: () => void
  voice?: TakeUser
  language: string
  showAllTakes?: boolean
  onCreateTake?: LineRecorderOnCreateTake
  onDeleteTake?: (takeId: string) => Promise<void>
  onUpdateTake?: (takeId: string, take: Partial<Take>) => Promise<void>
  onUpdateLine?: (lineId: string, line: UpdateLine) => Promise<void>
  uploadAudioBuffer?: (audioBuffer: AudioBuffer) => Promise<string>
  onDownloadScript?: () => Promise<void>
  isDownloadingScript?: boolean
  onError?: (e: Error) => void
  isRequesting?: boolean
  onRemoteControlRequest?: () => void
  onRemoteControlStop?: () => void
  remoteControl?: LineRecorderRemoteControl
  showSampleSize?: boolean
  onRecordingStarted?: ({ lineId }: { lineId: string }) => void
  onRecordingStopped?: ({ lineId }: { lineId: string }) => void
}

export const recorder = signal<RecorderType | undefined>(undefined)
export const lineRecorder = signal<LineRecorderState | undefined>(undefined)

const LineRecorderComponent = ({
  title,
  sessionId,
  onBack,
  onCreateTake,
  onDeleteTake,
  onError,
  onUpdateTake,
  onDownloadScript,
  isDownloadingScript,
  isRequesting,
  onRemoteControlRequest,
  onRemoteControlStop,
  remoteControl,
  showSampleSize = false,
  scenes,
  onRecordingStarted,
  onRecordingStopped
}: LineRecorderComponentProps) => {
  const [showLinesList, setShowLinesList] = useState(true)
  const [showLinesContextView, setShowLinesContextView] = useState(false)
  const [hasReceivedScenes, setHasReceivedScenes] = useState(false)

  const [isRecording, setIsRecording] = useState(false)

  // This state is used to handle when remote recording might be delayed
  const [isWaitingForRecordingToStart, setIsWaitingForRecordingToStart] = useState(false)
  const [waitForRecordingToStartTimer, setWaitForRecordingToStartTimer] = useState<
    NodeJS.Timeout | undefined
  >(undefined)

  const showSettings = useSignal(false)

  useEffect(() => {
    // Make sure to only auto-select view the first time scenes are received
    if (hasReceivedScenes) {
      return
    }

    if (scenes?.length) {
      setHasReceivedScenes(true)
    }

    const showLinesView = !scenes?.length

    setShowLinesList(showLinesView)
    setShowLinesContextView(!showLinesView)
  }, [scenes, hasReceivedScenes])

  useEffect(() => {
    if (isRecording) {
      setIsWaitingForRecordingToStart(false)
      if (waitForRecordingToStartTimer) {
        clearTimeout(waitForRecordingToStartTimer)
        setWaitForRecordingToStartTimer(undefined)
      }
    }
  }, [isRecording, waitForRecordingToStartTimer])

  const handleStopRecording = () => {
    if (!lineRecorder.value) {
      return
    }
    const lineId = lineRecorder.value.activeLineId.value
    if (!lineId) return

    if (remoteControl) {
      remoteControl.onRecStop(lineId)
    } else {
      if (recorder.value) {
        const stopped = recorder.value.stopRecording((audioBuffer) => {
          if (lineRecorder.value) {
            lineRecorder.value.createTake({
              lineId,
              audioBuffer,
              onUpload: onCreateTake
            })
          }
        })

        if (stopped && onRecordingStopped) {
          onRecordingStopped({ lineId })
        }
      }
    }

    setIsWaitingForRecordingToStart(false)
    if (waitForRecordingToStartTimer) {
      clearTimeout(waitForRecordingToStartTimer)
      setWaitForRecordingToStartTimer(undefined)
    }
  }

  const handleStartRecording = () => {
    if (!lineRecorder.value) {
      return
    }
    const lineId = lineRecorder.value.activeLineId.value
    if (!lineId) return

    if (lineRecorder.value.activeTake.value?.audioBufferPlayer?.state === 'playing') {
      lineRecorder.value.stopActiveTake()
    }

    if (remoteControl) {
      remoteControl.onRecStart(lineId)
    } else {
      const started = recorder.value?.startRecording()

      if (started && onRecordingStarted) {
        onRecordingStarted({
          lineId
        })
      }
    }

    const timer = setTimeout(() => {
      setIsWaitingForRecordingToStart(true)
      setWaitForRecordingToStartTimer(undefined)
    }, 1000)
    setWaitForRecordingToStartTimer(timer)
  }

  useEffect(() => {
    if (remoteControl) {
      setIsRecording(remoteControl.recordingStatus === 'recording')
    } else {
      setIsRecording(recorder.value?.state.value.mediaRecorderState === 'recording')
    }
  }, [remoteControl?.recordingStatus, recorder.value?.state.value.mediaRecorderState])

  useKeyboardEvents(
    {
      isRecording,
      onStartRecording: handleStartRecording,
      onStopRecording: handleStopRecording
    },
    lineRecorder.value
  )

  useSignalEffect(() => {
    if (!lineRecorder.value) {
      return
    }

    const characterId = lineRecorder.value.selectedCharacter.value
    if (remoteControl) {
      remoteControl.onSelectCharacter(characterId ?? undefined)
    }
  })

  useSignalEffect(() => {
    if (!lineRecorder.value) {
      return
    }
    const lineId = lineRecorder.value.activeLineId.value
    if (remoteControl && lineId) {
      remoteControl.onSelectLine(lineId)
    }
  })

  useSignalEffect(() => {
    if (!lineRecorder.value) {
      return
    }
    const showAllTakes = lineRecorder.value.showAllTakes.value
    if (remoteControl) {
      remoteControl.onShowAllTakes(showAllTakes)
    }
  })

  if (!lineRecorder.value) {
    return null
  }

  const onPlayTake = async (take: Take, startTime?: number) => {
    if (remoteControl && lineRecorder.value?.remotePlaybackEnabled.value) {
      remoteControl.onPlayTake({ lineId: take.lineId!, takeId: take.id, startTime })
    }
  }
  const onStopTake = async (take: Take) => {
    if (remoteControl && lineRecorder.value?.remotePlaybackEnabled.value) {
      remoteControl.onStopTake({ lineId: take.lineId!, takeId: take.id })
    }
  }
  const onPlayReferenceAudio = async (line: Line) => {
    if (remoteControl && lineRecorder.value?.remotePlaybackEnabled.value) {
      remoteControl.onPlayReferenceAudio({ lineId: line.id })
    }
  }
  const onStopReferenceAudio = async (line: Line) => {
    if (remoteControl && lineRecorder.value?.remotePlaybackEnabled.value) {
      remoteControl.onStopReferenceAudio({ lineId: line.id })
    }
  }

  const activeLine = lineRecorder.value.activeLine.value
  const currentLanguageLine = activeLine?.translations[lineRecorder.value.language.value]?.line
  const primaryLanguage = lineRecorder.value.game.value.primaryLanguage
  const primaryLanguageLine = activeLine?.translations[primaryLanguage]?.line

  const switchView = () => {
    setShowLinesContextView(!showLinesContextView)
    setShowLinesList(!showLinesList)
  }

  return (
    <Col class="bui" position="absolute" inset={0} background="var(--surface)">
      <Row
        padding="20px"
        alignItems="center"
        borderBottom="solid 1px var(--container-outline-lighter)"
      >
        {onBack && (
          <IconButton name="back" icon={BackArrowIcon} onClick={onBack} color="var(--on-surface)" />
        )}
        <Block fontSize={'20px'}>{title}</Block>
        {scenes && scenes.length > 0 && (
          <Block>
            <SpacerHorizontal large />
            <Button onClick={switchView} focusable={false} onFocus={blurActiveElement}>
              {showLinesList ? 'Show lines in scenes' : 'Show lines as list'}
            </Button>
          </Block>
        )}
        <Row flex="1" justifyContent="end" gap="10px">
          <LineStats />

          {sessionId && onRemoteControlRequest && (
            <Button
              onClick={onRemoteControlRequest}
              color="var(--on-surface)"
              loading={isRequesting}
              paddingLeft={10}
              paddingRight={10}
              whiteSpace="nowrap"
            >
              Request remote control
            </Button>
          )}
          {onRemoteControlStop && (
            <Button
              onClick={onRemoteControlStop}
              paddingLeft={10}
              paddingRight={10}
              whiteSpace="nowrap"
              error
            >
              Stop remote control
            </Button>
          )}

          {onDownloadScript && (
            <Button
              icon={PdfIcon}
              loading={isDownloadingScript}
              onClick={onDownloadScript}
              color="var(--on-surface)"
            >
              Download script
            </Button>
          )}

          <Button
            onClick={() => {
              showSettings.value = true
            }}
            icon={SettingsIcon}
            color="var(--on-surface)"
          >
            Settings
          </Button>
        </Row>
      </Row>

      <Row flex="1" overflow="hidden">
        {showLinesList && (
          <Block
            class="bui-show-scroll"
            overflowY="scroll"
            borderRight="solid 1px var(--container-outline-lighter)"
            minWidth="200px"
            maxWidth="500px"
            flex="1"
          >
            <Block marginBottom="80vh">
              <LinesList />
            </Block>
          </Block>
        )}

        {showLinesContextView && scenes && (
          <Block
            class="bui-show-scroll"
            overflowY="scroll"
            borderRight="solid 1px var(--container-outline-lighter)"
            minWidth="300px"
            maxWidth="600px"
            flex="1"
          >
            <Block marginBottom="80vh">
              <LinesContextView
                scenes={scenes}
                allLines={lineRecorder.value?.lines.value ?? []}
                activeLineRecorderTake={lineRecorder.value?.activeTake.value ?? undefined}
                onPlayReferenceAudio={onPlayReferenceAudio}
                onStopReferenceAudio={onStopReferenceAudio}
              />
            </Block>
          </Block>
        )}

        <Col flex="1" minWidth="650px">
          <Col
            height="40vh"
            minHeight="40vh"
            position="relative"
            overflow="hidden"
            borderBottom="solid 1px var(--container-outline-lighter)"
          >
            <Col class="bui-show-scroll" overflowY="auto" padding="40px" flex="1">
              <Col flex="1" maxWidth="1200px" justifyContent="center" gap="20px">
                {activeLine?.description && <Block>({activeLine.description})</Block>}
                <Block fontSize="32px" whiteSpace="pre-line" paddingTop="20px" paddingBottom="30px">
                  {currentLanguageLine || !activeLine ? (
                    currentLanguageLine
                  ) : (
                    <Block color="var(--warning)">
                      Not translated to {formatLanguage(lineRecorder.value.language.value)}
                    </Block>
                  )}
                  {!currentLanguageLine && primaryLanguageLine && (
                    <Block color="var(--on-surface-light)" fontSize="24px">
                      <br />
                      {formatLanguage(primaryLanguage)}: {primaryLanguageLine}
                    </Block>
                  )}
                </Block>
              </Col>
            </Col>

            <Block position="absolute" top="0" left="0" padding="20px">
              <Row alignItems="center" gap="10px">
                <Avatar size={30} src={lineRecorder.value.activeLine.value?.character?.picture} />{' '}
                {lineRecorder.value.activeLine.value?.character?.name}
              </Row>
            </Block>

            <Row
              position="absolute"
              bottom="10px"
              left="10px"
              right="10px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Row alignItems="center">
                <IconButton
                  onClick={() => lineRecorder.value!.prevLine()}
                  color="var(--on-surface)"
                  icon={ArrowUpwardIcon}
                  tooltipText="Previous line [Key UP]"
                  onFocus={blurActiveElement}
                  focusable={false}
                />
                <IconButton
                  onClick={() => lineRecorder.value!.nextLine()}
                  color="var(--on-surface)"
                  icon={ArrowDownwardIcon}
                  tooltipText="Next line [Key DOWN]"
                  onFocus={blurActiveElement}
                  focusable={false}
                />

                <Block paddingLeft="10px">
                  {lineRecorder.value.activeLineIndex.value + 1} /{' '}
                  {lineRecorder.value.activeLines.value.length}
                </Block>
              </Row>

              {recorder.value?.state.value.mediaStream && <AudioDeviceSelector />}
            </Row>
          </Col>

          <Col overflow="hidden">
            <Row borderBottom="solid 1px var(--container-outline-lighter)">
              <Block width="100%" maxWidth="1200px">
                <Recorder
                  showVuMeter={!remoteControl}
                  showWaveform={!remoteControl}
                  showOscilloscope={!remoteControl}
                  onStopRecording={handleStopRecording}
                  onStartRecording={handleStartRecording}
                  isRecording={isRecording}
                  isWaitingForRecordingToStart={isWaitingForRecordingToStart}
                  onError={onError}
                  recordingText={remoteControl ? 'Remote recording...' : undefined}
                />
              </Block>
            </Row>
            <Block overflow="hidden">
              <Block class="bui-show-scroll" overflowY="auto" maxHeight="100%">
                <Block>
                  <LineTakes
                    onDeleteTake={onDeleteTake}
                    onUpdateTake={onUpdateTake}
                    onPlayTake={onPlayTake}
                    onStopTake={onStopTake}
                  />
                </Block>
              </Block>
            </Block>
          </Col>
        </Col>
      </Row>
      {showSettings.value && (
        <LineRecorderSettingsDialog
          onClose={() => {
            showSettings.value = false
            // Hacky fix to avoid Settings button keeping focus after closing dialog.
            setTimeout(() => {
              if (document.activeElement instanceof HTMLElement) {
                document.activeElement?.blur()
              }
            }, 10)
          }}
          showSampleSize={showSampleSize}
          remoteControl={remoteControl}
        />
      )}
    </Col>
  )
}

export function LineRecorder(props: LineRecorderComponentProps) {
  recorder.value = useRecorder() ?? undefined

  const sortLinesByScene = (lines: Line[], scenes: Scene[]): Line[] => {
    const sortedLines = scenes
      .map((scene) => {
        if (!scene.lines) {
          return []
        }
        const sceneLines = scene.lines
          .map((sceneLine) => lines.find((line) => line.id === sceneLine.id))
          .filter(Boolean) as Line[]
        return sceneLines
      })
      .flat()

    // Push non-scene lines to the end
    sortedLines.push(
      ...lines.filter((line) => !sortedLines.some((sortedLine) => sortedLine.id === line.id))
    )

    return sortedLines
  }

  useEffect(() => {
    let lines =
      props.scenes?.length && props.lines?.length
        ? sortLinesByScene(props.lines, props.scenes)
        : props.lines ?? []

    lineRecorder.value = createLineRecorderState({
      sessionId: props.sessionId,
      game: props.game,
      lines,
      scenes: props.scenes ?? [],
      language: props.language,
      voice: props.voice,
      showAllTakes: props.showAllTakes ?? false,
      onUploadAudioBuffer: props.uploadAudioBuffer,
      onUpdateLine: props.onUpdateLine,
      onUpdateTake: props.onUpdateTake,
      onError: props.onError
    })
    lineRecorder.value.nextLine()
  }, [])

  useEffect(() => {
    if (!lineRecorder.value) return

    let lines =
      props.scenes?.length && props.lines?.length
        ? sortLinesByScene(props.lines, props.scenes)
        : props.lines ?? []

    lineRecorder.value.lines.value = lines
    lineRecorder.value.scenes.value = props.scenes ?? []

    if (!lineRecorder.value.activeLineId.value) {
      // Set the first line as active
      if (lines.length > 0) {
        lineRecorder.value.selectLine(lines[0].id)
      }
    }
  }, [props.lines, props.scenes])

  if (!recorder || !lineRecorder.value) {
    return null
  }

  return <LineRecorderComponent {...props} />
}
