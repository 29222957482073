import { addLinesToSession, showSnackbar } from '../../actions'

import { routeTo } from '@sodra/prutt'
import { SelectSession } from '../SelectSession'

type Props = {
  onClose: () => void
  onSuccess: () => void
  lineIds: string[]
}

export const AddToSession = ({ onClose, onSuccess, lineIds = [] }: Props) => {
  if (lineIds.length === 0) {
    onClose()
  }

  const handleSubmit = async (sessionId: string) => {
    if (await addLinesToSession(sessionId, lineIds)) {
      showSnackbar('Session updated', {
        text: 'Open',
        onClick: () => routeTo(`/session/${sessionId}`)
      })
      if (onSuccess) {
        onSuccess()
      }
    }
  }

  return (
    <SelectSession
      onClose={onClose}
      onSelect={(session) => {
        handleSubmit(session.id)
      }}
    />
  )
}

export default AddToSession
