import { remove as removeDiacritics } from 'diacritics'

const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max)
}

export const genGameId = (name = '') => {
  return name.trim()
    ? removeDiacritics(name.trim().toLowerCase()).replace(/\W+/g, '-') + '-' + getRandomInt(999)
    : ''
}
