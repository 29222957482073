import { useEffect, useState } from 'preact/hooks'
import { get } from './api'
import { Uploader } from './types'

export const useUploader = (initialUploaderId?: string) => {
  const [uploaderId, setUploaderId] = useState(initialUploaderId)
  const [uploader, setUploader] = useState<Uploader | undefined>()

  useEffect(() => {
    if (initialUploaderId) {
      get(`/files/uploaders/${initialUploaderId}`).then(({ data: uploader }) =>
        setUploader(uploader)
      )
    }
  }, [initialUploaderId])

  return {
    uploaderId,
    uploader,
    setUploader: (uploader: Uploader | undefined) => {
      setUploader(uploader)
      setUploaderId(uploader?.id)
    }
  }
}
