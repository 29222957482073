import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import {
  AliPayIcon,
  IconButton,
  ListItem,
  P,
  PlayIcon,
  SelectChip,
  SpacerVertical,
  StopIcon
} from '@sodra/bongo-ui'

import { goBack, Route, routeTo } from '@sodra/prutt'

import { useUrlParams } from '../../use-url-params'

import Avatar from '../Avatar'
import Page from '../Page'
import SearchTextField from '../SearchTextField'

import { useFetchVoices } from './use-fetch-voices'
import { Pager } from './Pager'
import LoadingList from './LoadingList'
import { GenderSelectChip } from './GenderSelectChip'
import { TypeSelectChip } from './TypeSelectChip'
import { ToneSelectChip } from './ToneSelectChip'
import { AccentSelectChip } from './AccentSelectChip'
import { AgeSelectChip } from './AgeSelectChip'
import { LanguageSelectChip } from './LanguageSelectChip'
import ShortlistVoice from '../ShortlistVoice'
import { formatVoiceName } from '../../format-voice-name'
import { useStore } from '../../store'
import { useLocalStorageState } from '../../use-local-storage-state'
import { useAudioPlayer } from 'lib/src/hooks/use-audio-player'
import { MoreSelectChip } from './MoreSelectChip'

const pageSize = 10

export const Voices = () => {
  const { player } = useAudioPlayer({ useAsMainPlayer: true })
  if (!player) {
    return null
  }
  const { resetPlayer, stopPlayer, play, playerState, initPlayer, hidePlayer, showPlayer } = player

  const { currentGame } = useStore('currentGame')

  const searchParams = new URLSearchParams(location.search)

  const [query, setQuery] = useLocalStorageState<string>(
    'speechless:voice-library:query',
    searchParams.get('query') ?? ''
  )
  const [language, setLanguage] = useLocalStorageState<string | undefined>(
    'speechless:voice-library:language',
    searchParams.get('language') ?? undefined
  )
  const [accent, setAccent] = useLocalStorageState<string | undefined>(
    'speechless:voice-library:accent',
    searchParams.get('accent') ?? undefined
  )
  const [gender, setGender] = useLocalStorageState<string | undefined>(
    'speechless:voice-library:gender',
    searchParams.get('gender') ?? undefined
  )
  const [type, setType] = useLocalStorageState<string | undefined>(
    'speechless:voice-library:type',
    searchParams.get('type') ?? undefined
  )
  const [tone, setTone] = useLocalStorageState<string | undefined>(
    'speechless:voice-library:tone',
    searchParams.get('tone') ?? undefined
  )
  const [soundsLike, setSoundsLike] = useLocalStorageState<string | undefined>(
    'speechless:voice-library:soundsLike',
    searchParams.get('soundsLike') ?? undefined
  )
  const [page, setPage] = useLocalStorageState<number>(
    'speechless:voice-library:page',
    parseFloat(searchParams.get('page') ?? '1')
  )

  const [allowAi, setAllowAi] = useLocalStorageState<string | undefined>(
    'speechless:voice-library:allowAi',
    searchParams.get('allowAi') ?? undefined
  )

  const [canRecordInLondon, setCanRecordInLondon] = useLocalStorageState<string | undefined>(
    'speechless:voice-library:canRecordInLondon',
    searchParams.get('canRecordInLondon') ?? undefined
  )
  const [canRecordInLA, setCanRecordInLA] = useLocalStorageState<string | undefined>(
    'speechless:voice-library:canRecordInLA',
    searchParams.get('canRecordInLA') ?? undefined
  )

  useEffect(() => {
    setPage(1)
  }, [
    query,
    language,
    accent,
    gender,
    type,
    tone,
    soundsLike,
    allowAi,
    canRecordInLondon,
    canRecordInLA
  ])

  useUrlParams(
    {
      query,
      language,
      accent,
      gender,
      type,
      tone,
      soundsLike,
      allowAi,
      canRecordInLondon,
      canRecordInLA,
      page
    },
    { query: '', page: 1 }
  )

  useEffect(() => {
    if (searchParams.has('query')) {
      setQuery(searchParams.get('query') ?? '')
    }
    if (searchParams.has('language')) {
      setLanguage(searchParams.get('language') ?? undefined)
    }
    if (searchParams.has('accent')) {
      setAccent(searchParams.get('accent') ?? undefined)
    }
    if (searchParams.has('gender')) {
      setGender(searchParams.get('gender') ?? undefined)
    }
    if (searchParams.has('type')) {
      setType(searchParams.get('type') ?? undefined)
    }
    if (searchParams.has('tone')) {
      setTone(searchParams.get('tone') ?? undefined)
    }
    if (searchParams.has('soundsLike')) {
      setSoundsLike(searchParams.get('soundsLike') ?? undefined)
    }
    if (searchParams.has('page')) {
      setPage(parseFloat(searchParams.get('page') ?? '1'))
    }
    if (searchParams.has('allowAi')) {
      setAllowAi(searchParams.get('allowAi') ?? undefined)
    }
    if (searchParams.has('canRecordInLondon')) {
      setCanRecordInLondon(searchParams.get('canRecordInLondon') ?? undefined)
    }
    if (searchParams.has('canRecordInLA')) {
      setCanRecordInLA(searchParams.get('canRecordInLA') ?? undefined)
    }
  }, [location.search])

  const { voices, isFetchingVoices, total, refetch } = useFetchVoices({
    query,
    language,
    accent,
    gender,
    type,
    tone,
    soundsLike,
    allowAi,
    canRecordInLondon,
    canRecordInLA,
    page,
    pageSize,
    gameId: currentGame!.id
  })

  const pages = Math.ceil(total / pageSize)

  return (
    <Page title="Voice library">
      <Block>
        <SearchTextField value={query} onChange={setQuery} />
        <SpacerVertical small />
        <Row alignItems="center" gap={10} flexWrap="wrap">
          <LanguageSelectChip
            value={language}
            onChange={(language: string) => {
              setLanguage(language)
              setPage(1)
            }}
          />
          <AccentSelectChip
            value={accent}
            onChange={(accent: string) => {
              setAccent(accent)
              setPage(1)
            }}
          />
          <GenderSelectChip
            value={gender}
            onChange={(gender?: string) => {
              setGender(gender)
              setPage(1)
            }}
          />
          <TypeSelectChip
            value={type}
            onChange={(type: string) => {
              setType(type)
              setPage(1)
            }}
          />
          <ToneSelectChip
            value={tone}
            onChange={(tone: string) => {
              setTone(tone)
              setPage(1)
            }}
          />
          <AgeSelectChip
            value={soundsLike}
            onChange={(soundsLike?: string) => {
              setSoundsLike(soundsLike)
              setPage(1)
            }}
          />
          {canRecordInLondon && (
            <SelectChip
              value="Can record in London"
              onClear={() => {
                setCanRecordInLondon(undefined)
                setPage(1)
              }}
            />
          )}
          {canRecordInLA && (
            <SelectChip
              value="Can record in LA"
              onClear={() => {
                setCanRecordInLA(undefined)
                setPage(1)
              }}
            />
          )}
          {allowAi && (
            <SelectChip
              value="AI license"
              onClear={() => {
                setAllowAi(undefined)
                setPage(1)
              }}
            />
          )}
          <MoreSelectChip
            onChange={(value?: string) => {
              if (value === 'aiLicense') {
                setAllowAi('yes')
              } else if (value === 'canRecordInLondon') {
                setCanRecordInLondon('yes')
              } else if (value === 'canRecordInLA') {
                setCanRecordInLA('yes')
              }
              setPage(1)
            }}
          />
        </Row>
        <SpacerVertical large />
        <Block maxWidth="600px">
          <LoadingList loading={isFetchingVoices}>
            {voices.map((voice, i) => {
              const hasAudio = voice.audio.length > 0
              const isActive = playerState.value.currentTrack?.metaData?.voiceId === voice.id
              const { isPlaying } = playerState.value
              const color = isActive ? 'var(--accent)' : 'var(--on-surface)'

              return (
                <ListItem
                  key={voice.id}
                  color={color}
                  onClick={() => {
                    routeTo(`/voice/${voice.id}`)
                  }}
                  visual={<Avatar size={40} src={voice.picture} name={voice.name} />}
                  text={
                    <Row alignItems="center" gap="10px">
                      <Block flex="1">
                        <Block>{formatVoiceName(voice.name)}</Block>
                      </Block>
                      {voice!.characters?.map((character) => {
                        return (
                          <Avatar
                            size={30}
                            src={character.picture}
                            name={character.name}
                            link={{
                              href: `/character/${character.id}`,
                              onRoute: routeTo
                            }}
                          />
                        )
                      })}
                      <IconButton
                        color={color}
                        disabled={!hasAudio}
                        icon={isPlaying && isActive ? StopIcon : PlayIcon}
                        onClick={() => {
                          if (isPlaying && isActive) {
                            stopPlayer()
                            hidePlayer()
                            resetPlayer()
                          } else {
                            if (!isActive) {
                              initPlayer({
                                audioTracks: voices.map((voice) => {
                                  return {
                                    picture: voice.picture,
                                    name: voice.name,
                                    metaData: {
                                      voiceId: voice.id
                                    },
                                    segments: voice.audio.map((audio) => {
                                      return {
                                        ...audio,
                                        description: audio.description,
                                        name: audio.name,
                                        metaData: {}
                                      }
                                    })
                                  }
                                })
                              })
                            }
                            showPlayer()
                            play({ audioTrackIndex: i })
                          }
                        }}
                      />
                    </Row>
                  }
                />
              )
            })}
          </LoadingList>
        </Block>
        {!isFetchingVoices && voices.length === 0 && (
          <P margin="3rem">
            Don’t find what you are looking for?
            <br />
            <br />
            <a href="mailto:info@speechless.games" title="Contact Speechless via email">
              Contact us
            </a>{' '}
            and we will help you find a voice.
          </P>
        )}
        <SpacerVertical />
        {pages > 1 && <Pager page={page} pages={pages} setPage={setPage} />}
        {allowAi && voices.length > 0 && (
          <Block fontSize="12px" color="var(--on-surface-light)">
            Note: AI license allows for generative AI technology within the game title.{' '}
            <a
              href={`${
                import.meta.env.VITE_HOMEPAGE_URL
              }/support-articles/can-i-purchase-an-ai-license-for-my-game`}
              target="_blank"
            >
              Read more
            </a>
          </Block>
        )}
        <SpacerVertical large />
      </Block>
      <Route
        path="/voices/short-list-voice/:voiceId"
        render={(props: { voiceId: string }) => {
          const voice = voices.find((v) => v.id == props.voiceId)
          if (!voice) {
            goBack('/voices')
          } else {
            return (
              <ShortlistVoice voice={voice} onSuccess={refetch} onClose={() => goBack(`/voices`)} />
            )
          }
        }}
      />
    </Page>
  )
}

export default Voices
