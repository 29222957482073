import { h } from 'preact'
import { useState } from 'preact/hooks'
import { PopupMenu, Position, PopupMenuOption } from './PopupMenu'

export type BuiClickEvent =
  | {
      type: 'mouse'
      x: number
      y: number
    }
  | {
      type: 'keyboard'
      target: HTMLElement
    }

export type MenuPosition =
  | {
      position: Position
    }
  | {
      positionElement: HTMLElement
    }

export const usePopupMenu = ({ options }: { options?: PopupMenuOption[] }) => {
  const [menuPosition, setMenuPosition] = useState<MenuPosition>()
  const [menuData, setMenuData] = useState<any>()

  const showMenu = (e: BuiClickEvent, data: any) => {
    if (e.type === 'keyboard') {
      if (!e.target) {
        return
      }
      setMenuPosition({ positionElement: e.target })
    } else {
      setMenuPosition({ position: { x: e.x, y: e.y } })
    }
    setMenuData(data)
  }

  const hideMenu = () => {
    setMenuPosition(undefined)
    setMenuData(undefined)
  }

  const popupMenu = menuPosition ? (
    <PopupMenu {...menuPosition} options={options} data={menuData} onClose={hideMenu} />
  ) : null

  return { PopupMenu: popupMenu, showMenu, hideMenu }
}
