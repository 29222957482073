import { confirmBillingAccount } from './confirmBillingAccount'
import { Game } from '../types'
import { confirmCreditCard } from './confirmCreditCard'
import { formatCurrencyNoDecimals } from 'lib'
import { Block } from 'jsxstyle/preact'
import { P, SpacerVertical } from '@sodra/bongo-ui'

export const checkBuyAiTimePreconditions = async (params: {
  game: Game
  onSuccess: () => void
  onBillingAccountRequired: () => void
  onCreditCardRequired: () => void
}) => {
  const { game, onSuccess, onBillingAccountRequired, onCreditCardRequired } = params

  if (!game) {
    return
  }

  const aiHourPrice = !game.billingAccount || game.billingAccount.plan === 'starter' ? 29 : 24

  if (!game.billingAccount) {
    // Ask user to add a billing account to the game first
    if (
      await confirmBillingAccount({
        message: (
          <Block>
            <P>
              Please add a billing account if you want to buy additional AI text-to-speech hours.
            </P>
            <SpacerVertical />
            <P>AI voice quota cost: {formatCurrencyNoDecimals(aiHourPrice)} per hour.</P>
          </Block>
        )
      })
    ) {
      onBillingAccountRequired()
    }
  } else if (!game.billingAccount.invoice && !game.billingAccount.hasCreditCard) {
    // Ask user to add credit card
    if (
      await confirmCreditCard({
        message: (
          <>
            <P>
              Please add a credit card to your billing account if you want to buy additional AI
              text-to-speech hours.
            </P>
            <SpacerVertical />
            <P>AI voice quota cost: {formatCurrencyNoDecimals(aiHourPrice)} per hour.</P>
          </>
        )
      })
    ) {
      onCreditCardRequired()
    }
  } else {
    onSuccess()
  }
}
