import { Block, Row } from 'jsxstyle/preact'
import type { Take } from './lineRecorderState'

type Props = {
  takes: Take[]
  selectedTake?: Take
  activeLineRecorderTake?: Take
}

export function TakeDots({ takes, selectedTake, activeLineRecorderTake }: Props) {
  return (
    <Row gap="4px" minHeight="8px" flexWrap="wrap">
      {takes.map((take) => {
        let color: string = 'var(--on-surface-lighter)'
        switch (take.status) {
          case 'done':
            color = 'var(--on-surface-lighter)'
            break
          case 'loading':
            color = 'var(--warning)'
            break
        }

        if (take.error) {
          color = 'var(--error)'
        }

        const isSelectedTake = take.id === selectedTake?.id
        const isActiveTake = take.id === activeLineRecorderTake?.id

        return (
          <Block
            width="8px"
            height="8px"
            borderRadius="50%"
            background={isSelectedTake ? 'var(--accent)' : color}
            boxShadow={isActiveTake ? '0 0 0 2px var(--on-surface)' : ''}
          />
        )
      })}
    </Row>
  )
}
