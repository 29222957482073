import { useSignal } from '@preact/signals'
import { useEffect } from 'preact/hooks'

export const usePermissionState = (api: PermissionName | 'microphone') => {
  const permissionState = useSignal<PermissionState | null>(null)
  useEffect(() => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions
        .query({ name: api as PermissionName })
        .then(function (permissionStatus) {
          permissionState.value = permissionStatus.state
          permissionStatus.onchange = function () {
            permissionState.value = this.state
          }
        })
    } else {
      permissionState.value = 'denied'
    }
  }, [api])

  return {
    permissionState
  }
}
