import {
  Dialog,
  ZoomInIcon,
  SpacerHorizontal,
  Slider,
  SpacerVertical,
  IconButton,
  StopIcon,
  PlayIcon,
  WarningIcon,
  LockIcon,
  Avatar,
  Switch,
  HelpIcon,
  H2
} from '@sodra/bongo-ui'
import { h, JSX } from 'preact'
import { formatDuration } from '../../lib/format-duration'
import { AudioEditor } from '../AudioEditor'
import { blurActiveElement } from './blur-active-element'
import { Take } from './lineRecorderState'
import { TooltipWrapper } from './TooltipWrapper'
import { useEffect, useRef, useState } from 'preact/hooks'
import { AudioBufferPlayer } from '../../lib/useAudioBufferPlayer'
import { Block, Grid, Row } from 'jsxstyle/preact'

const ZOOM_FACTOR_MAX = 5
const ZOOM_FACTOR_MIN = 1
const ZOOM_FACTOR_STEP = 0.5

type TakeFocusModeProps = {
  take: Take
  audioBufferPlayer: AudioBufferPlayer
  audioBuffer: AudioBuffer
  start: number
  end: number
  onTrimStart: (step: number) => void
  onTrimEnd: (step: number) => void
  onSetStart: (value: number, isMoving: boolean) => void
  onSetEnd: (value: number, isMoving: boolean) => void
  onSetTrimLocked: (locked: boolean) => void
  onSetSelectedTake: (selected: boolean) => void
  isSelectedTake: boolean
  onClose: () => void
}

export const TakeFocusMode = ({
  take,
  audioBuffer,
  audioBufferPlayer,
  start,
  end,
  onSetStart,
  onSetEnd,
  onTrimStart,
  onTrimEnd,
  onSetTrimLocked,
  onSetSelectedTake,
  isSelectedTake,
  onClose
}: TakeFocusModeProps) => {
  const [scrollOffsetLeft, setScrollOffsetLeft] = useState(0)
  const [zoomFactor, setZoomFactor] = useState(1)
  const [showHelp, setShowHelp] = useState(false)

  const scrollWheelListener = useRef<((e: WheelEvent) => void) | undefined>(undefined)

  type UseKeyboardEventsParams = {
    isTrimLocked: boolean
    takeStatus?: 'done' | 'loading' | undefined
    zoomFactor: number
    onIncreaseZoomFactor: () => void
    onDecreaseZoomFactor: () => void
    onTrimStart: (step: number) => void
    onTrimEnd: (step: number) => void
    onScroll: (direction: 'left' | 'right') => void
    onClose: () => void
  }

  const useKeyboardEvents = ({
    isTrimLocked,
    takeStatus,
    zoomFactor,
    onIncreaseZoomFactor,
    onDecreaseZoomFactor,
    onTrimStart,
    onTrimEnd,
    onScroll,
    onClose
  }: UseKeyboardEventsParams) => {
    type KeyboardEventHoldingKey = 'x' | 'z'
    const [holdingKey, setHoldingKey] = useState<KeyboardEventHoldingKey | undefined>(undefined)

    const TRIM_STEP = 0.04

    useEffect(() => {
      const keyDownHandler = (e: KeyboardEvent) => {
        const callback = {
          Escape: () => {
            onClose()
          },
          '+': () => {
            onIncreaseZoomFactor()
          },
          '-': () => {
            onDecreaseZoomFactor()
          },
          z: () => {
            if (!holdingKey && !isTrimLocked) {
              setHoldingKey('z')
            }
          },
          x: () => {
            if (!holdingKey && !isTrimLocked) {
              setHoldingKey('x')
            }
          },
          ArrowLeft: () => {
            if (!holdingKey) {
              onScroll('left')
            } else if (!isTrimLocked && takeStatus !== 'loading') {
              if (holdingKey === 'z') {
                onTrimStart(-TRIM_STEP / zoomFactor)
              }
              if (holdingKey === 'x') {
                onTrimEnd(-TRIM_STEP / zoomFactor)
              }
            }
          },
          ArrowRight: () => {
            if (!holdingKey) {
              onScroll('right')
            } else if (!isTrimLocked && takeStatus !== 'loading') {
              if (holdingKey === 'z') {
                onTrimStart(TRIM_STEP / zoomFactor)
              }
              if (holdingKey === 'x') {
                onTrimEnd(TRIM_STEP / zoomFactor)
              }
            }
          }
        }[e.key]

        if (callback) {
          e.preventDefault()
          callback()
        }
      }

      const keyUpHandler = (e: KeyboardEvent) => {
        e.preventDefault()
        const callback = {
          z: () => {
            if (holdingKey === 'z') {
              setHoldingKey(undefined)
            }
          },
          x: () => {
            if (holdingKey === 'x') {
              setHoldingKey(undefined)
            }
          }
        }[e.key]

        if (callback) {
          e.preventDefault()
          e.stopPropagation()
          callback()
        }
      }

      document.addEventListener('keydown', keyDownHandler)
      document.addEventListener('keyup', keyUpHandler)

      return () => {
        document.removeEventListener('keydown', keyDownHandler)
        document.removeEventListener('keyup', keyUpHandler)
      }
    }, [
      isTrimLocked,
      takeStatus,
      zoomFactor,
      onIncreaseZoomFactor,
      onDecreaseZoomFactor,
      onTrimStart,
      onTrimEnd,
      onClose,
      holdingKey
    ])
  }

  const increaseZoomFactor = () => {
    if (zoomFactor < ZOOM_FACTOR_MAX) {
      // Round zoom factor in case slider has been set manually to an "uneven" value
      const roundedZoomFactor = Math.round(zoomFactor / ZOOM_FACTOR_STEP) * ZOOM_FACTOR_STEP
      const newZoomFactor = Math.min(roundedZoomFactor + ZOOM_FACTOR_STEP, ZOOM_FACTOR_MAX)
      setZoomFactor(newZoomFactor)
    }
  }
  const decreaseZoomFactor = () => {
    if (zoomFactor > ZOOM_FACTOR_MIN) {
      // Round zoom factor in case slider has been set manually to an "uneven" value
      const roundedZoomFactor = Math.round(zoomFactor / ZOOM_FACTOR_STEP) * ZOOM_FACTOR_STEP
      const newZoomFactor = Math.max(roundedZoomFactor - ZOOM_FACTOR_STEP, ZOOM_FACTOR_MIN)
      setZoomFactor(newZoomFactor)
    }
  }

  const scrollAudioEditor = (direction: 'left' | 'right') => {
    const scrollAmountPixels = 150
    setScrollOffsetLeft(
      scrollOffsetLeft + (direction === 'left' ? -scrollAmountPixels : scrollAmountPixels)
    )
  }

  useKeyboardEvents({
    isTrimLocked: !!take.trimLocked,
    takeStatus: take.status,
    zoomFactor,
    onIncreaseZoomFactor: increaseZoomFactor,
    onDecreaseZoomFactor: decreaseZoomFactor,
    onTrimStart,
    onTrimEnd,
    onScroll: scrollAudioEditor,
    onClose
  })

  useEffect(() => {
    scrollWheelListener.current = (e: WheelEvent) => {
      if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
        e.preventDefault()
        if (e.deltaY < 0) {
          increaseZoomFactor()
        } else if (e.deltaY > 0) {
          decreaseZoomFactor()
        }
      }
    }
    window.addEventListener('wheel', scrollWheelListener.current, { passive: false })
    return () => {
      if (scrollWheelListener.current) {
        window.removeEventListener('wheel', scrollWheelListener.current)
      }
    }
  }, [zoomFactor, scrollOffsetLeft])

  const dialogPrimaryActions: any[] = []

  const dialogSecondaryActions: any[] = [
    {
      text: 'Done',
      onClick: onClose
    }
  ]

  const zoomSliderValue = (zoomFactor - ZOOM_FACTOR_MIN) / (ZOOM_FACTOR_MAX - ZOOM_FACTOR_MIN)
  const updateZoomFactorFromSlider = (sliderValue: number) => {
    const newZoomFactor = sliderValue * (ZOOM_FACTOR_MAX - ZOOM_FACTOR_MIN) + ZOOM_FACTOR_MIN
    setZoomFactor(newZoomFactor)
  }

  const playOnClick = (startValue: number) => {
    audioBufferPlayer?.play({ startFrom: startValue })
  }

  const togglePlay = () => {
    if (audioBufferPlayer.state === 'playing') {
      audioBufferPlayer.stop()
    } else {
      audioBufferPlayer.play()
    }
  }

  const onScroll = (scrollLeft: number) => {
    setScrollOffsetLeft(scrollLeft)
  }

  return (
    <Dialog
      full
      title={`Take ${take.seqLanguage}`}
      disableAutofocus
      onClose={onClose}
      actions={dialogPrimaryActions}
      secondaryActions={dialogSecondaryActions}
    >
      <Row>
        <Block flex="1">
          <Row gap="10px" alignItems="center">
            <Block>
              <IconButton
                icon={HelpIcon}
                tooltipText="Show help"
                onClick={() => setShowHelp(true)}
                onFocus={blurActiveElement}
                focusable={false}
              />
            </Block>
            <Block flex="1"></Block>
            <Block>
              <TooltipWrapper tooltipText="You can use the scroll wheel or +/- keys to zoom in/out.">
                <ZoomInIcon fill="var(--on-surface-light)" size={36} />
              </TooltipWrapper>
            </Block>
            <Block alignItems="center" whiteSpace="nowrap">
              x {zoomFactor.toFixed(1)}
            </Block>
            <SpacerHorizontal tiny />
            <Slider value={zoomSliderValue} onChange={updateZoomFactorFromSlider}></Slider>
            <SpacerHorizontal tiny />
          </Row>
        </Block>
      </Row>
      <SpacerVertical small />
      <Block width="100%">
        <AudioEditor
          zoom={{
            factor: zoomFactor,
            minFactor: ZOOM_FACTOR_MIN,
            maxFactor: ZOOM_FACTOR_MAX,
            minCanvasHeight: 80,
            maxCanvasHeight: 400
          }}
          height="400px"
          currentTime={audioBufferPlayer?.state === 'playing' ? audioBufferPlayer?.currentTime : 0}
          audioBuffer={audioBuffer}
          start={start}
          end={end}
          setStart={onSetStart}
          setEnd={onSetEnd}
          color="--on-surface"
          selectedColor="--on-surface-lighter"
          progressColor="--accent"
          progressBgColor="--accent-light"
          trimLocked={!!take.trimLocked}
          isUpdatingTake={take.status === 'loading'}
          onPlay={playOnClick}
          playOffsetTime={audioBufferPlayer?.playOffsetTime}
          scrollOffsetLeft={scrollOffsetLeft}
          onScroll={onScroll}
        />
      </Block>
      <SpacerVertical />
      <Row alignItems="center" gap="20px">
        {/* Play button */}
        <IconButton
          color="var(--accent)"
          icon={audioBufferPlayer?.state === 'playing' ? StopIcon : PlayIcon}
          onClick={togglePlay}
          tooltipText={audioBufferPlayer?.state === 'playing' ? 'Stop' : 'Play take'}
          focusable={false}
        />
        <Block flex="1"></Block>
        {/* Status icons */}
        <Block>
          {take.peakWarning && (
            <IconButton
              icon={WarningIcon}
              tooltipText="Input peaked during the recording of this take"
              color="var(--warning)"
              onFocus={blurActiveElement}
              focusable={false}
            />
          )}
        </Block>
        <Block>
          {take.trimLocked && (
            <IconButton
              icon={LockIcon}
              tooltipText="Unlock"
              color="var(--accent)"
              onClick={() => {
                onSetTrimLocked(false)
              }}
            />
          )}
          {!take.trimLocked && (
            <IconButton
              icon={LockIcon}
              tooltipText="Lock"
              color="var(--on-surface-light)"
              onClick={() => {
                onSetTrimLocked(true)
              }}
            />
          )}
        </Block>
        {/* Avatar */}
        <Row alignItems="center" gap="10px">
          <Avatar
            size={30}
            src={take.aiVoice?.picture ?? take.user?.picture ?? take.voice?.picture}
          />
          <Block>
            <Block fontSize="14px">
              {take.aiVoice?.name ?? take.user?.name ?? take.voice?.name}
            </Block>
            <Block fontSize="12px" color="var(--on-surface-light)">
              {formatDuration(take.created!)}
            </Block>
          </Block>
        </Row>

        {/* Set selected take button */}
        <Block>
          <Switch
            disabled={take.error}
            onChange={onSetSelectedTake}
            on={isSelectedTake}
            tooltipText={'Set as selected take'}
          />
        </Block>
      </Row>
      <SpacerVertical large />
      {showHelp && (
        <Dialog
          title="Keyboard & mouse controls"
          actions={[{ text: 'Close', onClick: () => setShowHelp(false) }]}
          onClose={() => setShowHelp(false)}
          dismissable={true}
        >
          <Block>
            <Grid gridTemplateColumns="200px 1fr" columnGap="10px" rowGap="10px">
              <strong>
                +/- keys or
                <br />
                Mouse wheel
              </strong>
              <span>Zoom in/out</span>
              <strong>
                Left/Right keys or
                <br />
                Shift + mouse wheel
              </strong>
              <span>Scroll left/right when zoomed in</span>
              <strong>Space</strong>
              <span> Play from beginning</span>
              <strong>Left click</strong>
              <span> Play from click</span>
              <strong>Hold left mouse button</strong>
              <span> Drag and release to set trim</span>
              <strong>Z + Left/Right</strong>
              <span>Adjust trim start</span>
              <strong>X + Left/Right</strong>
              <span>Adjust trim end</span>
              <strong>S</strong>
              <span>Set/unset as selected take</span>
              <strong>L</strong>
              <span>Lock/unlock trim</span>
              <strong>Esc</strong>
              <span>Close focus mode</span>
            </Grid>
          </Block>
          <SpacerVertical />
        </Dialog>
      )}
    </Dialog>
  )
}
