import { h } from 'preact'
import { Block, InlineBlock, Row } from 'jsxstyle/preact'
import { Button, EventIcon, List, ListItem, OpenInNewIcon, SpacerVertical } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import { Game, Session } from '../../types'
import { formatSessionStatus } from '../../formatSessionStatus'
import { formatScheduled } from 'lib'

type Props = {
  sessions: Session[]
  game?: Game
  wide?: boolean
}

export const SessionsCard = ({ sessions, game, wide = false }: Props) => {
  return (
    <InlineBlock
      position="relative"
      background="var(--container-background)"
      minWidth={wide ? '100%' : '300px'}
      padding="20px"
      boxShadow="1px 1px 1px var(--box-shadow-color)"
      cursor="pointer"
      props={{ onClick: () => routeTo('/sessions') }}
    >
      <Block textAlign="center" color="var(--on-surface-light)">
        Upcoming sessions
      </Block>
      <SpacerVertical />
      <List>
        {sessions.map((session) => {
          return (
            <Block props={{ onclick: (e: MouseEvent) => e.stopPropagation() }}>
              <ListItem
                text={`#${session.num}: ${formatScheduled(session.scheduled)}`}
                secondaryText={formatSessionStatus(session)}
                link={{
                  href: `/session/${session.id}`,
                  onRoute: routeTo
                }}
                actionIcon={session.meetingLink && OpenInNewIcon}
                onActionClick={session.meetingLink && (() => window.open(session.meetingLink))}
              />
            </Block>
          )
        })}
      </List>
      <SpacerVertical large />
      <Row alignItems="center" justifyContent="space-between">
        <Button
          icon={EventIcon}
          onClick={() =>
            window.open(`webcal:${import.meta.env.VITE_API_URL}/v1/games/${game!.id}/sessions.ics`)
          }
        >
          Subscribe
        </Button>
      </Row>
    </InlineBlock>
  )
}
