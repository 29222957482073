import { createExportTarget } from 'src/actions'
import { LineFilter } from 'src/types'

export type WwiseSettings = {
  workUnit: string
  folderStructure: string
  createEvents: boolean
  moveExisting: boolean
}

export const defaultSettings: WwiseSettings = {
  workUnit: 'Default Work Unit',
  folderStructure: 'Speechless',
  createEvents: false,
  moveExisting: false
}

type CreateWwiseExportTargetParams = {
  name: string
  settings: WwiseSettings
  lineFilter?: LineFilter
}

export function createWwiseExportTarget({
  name = 'wwise',
  settings = defaultSettings,
  lineFilter
}: CreateWwiseExportTargetParams) {
  return createExportTarget({
    type: 'wwise',
    name,
    lineFilterId: lineFilter?.id,
    ...settings
  })
}
