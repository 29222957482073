import {
  ArrowDownwardIcon,
  BasicDialog,
  Button,
  Form,
  IconButton,
  MemoryIcon,
  NativeSelect,
  OpenAIIcon,
  OpenInNewIcon,
  SpacerHorizontal,
  SpacerVertical,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { Route, goBack, routeTo } from '@sodra/prutt'
import { useStore } from 'src/store'
import SelectCharacter from '../SelectCharacter'
import SelectEvent from '../SelectEvent'
import SelectScene from '../SelectScene'
import { useEffect, useState } from 'preact/hooks'
import {
  Character,
  Event,
  LineAttributeValue,
  Scene as SceneType,
  Line as LineType,
  LineAttribute,
  LineAttributeType
} from 'src/types'
import { FakeSelect } from '../FakeSelect'
import Avatar from '../Avatar'
import { formatLanguage } from '../../format-language'
import { Block, Row } from 'jsxstyle/preact'
import { createRephrasedLineCopies, setError, showSnackbar, updateLine } from 'src/actions'
import { RephraseLine } from './RephraseLine'
import { LineAttributeInput } from './LineAttributeInput'
import { blurActiveElement } from 'line-recorder/src/components/LineRecorder/blur-active-element'
import { capitalize } from '../capitalize'
import { post } from 'src/api'
import { pluralize } from 'lib'

const emotions = [
  'angry',
  'cheerful',
  'excited',
  'friendly',
  'hopeful',
  'newscast',
  'sad',
  'shouting',
  'terrified',
  'unfriendly',
  'whispering'
].sort()

const getTranslationsFromLine = (line: LineType) => {
  return Object.entries(line.translations).map(([language, { line, aiLine, aiLineTemplate }]) => ({
    language,
    line,
    aiLine,
    aiLineTemplate
  }))
}

export const EditLineDetails = () => {
  const {
    isUpdatingLine,
    currentUser,
    currentGame,
    currentLanguage,
    lineAttributes = [],
    ...storeData
  } = useStore(
    'line',
    'isUpdatingLine',
    'currentUser',
    'currentGame',
    'currentLanguage',
    'lineAttributes'
  )

  const line = storeData.line!

  const primaryLanguage = currentGame?.primaryLanguage || 'en'
  const secondaryLanguage = currentLanguage !== primaryLanguage ? currentLanguage : undefined

  type Translations = {
    language: string
    line: string
    aiLine?: string
    aiLineTemplate?: string
  }[]

  const [translations, setTranslations] = useState<Translations>([])

  const [description, setDescription] = useState(line.description || '')
  const [filename, setFilename] = useState(line.filename || '')
  const [emotion, setEmotion] = useState(line.emotion || '')

  const [character, setCharacter] = useState<Character | undefined>(line.character)
  const [event, setEvent] = useState<Event | undefined>(line.event)
  const [scene, setScene] = useState<SceneType | undefined>(line.scene)

  const [lineError, setLineError] = useState('')
  const [filenameError, setFilenameError] = useState('')
  const [characterError, setCharacterError] = useState('')
  const [lineAttributeErrors, setLineAttributeErrors] = useState<Record<string, string>>({})

  const [lineAttributeValues, setLineAttributeValues] = useState<LineAttributeValue[]>(
    line.lineAttributes ? [...line.lineAttributes] : []
  )
  const [isTranslating, setIsTranslating] = useState(false)

  const [externalLineId, setExternalLineId] = useState<string | undefined>(line.externalLineId)
  const [externalLineIdError, setExternalLineIdError] = useState<string | undefined>()

  const [hasAiLines, setHasAiLines] = useState(false)
  const [aiLinePrimaryLanguage, setAiLinePrimaryLanguage] = useState<string | undefined>(undefined)
  const [aiLineSecondaryLanguage, setAiLineSecondaryLanguage] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    const lineTranslations = getTranslationsFromLine(line)

    setTranslations(lineTranslations)
    setAiLines(lineTranslations)
  }, [])

  useEffect(() => {
    if (!hasAiLines) {
      setTranslationAiLine(primaryLanguage, undefined)
      if (secondaryLanguage) {
        setTranslationAiLine(secondaryLanguage, undefined)
      }
      return
    } else {
      setTranslationAiLine(primaryLanguage, aiLinePrimaryLanguage)
      if (secondaryLanguage) {
        setTranslationAiLine(secondaryLanguage, aiLineSecondaryLanguage)
      }
    }
  }, [aiLinePrimaryLanguage, aiLineSecondaryLanguage, hasAiLines])

  const primaryTranslation = translations.find((t) => t.language === primaryLanguage)
  const secondaryTranslation = translations.find((t) => t.language === secondaryLanguage)

  const onClose = () => goBack(`/line/${line?.id}`)

  const createNewLines = async (phrases: string[], language: string) => {
    const lines = await createRephrasedLineCopies(line.id, phrases)
    console.log(lines)
    if (lines.length > 0) {
      showSnackbar(`${lines.length} new ${pluralize('line', lines.length)} created`)
    }
  }

  const setLineAttributeError = (attributeId: string, errorMessage: string) => {
    setLineAttributeErrors({
      ...lineAttributeErrors,
      [attributeId]: errorMessage
    })
  }

  const getLineAttributeValue = (attributeId: string) =>
    lineAttributeValues?.find((attribute: LineAttribute) => attribute.id === attributeId)?.value

  const setLineAttributeValue = (attributeId: string, value: LineAttributeType | undefined) => {
    setLineAttributeValues((attributes) =>
      attributes?.map((attribute) =>
        attribute.id === attributeId ? { ...attribute, value } : attribute
      )
    )
  }

  const setTranslation = (language: string, line: string) => {
    setTranslations((translations) => {
      const hasTranslation = translations.some((t) => t.language === language)
      if (hasTranslation) {
        return translations.map((t) => (t.language === language ? { ...t, line } : t))
      }
      return [...translations, { language, line }]
    })
  }

  const setTranslationAiLine = (language: string, aiLine: string | undefined) => {
    setTranslations((translations) => {
      return translations.map((t) => (t.language === language ? { ...t, aiLine } : t))
    })
  }

  const setAiLines = (lineTranslations: Translations) => {
    const primaryTranslation = lineTranslations.find(
      (translation) => translation.language === primaryLanguage
    )
    if (primaryTranslation?.aiLine) {
      setAiLinePrimaryLanguage(primaryTranslation.aiLine)
      setHasAiLines(true)
    }
    if (secondaryLanguage) {
      const secondaryTranslation = lineTranslations.find(
        (translation) => translation.language === secondaryLanguage
      )
      if (secondaryTranslation?.aiLine) {
        setAiLineSecondaryLanguage(secondaryTranslation.aiLine)
      }
    }
  }

  const translateLine = async () => {
    if (!secondaryLanguage) {
      return
    }
    if (!secondaryTranslation?.line || (await confirm('Overwrite current translation?'))) {
      setIsTranslating(true)
      try {
        const { data: translation } = await post(`/translate`, {
          gameId: currentGame!.id,
          text: primaryTranslation?.line,
          fromLanguage: primaryLanguage,
          toLanguage: secondaryLanguage
        })
        setTranslation(secondaryLanguage, translation)
        if (hasAiLines) {
          setAiLineSecondaryLanguage(translation)
        }
      } catch (err) {
        setError(err)
      } finally {
        setIsTranslating(false)
      }
    }
  }

  const isValidUrl = (url: string) => {
    try {
      new URL(url)
    } catch {
      return false
    }
    return true
  }

  const handleSubmit = async () => {
    setLineError('')
    setCharacterError('')
    setFilenameError('')
    setExternalLineIdError('')

    let hasErrors = false

    if (primaryTranslation && primaryTranslation.line.trim() === '') {
      setLineError('Line cannot be empty')
      hasErrors = true
    }
    if (filename.trim() === '') {
      setFilenameError('Filename cannot be empty')
      hasErrors = true
    }
    if (!character) {
      setCharacterError('Please select a character')
      hasErrors = true
    }
    if (
      currentGame?.useExternalLineId &&
      !currentGame.useFilenameAsExternalLineId &&
      !externalLineId?.trim()
    ) {
      setExternalLineIdError('External line ID cannot be empty')
      hasErrors = true
    }

    setLineAttributeErrors({})

    for (let lineAttribute of lineAttributes) {
      const attributeValue = lineAttributeValues.find((av) => av.id === lineAttribute.id)

      if (lineAttribute.type !== 'boolean' && lineAttribute.required) {
        hasErrors = !attributeValue?.value?.toString().trim()
        if (hasErrors) {
          setLineAttributeError(lineAttribute.id, 'Please enter a value')
        }
      }
      if (lineAttribute.type === 'url') {
        const url = attributeValue?.value?.toString().trim()

        if (url && !isValidUrl(url)) {
          setLineAttributeError(lineAttribute.id, 'Please enter a valid URL')
          hasErrors = true
        }
      }
    }

    if (hasErrors) {
      return
    }
    if (
      await updateLine(line.id, {
        translations: translations.map((translation) => {
          const aiLine = translation.aiLine?.trim()

          return {
            ...translation,
            // Make sure to reset (null) aiLine if not used or empty
            aiLine: hasAiLines && aiLine?.length ? aiLine : null
          }
        }),
        description,
        filename,
        emotion,
        eventId: event?.id || null,
        sceneId: scene?.id || null,
        characterId: character!.id,
        lineAttributes: lineAttributeValues,
        externalLineId: externalLineId || null
      })
    ) {
      showSnackbar('Line saved')
      onClose()
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      dismissable={false}
      title="Edit line details"
      onClose={onClose}
      actions={[
        { text: 'Save', onClick: handleSubmit, contained: true, loading: isUpdatingLine },
        { text: 'Cancel', onClick: onClose }
      ]}
    >
      <FakeSelect
        label="Character *"
        visual={
          character ? <Avatar size={30} src={character.picture} name={character.name} /> : undefined
        }
        value={character?.name}
        onClick={() => routeTo(`/line/${line.id}/edit-details/select-character`)}
        errorText={characterError}
      />
      <SpacerVertical />
      <FakeSelect
        label="Event"
        visual={event ? <Avatar size={30} src={event.picture} name={event.name} /> : undefined}
        value={event?.name}
        onClick={() => routeTo(`/line/${line.id}/edit-details/select-event`)}
      />
      <SpacerVertical />
      <FakeSelect
        label="Scene"
        visual={scene ? <Avatar size={30} src={scene.picture} name={scene.name} /> : undefined}
        value={scene?.name}
        onClick={() => routeTo(`/line/${line.id}/edit-details/select-scene`)}
      />
      <SpacerVertical />
      <Form onSubmit={handleSubmit} maxWidth="500px">
        <TextArea
          autocomplete="off"
          width="100%"
          autoHeight
          minRows={3}
          maxRows={10}
          label={secondaryLanguage ? `Line (${formatLanguage(primaryLanguage)}) *` : `Line *`}
          value={primaryTranslation?.line}
          onInput={(line: string) => setTranslation(primaryLanguage, line)}
          errorText={lineError}
        />
        {hasAiLines && (
          <>
            <SpacerVertical small />
            <TextArea
              autocomplete="off"
              width="100%"
              autoHeight
              minRows={3}
              maxRows={10}
              label={
                secondaryLanguage
                  ? `AI TTS prompt (${formatLanguage(primaryLanguage)}) - For generating AI takes`
                  : `AI TTS prompt - For generating AI takes`
              }
              value={aiLinePrimaryLanguage}
              onInput={(aiLine: string) => setAiLinePrimaryLanguage(aiLine)}
            />
          </>
        )}

        <>
          <SpacerVertical tiny />
          <Row justifyContent="left">
            <Block>
              <Button
                tiny
                icon={OpenAIIcon}
                onClick={() => routeTo(`/line/${line.id}/edit-details/rephrase-primary-language`)}
                loading={isTranslating}
                disabled={!primaryTranslation?.line}
              >
                Rephrase
              </Button>
              <SpacerHorizontal small />
              {!hasAiLines && (
                <Button
                  tiny
                  icon={MemoryIcon}
                  onClick={() => {
                    setHasAiLines(true)

                    // If adding a new AI line, copy line text from translations
                    if (!aiLinePrimaryLanguage?.trim().length) {
                      setAiLinePrimaryLanguage(
                        translations.find((t) => t.language === primaryLanguage)?.line ?? ''
                      )
                    }
                    if (secondaryLanguage && !aiLineSecondaryLanguage?.trim().length) {
                      setAiLineSecondaryLanguage(
                        translations.find((t) => t.language === secondaryLanguage)?.line ?? ''
                      )
                    }
                  }}
                  disabled={!primaryTranslation?.line}
                >
                  Add AI TTS prompt
                </Button>
              )}
              {hasAiLines && (
                <Button
                  tiny
                  icon={MemoryIcon}
                  onClick={() => {
                    setHasAiLines(false)

                    // If removing an AI line, unset the translation value as well as local state
                    setTranslationAiLine(primaryLanguage, undefined)
                    if (secondaryLanguage) {
                      setTranslationAiLine(secondaryLanguage, undefined)
                    }
                    setAiLinePrimaryLanguage(undefined)
                    setAiLineSecondaryLanguage(undefined)
                  }}
                >
                  Remove AI TTS prompt
                </Button>
              )}
            </Block>

            {secondaryLanguage && (
              <Row justifyContent="right" flex="1">
                <Button
                  tiny
                  icon={ArrowDownwardIcon}
                  onClick={translateLine}
                  loading={isTranslating}
                  disabled={!primaryTranslation?.line}
                >
                  Translate
                </Button>
              </Row>
            )}
          </Row>
        </>

        {secondaryLanguage && (
          <>
            <SpacerVertical small />
            <TextArea
              autocomplete="off"
              width="100%"
              autoHeight
              minRows={3}
              maxRows={10}
              label={`Line (${formatLanguage(secondaryLanguage)}) *`}
              value={secondaryTranslation?.line}
              onInput={(line: string) => setTranslation(secondaryLanguage, line)}
            />
            {hasAiLines && (
              <>
                <SpacerVertical small />
                <TextArea
                  autocomplete="off"
                  width="100%"
                  autoHeight
                  minRows={3}
                  maxRows={10}
                  label={`AI TTS prompt (${formatLanguage(
                    secondaryLanguage
                  )}) - For generating AI takes`}
                  value={aiLineSecondaryLanguage}
                  onInput={(aiLine: string) => setAiLineSecondaryLanguage(aiLine)}
                />
              </>
            )}
          </>
        )}
        <SpacerVertical />
        <TextArea
          autocomplete="off"
          width="100%"
          autoHeight
          minRows={1}
          maxRows={3}
          label="Acting notes"
          value={description}
          onInput={setDescription}
        />
        <SpacerVertical />
        <NativeSelect
          width="100%"
          label="Emotion"
          value={emotion}
          onChange={setEmotion}
          options={[
            { value: '', label: 'Neutral' },
            ...emotions.map((emotion) => ({ value: emotion, label: capitalize(emotion) }))
          ]}
        />
        <SpacerVertical />
        <TextField
          autocomplete="off"
          width="100%"
          label="Filename *"
          value={filename}
          onInput={setFilename}
          errorText={filenameError}
        />
        <SpacerVertical />

        {lineAttributes.map((attribute) => {
          const lineAttributeValue = getLineAttributeValue(attribute.id)
          return (
            <>
              <Row alignItems="center" gap="10px">
                <LineAttributeInput
                  attribute={attribute}
                  value={lineAttributeValue}
                  onChange={(newValue?: LineAttributeType) => {
                    setLineAttributeValue(attribute.id, newValue)
                  }}
                  errorText={lineAttributeErrors[attribute.id]}
                />
                {attribute.type === 'url' && (
                  <IconButton
                    icon={OpenInNewIcon}
                    disabled={!lineAttributeValue || !isValidUrl(lineAttributeValue as string)}
                    link={{
                      href: lineAttributeValue,
                      target: '_blank'
                    }}
                    tooltipText="Open link"
                    onFocus={blurActiveElement}
                  />
                )}
              </Row>
              <SpacerVertical />
            </>
          )
        })}

        {currentGame?.useExternalLineId && !currentGame.useFilenameAsExternalLineId && (
          <>
            <TextField
              autocomplete="off"
              width="100%"
              label="External line ID*"
              value={externalLineId}
              onInput={setExternalLineId}
              errorText={externalLineIdError}
            />
            <SpacerVertical />
          </>
        )}

        <Block color="var(--on-surface-light)" fontSize="14px">
          * Required field
        </Block>
      </Form>

      <Route
        path="/line/:lineId/edit-details/select-character"
        render={() => <SelectCharacter onClose={onClose} onSelect={setCharacter} />}
      />
      <Route
        path="/line/:lineId/edit-details/select-event"
        render={() => <SelectEvent onClose={onClose} onSelect={setEvent} />}
      />
      <Route
        path="/line/:lineId/edit-details/select-scene"
        render={() => <SelectScene onClose={onClose} onSelect={setScene} />}
      />

      <Route
        path="/line/:lineId/edit-details/rephrase-primary-language"
        render={() => (
          <RephraseLine
            line={line}
            language={primaryLanguage}
            originalPhrase={translations.find((t) => t.language === primaryLanguage)?.line ?? ''}
            onSave={({ newPhrase, newLines }) => {
              if (newPhrase) {
                setTranslations((translations) => {
                  return translations.map(({ language, line }) => {
                    return language === primaryLanguage
                      ? { language, line: newPhrase }
                      : { language, line }
                  })
                })
                if (hasAiLines) {
                  setAiLinePrimaryLanguage(newPhrase)
                }
              }
              if (newLines.length > 0) {
                createNewLines(newLines, primaryLanguage)
              }
            }}
          />
        )}
      />
    </BasicDialog>
  )
}
