import {
  BasicDialog,
  Checkbox,
  RadioButton,
  SpacerHorizontal,
  SpacerVertical,
  TextField
} from '@sodra/bongo-ui'

import { h } from 'preact'
import { post } from '../../api'
import { setError } from '../../actions'
import { useLocalStorageState } from '../../use-local-storage-state'
import { useState } from 'preact/hooks'
import dateFormat from 'dateformat'
import { useStore } from '../../store'
import { formatLanguage } from '../../format-language'

type DownloadAudioParams = {
  onClose: () => void
  character?: {
    name: string
  }
  event?: {
    name: string
  }
  scene?: {
    name: string
  }
  lineIds: string[]
}

export const DownloadAudio = ({
  onClose,
  character,
  event,
  scene,
  lineIds
}: DownloadAudioParams) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const { currentGame, currentLanguage } = useStore('currentGame', 'currentLanguage')

  const [allTakes, setAllTakes] = useLocalStorageState<boolean>(
    `speechless:${currentGame!.id}:lines:downloadAudio:allTakes`,
    false
  )
  const [suffixDefaultTake, setSuffixDefaultTake] = useLocalStorageState<boolean>(
    `speechless:${currentGame!.id}:lines:downloadAudio:suffixDefaultTake`,
    false
  )
  const [suffix, setSuffix] = useLocalStorageState<string>(
    `speechless:${currentGame!.id}:lines:downloadAudio:suffix`,
    '_alt##'
  )

  const handleDownload = async () => {
    setIsDownloading(true)
    try {
      const params = {
        suffix,
        suffixDefaultTake,
        allTakes,
        lineIds,
        language: currentLanguage
      }

      const { data: uri } = await post(`/games/${currentGame!.id}/takes.zip`, params)

      let filenameParts = [currentGame!.name]
      if (character) {
        filenameParts.push(character.name)
      }
      if (event) {
        filenameParts.push(event.name)
      }
      if (scene) {
        filenameParts.push(scene.name)
      }
      filenameParts.push(dateFormat('yyyy-mm-dd HH:MM'))
      const filename = filenameParts.join(' - ') + '.zip'

      onClose()
      location.href = `${import.meta.env.VITE_API_URL}/v1/download?uri=${uri}&filename=${filename}`
    } catch (err) {
      setError(err)
    } finally {
      setIsDownloading(false)
    }
  }

  const isLocalized = currentGame?.secondaryLanguages && currentGame.secondaryLanguages.length > 0
  const title = isLocalized
    ? `Export ${formatLanguage(currentLanguage)} audio files`
    : `Export audio files`

  return (
    <BasicDialog
      disableAutofocus
      action1Text="Cancel"
      onAction1Click={onClose}
      title={title}
      primaryActionText="Export"
      primaryActionLoading={isDownloading}
      onPrimaryActionClick={handleDownload}
      onClose={onClose}
    >
      <RadioButton
        checked={!allTakes}
        label="Selected takes"
        onChange={(e: any) => setAllTakes(!e.checked)}
      />
      <SpacerHorizontal />
      <RadioButton
        checked={allTakes}
        label="All takes"
        onChange={(e: any) => setAllTakes(e.checked)}
      />
      <SpacerVertical />
      <TextField width="100%" label="Filename suffix" value={suffix} onInput={setSuffix} />
      <Checkbox
        label="Use suffix for selected take"
        checked={suffixDefaultTake}
        onChange={setSuffixDefaultTake}
      />
    </BasicDialog>
  )
}
