import { Signal } from '@preact/signals'

/**
 * This is a simple helper function that can be called from signal effects,
 * when the effect should depend on the signal but signal's value is irrelevant
 * @param signal
 * @returns The signal's value
 */
export const signalDependency = (signal: Signal): any => {
  const value = signal.value
  return value
}
