import {
  Dialog,
  Link,
  List,
  ListItem,
  SpacerHorizontal,
  SpacerVertical,
  WarningIcon
} from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'
import { pluralize } from 'lib'
import { useState } from 'preact/hooks'
import { formatLanguage } from '../../format-language'
import { useStore } from 'src/store'
import { Character, Event } from 'src/types'

export type DryRunResult = {
  createdCharacters: string[]
  deletedCharacters: Character[]
  createdEvents: string[]
  deletedEvents: Event[]
  createdScenes: string[]
  createdLabels: string[]
  generatedAiTakes: { [language: string]: string[] }
  numCreatedLines: number
  numUpdatedLines: number
  numDeletedLines: number
  numRestoredLines: number
  numLinesMissingExternalLineId: number
  duplicateFilenames: string[]
  duplicateFilenamesInSource: string[]
}

type Props = {
  generateAiTakesForNewLines: boolean
  generateAiTakesForUpdatedLines: boolean
  result: DryRunResult
  onConfirm: () => void
  onCancel: () => void
}

export const ConfirmDryRun = ({
  generateAiTakesForNewLines,
  generateAiTakesForUpdatedLines,
  result,
  onConfirm,
  onCancel
}: Props) => {
  const { currentGame } = useStore('currentGame')
  const [duplicateFilanamesVisible, setDuplicateFilenamesVisible] = useState(false)
  const [duplicateFilanamesInSourceVisible, setDuplicateFilenamesInSourceVisible] = useState(false)
  const [showDeletedCharacters, setShowDeletedCharacters] = useState(false)
  const [showDeletedEvents, setShowDeletedEvents] = useState(false)

  const generateAiTakes = generateAiTakesForNewLines || generateAiTakesForUpdatedLines

  const {
    createdCharacters,
    deletedCharacters,
    createdEvents,
    deletedEvents,
    createdScenes,
    createdLabels,
    generatedAiTakes,
    numCreatedLines,
    numUpdatedLines,
    numDeletedLines,
    numRestoredLines,
    numLinesMissingExternalLineId,
    duplicateFilenames,
    duplicateFilenamesInSource
  } = result

  const totalGeneratedAiTakes = Object.values(generatedAiTakes).reduce(
    (total, takes) => total + takes.length,
    0
  )
  const isMissingAiTakes = Object.values(generatedAiTakes).some(
    (takes) => takes.length < numCreatedLines
  )

  return (
    <Dialog
      title="Please confirm"
      onClose={onCancel}
      actions={[
        { text: 'Import', onClick: onConfirm, contained: true },
        { text: 'Cancel', onClick: onCancel }
      ]}
    >
      <Block>
        <Block>
          {numCreatedLines} {pluralize('line', numCreatedLines)} will be created.
        </Block>
        {duplicateFilenames.length > 0 && (
          <>
            <SpacerVertical tiny />
            <Block color="var(--on-surface-light)">
              {duplicateFilenames.length} filename{duplicateFilenames.length !== 1 ? 's' : ''}{' '}
              already exist. <Link onClick={() => setDuplicateFilenamesVisible(true)}>Show</Link>
            </Block>
          </>
        )}
        {duplicateFilenamesInSource.length > 0 && (
          <>
            <SpacerVertical tiny />
            <Row
              color={
                currentGame?.useExternalLineId && currentGame?.useFilenameAsExternalLineId
                  ? 'var(--warning)'
                  : 'var(--on-surface-light)'
              }
              alignItems="center"
            >
              {currentGame?.useExternalLineId && currentGame?.useFilenameAsExternalLineId && (
                <>
                  <WarningIcon fill="var(--warning)" size="24" />
                  <SpacerHorizontal small />
                </>
              )}
              Source contains {duplicateFilenamesInSource.length} duplicate filename
              {duplicateFilenamesInSource.length !== 1 ? 's' : ''}. <SpacerHorizontal small />
              <Link onClick={() => setDuplicateFilenamesInSourceVisible(true)}>Show</Link>
            </Row>
          </>
        )}
        <SpacerVertical />
        <Block>
          {numUpdatedLines} {pluralize('line', numUpdatedLines)} will be updated.
        </Block>
        <SpacerVertical />
        <Block>
          {numRestoredLines} deleted {pluralize('line', numRestoredLines)} will be restored.
        </Block>
        <SpacerVertical />
        <Block color={numDeletedLines ? 'var(--warning)' : ''}>
          {numDeletedLines ?? 0} {pluralize('line', numDeletedLines ?? 0)} will be deleted.
        </Block>
        <SpacerVertical />
        {currentGame?.useExternalLineId && numLinesMissingExternalLineId > 0 && (
          <>
            <Block color={numLinesMissingExternalLineId ? 'var(--warning)' : ''}>
              {numLinesMissingExternalLineId ?? 0}{' '}
              {pluralize('line', numLinesMissingExternalLineId ?? 0)} will be ignored because of
              missing external line ID.
            </Block>
            <SpacerVertical />
          </>
        )}
        <Block>
          {createdCharacters.length} {pluralize('character', createdCharacters.length)} will be
          created.
        </Block>
        {createdCharacters.length > 0 && (
          <>
            <SpacerVertical tiny />
            <Block color="var(--on-surface-light)">{createdCharacters.join(', ')}</Block>
          </>
        )}
        <SpacerVertical />
        <Block color={deletedCharacters.length ? 'var(--warning)' : ''}>
          {deletedCharacters.length ?? 0} unused {pluralize('character', deletedCharacters?.length)}{' '}
          will be deleted.{' '}
          {deletedCharacters.length > 0 && (
            <Link onClick={() => setShowDeletedCharacters(true)}>Show</Link>
          )}
        </Block>
        <SpacerVertical />
        <Block>
          {createdEvents.length} {pluralize('event', createdEvents.length)} will be created.
        </Block>
        {createdEvents.length > 0 && (
          <>
            <SpacerVertical tiny />
            <Block color="var(--on-surface-light)">{createdEvents.join(', ')}</Block>
          </>
        )}
        <SpacerVertical />
        <Block color={deletedEvents.length ? 'var(--warning)' : ''}>
          {deletedEvents.length ?? 0} unused {pluralize('event', deletedEvents?.length)} will be
          deleted.{' '}
          {deletedEvents.length > 0 && <Link onClick={() => setShowDeletedEvents(true)}>Show</Link>}
        </Block>
        <SpacerVertical />
        <Block>
          {createdScenes.length} {pluralize('scene', createdScenes.length)} will be created.
        </Block>
        {createdScenes.length > 0 && (
          <>
            <SpacerVertical tiny />
            <Block color="var(--on-surface-light)">{createdScenes.join(', ')}</Block>
          </>
        )}
        <SpacerVertical />
        <Block>
          {createdLabels.length} {pluralize('label', createdLabels.length)} will be created.
        </Block>
        {createdLabels.length > 0 && (
          <>
            <SpacerVertical tiny />
            <Block color="var(--on-surface-light)">{createdLabels.join(', ')}</Block>
          </>
        )}
        <SpacerVertical />
        <Block>
          {totalGeneratedAiTakes} AI {pluralize('take', totalGeneratedAiTakes)} will be generated.
        </Block>
        {generateAiTakes && Object.keys(generatedAiTakes).length > 1 && (
          <>
            {Object.entries(generatedAiTakes)
              .sort(([lang1], [lang2]) =>
                formatLanguage(lang1).localeCompare(formatLanguage(lang2))
              )
              .map(([language, aiTakes]) => {
                const numSkippedLines = numCreatedLines - aiTakes.length

                return (
                  <>
                    <SpacerVertical tiny />
                    <Block color="var(--on-surface-light)">
                      {aiTakes.length} {formatLanguage(language)}{' '}
                      {pluralize('take', aiTakes.length)}
                      {numSkippedLines > 0
                        ? ` · Skipping ${numSkippedLines} ${pluralize('line', numSkippedLines)}`
                        : ''}
                    </Block>
                  </>
                )
              })}
          </>
        )}
        {generateAiTakes && isMissingAiTakes && (
          <>
            <SpacerVertical small />
            <Row color="var(--on-surface-light)" fontSize="14px" gap="10px" alignItems="top">
              <Block>
                <WarningIcon fill="var(--on-surface-light)" size="24" />
              </Block>
              <Block>
                AI takes will not be generated for all lines. Make sure that all the lines you wish
                to import contain text, and that you have assigned AI voices to your characters in
                Speechless before importing. You can also generate AI takes later.
              </Block>
            </Row>
          </>
        )}
        <SpacerVertical />
      </Block>
      {duplicateFilanamesVisible && (
        <Block position="absolute" zIndex={100}>
          <Dialog
            title="Duplicate filenames"
            actions={[
              {
                text: 'Close',
                contained: true,
                onClick: () => setDuplicateFilenamesVisible(false)
              }
            ]}
            onClose={() => setDuplicateFilenamesVisible(false)}
          >
            <List>
              {duplicateFilenames.map((filename) => {
                return <ListItem text={filename} />
              })}
            </List>
          </Dialog>
        </Block>
      )}
      {duplicateFilanamesInSourceVisible && (
        <Block position="absolute" zIndex={100}>
          <Dialog
            title="Duplicate filenames"
            actions={[
              {
                text: 'Close',
                contained: true,
                onClick: () => setDuplicateFilenamesInSourceVisible(false)
              }
            ]}
            onClose={() => setDuplicateFilenamesInSourceVisible(false)}
          >
            <List>
              {duplicateFilenamesInSource.map((filename) => {
                return <ListItem text={filename} />
              })}
            </List>
          </Dialog>
        </Block>
      )}

      {showDeletedCharacters && (
        <Block position="absolute" zIndex={100}>
          <Dialog
            title="Characters to delete"
            actions={[
              {
                text: 'Close',
                contained: true,
                onClick: () => setShowDeletedCharacters(false)
              }
            ]}
            onClose={() => setShowDeletedCharacters(false)}
          >
            <Block color="var(--on-surface-light)">
              The following {pluralize('character', deletedCharacters.length)} will be deleted
              because {deletedCharacters.length === 1 ? 'it is' : 'they are'} not used by any lines.
              <SpacerVertical />
              If you do not wish to delete any characters, please make sure that "Delete unused
              characters" is not checked in the settings of your import source.
              <SpacerVertical />
            </Block>
            <List>
              {deletedCharacters
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((character) => {
                  return <ListItem text={character.name} />
                })}
            </List>
            <SpacerVertical />
          </Dialog>
        </Block>
      )}

      {showDeletedEvents && (
        <Block position="absolute" zIndex={100}>
          <Dialog
            title="Events to delete"
            actions={[
              {
                text: 'Close',
                contained: true,
                onClick: () => setShowDeletedEvents(false)
              }
            ]}
            onClose={() => setShowDeletedEvents(false)}
          >
            <Block color="var(--on-surface-light)">
              The following {pluralize('event', deletedEvents.length)} will be deleted because{' '}
              {deletedEvents.length === 1 ? 'it is' : 'they are'} not used by any lines.
              <SpacerVertical />
              If you do not wish to delete any events, please make sure that "Delete unused events"
              is not checked in the settings of your import source.
              <SpacerVertical />
            </Block>
            <List>
              {deletedEvents
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((event) => {
                  return <ListItem text={event.name} />
                })}
            </List>
            <SpacerVertical />
          </Dialog>
        </Block>
      )}
    </Dialog>
  )
}
