import { BasicDialog, SpacerVertical } from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'
import { useEffect, useState } from 'preact/hooks'
import { fetchLineLabels, updateLines } from '../../actions'
import { useStore } from '../../store'
import { LineLabel } from '../../types'
import { Label } from '../Line/Label'
import Spinner from '../Spinner'

type Props = {
  lineIds: string[]
  onClose: () => void
  onSuccess: () => void
}

export const RemoveLabelDialog = ({ lineIds, onClose, onSuccess }: Props) => {
  const { currentGame, isUpdatingLines } = useStore('currentGame', 'isUpdatingLines')

  const [selectedLabels, setSelectedLabels] = useState<LineLabel[]>([])

  const [lineLabels, setLineLabels] = useState<LineLabel[] | undefined>(undefined)

  useEffect(() => {
    if (!currentGame) {
      return
    }
    fetchLineLabels({ lineIds }).then((lineLabels) => {
      setLineLabels(lineLabels)
    })
  }, [currentGame])

  if (lineIds.length === 0) {
    onClose()
  }

  if (!lineLabels) {
    return <Spinner />
  }

  const toggleSelected = (label: LineLabel) => {
    setSelectedLabels((selectedLabels) => {
      if (selectedLabels.some((selectedLabel) => selectedLabel.id === label.id)) {
        return selectedLabels.filter((l) => l.id !== label.id)
      }
      return [...selectedLabels, label]
    })
  }

  const handleRemove = async () => {
    if (
      await updateLines(lineIds, { remove: { labels: selectedLabels.map((label) => label.name) } })
    ) {
      onSuccess()
      onClose()
    }
  }

  return (
    <BasicDialog
      title="Remove labels"
      actions={[
        {
          text: 'Remove',
          contained: true,
          onClick: handleRemove,
          loading: isUpdatingLines,
          disabled: selectedLabels.length === 0
        },
        { text: 'Cancel', onClick: onClose }
      ]}
      onClose={onClose}
    >
      <Block>Select the labels that you want to remove</Block>
      <SpacerVertical />
      <Row alignItems="center" flexWrap="wrap" gap="10px">
        {lineLabels.map((label) => {
          return (
            <Label
              label={label.name}
              active={selectedLabels.includes(label)}
              onClick={() => toggleSelected(label)}
            />
          )
        })}
      </Row>
    </BasicDialog>
  )
}
