import { confirm } from 'lib'

type Params = {
  title?: string
  message?: string
  confirmText?: string
}

export const confirmVocalStress = ({ title, message, confirmText }: Params = {}) =>
  confirm({
    title: title || 'Confirm session duration',
    message:
      message ||
      'Session duration exceeds 2 hours. The artist will not record more than 2 hours per day of vocal stress voiceover. The artist will take a minimum of 5 minute rests every hour of recording.',
    confirmText: confirmText || 'Confirm'
  })
