import { BasicDialog, Dialog, TextArea } from '@sodra/bongo-ui'
import { useState } from 'preact/hooks'
import { setError, showSnackbar, updateLine } from 'src/actions'
import { Line } from 'src/types'

type Props = {
  line: Line
  onSave: () => void
  onClose: () => void
}

export const EditNotesDialog = ({ line, onSave, onClose }: Props) => {
  const [notes, setNotes] = useState(line.notes ?? '')
  const [isSaving, setIsSaving] = useState(false)

  const handleSave = async () => {
    setIsSaving(true)
    if (await updateLine(line.id, { notes })) {
      showSnackbar('Notes saved')
      onSave()
      onClose()
    } else {
      setError('Unable to save notes')
      setIsSaving(false)
    }
  }

  return (
    <Dialog
      title="Edit notes"
      onClose={onClose}
      actions={[
        { text: 'Save', contained: true, onClick: handleSave, loading: isSaving },
        { text: 'Cancel', onClick: onClose }
      ]}
    >
      <TextArea label="Notes" value={notes} onInput={setNotes} />
    </Dialog>
  )
}
