import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { history, Route, Router, Switch } from '@sodra/prutt'

import { get } from '../api'
import { store, useStore } from '../store'

import Spinner from './Spinner'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import { AcceptInvitation } from './AcceptInvitation'
import NotLoggedIn from './NotLoggedIn'
import LoggedIn from './LoggedIn'

import Snackbar from './Snackbar'
import Error from './Error'
import { fetchLineAttributes, setCurrentGame } from '../actions'
import { useFetchChats } from '../use-fetch-chats'
import { deleteSearchParam, setSearchParam } from '../delete-search-param'
import { fetchConfig } from '../actions/config'
import { Game } from '../types'
import { Confirm } from 'lib'
import CompleteSignUp from './CompleteSignUp'
import LoginWithToken from './LoginWithToken'
import { getItem } from 'src/storage'

const InnerApp = () => {
  const searchParams = new URLSearchParams(location.search)
  const { currentUser, currentGame, currentLanguage } = useStore(
    'currentUser',
    'currentGame',
    'currentLanguage'
  )

  const [isFetchingUser, setIsFetchingUser] = useState(true)
  const [isFetchingGames, setIsFetchingGames] = useState(false)

  useFetchChats()

  useEffect(() => {
    fetchConfig()
  }, [])

  useEffect(() => {
    if (currentGame) {
      fetchLineAttributes()
    }
  }, [currentGame?.id])

  const fetchUser = async () => {
    setIsFetchingUser(true)
    try {
      const { data: currentUser } = await get('/me')
      const email = searchParams.get('email')
      if (email && currentUser?.email !== email) {
        return
      } else {
        deleteSearchParam('email')
      }
      store.setState({ currentUser })
      await fetchGames()
    } catch (err) {
      console.log((err as Error).message)
    } finally {
      setIsFetchingUser(false)
    }
  }

  const fetchGames = async () => {
    setIsFetchingGames(true)
    try {
      const { data: games } = await get('/games')
      const gameShortId = searchParams.get('game')
      const gameIdFromLocalstorage =  getItem('speechless:currentGameId')
      let currentGame = games.find(
        (game: Game) => game.shortId === gameShortId || game.id === gameShortId || game.id === gameIdFromLocalstorage
      )
      if (!currentGame && games.length > 0) {
        currentGame = games[0]
      }
      setCurrentGame(currentGame)

      const lang = searchParams.get('lang')
      if (
        lang &&
        (currentGame.primaryLanguage === lang || currentGame.secondaryLanguages?.includes(lang))
      ) {
        store.setState({ currentLanguage: lang })
      } else {
        deleteSearchParam('lang')
      }

      store.setState({ games })
    } catch (err) {
      console.log((err as Error).message)
    } finally {
      setIsFetchingGames(false)
    }
  }

  useEffect(() => {
    if (currentGame) {
      const setGameSearchParam = () => {
        setSearchParam('game', currentGame.shortId)
        if (currentGame.name) {
          document.title = `${currentGame.name} · Speechless`
        }
      }
      setGameSearchParam()
      return history.listen(setGameSearchParam)
    }
  }, [currentGame?.id])

  useEffect(() => {
    if (currentGame && currentLanguage) {
      const setLangSearchParam = () => {
        if (currentGame.primaryLanguage !== currentLanguage) {
          setSearchParam('lang', currentLanguage)
        } else {
          deleteSearchParam('lang')
        }
      }
      setLangSearchParam()
      return history.listen(setLangSearchParam)
    }
  }, [currentGame, currentLanguage])

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    if (currentUser?.id) {
      fetchGames()
    }
  }, [currentUser?.id])

  useEffect(() => {
    const handleResize = () => {
      store.setState({ isNarrow: window.innerWidth < 800 })
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (isFetchingUser || isFetchingGames) {
    console.log('Fetching current user...')
    return <Spinner />
  }

  return (
    <>
      <Router>
        <Switch>
          <Route path="/login-with-token" component={LoginWithToken} />
          <Route path="/complete-sign-up" component={CompleteSignUp} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/accept-invitation" component={AcceptInvitation} />
          {!currentUser && <NotLoggedIn />}
          <LoggedIn />
        </Switch>
      </Router>
    </>
  )
}

/**
 * Make sure LoginWithToken is rendered before the InnerApp
 */
export const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/login-with-token" component={LoginWithToken} />
          <InnerApp />
        </Switch>
      </Router>
      <Confirm />
      <Snackbar />
      <Error />
    </>
  )
}
