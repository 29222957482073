import { h } from 'preact'
import { Block } from 'jsxstyle/preact'

type Props = {
  uri: string
  currentTime: number
  duration: number
  width?: number
  height?: number
  onClick?: (time: number, fraction?: number) => void
}

const Waveform = ({ uri, currentTime, duration, width = 100, height = 40, onClick }: Props) => {
  if (!uri) {
    return <Block width={`${width}px`} height={`${height}px`} />
  }

  const progress = duration > 0 ? Math.round((100 * currentTime) / duration) : 0

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation()
    if (onClick) {
      onClick((e.offsetX / width) * duration, e.offsetX / width)
    }
  }

  return (
    <Block
      position="relative"
      width={`${width}px`}
      height={`${height}px`}
      props={{ onClick: onClick ? handleClick : undefined }}
    >
      <img
        style={{ position: 'absolute', top: 0, left: 0 }}
        width={width}
        height={height}
        src={`${import.meta.env.VITE_WAVEFORM_URL}?uri=${uri}&width=${width * 2}&height=${
          height * 2
        }&waveformColor=819883`}
      />
      <Block
        position="absolute"
        top="0"
        left="0"
        width={`${progress}%`}
        overflow="hidden"
        borderRight={progress > 0 ? 'solid 1px var(--accent)' : ''}
      >
        <img
          width={width}
          height={height}
          src={`${import.meta.env.VITE_WAVEFORM_URL}?uri=${uri}&width=${width * 2}&height=${
            height * 2
          }&waveformColor=53f961`}
        />
      </Block>
    </Block>
  )
}

export default Waveform
