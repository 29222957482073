import { Block } from 'jsxstyle/preact'
import { formatTimeZone, getSessionStartEndDateTime } from 'lib'

type Props = {
  label: string
  timeZone: string
  start: Date
  duration: number
}

export const SessionStartEnd = ({ label, timeZone, start, duration }: Props) => {
  const startEnd = getSessionStartEndDateTime(start, duration, timeZone)

  const end = new Date(start)
  if (duration > 0) {
    end.setHours(end.getHours() + duration)
  }

  const timeZoneOffsetStart = formatTimeZone(timeZone, start)
  const timeZoneOffsetEnd = formatTimeZone(timeZone, end)

  let timeZoneOffset
  if (timeZoneOffsetStart === timeZoneOffsetEnd) {
    timeZoneOffset = timeZoneOffsetStart
  } else {
    timeZoneOffset = `${timeZoneOffsetStart}–${timeZoneOffsetEnd}`
  }

  return (
    <>
      <Block color="var(--on-surface-light)" fontSize="14px">
        {label} ({timeZoneOffset})
      </Block>
      <Block marginTop="3px">
        {startEnd.startDate}&nbsp;&nbsp;{startEnd.startTime}
        {startEnd.endTime && (
          <>
            –{startEnd.endTime}&nbsp;&nbsp;
            {startEnd.endDate}
          </>
        )}
      </Block>
    </>
  )
}
