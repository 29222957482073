import { h } from 'preact'
import { Block, InlineBlock, Row } from 'jsxstyle/preact'
import { SpacerHorizontal, SpacerVertical } from '@sodra/bongo-ui'

type Props = {
  title: string
  value: string | number
  iconNumbers?: { icon: any; value: any }[]
  wide?: boolean
  link: {
    href: string
    onRoute?: (path: string) => void
  }
}

export const StatsCard = ({ title, value, iconNumbers = [], wide = false, link }: Props) => {
  const handleClick = (e: MouseEvent) => {
    const { href, onRoute } = link
    e.stopPropagation()
    // Prevent default if no modifier key is used
    // If a modifier is used we assume that the user is trying to open
    // the link in a new browser tab
    const modifiersUsed = e.altKey || e.ctrlKey || e.metaKey || e.shiftKey
    if (!modifiersUsed && onRoute) {
      e.preventDefault()
      onRoute(href)
    }
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 13) {
      const { href, onRoute } = link
      // Enter (13)
      if (href && onRoute) {
        e.preventDefault()
        onRoute(href)
      }
    }
  }

  return (
    <InlineBlock
      component="a"
      position="relative"
      background="var(--container-background)"
      minWidth={wide ? '100%' : '200px'}
      padding="20px"
      textAlign="center"
      boxShadow="1px 1px 1px var(--box-shadow-color)"
      cursor="pointer"
      textDecoration="none"
      props={{ href: link.href, onClick: handleClick, onKeyDown: handleKeyDown }}
    >
      <Block color="var(--on-surface-light)">{title}</Block>
      <SpacerVertical />
      <Block color="var(--accent)" fontSize="100px">
        {value}
      </Block>
      <SpacerVertical large />
      <Row alignItems="center" justifyContent="space-between">
        {iconNumbers.map(({ icon: Icon, value }) => {
          return (
            <Row alignItems="center">
              <Icon fill="var(--on-surface-lighter)" />
              <SpacerHorizontal tiny />
              <InlineBlock color="var(--on-surface-lighter)">{value}</InlineBlock>
            </Row>
          )
        })}
      </Row>
    </InlineBlock>
  )
}
