export const formatDate = (dateStr?: Date, timeZone?: string) => {
  if (!dateStr) {
    return '–'
  }

  const date = new Date(dateStr)

  const dateFormat = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    day: 'numeric',
    month: 'short',
    timeZone
  })

  // https://stackoverflow.com/questions/57666095/browser-default-locale-intl-datetimeformat-vs-navigator-language
  //
  // console.log('default', Intl.DateTimeFormat().resolvedOptions().locale)
  // console.log(navigator.language)

  return dateFormat.format(date)
}

export const formatTime = (dateStr?: Date, timeZone?: string) => {
  if (!dateStr) {
    return '–'
  }

  const date = new Date(dateStr)

  const timeFormat = new Intl.DateTimeFormat('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
    hour12: false
  })

  // https://stackoverflow.com/questions/57666095/browser-default-locale-intl-datetimeformat-vs-navigator-language
  //
  // console.log('default', Intl.DateTimeFormat().resolvedOptions().locale)
  // console.log(navigator.language)

  return timeFormat.format(date)
}

export const formatTimeWithTimezone = (dateStr?: Date, timeZone?: string) => {
  if (!dateStr) {
    return '–'
  }

  const date = new Date(dateStr)

  const timeFormat = new Intl.DateTimeFormat('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'shortOffset',
    timeZone,
    hour12: false
  })

  // https://stackoverflow.com/questions/57666095/browser-default-locale-intl-datetimeformat-vs-navigator-language
  //
  // console.log('default', Intl.DateTimeFormat().resolvedOptions().locale)
  // console.log(navigator.language)

  return timeFormat.format(date)
}

export const formatScheduled = (dateStr?: Date, timeZone?: string) => {
  if (!dateStr) {
    return '–'
  }

  const date = new Date(dateStr)

  const dateTimeFormat = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'shortOffset',
    timeZone,
    hour12: false
  })

  // https://stackoverflow.com/questions/57666095/browser-default-locale-intl-datetimeformat-vs-navigator-language
  //
  // console.log('default', Intl.DateTimeFormat().resolvedOptions().locale)
  // console.log(navigator.language)

  return dateTimeFormat.format(date)
}
