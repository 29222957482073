import { h } from 'preact'
import { Block } from 'jsxstyle/preact'
import { AudioBufferVisualizer } from '../AudioBufferVisualizer'
import { useEffect, useRef, useState } from 'preact/hooks'
import { AudioSelect } from './AudioSelect'
import { useSignal } from '@preact/signals'
import { audioBufferFromURI } from '../../lib/audioBufferFromURI'
import { ColorName } from '../LineRecorder/colors'

const padding = 16 // Left and right padding

type ZoomProps = {
  factor: number
  minFactor: number
  maxFactor: number
  minCanvasHeight: number
  maxCanvasHeight: number
}

type AudioEditorProps = {
  audioBuffer?: AudioBuffer
  uri?: string
  currentTime: number
  start: number
  setStart: (start: number, isMoving: boolean) => void
  end: number
  setEnd: (end: number, isMoving: boolean) => void
  height: string
  color?: ColorName
  selectedColor?: ColorName
  progressColor?: ColorName
  progressBgColor?: ColorName
  trimLocked: boolean
  isUpdatingTake: boolean
  onPlay?: (start: number) => void
  playOffsetTime?: number
  zoom?: ZoomProps
  scrollOffsetLeft?: number
  onScroll?: (scrollLeft: number) => void
  disableVisualizerRedraw?: boolean
}

export function AudioEditor({
  audioBuffer,
  uri,
  currentTime,
  start,
  setStart,
  end,
  setEnd,
  height,
  color = '--on-surface',
  selectedColor = '--on-surface-lighter',
  progressColor = '--accent',
  progressBgColor = '--accent-light',
  trimLocked,
  isUpdatingTake,
  onPlay,
  playOffsetTime,
  zoom,
  scrollOffsetLeft = 0,
  onScroll,
  disableVisualizerRedraw
}: AudioEditorProps) {
  const [pos, setPos] = useState(0)

  const scrollRef = useRef<HTMLDivElement | undefined>(undefined)

  const audioBufferUri = useSignal<AudioBuffer | null>(null)

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = scrollOffsetLeft

      // Must check if scrolling was successful, and if not make callback to update outside scroll offset value.
      if (scrollRef.current.scrollLeft !== scrollOffsetLeft) {
        if (onScroll) {
          onScroll(scrollRef.current.scrollLeft)
        }
      }
    }
  }, [scrollOffsetLeft])

  useEffect(() => {
    if (uri) {
      audioBufferFromURI(uri).then((buffer) => {
        audioBufferUri.value = buffer
      })
    } else {
      audioBufferUri.value = null
    }
  }, [uri])

  const buffer = audioBufferUri.value ?? audioBuffer

  if (!buffer) {
    return null
  }

  const calculateZoomedHeight = () => {
    if (!zoom) {
      return 0
    }
    const zoomedHeight =
      zoom.minCanvasHeight +
      ((zoom.factor - zoom.minFactor) / (zoom.maxFactor - zoom.minFactor)) *
        (zoom.maxCanvasHeight - zoom.minCanvasHeight)

    return zoomedHeight
  }

  return (
    <Block
      position="relative"
      flex="1"
      height={height}
      class={zoom ? 'bui-show-scroll' : undefined}
      overflowX={zoom ? 'scroll' : 'hidden'}
      overflowY="hidden"
      props={{
        ref: scrollRef,
        onscroll: () => {
          if (onScroll) {
            onScroll(scrollRef.current?.scrollLeft ?? 0)
          }
        }
      }}
    >
      <AudioBufferVisualizer
        audioBuffer={buffer}
        pos={pos}
        zoom={
          zoom
            ? {
                ...zoom,
                zoomedCanvasHeight: calculateZoomedHeight()
              }
            : undefined
        }
        setPos={setPos}
        currentTime={currentTime + (playOffsetTime ? playOffsetTime - start : 0)}
        start={start}
        end={end}
        height={height}
        padding={padding}
        color={color}
        selectedBgColor={selectedColor}
        progressColor={progressColor}
        progressBgColor={progressBgColor}
        disableRedraw={disableVisualizerRedraw}
      />
      <AudioSelect
        audioBuffer={buffer}
        startTime={start}
        onChangeStart={setStart}
        endTime={end}
        onChangeEnd={setEnd}
        pos={pos}
        zoom={
          zoom
            ? {
                ...zoom,
                zoomedCanvasHeight: calculateZoomedHeight(),
                scrollOffsetLeft: scrollOffsetLeft ?? 0
              }
            : undefined
        }
        disabled={false}
        padding={padding}
        trimLocked={trimLocked}
        isUpdatingTake={isUpdatingTake}
        onPlay={onPlay}
      />
    </Block>
  )
}
