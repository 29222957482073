import { store } from '../store'
import { get, del, post, patch } from '../api'

export const fetchAPIKeys = async () => {
  try {
    store.setState({ isFetchingAPIKeys: true })
    const gameId = store.getState().currentGame?.id
    const { data: apiKeys } = await get(`/api-keys`, { gameId })
    store.setState({ apiKeys, isFetchingAPIKeys: false })
    return apiKeys
  } catch (err) {
    const error = err as Error
    store.setState({ error, isFetchingAPIKeys: false })
  }
}

export const createAPIKey = async (params: any) => {
  try {
    store.setState({ isCreatingAPIKey: true })
    const gameId = store.getState().currentGame?.id
    const { data: apiKeys } = await post(`/api-keys`, { gameId, ...params })
    store.setState({ apiKeys, isCreatingAPIKey: false })
    return apiKeys
  } catch (err) {
    const error = err as Error
    store.setState({ error, isCreatingAPIKey: false })
  }
}

export const updateAPIKey = async (apiKeyId: string, params: any) => {
  try {
    store.setState({ isUpdatingAPIKey: true })
    const { data: apiKeys } = await patch(`/api-keys/${apiKeyId}`, params)
    store.setState({ apiKeys, isUpdatingAPIKey: false })
    return apiKeys
  } catch (err) {
    const error = err as Error
    store.setState({ error, isUpdatingAPIKey: false })
  }
}

export const deleteAPIKey = async (apiKeyId: string) => {
  try {
    store.setState({ isDeletingAPIKey: true })
    const { data: apiKeys } = await del(`/api-keys/${apiKeyId}`)
    store.setState({ apiKeys, isDeletingAPIKey: false })
    return apiKeys
  } catch (err) {
    const error = err as Error
    store.setState({ error, isDeletingAPIKey: false })
  }
}
