import {
  IconButton,
  CloseIcon,
  Avatar,
  SkipPrevIcon,
  PauseIcon,
  PlayIcon,
  SkipNextIcon
} from '@sodra/bongo-ui'
import { useMatchMedia } from 'jsxstyle'
import { Block, Col, Grid, Row } from 'jsxstyle/preact'
import { AIAvatar } from './AIAvatar'
import { mainAudioPlayerSignal } from 'lib/src/hooks/use-audio-player'

export const MainAudioPlayer = () => {
  const player = mainAudioPlayerSignal.value
  if (!player) {
    return null
  }

  const { playerState, hidePlayer, play, pausePlayer, stopPlayer, nextTrack, previousTrack } =
    player

  const { currentTrack, isVisible, isPlaying } = playerState.value

  const isNarrow = useMatchMedia('(max-width: 800px)')

  const height = isNarrow ? 70 : 100

  function stringOfMaxLength(str: string, maxLength: number) {
    if (str.length <= maxLength) {
      return str
    }
    return str.slice(0, maxLength) + '…'
  }

  return (
    <Row
      position="relative"
      height={isVisible ? `${height}px` : '0px'}
      transition="height .1s ease-in-out"
    >
      {isVisible && (
        <Row
          width="100%"
          height="100%"
          background="var(--surface-alternative)"
          color="var(--on-surface)"
          position="absolute"
          top="0"
          padding={isNarrow ? '0 10px' : '0 20px'}
          alignItems="center"
          justifyContent="space-between"
        >
          {currentTrack && (
            <Row
              gap="15px"
              alignItems="center"
              overflow="hidden"
              maxWidth={isNarrow ? 'calc(100% - 150px)' : 'calc(50% - 85px)'}
            >
              <IconButton
                color="var(--on-surface-light)"
                icon={CloseIcon}
                onClick={() => {
                  stopPlayer()
                  hidePlayer()
                }}
              />
              {currentTrack.isAIVoice && (
                <AIAvatar src={currentTrack.picture} name={currentTrack.name} />
              )}
              {!currentTrack.isAIVoice && (
                <Avatar src={currentTrack.picture} name={currentTrack.name} />
              )}
              <Col overflow="hidden">
                <Block
                  fontSize={isNarrow && '16px'}
                  lineHeight={isNarrow && '18px'}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {currentTrack.name}
                </Block>
                {currentTrack.description && (
                  <Block
                    fontSize={isNarrow ? '10px' : '12px'}
                    lineHeight={isNarrow ? '12px' : '14px'}
                    color="var(--on-surface-light)"
                  >
                    {isNarrow
                      ? stringOfMaxLength(currentTrack.description, 100)
                      : stringOfMaxLength(currentTrack.description, 150)}
                  </Block>
                )}
              </Col>
            </Row>
          )}
          <Grid
            pointerEvents="none"
            placeItems={isNarrow ? 'center end' : 'center'}
            position="absolute"
            top="0"
            bottom="0"
            right="0"
            left="0"
            paddingRight={isNarrow ? '5px' : '0'}
          >
            <Row pointerEvents="all" alignItems="center" gap={isNarrow ? '5px' : '10px'}>
              <IconButton
                color="var(--on-surface)"
                icon={SkipPrevIcon}
                disabled={!playerState.value.hasPreviousTrack}
                onClick={() => previousTrack()}
              />
              {isPlaying && (
                <IconButton
                  color="var(--on-surface)"
                  outlined
                  icon={PauseIcon}
                  onClick={() => pausePlayer()}
                />
              )}
              {!isPlaying && (
                <IconButton
                  color="var(--on-surface)"
                  outlined
                  icon={PlayIcon}
                  onClick={() => play()}
                />
              )}
              <IconButton
                color="var(--on-surface)"
                icon={SkipNextIcon}
                disabled={!playerState.value.hasNextTrack}
                onClick={() => nextTrack()}
              />
            </Row>
          </Grid>
          <Block />
        </Row>
      )}
    </Row>
  )
}
