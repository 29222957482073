import { h } from 'preact'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

import { Block } from 'jsxstyle/preact'

type Props = {
  countryCode?: string
}
export const CountryFlag = ({ countryCode }: Props) => {
  if (!countryCode || countryCode.trim() === '') {
    return
  }
  return <Block fontSize="24px">{getUnicodeFlagIcon(countryCode)}</Block>
}
