import { h } from 'preact'
import { Button, Checkbox, Divider, P, SpacerVertical } from '@sodra/bongo-ui'
import { Block, Col, Inline, Row } from 'jsxstyle/preact'
import { useEffect, useMemo, useState } from 'preact/hooks'

import { get } from 'src/api'
import { LineAttribute } from 'src/types'
import { useFetchResult } from 'src/use-fetch-result'
import { WwiseSettings as WwiseSettingsType } from './create-export-target'

import { SubstitutionVariables } from './SubstitutionVariables'
import { TextFieldSuggest } from './TextFieldSuggest'
import { getPath, lineAttributeToVariable } from './substitution-variables'

export function WwiseSettings(props: {
  workUnit: string
  folderStructure: string
  createEvents: boolean
  moveExisting: boolean
  gameId: string
  onSave: (settings: WwiseSettingsType) => void
}) {
  const [workUnit, setWorkUnit] = useState<string>(props.workUnit)
  const [folderStructure, setFolderStructure] = useState<string>(props.folderStructure)
  const [createEvents, setCreateEvents] = useState<boolean>(props.createEvents)
  const [moveExisting, setMoveExisting] = useState<boolean>(props.moveExisting)

  const [loading, setLoading] = useState(false)
  const { data: lineAttributes } = useFetchResult<LineAttribute[]>(`/line-attributes`, {
    gameId: props.gameId
  })

  const [exampleLine, setExampleLine] = useState<any>()
  const [substitutionVariablesVisible, setSubstitutionVariablesVisible] = useState<string | null>(
    null
  )

  useEffect(() => {
    let ignore = false
    get(`/games/${props.gameId}/example-line`).then(({ data: exampleLine }) => {
      if (!ignore) {
        setExampleLine(exampleLine)
      }
    })
    return () => {
      ignore = true
    }
  }, [props.gameId])

  let filenameExample = useMemo(() => {
    if (!exampleLine) {
      return null
    }
    return getPath(
      `${workUnit || 'Default Work Unit'}/${folderStructure}`,
      { ...exampleLine },
      true
    )
  }, [exampleLine, folderStructure, workUnit])

  const handleSave = () => {
    setLoading(true)
    props.onSave({
      workUnit,
      folderStructure,
      createEvents,
      moveExisting
    })
  }

  const hasChanges = useMemo(() => {
    return (
      workUnit !== props.workUnit ||
      folderStructure !== props.folderStructure ||
      createEvents !== props.createEvents ||
      moveExisting !== props.moveExisting
    )
  }, [
    workUnit,
    folderStructure,
    createEvents,
    moveExisting,
    props.workUnit,
    props.folderStructure,
    props.createEvents,
    props.moveExisting
  ])

  return (
    <Col gap="20px" maxWidth="700px">
      <Col gap="5px">
        <TextFieldSuggest
          suggestions={[
            { text: '$GAME', secondaryText: 'Game name' },
            { text: '$CHARACTER', secondaryText: 'Character name' },
            { text: '$EVENT', secondaryText: 'Event name' },
            { text: '$SCENE', secondaryText: 'Scene name' },
            ...(lineAttributes?.map((attribute) => ({
              text: lineAttributeToVariable(attribute),
              secondaryText: attribute.description
            })) ?? [])
          ]}
          label="Work unit"
          value={workUnit}
          onInput={(value: string) => setWorkUnit(value.replaceAll('/', '').replaceAll('\\', ''))}
          placeholder="Default Work Unit"
        />
        <Block color="var(--on-surface-light)" paddingLeft="15px" fontSize="12px">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault()
              setSubstitutionVariablesVisible('workUnit')
            }}
          >
            substitution variables
          </a>
        </Block>
      </Col>
      <Col gap="5px">
        <TextFieldSuggest
          suggestions={[
            { text: '$GAME', secondaryText: 'Game name' },
            { text: '$CHARACTER', secondaryText: 'Character name' },
            { text: '$EVENT', secondaryText: 'Event name' },
            { text: '$SCENE', secondaryText: 'Scene name' },
            ...(lineAttributes?.map((attribute) => ({
              text: lineAttributeToVariable(attribute),
              secondaryText: attribute.description
            })) ?? [])
          ]}
          label="Folder structure"
          value={folderStructure}
          onInput={setFolderStructure}
        />
        <Block color="var(--on-surface-light)" paddingLeft="15px" fontSize="12px">
          <a
            href=""
            onClick={(e) => {
              e.preventDefault()
              setSubstitutionVariablesVisible('folderStructure')
            }}
          >
            substitution variables
          </a>
        </Block>
      </Col>

      <SpacerVertical small />
      <Divider />

      <Block fontSize="14px">
        <Inline color="var(--on-surface-light)">Example:</Inline>{' '}
        {filenameExample?.map(p => p.name).join(' \\ ')} {exampleLine && <>\ {exampleLine.filename}</>}
      </Block>
      <Divider />

      <Row>
        <Checkbox checked={createEvents} onChange={setCreateEvents} label="Create events" />
      </Row>
      <Row>
        <Checkbox checked={moveExisting} onChange={setMoveExisting} label="Move existing objects" />
      </Row>
      <SpacerVertical />
      <Row>
        <Button loading={loading} outlined={!hasChanges} contained={hasChanges} onClick={handleSave}>
          Save
        </Button>
      </Row>

      {substitutionVariablesVisible !== null && (
        <SubstitutionVariables
          onSelect={(variable) => {
            switch (substitutionVariablesVisible) {
              case 'workUnit':
                setWorkUnit((workUnit) => workUnit + variable)
                break
              case 'folderStructure':
                setFolderStructure((folder) => folder + variable)
                break
            }
            setSubstitutionVariablesVisible(null)
          }}
          onClose={() => setSubstitutionVariablesVisible(null)}
        />
      )}
    </Col>
  )
}
