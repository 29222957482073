import { h, Fragment } from 'preact'
import { InlineBlock, Block, Col, Row, Grid } from 'jsxstyle/preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import {
  AttachFileIcon,
  Avatar,
  DownloadIcon,
  Form,
  IconButton,
  InfoIcon,
  Link,
  SendIcon,
  SpacerHorizontal,
  SpacerVertical,
  TextArea
} from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

import { get, post, put, getApiUrl } from '../../api'
import { formatDuration } from '../../format-duration'
import { fetchChats, uploadFile } from '../../actions'
import { FetchingMessages } from './FetchingMessages'
import { UnreadMessageNotice } from './UnreadMessageNotice'
import { useStore } from '../../store'
import { confirm, formatDate } from 'lib'

const formatSenderName = (sender, chat) => {
  if (sender.isAdmin) {
    return `${sender.name} · Support Administrator`
  }
  if (sender.isAgencyAdmin && !sender.isVoice) {
    return `${sender.name} · Agent for ${chat.voice.name}`
  }
  return sender.name
}

const Message = ({ message, chat }) => {
  const { currentGame } = useStore('currentGame')

  const sender = message.fromVoice || message.fromUser || message.fromAdmin
  if (message.fromAdmin) {
    sender.isAdmin = true
  }

  if (message.data.type === 'text') {
    const lines = message.data.text.split('\n')

    if (!sender) {
      return (
        <Row alignItems="start">
          <Grid width="40px" height="40px" placeItems="center" flexShrink="0">
            <InfoIcon fill="var(--on-surface)" />
          </Grid>
          <SpacerHorizontal />
          <Block padding="10px">
            {lines.map((line, index) => {
              if (index > 0) {
                return (
                  <>
                    <br />
                    {line}
                  </>
                )
              }
              return line
            })}
          </Block>
        </Row>
      )
    }

    return (
      <Row alignItems="start">
        <Avatar src={sender.picture} name={sender.name} />
        <SpacerHorizontal />
        <Block padding="10px">
          <Row color="var(--on-surface-light)">
            {formatSenderName(sender, chat)}
            <SpacerHorizontal />
            <InlineBlock color="var(--on-surface-lighter)">
              {formatDuration(message.created)}
            </InlineBlock>
          </Row>
          <SpacerVertical small />
          <Block>
            {lines.map((line, index) => {
              if (index > 0) {
                return (
                  <>
                    <br />
                    {line}
                  </>
                )
              }
              return line
            })}
          </Block>
        </Block>
      </Row>
    )
  }

  if (message.type === 'session-created') {
    const { session, user } = message.data
    return (
      <Row alignItems="start">
        <Grid width="40px" height="40px" placeItems="center" flexShrink="0">
          <InfoIcon fill="var(--on-surface)" />
        </Grid>
        <SpacerHorizontal />
        <Row alignItems="center" padding="10px">
          <Block>
            {session?.voice?.name} assigned to{' '}
            <Link to={`/session/${session.id}`} onRoute={routeTo}>
              Session {session.num}
            </Link>{' '}
            by {user.name}
          </Block>
        </Row>
      </Row>
    )
  }

  if (message.type === 'chat-initiated') {
    const { user } = message.data
    return (
      <Row alignItems="start">
        <Grid width="40px" height="40px" placeItems="center" flexShrink="0">
          <InfoIcon fill="var(--on-surface)" />
        </Grid>
        <SpacerHorizontal />
        <Row alignItems="center" padding="10px">
          <Block>
            Chat initiated by {user.name}.
            <br />
            <br />
            Remember to inform the talent of your deadline for the casting/audition tapes. It’s
            important to check the talent’s availability for when you plan to record the session.
          </Block>
        </Row>
      </Row>
    )
  }

  if (message.type === 'audition-invitation' || message.type === 'audition-details') {
    const { user, details } = message.data

    let deadline = '—'
    if (details?.deadline?.numDays) {
      deadline = `within ${details?.deadline?.numDays} days`
    } else if (details?.deadline?.numHours) {
      deadline = `within ${details?.deadline?.numHours} hours`
    }

    let recordingDates = '—'
    if (details?.startDate && details?.endDate) {
      recordingDates = `${formatDate(details.startDate)} – ${formatDate(details.endDate)}`
    } else if (details?.startDate) {
      recordingDates = `starting ${formatDate(details.startDate)}`
    } else if (details?.endDate) {
      recordingDates = `ending ${formatDate(details.endDate)}`
    }

    // NOTE: Only used as fallback if message data is missing budgetSegment or rate
    const { config } = useStore('config')

    const gameBudgetSegment = currentGame.budgetSegment

    let budgetSegment = '—'
    if (details?.budgetSegment) {
      budgetSegment = details.budgetSegment
    } else {
      // Handle old version of audition-details, missing bydgetSegment
      // Try to lookup game budget segment rate
      const rateById = config?.ratesById[gameBudgetSegment]
      if (rateById) {
        budgetSegment = rateById.label
      }
    }

    let platformFee // Percentage, i.e. 0.25
    if (details?.platformFee) {
      platformFee = details?.platformFee
    } else {
      // Handle old version of audition-invitation, missing platformFee
      platformFee = config.platformFee
    }

    let cost = '—'
    if (details?.rate) {
      cost = `€${details.rate + details.rate * platformFee}`
    } else {
      // Handle old version of audition invitations, missing bydgetSegment
      // Try to lookup game budget segment rate
      const rateById = config?.ratesById[gameBudgetSegment]
      if (rateById) {
        cost = `€${rateById.rate + rateById.rate * platformFee}`
      }
    }

    let scope = '—'
    if (details?.scope) {
      scope = `${details.scope} hours`
    }

    return (
      <Row alignItems="start">
        <Grid width="40px" height="40px" placeItems="center" flexShrink="0">
          <InfoIcon fill="var(--on-surface)" />
        </Grid>
        <SpacerHorizontal />
        <Block alignItems="center" padding="10px">
          <Row color="var(--on-surface-light)">
            Invitation
            <SpacerHorizontal />
            <InlineBlock color="var(--on-surface-lighter)">
              {formatDuration(message.created)}
            </InlineBlock>
          </Row>
          <SpacerVertical small />
          <Block>
            {user.name} invited {chat.voice.name} to {currentGame.name}.
            <br />
            <br />⌚ Audition deadline: {deadline}
            <br />
            📅 Proposed recording availability: {recordingDates}
            <br />
            💵 Cost ({budgetSegment}): {cost} /hour
            <br />
            💰 Estimated scope: {scope}
            <br />
            🔧 Setup and buyout: {cost}
            <br />
            <br />
            Remember to inform the talent of your deadline for the casting/audition tapes. It’s
            important to check the talent’s availability for when you plan to record the session.
          </Block>
        </Block>
      </Row>
    )
  }

  if (message.type === 'session-updated') {
    const { session, user } = message.data
    return (
      <Row alignItems="start">
        <Grid width="40px" height="40px" placeItems="center" flexShrink="0">
          <InfoIcon fill="var(--on-surface)" />
        </Grid>
        <SpacerHorizontal />
        <Row alignItems="center" padding="10px">
          <Block>
            <Link to={`/session/${session.id}`} onRoute={routeTo}>
              Session {session.num}
            </Link>{' '}
            updated by {user.name}
          </Block>
        </Row>
      </Row>
    )
  }

  if (message.type === 'session-deleted') {
    const { session, user } = message.data
    return (
      <Row alignItems="start">
        <Grid width="40px" height="40px" placeItems="center" flexShrink="0">
          <InfoIcon fill="var(--on-surface)" />
        </Grid>
        <SpacerHorizontal />
        <Row alignItems="center" padding="10px">
          <Block>
            Session {session.num} deleted by {user.name}
          </Block>
        </Row>
      </Row>
    )
  }

  if (message.data.type === 'session-accepted') {
    const { session, voice } = message.data
    return (
      <Row alignItems="start">
        <Grid width="40px" height="40px" placeItems="center" flexShrink="0">
          <InfoIcon fill="var(--on-surface)" />
        </Grid>
        <SpacerHorizontal />
        <Row alignItems="center" padding="10px">
          <Block>
            <Link to={`/session/${session.id}`} onRoute={routeTo}>
              Session {session.num}
            </Link>{' '}
            accepted by {voice.name}.
          </Block>
        </Row>
      </Row>
    )
  }

  if (message.data.type === 'session-declined') {
    const { session, voice } = message.data
    return (
      <Row alignItems="start">
        <Grid width="40px" height="40px" placeItems="center" flexShrink="0">
          <InfoIcon fill="var(--on-surface)" />
        </Grid>
        <SpacerHorizontal />
        <Row alignItems="center" padding="10px">
          <Block>
            <Link to={`/session/${session.id}`} onRoute={routeTo}>
              Session {session.num}
            </Link>{' '}
            declined by {voice.name}
          </Block>
        </Row>
      </Row>
    )
  }

  if (message.type === 'file-upload') {
    return (
      <Row alignItems="start">
        <Avatar src={sender.picture} name={sender.name} />
        <SpacerHorizontal />
        <Block padding="10px">
          <Row color="var(--on-surface-light)">
            {sender.name}
            <SpacerHorizontal />
            <InlineBlock color="var(--on-surface-lighter)">
              {formatDuration(message.created)}
            </InlineBlock>
          </Row>
          <SpacerVertical small />
          {message.data.contentType?.startsWith('image') && (
            <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={message.data.uri} />
          )}
          {message.data.contentType?.startsWith('audio') && (
            <audio controls src={message.data.uri} type={message.data.contentType} />
          )}
          <Row alignItems="center">
            <a target="_blank" href={message.data.uri}>
              {message.data.name}
            </a>
            <SpacerHorizontal small />
            <IconButton
              small
              color="var(--on-surface-light)"
              icon={DownloadIcon}
              onClick={() => {
                location.href = `${import.meta.env.VITE_API_URL}/v1/download?uri=${
                  message.data.uri
                }&filename=${message.data.name}`
              }}
            />
          </Row>
        </Block>
      </Row>
    )
  }

  return null
}

const sortMessages = (a, b) => {
  if (a.seq < b.seq) return -1
  if (a.seq > b.seq) return 1
  return 0
}

export const Chat = ({ chat, onMessage }) => {
  const { appHasFocus } = useStore('appHasFocus')

  const [hasUnread, setHasUnread] = useState(false)
  const [messages, setMessages] = useState([])
  const [isFetchingMessages, setIsFetchingMessages] = useState(false)
  const [text, setText] = useState('')
  const [shouldScrollDown, setShouldScrollDown] = useState(false)
  const [isUploadingFile, setIsUploadingFile] = useState(false)

  const ref = useRef()
  const fileInput = useRef()

  const chatId = chat?.id

  useEffect(() => {
    setIsFetchingMessages(true)
    get(`/chats/${chat.id}/messages`)
      .then(({ meta, data }) => {
        //setHasUnread(meta.hasUnread)
        setMessages(data)
        setShouldScrollDown(true)
      })
      .finally(() => {
        setIsFetchingMessages(false)
      })
  }, [chatId])

  useEffect(() => {
    if (shouldScrollDown) {
      scrollToBottom()
      setShouldScrollDown(false)
    }
  }, [shouldScrollDown])

  useEffect(() => {
    if (messages.length > 0 && onMessage) {
      onMessage(messages[messages.length - 1])
    }
  }, [messages])

  const fetchMessages = () => {
    const afterSeq = messages.length > 0 ? messages[messages.length - 1].seq : 0
    setIsFetchingMessages(true)
    get(`/chats/${chat.id}/messages`, { afterSeq })
      .then(({ meta, data: newMessages }) => {
        setHasUnread(meta.hasUnread)
        if (newMessages.length > 0) {
          setMessages((messages) => {
            const uniqueNewMessages = newMessages.filter((newMessage) =>
              messages.every((message) => message.seq !== newMessage.seq)
            )
            if (uniqueNewMessages.length > 0) {
              return [...messages, ...uniqueNewMessages].sort(sortMessages)
            }
            return messages
          })
        }
      })
      .finally(() => {
        setIsFetchingMessages(false)
      })
  }

  useEffect(() => {
    const interval = setInterval(fetchMessages, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [messages])

  const updateLastRead = async () => {
    if (appHasFocus && messages?.length > 0) {
      setHasUnread(false)
      const lastMessage = messages[messages.length - 1]
      await put(`/chats/${chatId}/last-read`, { messageId: lastMessage.id, seq: lastMessage.seq })
      await fetchChats()
    }
  }

  const [atBottom, setAtBottom] = useState()

  const updateScroll = () => {
    if (ref.current) {
      const bottomOffset =
        ref.current.scrollTop + ref.current.offsetHeight - ref.current.scrollHeight
      setAtBottom(Math.abs(bottomOffset) < 10)
    }
  }

  const handleScroll = (e) => {
    updateScroll()
  }

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }

  useEffect(() => {
    if (atBottom && appHasFocus) {
      const timeout = setTimeout(() => {
        updateLastRead()
      }, 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [atBottom, appHasFocus])

  useEffect(() => {
    updateScroll()
    if (messages && appHasFocus && ref.current && atBottom) {
      ref.current.scrollTop = ref.current.scrollHeight
      const timeout = setTimeout(() => {
        updateLastRead()
      }, 1000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [ref.current, messages, atBottom, appHasFocus])

  const containsEmail = (text = '') => {
    return /[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/.test(text)
  }

  const postMessage = async () => {
    if (text.trim() === '') {
      return
    }
    if (
      containsEmail(text) &&
      !(await confirm({
        message: `It looks like you are typing an e-mail address in the chat. It is recommended to keep all communication within the platform. Do you still want to send the message?`,
        confirmText: 'Send message'
      }))
    ) {
      return
    }

    setText('')

    fetchMessages()

    const { data: newMessage } = await post(`/chats/${chat.id}/messages`, {
      data: {
        type: 'text',
        text
      }
    })

    setMessages((previousMessages) => {
      if (!previousMessages.some((message) => message.seq === newMessage.seq)) {
        return [...previousMessages, newMessage].sort(sortMessages)
      }
      return previousMessages
    })

    setShouldScrollDown(true)
  }

  const handleFileUpload = async () => {
    setIsUploadingFile(true)
    try {
      const file = fileInput.current.files[0]

      const gameFile = await uploadFile(file, { filename: file.name, chatId })

      const { data: message } = await post(`/chats/${chatId}/messages`, {
        data: {
          type: 'file-upload',
          name: gameFile.filename,
          uri: gameFile.uri,
          contentType: gameFile.contentType
        }
      })

      setMessages((messages) => {
        return [...messages, message].sort(sortMessages)
      })

      setShouldScrollDown(true)
    } catch (err) {
      console.warn(err)
    } finally {
      setIsUploadingFile(false)
    }
  }

  return (
    <Col height="100%" overflow="hidden">
      <Col
        position="relative"
        flex="1"
        overflow="hidden"
        justifyContent="flex-end"
        background="var(--container-background)"
        borderRadius="3px"
      >
        <Block
          props={{
            ref,
            onScroll: handleScroll
          }}
          padding="10px"
          overflowY="auto"
        >
          {messages.map((message, index) => {
            if (index > 0) {
              return (
                <Fragment>
                  <SpacerVertical small />
                  <Message key={message.id} chat={chat} message={message} />
                </Fragment>
              )
            }
            return <Message key={message.id} chat={chat} message={message} />
          })}
        </Block>
        {hasUnread && <UnreadMessageNotice onClick={scrollToBottom} />}
        {isFetchingMessages && <FetchingMessages />}
      </Col>
      <SpacerVertical />
      <Form onSubmit={postMessage}>
        <Row gap={10} alignItems="center">
          <IconButton icon={AttachFileIcon} onClick={() => fileInput.current?.click()} />
          <TextArea
            placeholder="Chat message"
            value={text}
            onInput={setText}
            onEnterSubmit={postMessage}
            flex={1}
            autoHeight
            maxRows={10}
          />
          <IconButton
            name="send-chat-message"
            type="submit"
            icon={SendIcon}
            disabled={text.trim() === ''}
          />
        </Row>
      </Form>
      <input type="file" ref={fileInput} onChange={handleFileUpload} style={{ display: 'none' }} />
    </Col>
  )
}
