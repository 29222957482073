import { Line, Take } from '../../types'

export const isTakeOutdated = (take: Take, line: Line, language: string): boolean => {
  // If the take does not have any line text, there is no way of knowing if it is outdated
  if (!take.line) {
    return false
  }

  return take.line !== line?.translations[language]?.line
}
