type Params = {
  url: string
  width: number
  height: number
  fit?: string
}

export const getCropperUrl = ({ url, width, height, fit = 'contain' }: Params) => {
  const dpr = window.devicePixelRatio || 1
  const params = {
    url,
    width: Math.floor(width * dpr),
    height: Math.floor(height * dpr),
    fit
  }
  const encoded = window.btoa(JSON.stringify(params))
  if (import.meta.env.VITE_CROPPER_URL) {
    return `${import.meta.env.VITE_CROPPER_URL}/${encoded}`
  } else {
    return url
  }
}
