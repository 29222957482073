import { h } from 'preact'
import { AddIcon, SpacerVertical } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import { useEffect, useState } from 'preact/hooks'

import { fetchSessions } from '../../actions'
import { useUrlParams } from '../../use-url-params'

import Page from '../Page'
import Spinner from '../Spinner'
import SearchTextField from '../SearchTextField'

import SessionsTable from './SessionsTable'
import { Block, Col } from 'jsxstyle/preact'
import { useStore } from '../../store'

const pageSize = 10

export const Sessions = () => {
  const {
    currentGame,
    sessions,
    totalSessions = 0,
    isFetchingSessions
  } = useStore('currentGame', 'sessions', 'totalSessions', 'isFetchingSessions')
  const params = new URLSearchParams(location.search)

  const [query, setQuery] = useState(params.get('query') || '')
  const [page, setPage] = useState(parseFloat(params.get('page') || '0'))
  const [orderBy, setOrderBy] = useState(params.get('orderBy') || 'name')
  const [sortOrder, setSortOrder] = useState(params.get('sortOrder') || 'asc')

  useUrlParams(
    { page, query, orderBy, sortOrder },
    { page: 0, query: '', orderBy: 'name', sortOrder: 'asc' }
  )

  useEffect(() => {
    const now = new Date()
    now.setUTCHours(now.getUTCHours() - 1)
    fetchSessions({
      offset: page * pageSize,
      limit: pageSize,
      query,
      //scheduledAfter: now,
      sortOrder,
      orderBy
    })
  }, [currentGame!.id, page, query, sortOrder, orderBy])

  if (!sessions) {
    return <Spinner />
  }

  return (
    <Page
      title="Sessions"
      actions={[
        {
          label: 'Create session',
          icon: AddIcon,
          onClick: () => routeTo('/create-session')
        }
      ]}
    >
      <Col height="100%">
        <SearchTextField
          value={query}
          onChange={(query) => {
            setQuery(query)
            setPage(0)
          }}
        />
        <SpacerVertical />
        <SessionsTable
          loading={!!isFetchingSessions}
          sessions={sessions}
          total={totalSessions}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
      </Col>
    </Page>
  )
}

export default Sessions
