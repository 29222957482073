import { h } from 'preact'
import { Block, Row } from 'jsxstyle/preact'
import {
  AccountCircleIcon,
  BarChartIcon,
  ChatBubbleIcon,
  CloudUploadIcon,
  Divider,
  FaceIcon,
  ImageIcon,
  RecordVoiceOverIcon,
  List,
  ListItem,
  LogoutIcon,
  SettingsIcon,
  SpacerVertical,
  StarIcon,
  VoiceIcon,
  EditNoteIcon,
  FolderOpenIcon,
  MemoryIcon,
  CloudDownloadIcon,
  ReceiptIcon
} from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

import { logout } from '../actions'

import { useStore } from '../store'

type MenuListItemProps = {
  active?: boolean
  icon?: any
  text?: any
  link?: {
    href: string
    onRoute: (path: string, options?: any) => void
  }
  onClick?: () => void
}

const MenuListItem = ({ active, ...props }: MenuListItemProps) => {
  return (
    <ListItem
      active={active}
      borderLeft={active ? 'solid 4px var(--accent)' : 'solid 4px transparent'}
      {...props}
    />
  )
}

export const LeftMenuContent = () => {
  const { currentGame, chats = [] } = useStore('currentGame', 'chats')

  if (!currentGame) {
    return (
      <Block>
        <SpacerVertical small />
        <List>
          <MenuListItem
            active={location.pathname.startsWith('/account')}
            icon={AccountCircleIcon}
            text="My account"
            link={{
              href: '/account',
              onRoute: routeTo
            }}
          />
          <MenuListItem icon={LogoutIcon} text="Logout" onClick={logout} />
        </List>
        <SpacerVertical small />
      </Block>
    )
  }

  return (
    <Block>
      <SpacerVertical small />
      <List>
        <MenuListItem
          active={location.pathname === '/'}
          icon={BarChartIcon}
          text="Dashboard"
          link={{
            href: `/?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
        <MenuListItem
          active={location.pathname.startsWith('/voice')}
          icon={VoiceIcon}
          text="Voice library"
          link={{
            href: `/voices?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
        <MenuListItem
          active={
            location.pathname.startsWith('/ai-voice') ||
            location.pathname.startsWith('/create-ai-voice')
          }
          icon={MemoryIcon}
          text="AI Voices"
          link={{
            href: `/ai-voices?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
      </List>
      <SpacerVertical small />
      <Divider />
      <SpacerVertical small />
      <List>
        <MenuListItem
          active={
            location.pathname.startsWith('/character') ||
            location.pathname.startsWith('/create-character')
          }
          icon={FaceIcon}
          text="Characters"
          link={{
            href: `/characters?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
        <MenuListItem
          active={
            location.pathname.startsWith('/event') || location.pathname.startsWith('/create-event')
          }
          icon={StarIcon}
          text="Events"
          link={{
            href: `/events?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
        <MenuListItem
          active={
            location.pathname.startsWith('/scene') || location.pathname.startsWith('/create-scene')
          }
          icon={ImageIcon}
          text="Scenes"
          link={{
            href: `/scenes?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
        <MenuListItem
          active={
            location.pathname.startsWith('/line') ||
            location.pathname.startsWith('/create-line') ||
            location.pathname.startsWith('/upload-excel') ||
            location.pathname.startsWith('/upload-audio')
          }
          icon={EditNoteIcon}
          text="Lines"
          link={{
            href: `/lines?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
        <MenuListItem
          active={
            location.pathname.startsWith('/session') ||
            location.pathname.startsWith('/create-session')
          }
          icon={RecordVoiceOverIcon}
          text="Sessions"
          link={{
            href: `/sessions?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
        <MenuListItem
          active={location.pathname.startsWith('/chat')}
          icon={ChatBubbleIcon}
          text={
            <Row alignItems="center">
              <Block>Chat</Block>
              {chats.some((chat) => chat.hasUnread) && (
                <Block
                  width="10px"
                  height="10px"
                  borderRadius="50%"
                  background="var(--error)"
                  marginLeft="3px"
                  marginBottom="10px"
                ></Block>
              )}
            </Row>
          }
          link={{
            href: `/chats?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
        <MenuListItem
          active={
            location.pathname.startsWith('/file') || location.pathname.startsWith('/upload-file')
          }
          icon={FolderOpenIcon}
          text="Files"
          link={{
            href: `/files?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
      </List>
      <SpacerVertical small />
      <Divider />
      <SpacerVertical small />
      <List>
        <MenuListItem
          active={
            location.pathname.startsWith('/export-target') ||
            location.pathname.startsWith('/create-export-target')
          }
          icon={CloudUploadIcon}
          text="Export targets"
          link={{
            href: `/export-targets?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
        <MenuListItem
          active={
            location.pathname.startsWith('/import-sources') ||
            location.pathname.startsWith('/create-import-source')
          }
          icon={CloudDownloadIcon}
          text="Import sources"
          link={{
            href: `/import-sources?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
        <MenuListItem
          active={location.pathname.startsWith('/settings')}
          icon={SettingsIcon}
          text="Settings"
          link={{
            href: `/settings?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
        <MenuListItem
          active={location.pathname.startsWith('/orders')}
          icon={ReceiptIcon}
          text="Orders"
          link={{
            href: `/orders?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
      </List>
      <SpacerVertical small />
      <Divider />
      <SpacerVertical small />
      <List>
        <MenuListItem
          active={location.pathname.startsWith('/account')}
          icon={AccountCircleIcon}
          text="My account"
          link={{
            href: `/account?game=${currentGame.shortId}`,
            onRoute: routeTo
          }}
        />
        <MenuListItem icon={LogoutIcon} text="Logout" onClick={logout} />
      </List>
      <SpacerVertical small />
    </Block>
  )
}

export default LeftMenuContent
