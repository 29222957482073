import { Block } from 'jsxstyle/preact'
import { Actor, Take } from '../../types'
import { AIAvatar } from '../AIAvatar'
import Avatar from '../Avatar'

type UploaderProps = {
  take: Take
  size?: number
}

export const TakeUploader = ({ take, size = 24 }: UploaderProps) => {
  let uploader: Actor | undefined
  if (take?.aiVoice) {
    uploader = take.aiVoice
  } else if (take.voice) {
    uploader = take.voice
  } else if (take.user) {
    uploader = take.user
  }
  return (
    <Block props={{ title: uploader?.name }}>
      {take.aiVoice && <AIAvatar src={uploader?.picture} name={uploader?.name} size={size} />}
      {!take.aiVoice && <Avatar src={uploader?.picture} name={uploader?.name} size={size} />}
    </Block>
  )
}
