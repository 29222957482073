import { BasicDialog, Form, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'
import { Row } from 'jsxstyle/preact'
import { useEffect, useState } from 'preact/hooks'
import { fetchLineLabels, updateLineLabels } from '../../actions'
import { useStore } from '../../store'
import { Label } from './Label'

type Props = {
  lineId: string
}

export const AddLabelDialog = ({ lineId }: Props) => {
  const { lineLabels = [], ...store } = useStore('line', 'lineLabels')
  const line = store.line!

  const [label, setLabel] = useState('')
  const [labelError, setLabelError] = useState('')

  useEffect(() => {
    fetchLineLabels()
  }, [])

  const onClose = () => goBack(`/line/${lineId}`)

  const addLabel = async (label: string) => {
    if (await updateLineLabels([...line.labels, label])) {
      onClose()
    }
  }

  const handleAdd = async () => {
    if (label.trim() === '') {
      setLabelError('Please enter a label name')
      return
    }
    if (await updateLineLabels([...line.labels, label])) {
      onClose()
    }
  }

  return (
    <BasicDialog
      title="Add label"
      actions={[
        { text: 'Add', contained: true, onClick: handleAdd },
        { text: 'Cancel', onClick: onClose }
      ]}
      onClose={onClose}
    >
      <Row alignItems="center" flexWrap="wrap" gap="10px">
        {lineLabels.map((label) => {
          const isSelected = line.labels.includes(label.name)
          return (
            <Label
              label={label.name}
              active={!isSelected}
              disabled={isSelected}
              onClick={() => addLabel(label.name)}
            />
          )
        })}
      </Row>
      <SpacerVertical />
      <Form onSubmit={handleAdd}>
        <TextField
          autoComplete="off"
          width="100%"
          label="Label"
          value={label}
          onInput={setLabel}
          errorText={labelError}
        />
      </Form>
    </BasicDialog>
  )
}
