import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { goBack, Route, routeTo, Switch } from '@sodra/prutt'

import { fetchAIVoice, updateAIVoice } from '../../actions'
import { useEffectSkipFirst } from '../../use-effect-skip-first'

import Spinner from '../Spinner'

import DeleteAIVoice from './DeleteAIVoice'
import { useStore } from '../../store'
import { EditGoogleDetails } from './EditGoogleDetails'
import { EditElevenlabsDetails } from './EditElevenlabsDetails'
import PictureDialog from '../PictureDialog'
import EditPlayHTDetails from './EditPlayHTDetails'
import EditAzureTTSDetails from './EditAzureTTSDetails'

type Props = {
  aiVoiceId: string
}

const Character = ({ aiVoiceId }: Props) => {
  const { currentGame, aiVoice } = useStore('currentGame', 'aiVoice')

  const [picture, setPicture] = useState<string | undefined>()

  // Handle switch game
  useEffectSkipFirst(() => {
    routeTo('/ai-voices', true)
  }, [currentGame!.id])

  useEffect(() => {
    fetchAIVoice(aiVoiceId)
  }, [aiVoiceId])

  useEffect(() => {
    if (aiVoice) {
      setPicture(aiVoice.picture)
    }
  }, [aiVoice])

  const updatePicture = async (url: string) => {
    await updateAIVoice({ picture: url })
    setPicture(url)
    return true
  }

  const removePicture = async () => {
    await updateAIVoice({ picture: '' })
    setPicture(undefined)
    return true
  }

  if (!aiVoice || aiVoice.id !== aiVoiceId) {
    return <Spinner />
  }

  return (
    <>
      <Switch>
        <Route path="/ai-voice/:aiVoiceId/delete" component={DeleteAIVoice} />
        {aiVoice.type === 'google' && <EditGoogleDetails />}
        {aiVoice.type === 'elevenlabs' && <EditElevenlabsDetails />}
        {aiVoice.type === 'playht' && <EditPlayHTDetails />}
        {aiVoice.type === 'azuretts' && <EditAzureTTSDetails />}
      </Switch>{' '}
      <Route
        path="/ai-voice/:aiVoiceId/picture"
        render={() => (
          <PictureDialog
            title="AI voice picture"
            url={picture}
            name={aiVoice.name}
            onUpdate={updatePicture}
            onRemove={removePicture}
            onClose={() => goBack(`/ai-voice/${aiVoice.id}`)}
          />
        )}
      />
    </>
  )
}

export default Character
