import { store } from '../store'
import { get } from '../api'

export const fetchShortlistedVoices = async () => {
  try {
    store.setState({ isFetchingShortlistedVoices: true })
    const gameId = store.getState().currentGame?.id
    const { data: shortlistedVoices } = await get(`/games/${gameId}/shortlisted-voices`)
    store.setState({ shortlistedVoices, isFetchingShortlistedVoices: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isFetchingShortlistedVoices: false })
  }
}
