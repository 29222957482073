import { useCallback, useEffect, useState } from 'preact/hooks'

type Node = {
  base: Element
}

export const useResizeObserver = (onResize: Function, dependencies: any[] = []) => {
  const [node, setNode] = useState<Node>()

  useEffect(() => {
    if (node) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (onResize) {
          onResize(entries[0].contentRect)
        }
      })
      resizeObserver.observe(node.base)
      return () => resizeObserver.unobserve(node.base)
    }
  }, [node, ...dependencies])

  return useCallback(setNode, [])
}
