import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Row } from 'jsxstyle/preact'
import {
  Button,
  EditIcon,
  Form,
  SpacerHorizontal,
  SpacerVertical,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { createScene } from '../actions'

import Page from './Page'
import Avatar from './Avatar'
import PictureDialog from './PictureDialog'
import { useStore } from '../store'
import { SuperForm } from './SuperForm'

export const CreateScene = () => {
  const { isCreatingScene } = useStore('isCreatingScene')
  const [picture, setPicture] = useState('')

  const onBack = () => goBack('/scenes')

  const handleSubmit = async (values: any) => {
    if (await createScene({ ...values, picture })) {
      onBack()
    }
  }

  return (
    <Page title="Create scene" onBack={onBack}>
      <Avatar
        actionIcon={EditIcon}
        onActionClick={() => routeTo('create-scene/picture')}
        size={150}
        src={picture}
        name={name}
      />
      <SpacerVertical />
      <SuperForm
        onSubmit={handleSubmit}
        onCancel={onBack}
        loading={isCreatingScene}
        elements={[
          { element: 'TextField', label: 'Name', name: 'name', value: '', required: true },
          { element: 'TextArea', label: 'Description', name: 'description', value: '' }
        ]}
      />

      <Route
        path="/create-scene/picture"
        render={() => (
          <PictureDialog
            title="Scene picture"
            url={picture}
            onUpdate={(url: string) => {
              setPicture(url)
              return true
            }}
            onRemove={() => {
              setPicture('')
              return true
            }}
            onClose={() => goBack('/create-scene')}
          />
        )}
      />
    </Page>
  )
}
