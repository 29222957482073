import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import { goBack, Route, routeTo } from '@sodra/prutt'
import {
  Button,
  Checkbox,
  EditIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  Form,
  NativeSelect,
  Select,
  SpacerHorizontal,
  SpacerVertical,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { useSignal } from '@preact/signals'

import Avatar from './Avatar'
import Page from './Page'
import { createGame, showSnackbar } from '../actions'
import PictureDialog from './PictureDialog'
import { genGameId } from '../gen-game-id'
import { get } from '../api'
import { useStore } from '../store'
import * as languages from '../languages'
import { formatLanguage } from '../format-language'
import { HelpExternalLineId } from './HelpExternalLineId'
import { TimeZoneSelectButton } from './TimeZoneSelectButton'
import { TimeZoneSelectDialog } from './TimeZoneSelectDialog'

const NewGame = () => {
  const { isCreatingGame } = useStore('isCreatingGame')
  const [name, setName] = useState('')
  const [shortId, setShortId] = useState('')
  const [editShortId, setEditShortId] = useState(false)
  const [description, setDescription] = useState('')
  const [picture, setPicture] = useState('')
  const [budgetSegment, setBudgetSegment] = useState<string | undefined>(undefined)
  const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const [gameStudio, setGameStudio] = useState('')
  const [publisher, setPublisher] = useState('')
  const [primaryLanguage, setPrimaryLanguage] = useState('en')

  const [nameError, setNameError] = useState('')
  const budgetSegmentError = useSignal('')
  const gameStudioError = useSignal('')
  const timeZoneError = useSignal('')

  const [shortIdError, setShortIdError] = useState('')
  const [isCheckingShortId, setIsCheckingShortId] = useState(false)
  const [shortIdAvailable, setShortIdAvailable] = useState(false)

  const [useExternalLineId, setUseExternalLineId] = useState<boolean>(false)
  const [isExternalLineIdHelpVisible, setIsExternalLineIdHelpVisible] = useState(false)
  const [useFilenameAsExternalLineId, setUseFilenameAsExternalLineId] = useState<boolean>(false)

  useEffect(() => {
    if (shortId) {
      setIsCheckingShortId(true)
      const checkShortIdAvailable = async () => {
        return get('/game-id-available', { shortId })
          .then(({ data: shortIdAvailable }) => {
            setShortIdAvailable(shortIdAvailable)
            if (shortIdAvailable) {
              setShortIdError('')
            } else {
              setShortIdError('Please enter a unique Game ID')
            }
          })
          .catch(() => setShortIdError('Unable to verify short id'))
          .finally(() => setIsCheckingShortId(false))
      }
      const timeout = setTimeout(checkShortIdAvailable, 1000)
      return () => clearTimeout(timeout)
    }
  }, [shortId])

  useEffect(() => {
    if (!editShortId) {
      setShortId(genGameId(name))
    }
  }, [name, editShortId])

  const onBack = () => goBack('/')

  const handleSubmit = async () => {
    setNameError('')
    setShortIdError('')
    budgetSegmentError.value = ''
    gameStudioError.value = ''
    timeZoneError.value = ''

    let hasErrors = false

    if (name.trim() === '') {
      setNameError('Please enter a name')
      hasErrors = true
    }
    if (!budgetSegment) {
      budgetSegmentError.value = 'Please select a budget segment'
      hasErrors = true
    }
    if (gameStudio.trim() === '') {
      gameStudioError.value = 'Please enter game studio'
      hasErrors = true
    }
    if (!timeZone) {
      timeZoneError.value = 'Please select a time zone'
      hasErrors = true
    }
    if (!shortIdAvailable) {
      setShortIdError('Please enter a unique Game ID')
      hasErrors = true
    }
    if (!/^[\w-]+$/.test(shortId)) {
      setShortIdError('Please enter valid Game ID')
      hasErrors = true
    }

    if (hasErrors) {
      return
    }

    const game = await createGame({
      name,
      shortId,
      description,
      picture,
      budgetSegment,
      timeZone,
      gameStudio,
      publisher,
      primaryLanguage,
      useExternalLineId,
      useFilenameAsExternalLineId
    })
    if (game) {
      showSnackbar(`Game ${game.name} created`)
      onBack()
    }
  }

  const showExternalLineIdHelp = () => {
    setIsExternalLineIdHelpVisible(true)
  }
  const hideExternalLineIdHelp = () => {
    setIsExternalLineIdHelpVisible(false)
  }

  return (
    <Page title="New game" onBack={onBack}>
      <Form onSubmit={handleSubmit} maxWidth="500px">
        <Avatar
          actionIcon={EditIcon}
          onActionClick={() => routeTo('/new-game/picture')}
          size={150}
          src={picture}
          name={name}
          actionName="edit-game-picture"
        />
        <SpacerVertical />
        <TextField
          width="100%"
          autocomplete="off"
          label="Name"
          value={name}
          onInput={setName}
          errorText={nameError}
        />
        {editShortId && (
          <Fragment>
            <SpacerVertical />
            <TextField
              loading={isCheckingShortId}
              width="100%"
              autocomplete="off"
              label="Game ID"
              value={shortId}
              onInput={setShortId}
              errorText={shortIdError}
            />
          </Fragment>
        )}
        {!editShortId && shortId && (
          <Row alignItems="center" fontSize="12px" color="var(--on-surface-light)">
            <Block>
              Game ID: <strong>{shortId}</strong>. It cannot be changed later.
            </Block>
            <Button tiny onClick={() => setEditShortId(true)}>
              Edit
            </Button>
          </Row>
        )}
        <SpacerVertical />
        <TextArea
          width="100%"
          autocomplete="off"
          label="Description (optional)"
          value={description}
          onInput={setDescription}
        />
        <SpacerVertical />
        <NativeSelect
          label="Game primary language"
          value={primaryLanguage}
          onChange={setPrimaryLanguage}
          options={languages
            .getCodes()
            .map((code: string) => {
              return { value: code, label: formatLanguage(code) }
            })
            .sort((option1, option2) => option1.label.localeCompare(option2.label))}
        />
        <SpacerVertical />
        <Select
          label="Game budget segment"
          value={budgetSegment}
          onChange={setBudgetSegment}
          options={[
            { text: 'AAA (more than €5M)', value: 'aaa' },
            { text: 'Indie (betweeen €0.5–€5M)', value: 'indie' },
            { text: 'Micro (less than €0.5M)', value: 'micro' }
          ]}
          infoText="Note: this can't be changed later"
          errorText={budgetSegmentError.value}
        />
        <SpacerVertical />
        <TimeZoneSelectButton
          label="Game time zone"
          timeZone={timeZone}
          onClick={() => routeTo('/new-game/time-zone')}
          errorText={timeZoneError.value}
        />
        <SpacerVertical />
        <TextField
          width="100%"
          label="Game studio"
          value={gameStudio}
          onInput={setGameStudio}
          errorText={gameStudioError.value}
        />
        <SpacerVertical />
        <TextField
          width="100%"
          autocomplete="off"
          label="Publisher (optional)"
          value={publisher}
          onInput={setPublisher}
        />
        <SpacerVertical />
        <Row alignItems="center" gap="20px">
          <Checkbox
            label="Use external line IDs"
            checked={useExternalLineId}
            onChange={setUseExternalLineId}
          />
          <Button
            tiny
            icon={isExternalLineIdHelpVisible ? ExpandLessIcon : ExpandMoreIcon}
            onClick={() =>
              isExternalLineIdHelpVisible ? hideExternalLineIdHelp() : showExternalLineIdHelp()
            }
          >
            {isExternalLineIdHelpVisible ? 'Hide help' : 'Show help'}
          </Button>
        </Row>
        <Row alignItems="center" gap="10px">
          <Checkbox
            width="225px"
            label="Filename is line ID"
            checked={useFilenameAsExternalLineId}
            onChange={setUseFilenameAsExternalLineId}
            marginLeft="25px"
            disabled={!useExternalLineId}
          />
        </Row>
        {isExternalLineIdHelpVisible && (
          <>
            <HelpExternalLineId />
            <SpacerVertical />
          </>
        )}
        <SpacerVertical large />
        <Row alignItems="center">
          <Button type="submit" contained loading={isCreatingGame}>
            Save
          </Button>
          <SpacerHorizontal />
          <Button onClick={onBack}>Cancel</Button>
        </Row>
      </Form>
      <Route
        path="/new-game/picture"
        render={() => (
          <PictureDialog
            title="Game picture"
            url={picture}
            name={name}
            onUpdate={(url: string) => {
              setPicture(url)
              return true
            }}
            onRemove={() => {
              setPicture('')
              return true
            }}
            onClose={() => goBack('/new-game')}
          />
        )}
      />
      <Route
        path="/new-game/time-zone"
        render={() => (
          <TimeZoneSelectDialog
            onSelect={(timeZone) => {
              goBack('/new-game')
              setTimeZone(timeZone)
            }}
            onClose={() => goBack('/new-game')}
          />
        )}
      />
    </Page>
  )
}

export default NewGame
