import { h } from 'preact'
import { Slider } from '@sodra/bongo-ui'

const getSliderValue = (value: number, minValue: number, maxValue: number, middleValue: number) => {
  let sliderValue
  if (value < middleValue) {
    sliderValue = 0.5 * ((value - minValue) / (middleValue - minValue))
  } else {
    sliderValue = 0.5 * ((value - middleValue) / (maxValue - middleValue)) + 0.5
  }
  return sliderValue
}

const getValue = (sliderValue: number, minValue: number, maxValue: number, middleValue: number) => {
  let value
  if (sliderValue < 0.5) {
    value = minValue + (sliderValue / 0.5) * (middleValue - minValue)
  } else {
    value = middleValue + ((sliderValue - 0.5) / 0.5) * (maxValue - middleValue)
  }
  return value
}

type Props = {
  minValue: number
  maxValue: number
  middleValue?: number
  value: number
  onChange: (value: number) => void
  width?: string
}

export const MinMaxSlider = ({
  minValue,
  maxValue,
  middleValue = minValue + (maxValue - minValue) / 2,
  value,
  onChange,
  ...props
}: Props) => {
  const sliderValue = getSliderValue(value, minValue, maxValue, middleValue)

  const handleChange = (sliderValue: number) => {
    const value = getValue(sliderValue, minValue, maxValue, middleValue)
    onChange(value)
  }

  return <Slider {...props} value={sliderValue} onChange={handleChange} />
}

export default MinMaxSlider
