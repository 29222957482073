import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { AddIcon, NativeSelect, SpacerVertical } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import { parse } from 'qs'

import { fetchAIVoices } from '../../actions'
import { useUrlParams } from '../../use-url-params'
import { useLocalStorageState } from '../../use-local-storage-state'

import Page from '../Page'
import Spinner from '../Spinner'
import SearchTextField from '../SearchTextField'

import AIVoicesTable from './AIVoicesTable'
import { Col, Row } from 'jsxstyle/preact'
import { useStore } from '../../store'
import { formatAIVoiceType } from 'lib'

export const AIVoices = () => {
  const { currentGame, aiVoices, totalAIVoices, isFetchingAIVoices } = useStore(
    'currentGame',
    'aiVoices',
    'totalAIVoices',
    'isFetchingAIVoices'
  )
  const params = parse(location.search.slice(1))

  const [query, setQuery] = useState(params.query || '')
  const [type, setType] = useState(params.type || '')

  const [page, setPage] = useState(params.page || 0)
  const [pageSize, setPageSize] = useLocalStorageState(`speechless:${currentGame!.id}:pageSize`, 20)
  const [orderBy, setOrderBy] = useState(params.orderBy || 'name')
  const [sortOrder, setSortOrder] = useState(params.sortOrder || 'asc')

  useUrlParams(
    { page, query, type, orderBy, sortOrder },
    { page: 0, query: '', type: '', orderBy: 'name', sortOrder: 'asc' }
  )

  useEffect(() => {
    fetchAIVoices({
      offset: page * pageSize,
      limit: pageSize,
      query,
      type,
      sortOrder,
      orderBy,
      includeReplica: false
    })
  }, [currentGame!.id, page, query, type, sortOrder, orderBy])

  if (!aiVoices) {
    return <Spinner />
  }

  return (
    <Page
      title="AI voices"
      actions={[
        {
          label: 'Create AI voice',
          icon: AddIcon,
          onClick: () => routeTo('/create-ai-voice')
        },
        {
          label: 'Import',
          icon: AddIcon,
          onClick: () => routeTo('/import-ai-voices')
        }
      ]}
    >
      <Col height="100%">
        <Row alignItems="center" gap="5px">
          <SearchTextField
            value={query}
            onChange={(query: string) => {
              setQuery(query)
              setPage(0)
            }}
          />
          <NativeSelect
            width="max-content"
            label="Type"
            value={type}
            onChange={setType}
            options={[
              { value: '', label: 'Any' },
              { value: 'google', label: formatAIVoiceType('google') },
              { value: 'elevenlabs', label: formatAIVoiceType('elevenlabs') },
              { value: 'azuretts', label: formatAIVoiceType('azuretts') }
            ]}
          />
        </Row>
        <SpacerVertical />
        <AIVoicesTable
          loading={isFetchingAIVoices}
          aiVoices={aiVoices}
          total={totalAIVoices}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
      </Col>
    </Page>
  )
}

export default AIVoices
