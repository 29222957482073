import { BasicDialog, Form, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { Row } from 'jsxstyle/preact'
import { useEffect, useState } from 'preact/hooks'
import { fetchLineLabels, updateLines } from '../../actions'
import { useStore } from '../../store'
import { Label } from '../Line/Label'

type Props = {
  lineIds: string[]
  onClose: () => void
  onSuccess: () => void
}

export const AddLabelDialog = ({ lineIds, onClose, onSuccess }: Props) => {
  const { lineLabels = [], isUpdatingLines } = useStore('lineLabels', 'isUpdatingLines')

  const [label, setLabel] = useState('')
  const [labelError, setLabelError] = useState('')

  useEffect(() => {
    fetchLineLabels()
  }, [])

  if (lineIds.length === 0) {
    onClose()
  }

  const addLabel = async (label: string) => {
    if (await updateLines(lineIds, { add: { label } })) {
      onSuccess()
      onClose()
    }
  }

  const handleAdd = async () => {
    if (label.trim() === '') {
      setLabelError('Please enter a label name')
      return
    }
    addLabel(label)
  }

  return (
    <BasicDialog
      title="Add label"
      actions={[
        { text: 'Add', contained: true, onClick: handleAdd, loading: isUpdatingLines },
        { text: 'Cancel', onClick: onClose }
      ]}
      onClose={onClose}
    >
      <Row alignItems="center" flexWrap="wrap" gap="10px">
        {lineLabels.map((label) => {
          return <Label label={label.name} onClick={() => addLabel(label.name)} />
        })}
      </Row>
      <SpacerVertical />
      <Form onSubmit={handleAdd}>
        <TextField
          autoComplete="off"
          width="100%"
          label="Label"
          value={label}
          onInput={setLabel}
          errorText={labelError}
        />
      </Form>
    </BasicDialog>
  )
}
