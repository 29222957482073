import { h } from 'preact'
import { Dialog, SpacerVertical } from '@sodra/bongo-ui'

import { SuperForm } from '../../SuperForm'
import { LineFilter } from 'src/types'
import { updateLineFilter } from 'src/actions/line-filters'
import { useStore } from 'src/store'

type Props = {
  filter: LineFilter
  onUpdate: (filter: LineFilter) => void
  onClose: () => void
}

export const EditLineFilter = ({ filter, onUpdate, onClose }: Props) => {
  const { isUpdatingLineFilter } = useStore('isUpdatingLineFilter')

  const handleSubmit = async (params: any) => {
    const updatedFilter = await updateLineFilter(filter.id, { name: params.name })
    if (updatedFilter) {
      onUpdate(updatedFilter)
      onClose()
    }
  }

  return (
    <Dialog title="Edit line filter" onClose={onClose}>
      <SuperForm
        disableSubmitIfNoChanges={true}
        onSubmit={handleSubmit}
        onCancel={onClose}
        loading={isUpdatingLineFilter}
        elements={[
          {
            element: 'TextField',
            name: 'name',
            label: 'Name',
            required: true,
            value: filter.name
          }
        ]}
      />
      <SpacerVertical />
    </Dialog>
  )
}
