import { h } from 'preact'
import { useCallback, useEffect, useState } from 'preact/hooks'
import { goBack, routeTo } from '@sodra/prutt'

import {
  deleteImportSource,
  fetchImportSource,
  setError,
  showSnackbar,
  syncImportSource,
  updateImportSource
} from '../../actions'
import { useEffectSkipFirst } from '../../use-effect-skip-first'

import Spinner from '../Spinner'

import { useStore } from '../../store'
import { confirm, useSSE } from 'lib'
import { Block, Row } from 'jsxstyle/preact'
import Page from '../Page'
import {
  Button,
  Checkbox,
  DeleteIcon,
  ListIcon,
  PlayIcon,
  SpacerHorizontal,
  SpacerVertical,
  TextField
} from '@sodra/bongo-ui'
import { MapColumns, MapColumnsParams } from './MapColumns'
import { Sheet, SheetWithRows, SpreadsheetMeta } from 'src/types'

type Props = {
  importSourceId: string
}

const ImportSource = ({ importSourceId }: Props) => {
  const {
    currentGame,
    importSource,
    isUpdatingImportSource,
    isDeletingImportSource,
    isSyncingImportSource
  } = useStore(
    'currentGame',
    'importSource',
    'isUpdatingImportSource',
    'isDeletingImportSource',
    'isSyncingImportSource'
  )

  const [isLoadingSheet, setIsLoadingSheet] = useState(false)
  const [sheetWithRows, setSheetWithRows] = useState<SheetWithRows[] | undefined>()
  const [allSheets, setAllSheets] = useState<Sheet[] | undefined>()
  const [sheets, setSheets] = useState<Sheet[]>(importSource?.sheets ?? [])

  const [sheetUrl, setSheetUrl] = useState(importSource?.url ?? '')
  const [name, setName] = useState(importSource?.name ?? '')
  const [autoSync, setAutoSync] = useState(importSource?.autoSync ?? false)

  // Handle switch game
  useEffectSkipFirst(() => {
    routeTo('/import-sources', true)
  }, [currentGame!.id])

  useEffect(() => {
    fetchImportSource(importSourceId)
  }, [importSourceId])

  useEffect(() => {
    if (importSource) {
      setSheetUrl(importSource.url)
      setSheets(importSource.sheets ?? [])
      setName(importSource.name)
      setAutoSync(importSource.autoSync)
    }
  }, [importSource?.id])

  useSSE({
    gameId: currentGame!.id,
    onImportSourceUpdated: useCallback(
      (updateImportSourceId: string) => {
        if (updateImportSourceId === importSourceId) {
          // console.log(`Import source updated!`)
          fetchImportSource(importSourceId)
        }
      },
      [importSourceId]
    )
  })

  const toggleSheet = (sheet: Sheet) => {
    if (sheets?.some((s) => s.sheetId === sheet.sheetId)) {
      setSheets((sheets) => sheets?.filter((s) => s.sheetId !== sheet.sheetId))
    } else {
      setSheets((sheets) => [...sheets, sheet])
    }
  }

  const getSpreadsheetMeta = async (spreadsheetId: string): Promise<SpreadsheetMeta> => {
    const spreadSheetUrl = new URL(`${import.meta.env.VITE_SPREADSHEET_URL}/meta`)
    spreadSheetUrl.searchParams.set('spreadsheetId', spreadsheetId)
    const res = await fetch(spreadSheetUrl, {
      method: 'POST'
    })
    if (!res.ok) {
      const { error } = await res.json()
      throw new Error(error)
    }
    return await res.json()
  }

  const loadSheet = async () => {
    setIsLoadingSheet(true)

    try {
      const url = new URL(sheetUrl!.replace('#', '?'))
      const spreadsheetId = url.pathname.split('/')[3]

      const meta = await getSpreadsheetMeta(spreadsheetId)

      setAllSheets(meta.sheets)

      if (meta.sheets.length === 1) {
        setSheets([meta.sheets[0]])
        await importRows([meta.sheets[0]])
      } else if (sheets) {
        setSheets(sheets.filter((sheet) => meta.sheets.some((s) => s.sheetId === sheet.sheetId)))
      }
    } catch (err: any) {
      setError(
        new Error(`
          Something went wrong. Please check that the URL has format https://docs.google.com/spreadsheets/d/spreadsheetId/edit#gid=sheetId
          and is shared with email ${import.meta.env.VITE_SPREADSHEET_SERVICE_ACCOUNT}.
          ${err.message}
        `)
      )
    } finally {
      setIsLoadingSheet(false)
    }
  }

  const importRows = async (sheets: Sheet[]) => {
    setIsLoadingSheet(true)

    try {
      const url = new URL(sheetUrl!.replace('#', '?'))
      const spreadsheetId = url.pathname.split('/')[3]

      const spreadSheetUrl = new URL(`${import.meta.env.VITE_SPREADSHEET_URL}/rows`)
      spreadSheetUrl.searchParams.set('spreadsheetId', spreadsheetId)
      for (let sheet of sheets) {
        spreadSheetUrl.searchParams.append('sheetId', String(sheet.sheetId))
      }

      const res = await fetch(spreadSheetUrl, {
        method: 'POST'
      })

      if (!res.ok) {
        const { error } = await res.json()
        throw new Error(error)
      }

      const sheetWithRows: SheetWithRows[] = await res.json()

      setSheetWithRows(sheetWithRows)
    } catch (err: any) {
      setError(
        new Error(`
          Something went wrong. Please check that the URL has format https://docs.google.com/spreadsheets/d/spreadsheetId/edit#gid=sheetId
          and is shared with email ${import.meta.env.VITE_SPREADSHEET_SERVICE_ACCOUNT}.
          ${err.message}
        `)
      )
    } finally {
      setIsLoadingSheet(false)
    }
  }

  const onClose = () => goBack('/import-sources')

  const handleSave = async (params: MapColumnsParams) => {
    if (
      await updateImportSource({
        url: sheetUrl,
        data: { sheets },
        name,
        autoSync,
        ...params
      })
    ) {
      showSnackbar('Import source updated')
      onClose()
    }
  }

  const handleDelete = async () => {
    if (await confirm('Delete import source?')) {
      if (await deleteImportSource(importSourceId)) {
        showSnackbar('Import source deleted')
        onClose()
      }
    }
  }

  const handleSyncNow = async () => {
    if (await syncImportSource(importSourceId, { dryRun: false })) {
      showSnackbar('Import source synced')
    }
  }

  if (!importSource || importSource?.id !== importSourceId) {
    return <Spinner />
  }

  const actions = [
    {
      label: 'Sync now',
      icon: PlayIcon,
      onClick: handleSyncNow,
      loading: isSyncingImportSource
    },
    {
      label: 'Logs',
      icon: ListIcon,
      onClick: () => routeTo(`/import-source/${importSource.id}/logs`)
    },
    {
      label: 'Delete',
      icon: DeleteIcon,
      onClick: handleDelete,
      loading: isDeletingImportSource
    }
  ]

  if (sheetWithRows) {
    return (
      <Page title={name} onBack={() => setSheetWithRows(undefined)} actions={actions}>
        <MapColumns
          saveText="Save"
          firstRowIsHeader={importSource.firstRowIsHeader}
          generateUniqueFilenames={importSource.generateUniqueFilenames}
          generateAiTakesForNewLines={importSource.generateAiTakesForNewLines}
          generateAiTakesForUpdatedLines={importSource.generateAiTakesForUpdatedLines}
          useAsSelectedTake={importSource.useAsSelectedTake}
          deleteMissingLines={importSource.deleteMissingLines}
          deleteUnusedCharacters={importSource.deleteUnusedCharacters}
          deleteUnusedEvents={importSource.deleteUnusedEvents}
          useSceneSeqNumber={importSource.useSceneSeqNumber}
          columns={importSource.columns}
          sheetWithRows={sheetWithRows}
          loading={isUpdatingImportSource}
          onSave={handleSave}
          onCancel={() => setSheetWithRows(undefined)}
        />
      </Page>
    )
  }

  if (allSheets && allSheets.length > 1) {
    return (
      <Page title={importSource.name} onBack={onClose} actions={actions}>
        <Block maxWidth="500px">
          <Block>Select one or more spreadsheet tabs to import</Block>
          <SpacerHorizontal />
          {allSheets.map((sheet) => (
            <Row alignItems="center">
              <Checkbox
                label={sheet.title}
                checked={sheets?.some((s) => s.sheetId === sheet.sheetId)}
                onChange={() => toggleSheet(sheet)}
              />
            </Row>
          ))}
          <SpacerVertical />
          <Row alignItems="center">
            <Button
              contained
              loading={isLoadingSheet}
              disabled={sheets.length === 0}
              onClick={() => importRows(sheets)}
            >
              Continue
            </Button>
            <SpacerHorizontal />
            <Button onClick={onClose}>Cancel</Button>
          </Row>
        </Block>
      </Page>
    )
  }

  return (
    <Page title={importSource.name} onBack={onClose} actions={actions}>
      <Block maxWidth="500px">
        <TextField width="100%" label="Name" value={name} onInput={setName} />
        <SpacerVertical />
        <TextField width="100%" label="Google sheet URL" value={sheetUrl} onInput={setSheetUrl} />
        <SpacerVertical />
        <Block>
          Please share the spreadsheet with Speechless service account email{' '}
          <em>{import.meta.env.VITE_SPREADSHEET_SERVICE_ACCOUNT}</em>. Viewer access is enough.
        </Block>
        <SpacerVertical />
        <Checkbox label="Auto sync (beta)" checked={autoSync} onChange={setAutoSync} />
        <SpacerVertical />
        <Row alignItems="center">
          <Button
            contained
            loading={isLoadingSheet}
            disabled={!sheetUrl || sheetUrl.trim() === ''}
            onClick={loadSheet}
          >
            Continue
          </Button>
          <SpacerHorizontal />
          <Button onClick={onClose}>Cancel</Button>
        </Row>
      </Block>
    </Page>
  )
}

export default ImportSource
