import { h } from 'preact'
import { BasicDialog, Checkbox, SpacerVertical } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'
import { useState } from 'preact/hooks'

import { translateLines } from '../../actions'
import { useStore } from '../../store'
import { formatLanguage } from '../../format-language'
import { pluralize } from 'lib'

type Props = {
  onClose: () => void
  onSuccess: () => void
  lineIds: string[]
}

export const TranslateLines = ({ onSuccess, onClose, lineIds = [] }: Props) => {
  const { currentGame, isTranslatingLines, currentLanguage } = useStore(
    'currentGame',
    'isTranslatingLines',
    'currentLanguage'
  )

  const [overwrite, setOverwrite] = useState(false)

  if (lineIds.length === 0 || currentGame?.primaryLanguage === currentLanguage) {
    onClose()
  }

  const handleSubmit = async () => {
    if (await translateLines(lineIds, { overwrite, language: currentLanguage })) {
      onSuccess()
      onClose()
    }
  }

  return (
    <BasicDialog
      title={`Translate ${lineIds.length} ${pluralize('line', lineIds.length)} to ${formatLanguage(
        currentLanguage
      )}?`}
      disableAutofocus
      primaryActionText="Translate"
      primaryActionLoading={isTranslatingLines}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Checkbox
        label="Overwrite current translations"
        checked={overwrite}
        onChange={setOverwrite}
      />
    </BasicDialog>
  )
}
