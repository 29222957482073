export type ColorName =
  | '--error'
  | '--on-surface'
  | '--on-surface-light'
  | '--on-surface-lighter'
  | '--accent'
  | '--accent-light'
  | '--selected'
  | '--container-background'
  | '--selection-control'
  | '--scrollbar-thumb'

export const colors = {
  '--error': 'hsl(0 85% 46%)',
  '--on-surface': 'hsl(125 20% 90%)',
  '--on-surface-light': 'hsl(125 15% 55%)',
  '--on-surface-lighter': 'hsl(125 10% 20%)',
  '--accent': 'hsl(125 93% 65.1%)',
  '--accent-light': 'hsl(125 34.41% 24.8%)',
  '--selected': 'hsl(125 34.41% 22%)',
  '--container-background': 'hsla(125, 5%, 16%, 0.8)',
  '--selection-control': 'hsl(125 10% 36%)',
  '--scrollbar-thumb': 'hsl(125 10% 40%)'
}
