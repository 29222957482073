import { getLines } from './lib/get-lines'
import { getTrimmed } from './lib/get-trimmed'
import { processLine } from './lib/process-line'

type Options = {
  numChars?: number
  numLines?: number
}

const getOptions = (options: Options) => ({
  numChars: options?.numChars || 400,
  numLines: options?.numLines || 6
})

export const getIsPreviewNeeded = (text: string, options: Options) => {
  const o = getOptions(options)
  const lines = getLines(text)
  return lines.length > o.numLines || getTrimmed(text).length > o.numChars
}

export const getPreview = (text: string, options: Options) => {
  const o = getOptions(options)
  const trimmed = getTrimmed(text)
  if (trimmed === '') {
    return []
  }

  const allLines = getLines(trimmed)

  const croppedLines = allLines.slice(0, o.numLines)
  const croppedText = croppedLines.join('\n').substring(0, o.numChars)

  const lines = getLines(croppedText).map(processLine)
  if (croppedText.length < trimmed.length) {
    lines[lines.length - 1] = lines[lines.length - 1] + '…'
  }
  return lines
}
