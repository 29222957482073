import { Route, routeTo } from '@sodra/prutt'
import { useEffect } from 'preact/hooks'

import { useEffectSkipFirst } from '../../use-effect-skip-first'

import Spinner from '../Spinner'

import { useStore } from '../../store'
import Page from '../Page'
import { fetchOrder } from '../../actions/orders'
import { Block, Grid, Row } from 'jsxstyle/preact'
import { Avatar, Button, Link, SpacerVertical } from '@sodra/bongo-ui'
import { formatCurrency, formatDate, formatTimeWithTimezone } from 'lib'
import { formatOrderState } from '../Orders/format-order-state'
import { ApproveOrderDialog } from './ApproveOrderDialog'

type Props = {
  orderId: string
}

export const OrderPage = ({ orderId }: Props) => {
  const { currentGame, order, isUpdatingOrder } = useStore(
    'currentGame',
    'order',
    'isUpdatingOrder'
  )

  // Handle switch game
  useEffectSkipFirst(() => {
    routeTo('/orders', true)
  }, [currentGame!.id])

  useEffect(() => {
    if (orderId) {
      fetchOrder(orderId)
    }
  }, [orderId])

  if (!order || order.id !== orderId) {
    return <Spinner />
  }

  const timeZone = currentGame?.timeZone

  const isOrderApprovalNeeded = order.state === 'pending-customer-approval'

  const billingAccount = order.billingAccount || order.game?.billingAccount
  const hasBillingAccount = billingAccount

  let receiptUrl
  if (order.stripeInvoice?.receiptUrl) {
    receiptUrl = order.stripeInvoice?.receiptUrl
  }

  let stripeHostedInvoiceUrl
  if (
    !receiptUrl &&
    order.stripeInvoice?.hostedInvoiceUrl &&
    order.stripeInvoice.status !== 'void'
  ) {
    stripeHostedInvoiceUrl = order.stripeInvoice?.hostedInvoiceUrl
  }

  let invoiceDetailsUri
  if (order.invoiceDetailsUri) {
    invoiceDetailsUri = order.invoiceDetailsUri
  }

  return (
    <Page title={`${order.name} · Orders`} onBack={() => routeTo(`/orders`)}>
      <Grid gridTemplateColumns="max-content auto" gap="20px 40px" alignItems="center">
        <Block color="var(--on-surface-light)">Order name</Block>
        <Block>{order.name}</Block>

        <Block color="var(--on-surface-light)">Order no.</Block>
        <Block>{order.shortId}</Block>

        <Block color="var(--on-surface-light)">Created</Block>
        <Block>{`${formatDate(order.created, timeZone)} ${formatTimeWithTimezone(
          order.created,
          timeZone
        )}`}</Block>

        <Block color="var(--on-surface-light)">State</Block>
        <Block>{formatOrderState(order.state)}</Block>

        <Block color="var(--on-surface-light)">Billing amount</Block>
        <Block>{formatCurrency(order.amount)} (excl. VAT)</Block>

        {order.voice && (
          <>
            <Block color="var(--on-surface-light)">Supplier ref: </Block>
            <Row alignItems="center" gap={10}>
              <Avatar size={30} src={order.voice.picture} name={order.voice.name} />
              {order.voice.name}
            </Row>
          </>
        )}

        {order.specificationUri && (
          <>
            <Block color="var(--on-surface-light)">Specification: </Block>
            <Block>
              <Link to={order.specificationUri} target="_blank">
                Order no. {order.shortId}
              </Link>
            </Block>
          </>
        )}

        {receiptUrl && (
          <>
            <Block color="var(--on-surface-light)">Receipt: </Block>
            <Block>
              <Link to={receiptUrl} target="_blank">
                Receipt (Stripe)
              </Link>
            </Block>
          </>
        )}

        {stripeHostedInvoiceUrl && (
          <>
            <Block color="var(--on-surface-light)">Invoice: </Block>
            <Block>
              <Link to={stripeHostedInvoiceUrl} target="_blank">
                Invoice (Stripe)
              </Link>
            </Block>
          </>
        )}

        {invoiceDetailsUri && (
          <>
            <Block color="var(--on-surface-light)">Invoice details: </Block>
            <Block>
              <Link to={invoiceDetailsUri} target="_blank">
                Invoice no. {order.invoiceNumber}
              </Link>
            </Block>
          </>
        )}
      </Grid>

      {isOrderApprovalNeeded && (
        <>
          <SpacerVertical large />
          <Button
            contained
            onClick={() => routeTo(`/orders/${orderId}/approve`)}
            disabled={!hasBillingAccount}
            loading={isUpdatingOrder}
          >
            Approve
          </Button>
          {!hasBillingAccount && (
            <>
              <SpacerVertical />
              <Block>
                The game needs a{' '}
                <Link to="/settings/billing" onRoute={routeTo}>
                  billing account
                </Link>{' '}
                before the order can be approved.
              </Block>
            </>
          )}
        </>
      )}

      <Route path="/orders/:orderId/approve" render={() => <ApproveOrderDialog order={order} />} />
    </Page>
  )
}
