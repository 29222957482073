import { Fragment, h } from 'preact'
import { FilterListIcon, SelectChip } from '@sodra/bongo-ui'
import { usePopupMenu } from '../use-popup-menu'
import { LineFilterTakeStatus } from 'src/types'

type Props = {
  value?: LineFilterTakeStatus
  onChange: (value?: LineFilterTakeStatus) => void
}

export const TakeStatusSelectChip = ({ value, onChange }: Props) => {
  const options = [
    {
      value: 'error',
      label: 'Error',
      onClick: () => onChange('error')
    },
    {
      value: 'processing',
      label: 'Processing',
      onClick: () => onChange('processing')
    },
    {
      value: 'no-takes-exist',
      label: 'No takes exist',
      onClick: () => onChange('no-takes-exist')
    },
    {
      value: 'no-selected-take',
      label: 'No selected take',
      onClick: () => onChange('no-selected-take')
    },
    { value: 'outdated', label: 'Outdated selected take', onClick: () => onChange('outdated') },
    {
      value: 'up-to-date',
      label: 'Up-to-date selected take',
      onClick: () => onChange('up-to-date')
    },
    {
      value: 'ai-tts-selected-take',
      label: 'AI generated selected take',
      onClick: () => onChange('ai-tts-selected-take')
    },
    {
      value: 'recorded-selected-take',
      label: 'Recorded/uploaded selected take',
      onClick: () => onChange('recorded-selected-take')
    }
  ]

  const { PopupMenu, showMenu } = usePopupMenu({ options })

  return (
    <Fragment>
      <SelectChip
        label="Take status"
        icon={FilterListIcon}
        value={options.find((option) => option.value === value)?.label}
        onClick={showMenu}
        onClear={() => onChange(undefined)}
      />
      {PopupMenu}
    </Fragment>
  )
}
