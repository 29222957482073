import { h } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'
import { get } from '../../api'
import { List, ListItem, PlayIcon, StopIcon } from '@sodra/bongo-ui'

import { AudioPlayer } from 'lib/src/hooks/use-audio-player'
import { Character, Line, Voice } from '../../types'
import { setError } from '../../actions'
import { Block } from 'jsxstyle/preact'

type Scene = {
  id: string
  name: string
  description?: string
  location?: string
  characters?: Character[]
  lines?: Line[]
}

type ExampleScenes = {
  [exampleSceneId: string]: Scene
}

type ExampleScenesProps = {
  voice: Voice
  player: AudioPlayer
}

export function ExampleScenes({ voice, player }: ExampleScenesProps) {
  const { resetPlayer, stopPlayer, play, playerState, initPlayer } = player

  const [exampleScenes, setExampleScenes] = useState<ExampleScenes | undefined>(undefined)

  useEffect(() => {
    get(`/voices/${voice.id}/example-scenes`)
      .then(({ data: exampleScenes }) => {
        setExampleScenes(exampleScenes)
      })
      .catch((err) => {
        setError(`Error fetching example scenes: ${err.message}`)
      })
  }, [])

  type ExampleScenesAudio = {
    [exampleSceneId: string]: {
      [characterId: string]: {
        audioUris: string[]
        isComplete: boolean
      }
    }
  }

  const exampleScenesAudio: ExampleScenesAudio | undefined = useMemo(() => {
    if (!exampleScenes) {
      return undefined
    }

    const exampleScenesAudio: ExampleScenesAudio = {}

    Object.entries(exampleScenes).forEach(([exampleSceneId, scene]) => {
      exampleScenesAudio[exampleSceneId] = {}

      for (const character of scene.characters ?? []) {
        const audioUris = scene.lines
          ?.map((line) => {
            const lineTranslation = line.translations?.en

            if (!lineTranslation) {
              return undefined
            }

            if (line.character?.id === character.id) {
              // For the current character, always use selected take
              return lineTranslation.selectedTake?.processedUri
            } else {
              // For other characters, use reference audio if exists, otherwise selected take
              return lineTranslation.referenceAudioUri ?? lineTranslation.selectedTake?.processedUri
            }
          })
          .filter(Boolean) as string[]

        const isComplete = audioUris.length === scene.lines?.length
        exampleScenesAudio[exampleSceneId][character.id] = {
          audioUris: isComplete ? audioUris : [],
          isComplete
        }
      }
    })

    return exampleScenesAudio
  }, [exampleScenes])

  if (!exampleScenes || Object.keys(exampleScenes).length === 0) {
    return <Block color="var(--on-surface-light)">No example scenes recorded yet</Block>
  }

  return (
    <List margin="0 -15px">
      {Object.entries(exampleScenes)
        .sort(([, s1], [, s2]) => s1.name.localeCompare(s2.name))
        .map(([exampleSceneId, scene]) => {
          return (
            <>
              {(scene.characters ?? [])
                .sort((c1, c2) => c1.name.localeCompare(c2.name))
                .map((character) => {
                  const sceneAsCharacterAudio = exampleScenesAudio?.[exampleSceneId]?.[character.id]
                  if (!sceneAsCharacterAudio?.isComplete) {
                    return (
                      <ListItem
                        disabled
                        visual={<PlayIcon fill="var(--on-surface-lighter)" />}
                        text={
                          <span>
                            {scene.name}, as{' '}
                            <span style={{ textTransform: 'capitalize' }}>{character.name}</span>
                          </span>
                        }
                        secondaryText="Not recorded yet"
                      />
                    )
                  }

                  const currentTrackMetaData = playerState.value.currentTrack?.metaData
                  const isPlayingCurrentScene =
                    playerState.value.isPlaying &&
                    currentTrackMetaData?.exampleSceneId === exampleSceneId &&
                    currentTrackMetaData?.asCharacterId === character.id

                  const color = isPlayingCurrentScene ? 'var(--accent)' : 'var(--on-surface)'

                  const togglePlay = () => {
                    if (isPlayingCurrentScene) {
                      stopPlayer()
                      resetPlayer()
                    } else {
                      initPlayer({
                        audioTracks: [
                          {
                            metaData: {
                              exampleSceneId,
                              asCharacterId: character.id
                            },
                            segments: sceneAsCharacterAudio.audioUris.map((uri) => ({ uri }))
                          }
                        ]
                      })
                      play()
                    }
                  }

                  return (
                    <ListItem
                      color={color}
                      onClick={togglePlay}
                      visual={
                        isPlayingCurrentScene ? (
                          <StopIcon fill={color} />
                        ) : (
                          <PlayIcon fill={color} />
                        )
                      }
                      text={
                        <span>
                          {scene.name}, as{' '}
                          <span style={{ textTransform: 'capitalize' }}>{character.name}</span>
                        </span>
                      }
                      secondaryText={scene.location}
                    />
                  )
                })}
            </>
          )
        })}
    </List>
  )
}
