import { Fragment, h } from 'preact'
import { Block, Box } from 'jsxstyle/preact'
import { useStore } from '../store'
import { Order } from '../types'
import { H2, Link, P, ProgressCircular, SpacerHorizontal, SpacerVertical } from '@sodra/bongo-ui'
import { formatCurrency } from 'lib'
import { routeTo } from '@sodra/prutt'
import { calcBuyoutOrderDetails } from './CreateSession/calc-session-order-details'

type Props = {
  budgetSegment: string
  buyoutOrder?: Order
  includeVAT: boolean
}

export const BuyoutInfo = ({ budgetSegment, buyoutOrder, includeVAT }: Props) => {
  const { config } = useStore('config')

  if (buyoutOrder) {
    return (
      <>
        <Block>
          <Link to={`/orders/${buyoutOrder.id}`} onRoute={routeTo}>
            View buyout order
          </Link>
        </Block>
      </>
    )
  }

  if (!config) {
    return (
      <>
        <H2 size="5">Buyout</H2>
        <SpacerVertical />
        <ProgressCircular size={24} />
      </>
    )
  }

  const {
    description,
    items,
    total,
    vat,
    vatPercent,
    totalIncVAT: totalIncVat
  } = calcBuyoutOrderDetails({
    budgetSegment,
    includeVAT,
    config
  })

  return (
    <>
      <H2 size="5">Buyout</H2>
      <SpacerVertical />
      <P color="var(--on-surface-light)">
        Full title buy-out of rights. Paid only once per voice actor per game title and includes
        setup.
      </P>
      <SpacerVertical />

      <Block>
        <table cellPadding="0" cellSpacing="0" style="min-width: 250px">
          {items.map((item) => {
            return (
              <Box component="tr" height="1.75rem">
                <Box component="td">{item.label}</Box>
                <Box component="td" textAlign="right">
                  {item.cost ? formatCurrency(item.cost) : '–'}
                </Box>
              </Box>
            )
          })}
          <Box component="tr" height="1.75rem">
            <Box component="td" borderTop="1px solid var(--on-surface-light)">
              <strong>{includeVAT ? 'Total excl. VAT' : 'Total'}</strong>
            </Box>
            <Box component="td" textAlign="right" borderTop="1px solid var(--on-surface-light)">
              <strong>{total ? formatCurrency(total) : '–'}</strong>
            </Box>
          </Box>
          <Box component="tr" height="1.75rem">
            <Box component="td">VAT</Box>
            <Box component="td" textAlign="right">
              {total ? formatCurrency(vat) : '–'}
            </Box>
          </Box>
          {includeVAT && (
            <Box component="tr" height="1.75rem">
              <Box component="td">Total incl. VAT</Box>
              <Box component="td" textAlign="right">
                {totalIncVat ? formatCurrency(totalIncVat) : '–'}
              </Box>
            </Box>
          )}
        </table>
      </Block>
    </>
  )
}
