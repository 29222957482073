import { store } from '../store'
import { get, post, patch, del } from '../api'

export const fetchAIVoices = async (params) => {
  let cancelled = false
  store.setState({ isFetchingAIVoices: true })
  const gameId = store.getState().currentGame.id
  get(`/games/${gameId}/ai-voices`, params)
    .then(({ data: aiVoices, meta: { total } }) => {
      if (!cancelled) {
        store.setState({ aiVoices, totalAIVoices: total, isFetchingAIVoices: false })
      }
    })
    .catch((error) => {
      if (!cancelled) {
        store.setState({ error, isFetchingAIVoices: false })
      }
    })
  return () => {
    store.setState({ isFetchingAIVoices: false })
    cancelled = true
  }
}

export const fetchAIVoice = async (aiVoiceId) => {
  try {
    store.setState({ isFetchingAIVoice: true })
    const { data: aiVoice } = await get(`/ai-voices/${aiVoiceId}`)
    store.setState({ aiVoice, isFetchingAIVoice: false })
    return aiVoice
  } catch (error) {
    store.setState({ error, isFetchingAIVoice: false })
  }
}

export const createAIVoice = async (params) => {
  try {
    store.setState({ isCreatingAIVoice: true })
    const gameId = store.getState().currentGame.id
    const { data: aiVoice } = await post(`/games/${gameId}/ai-voices`, params)
    store.setState({ aiVoice, isCreatingAIVoice: false })
    return aiVoice
  } catch (error) {
    store.setState({ error, isCreatingAIVoice: false })
  }
}

export const updateAIVoice = async (params) => {
  try {
    store.setState({ isUpdatingAIVoice: true })
    const aiVoiceId = store.getState().aiVoice.id
    const { data: aiVoice } = await patch(`/ai-voices/${aiVoiceId}`, params)
    store.setState({ aiVoice, isUpdatingAIVoice: false })
    return aiVoice
  } catch (error) {
    store.setState({ error, isUpdatingAIVoice: false })
  }
}

export const deleteAIVoice = async () => {
  try {
    store.setState({ isDeletingAIVoice: true })
    const aiVoiceId = store.getState().aiVoice.id
    await del(`/ai-voices/${aiVoiceId}`)
    store.setState({ isDeletingAIVoice: false })
    return true
  } catch (error) {
    store.setState({ error, isDeletingAIVoice: false })
  }
}
