type IsValidUrlOptions = { allowEmpty: boolean }

export const isValidUrl = (
  url: string,
  options: IsValidUrlOptions = { allowEmpty: false }
): boolean => {
  if (options.allowEmpty && url.trim() === '') {
    return true
  }

  const isValid =
    /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
      url
    )

  return isValid
}
