import { h } from 'preact'
import { goBack } from '@sodra/prutt'
import { Block, Col, Row } from 'jsxstyle/preact'
import Page from '../Page'
import { Lines } from '../Lines/Lines'
import { useSignal } from '@preact/signals'
import { Button } from '@sodra/bongo-ui'
import { setError, showSnackbar } from '../../actions'
import { put } from '../../api'

type Props = {
  sessionId: string
}

export function AddSessionLines({ sessionId }: Props) {
  const onBack = () => goBack(`/session/${sessionId}/lines`)
  const loading = useSignal(false)

  const selectedIds = useSignal<string[]>([])

  const hasSelected = selectedIds.value.length > 0

  const onDone = async () => {
    loading.value = true
    try {
      await put(`/sessions/${sessionId}/lines`, { lineIds: selectedIds.value })
      showSnackbar('Session updated')
      onBack()
    } catch (e) {
      console.error(e)
      setError(new Error('Failed adding lines to session'))
      loading.value = false
    }
  }

  return (
    <Block position="fixed" inset={0} zIndex={10} background="var(--surface)">
      <Page title={'Add lines'} onBack={onBack}>
        <Col gap="40px">
          <Row gap="10px" alignItems="center">
            <Button loading={loading.value} disabled={!hasSelected} contained onClick={onDone}>
              Add selected lines to session
            </Button>

            <div>Select lines in the table below to add them to the current session.</div>
          </Row>
          <Block height="700px">
            <Lines
              basePath={`/session/${sessionId}/lines/add-lines`}
              onSetSelectedIds={(_selectedIds) => (selectedIds.value = _selectedIds)}
              filters={['character', 'event', 'labels', 'scene']}
              excludeSessionId={sessionId}
            />
          </Block>
        </Col>
      </Page>
    </Block>
  )
}
