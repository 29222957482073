import { h } from 'preact'
import { Dialog, P } from '@sodra/bongo-ui'
import { useEffect, useState } from 'preact/hooks'

type Props = {
  onConfirm: () => void
  onClose: () => void
}

export const ConfirmDeleteDialog = ({ onConfirm, onClose }: Props) => {
  const [hideDialog, setHideDialog] = useState(false)

  useEffect(() => {
    return () => {
      setHideDialog(true)
    }
  }, [])

  const actions = [
    { text: 'Delete', onClick: onConfirm },
    { text: 'Cancel', onClick: () => setHideDialog(true) }
  ]

  return (
    <Dialog
      title="Delete session?"
      actions={actions}
      hideDialog={hideDialog}
      onClose={onClose}
      disableAutofocus
    >
      <P>Do you really want to delete the session?</P>
    </Dialog>
  )
}
