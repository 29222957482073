import { h } from 'preact'

import { goBack } from '@sodra/prutt'

import { useStore } from '../../../store'
import Page from '../../Page'
import { fetchBillingAccount, removeBillingAccountGame } from '../../../actions/billing-accounts'
import { showSnackbar } from '../../../actions'
import Spinner from '../../Spinner'
import { useEffect } from 'preact/hooks'
import { DeleteIcon, List, ListItem } from '@sodra/bongo-ui'
import Avatar from '../../Avatar'
import { Actor } from '../../../types'
import { Block } from 'jsxstyle/preact'
import { confirm } from 'lib'

type Props = {
  accountId: string
}

export const ManageBillingAccountGames = ({ accountId }: Props) => {
  const { billingAccount, isUpdatingBillingAccount } = useStore(
    'billingAccount',
    'isUpdatingBillingAccount'
  )

  useEffect(() => {
    fetchBillingAccount(accountId)
  }, [accountId])

  if (!billingAccount) {
    return <Spinner />
  }

  const handleRemoveGame = async (game: Actor) => {
    if (await confirm(`Remove game ${game.name} from billing account?`)) {
      if (await removeBillingAccountGame(accountId, game.id)) {
        showSnackbar(`Game ${game.name} removed from billing account`)
      }
    }
  }

  const onClose = () => goBack(`/settings/billing/${billingAccount.id}`)

  const hasGames = !!billingAccount.games?.length

  return (
    <Page title={`Games · ${billingAccount.name}`} onBack={onClose}>
      {!hasGames && (
        <Block color="var(--on-surface-light)">
          No games are currently using this billing account
        </Block>
      )}
      {hasGames && (
        <List maxWidth="500px">
          {billingAccount.games?.map((game) => {
            return (
              <ListItem
                visual={<Avatar size={30} src={game.picture} name={game.name} />}
                text={game.name}
                actionIcon={DeleteIcon}
                onActionClick={() => handleRemoveGame(game)}
              />
            )
          })}
        </List>
      )}
    </Page>
  )
}
