import { useSignalEffect } from '@preact/signals'
import { Row } from 'jsxstyle/preact'
import { colors } from './colors'
import { Timer, useTimer } from '../Timer'
import { Waveform } from '../WaveForm'
import { recorder } from './LineRecorder'

export function TimerAndWaveform() {
  const timer = useTimer()

  useSignalEffect(() => {
    if (recorder.value?.state.value.mediaRecorderState === 'recording') {
      timer.start()
    } else {
      timer.stop()
    }
  })

  if (!recorder.value?.state.value.mediaStream) return null

  return (
    <Row height="40px" width="100%" alignItems="center" gap="5px">
      <Timer millis={timer.millis} color={colors['--on-surface']} />
      <Waveform stream={recorder.value.state.value.mediaStream} color={colors['--on-surface']} />
    </Row>
  )
}
