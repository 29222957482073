import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { Col, Row } from 'jsxstyle/preact'
import { Button, ArrowDownwardIcon } from '@sodra/bongo-ui'

export const UnreadMessageNotice = ({ onClick }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setVisible(true), 720)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <Col
      position="absolute"
      top="0"
      left="0"
      right="0"
      alignItems="center"
      background="var(--surface-alternative)"
      color="var(--on-surface)"
      border="4px solid var(--accent)"
      opacity={visible ? 1 : 0}
      transition="opacity .36s cubic-bezier(0, 0, .2, 1)"
    >
      <Row alignItems="center" padding="8px 12px">
        Unread messages{' '}
        <Button onClick={onClick} icon={ArrowDownwardIcon}>
          Show
        </Button>
      </Row>
    </Col>
  )
}
