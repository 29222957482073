import { computed, ReadonlySignal, signal } from '@preact/signals'
import { useEffect, useState } from 'preact/hooks'
import { v4 as uuid } from 'uuid'

export const mainAudioPlayerSignal = signal<AudioPlayer | undefined>(undefined)
interface AudioInfo {
  name?: string
  description?: string
  picture?: string
  metaData?: Record<string, any>
  isAIVoice?: boolean
}
export interface AudioSegment extends AudioInfo {
  uri: string
}
export interface SingleAudioTrack extends AudioInfo {
  uri: string
}
export interface SegmentedAudioTrack extends AudioInfo {
  segments: AudioSegment[]
}
export type AudioTrack = SingleAudioTrack | SegmentedAudioTrack

export const isSingleAudioTrack = (track: AudioTrack): track is SingleAudioTrack =>
  (track as SingleAudioTrack).uri !== undefined

type AudioPlayerState = {
  isPlaying: boolean
  isInitialized: boolean
  metaData?: Record<string, any>
  currentTrack?: AudioTrack
  currentTrackUri?: string
  currentTrackIndex?: number
  currentSegmentIndex?: number
  currentTime: number
  duration: number
  loading: boolean
  isVisible: boolean
  hasNextTrack: boolean
  hasNextSegment: boolean
  hasPreviousTrack: boolean
}

type InitPlayerParams = {
  audioTracks: AudioTrack[]
  options?: {
    autoplayNextTrack?: boolean
  }
  metaData?: Record<string, any>
}

type PlayParams = {
  audioTrackIndex?: number
  segmentIndex?: number
  audioTrackUri?: string
  startTimeFraction?: number
}

export type AudioPlayer = {
  id: string

  initPlayer: (params: InitPlayerParams) => void

  hidePlayer: () => void
  showPlayer: () => void

  play: (params?: PlayParams) => void
  pausePlayer: () => void
  stopPlayer: () => void

  nextTrack: () => boolean
  nextSegment: () => boolean
  previousTrack: () => boolean

  setTime: (time: number) => void

  resetPlayer: () => void
  destroyPlayer: () => void

  playerState: ReadonlySignal<AudioPlayerState>
}

const createNewPlayer = (): AudioPlayer => {
  let audioElem: HTMLAudioElement | undefined = new Audio()
  audioElem.crossOrigin = 'anonymous'

  const signals = {
    isInitialized: signal(false),
    metaData: signal<Record<string, any> | undefined>(undefined),
    audioTracks: signal<AudioTrack[] | undefined>(undefined),
    currentTrackIndex: signal<number | undefined>(undefined),
    currentSegmentIndex: signal<number | undefined>(undefined),
    isPlaying: signal<boolean>(false),
    isVisible: signal<boolean>(false),
    currentTime: signal<number>(0),
    duration: signal<number>(0),
    loading: signal(false)
  }

  const playerState = computed((): AudioPlayerState => {
    const getCurrentTrack = () => {
      let currentTrack
      const currentTrackIndex = signals.currentTrackIndex.value

      if (currentTrackIndex !== undefined) {
        currentTrack = signals.audioTracks.value?.[currentTrackIndex]
      }
      return currentTrack
    }
    const currentTrack = getCurrentTrack()

    const currentTrackUri =
      currentTrack && isSingleAudioTrack(currentTrack) ? currentTrack.uri : undefined

    const hasNextTrack =
      signals.currentTrackIndex.value === undefined || signals.audioTracks.value === undefined
        ? false
        : signals.currentTrackIndex.value + 1 < signals.audioTracks.value.length

    const hasPreviousTrack =
      signals.currentTrackIndex.value !== undefined && signals.audioTracks.value !== undefined

    const hasNextSegment = () => {
      if (signals.currentSegmentIndex.value === undefined) {
        return false
      }

      if (!currentTrack || isSingleAudioTrack(currentTrack)) {
        return false
      }

      return signals.currentSegmentIndex.value + 1 < currentTrack.segments.length
    }

    const state: AudioPlayerState = {
      isPlaying: signals.isPlaying.value,
      isInitialized: signals.isInitialized.value,
      metaData: signals.metaData.value,
      currentTrack,
      currentTrackUri,
      currentTrackIndex: signals.currentTrackIndex.value,
      currentSegmentIndex: signals.currentSegmentIndex.value,
      currentTime: signals.currentTime.value,
      duration: signals.duration.value,
      loading: signals.loading.value,
      isVisible: signals.isVisible.value,
      hasNextTrack,
      hasNextSegment: hasNextSegment(),
      hasPreviousTrack
    }

    return state
  })

  const initPlayer = (params: InitPlayerParams) => {
    const defaultOptions = { autoplayNextTrack: false }
    const { audioTracks, options = defaultOptions } = params

    resetPlayer()
    audioElem = new Audio()

    const onEnded = () => {
      const currentTrack = playerState.value.currentTrack
      if (currentTrack) {
        if (isSingleAudioTrack(currentTrack)) {
          if (!options.autoplayNextTrack || !nextTrack()) {
            stopPlayer()
          }
        } else {
          if (!nextSegment() && (!options.autoplayNextTrack || !nextTrack())) {
            stopPlayer()
          }
        }
      }
    }
    audioElem.addEventListener('ended', onEnded)

    signals.audioTracks.value = audioTracks
    if (audioTracks.length > 0) {
      signals.currentTrackIndex.value = 0
      signals.currentSegmentIndex.value = 0
    }

    signals.metaData.value = params.metaData
    signals.isInitialized.value = true
  }

  const play = (params: PlayParams = {}) => {
    const { audioTrackIndex, segmentIndex, audioTrackUri, startTimeFraction } = params

    if (audioTrackIndex !== undefined && signals.audioTracks.value) {
      if (audioTrackIndex < 0 || audioTrackIndex >= signals.audioTracks.value.length) {
        throw new Error(`audioTrackIndex out of bounds: ${audioTrackIndex}`)
      }

      signals.currentTrackIndex.value = audioTrackIndex
    } else if (audioTrackUri !== undefined && signals.audioTracks.value) {
      const index = signals.audioTracks.value.findIndex((audioTrack) => {
        if (!isSingleAudioTrack(audioTrack)) return

        return audioTrack.uri === audioTrackUri
      })

      if (index === -1) {
        throw new Error(`audioTrackUri not found: ${audioTrackUri}`)
      }

      signals.currentTrackIndex.value = index
    }

    const currentTrack = playerState.value.currentTrack!

    if (segmentIndex !== undefined && !isSingleAudioTrack(currentTrack)) {
      if (segmentIndex < 0 || segmentIndex >= currentTrack.segments.length) {
        throw new Error(`segmentIndex out of bounds: ${segmentIndex}`)
      }
      signals.currentSegmentIndex.value = segmentIndex
    }

    if (currentTrack) {
      if (isSingleAudioTrack(currentTrack)) {
        if (audioElem!.src !== currentTrack.uri) {
          audioElem!.src = currentTrack.uri
          signals.loading.value = true
        }
      } else {
        const currentSegment = currentTrack.segments[signals.currentSegmentIndex.value!]
        if (!currentSegment) {
          throw new Error(
            `Could not get currentSegment for segment index ${signals.currentSegmentIndex.value}`
          )
        }
        if (audioElem!.src !== currentSegment.uri) {
          audioElem!.src = currentSegment.uri
          signals.loading.value = true
        }
      }

      // Store the current audio source to handle rejected play() correctly
      const audioUri = audioElem!.src

      audioElem!
        .play()
        .then(() => {
          signals.loading.value = false
          if (audioElem) {
            if (
              startTimeFraction !== undefined &&
              startTimeFraction >= 0 &&
              startTimeFraction < 1
            ) {
              audioElem.currentTime = startTimeFraction * audioElem.duration
            }
            signals.duration.value = audioElem.duration
          }
        })
        .catch((err) => {
          // A call to play() will fail if pause() is called during loading.
          // If pause() was called because _another_ audio source is about to be played,
          // we should just ignore the failure and not reset the loading/isPlaying states.
          if (audioUri === audioElem?.src) {
            signals.isPlaying.value = false
            signals.loading.value = false
          }
        })

      signals.isPlaying.value = true
      const updateTime = () => {
        if (signals.isPlaying.value) {
          signals.currentTime.value = audioElem!.currentTime
          requestAnimationFrame(updateTime)
        }
      }
      requestAnimationFrame(updateTime)
    }
  }

  const pausePlayer = () => {
    if (signals.isInitialized.value) {
      signals.isPlaying.value = false
      audioElem!.pause()
    }
  }

  const stopPlayer = () => {
    if (signals.isInitialized.value) {
      pausePlayer()
      audioElem!.currentTime = 0
    }
  }

  const setTime = (time: number) => {
    audioElem!.currentTime = time
  }

  const nextTrack = () => {
    if (playerState.value.hasNextTrack) {
      play({ audioTrackIndex: playerState.value.currentTrackIndex! + 1, segmentIndex: 0 })
      return true
    }
    return false
  }

  const previousTrack = () => {
    if (playerState.value.hasPreviousTrack) {
      if (playerState.value.currentTrackIndex === 0) {
        stopPlayer()
        play()
      } else {
        play({ audioTrackIndex: playerState.value.currentTrackIndex! - 1, segmentIndex: 0 })
      }
      return true
    }
    return false
  }

  const nextSegment = () => {
    if (playerState.value.hasNextSegment) {
      play({ segmentIndex: playerState.value.currentSegmentIndex! + 1 })
      return true
    }

    return false
  }

  const hidePlayer = () => (signals.isVisible.value = false)
  const showPlayer = () => (signals.isVisible.value = true)

  const resetPlayer = () => {
    signals.metaData.value = undefined
    signals.isInitialized.value = false
    signals.audioTracks.value = undefined
    signals.currentTrackIndex.value = undefined
    signals.loading.value = false
    if (audioElem) {
      audioElem.src = ''
    }
    hidePlayer()
    stopPlayer()
  }

  const destroyPlayer = () => {
    resetPlayer()
    audioElem!.src = ''
    audioElem = undefined
  }

  const player: AudioPlayer = {
    id: uuid(),
    initPlayer,

    hidePlayer,
    showPlayer,

    play,
    pausePlayer,
    stopPlayer,

    nextTrack,
    nextSegment,
    previousTrack,

    setTime,

    resetPlayer,
    destroyPlayer,

    playerState
  }

  return player
}

const currentAudioPlayers: { [playerId: string]: AudioPlayer } = {}

type UseAudioPlayerParams = {
  useAsMainPlayer?: boolean
}
export const useAudioPlayer = ({ useAsMainPlayer }: UseAudioPlayerParams = {}) => {
  const [player, setPlayer] = useState<AudioPlayer>()

  useEffect(() => {
    // Stop all other players
    for (let otherPlayer of Object.values(currentAudioPlayers)) {
      otherPlayer.stopPlayer()
    }

    const newPlayer = createNewPlayer()
    currentAudioPlayers[newPlayer.id] = newPlayer
    setPlayer(newPlayer)

    // Replace main player
    let currentMainAudioPlayer: AudioPlayer | undefined
    if (useAsMainPlayer) {
      currentMainAudioPlayer = mainAudioPlayerSignal.value
      mainAudioPlayerSignal.value = newPlayer
    }

    return () => {
      // Restore main player
      if (useAsMainPlayer) {
        mainAudioPlayerSignal.value = currentMainAudioPlayer
      }
      newPlayer.destroyPlayer()
      delete currentAudioPlayers[newPlayer.id]
    }
  }, [])

  return { player }
}
