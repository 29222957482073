import { h } from 'preact'
import { Block } from 'jsxstyle/preact'
import { routeTo } from '@sodra/prutt'
import { List, ListItem } from '@sodra/bongo-ui'
import { Chat } from '../../types'
import Avatar from '../Avatar'
import { useStore } from '../../store'

type Props = {
  selectedChatId: string
}

export const ChatList = ({ selectedChatId }: Props) => {
  const { currentUser, chats } = useStore('currentUser', 'chats')

  if (!chats) {
    return null
  }

  return (
    <List compact>
      {chats.map((chat) => {
        let lastMessage = ''
        if (chat.lastMessage?.fromUser?.id === currentUser?.id) {
          lastMessage += 'You: '
        } else if (chat.lastMessage?.fromAdmin) {
          lastMessage += `${chat.lastMessage.fromAdmin.name}: `
        } else if (chat.lastMessage?.fromUser) {
          lastMessage += `${chat.lastMessage.fromUser.name}: `
        } else if (chat.lastMessage?.fromVoice) {
          lastMessage += `${chat.lastMessage.fromVoice.name}: `
        }
        // TODO: Handle none text messages
        if (chat.lastMessage) {
          if (chat.lastMessage.type === 'file-upload') {
            lastMessage += 'Uploaded file'
          } else if (chat.lastMessage.data.type === 'session-created') {
            const { user, session } = chat.lastMessage.data
            lastMessage += `${user.name} created Session ${session.num}`
          } else if (chat.lastMessage.data.type === 'session-updated') {
            const { user, session } = chat.lastMessage.data
            lastMessage += `${user.name} updated Session ${session.num}`
          } else if (chat.lastMessage.data.type === 'session-deleted') {
            const { user, session } = chat.lastMessage.data
            lastMessage += `${user.name} deleted Session ${session.num}`
          } else if (chat.lastMessage.data.type === 'text') {
            lastMessage += chat.lastMessage.data.text
          }
        }

        return (
          <ListItem
            fontWeight={chat.hasUnread ? 600 : undefined}
            selected={selectedChatId === chat.id}
            visual={
              <Avatar
                size={28}
                src={chat.voice.picture}
                name={chat.voice.name}
                badge={chat.hasUnread}
              />
            }
            text={
              <Block
                color={
                  chat.hasUnread || selectedChatId === chat.id
                    ? 'var(--on-surface)'
                    : 'var(--on-surface-light)'
                }
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {chat.voice.name}
              </Block>
            }
            secondaryText={
              <Block overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                {lastMessage}
              </Block>
            }
            link={{
              href: `/chats/${chat.id}`,
              onRoute: routeTo
            }}
          />
        )
      })}
    </List>
  )
}
