import { store } from '../store'
import { del, get, patch, post } from '../api'
import { fetchCurrentGame, fetchGames } from './games'

const updateGame = async (gameId?: string) => {
  if (!gameId) {
    return
  }
  if (gameId === store.getState().currentGame?.id) {
    await fetchCurrentGame()
  }
  if (store.getState().games?.some((game) => game.id === gameId)) {
    await fetchGames()
  }
}

const updateGamesWithBillingAccount = async (accountId: string) => {
  if (accountId === store.getState().currentGame?.billingAccount?.id) {
    await fetchCurrentGame()
  }
  if (store.getState().games?.some((game) => game.billingAccount?.id === accountId)) {
    await fetchGames()
  }
}

export const fetchBillingAccounts = async () => {
  try {
    store.setState({ isFetchingBillingAccounts: true })
    const { data: billingAccounts } = await get(`/billing-accounts`)
    store.setState({ billingAccounts, isFetchingBillingAccounts: false })
    return billingAccounts
  } catch (err) {
    const error = err as Error
    store.setState({ error, isFetchingBillingAccounts: false })
  }
}

export const fetchBillingAccount = async (accountId: string) => {
  try {
    store.setState({ isFetchingBillingAccount: true })
    const { data: billingAccount } = await get(`/billing-accounts/${accountId}`)
    store.setState({ billingAccount, isFetchingBillingAccount: false })
  } catch (err) {
    const error = err as Error
    store.setState({ error, isFetchingBillingAccount: false })
  }
}

export const createBillingAccount = async (params: any) => {
  try {
    store.setState({ isCreatingBillingAccount: true })
    const { data: billingAccount } = await post(`/billing-accounts`, params)
    const { data: billingAccounts } = await get(`/billing-accounts`)
    if (params.gameId) {
      await updateGame(params.gameId)
    }
    store.setState({ billingAccount, billingAccounts, isCreatingBillingAccount: false })
    return billingAccount
  } catch (err) {
    const error = err as Error
    store.setState({ error, isCreatingBillingAccount: false })
    return false
  }
}

export const updateBillingAccount = async (accountId: string, params: any) => {
  try {
    store.setState({ isUpdatingBillingAccount: true })
    const { data: billingAccount } = await patch(`/billing-accounts/${accountId}`, params)
    await updateGamesWithBillingAccount(accountId)
    store.setState({ billingAccount, isUpdatingBillingAccount: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isUpdatingBillingAccount: false })
    return false
  }
}

export const addBillingAccountUser = async (accountId: string, email: string) => {
  try {
    store.setState({ isUpdatingBillingAccount: true })
    const { data: billingAccount } = await post(`/billing-accounts/${accountId}/users`, { email })
    store.setState({ billingAccount, isUpdatingBillingAccount: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isUpdatingBillingAccount: false })
    return false
  }
}

export const removeBillingAccountUser = async (accountId: string, userId: string) => {
  try {
    store.setState({ isUpdatingBillingAccount: true })
    const { data: billingAccount } = await del(`/billing-accounts/${accountId}/users/${userId}`)
    store.setState({ billingAccount, isUpdatingBillingAccount: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isUpdatingBillingAccount: false })
    return false
  }
}

export const deleteBillingAccount = async (accountId: string) => {
  try {
    store.setState({ isDeletingBillingAccount: true })
    await del(`/billing-accounts/${accountId}`)
    store.setState({ isDeletingBillingAccount: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isDeletingBillingAccount: false })
    return false
  }
}

export const removeBillingAccountGame = async (accountId: string, gameId: string) => {
  try {
    store.setState({ isUpdatingBillingAccount: true })
    const { data: billingAccount } = await del(`/billing-accounts/${accountId}/games/${gameId}`)
    await updateGame(gameId)
    store.setState({ billingAccount, isUpdatingBillingAccount: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isUpdatingBillingAccount: false })
    return false
  }
}
