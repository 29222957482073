import { render } from 'preact'

import { store } from './store'
import { App } from './App'

import './index.css'

render(<App />, document.getElementById('app') as HTMLElement)

store.setState({ appHasFocus: document.hasFocus() })
window.addEventListener('focus', () => store.setState({ appHasFocus: true }))
window.addEventListener('blur', () => store.setState({ appHasFocus: false }))
