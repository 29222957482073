export function hierarchyFromPath(path: { name: string; id: string }[], leaf?: any) {
  if (path.length === 0) {
    return leaf ?? null
  }
  return path.reduceRight<any & { children: any[] }>((acc, curr) => {
    return {
      type: 'Folder',
      name: curr.name,
      notes: JSON.stringify({...curr, meta: 'Created by Speechless'}),
      children: [acc].filter(Boolean)
    }
  }, leaf)
}
