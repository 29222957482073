import { h } from 'preact'
import { BasicDialog, Link, P, SpacerVertical } from '@sodra/bongo-ui'

import { clearError, setError } from '../actions'
import { store, useStore } from '../store'
import { Block } from 'jsxstyle/preact'
import { routeTo } from '@sodra/prutt'

const Error = () => {
  const { error } = useStore('error')
  if (!error) {
    return null
  }

  //
  // Payment failed
  //
  if (error.type === 'payment-failed') {
    const currentGame = store.getState().currentGame
    let creditCardDetailsUrl = '/settings/billing'
    if (currentGame && currentGame.billingAccount) {
      const billingAccountId = currentGame.billingAccount.id
      creditCardDetailsUrl = `/settings/billing/${billingAccountId}`
    }
    return (
      <Block position="relative" zIndex={10}>
        <BasicDialog
          title={error.title || 'Payment failed'}
          onClose={clearError}
          action1Text="Close"
          onAction1Click={clearError}
          disableAutofocus
        >
          <P>{error.message}</P>
          <SpacerVertical />
          <P>
            Please check your{' '}
            <Link
              to={creditCardDetailsUrl}
              onRoute={(to: string) => {
                setError(undefined)
                routeTo(to)
              }}
            >
              credit card details
            </Link>{' '}
            and ensure you have enough funds. Then try again. If the problem persists, please{' '}
            <Link to="mailto:support@speechless.games">email support@speechless.games</Link> for
            more information.
          </P>
        </BasicDialog>
      </Block>
    )
  }

  //
  // Default error dialog
  //
  return (
    <Block position="relative" zIndex={10}>
      <BasicDialog
        title={error.title || 'Oooops!'}
        onClose={clearError}
        action1Text="Close"
        onAction1Click={clearError}
      >
        {error.message}
      </BasicDialog>
    </Block>
  )
}

export default Error
