import { Fragment, h } from 'preact'
import { FilterListIcon, SelectChip } from '@sodra/bongo-ui'

import { usePopupMenu } from '../use-popup-menu'
import { useStore } from '../../store'

type Props = {
  value?: string
  onChange: (value?: string) => void
}

type Option = {
  value: string
  label: string
}

export const MoreSelectChip = ({ value, onChange }: Props) => {
  const { currentUser } = useStore('currentUser')

  const options: Option[] = []

  if (currentUser?.voiceLibraryFilters?.aiLicense) {
    options.push({ value: 'aiLicense', label: 'AI license' })
  }

  if (currentUser?.voiceLibraryFilters?.canRecordInLondon) {
    options.push({ value: 'canRecordInLondon', label: 'Can record in London' })
  }

  if (currentUser?.voiceLibraryFilters?.canRecordInLA) {
    options.push({ value: 'canRecordInLA', label: 'Can record in LA' })
  }

  if (options.length === 0) {
    return null
  }

  const formatValue = (value = '') => {
    for (let option of options) {
      if (option.value === value) {
        return option.label
      }
    }
    return ''
  }

  const { PopupMenu, showMenu } = usePopupMenu({
    options: [
      ...options.map((option) => {
        return {
          label: option.label,
          onClick: () => onChange(option.value)
        }
      })
    ]
  })

  return (
    <Fragment>
      <SelectChip
        label="More…"
        icon={FilterListIcon}
        value={formatValue(value)}
        onClick={showMenu}
        whiteSpace="nowrap"
      />
      {PopupMenu}
    </Fragment>
  )
}
