import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { Button, Checkbox, EditIcon, Form, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { showSnackbar, updateUser } from '../../actions'

import Page from '../Page'
import PictureDialog from '../PictureDialog'
import Avatar from '../Avatar'
import { useStore } from '../../store'

export const EditDetails = () => {
  const { currentUser, isUpdatingUser } = useStore('currentUser', 'isUpdatingUser')
  const [firstName, setFirstName] = useState(currentUser!.firstName || '')
  const [lastName, setLastName] = useState(currentUser!.lastName || '')
  const [email, setEmail] = useState(currentUser!.email || '')
  const [picture, setPicture] = useState(currentUser!.picture)
  const [sendEmailNotifications, setSendEmailNotifications] = useState(
    currentUser!.sendEmailNotifications
  )

  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [emailError, setEmailError] = useState('')

  useEffect(() => {
    if (firstName && firstName.trim() !== '') {
      setFirstNameError('')
    }
    if (lastName && lastName.trim() !== '') {
      setLastNameError('')
    }
    // if (email && email.trim() !== '') {
    //   setEmailError('')
    // }
  }, [firstName, email])

  const handleSave = async () => {
    let errorId

    // if (email.trim() === '') {
    //   setEmailError('Please enter an email address')
    //   errorId = 'email'
    // }
    if (firstName.trim() === '') {
      setFirstNameError('Please enter first name')
      errorId = 'firstName'
    }
    if (lastName.trim() === '') {
      setLastNameError('Please enter last name')
      errorId = 'lastName'
    }
    if (errorId) {
      const el = document.querySelector(`#${errorId}`)
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    if (!errorId) {
      if (await updateUser({ firstName, lastName, picture, sendEmailNotifications })) {
        showSnackbar('User details saved')
      }
    }
  }

  const updatePicture = async (url: string) => {
    await updateUser({ picture: url })
    setPicture(url)
    return true
  }

  const removePicture = async () => {
    await updateUser({ picture: '' })
    setPicture('')
    return true
  }

  return (
    <Page
      title="My account"
      actions={[
        {
          label: 'Change password',
          icon: EditIcon,
          onClick: () => routeTo('/account/change-password'),
          visible: !currentUser?.sso || currentUser?.sso.allowPasswords
        }
      ]}
    >
      <Block maxWidth="500px">
        <Avatar
          actionIcon={EditIcon}
          onActionClick={() => routeTo('/account/picture')}
          size={150}
          src={picture}
          name={currentUser!.name}
        />
        <SpacerVertical />
        <Form onSubmit={handleSave}>
          <TextField
            width="100%"
            label="First name"
            value={firstName}
            onInput={setFirstName}
            errorText={firstNameError}
            props={{
              id: 'firstName'
            }}
          />
          <SpacerVertical />
          <TextField
            width="100%"
            label="Last name"
            value={lastName}
            onInput={setLastName}
            errorText={lastNameError}
            props={{ id: 'lastName' }}
          />
          <SpacerVertical />
          <TextField
            width="100%"
            label="Email"
            value={email}
            onInput={setEmail}
            disabled={true}
            errorText={emailError}
            props={{
              id: 'email'
            }}
          />
          <SpacerVertical />
          <Checkbox
            label="Receive email notifications"
            checked={sendEmailNotifications}
            onChange={setSendEmailNotifications}
          />
          <SpacerVertical />
          <Button type="submit" contained loading={isUpdatingUser}>
            Save
          </Button>
        </Form>
      </Block>

      <Route
        path="/account/picture"
        render={() => (
          <PictureDialog
            title="User picture"
            url={picture}
            name={currentUser!.name}
            onUpdate={updatePicture}
            onRemove={removePicture}
            onClose={() => goBack('/account')}
          />
        )}
      />
    </Page>
  )
}

export default EditDetails
