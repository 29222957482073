import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { Row } from 'jsxstyle/preact'
import { ProgressCircular, SpacerHorizontal } from '@sodra/bongo-ui'

export const FetchingMessages = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setVisible(true), 720)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <Row
      position="absolute"
      bottom="0"
      left="0"
      right="0"
      justifyContent="center"
      pointerEvents="none"
    >
      <Row
        alignItems="center"
        fontSize="14px"
        lineHeight="16px"
        margin="12px"
        padding="8px 12px"
        borderRadius="3px"
        background="var(--inverted-surface)"
        color="var(--on-inverted-surface)"
        opacity={visible ? 1 : 0}
        transition="opacity .36s cubic-bezier(0, 0, .2, 1)"
      >
        <ProgressCircular color="var(--on-inverted-surface)" size={20} />
        <SpacerHorizontal small />
        Loading messages…
      </Row>
    </Row>
  )
}
