import { h } from 'preact'
import { Button, SpacerHorizontal, SpacerVertical } from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'
import { goBack, routeTo } from '@sodra/prutt'

import { showSnackbar, deleteCharacter } from '../../actions'

import Page from '../Page'
import { useStore } from '../../store'

export const DeleteCharacter = () => {
  const { character, isDeletingCharacter } = useStore('character', 'isDeletingCharacter')
  const onBack = () => goBack('/characters')

  const handleDelete = async () => {
    if (await deleteCharacter()) {
      showSnackbar('Character deleted')
      routeTo('/characters', true)
    }
  }

  return (
    <Page title={character!.name} onBack={onBack}>
      <Block>Delete character?</Block>
      <SpacerVertical />
      <Row alignItems="center">
        <Button contained loading={isDeletingCharacter} onClick={handleDelete}>
          Delete
        </Button>
        <SpacerHorizontal />
        <Button onClick={onBack}>Cancel</Button>
      </Row>
    </Page>
  )
}

export default DeleteCharacter
