import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Row } from 'jsxstyle/preact'
import {
  Button,
  Checkbox,
  Form,
  SpacerHorizontal,
  SpacerVertical,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'

import { showSnackbar, cloneCharacter } from '../../actions'

import Avatar from '../Avatar'
import Page from '../Page'
import { useStore } from '../../store'

export const CloneCharacter = () => {
  const { character, isCloningCharacter } = useStore('character', 'isCloningCharacter')
  const [name, setName] = useState(character!.name)
  const [description, setDescription] = useState(character!.description)
  const [picture, setPicture] = useState(character!.picture)
  const [cloneLines, setCloneLines] = useState(true)

  const onBack = () => goBack('/characters')

  const handleSave = async () => {
    const newCharacter = await cloneCharacter({ name, description, picture, cloneLines })
    if (newCharacter) {
      showSnackbar('Character cloned')
      routeTo(`/character/${newCharacter.id}`, true)
    }
  }

  return (
    <Page title={`Clone character`} onBack={onBack}>
      <Form onSubmit={handleSave} maxWidth="500px">
        <Avatar size={150} src={picture} name={name} onChange={setPicture} />
        <SpacerVertical />
        <TextField width="100%" label="Name" value={name} onInput={setName} />
        <SpacerVertical />
        <TextArea width="100%" label="Description" value={description} onInput={setDescription} />
        <SpacerVertical />
        <Checkbox label="Clone lines" checked={cloneLines} onChange={setCloneLines} />
        <SpacerVertical />
        <Row alignItems="center">
          <Button type="submit" contained loading={isCloningCharacter}>
            Clone
          </Button>
          <SpacerHorizontal />
          <Button onClick={onBack}>Cancel</Button>
        </Row>
      </Form>
    </Page>
  )
}

export default CloneCharacter
