import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Dialog, List, ListItem, SpacerVertical, EventIcon } from '@sodra/bongo-ui'

import { useFetchResult } from '../../use-fetch-result'

import SearchTextField from '../SearchTextField'

import Avatar from '../Avatar'
import { Session } from '../../types'
import { useStore } from '../../store'
import { formatScheduled } from 'lib'

type Props = {
  onClose: () => void
  onSelect: (session: Session) => void
}

export const SelectSession = ({ onClose, onSelect }: Props) => {
  const { currentGame, isNarrow } = useStore('currentGame', 'isNarrow')
  const [query, setQuery] = useState('')

  const { data: sessions } = useFetchResult<Session[]>(`/games/${currentGame!.id}/sessions`, {
    query
  })

  return (
    <Dialog
      large
      full={isNarrow}
      dismissable={false}
      title="Select session"
      onClose={onClose}
      actions={[{ text: 'Close', onClick: onClose }]}
    >
      <SearchTextField value={query} onChange={setQuery} />
      <SpacerVertical />
      {sessions && (
        <List>
          {sessions.map((session) => {
            return (
              <ListItem
                visual={
                  session.voice ? (
                    <Avatar size={30} src={session.voice.picture} name={session.voice.name} />
                  ) : (
                    <EventIcon size={30} fill="var(--on-surface)" />
                  )
                }
                text={formatScheduled(session.scheduled)}
                secondaryText={`${session.voice?.name || 'Not assigned'} · ${
                  session.duration
                } hour${session.duration !== 1 ? 's' : ''}`}
                onClick={() => {
                  onSelect(session)
                }}
              />
            )
          })}
        </List>
      )}
    </Dialog>
  )
}
