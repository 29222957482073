import { h } from 'preact'
import { Link } from '@sodra/bongo-ui'
import { Block, Col, Row } from 'jsxstyle/preact'
import { routeTo } from '@sodra/prutt'
import Logo from './Logo'
import { useStore } from '../store'

type Props = {
  signUpLink?: boolean
  children: any
}

const LandingPage = ({ children, signUpLink }: Props) => {
  const { isNarrow } = useStore('isNarrow')

  if (isNarrow) {
    return (
      <Block>
        <Block
          padding="40px 20px"
          margin="0 0 10px"
          width="100%"
          borderBottom="1px solid var(--container-outline)"
        >
          {children}
        </Block>
        <Row alignItems="center" justifyContent="space-between" padding="5px 25px" width="100%">
          <Logo href="/" />
          <Row gap="10px">
            <Link
              to={`${import.meta.env.VITE_HOMEPAGE_URL}`}
              target="_blank"
              title="Speechless"
              color="var(--on-surface)"
              underline
              text
            >
              About
            </Link>
            {signUpLink && (
              <Link
                to={`${import.meta.env.VITE_HOMEPAGE_URL}/sign-up`}
                target="_blank"
                color="var(--on-surface)"
                underline
                text
              >
                Sign up
              </Link>
            )}
            {location.pathname !== '/' && (
              <Link to="/" onRoute={routeTo} color="var(--on-surface)" underline text>
                Login
              </Link>
            )}
          </Row>
        </Row>
      </Block>
    )
  } else {
    return (
      <Col>
        <Col alignItems="center" justifyContent="center" minHeight="calc(100vh - 60px)">
          <Block
            padding="40px 30px"
            margin="20px 20px 10px 20px"
            borderRadius="6px"
            width="calc(100% - 40px)"
            maxWidth="500px"
            border="1px solid var(--container-outline)"
          >
            {children}
          </Block>
          <Row
            alignItems="center"
            justifyContent="space-between"
            padding="5px 25px"
            width="calc(100% - 30px)"
            maxWidth="500px"
          >
            <Logo href="/" />
            <Row gap="10px">
              <Link
                to={`${import.meta.env.VITE_HOMEPAGE_URL}`}
                target="_blank"
                title="Speechless"
                color="var(--on-surface)"
                underline
                text
              >
                About
              </Link>
              {signUpLink && (
                <Link
                  to={`${import.meta.env.VITE_HOMEPAGE_URL}/sign-up`}
                  target="_blank"
                  color="var(--on-surface)"
                  underline
                  text
                >
                  Sign up
                </Link>
              )}
              {location.pathname !== '/' && (
                <Link to="/" onRoute={routeTo} color="var(--on-surface)" underline text>
                  Login
                </Link>
              )}
            </Row>
          </Row>
        </Col>
      </Col>
    )
  }
}

export default LandingPage
