import { Fragment, h } from 'preact'
import { Block, Box } from 'jsxstyle/preact'
import { H2, SpacerVertical } from '@sodra/bongo-ui'
import { formatCurrency } from 'lib'

type Props = {
  description?: string
  items: {
    label: string
    cost: number
  }[]
  total: number
  includeVat: boolean
  vat: number
  totalIncVat: number
  vatPercent?: number
}

export const SessionCost = ({
  description,
  items,
  total,
  includeVat,
  vat,
  totalIncVat,
  vatPercent
}: Props) => {
  return (
    <>
      <H2 size="5">Session cost</H2>
      <SpacerVertical />
      {description && (
        <>
          <Block color="var(--on-surface-light)">{description}</Block>
          <SpacerVertical />
        </>
      )}
      <table cellPadding="0" cellSpacing="0" style="min-width: 250px">
        {items.map((item) => {
          return (
            <Box component="tr" height="1.75rem">
              <Box component="td">{item.label}</Box>
              <Box component="td" textAlign="right">
                {item.cost ? formatCurrency(item.cost) : '–'}
              </Box>
            </Box>
          )
        })}
        <Box component="tr" height="1.75rem">
          <Box component="td" borderTop="1px solid var(--on-surface-light)">
            <strong>{includeVat ? 'Total excl. VAT' : 'Total'}</strong>
          </Box>
          <Box component="td" textAlign="right" borderTop="1px solid var(--on-surface-light)">
            <strong>{total ? formatCurrency(total) : '–'}</strong>
          </Box>
        </Box>
        <Box component="tr" height="1.75rem">
          <Box component="td">VAT</Box>
          <Box component="td" textAlign="right">
            {total ? formatCurrency(vat) : '–'}
          </Box>
        </Box>
        {includeVat && (
          <Box component="tr" height="1.75rem">
            <Box component="td">Total incl. VAT</Box>
            <Box component="td" textAlign="right">
              {totalIncVat ? formatCurrency(totalIncVat) : '–'}
            </Box>
          </Box>
        )}
      </table>
    </>
  )
}
