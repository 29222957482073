import { h } from 'preact'
import { BasicDialog } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'

import { restoreLines } from '../../actions'
import { useStore } from '../../store'
import { useEffect, useState } from 'preact/hooks'
import Spinner from '../Spinner'

type Props = {
  onClose: () => void
  onSuccess: (restoredLineIds: string[]) => void
  lineIds?: string[]
}

export const RestoreLines = ({ onSuccess, onClose, lineIds = [] }: Props) => {
  const { isRestoringLines } = useStore('isRestoringLines')
  const [lineIdsToRestore, setLineIdsToRestore] = useState<string[] | undefined>(undefined)

  useEffect(() => {
    // Do a dry run to verify which lines will actually be restored
    const dryRun = true
    restoreLines(lineIds, dryRun).then((expectedLineIdsToRestore: string[]) => {
      setLineIdsToRestore(expectedLineIdsToRestore)
    })
  }, [])

  if (lineIds.length === 0) {
    onClose()
  }

  const handleSubmit = async () => {
    let restoredLineIds = await restoreLines(lineIds)
    if (restoredLineIds) {
      onSuccess(restoredLineIds)
      onClose()
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      primaryActionText="Restore"
      primaryActionLoading={isRestoringLines}
      primaryActionDisabled={!lineIdsToRestore?.length}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      title="Restore lines"
      onClose={onClose}
    >
      {lineIdsToRestore === undefined && <Spinner />}
      {lineIdsToRestore && lineIdsToRestore.length > 0 && (
        <Block>
          Restore {lineIds.length} deleted line{lineIds.length !== 1 ? 's' : ''}?
        </Block>
      )}
      {lineIdsToRestore && lineIdsToRestore.length === 0 && (
        <Block>
          None of the selected lines can be restored. Probably because they have not been deleted.
        </Block>
      )}
    </BasicDialog>
  )
}

export default RestoreLines
