import { h, JSX } from 'preact'

import { Link, List, ListItem, P, SpacerVertical } from '@sodra/bongo-ui'
import { useEffect, useState } from 'preact/hooks'
import { ShortlistedVoice, Voice } from '../../types'
import Avatar from '../Avatar'
import LargeDialog from '../LargeDialog'
import SearchTextField from '../SearchTextField'
import Spinner from '../Spinner'
import { useStore } from '../../store'
import { Block, Row } from 'jsxstyle/preact'
import { routeTo } from '@sodra/prutt'
import { fetchShortlistedVoices } from '../../actions/shortlisted-voices'
import { formatVoiceName } from '../../format-voice-name'

type Props = {
  onSelect: (voice: ShortlistedVoice) => void
  onClose: (voice?: ShortlistedVoice) => void
}

export const SelectVoice = ({ onClose, onSelect }: Props) => {
  const { isFetchingShortlistedVoices, shortlistedVoices = [] } = useStore(
    'isFetchingShortlistedVoices',
    'shortlistedVoices'
  )

  useEffect(() => {
    fetchShortlistedVoices()
  }, [])

  const [query, setQuery] = useState<string>('')

  const filteredVoices = shortlistedVoices.filter((shortlistedVoice) =>
    shortlistedVoice.name.toLowerCase().includes(query.toLowerCase())
  )

  return (
    <LargeDialog
      title="Select voice actor"
      onClose={onClose}
      action1Text="Cancel"
      onAction1Click={onClose}
    >
      <SearchTextField value={query} onChange={setQuery} width="100%" />
      <SpacerVertical />
      {isFetchingShortlistedVoices && <Spinner />}
      {!isFetchingShortlistedVoices && shortlistedVoices.length > 0 && (
        <List>
          {filteredVoices.map((voice) => {
            return (
              <ListItem
                visual={<Avatar src={voice.picture} name={voice.name} />}
                text={
                  <Row alignItems="center" gap="10px">
                    <Block flex="1">
                      <Block>{formatVoiceName(voice.name)}</Block>
                    </Block>
                    {voice.characters.map((character) => {
                      return (
                        <Avatar
                          size={30}
                          src={character.picture}
                          name={character.name}
                          link={{
                            href: `/character/${character.id}`,
                            onRoute: routeTo
                          }}
                          tooltipText={character.name}
                        />
                      )
                    })}
                  </Row>
                }
                onClick={() => {
                  onSelect(voice)
                }}
              />
            )
          })}
        </List>
      )}
      {!isFetchingShortlistedVoices && shortlistedVoices.length > 0 && (
        <Block margin="40px" text-align="center">
          <P>Do you not find the voice actor you are looking for?</P>
          <SpacerVertical />
          <P>
            Please go to{' '}
            <Link to="/voices" onRoute={routeTo}>
              the voice library
            </Link>{' '}
            and shortlist.
          </P>
        </Block>
      )}
      {!isFetchingShortlistedVoices && shortlistedVoices.length === 0 && (
        <Block margin="40px" text-align="center">
          <P>No voice actors have been shortlisted.</P>
          <SpacerVertical />
          <P>
            Please find a voice actor in{' '}
            <Link to="/voices" onRoute={routeTo}>
              the voice library
            </Link>{' '}
            to shortlist.
          </P>
        </Block>
      )}
      <SpacerVertical />
    </LargeDialog>
  )
}
