import { store } from '../store'
import { del, get, patch, post } from '../api'
import { LineFilter, LineFilterSettings } from 'src/types'

export const fetchLineFilters = async (): Promise<LineFilter[] | undefined> => {
  try {
    store.setState({ isFetchingLineFilters: true })
    const gameId = store.getState().currentGame?.id
    const { data: lineFilters } = await get(`/line-filters`, { gameId })
    store.setState({ lineFilters, isFetchingLineFilters: false })
    return lineFilters
  } catch (err) {
    const error = err as Error
    store.setState({ error, isFetchingLineFilters: false })
  }
}

export const createLineFilter = async (params: {
  name: string
  settings: LineFilterSettings
}): Promise<LineFilter | undefined> => {
  try {
    const { name, settings } = params
    store.setState({ isCreatingLineFilter: true })
    const gameId = store.getState().currentGame?.id
    const { data: lineFilter } = await post(`/line-filters`, { gameId, name, settings })
    store.setState({ isCreatingLineFilter: false })
    return lineFilter
  } catch (err) {
    const error = err as Error
    store.setState({ error, isCreatingLineFilter: false })
  }
}

export const updateLineFilter = async (
  filterId: string,
  params: { name: string; settings?: LineFilterSettings }
): Promise<LineFilter | undefined> => {
  try {
    store.setState({ isUpdatingLineFilter: true })
    const { data: lineFilter } = await patch(`/line-filters/${filterId}`, params)
    store.setState({ isUpdatingLineFilter: false })
    return lineFilter
  } catch (err) {
    const error = err as Error
    store.setState({ error, isUpdatingLineFilter: false })
  }
}

export const deleteLineFilter = async (filterId: string) => {
  try {
    store.setState({ isDeletingLineFilter: true })
    const { data: lineFilters } = await del(`/line-filters/${filterId}`)
    store.setState({ lineFilters, isDeletingLineFilter: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isDeletingLineFilter: false })
  }
}
