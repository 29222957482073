import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { AddIcon, Button, CheckmarkIcon, List, ListItem, SpacerVertical } from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'
import { Block, Row } from 'jsxstyle/preact'

import Page from '../../Page'
import { useStore } from '../../../store'
import { fetchBillingAccounts } from '../../../actions/billing-accounts'
import { BasicBillingAccount } from '../../../types'
import { showSnackbar, updateGame } from '../../../actions'
import { confirm } from 'lib'

export const BillingAccounts = () => {
  const { currentGame, billingAccounts = [] } = useStore('currentGame', 'billingAccounts')

  useEffect(() => {
    fetchBillingAccounts()
  }, [currentGame?.id])

  const onClose = () => goBack('/settings')

  const makePrimary = async (account: BasicBillingAccount) => {
    if (await confirm(`Make ${account.name} the primary billing account?`)) {
      if (await updateGame({ billingAccountId: account.id })) {
        showSnackbar('Primary billing account updated')
      }
    }
  }

  const primaryBillingAccount = currentGame?.billingAccount

  const hasAccess =
    primaryBillingAccount &&
    billingAccounts.some((account) => account.id === primaryBillingAccount.id)

  const filteredAccounts = billingAccounts.filter(
    (account) => account.id !== primaryBillingAccount?.id
  )

  return (
    <Page
      title="Billing accounts"
      onBack={onClose}
      actions={[
        {
          label: 'Add',
          icon: AddIcon,
          link: {
            href: `/settings/billing/add-billing-account`,
            onRoute: routeTo
          }
        }
      ]}
    >
      <Block maxWidth="500px">
        {!primaryBillingAccount && (
          <Block color="var(--on-surface-light)">No primary billing account</Block>
        )}
        {primaryBillingAccount && (
          <>
            <Block color="var(--on-surface-light)" fontSize="14px">
              Primary billing account
            </Block>
            <SpacerVertical small />
            <List>
              <ListItem
                text={primaryBillingAccount.name}
                onClick={
                  hasAccess
                    ? () => routeTo(`/settings/billing/${primaryBillingAccount.id}`)
                    : undefined
                }
              />
            </List>
          </>
        )}
        {filteredAccounts.length > 0 && (
          <>
            <SpacerVertical large />
            <Block color="var(--on-surface-light)" fontSize="14px">
              Available billing accounts
            </Block>
            <SpacerVertical small />
            <List>
              {filteredAccounts.map((account) => {
                return (
                  <ListItem
                    onClick={() => routeTo(`/settings/billing/${account.id}`)}
                    text={
                      <Row alignItems="center">
                        <Block flex="1">{account.name}</Block>
                        <Button onClick={() => makePrimary(account)}>Make primary</Button>
                      </Row>
                    }
                  />
                )
              })}
            </List>
          </>
        )}
      </Block>
    </Page>
  )
}
