import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import {
  Button,
  DeleteIcon,
  EditIcon,
  Form,
  SpacerHorizontal,
  SpacerVertical,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { showSnackbar, updateScene } from '../../actions'

import Avatar from '../Avatar'
import Page from '../Page'
import PictureDialog from '../PictureDialog'
import { useStore } from '../../store'

export const EditDetails = () => {
  const { scene, isUpdatingScene } = useStore('scene', 'isUpdatingScene')

  const [name, setName] = useState(scene!.name)
  const [description, setDescription] = useState(scene!.description)
  const [picture, setPicture] = useState(scene!.picture)

  const onBack = () => goBack('/scenes')

  const handleSave = async () => {
    if (await updateScene({ name, description })) {
      showSnackbar('Scene details saved')
      onBack()
    }
  }

  const updatePicture = async (url: string) => {
    await updateScene({ picture: url })
    setPicture(url)
    return true
  }

  const removePicture = async () => {
    await updateScene({ picture: '' })
    setPicture('')
    return true
  }

  return (
    <Page
      title={scene!.name}
      actions={[
        {
          label: 'Delete',
          icon: DeleteIcon,
          onClick: () => routeTo(`/scene/${scene!.id}/delete`)
        }
      ]}
      onBack={onBack}
    >
      <Block maxWidth="500px">
        <Avatar
          actionIcon={EditIcon}
          onActionClick={() => routeTo(`/scene/${scene!.id}/edit/picture`)}
          size={150}
          src={picture}
          name={name}
        />
        <SpacerVertical />
        <Form onSubmit={handleSave}>
          <TextField width="100%" label="Name" value={name} onInput={setName} />
          <SpacerVertical />
          <TextArea width="100%" label="Description" value={description} onInput={setDescription} />
          <SpacerVertical />
          <Row alignItems="center">
            <Button type="submit" contained loading={isUpdatingScene}>
              Save
            </Button>
            <SpacerHorizontal />
            <Button onClick={onBack}>Cancel</Button>
          </Row>
        </Form>
      </Block>

      <Route
        path="/scene/:sceneId/edit/picture"
        render={() => (
          <PictureDialog
            title="Scene picture"
            url={picture}
            name={name}
            onUpdate={updatePicture}
            onRemove={removePicture}
            onClose={() => goBack(`/scene/${scene!.id}/edit`)}
          />
        )}
      />
    </Page>
  )
}

export default EditDetails
