import { h } from 'preact'
import { List, ListItem, PlayIcon, StopIcon } from '@sodra/bongo-ui'

import { AudioPlayer, SingleAudioTrack } from 'lib/src/hooks/use-audio-player'

import { Voice } from '../../types'

export const VoiceDemoList = ({ voice, player }: { voice: Voice; player: AudioPlayer }) => {
  const { resetPlayer, stopPlayer, play, playerState, initPlayer, hidePlayer, showPlayer } = player

  return (
    <List margin="0 -15px">
      {voice.audio.map((audio, i) => {
        const currentTrack = playerState.value.currentTrack as SingleAudioTrack | undefined
        const isActive = currentTrack && currentTrack.uri === audio.uri
        const { isPlaying } = playerState.value
        const color = isActive ? 'var(--accent)' : 'var(--on-surface)'

        return (
          <ListItem
            color={color}
            onClick={() => {
              if (isPlaying && isActive) {
                stopPlayer()
                hidePlayer()
                resetPlayer()
              } else {
                if (!isActive) {
                  initPlayer({
                    options: { autoplayNextTrack: true },
                    audioTracks: voice.audio.map((audio) => {
                      return {
                        picture: voice.picture,
                        name: audio.name,
                        description: audio.description,
                        uri: audio.uri
                      }
                    })
                  })
                }
                showPlayer()
                play({ audioTrackIndex: i })
              }
            }}
            visual={isActive && isPlaying ? <StopIcon fill={color} /> : <PlayIcon fill={color} />}
            text={audio.name}
            secondaryText={audio.description}
          />
        )
      })}
    </List>
  )
}
