import { h } from 'preact'
import {
  BackArrowIcon,
  Checkbox,
  CloseIcon,
  ContentCopyIcon,
  Dialog,
  IconButton,
  Link,
  P,
  ProgressLinear,
  RefreshIcon,
  Select,
  SpacerVertical
} from '@sodra/bongo-ui'
import { Block, Col, Grid, Inline, InlineBlock, Row } from 'jsxstyle/preact'
import { ComponentChildren } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'

import { ImportSound, WwiseConnection } from 'src/App/Settings/Wwise/lib/WwiseConnection'
import { useWwise } from 'src/App/Settings/Wwise/lib/useWwise'
import { setError, showSnackbar, updateExportTarget } from 'src/actions'
import { formatLanguage } from 'src/format-language'
import { useStore } from 'src/store'
import { ExportTarget, Line } from 'src/types'
import { useFetchResult } from 'src/use-fetch-result'
import { useLocalStorageState } from 'src/use-local-storage-state'
import { defaultSettings, type WwiseSettings as WwiseSettingsType } from './create-export-target'

import { ConnectionSettings } from './ConnectionSettings'
import { TextOutput } from './TextOutput'
import { WwiseSettings } from './WwiseSettings'
import { PathOptions, applySubstitutionVariablesString, getPath } from './substitution-variables'
import { routeTo } from '@sodra/prutt'

const SHOW_FORCE_EXPORT = true

const lineMetaCompare = (a: any, b: any, targetLanguage: string) => {
  return a.lineId === b.lineId && a.hash[targetLanguage] === b.hash[targetLanguage]
}

const copyToClipboard = (str: string) => {
  navigator.clipboard.writeText(str)
  showSnackbar('Copied to clipboard')
}

function lineToSound(
  line: Line,
  options: {
    workUnit?: string
    folderStructure?: string
    currentLanguage: string
    currentGame: { name: string; id: string }
    targetLanguage: string
  }
): ImportSound {
  const substitutions: PathOptions = {}
  if (line.character) {
    substitutions.character = { name: line.character.name, id: line.character.id }
  }
  if (line.event) {
    substitutions.event = { name: line.event.name, id: line.event.id }
  }
  if (line.scene) {
    substitutions.scene = { name: line.scene.name, id: line.scene.id }
  }
  if (options.currentGame) {
    substitutions.game = { name: options.currentGame.name, id: options.currentGame.id }
  }
  if (line.lineAttributes) {
    substitutions.lineAttributes = line.lineAttributes
  }

  const _workUnit = options.workUnit
    ? applySubstitutionVariablesString(options.workUnit, substitutions)
    : undefined
  const path = getPath(options.folderStructure, substitutions)
  return {
    name: line.filename!,
    url: line.translations[options.currentLanguage]?.selectedTake?.processedUri!,
    folderStructure: {
      workUnit: _workUnit,
      path: path
    },
    metaIdKey: 'lineId',
    meta: {
      line: line.translations[options.currentLanguage]?.selectedTake?.line,
      lineId: line.id,
      hash: {
        [options.targetLanguage]: line.translations[options.currentLanguage]?.selectedTake?.hash
      }
    }
  }
}

type SoundStatus = Awaited<ReturnType<WwiseConnection['compareSoundsToWwiseContent']>>
type SoundStatuses = SoundStatus['tasks'][number]['status']
type GetSoundStatusFn = ReturnType<typeof useWwise>['getSoundStatus']

function ExportPreview(props: {
  selectedLines: Line[]
  allLines: Line[]
  currentGame: { name: string; id: string }
  wwiseSettings: {
    workUnit: string
    folderStructure: string
    createEvents: boolean
    forceExport: boolean
    moveExisting: boolean
    targetLanguage: string
  }
  getSoundStatus: GetSoundStatusFn
}) {
  const [soundStatus, setSoundStatus] = useState<SoundStatus | null>(null)

  const getSoundStatus = async () => {
    console.log('GETTING SOUND STATUS')
    const result = await props.getSoundStatus?.(
      props.selectedLines.map((line) =>
        lineToSound(line, {
          workUnit: props.wwiseSettings.workUnit,
          folderStructure: props.wwiseSettings.folderStructure,
          currentLanguage: 'en',
          currentGame: props.currentGame,
          targetLanguage: props.wwiseSettings.targetLanguage
        })
      ),
      {
        metaCompare: (a, b) => lineMetaCompare(a, b, props.wwiseSettings.targetLanguage),
        moveExisting: props.wwiseSettings.moveExisting,
        force: props.wwiseSettings.forceExport,
        createEvents: props.wwiseSettings.createEvents
      }
    )

    return result
  }
  useEffect(() => {
    let ignore = false

    getSoundStatus().then((result) => {
      if (result && !ignore) {
        setSoundStatus(result)
      }
    })

    return () => {
      ignore = true
    }
  }, [props.selectedLines, props.wwiseSettings])

  const lineInfo = useMemo(() => {
    const nTotalLines = props.allLines.length
    const nLinesWithSelectedTake = props.selectedLines.length
    const nLinesWithoutSelectedTake = nTotalLines - nLinesWithSelectedTake
    return {
      nTotalLines,
      nLinesWithSelectedTake,
      nLinesWithoutSelectedTake
    }
  }, [props.allLines, props.selectedLines])

  function pluralize(n: number, word: string) {
    return n === 1 ? word : word + 's'
  }

  const infoItems = [
    { value: lineInfo.nTotalLines, text: `${pluralize(lineInfo.nTotalLines, 'line')} selected` },
    { value: lineInfo.nLinesWithoutSelectedTake, text: `missing take` },
    { value: soundStatus?.numNew, text: 'to add' },
    { value: soundStatus?.numUpdate, text: 'to update' },
    { value: soundStatus?.numSkip, text: 'to skip' }
  ]

  return (
    <>
      <Row fontSize="14px" gap="10px" alignItems="center">
        {infoItems
          .map((item) => (
            <Row gap="7px">
              <Inline>{item.value}</Inline>
              <Inline color="var(--on-surface-light)">{item.text}</Inline>
            </Row>
          ))
          .flatMap((d, i) =>
            i < infoItems.length - 1 ? [d, <Block color="var(--on-surface-light)">·</Block>] : [d]
          )}

        <IconButton
          small
          size={14}
          icon={RefreshIcon}
          tooltipText="Refresh Wwise status"
          onClick={() => {
            getSoundStatus().then((result) => {
              if (result) {
                setSoundStatus(result)
              }
            })
          }}
        />
      </Row>
      <TextOutput>
        {(soundStatus?.tasks ?? []).map((task) => {
          const statusVerb: Record<SoundStatuses, string> = {
            new: 'ADD',
            skip: 'SKIP',
            update: 'UPDATE'
          }

          const Message = () => {
            if (task.message) {
              if (task.message.type === 'warning') {
                return (
                  <>
                    <Block />
                    <Row gap="6px" marginBottom="3px" whiteSpace="nowrap" textOverflow="ellipsis">
                      <InlineBlock color="var(--warning)">Warning: </InlineBlock>
                      {task.message.text}
                    </Row>
                    <Block />
                  </>
                )
              }
            }
            return null
          }

          if (task.type === 'sound') {
            return (
              <>
                <Block>
                  <Inline>{statusVerb[task.status]}</Inline>
                </Block>
                <Row color="var(--on-surface)" whiteSpace="nowrap" textOverflow="ellipsis">
                  {task.sound.folderStructure.workUnit ?? 'Default Work Unit'}
                  {task.sound.folderStructure.path &&
                    task.sound.folderStructure.path.length > 0 && (
                      <> \ {task.sound.folderStructure.path.map((p) => p.name).join(' \\ ')}</>
                    )}{' '}
                  \ {task.sound.name}
                </Row>
                <Block whiteSpace="nowrap">sound</Block>
                <Message />
              </>
            )
          } else if (task.type === 'event') {
            return (
              <>
                <Block>
                  <Inline>{statusVerb[task.status]}</Inline>
                </Block>
                <Row color="var(--on-surface)" whiteSpace="nowrap" textOverflow="ellipsis">
                  {task.sound.folderStructure.workUnit ?? 'Default Work Unit'}
                  {task.sound.folderStructure.path &&
                    task.sound.folderStructure.path.length > 0 && (
                      <> \ {task.sound.folderStructure.path.map((p) => p.name).join(' \\ ')}</>
                    )}{' '}
                  \ Play_{task.sound.name}_event
                </Row>
                <Block whiteSpace="nowrap">event</Block>
                <Message />
              </>
            )
          } else if (task.type === 'work-unit' && task.status === 'new') {
            return (
              <>
                <Block>
                  <Inline>CREATE</Inline>
                </Block>
                <Row color="var(--on-surface)" whiteSpace="nowrap" textOverflow="ellipsis">
                  {task.workUnit}
                </Row>
                <Block whiteSpace="nowrap">work unit</Block>
                <Message />
              </>
            )
          }
        })}
      </TextOutput>
    </>
  )
}

export const WwiseExportDialogWithExportTarget = (props: {
  exportTargetId: string
  onClose: () => void
}) => {
  const { data: exportTarget, refetch: refetchExportTarget } = useFetchResult<
    ExportTarget & { type: 'wwise' }
  >(`/export-targets/${props.exportTargetId}`, {})

  if (!exportTarget) {
    return null
  }

  return (
    <WwiseExportDialog
      settings={exportTarget}
      onClose={props.onClose}
      onSave={async (settings) => {
        await updateExportTarget(props.exportTargetId, {
          ...settings
        })
        refetchExportTarget()
      }}
      selectedIds={[]}
    />
  )
}

export const WwiseExportDialogLocalStorage = (props: {
  id?: string
  onClose: () => void
  selectedIds: string[]
}) => {
  const [settings, setSettings] = useLocalStorageState<WwiseSettingsType>(
    'speechless:integrations:wwise:folder' + props.id ?? '',
    defaultSettings
  )

  return (
    <WwiseExportDialog
      settings={settings}
      onClose={props.onClose}
      onSave={async (_settings) => {
        setSettings(_settings)
      }}
      selectedIds={props.selectedIds}
      settingsFooter={
        <>
          These settings are saved in this browser. For project wide settings you can create an{' '}
          <Link to={`/export-targets`} onRoute={routeTo}>
            export target
          </Link>
        </>
      }
    />
  )
}

export const WwiseExportDialog = (props: {
  settings: WwiseSettingsType
  onClose: () => void
  onSave: (settings: WwiseSettingsType) => void
  selectedIds: string[]
  settingsFooter?: string | ComponentChildren
}) => {
  const { currentGame, currentLanguage } = useStore('currentGame', 'currentLanguage')

  const [targetLanguage, setTargetLanguage] = useState<string>('')
  const [forceExport, setForceExport] = useState<boolean>(false)

  // Local Wwise settings
  const [wwisePort, setWwisePort] = useLocalStorageState('speechless:integrations:wwise:port', 8080)
  /**
   * successfullConnection, if this browser has seen a successful connection to Wwise
   * we can try connecting immediately when opening the export dialog
   */
  const [successfullConnection, setSuccessfullConnection] = useLocalStorageState<boolean>(
    'speechless:integrations:wwise:successfullConnection' + currentGame?.id ?? '',
    false
  )

  const [step, setStep] = useState<string>(successfullConnection ? 'preview' : 'notConnected')

  const { data: lines, isFetching: isFetchingLines } = useFetchResult<Line[]>(
    `/games/${currentGame!.id}/lines`,
    {
      lineIds: props.selectedIds
    }
  )
  const selectedLines =
    lines?.filter((line) => {
      if (!line.translations[currentLanguage]?.selectedTake?.processedUri) {
        return false
      }

      return true
    }) ?? []

  const steps: Record<
    string,
    {
      title: string
      titleIcon?: ComponentChildren
      onTitleIconClick?: () => void
      dismissable?: boolean
      actions: { text: string; type: string; onClick: () => void; disabled?: boolean }[]
    }
  > = {
    notConnected: {
      title: 'Export to Wwise',
      titleIcon: CloseIcon,
      onTitleIconClick: props.onClose,
      actions: []
    },
    connected: {
      title: 'Export to Wwise',
      titleIcon: CloseIcon,
      onTitleIconClick: props.onClose,
      actions: [{ text: 'Next', type: 'contained', onClick: () => setStep('preview') }]
    },
    preview: {
      title: 'Export to Wwise',
      titleIcon: CloseIcon,
      onTitleIconClick: props.onClose,
      actions: [
        { text: 'Export', type: 'contained', onClick: () => handleExport() },
        { text: 'Settings', type: 'text', onClick: () => setStep('settings') }
      ]
    },
    settings: {
      title: 'Export to Wwise',
      titleIcon: BackArrowIcon,
      onTitleIconClick: () => setStep('preview'),
      actions: [],
      dismissable: false
    },
    exporting: {
      title: 'Exporting',
      actions: [
        /**
         * Cancel would perhaps be nice, but needs to be implemented in WwiseConnection first.
         */
        // { text: 'Cancel', type: 'outlined', onClick: () => setStep('settings') },
        { text: 'Done', disabled: true, type: 'text', onClick: () => {} }
      ],
      dismissable: false
    },
    done: {
      title: 'Export done',
      titleIcon: BackArrowIcon,
      onTitleIconClick: () => setStep('preview'),
      actions: [{ text: 'Done', type: 'contained', onClick: props.onClose }]
    }
  }

  const wwise = useWwise({
    port: wwisePort,
    enabled: true,
    shouldConnect: successfullConnection,
    onImport: (message) => {
      showSnackbar(message)
    },
    onImportError: (message) => setError(new Error(message)),
    onConnectionClosed: () => setStep('notConnected')
  })

  useEffect(() => {
    if (wwise.isOpen && !successfullConnection) {
      setSuccessfullConnection(true)
    }
  }, [wwise.isOpen])

  useEffect(() => {
    if (wwise.projectInfo) {
      /**
       * Default to first language in Wwwise project language list
       */
      let language = wwise.projectInfo.languages[0].name

      /**
       * If Wwwise project language list contains language that match with the current language, use that instead
       */
      const match = wwise.projectInfo.languages.find(
        (language) => language.name === formatLanguage(currentLanguage)
      )
      if (match) {
        language = match.name
      }

      setTargetLanguage(language)
    }
  }, [wwise.projectInfo])

  if (!wwise.isOpen && wwise.connectionError) {
    setStep('notConnected')
  }
  if (wwise.isOpen && step === 'notConnected') {
    setStep('connected')
  }

  const handleSaveSettings = async (settings: WwiseSettingsType) => {
    await props.onSave(settings)
    setStep('preview')
  }

  const handleExport = async () => {
    if (!lines || !selectedLines || !wwise.batchImportSounds || !targetLanguage) {
      // TODO: Something is really wrong if we end up here, show error
      return
    }
    setStep('exporting')

    const startTime = performance.now()

    const sounds = selectedLines.map((line) =>
      lineToSound(line, {
        workUnit: props.settings.workUnit,
        folderStructure: props.settings.folderStructure,
        currentLanguage,
        currentGame: { name: currentGame!.name, id: currentGame!.id },
        targetLanguage
      })
    )

    const result = await wwise.batchImportSounds(sounds, {
      importLanguage: targetLanguage ?? undefined,
      createEvents: props.settings.createEvents,
      batchSize: 1,
      metaCompare: (a, b) => lineMetaCompare(a, b, targetLanguage),
      moveExisting: props.settings.moveExisting,
      force: forceExport
    })

    const elapsedTime = performance.now() - startTime
    console.log('Took', elapsedTime)

    console.log(result)
    setStep('done')
  }

  const Code = ({ children }: { children: ComponentChildren }) => (
    <InlineBlock component="code" color="var(--on-surface-light)">
      {children}
    </InlineBlock>
  )

  function WwiseInfo() {
    if (!wwise.wwiseInfo || !wwise.isOpen) {
      return null
    }

    return (
      <Row fontSize="14px" gap="10px" alignItems="center">
        {[
          <Block color="var(--on-surface-light)">
            {' '}
            Connected to {wwise.wwiseInfo?.displayName} ({wwise.wwiseInfo.version.displayName})
          </Block>,
          <Block color="var(--on-surface-light)">·</Block>,
          <Row gap="7px">
            <Inline color="var(--on-surface-light)">Project:</Inline>
            <Inline color="var(--on-surface)">
              {wwise.projectInfo?.displayTitle.split('-')[0]}
            </Inline>
          </Row>,
          <Block color="var(--on-surface-light)">·</Block>,
          <Row gap="7px" alignItems="center">
            <Inline color="var(--on-surface-light)">Language:</Inline>
            <Select
              text
              value={targetLanguage}
              options={wwise.projectInfo?.languages.map((language) => ({
                value: language.name,
                text: language.name
              }))}
              onChange={setTargetLanguage}
            />
          </Row>
        ]}
      </Row>
    )
  }

  return (
    <Dialog
      large={!['notConnected', 'connected'].includes(step)}
      title={steps[step].title}
      onClose={props.onClose}
      titleIcon={steps[step].titleIcon}
      onTitleIconClick={steps[step].onTitleIconClick}
      actions={steps[step].actions}
      dismissable={steps[step]?.dismissable}
      footer1={
        props.settingsFooter && ['settings'].includes(step) ? (
          <Block fontSize="14px" padding="20px 0" color="var(--on-surface-light)">
            {props.settingsFooter}
          </Block>
        ) : undefined
      }
    >
      <Col gap="20px" height="100%">
        {/* NOT CONNECTED */}
        {step === 'notConnected' && (
          <>
            <P>
              The Speechless Wwise integration allows you to export audio files directly from
              Speechless into your Wwise project.
            </P>
            <Block>
              <P>Get started:</P>
              <Block component="ul" display="flex" flexDirection="column" gap="10px">
                <li>Wwise must be running.</li>
                <li>
                  In Wwise under <Code>Project &gt; User Preferences</Code>, make sure{' '}
                  <Code>Wwise Authoring API</Code> is enabled.
                </li>
                <li>
                  Add <Code>{window.location.origin}</Code>{' '}
                  <IconButton
                    margin={-10}
                    tooltipText="Copy"
                    icon={() => <ContentCopyIcon size={14} />}
                    fill="var(--accent)"
                    onClick={() => {
                      copyToClipboard(window.location.origin)
                    }}
                  />{' '}
                  to the
                  <Code>Allow browser connections from</Code> field.
                </li>
                <li>
                  <Code>WAMP port</Code> must be the same in Wwise and Speechless (default is 8080).
                </li>
              </Block>
            </Block>
            <ConnectionSettings
              onConnect={() => {
                wwise.connect()
              }}
              port={wwisePort}
              onPortChange={setWwisePort}
              connectionError={wwise.connectionError}
            />
            <SpacerVertical />
          </>
        )}

        {/* CONNECTED */}
        {step === 'connected' && (
          <>
            <P>You are connected to Wwise</P>
            <Grid gridTemplateColumns="auto 1fr" columnGap="10px">
              <Block color="var(--on-surface-light)">Version:</Block>
              <Block>{wwise.wwiseInfo?.version.displayName}</Block>
              <Block color="var(--on-surface-light)">Project:</Block>
              <Block>{wwise.projectInfo?.displayTitle.split('-')[0]}</Block>
            </Grid>
          </>
        )}

        {/* PREVIEW */}
        {step === 'preview' && (
          <>
            <ExportPreview
              selectedLines={selectedLines}
              currentGame={{ name: currentGame!.name, id: currentGame!.id }}
              allLines={lines ?? []}
              wwiseSettings={{
                workUnit: props.settings.workUnit,
                folderStructure: props.settings.folderStructure,
                createEvents: props.settings.createEvents,
                forceExport,
                moveExisting: props.settings.moveExisting,
                targetLanguage
              }}
              getSoundStatus={wwise.getSoundStatus}
            />
            <Block marginTop="-10px">
              <WwiseInfo />
              {SHOW_FORCE_EXPORT && (
                <Checkbox
                  marginTop="-14px"
                  fontSize="14px"
                  checked={forceExport}
                  onChange={setForceExport}
                  label="Force export"
                />
              )}
            </Block>
          </>
        )}

        {/* SETTINGS */}
        {step === 'settings' && (
          <WwiseSettings
            workUnit={props.settings.workUnit}
            folderStructure={props.settings.folderStructure}
            createEvents={props.settings.createEvents}
            moveExisting={props.settings.moveExisting}
            gameId={currentGame!.id}
            onSave={handleSaveSettings}
          />
        )}

        {/* EXPORTING OR DONE */}
        {(step === 'exporting' || step === 'done') && (
          <>
            <Row>
              <ProgressLinear value={wwise.progress} />
            </Row>
            <TextOutput>
              {wwise.log.map((logRow) => {
                let color = 'var(--on-surface-light)'
                if (logRow.verb === 'failed' || logRow.type === 'warning') color = 'var(--warning)'
                return (
                  <>
                    <Block>
                      {logRow.verb && (
                        <Inline whiteSpace="nowrap" color={color}>
                          {logRow.verb.toUpperCase()}{' '}
                        </Inline>
                      )}
                    </Block>
                    <Row
                      gridColumn="span 2"
                      color="var(--on-surface)"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      {logRow.message}
                    </Row>
                  </>
                )
              })}
            </TextOutput>
          </>
        )}
      </Col>
    </Dialog>
  )
}
