import { h } from 'preact'
import {
  Button,
  Dialog,
  IconButton,
  List,
  ListItem,
  MoreIcon,
  PlusIcon,
  SpacerVertical
} from '@sodra/bongo-ui'
import { LineFilter, LineFilterSettings } from 'src/types'
import { Block, Row } from 'jsxstyle/preact'
import { confirm } from 'lib'
import { useState } from 'preact/hooks'
import { CreateLineFilter } from './CreateLineFilter'
import { deleteLineFilter, updateLineFilter } from 'src/actions/line-filters'
import { PopupMenuOption } from 'src/App/PopupMenu'
import { usePopupMenu } from 'src/App/use-popup-menu'
import { EditLineFilter } from './EditLineFilter'

type Props = {
  filters: LineFilter[]
  filterSettings: LineFilterSettings
  onClose: () => void
  onNewLineFilter: (filter: LineFilter) => void
  onOverwriteFilter: (filter: LineFilter) => Promise<void>
  onLoadFilter: (filter: LineFilter) => void
  onDeleteFilter: (filter: LineFilter) => void
  onEditFilter: (filter: LineFilter) => void
}

export const ManageLineFilters = ({
  filters,
  filterSettings,
  onClose,
  onNewLineFilter,
  onOverwriteFilter,
  onLoadFilter,
  onDeleteFilter,
  onEditFilter
}: Props) => {
  const [showCreateLineFilterDialog, setShowCreateLineFilterDialog] = useState(false)
  const [lineFilterToEdit, setLineFilterToEdit] = useState<LineFilter | undefined>(undefined)

  const overwriteFilter = async (selectedFilter: LineFilter) => {
    if (selectedFilter) {
      if (
        await confirm({
          title: 'Overwrite filter?',
          message: `Are you sure you want to overwrite filter "${selectedFilter.name}" with your current filter settings?"`,
          confirmText: 'Yes',
          rejectText: 'Cancel'
        })
      ) {
        await updateLineFilter(selectedFilter.id, {
          name: selectedFilter.name,
          settings: filterSettings
        })
        onOverwriteFilter(selectedFilter)
        onClose()
      }
    }
  }

  const loadFilter = async (selectedFilter: LineFilter) => {
    onLoadFilter(selectedFilter)
    onClose()
  }

  const filterPopupMenuOptions: PopupMenuOption[] = [
    {
      label: 'Rename',
      onClick: async (filter: LineFilter) => {
        setLineFilterToEdit(filter)
      }
    },
    {
      label: 'Delete',
      onClick: async (filter: LineFilter) => {
        if (await confirm(`Delete filter "${filter.name}"?`)) {
          if (await deleteLineFilter(filter.id)) {
            onDeleteFilter(filter)
          }
        }
      }
    }
  ]
  const { PopupMenu, showMenu } = usePopupMenu({ options: filterPopupMenuOptions })

  return (
    <Dialog
      title="Line filters"
      large
      onClose={onClose}
      actions={[
        {
          text: 'New',
          icon: PlusIcon,
          outlined: true,
          onClick: () => setShowCreateLineFilterDialog(true)
        }
      ]}
      secondaryActions={[
        {
          text: 'Cancel',
          onClick: onClose
        }
      ]}
    >
      <SpacerVertical />
      {filters.length === 0 && (
        <Block color="var(--on-surface-light)">
          Click the New button to create a new line filter with your current settings.
        </Block>
      )}
      {filters.length > 0 && (
        <>
          <List>
            {filters.map((filter) => {
              return (
                <ListItem
                  text={
                    <Row alignItems="center" gap="10px">
                      <Block flex="1">
                        <Block>{filter.name}</Block>
                      </Block>
                      <Button onClick={() => loadFilter(filter)}>Load</Button>
                      <Button onClick={() => overwriteFilter(filter)}>Overwrite</Button>
                      <IconButton
                        color="var(--on-surface-light)"
                        icon={MoreIcon}
                        onClick={(e: BuiClickEvent) => showMenu(e, filter)}
                      />
                    </Row>
                  }
                />
              )
            })}
          </List>
        </>
      )}
      <SpacerVertical large />
      {showCreateLineFilterDialog && (
        <CreateLineFilter
          filterSettings={filterSettings}
          onCancel={() => setShowCreateLineFilterDialog(false)}
          onCreate={async (newLineFilter: LineFilter) => {
            console.log({ newLineFilter })
            onNewLineFilter(newLineFilter)
            onClose()
          }}
        />
      )}
      {lineFilterToEdit && (
        <EditLineFilter
          filter={lineFilterToEdit}
          onClose={() => setLineFilterToEdit(undefined)}
          onUpdate={async (filter: LineFilter) => {
            onEditFilter(filter)
          }}
        />
      )}
      {filterPopupMenuOptions && PopupMenu}
    </Dialog>
  )
}

export default ManageLineFilters
