import {
  Button,
  Checkbox,
  Dialog,
  Link,
  P,
  SpacerHorizontal,
  SpacerVertical,
  Switch
} from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'
import { Block, Col, Grid, Row } from 'jsxstyle/preact'
import { useEffect, useState } from 'preact/hooks'
import { setError } from '../../actions'
import { post } from '../../api'
import { Line } from '../../types'
import Avatar from '../Avatar'
import Spinner from '../Spinner'

type Props = {
  line: Line
  language: string
  originalPhrase: string
  onSave: (params: { newPhrase?: string; newLines: string[] }) => void
}
export const RephraseLine = ({ line, language, originalPhrase, onSave }: Props) => {
  const [isFetching, setIsFetching] = useState(false)
  const [alternatives, setAlternatives] = useState([])

  const [currentPhrase, setCurrentPhrase] = useState<string | undefined>(originalPhrase)
  const [newLines, setNewLines] = useState<string[]>([])

  const fetchAlternatives = async () => {
    setIsFetching(true)
    try {
      const { data: alternatives } = await post(`/lines/${line.id}/alternatives`, {
        language
      })
      setAlternatives(alternatives)
      setIsFetching(false)
    } catch (err) {
      setError(err)
    }
  }

  const onClose = () => goBack(`/line/${line.id}`)

  const saveAndClose = () => {
    onSave({
      newPhrase: currentPhrase !== originalPhrase ? currentPhrase : undefined,
      newLines
    })
    onClose()
  }

  useEffect(() => {
    fetchAlternatives()
  }, [])

  const handleSelectNewLine = (phrase: string, include: boolean) => {
    if (include) {
      setNewLines([...new Set(newLines.concat(phrase))])
      if (currentPhrase === phrase) {
        setCurrentPhrase(originalPhrase)
      }
    } else {
      setNewLines(newLines.filter((line) => line !== phrase))
    }
  }

  const selectPhrase = async (phrase: string) => {
    // onSelect(phrase)
    setCurrentPhrase(phrase)
    handleSelectNewLine(phrase, false)
  }

  return (
    <Dialog
      large
      title="Rephrase line"
      disableAutofocus
      actions={[{ text: 'Cancel', onClick: onClose }]}
      secondaryActions={[
        {
          text: 'Save',
          type: 'contained',
          onClick: saveAndClose,
          disabled: currentPhrase === originalPhrase && newLines.length === 0
        }
      ]}
      onClose={onClose}
    >
      <P color="var(--on-surface-light)">
        Customize the style of the generated phrases on {line?.character?.name}s{' '}
        <Link to={`/character/${line?.character?.id}`} onRoute={routeTo}>
          character sheet.
        </Link>
      </P>
      <SpacerVertical />
      {isFetching && (
        <>
          <Block color="var(--on-surface-light)">
            Generating alternatives to the phrase "{originalPhrase}"...
          </Block>
          <Block position="relative" height="100px">
            <Spinner />
          </Block>
        </>
      )}
      {!isFetching && alternatives.length === 0 && (
        <>
          <Row alignItems="center">
            <Block>Could not find any alternatives to the phrase "{originalPhrase}"</Block>
            <SpacerHorizontal />
            <Button onClick={fetchAlternatives} whiteSpace="nowrap">
              Try again
            </Button>
          </Row>
        </>
      )}
      {!isFetching && alternatives.length > 0 && (
        <>
          <Block>
            Here are {alternatives.length} alternatives to the phrase "{originalPhrase}":
          </Block>
          <SpacerVertical large />
          <Grid
            gridTemplateColumns="40px auto 100px 80px"
            columnGap="20px"
            rowGap="10px"
            alignItems="center"
          >
            <Col />
            <Col />
            <Col alignItems="center">Replace text</Col>
            <Col alignItems="center">New line</Col>
            {alternatives.map((phrase) => {
              return (
                <>
                  <Block>
                    <Avatar src={line?.character?.picture} name={line?.character?.name} />
                  </Block>
                  <Block
                    color={
                      currentPhrase === phrase ? 'var(--on-surface)' : 'var(--on-surface-light)'
                    }
                  >
                    {phrase}
                  </Block>
                  <Col alignItems="center">
                    <Switch
                      onChange={(on: boolean) => selectPhrase(on ? phrase : originalPhrase!)}
                      on={phrase === currentPhrase}
                      tooltipText="Replace text in existing line"
                    />
                  </Col>
                  <Col alignItems="center">
                    <Checkbox
                      label=""
                      onChange={(checked: boolean) => handleSelectNewLine(phrase, checked)}
                      checked={newLines.includes(phrase)}
                      disabled={currentPhrase === phrase}
                    />
                  </Col>
                </>
              )
            })}
          </Grid>
        </>
      )}
    </Dialog>
  )
}
