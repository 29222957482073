import { Fragment, h } from 'preact'
import { AddIcon, Button, DownIcon, List, ListItem, SpacerHorizontal } from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'
import { useState } from 'preact/hooks'
import { routeTo } from '@sodra/prutt'

import { setCurrentGame } from '../actions'

import LargeDialog from './LargeDialog'
import Avatar from './Avatar'
import { useStore } from '../store'

export const GameSelect = () => {
  const { games = [], currentGame } = useStore('games', 'currentGame')

  const [showDialog, setShowDialog] = useState(false)

  const onClose = () => setShowDialog(false)

  if (!currentGame) {
    return null
  }

  return (
    <Fragment>
      <Button name="game-select" onClick={() => setShowDialog(true)}>
        <Row alignItems="center">
          <Block color="var(--on-surface)">{currentGame!.name}</Block>
          <SpacerHorizontal tiny />
          <DownIcon fill="var(--on-surface)" />
        </Row>
      </Button>
      {showDialog && (
        <LargeDialog
          title="Select game"
          action1Text="Close"
          onAction1Click={onClose}
          secondaryActionText="New game"
          secondaryActionIcon={AddIcon}
          onSecondaryActionClick={() => {
            routeTo('/new-game')
            setShowDialog(false)
          }}
          onClose={onClose}
        >
          <List>
            {games.map((game) => {
              return (
                <ListItem
                  visual={<Avatar size={40} src={game.picture} name={game.name} />}
                  text={game.name}
                  onClick={() => {
                    setCurrentGame(game)
                    setShowDialog(false)
                  }}
                />
              )
            })}
          </List>
        </LargeDialog>
      )}
    </Fragment>
  )
}

export default GameSelect
