import { createCanvas } from '../../lib/create-canvas'
import { getViewportTime } from '../../lib/get-viewport-time'

type Options = {
  ctx: CanvasRenderingContext2D
  waveformImage: HTMLCanvasElement | OffscreenCanvas
  duration: number
  startTime: number
  endTime: number
  currentTime: number
  pos: number
  zoom: number
  color: string
  selectedBgColor?: string
  progressBgColor?: string
}

// Alt 2. Draw using clip path
export const drawUsingClipPath = ({
  ctx,
  waveformImage,
  duration,
  startTime,
  endTime,
  currentTime,
  pos,
  zoom,
  color,
  selectedBgColor,
  progressBgColor
}: Options) => {
  //console.log('drawUsingClip')

  const { width, height } = waveformImage

  const { min: viewportMin, max: viewportMax } = getViewportTime(duration, pos, zoom)
  //console.log('viewPortTime', viewportMin, viewportMax)

  // Start
  const start = (startTime - viewportMin) / (viewportMax - viewportMin)
  const startMax = Math.min(width, start * width)
  let startCanvas
  if (startMax > 0) {
    //console.log('draw start', start, startMax)
    startCanvas = createCanvas(width, height)
    const ctx = startCanvas.getContext('2d') as
      | CanvasRenderingContext2D
      | OffscreenCanvasRenderingContext2D
    if (ctx) {
      ctx.beginPath()
      ctx.rect(0, 0, startMax, height)
      ctx.clip()
      ctx.globalAlpha = 0.2
      ctx.drawImage(waveformImage, 0, 0)
      ctx.restore()
    }
  }

  // End
  const end = (viewportMax - endTime) / (viewportMax - viewportMin)
  const endMin = Math.max(0, (1 - end) * width)
  let endCanvas
  if (endMin < width) {
    //console.log('draw end', end, endMin)
    endCanvas = createCanvas(width, height)
    const ctx = endCanvas.getContext('2d') as
      | CanvasRenderingContext2D
      | OffscreenCanvasRenderingContext2D
    if (ctx) {
      ctx.beginPath()
      ctx.rect(endMin, 0, width - endMin, height)
      ctx.clip()
      ctx.globalAlpha = 0.2
      ctx.drawImage(waveformImage, 0, 0)
      ctx.restore()
    }
  }

  // Progress
  const progressStart = (startTime - viewportMin) / (viewportMax - viewportMin)
  const progressCurrent = (startTime + currentTime - viewportMin) / (viewportMax - viewportMin)
  const progressMin = Math.max(0, progressStart * width)
  const progressMax = Math.min(width, progressCurrent * width)

  // Rest
  const restMin = Math.max(0, startMax)
  const restMax = Math.min(width, endMin)
  let restCanvas
  if (restMax - restMin > 0) {
    //console.log('draw rest', restMin, restMax)
    restCanvas = createCanvas(width, height)
    const ctx = restCanvas.getContext('2d') as
      | CanvasRenderingContext2D
      | OffscreenCanvasRenderingContext2D
    if (!ctx) return

    // Background fill for selected area
    if (selectedBgColor) {
      ctx.fillStyle = selectedBgColor
      ctx.fillRect(restMin, 0, restMax - restMin, height)
    }

    // Background color for progress area
    if (progressBgColor) {
      ctx.fillStyle = progressBgColor
      ctx.fillRect(progressMin, 0, progressMax - progressMin, height)
    }

    // Stroke color for selected area
    ctx.beginPath()
    ctx.rect(restMin, 0, restMax - restMin, height)
    ctx.clip()
    ctx.drawImage(waveformImage, 0, 0)
    ctx.restore()
  }

  // Draw progress
  let progressCanvas
  if (currentTime > 0 && progressMin < width && progressMax > 0) {
    //console.log('draw progress', progressMin, progressMax)
    progressCanvas = createCanvas(width, height)
    const ctx = progressCanvas.getContext('2d') as
      | CanvasRenderingContext2D
      | OffscreenCanvasRenderingContext2D
    if (!ctx) return
    ctx.drawImage(waveformImage, 0, 0)
    ctx.globalCompositeOperation = 'source-in'
    ctx.fillStyle = color
    ctx.fillRect(progressMin, 0, progressMax - progressMin, height)
    ctx.globalCompositeOperation = 'source-over'
    ctx.fillStyle = color
    ctx.fillRect(progressMax, 0, 1, height)
  }

  ctx.clearRect(0, 0, width, height)
  if (startCanvas) {
    ctx.drawImage(startCanvas, 0, 0)
  }
  if (restCanvas) {
    ctx.drawImage(restCanvas, 0, 0)
  }
  if (endCanvas) {
    ctx.drawImage(endCanvas, 0, 0)
  }
  if (progressCanvas) {
    ctx.drawImage(progressCanvas, 0, 0)
  }
}
