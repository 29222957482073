import { h } from 'preact'
import { Dialog, SpacerVertical } from '@sodra/bongo-ui'

import { SuperForm } from '../../SuperForm'
import { useStore } from '../../../store'
import { LineFilter, LineFilterSettings } from 'src/types'
import { createLineFilter } from 'src/actions/line-filters'

type CreateLineFilterParams = {
  filterSettings: LineFilterSettings
  onCancel: () => void
  onCreate: (filter: LineFilter) => void
}

export const CreateLineFilter = ({
  filterSettings,
  onCancel,
  onCreate
}: CreateLineFilterParams) => {
  const { isCreatingLineFilter } = useStore('isCreatingLineFilter')

  const handleSubmit = async (params: any) => {
    const newFilter = await createLineFilter({ name: params.name, settings: filterSettings })
    if (newFilter) {
      onCreate(newFilter)
    }
  }

  return (
    <Dialog title="Create line filter" onClose={onCancel}>
      <SuperForm
        onSubmit={handleSubmit}
        onCancel={onCancel}
        loading={isCreatingLineFilter}
        elements={[
          {
            element: 'TextField',
            name: 'name',
            label: 'Name',
            required: true
          }
        ]}
      />
      <SpacerVertical />
    </Dialog>
  )
}
