import { h } from 'preact'
import { Dialog, SpacerVertical } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { showSnackbar, updateLineLabel } from '../../../actions'
import { useStore } from '../../../store'
import { capitalize } from '../../capitalize'
import { SuperForm } from '../../SuperForm'

type Props = {
  lineLabelId: string
}

export const UpdateLineLabel = ({ lineLabelId }: Props) => {
  const { lineLabels, isUpdatingLineLabel } = useStore('lineLabels', 'isUpdatingLineLabel')

  const lineLabel = lineLabels?.find((lineLabel) => lineLabel.id === lineLabelId)

  const onBack = () => goBack('/lines/line-labels')

  if (!lineLabel) {
    return
  }

  const handleSubmit = async (params: any) => {
    if (await updateLineLabel(lineLabelId, params)) {
      showSnackbar(`Line label ${capitalize(params.name)} updated`)
      onBack()
    }
  }

  return (
    <Dialog title="Edit line label" onClose={onBack}>
      <SuperForm
        disableSubmitIfNoChanges={true}
        onSubmit={handleSubmit}
        onCancel={onBack}
        loading={isUpdatingLineLabel}
        elements={[
          {
            element: 'TextField',
            name: 'name',
            label: 'Name',
            required: true,
            value: capitalize(lineLabel.name)
          }
        ]}
      />
      <SpacerVertical />
    </Dialog>
  )
}
