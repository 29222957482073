import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Button, IconButton, MenuIcon, SpacerHorizontal } from '@sodra/bongo-ui'
import { Route, routeTo, Switch } from '@sodra/prutt'
import { Block, Col, Row } from 'jsxstyle/preact'

import { logout } from '../actions'

import { usePopupMenu } from './use-popup-menu'

import Avatar from './Avatar'
import Account from './Account'
import { GameSelect } from './GameSelect'
import NewGame from './NewGame'
import { Settings } from './Settings'
import LeftMenu from './LeftMenu'
import { LeftMenuContent } from './LeftMenuContent'

import GameDashboard from './GameDashboard'

import Characters from './Characters'
import Character from './Character'
import CreateCharacter from './CreateCharacter'

import Events from './Events'
import Event from './Event'
import CreateEvent from './CreateEvent'

import Scenes from './Scenes'
import Scene from './Scene'
import { CreateScene } from './CreateScene'

import { LinesPage } from './Lines'
import CreateLine from './CreateLine'

import Sessions from './Sessions'
import { CreateSession } from './CreateSession/CreateSession'
import Session from './Session'

import { Chats } from './Chats'

import AIVoices from './AIVoices'
import AIVoice from './AIVoice'
import CreateAIVoice from './CreateAIVoice'

import ExportTargets from './ExportTargets'
import CreateExportTarget from './ExportTargets/CreateExportTarget'
import ExportTargetSettings from './ExportTarget'
import Line from './Line'

import UploadExcel from './UploadExcel'
import UploadAudio from './UploadAudio'
import Logo from './Logo'
import { Voices } from './Voices'
import { Voice } from './Voice'
import { Files } from './Files'
import { UploadFile } from './Files/UploadFile'
import { useStore } from '../store'
import { LineAttributes } from './Lines/LineAttributes'
import { LineLabels } from './Lines/LineLabels'
import { LanguageSelect } from './LanguageSelect'
import { AddSessionLines } from './Session/AddSessionLines'
import { RecordLines } from './Lines/RecordLines'
import { MainAudioPlayer } from './MainAudioPlayer'
import { ExportTargetLogs } from './ExportTargetLogs'
import { ImportSources } from './ImportSources'
import { CreateImportSource } from './ImportSources/CreateImportSource'
import ImportSource from './ImportSource'
import { ImportSourceLogs } from './ImportSourceLogs'
import { ImportAIVoices } from './ImportAIVoices'
import { Orders } from './Orders'
import { OrderPage } from './Order'

export const LoggedIn = () => {
  const { currentUser, currentGame, isNarrow } = useStore('currentUser', 'currentGame', 'isNarrow')
  const [leftMenuVisible, setLeftMenuVisible] = useState(false)

  const { PopupMenu, showMenu } = usePopupMenu({
    options: [
      {
        label: 'My account',
        link: {
          href: '/account',
          onRoute: routeTo
        }
      },
      { label: 'Log out', onClick: logout }
    ]
  })

  const hasManageAccess = currentGame?.role === 'owner' || currentGame?.role === 'admin'

  const DebugEnv = ({ envName }: { envName: string }) => (
    <>
      <Row color="magenta" alignItems="center" whiteSpace="nowrap" height="100%">
        <Block>{envName.toUpperCase()}</Block>
      </Row>
      <SpacerHorizontal />
    </>
  )

  return (
    <Col position="absolute" top="0" left="0" bottom="0" right="0" overflow="hidden">
      <Row
        alignItems="center"
        padding="10px 20px"
        background="var(--surface)"
        borderBottom="1px solid var(--container-outline)"
        gap="10px"
      >
        {(import.meta.env.VITE_DEBUG_ENV_NAME ?? '').toLowerCase() === 'localhost' && (
          <DebugEnv envName={import.meta.env.VITE_DEBUG_ENV_NAME} />
        )}
        {isNarrow && (
          <IconButton
            icon={MenuIcon}
            color="var(--on-surface)"
            onClick={() => setLeftMenuVisible((visible) => !visible)}
          />
        )}
        {!isNarrow && <Logo href="/" />}
        <GameSelect />
        <Block flex="1" />
        {currentGame?.secondaryLanguages && currentGame?.secondaryLanguages.length > 0 && (
          <LanguageSelect />
        )}
        <Avatar size={40} onClick={showMenu} src={currentUser!.picture} name={currentUser!.name} />
      </Row>
      <Row flex="1" overflow="hidden">
        {!isNarrow && (
          <Block
            width="250px"
            flexShrink={0}
            borderRight="solid 1px var(--container-outline-lighter)"
            overflowY="auto"
          >
            <LeftMenuContent />
          </Block>
        )}
        <Block flex="1" position="relative" overflow="hidden">
          <Switch>
            <Route path="/account" component={Account} />
            <Route path="/new-game" component={NewGame} />
            {currentGame && <Route path="/settings" component={Settings} />}
            {currentGame && <Route path="/characters" component={Characters} />}
            {currentGame && <Route path="/create-character" component={CreateCharacter} />}
            {currentGame && <Route path="/character/:characterId" component={Character} />}

            {currentGame && <Route path="/events" component={Events} />}
            {currentGame && <Route path="/create-event" component={CreateEvent} />}
            {currentGame && <Route path="/event/:eventId" component={Event} />}

            {currentGame && <Route path="/scenes" component={Scenes} />}
            {currentGame && <Route path="/create-scene" component={CreateScene} />}
            {currentGame && <Route path="/scene/:sceneId" component={Scene} />}

            {currentGame && hasManageAccess && (
              <Route path="/lines/line-attributes" component={LineAttributes} />
            )}
            {currentGame && hasManageAccess && (
              <Route path="/lines/line-labels" component={LineLabels} />
            )}
            {currentGame && <Route path="/lines" component={LinesPage} />}
            {currentGame && <Route path="/create-line" component={CreateLine} />}
            {currentGame && <Route path="/line/:lineId" component={Line} />}

            {currentGame && <Route path="/orders/:orderId" component={OrderPage} />}
            {currentGame && <Route path="/orders" component={Orders} />}

            {currentGame && <Route path="/sessions" component={Sessions} />}
            {currentGame && <Route path="/create-session" component={CreateSession} />}
            {currentGame && (
              <Route path="/session/:sessionId/lines/add-lines" component={AddSessionLines} />
            )}
            {currentGame && <Route path="/session/:sessionId" component={Session} />}
            {currentGame && <Route path="/chats/:chatId?" component={Chats} />}

            {currentGame && <Route path="/export-targets" component={ExportTargets} />}
            {currentGame && <Route path="/create-export-target" component={CreateExportTarget} />}
            {currentGame && (
              <Route path="/export-target/:exportTargetId/logs" component={ExportTargetLogs} />
            )}
            {currentGame && (
              <Route path="/export-target/:exportTargetId" component={ExportTargetSettings} />
            )}

            {currentGame && <Route path="/import-sources" component={ImportSources} />}
            {currentGame && <Route path="/create-import-source" component={CreateImportSource} />}
            {currentGame && (
              <Route path="/import-source/:importSourceId/logs" component={ImportSourceLogs} />
            )}

            {currentGame && (
              <Route path="/import-source/:importSourceId" component={ImportSource} />
            )}

            {currentGame && <Route path="/ai-voices" component={AIVoices} />}
            {currentGame && <Route path="/create-ai-voice" component={CreateAIVoice} />}
            {currentGame && <Route path="/import-ai-voices" component={ImportAIVoices} />}
            {currentGame && <Route path="/ai-voice/:aiVoiceId" component={AIVoice} />}

            {currentGame && <Route path="/upload-excel" component={UploadExcel} />}
            {currentGame && <Route path="/upload-audio" component={UploadAudio} />}

            {currentGame && <Route path="/voices" component={Voices} />}
            {currentGame && <Route path="/voice/:voiceId" component={Voice} />}

            {currentGame && <Route path="/files" component={Files} />}
            {currentGame && <Route path="/upload-file" component={UploadFile} />}

            {currentGame && <Route path="/record-session/:sessionId" component={RecordLines} />}
            {currentGame && <Route path="/record-lines" component={RecordLines} />}

            {currentGame && <Route exact path="/" component={GameDashboard} />}
            {currentGame && <div>404</div>}
            <Col alignItems="center" justifyContent="center" height="80%">
              <Button
                contained
                link={{
                  href: '/new-game',
                  onRoute: routeTo
                }}
              >
                Create game
              </Button>
            </Col>
          </Switch>
        </Block>
      </Row>
      <MainAudioPlayer />
      <LeftMenu visible={leftMenuVisible} onClose={() => setLeftMenuVisible(false)} />
      {PopupMenu}
    </Col>
  )
}

export default LoggedIn
