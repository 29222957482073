import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { fetchEvent } from '../../actions'
import { useEffectSkipFirst } from '../../use-effect-skip-first'

import Spinner from '../Spinner'

import { Block } from 'jsxstyle/preact'
import { InlineBlock } from 'jsxstyle/preact'

import { AddIcon, DeleteIcon, EditIcon, SpacerVertical } from '@sodra/bongo-ui'

import { ActivityLog } from '../ActivityLog'

import { deleteEvent, showSnackbar, updateEvent } from '../../actions'

import Avatar from '../Avatar'
import Page from '../Page'
import PictureDialog from '../PictureDialog'
import { useStore } from '../../store'
import { SuperForm } from '../SuperForm'
import { confirm } from 'lib'

type Props = {
  eventId: string
}

const Event = ({ eventId }: Props) => {
  const { currentGame, event, isUpdatingEvent } = useStore(
    'currentGame',
    'event',
    'isUpdatingEvent'
  )

  // Handle switch game
  useEffectSkipFirst(() => {
    routeTo('/events', true)
  }, [currentGame!.id])

  useEffect(() => {
    fetchEvent(eventId)
  }, [eventId])

  const onBack = () => goBack('/events')

  const closeDialog = () => goBack(`/event/${event!.id}`)

  const handleSubmit = async (values: any) => {
    if (await updateEvent(values)) {
      showSnackbar('Event details saved')
      onBack()
    }
  }

  const updatePicture = async (url: string) => {
    await updateEvent({ picture: url })
    return true
  }

  const removePicture = async () => {
    await updateEvent({ picture: '' })
    return true
  }

  const handleDelete = async () => {
    if (await confirm(`Delete event ${event!.name}?`)) {
      if (await deleteEvent()) {
        showSnackbar(`Event ${event?.name} deleted`)
        onBack()
      }
    }
  }

  const handleAddLines = async () => {
    if (
      await confirm({
        title: 'Add existing lines',
        confirmText: 'Select lines',
        message: (
          <>
            On the lines page, select the lines you want to add to the event and click the{' '}
            <InlineBlock padding="0 10px">
              <pre>Add to…</pre>
            </InlineBlock>{' '}
            button.
          </>
        )
      })
    ) {
      routeTo(`/lines`)
    }
  }

  if (!event || event.id !== eventId) {
    return <Spinner />
  }

  return (
    <Page
      title={event!.name}
      actions={[
        {
          label: 'Delete',
          icon: DeleteIcon,
          onClick: handleDelete
        },
        {
          label: 'Add existing lines',
          icon: AddIcon,
          onClick: handleAddLines
        }
      ]}
      onBack={onBack}
    >
      <Block maxWidth="500px">
        <Avatar
          actionIcon={EditIcon}
          onActionClick={() => routeTo(`/event/${event!.id}/picture`)}
          size={150}
          src={event.picture}
        />
        <SpacerVertical />

        <SuperForm
          disableSubmitIfNoChanges={true}
          onSubmit={handleSubmit}
          onCancel={onBack}
          loading={isUpdatingEvent}
          submitText="Save"
          elements={[
            {
              element: 'TextField',
              label: 'Name',
              name: 'name',
              value: event?.name,
              required: true
            },
            {
              element: 'TextArea',
              label: 'Description',
              name: 'description',
              value: event?.description
            }
          ]}
        />
      </Block>
      <SpacerVertical large />
      <Block maxWidth="800px">
        <ActivityLog
          parameters={{
            gameId: currentGame!.id,
            eventId: event!.id
          }}
        />
      </Block>
      <Route
        path="/event/:eventId/picture"
        render={() => (
          <PictureDialog
            title="Event picture"
            url={event.picture}
            onUpdate={updatePicture}
            onRemove={removePicture}
            onClose={closeDialog}
          />
        )}
      />
    </Page>
  )
}

export default Event
