import { FilterListIcon, SelectChip } from '@sodra/bongo-ui'
import { Fragment, h } from 'preact'
import { capitalize } from '../capitalize'
import { usePopupMenu } from '../use-popup-menu'

const formatGender = (gender: string) => `Sounds like ${gender}`

type Props = {
  value?: string
  onChange: (value?: string) => void
}

export const GenderSelectChip = ({ value, onChange }: Props) => {
  const { PopupMenu, showMenu } = usePopupMenu({
    options: [
      {
        label: 'Sounds like',
        disabled: true
      },
      {
        label: 'Male',
        onClick: () => onChange('male')
      },
      {
        label: 'Female',
        onClick: () => onChange('female')
      },
      {
        label: 'Neither',
        onClick: () => onChange('neither')
      }
    ]
  })

  return (
    <Fragment>
      <SelectChip
        label="Gender"
        icon={FilterListIcon}
        value={value && formatGender(value)}
        onClick={showMenu}
        onClear={() => onChange()}
        whiteSpace="nowrap"
      />
      {PopupMenu}
    </Fragment>
  )
}
