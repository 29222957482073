import { checkVAT, countries } from 'jsvat'

export const validateVatId = (vatId: string, countryCode = '') => {
  const vatCountry = countries.find((c) => c.codes[0] === countryCode)
  if (vatCountry) {
    const prettyVatId = vatId?.replace(/\W/g, '') || ''
    if (countryCode !== 'GB') {
      const res = checkVAT(prettyVatId, [vatCountry])
      return res.isValid
    }
  }
  return true
}
