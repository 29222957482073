import { h } from 'preact'
import { useState } from 'preact/hooks'
import { BasicDialog, Form, NativeSelect, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { createInvitation, showSnackbar } from '../../actions'
import { useStore } from '../../store'

const roleOptions = [
  { value: 'owner', label: 'Owner' },
  { value: 'admin', label: 'Admin' },
  { value: 'editor', label: 'Editor' }
]

type Props = {
  onClose: () => void
}

export const InviteMember = ({ onClose }: Props) => {
  const { isCreatingInvitation } = useStore('isCreatingInvitation')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [role, setRole] = useState('')
  const [roleError, setRoleError] = useState('')

  const handleSubmit = async () => {
    setEmailError('')
    setRoleError('')

    let hasErrors = false

    if (email.trim() === '') {
      setEmailError('Please enter email')
      hasErrors = true
    }

    if (role === '') {
      setRoleError('Please select role')
      hasErrors = true
    }

    if (hasErrors) {
      return
    }

    if (await createInvitation({ email, role })) {
      showSnackbar('Invitation sent')
      onClose()
    }
  }

  return (
    <BasicDialog
      title="Invite member"
      primaryActionText="Invite"
      primaryActionLoading={isCreatingInvitation}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <TextField
          width="100%"
          label="Email"
          value={email}
          onInput={setEmail}
          errorText={emailError}
        />
        <SpacerVertical />
        <NativeSelect
          width="100%"
          label="Role"
          options={roleOptions}
          value={role}
          onChange={setRole}
          errorText={roleError}
        />
      </Form>
    </BasicDialog>
  )
}

export default InviteMember
