import { store } from '../store'
import { get, post, patch, del } from '../api'
import { File as GameFile } from '../types'

export const fetchFiles = async (params: any) => {
  try {
    store.setState({ isFetchingFiles: true })
    const {
      data: files,
      meta: { total }
    } = await get(`/files`, params)
    store.setState({ isFetchingFiles: false, totalFiles: total, files })
  } catch (error) {
    store.setState({ isFetchingFiles: false, error: error as Error })
  }
}

export const uploadFile = async (
  file: File,
  { filename, sessionId, chatId }: { filename: string; sessionId?: string; chatId?: string }
): Promise<GameFile | undefined> => {
  try {
    store.setState({ isUploadingFile: true })

    const { currentGame } = store.getState()

    const contentType = file.type

    const {
      data: { signedUrl, uri }
    } = await post('/sign-url', { prefix: 'files', contentType })

    await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': contentType
      }
    })

    const { data: gameFile } = await post(`/files`, {
      gameId: currentGame?.id,
      sessionId,
      chatId,
      filename,
      uri,
      contentType,
      bytes: file.size
    })

    store.setState({ isUploadingFile: false, file: gameFile })
    return gameFile
  } catch (error) {
    store.setState({ isUploadingFile: false, error: error as Error })
  }
}

export const updateFile = async (fileId: string, params: any): Promise<boolean> => {
  try {
    store.setState({ isUpdatingFile: true })
    const { data: file } = await patch(`/files/${fileId}`, params)
    store.setState({ isUpdatingFile: false, file })
    return true
  } catch (error) {
    store.setState({ isUpdatingFile: false, error: error as Error })
    return false
  }
}

export const deleteFile = async (fileId: string): Promise<boolean> => {
  try {
    store.setState({ isDeletingFile: true })

    await del(`/files/${fileId}`)

    store.setState({ isDeletingFile: false })
    return true
  } catch (error) {
    store.setState({ isDeletingFile: false, error: error as Error })
    return false
  }
}
