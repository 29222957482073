import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Dialog, List, ListItem, SpacerVertical } from '@sodra/bongo-ui'

import { useFetchResult } from '../../use-fetch-result'
import { Uploader } from '../../types'

import SearchTextField from '../SearchTextField'
import Avatar from '../Avatar'
import { useStore } from '../../store'

type Props = {
  onClose: () => void
  onSelect: (uploader: Uploader) => void
}

export const SelectUploader = ({ onClose, onSelect }: Props) => {
  const { currentGame, isNarrow } = useStore('currentGame', 'isNarrow')

  const [query, setQuery] = useState('')

  const { data: uploaders = [] }: { data: Uploader[] | undefined } = useFetchResult(
    `/files/uploaders`,
    {
      gameId: currentGame?.id,
      query
    }
  )

  return (
    <Dialog
      large
      full={isNarrow}
      dismissable={false}
      title="Select uploader"
      onClose={onClose}
      actions={[{ text: 'Close', onClick: onClose }]}
    >
      <SearchTextField value={query} onChange={setQuery} />
      <SpacerVertical />
      <List>
        {uploaders.map((uploader: Uploader) => {
          return (
            <ListItem
              visual={<Avatar size={30} src={uploader.picture} name={uploader.name} />}
              text={uploader.name}
              onClick={() => {
                onSelect(uploader)
                onClose()
              }}
            />
          )
        })}
      </List>
    </Dialog>
  )
}
