import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { Button, Checkbox, EditIcon, Form, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { createVirtualMember, showSnackbar, updateUser } from '../../actions'

import Page from '../Page'
import PictureDialog from '../PictureDialog'
import Avatar from '../Avatar'
import { useStore } from '../../store'

export const CreateVirtualGameMember = () => {
  const [isCreatingVirtualUser, setIsCreatingVirtualUser] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [picture, setPicture] = useState('')

  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')

  useEffect(() => {
    if (firstName && firstName.trim() !== '') {
      setFirstNameError('')
    }
    if (lastName && lastName.trim() !== '') {
      setLastNameError('')
    }
  }, [firstName, lastName])

  const virtualUserName = firstName.trim() + (lastName.trim() ? ` ${lastName.trim()}` : '')
  const onBack = () => goBack('/settings/manage-access')

  const handleSave = async () => {
    let errorId

    if (firstName.trim() === '') {
      setFirstNameError('Please enter first name')
      errorId = 'firstName'
    }
    if (lastName.trim() === '') {
      setFirstNameError('Please enter last name')
      errorId = 'lastName'
    }

    if (errorId) {
      const el = document.querySelector(`#${errorId}`)
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    if (!errorId) {
      setIsCreatingVirtualUser(true)
      await createVirtualMember({
        firstName,
        lastName,
        picture
      })
      setIsCreatingVirtualUser(false)
      onBack()
      showSnackbar(`Virtual member ${virtualUserName} created`)
    }
  }

  const updatePicture = async (url: string) => {
    setPicture(url)
    return true
  }

  const removePicture = async () => {
    setPicture('')
    return true
  }

  return (
    <Page title="Create virtual member" onBack={onBack}>
      <Block maxWidth="500px">
        <Avatar
          actionIcon={EditIcon}
          onActionClick={() =>
            routeTo('/settings/manage-access/create-virtual-game-member/picture')
          }
          size={150}
          src={picture}
          name={virtualUserName}
        />
        <SpacerVertical />
        <Form onSubmit={handleSave}>
          <TextField
            width="100%"
            label="First name"
            value={firstName}
            onInput={setFirstName}
            errorText={firstNameError}
            props={{
              id: 'firstName'
            }}
          />
          <SpacerVertical />
          <TextField width="100%" label="Last name" value={lastName} onInput={setLastName} />
          <SpacerVertical />
          <Button type="submit" contained loading={isCreatingVirtualUser}>
            Create virtual member
          </Button>
        </Form>
      </Block>

      <Route
        path="/settings/manage-access/create-virtual-game-member/picture"
        render={() => (
          <PictureDialog
            title="Virtual user picture"
            url={picture}
            name={virtualUserName}
            onUpdate={updatePicture}
            onRemove={removePicture}
            onClose={() => goBack('/settings/manage-access/virtual-game-member')}
          />
        )}
      />
    </Page>
  )
}

export default CreateVirtualGameMember
