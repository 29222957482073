import { MutableRef, useEffect } from 'preact/hooks'

export function useCanvas(
  canvasRef: MutableRef<HTMLCanvasElement | null>,
  draw: (ctx: CanvasRenderingContext2D, width: number, height: number, time?: number) => void,
  onResize?: (width: number, height: number) => void
) {
  const tick = (t: number = 0) => {
    if (!canvasRef.current) return
    const ctx = canvasRef.current.getContext('2d')
    if (!ctx) return
    draw(ctx, canvasRef.current.width, canvasRef.current.height, t)
  }

  useEffect(() => {
    tick()
  }, [draw])

  // Canvas resize observer
  useEffect(() => {
    if (!canvasRef.current) return
    const canvas = canvasRef.current
    canvas.width = canvas.width * window.devicePixelRatio
    canvas.height = canvas.height * window.devicePixelRatio

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === canvasRef.current) {
          canvas.width = entry.contentRect.width * window.devicePixelRatio
          canvas.height = entry.contentRect.height * window.devicePixelRatio
          tick()
          if (onResize) {
            onResize(canvas.width, canvas.height)
          }
        }
      }
    })
    resizeObserver.observe(canvasRef.current)

    return () => {
      if (!canvasRef.current) return
      resizeObserver.unobserve(canvasRef.current)
    }
  }, [canvasRef.current])

  return { tick }
}
