import { h, Fragment } from 'preact'
import { Block, Inline } from 'jsxstyle/preact'
import { P } from '@sodra/bongo-ui'

export const Languages = ({ voice }) => {
  const languages = voice.languages
    ? Object.entries(
        voice.languages.reduce((obj, curr) => {
          if (!obj[curr.language]) {
            obj[curr.language] = []
          }
          obj[curr.language].push(curr.accent)
          return obj
        }, {})
      )
    : []

  return (
    <Fragment>
      <Block fontSize="14px" marginBottom="1rem" color="var(--on-surface-light)">
        Languages
      </Block>
      {languages.length === 0 && ' — '}
      {languages.map(([lang, accents]) => {
        return (
          <P margin="1rem 0">
            {lang} <Inline color="var(--on-surface-light)">{accents.join(' · ')}</Inline>
          </P>
        )
      })}
    </Fragment>
  )
}
