import { h } from 'preact'
import { Dialog, Link, SpacerHorizontal, SpacerVertical } from '@sodra/bongo-ui'
import { Block, InlineBlock } from 'jsxstyle/preact'
import { formatCurrency } from 'lib'
import { get } from 'src/api'
import { BillingAccount, Order } from 'src/types'
import { routeTo } from '@sodra/prutt'
import { acceptOrder } from 'src/actions/orders'
import { useEffect, useState } from 'preact/hooks'
import Spinner from '../Spinner'
import { CreditCardMissing } from './CreditCardMissing'
import { useStore } from 'src/store'

const getCost = (order: Order) => {
  const billingAccount = order.billingAccount || order.game?.billingAccount

  const includeVAT = billingAccount?.address?.country === 'Sweden'

  const total = order.amount

  const vatPercent = includeVAT ? 0.25 : 0
  const vat = total * vatPercent

  const totalIncVAT = total + vat
  return {
    total,
    vat,
    totalIncVAT
  }
}

type ValidateParams = {
  billingAccount: BillingAccount
  orderId: string
}

const getValidationError = ({ billingAccount, orderId }: ValidateParams) => {
  // Make sure Stripe customer exists
  if (!billingAccount.stripeCustomerId) {
    const params = new URLSearchParams({ onSuccessUrl: `/orders/${orderId}/approve` })
    return (
      <Block>
        Please make sure that your{' '}
        <Link to={`/settings/billing/${billingAccount.id}?${params.toString()}`} onRoute={routeTo}>
          billing account
        </Link>{' '}
        is correct. Then try again. Contact us if the problem persists.
      </Block>
    )
  }

  // Make sure Stripe approves the customer location
  if (billingAccount.taxLocationStatus === 'unrecognized_location') {
    const params = new URLSearchParams({ onSuccessUrl: `/orders/${orderId}/approve` })
    return (
      <Block>
        Please make sure the location of your{' '}
        <Link to={`/settings/billing/${billingAccount.id}?${params.toString()}`} onRoute={routeTo}>
          billing account
        </Link>{' '}
        is correct. Then try again. Contact us if the problem persists.
      </Block>
    )
  }

  // Make sure credit card has been added
  if (!billingAccount.invoice && !billingAccount.creditCard) {
    return <CreditCardMissing billingAccountId={billingAccount.id} orderId={orderId} />
  }
}

type Params = {
  order: Order
}

export const ApproveOrderDialog = ({ order }: Params) => {
  const { isUpdatingOrder } = useStore('isUpdatingOrder')

  const close = () => routeTo(`/orders/${order.id}`)

  const { total, vat, totalIncVAT } = getCost(order)

  const billingAccount = order.billingAccount || order.game?.billingAccount

  if (!billingAccount || !order.specificationUri) {
    routeTo(`/orders/${order.id}`)
  }

  const orderId = order.id
  const orderName = order.name
  const orderShortId = order.shortId
  const orderSpecificationUrl = order.specificationUri

  const [isFetchingFullBillingAccount, setIsFetchingFullBillingAccount] = useState(true)
  const [fullBillingAccount, setFullBillingAccount] = useState<BillingAccount>()

  useEffect(() => {
    setIsFetchingFullBillingAccount(true)
    get(`/billing-accounts/${billingAccount!.id}`)
      .then(({ data: fullBillingAccount }) => {
        setFullBillingAccount(fullBillingAccount)
      })
      .finally(() => {
        setIsFetchingFullBillingAccount(false)
      })
  }, [billingAccount?.id])

  const handleApprove = async () => {
    await acceptOrder(orderId)
    close()
  }

  const isPaidWithCreditCard = fullBillingAccount && !fullBillingAccount.invoice

  const validationError = isPaidWithCreditCard
    ? getValidationError({ billingAccount: fullBillingAccount, orderId })
    : undefined

  return (
    <Dialog
      disableAutofocus
      title="Please confirm"
      onClose={close}
      actions={[
        {
          text: 'Approve',
          contained: true,
          disabled:
            isFetchingFullBillingAccount || !fullBillingAccount || validationError !== undefined,
          loading: isUpdatingOrder,
          onClick: handleApprove
        },
        { text: 'Cancel', onClick: close, disabled: isUpdatingOrder }
      ]}
    >
      <SpacerVertical small />
      {(isFetchingFullBillingAccount || !fullBillingAccount) && <Spinner />}
      {!isFetchingFullBillingAccount && fullBillingAccount && (
        <>
          <Block>
            <InlineBlock color="var(--on-surface-light)">Order name:</InlineBlock> {orderName}
          </Block>
          <SpacerVertical />
          <Block color="var(--on-surface-light)">
            Specification:{' '}
            <Link to={orderSpecificationUrl} target="_blank">
              Order no. {orderShortId}
            </Link>
          </Block>
          <SpacerVertical />
          <Block color="var(--on-surface-light)">Purchase</Block>
          <SpacerVertical />
          <Block paddingLeft="10px">
            <table cellPadding="0" cellSpacing="5" style={{ textAlign: 'left' }}>
              <tr>
                <td>{vat > 0 ? 'Total excl. VAT:' : 'Total:'}</td>
                <td>
                  <SpacerHorizontal />
                </td>
                <td>{formatCurrency(total)}</td>
              </tr>
              <tr>
                <td>VAT:</td>
                <td>
                  <SpacerHorizontal />
                </td>
                <td>{formatCurrency(vat)}</td>
              </tr>
              {vat > 0 && (
                <tr>
                  <td>Total incl. VAT:</td>
                  <td>
                    <SpacerHorizontal />
                  </td>
                  <td>{formatCurrency(totalIncVAT)}</td>
                </tr>
              )}
              <tr>
                <td>Billing account:</td>
                <td>
                  <SpacerHorizontal />
                </td>
                <td>{fullBillingAccount.name}</td>
              </tr>
              <tr>
                <td>Payment type:</td>
                <td>
                  <SpacerHorizontal />
                </td>
                <td>{fullBillingAccount.invoice ? 'Invoice' : 'Credit card'}</td>
              </tr>
              {!fullBillingAccount.invoice && (
                <tr>
                  <td>Card number:</td>
                  <td>
                    <SpacerHorizontal />
                  </td>
                  <td>
                    {fullBillingAccount.creditCard
                      ? `•••• •••• •••• ${fullBillingAccount.creditCard.last4}`
                      : '-'}
                  </td>
                </tr>
              )}
            </table>
          </Block>

          {validationError && (
            <>
              <SpacerVertical large />
              <Block border="3px solid var(--error)" padding="10px">
                {validationError}
              </Block>
            </>
          )}

          {isPaidWithCreditCard && !validationError && (
            <>
              <SpacerVertical large />
              <Block color="var(--on-surface-light)">
                We will debit {formatCurrency(totalIncVAT)} on your card.
              </Block>
            </>
          )}
        </>
      )}
      <SpacerVertical large />
    </Dialog>
  )
}
