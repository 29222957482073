import { useEffect, useRef, useState } from 'preact/hooks'
import { v4 as uuid } from 'uuid'

type useAudioBufferPlayerOptions = {
  audioBuffer: AudioBuffer | null
  start?: number
  end?: number
  registerKeyboardEvent?: boolean
  isRecording: boolean
  onStop?: () => void
  onPlay: (startTime?: number) => void
}

type PlayStates = 'stopped' | 'playing'

type PlayParams = {
  startFrom?: number
  onEnded?: () => void
}

export type AudioBufferPlayer = {
  currentTime: number
  playOffsetTime: number
  state: PlayStates
  play: (params?: PlayParams) => void
  stop: () => void
}

// Audio context and gain node should only be created once
let audioContext: AudioContext | undefined
let volume: GainNode | undefined
export const initAudioContext = () => {
  if (!audioContext) {
    audioContext = new AudioContext()
    volume = new GainNode(audioContext, { gain: 1 })
    volume.connect(audioContext.destination)
  }
}

const allAudioBufferPlayers: { [playerId: string]: AudioBufferPlayer } = {}

export const useAudioBufferPlayer = ({
  audioBuffer,
  start = 0,
  end,
  registerKeyboardEvent = false,
  onStop,
  onPlay,
  isRecording
}: useAudioBufferPlayerOptions): AudioBufferPlayer | undefined => {
  const [playerId] = useState(uuid())
  const [playerState, setPlayerState] = useState<PlayStates>('stopped')
  const [currentTime, setCurrentTime] = useState(0)
  const [playOffsetTime, setPlayOffsetTime] = useState(0)

  const audioBufferSource = useRef<AudioBufferSourceNode | undefined>(undefined)
  const playStartTime = useRef<number>(0)
  const requestAnimationFrameRef = useRef<number | undefined>()

  const play = (params: PlayParams = {}) => {
    const { startFrom = 0, onEnded } = params

    // Lazy init of audio context, to avoid auto-play problems in browser
    initAudioContext()

    if (!audioContext || !volume) {
      return
    }

    if (!audioBuffer) return
    if (isRecording) return

    if (audioContext.state === 'suspended') {
      audioContext.resume()
    }

    // Stop all players
    for (let p of Object.values(allAudioBufferPlayers)) {
      if (p.state === 'playing') {
        p.stop()
      }
    }

    setPlayerState('playing')
    setPlayOffsetTime(startFrom)

    if (!end) end = audioBuffer.duration
    const startValue = Math.max(start, startFrom)
    const duration = end - startValue

    // Cancel any already scheduled fade in/out and reset volume to 1 (100%)
    volume.gain.cancelScheduledValues(0)
    volume.gain.value = 1

    const fadeIn = (params: { trimStartTime: number; duration: number }) => {
      if (audioContext && volume) {
        volume.gain.setValueAtTime(0, audioContext.currentTime)
        const fadeInStartTime = params.trimStartTime + audioContext.currentTime
        volume.gain.setTargetAtTime(1, fadeInStartTime, params.duration)
      }
    }

    const fadeOut = (params: { trimEndTime: number; duration: number }) => {
      if (audioContext && volume) {
        const fadeOutStartTime =
          audioContext.currentTime + duration - params.duration - params.trimEndTime
        volume.gain.setTargetAtTime(0, fadeOutStartTime, params.duration)
      }
    }

    fadeIn({ trimStartTime: 0.03, duration: 0.03 })
    fadeOut({ trimEndTime: 0.05, duration: 0.03 })

    const source = audioContext.createBufferSource()
    source.connect(volume)
    source.buffer = audioBuffer
    source.onended = () => {
      if (onEnded) {
        onEnded()
      }
      stop()
    }

    playStartTime.current = audioContext.currentTime
    source.start(0, startValue, duration)

    if (onPlay) {
      onPlay(startValue)
    }

    audioBufferSource.current = source

    startAnimationFrame()
  }

  const stop = () => {
    stopAnimationFrame()
    setCurrentTime(0)
    setPlayerState('stopped')
    setPlayOffsetTime(0)

    if (onStop) {
      onStop()
    }

    if (audioBufferSource.current) {
      audioBufferSource.current.onended = null
      audioBufferSource.current.stop()
      audioBufferSource.current.disconnect()
      audioBufferSource.current = undefined
    }
  }

  useEffect(() => {
    if (!registerKeyboardEvent) return
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        e.preventDefault()
        e.stopPropagation()
        if (playerState === 'stopped') {
          play()
        } else if (playerState === 'playing') {
          stop()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [audioBuffer, isRecording, playerState, start, end, registerKeyboardEvent])

  useEffect(() => {
    stop()
  }, [start, end])

  const updateTime = () => {
    if (!audioContext) return

    if (requestAnimationFrameRef.current) {
      setCurrentTime(audioContext.currentTime - playStartTime.current)
      requestAnimationFrameRef.current = requestAnimationFrame(updateTime)
    }
  }

  const stopAnimationFrame = () => {
    if (requestAnimationFrameRef.current) {
      cancelAnimationFrame(requestAnimationFrameRef.current)
      requestAnimationFrameRef.current = undefined
      setCurrentTime(0)
    }
  }

  const startAnimationFrame = () => {
    stopAnimationFrame()
    requestAnimationFrameRef.current = requestAnimationFrame(updateTime)
  }

  useEffect(() => {
    return () => {
      stop()
      delete allAudioBufferPlayers[playerId]
    }
  }, [])

  const player: AudioBufferPlayer = {
    play,
    stop,
    state: playerState,
    currentTime,
    playOffsetTime
  }

  allAudioBufferPlayers[playerId] = player

  return player
}
