import { Block, Row } from 'jsxstyle/preact'
import { colors } from './colors'
import { AudioDeviceSelector } from '../AudioRecorder'
import { RecordButton } from './RecordButton'
import { Timer } from '../Timer'
import { OscilloScope } from '../WaveForm/OscilloScope'

import { TimerAndWaveform } from './TimerAndWaveform'
import { VuMeter } from '../VuMeter'
import { lineRecorder, recorder } from './LineRecorder'
import { blurActiveElement } from './blur-active-element'
import { Button, CloseIcon, ProgressCircular, SpacerHorizontal } from '@sodra/bongo-ui'

const SHOW_VU_METER = true

type RecorderProps = {
  showVuMeter: boolean
  showWaveform: boolean
  showOscilloscope: boolean
  recordingText?: string
  onStopRecording: () => void
  onStartRecording: () => void
  isRecording: boolean
  isWaitingForRecordingToStart: boolean
  onError?: (e: Error) => void
}

export function Recorder({
  showVuMeter,
  showWaveform,
  showOscilloscope,
  recordingText,
  onStopRecording,
  onStartRecording,
  isRecording,
  isWaitingForRecordingToStart,
  onError
}: RecorderProps) {
  const toggleRecording = () => {
    if (!isRecording) {
      onStartRecording()
    } else {
      onStopRecording()
      if (lineRecorder.value && lineRecorder.value.autoAdvance.value) {
        lineRecorder.value.nextLine()
      }
    }
  }

  if (!lineRecorder.value) return null

  return (
    <Block width="100%" padding="20px" background={isRecording ? 'var(--error)' : undefined}>
      <Row alignItems="center" gap="20px" height="75px" width="100%">
        {SHOW_VU_METER && showVuMeter && (
          <Block>
            {recorder.value?.state.value.mediaStream && (
              <VuMeter
                stream={recorder.value.state.value.mediaStream}
                onPeakWarning={() => {
                  if (isRecording && lineRecorder.value) {
                    lineRecorder.value.recordingPeakWarning.value = true
                  }
                }}
              />
            )}
          </Block>
        )}
        <Block>
          {!isWaitingForRecordingToStart && (
            <RecordButton
              tooltipText={
                lineRecorder.value.pushToRecord.value
                  ? `Toggle recording \n (or hold down [ENTER])`
                  : `Toggle recording [ENTER]`
              }
              loading={isWaitingForRecordingToStart}
              showRec
              size={60}
              state={
                lineRecorder.value.activeLineId.value
                  ? isRecording
                    ? 'recording'
                    : 'inactive'
                  : undefined
              }
              onClick={() => {
                toggleRecording()
              }}
              onFocus={blurActiveElement}
              focusable={false}
            />
          )}
          {isWaitingForRecordingToStart && (
            <ProgressCircular color={colors['--on-surface']} size="60" />
          )}
        </Block>
        {!recorder.value?.state.value.mediaStream && <AudioDeviceSelector onError={onError} />}
        {recorder.value?.state.value.mediaStream && isRecording && showWaveform && (
          <TimerAndWaveform />
        )}
        {isWaitingForRecordingToStart && (
          <>
            <Block>Waiting for recording to start...</Block>
            <SpacerHorizontal />
            <Button
              outlined
              borderColor={colors['--on-surface']}
              color={colors['--on-surface']}
              icon={CloseIcon}
              onClick={onStopRecording}
            >
              Abort
            </Button>
          </>
        )}
        {isRecording && recordingText && <Block>{recordingText}</Block>}
        {recorder.value?.state.value.mediaStream && !isRecording && showOscilloscope && (
          <>
            <Block>
              <Timer millis={0} color={colors['--on-surface']} />
            </Block>
            <Block width="100%" height="100%">
              <OscilloScope stream={recorder.value?.state.value.mediaStream} />
            </Block>
          </>
        )}
      </Row>
    </Block>
  )
}
