import { useEffect, useState } from 'preact/hooks'

import { get } from './api'
import { setError } from './actions'

export const useFetchResult = <T>(endpoint: string, params: any) => {
  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState<T>()
  const [meta, setMeta] = useState()
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    let cancelled = false
    setIsFetching(true)
    get(endpoint, params)
      .then(({ data, meta }) => {
        if (!cancelled) {
          setData(data)
          setMeta(meta)
          setIsFetching(false)
        }
      })
      .catch((err) => {
        if (!cancelled) {
          setError(err)
          setIsFetching(false)
        }
      })
    return () => {
      cancelled = true
    }
  }, [endpoint, ...Object.values(params), counter])

  const refetch = () => setCounter((counter) => counter + 1)

  return { isFetching, data, meta, refetch }
}
