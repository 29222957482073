import { CloseIcon } from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'
import { BuiClickEvent } from '../use-popup-menu'

type Props = {
  label: string
  onClick?: () => void
  onClear?: () => void
  disabled?: boolean
  active?: boolean
  small?: boolean
}

export const Label = ({ label, onClick, onClear, disabled, active, small }: Props) => {
  const isClickable = !disabled && onClick

  const color = disabled
    ? 'var(--on-surface-lighter)'
    : active
    ? 'var(--accent)'
    : 'var(--on-surface-light)'

  const fontSize = small ? '10px' : '14px'

  let padding = '5px 10px'
  if (onClear) {
    padding = '5px 5px 5px 10px'
  } else if (small) {
    padding = '2px 5px'
  }

  const handleClick = (e: Event) => {
    if (onClick) {
      e.stopPropagation()
      onClick()
    }
  }

  return (
    <Row
      width="max-content"
      alignItems="center"
      padding={padding}
      borderRadius="5px"
      border="1px solid"
      borderColor={color}
      gap="10px"
      cursor={isClickable ? 'pointer' : 'default'}
      props={{ onClick: isClickable ? handleClick : undefined }}
    >
      <Block flex="1" color={color} textTransform="capitalize" fontSize={fontSize}>
        {label}
      </Block>
      {onClear && (
        <Block cursor="pointer" props={{ onClick: onClear }}>
          <CloseIcon fill={color} />
        </Block>
      )}
    </Row>
  )
}
