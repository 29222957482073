import { confirm } from 'lib'

type Params = {
  title?: string
  message?: string
  confirmText?: string
}

export const confirmBillingAccount = ({ title, message, confirmText }: Params = {}) =>
  confirm({
    title: title || 'Billing account required',
    message:
      message || 'To start booking voice actors please assign a billing account to your game.',
    confirmText: confirmText || 'Add billing account'
  })
