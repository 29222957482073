import { h, Fragment } from 'preact'
import { Block } from 'jsxstyle/preact'
import { P } from '@sodra/bongo-ui'

export const TimeZone = ({ voice }) => {
  return (
    <Fragment>
      <Block fontSize="14px" marginBottom="1rem" color="var(--on-surface-light)">
        Time zone
      </Block>
      <P margin="1rem 0">{voice.formattedTimeZone}</P>
    </Fragment>
  )
}
