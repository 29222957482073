import { Button, Dialog, H2, List, ListItem, SpacerVertical } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle'
import { Col, Row } from 'jsxstyle/preact'
import { LineAttribute } from 'src/types'
import { lineAttributeToVariable } from '../WwiseExport/substitution-variables'

type Props = {
  lineAttributes: LineAttribute[]
  onSelect: (value: string) => void
  onClose: () => void
}

const variables = [
  { name: '$GAME', description: 'The game name' },
  { name: '$CHARACTER', description: 'The character name' },
  { name: '$EVENT', description: 'The event name, if any' },
  { name: '$SCENE', description: 'The scene name, if any' },
  { name: '$UUID', description: 'A randomized unique identifier' },
  { name: '$COUNTER:CHARACTER', description: 'An incremental counter for each character' },
  { name: '$COUNTER:EVENT', description: 'An incremental counter for each event' },
  { name: '$COUNTER:SCENE', description: 'An incremental counter for each scene' },
  {
    name: '$COUNTER:COMBINED',
    description: 'An incremental counter for each combination of character, event and scene'
  },
  { name: '$COUNTER:GLOBAL', description: 'A global incremental counter for the entire game' }
]

export const SubstitutionVariables = ({ lineAttributes, onSelect, onClose }: Props) => {
  return (
    <Dialog
      title="Substitution variables"
      disableAutofocus
      onClose={onClose}
      actions={[{ text: 'Close', onClick: onClose }]}
    >
      <List>
        {variables.map((variable) => {
          return (
            <ListItem
              text={
                <Row alignItems="center">
                  <Block flex="1">
                    <Block>{variable.name}</Block>
                    <Block color="var(--on-surface-light)" fontSize="14px">
                      {variable.description}
                    </Block>
                  </Block>
                  <Button onClick={() => onSelect(variable.name)}>Insert</Button>
                </Row>
              }
            />
          )
        })}
      </List>

      <SpacerVertical />
      <H2 size="7">User defined line attributes</H2>
      <List>
        {lineAttributes
          ?.filter((la) => la.type === 'text' || la.type === 'string')
          .map((la) => {
            let secondaryText = 'Text'
            if (la.description && la.description.trim() !== '') {
              secondaryText += ` – ${la.description}`
            }

            return (
              <ListItem
                text={
                  <Row alignItems="center">
                    <Block flex="1">
                      <Block>{lineAttributeToVariable(la)}</Block>
                      <Col color="var(--on-surface-light)" fontSize="14px">
                        <Block>{la.name}</Block>
                        <Block>{secondaryText}</Block>
                      </Col>
                    </Block>
                    <Button onClick={() => onSelect(lineAttributeToVariable(la))}>Insert</Button>
                  </Row>
                }
              />
            )
          })}
      </List>
    </Dialog>
  )
}
