import { useEffect, useState } from 'preact/hooks'
import { googleVoices } from '../google-voices'

const languageCodes = Array.from(
  new Set(googleVoices.map((googleVoice) => googleVoice.languageCodes[0]))
).sort()

const languageCodeOptions = languageCodes.map((languageCode) => ({
  value: languageCode,
  label: languageCode
}))

const genderOptions = [
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' }
]

const defaultTextToSpeechParams = {
  audioConfig: {
    speakingRate: 1,
    pitch: 0
  },
  voice: {
    languageCode: 'en-US',
    ssmlGender: 'MALE',
    name: 'en-US-Standard-A'
  }
}

export const useTextToSpeech = ({ audioConfig, voice } = defaultTextToSpeechParams) => {
  const [languageCode, setLanguageCode] = useState(voice?.languageCode || 'en-US')
  const [gender, setGender] = useState(voice?.ssmlGender || 'MALE')
  const [voiceName, setVoiceName] = useState(voice?.name || 'en-US-Standard-A')
  const [speakingRate, setSpeakingRate] = useState(audioConfig?.speakingRate || 1)
  const [pitch, setPitch] = useState(audioConfig?.pitch || 0)

  const textToSpeechParams = {
    voice: {
      name: voiceName,
      languageCode,
      ssmlGender: gender
    },
    audioConfig: {
      pitch: Math.round(pitch * 100) / 100,
      speakingRate: Math.round(speakingRate * 100) / 100
    }
  }

  const voiceNameOptions = googleVoices
    .filter(
      (googleVoice) =>
        googleVoice.languageCodes.includes(languageCode) && googleVoice.ssmlGender === gender
    )
    .map((googleVoice) => ({ value: googleVoice.name, label: googleVoice.name }))

  useEffect(() => {
    if (!voiceNameOptions.some((option) => option.value === voiceName)) {
      setVoiceName(voiceNameOptions[0]?.value)
    }
  }, [voiceNameOptions, voiceName])

  return {
    languageCode,
    setLanguageCode,
    gender,
    setGender,
    voiceName,
    setVoiceName,
    speakingRate,
    setSpeakingRate,
    pitch,
    setPitch,
    textToSpeechParams,
    languageCodeOptions,
    genderOptions,
    voiceNameOptions
  }
}
