import { goBack, routeTo } from '@sodra/prutt'
import { ExportTarget, LineFilter } from 'src/types'
import Page from '../Page'
import { WwiseSettings } from '../WwiseExport/WwiseSettings'
import { updateExportTarget } from 'src/actions'
import { useState } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'
import { Divider, Link, Select, SpacerVertical, TextField } from '@sodra/bongo-ui'

type EditWwiseDetailsProps = {
  exportTarget: ExportTarget & { type: 'wwise' }
  gameId: string
  lineFilters: LineFilter[]
}

export function EditWwiseDetails({ exportTarget, gameId, lineFilters }: EditWwiseDetailsProps) {
  const onBack = () => goBack('/export-targets')

  const [name, setName] = useState(exportTarget.name)

  const [lineFilter, setLineFilter] = useState<LineFilter | undefined>(exportTarget.lineFilter)

  const lineFilterOptions: { text: string; value: string }[] = [
    { text: '-- No line filter --', value: '' }
  ]
  lineFilterOptions.push(
    ...lineFilters
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((filter) => ({
        text: filter.name,
        value: filter.id
      }))
  )

  return (
    <Page title={exportTarget.name} onBack={onBack}>
      <Col gap="0px" maxWidth="700px">
        <TextField label="Name" value={name} onInput={setName} />
        <SpacerVertical large />
        <Row>
          <Select
            label="Line filter"
            value={lineFilter?.id ?? ''}
            options={lineFilterOptions}
            onChange={(selectedFilterId: string) => {
              setLineFilter(
                selectedFilterId === ''
                  ? undefined
                  : lineFilters.find((filter) => filter.id === selectedFilterId)
              )
            }}
          />
        </Row>
        <SpacerVertical small />
        <Block color="var(--on-surface-light)" fontSize="12px">
          Note: Select a line filter to export only a subset of all lines.
          <SpacerVertical small />
          Line filters are created from the{' '}
          <Link to="/lines" onRoute={routeTo}>
            Lines
          </Link>{' '}
          section.
        </Block>
        <SpacerVertical large />
        <Divider />
        <SpacerVertical large />
        <WwiseSettings
          gameId={gameId}
          onSave={async (settings) => {
            await updateExportTarget(exportTarget.id, {
              name,
              lineFilterId: lineFilter?.id ?? null,
              ...settings
            })
            onBack()
          }}
          workUnit={exportTarget.workUnit}
          folderStructure={exportTarget.folderStructure}
          createEvents={exportTarget.createEvents}
          moveExisting={exportTarget.moveExisting}
        />
      </Col>
    </Page>
  )
}
