import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Row, Block } from 'jsxstyle/preact'
import { Button, IconButton, PlayIcon, SpacerHorizontal, StopIcon } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

import { buildSpeakerUri } from '../../speak'

import { usePopupMenu } from '../use-popup-menu'

import Avatar from '../Avatar'
import { Character } from '../../types'
import { AudioSegment, SegmentedAudioTrack, useAudioPlayer } from 'lib/src/hooks/use-audio-player'
import { ScrollableDataTable } from '../ScrollableDataTable'
import { AIAvatar } from '../AIAvatar'
import { useStore } from '../../store'

type Props = {
  loading?: boolean
  characters: Character[]
  total?: number
  pageSize: number
  page: number
  setPage: (page: number) => void
  orderBy: string
  setOrderBy: (orderBy: string) => void
  sortOrder: string
  setSortOrder: (sortOrder: string) => void
}

export const CharactersTable = ({
  loading,
  characters,
  total,
  pageSize = 10,
  page,
  setPage,
  orderBy,
  setOrderBy,
  sortOrder,
  setSortOrder
}: Props) => {
  const { currentLanguage } = useStore('currentLanguage')

  const { player } = useAudioPlayer()
  if (!player) {
    return null
  }
  const { initPlayer, play, stopPlayer, playerState } = player

  const [showSpinner, setShowSpinner] = useState(false)

  const { PopupMenu } = usePopupMenu({
    options: [
      { label: 'Edit', onClick: (character: Character) => routeTo(`/character/${character.id}`) },
      {
        label: 'Clone',
        onClick: (character: Character) => routeTo(`/character/${character.id}/clone`)
      },
      {
        label: 'Delete',
        onClick: (character: Character) => routeTo(`/character/${character.id}/delete`)
      }
    ]
  })

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => setShowSpinner(true), 500)
      return () => clearTimeout(timer)
    } else {
      setShowSpinner(false)
    }
  }, [loading])

  const header = [
    { value: 'name', sortable: true, label: 'Name' },
    { value: 'archetype', sortable: false, label: 'Archetype' },
    { value: 'gender', sortable: false, label: 'Gender' },
    { value: 'age', sortable: false, label: 'Age' },
    { value: 'style', sortable: false, label: 'Style' },
    { value: 'events', sortable: true, label: 'Events' },
    { value: 'scenes', sortable: true, label: 'Scenes' },
    { value: 'lines', sortable: true, label: 'Lines' },
    { value: 'aiVoice', sortable: false, label: 'AI voice' },
    { label: 'Example', style: { width: '100px' } }
  ]

  const body = characters.map((character) => {
    const aiVoice = character.aiVoices[currentLanguage]
    const canSpeak = aiVoice && character!.exampleLines?.length

    const audioTrack: SegmentedAudioTrack | undefined = canSpeak
      ? {
          name: character.name,
          picture: character.picture,
          metaData: { id: character.id },
          segments: character.exampleLines!.map((line): AudioSegment => {
            const uri = buildSpeakerUri({ aiVoiceId: aiVoice.id, text: line.line })
            return {
              uri,
              description: line.line
            }
          })
        }
      : undefined

    const isPlayingCurrentTrack =
      playerState.value.isPlaying && playerState.value.currentTrack?.metaData?.id === character.id

    const togglePlay = () => {
      if (!audioTrack) {
        return
      }

      if (isPlayingCurrentTrack) {
        stopPlayer()
      } else {
        initPlayer({
          audioTracks: [audioTrack]
        })
        play()
      }
    }

    return [
      <Row alignItems="center">
        <Avatar size={30} src={character.picture} name={character.name} />
        <SpacerHorizontal small />
        <Block maxWidth="200px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {character.name}
        </Block>
      </Row>,
      <Block color="var(--on-surface-light)">{character.archetype}</Block>,
      <Block color="var(--on-surface-light)">{character.gender}</Block>,
      <Block color="var(--on-surface-light)">{character.age}</Block>,
      <Block color="var(--on-surface-light)">{character.style}</Block>,
      <Button
        whiteSpace="nowrap"
        marginLeft="-15px"
        link={{
          href: `/events?characterId=${character.id}`,
          onRoute: routeTo
        }}
      >
        {character!.events} event{character!.events !== 1 ? 's' : ''}
      </Button>,
      <Button
        whiteSpace="nowrap"
        marginLeft="-15px"
        link={{
          href: `/scenes?characterId=${character.id}`,
          onRoute: routeTo
        }}
      >
        {character.scenes} scene{character.scenes !== 1 ? 's' : ''}
      </Button>,
      <Button
        whiteSpace="nowrap"
        marginLeft="-15px"
        link={{
          href: `/lines?characterId=${character.id}`,
          onRoute: routeTo
        }}
      >
        {character.lines} line{character.lines !== 1 ? 's' : ''}
      </Button>,
      aiVoice ? (
        <Row alignItems="center">
          <AIAvatar size={30} src={aiVoice.picture} name={aiVoice.name} />
          <SpacerHorizontal small />
          <Block maxWidth="200px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {aiVoice.name}
          </Block>
        </Row>
      ) : (
        ''
      ),
      canSpeak ? (
        <IconButton
          icon={isPlayingCurrentTrack ? StopIcon : PlayIcon}
          loading={isPlayingCurrentTrack && playerState.value.loading}
          onClick={togglePlay}
        />
      ) : (
        ''
      )
    ]
  })

  const handleSort = (column: string, sortOrder: 'asc' | 'desc') => {
    setOrderBy(column)
    setSortOrder(sortOrder)
    setPage(0)
  }

  return (
    <Block flex="1" position="relative">
      <ScrollableDataTable
        loading={showSpinner}
        header={header}
        body={body}
        sortedColumn={orderBy}
        sortOrder={sortOrder}
        onSort={handleSort}
        onClick={(index: number) => routeTo(`/character/${characters[index].id}`)}
        pagination={{
          page,
          numRowsPerPage: pageSize,
          numRows: total,
          onPageChange: setPage
        }}
        emptyText="No characters found"
      />
      {PopupMenu}
    </Block>
  )
}

export default CharactersTable
