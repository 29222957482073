import { goBack, Route, routeTo, Switch } from '@sodra/prutt'
import { useEffect } from 'preact/hooks'

import { fetchSession } from '../../actions'
import { useEffectSkipFirst } from '../../use-effect-skip-first'

import Spinner from '../Spinner'

import {
  ChatBubbleIcon,
  DeleteIcon,
  FolderOpenIcon,
  PeopleIcon,
  TabItem,
  Tabs,
  VoiceIcon
} from '@sodra/bongo-ui'
import { Block, Col } from 'jsxstyle/preact'
import { useStore } from '../../store'
import Page from '../Page'
import { PageActionType } from '../PageAction'
import { EditDetails } from './EditDetails'
import { SessionLines } from './SessionLines'

type Props = {
  sessionId: string
}

export const Session = ({ sessionId }: Props) => {
  const { currentGame, session } = useStore('currentGame', 'session')

  // Handle switch game
  useEffectSkipFirst(() => {
    routeTo('/sessions', true)
  }, [currentGame!.id])

  useEffect(() => {
    fetchSession(sessionId)
  }, [sessionId])

  if (!session || session.id !== sessionId) {
    return <Spinner />
  }

  const onBack = () => goBack('/sessions')

  const pageActions: PageActionType[] = [
    {
      label: 'Characters',
      icon: PeopleIcon,
      onClick: () => routeTo(`/characters?sessionId=${session?.id}`)
    },
    {
      label: 'Files',
      icon: FolderOpenIcon,
      onClick: () => routeTo(`/files?sessionId=${session?.id}`)
    }
  ]
  if (session.chatId) {
    pageActions.push({
      label: 'Chat',
      icon: ChatBubbleIcon,
      onClick: () => routeTo(`/chats/${session.chatId}`)
    })
  }
  pageActions.push({
    label: 'Record',
    icon: VoiceIcon,
    link: {
      href: `/record-session/${session?.id}?game=${currentGame?.shortId}`,
      onRoute: routeTo
    }
  })

  const hoursUntilSessionStart = Math.ceil(
    (new Date(session.scheduled).getTime() - Date.now()) / (1000 * 3600)
  )

  const canDeleteSession = !session.voiceAccepted || hoursUntilSessionStart > 24

  return (
    <Page
      title={`Session ${session.num ?? 'details'}${
        session.voice ? ` · ${session.voice.name}` : ''
      }`}
      actions={pageActions}
      moreActions={
        canDeleteSession
          ? [
              {
                label: 'Delete',
                icon: DeleteIcon,
                disabled: !canDeleteSession,
                onClick: () => routeTo(`/session/${session?.id}/delete`)
              }
            ]
          : undefined
      }
      onBack={onBack}
    >
      <Col isolation="isolate" height="100%">
        <Block
          position="sticky"
          top="-20px"
          marginTop="-20px"
          paddingTop="20px"
          background="var(--surface)"
          zIndex={3}
        >
          <Tabs type="fixed-left">
            <TabItem
              text="Details"
              onClick={() => routeTo(`/session/${session?.id}`)}
              active={location.pathname.endsWith(`/${session.id}`)}
            />
            <TabItem
              text={'Lines (' + (session.lines ?? 0) + ')'}
              onClick={() => routeTo(`/session/${session?.id}/lines`)}
              active={location.pathname.endsWith('/lines')}
            />
          </Tabs>
        </Block>
        <Col marginTop="40px" flex={1}>
          <Switch>
            <Route
              path="/session/:sessionId/lines"
              render={() => {
                return <SessionLines sessionId={session.id} />
              }}
            />
            <Route
              path="/session/:sessionId"
              render={() => {
                return <EditDetails />
              }}
            />
          </Switch>
        </Col>
      </Col>
    </Page>
  )
}

export default Session
