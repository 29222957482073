import { h } from 'preact'
import {
  Checkbox,
  CloseIcon,
  Dialog,
  P,
  RadioButton,
  SpacerHorizontal,
  SpacerVertical,
  TextField
} from '@sodra/bongo-ui'
import { useEffect, useState } from 'preact/hooks'

import { useStore } from '../store'
import { ErrorMessage } from './ErrorMessage'
import { useMatchMedia } from 'jsxstyle'
import { Row } from 'jsxstyle/preact'
import { useSignal } from '@preact/signals'

export type AuditionDetailsSubmitParams = {
  deadline?: { numHours?: number; numDays?: number }
  startDate?: Date
  endDate?: Date
  scope?: number
}

type Props = {
  onSubmit: (
    { deadline, startDate, endDate, scope }: AuditionDetailsSubmitParams,
    shareCharacterSheet: boolean
  ) => Promise<void>
  onSkip: () => Promise<void>
  onClose: () => void
}

export const AuditionDetails = ({ onSubmit, onSkip, onClose }: Props) => {
  const isNarrow = useMatchMedia('(max-width: 700px)')

  const { currentGame, config } = useStore('currentGame', 'config')

  const isLoading = useSignal<string | null>(null)

  const budgetSegment = config!.ratesById[currentGame!.budgetSegment].label
  const rate = config!.ratesById[currentGame!.budgetSegment].rate

  const [isHidden, setIsHidden] = useState(false)

  const [selectedDeadline, setSelectedDeadline] = useState<string>()
  const [deadlineDays, setDeadlineDays] = useState<number>()

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  const [scope, setScope] = useState<number>()
  const [shareCharacterSheet, setShareCharacterSheet] = useState<boolean>(false)

  const [deadlineError, setDeadlineError] = useState<string>()
  const [startDateError, setStartDateError] = useState<string>()
  const [endDateError, setEndDateError] = useState<string>()
  const [scopeError, setScopeError] = useState<string>()

  useEffect(() => {
    // Clear deadline error
    if (selectedDeadline) {
      setDeadlineError(undefined)
    }
    // Clear recording start/end date error
    if (startDate) {
      setStartDateError(undefined)
    }
    if (endDate) {
      setEndDateError(undefined)
    }
    // Clear scope error
    if (scope !== undefined && scope > 0) {
      setScopeError(undefined)
    }
  }, [selectedDeadline, deadlineDays, startDate, endDate, scope])

  const handleSkip = async () => {
    try {
      isLoading.value = 'skip'
      await onSkip()
    } finally {
      isLoading.value = null
    }
  }

  const handleSubmit = async () => {
    let errorId

    // Validate scope
    // if (!scope) {
    //   setScopeError('Please enter the approximate number of hours')
    //   errorId = 'scope'
    // }

    // Validate start/end date
    // if (!endDate) {
    //   setEndDateError('Please select an end date')
    //   errorId = 'recordingTimeWindow'
    // }
    // if (!startDate) {
    //   setStartDateError('Please select a start date')
    //   errorId = 'recordingTimeWindow'
    // }

    // Validate deadline
    // if (!selectedDeadline) {
    //   setDeadlineError('Please select a deadline')
    //   errorId = 'deadline'
    // } else if (selectedDeadline === 'other' && !deadlineDays) {
    //   setDeadlineError('Please enter a number of days')
    //   errorId = 'deadline'
    // }

    if (errorId) {
      const el = document.querySelector(`#${errorId}`)
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    if (!errorId) {
      let sd
      if (startDate) {
        sd = new Date(startDate) // Create date in user time zone
        sd.setHours(0)
      }

      let ed
      if (endDate) {
        ed = new Date(endDate) // Create date in user time zone
        ed.setHours(0)
      }

      try {
        isLoading.value = 'submit'
        await onSubmit(
          {
            deadline: {
              numHours:
                selectedDeadline === '24hours'
                  ? 24
                  : selectedDeadline === '48hours'
                  ? 48
                  : undefined,
              numDays: selectedDeadline === 'other' ? deadlineDays : undefined
            },
            startDate: sd,
            endDate: ed,
            scope
          },
          shareCharacterSheet
        )
      } finally {
        isLoading.value = null
      }
    }
  }

  const hide = () => {
    setIsHidden(true)
  }

  return (
    <Dialog
      full={isNarrow}
      title="Audition details"
      actions={[
        {
          type: 'contained',
          text: 'Continue',
          onClick: handleSubmit,
          loading: isLoading.value === 'submit',
          disabled: isLoading.value === 'skip'
        },
        { text: 'Close', onClick: hide, disabled: isLoading.value !== null },
        {
          text: 'Skip',
          onClick: handleSkip,
          loading: isLoading.value === 'skip',
          disabled: isLoading.value === 'submit'
        }
      ]}
      titleIcon={CloseIcon}
      hideDialog={isHidden}
      onTitleIconClick={hide}
      onClose={onClose}
      dismissable={false}
    >
      <SpacerVertical />
      <P color="var(--on-surface-light)">
        Please enter details for the audition and upcoming recording.
      </P>

      <SpacerVertical large />

      <P props={{ id: 'deadline' }}>Audition deadline</P>
      <SpacerVertical small />
      <RadioButton
        checked={selectedDeadline === '24hours'}
        label="24 hours"
        onChange={() => {
          setSelectedDeadline('24hours')
        }}
      />
      <SpacerHorizontal />
      <RadioButton
        checked={selectedDeadline === '48hours'}
        label="48 hours"
        onChange={() => {
          setSelectedDeadline('48hours')
        }}
      />
      <SpacerHorizontal />
      <RadioButton
        label="Other"
        checked={selectedDeadline === 'other'}
        onChange={() => {
          setSelectedDeadline('other')
        }}
      />
      {selectedDeadline === 'other' && (
        <>
          <SpacerVertical small />
          <TextField
            type="number"
            value={deadlineDays}
            label="Num days"
            onInput={setDeadlineDays}
          />
        </>
      )}
      {deadlineError && (
        <>
          <SpacerVertical small />
          <ErrorMessage>{deadlineError}</ErrorMessage>
        </>
      )}

      <SpacerVertical large />

      <P props={{ id: 'recordingTimeWindow' }}>Proposed recording availability</P>
      <SpacerVertical />
      <Row alignItems="flexStart">
        <TextField
          type="date"
          label="Start date"
          value={startDate}
          onChange={setStartDate}
          errorText={startDateError}
        />
        <SpacerHorizontal />
        <TextField
          type="date"
          label="End date"
          value={endDate}
          onChange={setEndDate}
          errorText={endDateError}
        />
      </Row>

      <SpacerVertical large />

      <P props={{ id: 'scope' }}>Estimated scope</P>
      <SpacerVertical />
      <TextField
        type="number"
        label="Total hours"
        placeholder="Enter approximate value"
        value={scope}
        onInput={setScope}
        errorText={scopeError}
      />
      <SpacerVertical />

      <Checkbox
        label="Share character sheet(s) with voice"
        checked={shareCharacterSheet}
        onChange={setShareCharacterSheet}
      />

      <SpacerVertical />
    </Dialog>
  )
}

export default AuditionDetails
