import { h } from 'preact'
import { BasicDialog, Checkbox, SelectChip, SpacerVertical } from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'

import { generateAITakes, updateGame } from '../../actions'
import { useLocalStorageState } from '../../use-local-storage-state'
import { useEffect, useState } from 'preact/hooks'
import { get, patch, post } from '../../api'
import Spinner from '../Spinner'
import { pluralize } from 'lib'
import Avatar from '../Avatar'
import SelectAIVoice from '../SelectAIVoice'
import { useStore } from '../../store'
import { AIAvatar } from '../AIAvatar'
import { formatLanguage } from '../../format-language'
import { GameAiQuota } from '../GameDashboard'
import { checkBuyAiTimePreconditions } from '../check-buy-ai-time-preconditions'
import { AiQuota } from '../../types'
import { fetchBillingAccount, fetchBillingAccounts } from '../../actions/billing-accounts'
import { BuyExtraAiQuotaTimeDialog } from '../BuyExtraAiQuotaTimeDialog'
import { confirm } from 'lib'
import { getGameAiQuotaRemainingSeconds, isGameAiQuotaEmpty } from '../is-game-ai-quota-empty'
import SelectBillingAccount from '../SelectBillingAccount'
import { confirmSelectBillingAccount } from '../confirmSelectBillingAccount'
import { routeTo } from '@sodra/prutt'
import { formatSeconds } from 'src/format-seconds'

type Props = {
  onClose: () => void
  onSuccess: () => void
  onSubmit: () => void
  lineIds: string[]
}

type CharacterLines = {
  character: {
    id: string
    name: string
    picture?: string
  }
  aiVoice?: {
    id: string
    name: string
    picture?: string
  }
  lineIds: string[]
}

export const GenerateManyAITakes = ({ onSubmit, onSuccess, onClose, lineIds = [] }: Props) => {
  const { currentGame, isGeneratingAITakes, currentLanguage, billingAccount, billingAccounts } =
    useStore(
      'currentGame',
      'isGeneratingAITakes',
      'currentLanguage',
      'billingAccount',
      'billingAccounts'
    )

  const [makeDefault, setMakeDefault] = useLocalStorageState(
    'speechless:lines:generateAiTake:makeDefault',
    false
  )

  const [expectedSeconds, setExpectedSeconds] = useState(0)
  const [characterLines, setCharacterLines] = useState<CharacterLines[]>([])
  const [characterId, setCharacterId] = useState<string | undefined>()
  const [initialCharacterAiVoiceIds, setInitialCharacterAiVoiceIds] = useState<{
    [characterId: string]: string
  }>({})

  const [gameAiQuota, setGameAiQuota] = useState<GameAiQuota | undefined>()
  const [buyExtraTimeAiQuota, setBuyExtraTimeAiQuota] = useState<AiQuota | undefined>(undefined)
  const [showSelectBillingAccountDialog, setShowSelectBillingAccountDialog] = useState(false)

  useEffect(() => {
    post(`/games/${currentGame!.id}/character-lines`, { lineIds, language: currentLanguage }).then(
      ({ meta: { expectedSeconds }, data: characterLines }) => {
        setExpectedSeconds(expectedSeconds)
        setCharacterLines(characterLines)

        // Store AI voices that characters had to know if we should auto-update any AI voices after generating takes
        const initialAiVoices: { [characterId: string]: string } = {}
        characterLines.map((cl: CharacterLines) => {
          if (cl.aiVoice) {
            initialAiVoices[cl.character.id] = cl.aiVoice.id
          }
        })
        setInitialCharacterAiVoiceIds(initialAiVoices)
      }
    )
    fetchBillingAccounts()
  }, [])

  useEffect(() => {
    if (currentGame) {
      fetchGameAiQuota()

      if (currentGame.billingAccount) {
        fetchBillingAccount(currentGame.billingAccount.id)
      }
    }
  }, [currentGame])

  if (lineIds.length === 0) {
    onClose()
  }

  const selectAIVoice = (aiVoice: any) => {
    setCharacterLines((characterLines) =>
      characterLines.map((cl) => (cl.character.id === characterId ? { ...cl, aiVoice } : cl))
    )
    setCharacterId(undefined)
  }

  const clearAIVoice = (characterId: string) => {
    setCharacterLines((characterLines) =>
      characterLines.map((cl) =>
        cl.character.id === characterId ? { ...cl, aiVoice: undefined } : cl
      )
    )
  }

  const fetchGameAiQuota = async () => {
    if (currentGame) {
      get(`/games/${currentGame.id}/ai-quota`).then(({ data: aiQuota }) => {
        setGameAiQuota(aiQuota)
      })
    }
  }

  const handleSubmit = async () => {
    onSubmit()

    if (!gameAiQuota || isGameAiQuotaEmpty(gameAiQuota)) {
      if (
        await confirm({
          title: 'Your AI voices quota is empty',
          message: 'Would you like to buy some more hours to keep the magic going?',
          confirmText: 'Buy more hours'
        })
      ) {
        await buyExtraAiTime()
      }
      return
    }

    const lines = []
    const characterAiVoiceIdsToUpdate: { characterId: string; aiVoiceId: string }[] = []
    for (let { aiVoice, lineIds, character } of characterLines) {
      if (aiVoice) {
        // If character didn't have an AI voice, update it automatically
        if (!initialCharacterAiVoiceIds[character.id] && lineIds.length > 0) {
          characterAiVoiceIdsToUpdate.push({ characterId: character.id, aiVoiceId: aiVoice.id })
        }

        for (let lineId of lineIds) {
          lines.push({ lineId, aiVoiceId: aiVoice.id })
        }
      }
    }

    if (await generateAITakes({ lines, makeDefault, language: currentLanguage })) {
      const updateCharacterPromises = characterAiVoiceIdsToUpdate.map(
        ({ characterId, aiVoiceId }) => {
          return patch(`/characters/${characterId}`, {
            aiVoices: [{ language: currentLanguage, aiVoiceId }]
          })
        }
      )
      await Promise.all(updateCharacterPromises)

      onSuccess()
      onClose()
    }
  }

  const createBillingAccount = () => {
    if (!currentGame) {
      return
    }
    const params = new URLSearchParams({
      onSuccessUrl: `/lines/generate-ai-take?game=${currentGame.shortId}&restore=true`
    })
    routeTo(`/settings/billing/add-billing-account?${params.toString()}`)
  }

  const addCreditCard = () => {
    if (!currentGame?.billingAccount) {
      return
    }
    const params = new URLSearchParams({
      onSuccessUrl: `/lines/generate-ai-take?game=${currentGame.shortId}&restore=true`
    })
    routeTo(
      `/settings/billing/${currentGame.billingAccount.id}/add-credit-card?${params.toString()}`
    )
  }

  const buyExtraAiTime = async () => {
    if (!currentGame) {
      return
    }
    await checkBuyAiTimePreconditions({
      game: currentGame,
      onCreditCardRequired: addCreditCard,
      onBillingAccountRequired: () => {
        if (billingAccounts?.length) {
          setShowSelectBillingAccountDialog(true)
        } else {
          createBillingAccount()
        }
      },
      onSuccess: () => {
        // Open a dialog to buy extra time
        if (gameAiQuota?.billingAccount?.basic?.[0]) {
          setBuyExtraTimeAiQuota(gameAiQuota.billingAccount.basic[0])
        }
      }
    })
  }

  const allSelected = characterLines.every((cl) => cl.aiVoice)

  const isLocalized = currentGame?.secondaryLanguages && currentGame.secondaryLanguages.length > 0
  const title = isLocalized
    ? `Generate ${formatLanguage(currentLanguage)} AI takes`
    : `Generate AI takes`

  const remainingSeconds = getGameAiQuotaRemainingSeconds(gameAiQuota)

  return (
    <BasicDialog
      title={title}
      disableAutofocus
      primaryActionText="Generate"
      primaryActionLoading={isGeneratingAITakes}
      primaryActionDisabled={!allSelected}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      {characterLines.length === 0 && <Spinner />}
      <table style="width:100%">
        {characterLines.map(({ character, aiVoice, lineIds }) => {
          return (
            <tr>
              <td>
                <Row alignItems="center" gap="10px">
                  <Avatar size={30} src={character.picture} name={character.name} />
                  <Block>{character.name}</Block>
                </Row>
              </td>
              <td>
                {lineIds.length} {pluralize('line', lineIds.length)}
              </td>
              <td>
                {aiVoice && (
                  <SelectChip
                    visual={<AIAvatar size={30} src={aiVoice.picture} name={aiVoice.name} />}
                    value={aiVoice.name}
                    onClick={() => setCharacterId(character.id)}
                    onClear={() => clearAIVoice(character.id)}
                  />
                )}
                {!aiVoice && (
                  <SelectChip
                    label="Select AI voice"
                    onClick={() => setCharacterId(character.id)}
                  />
                )}
              </td>
            </tr>
          )
        })}
      </table>
      <SpacerVertical small />
      <Checkbox label="Use as selected takes" checked={makeDefault} onChange={setMakeDefault} />
      {characterId && (
        <SelectAIVoice onClose={() => setCharacterId(undefined)} onSelect={selectAIVoice} />
      )}
      <SpacerVertical small />
      <Block color="var(--on-surface-light)" fontSize="14px">
        Approximately {formatSeconds(expectedSeconds)} of remaining{' '}
        {formatSeconds(remainingSeconds)}
      </Block>
      <SpacerVertical small />

      {buyExtraTimeAiQuota && billingAccount && (
        <>
          <BuyExtraAiQuotaTimeDialog
            aiQuota={buyExtraTimeAiQuota}
            billingAccount={billingAccount}
            onSuccess={(aiQuota: AiQuota, hours: number) => {
              fetchGameAiQuota()
            }}
            onClose={() => {
              setBuyExtraTimeAiQuota(undefined)
            }}
          />
        </>
      )}
      {showSelectBillingAccountDialog && billingAccounts?.length && (
        <SelectBillingAccount
          billingAccounts={billingAccounts}
          onCreateBillingAccount={createBillingAccount}
          onClose={() => setShowSelectBillingAccountDialog(false)}
          onSelect={async (billingAccount) => {
            if (await confirmSelectBillingAccount({ billingAccount })) {
              updateGame({ billingAccountId: billingAccount.id }).then(fetchGameAiQuota)
              return true
            }
            return false
          }}
        />
      )}
    </BasicDialog>
  )
}
