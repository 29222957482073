import { EventIcon, FilterListIcon, Link, SelectChip, SpacerVertical } from '@sodra/bongo-ui'
import { goBack, Route, routeTo, Switch } from '@sodra/prutt'
import { Block, Col, Row } from 'jsxstyle/preact'
import { formatScheduled, useAudioPlayer } from 'lib'
import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { fetchLines, updateLine } from '../../actions'
import { get } from '../../api'
import { useStore } from '../../store'
import { Character, Line, LineFilterTakeStatus, Scene, Take } from '../../types'
import { useCharacter } from '../../use-character'
import { useEffectSkipFirst } from '../../use-effect-skip-first'
import { useEvent } from '../../use-event'
import { useLocalStorageState } from '../../use-local-storage-state'
import { useScene } from '../../use-scene'
import { useSession } from '../../use-session'
import { useUrlParams } from '../../use-url-params'
import Avatar from '../Avatar'
import { capitalize } from '../capitalize'
import ColumnDisplayOptions from '../ColumnDisplayOptions'
import SearchTextField from '../SearchTextField'
import SelectCharacter from '../SelectCharacter'
import SelectEvent from '../SelectEvent'
import { SelectLabels } from '../SelectLabels'
import SelectScene from '../SelectScene'
import { SelectSession } from '../SelectSession'
import Spinner from '../Spinner'
import LinesTable from './LinesTable'
import { TakeStatusSelectChip } from './TakeStatusSelectChip'

type Props = {
  basePath: string
  filters?: ('character' | 'event' | 'event' | 'scene' | 'session' | 'labels' | 'selected_take')[]
  onSetSelectedIds?: (selectedIds: string[]) => void
  excludeSessionId?: string
}

export function Lines({
  basePath,
  filters = ['character', 'event', 'event', 'scene', 'session', 'labels', 'selected_take'],
  onSetSelectedIds,
  excludeSessionId
}: Props) {
  const {
    currentGame,
    lines,
    totalLines = 0,
    isFetchingLines,
    currentLanguage
  } = useStore('currentGame', 'lines', 'totalLines', 'isFetchingLines', 'currentLanguage')

  const closeDialog = () => goBack(basePath)

  const params = new URLSearchParams(location.search)

  const secondaryLanguage =
    currentLanguage !== currentGame?.primaryLanguage ? currentLanguage : undefined

  const [counter, setCounter] = useState(0)

  const [query, setQuery] = useState(params.get('query') ?? '')
  const [page, setPage] = useState(parseFloat(params.get('page') ?? '0'))
  const [pageSize, setPageSize] = useLocalStorageState(`speechless:${currentGame!.id}:pageSize`, 20)
  const [orderBy, setOrderBy] = useState(params.get('orderBy') ?? 'created')
  const [sortOrder, setSortOrder] = useState(params.get('sortOrder') ?? 'asc')

  const [outdatedTake, setOutdatedTake] = useState<
    { take: Take; line: Line; language: string } | undefined
  >(undefined)

  const { eventId, event, setEvent, clearEvent } = useEvent(params.get('eventId') ?? undefined)
  const { characterId, character, setCharacter, clearCharacter } = useCharacter(
    params.get('characterId') ?? undefined
  )
  const { sceneId, scene, setScene, clearScene } = useScene(params.get('sceneId') ?? undefined)
  const { sessionId, session, setSession, clearSession } = useSession(
    params.get('sessionId') ?? undefined
  )

  const [labels, setLabels] = useState(params.get('labels')?.split(',') ?? undefined)

  const [takeStatus, setTakeStatus] = useState<LineFilterTakeStatus | undefined>(
    params.get('takeStatus') ? (params.get('takeStatus') as LineFilterTakeStatus) : undefined
  )

  const [selectedIds, setSelectedIds] = useState<string[]>([])
  useEffect(() => {
    if (onSetSelectedIds) {
      onSetSelectedIds(selectedIds)
    }
  }, [selectedIds])

  const [columns, setColumns] = useLocalStorageState(
    `speechless:${currentGame!.id}:lines:columns`,
    ['filename', 'character', 'event', 'scene', 'line', 'selectedTake']
  )

  const refetch = () => setCounter((counter) => counter + 1)

  // Handle switch game
  useEffectSkipFirst(() => {
    clearCharacter()
    clearEvent()
    clearScene()
    clearSession()
  }, [currentGame!.id])

  useEffect(() => {
    if (lines) {
      setSelectedIds(lines.filter((line) => selectedIds.includes(line.id)).map((line) => line.id))
    }
  }, [lines])

  useEffect(() => {
    const inProgress =
      lines &&
      lines.some((line) => {
        const lineTranslation = line.translations[currentLanguage]
        return (
          lineTranslation &&
          lineTranslation.selectedTake &&
          !lineTranslation.selectedTake.uri &&
          !lineTranslation.selectedTake.error
        )
      })
    if (inProgress) {
      const timeout = setTimeout(refetch, 5000)
      return () => clearTimeout(timeout)
    }
  }, [lines])

  useEffect(() => {
    fetchLines({
      offset: page * pageSize,
      limit: pageSize,
      query,
      sortOrder,
      orderBy,
      eventId,
      characterId,
      sceneId,
      sessionId,
      labels,
      language: secondaryLanguage,
      outdatedTakes: takeStatus,
      excludeSessionId
    })
  }, [
    currentGame!.id,
    page,
    pageSize,
    query,
    sortOrder,
    orderBy,
    eventId,
    characterId,
    sceneId,
    sessionId,
    labels,
    counter,
    secondaryLanguage,
    outdatedTake,
    takeStatus,
    excludeSessionId
  ])

  useUrlParams(
    {
      page,
      query,
      orderBy,
      sortOrder,
      eventId,
      characterId,
      sceneId,
      sessionId,
      labels,
      outdatedTakes: takeStatus
    },
    { page: 0, query: '', orderBy: 'created', sortOrder: 'desc' }
  )

  const { player } = useAudioPlayer()

  if (!player) {
    return <Spinner />
  }

  if (!lines) {
    return <Spinner />
  }

  if (eventId && !event) {
    return <Spinner />
  }

  if (characterId && !character) {
    return <Spinner />
  }

  if (sceneId && !scene) {
    return <Spinner />
  }

  if (sessionId && !session) {
    return <Spinner />
  }

  const selectedRows = []
  for (let i = 0; i < lines.length; i++) {
    if (selectedIds.includes(lines[i].id)) {
      selectedRows.push(i)
    }
  }

  const fetchAllIds = async () => {
    const { data: lineIds } = await get(`/games/${currentGame!.id}/line-ids`, {
      query,
      sortOrder,
      orderBy,
      eventId,
      characterId,
      sceneId,
      language: secondaryLanguage,
      sessionId,
      excludeSessionId
    })
    setSelectedIds(lineIds)
  }

  const handleSelectedRowsChange = (selectedRows: number[]) => {
    const selectedIds = lines
      .filter((_, index) => selectedRows.includes(index))
      .map((line) => line.id)
    setSelectedIds(selectedIds)
  }

  const handleSelectedTakeChange = async (lineId: string, takeId?: string) => {
    if (
      await updateLine(lineId, {
        selectedTakes: [{ language: currentLanguage, takeId: takeId || null }]
      })
    ) {
      refetch()
    }
  }
  return (
    <>
      <Col height="100%">
        <SearchTextField
          value={query}
          onChange={(query) => {
            setQuery(query)
            setPage(0)
          }}
        />
        <SpacerVertical small />
        <Row alignItems="center" gap="5px">
          {character && filters.includes('character') && (
            <SelectChip
              visual={<Avatar size={30} src={character.picture} name={character.name} />}
              value={character.name}
              onClick={() => routeTo('/lines/select-character')}
              onClear={() => {
                setCharacter()
                setPage(0)
              }}
            />
          )}
          {!character && filters.includes('character') && (
            <SelectChip
              icon={FilterListIcon}
              label="Character"
              onClick={() => routeTo(`${basePath}/lines/select-character`)}
            />
          )}
          {event && filters.includes('event') && (
            <SelectChip
              visual={<Avatar size={30} src={event.picture} name={event.name} />}
              value={event.name}
              onClick={() => routeTo(`${basePath}/lines/select-event`)}
              onClear={() => {
                setEvent()
                setPage(0)
              }}
            />
          )}
          {!event && filters.includes('event') && (
            <SelectChip
              icon={FilterListIcon}
              label="Event"
              onClick={() => routeTo(`${basePath}/lines/select-event`)}
            />
          )}
          {scene && filters.includes('scene') && (
            <SelectChip
              visual={<Avatar size={30} src={scene.picture} name={scene.name} />}
              value={scene.name}
              onClick={() => routeTo(`${basePath}/lines/select-scene`)}
              onClear={() => {
                setScene()
                setPage(0)
              }}
            />
          )}
          {!scene && filters.includes('scene') && (
            <SelectChip
              icon={FilterListIcon}
              label="Scene"
              onClick={() => routeTo(`${basePath}/lines/select-scene`)}
            />
          )}
          {session && filters.includes('session') && (
            <SelectChip
              visual={
                session.voice ? (
                  <Avatar size={30} src={session.voice.picture} name={session.voice.name} />
                ) : (
                  <EventIcon />
                )
              }
              value={`${formatScheduled(session.scheduled)}`}
              onClick={() => routeTo(`${basePath}/lines/select-session`)}
              onClear={() => {
                setSession()
                setPage(0)
              }}
            />
          )}
          {!session && filters.includes('session') && (
            <SelectChip
              icon={FilterListIcon}
              label="Session"
              onClick={() => routeTo(`${basePath}/lines/select-session`)}
            />
          )}
          {labels && filters.includes('labels') && labels.length > 0 && (
            <SelectChip
              value={capitalize(
                `${labels[0]}${labels.length > 1 ? ' +' + (labels.length - 1) : ''}`
              )}
              onClick={() => routeTo(`${basePath}/lines/select-labels`)}
              onClear={() => {
                setLabels(undefined)
                setPage(0)
              }}
            />
          )}
          {(!labels || labels.length === 0) && filters.includes('labels') && (
            <SelectChip
              icon={FilterListIcon}
              label="Labels"
              onClick={() => routeTo(`${basePath}/lines/select-labels`)}
            />
          )}
          {filters.includes('selected_take') && (
            <TakeStatusSelectChip
              value={takeStatus}
              onChange={(takeStatus?: LineFilterTakeStatus) => {
                setTakeStatus(takeStatus)
                setPage(0)
              }}
            />
          )}
        </Row>
        <SpacerVertical />
        {totalLines > 0 && (
          <>
            <Row alignItems="center" gap="10px">
              {selectedIds.length === 0 && <Block>No lines selected</Block>}
              {selectedIds.length > 0 && (
                <Block>
                  <strong>{selectedIds.length}</strong> of {totalLines} line
                  {totalLines !== 1 ? 's' : ''} selected
                </Block>
              )}
              {selectedIds.length !== totalLines && (
                <Link onClick={fetchAllIds}>Select all {totalLines} lines</Link>
              )}
              <ColumnDisplayOptions value={columns} onChange={setColumns} />
            </Row>
          </>
        )}
        <Block flex={1} position="relative">
          <LinesTable
            showNotesAndWarnings
            columns={columns}
            loading={isFetchingLines}
            lines={lines}
            total={totalLines}
            selectedRows={selectedRows}
            onSelectedRowsChange={handleSelectedRowsChange}
            labels={labels}
            setLabels={setLabels}
            page={page}
            pageSize={pageSize}
            orderBy={orderBy}
            sortOrder={sortOrder}
            setPage={setPage}
            setPageSize={setPageSize}
            setOrderBy={(orderBy: string) => {
              setOrderBy(orderBy)
              setPage(0)
            }}
            setSortOrder={(sortOrder: string) => {
              setSortOrder(sortOrder)
              setPage(0)
            }}
            event={event}
            setEvent={(event) => {
              setEvent(event)
              setPage(0)
            }}
            scene={scene}
            setScene={(scene?: Scene) => {
              setScene(scene)
              setPage(0)
            }}
            character={character}
            setCharacter={(character?: Character) => {
              setCharacter(character)
              setPage(0)
            }}
            onSelectedTakeChange={handleSelectedTakeChange}
            onShowOutdatedTake={setOutdatedTake}
            player={player}
          />
        </Block>
      </Col>
      <Switch>
        <Route
          path={`${basePath}/lines/select-character`}
          render={() => <SelectCharacter onClose={closeDialog} onSelect={setCharacter} />}
        />
        <Route
          path={`${basePath}/lines/select-event`}
          render={() => <SelectEvent onClose={closeDialog} onSelect={setEvent} />}
        />
        <Route
          path={`${basePath}/lines/select-scene`}
          render={() => <SelectScene onClose={closeDialog} onSelect={setScene} />}
        />
        <Route
          path={`${basePath}/lines/select-session`}
          render={() => (
            <SelectSession
              onClose={closeDialog}
              onSelect={(session) => {
                closeDialog()
                setSession(session)
              }}
            />
          )}
        />
        <Route
          path={`${basePath}/lines/select-labels`}
          render={() => (
            <SelectLabels
              selectedLabels={labels ?? []}
              onClose={closeDialog}
              onSelect={setLabels}
            />
          )}
        />
      </Switch>
    </>
  )
}
