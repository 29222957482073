import { store } from '../store'
import { del, get, patch, post } from '../api'
import { LineAttribute } from '../types'

export const fetchLineAttributes = async (params?: any) => {
  try {
    store.setState({ isFetchingLineAttributes: true })
    const gameId = store.getState().currentGame?.id
    const { data: lineAttributes } = await get(`/line-attributes`, { gameId, ...params })
    store.setState({ lineAttributes, isFetchingLineAttributes: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isFetchingLineAttributes: false })
  }
}

export const createLineAttribute = async (params: any): Promise<LineAttribute | undefined> => {
  try {
    store.setState({ isCreatingLineAttribute: true })
    const gameId = store.getState().currentGame?.id
    const oldLineAttributes = store.getState().lineAttributes || []
    const { data: lineAttributes } = await post(`/line-attributes`, { gameId, ...params })
    const attribute = lineAttributes.find(
      (newAttribute: LineAttribute) =>
        !oldLineAttributes.some((oldAttribute) => oldAttribute.id === newAttribute.id)
    )
    store.setState({ lineAttributes, isCreatingLineAttribute: false })
    return attribute
  } catch (err) {
    const error = err as Error
    store.setState({ error, isCreatingLineAttribute: false })
  }
}

export const updateLineAttribute = async (attributeId: string, params: any) => {
  try {
    store.setState({ isUpdatingLineAttribute: true })
    const { data: lineAttributes } = await patch(`/line-attributes/${attributeId}`, params)
    store.setState({ lineAttributes, isUpdatingLineAttribute: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isUpdatingLineAttribute: false })
  }
}

export const deleteLineAttribute = async (attributeId: string) => {
  try {
    store.setState({ isDeletingLineAttribute: true })
    const { data: lineAttributes } = await del(`/line-attributes/${attributeId}`)
    store.setState({ lineAttributes, isDeletingLineAttribute: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isDeletingLineAttribute: false })
  }
}
