import { Fragment, h } from 'preact'
import { FilterListIcon, SelectChip } from '@sodra/bongo-ui'
import { usePopupMenu } from '../use-popup-menu'
import { LineFilterStatus } from 'src/types'

type Props = {
  value?: LineFilterStatus
  onChange: (value?: LineFilterStatus) => void
}

export const LineStatusSelectChip = ({ value, onChange }: Props) => {
  const options = [
    {
      value: 'deleted',
      label: 'Deleted',
      onClick: () => onChange('deleted')
    },
    {
      value: 'duplicate-filename',
      label: 'Duplicate filename',
      onClick: () => onChange('duplicate-filename')
    }
  ]

  const { PopupMenu, showMenu } = usePopupMenu({ options })

  return (
    <Fragment>
      <SelectChip
        label="Line status"
        icon={FilterListIcon}
        value={options.find((option) => option.value === value)?.label}
        onClick={showMenu}
        onClear={() => onChange(undefined)}
      />
      {PopupMenu}
    </Fragment>
  )
}
