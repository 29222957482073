import { confirm, pluralize } from 'lib'
import { Checkbox, Dialog, P, RadioButton, SpacerVertical } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'
import { useEffect, useState } from 'preact/hooks'
import { DeleteTakesFilter, DeleteTakesResult, VoiceType, deleteTakesByFilter } from '../../actions'
import Spinner from '../Spinner'
import { useLocalStorageState } from 'src/use-local-storage-state'

type TakeSelectionType = 'subset' | 'all'

type DeleteTakesProps = {
  lineIds: string[]
  language: string
  onDelete: (result: DeleteTakesResult) => void
  onClose: () => void
}

export const DeleteTakes = ({ lineIds, language, onDelete, onClose }: DeleteTakesProps) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const [takeSelectionType, setTakeSelectionType] = useLocalStorageState<
    TakeSelectionType | undefined
  >('speechless:lines:delete-takes:takeSelectionType', undefined)
  const [voiceTypes, setVoiceTypes] = useLocalStorageState<VoiceType[]>(
    'speechless:lines:delete-takes:voiceTypes',
    []
  )
  const [expectedResult, setExpectedResult] = useState<DeleteTakesResult | undefined>(undefined)
  const [selectedSubsetsCount, setSelectedSubsetsCount] = useState<number | undefined>(undefined)
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true)

  useEffect(() => {
    const filter: DeleteTakesFilter = {
      language
    }
    const dryRun = true
    deleteTakesByFilter(lineIds, filter, dryRun).then((result?: DeleteTakesResult) => {
      setExpectedResult(result)
    })
  }, [])

  useEffect(() => {
    const shouldDisable = () => {
      if (isDeleting) {
        return true
      }
      if (!takeSelectionType) {
        return true
      }
      if (takeSelectionType === 'subset' && (selectedSubsetsCount === 0 || !voiceTypes?.length)) {
        return true
      }
      if (takeSelectionType === 'all' && !expectedResult?.total) {
        return true
      }

      return false
    }

    setIsDeleteButtonDisabled(shouldDisable())
  }, [takeSelectionType, voiceTypes, isDeleting, selectedSubsetsCount, expectedResult])

  useEffect(() => {
    if (expectedResult) {
      let count = 0
      for (const vt of voiceTypes) {
        count += expectedResult?.voiceType?.[vt] ?? 0
      }
      setSelectedSubsetsCount(count)
    }
  }, [voiceTypes, expectedResult])

  const setVoiceType = (voiceType: VoiceType, enabled: boolean) => {
    let updatedVoiceTypes = [...voiceTypes]

    if (enabled) {
      if (!updatedVoiceTypes.includes(voiceType)) {
        updatedVoiceTypes.push(voiceType)
      }
    } else {
      updatedVoiceTypes = updatedVoiceTypes.filter((vt) => vt !== voiceType)
    }

    setVoiceTypes(updatedVoiceTypes)
  }

  const deleteAndClose = async () => {
    const count =
      (takeSelectionType === 'subset' ? selectedSubsetsCount : expectedResult?.total) ?? 0
    if (
      await confirm({
        title: 'Confirm delete',
        message: `${count} ${pluralize(
          'take',
          count
        )} will be deleted. You can restore them later if necessary.`,
        confirmText: 'Delete',
        rejectText: 'Cancel'
      })
    ) {
      setIsDeleting(true)

      const filter: DeleteTakesFilter = {
        language,
        voiceType: takeSelectionType === 'subset' ? voiceTypes : undefined
      }
      const dryRun = false
      const result = await deleteTakesByFilter(lineIds, filter, dryRun)

      if (result) {
        onDelete(result)
      }
      setIsDeleting(false)
      onClose()
    }
  }

  const labelWithCount = (label: string, count?: number): string => {
    if (count === undefined) {
      return label
    }
    return `${label} (${count})`
  }

  return (
    <Dialog
      title={`Delete takes${lineIds.length > 1 ? ' for ' + lineIds.length + ' lines' : ''}`}
      disableAutofocus
      actions={[{ text: 'Cancel', onClick: onClose }]}
      secondaryActions={[
        {
          text: 'Delete',
          type: 'contained',
          onClick: deleteAndClose,
          disabled: isDeleteButtonDisabled
        }
      ]}
      onClose={onClose}
    >
      {!isDeleting && (
        <>
          <P color="var(--on-surface-light)">
            Choose which takes you want to delete. All deleted takes can be restored later.
            <SpacerVertical />
            Note that only alternative takes will be deleted this way. The selected primary take for
            each line must be deleted individually.
          </P>
          <SpacerVertical />
        </>
      )}
      {isDeleting && (
        <>
          <Block color="var(--on-surface-light)">Deleting takes…</Block>
          <Block position="relative" height="100px">
            <Spinner />
          </Block>
        </>
      )}
      {!isDeleting && (
        <>
          <RadioButton
            checked={takeSelectionType === 'subset'}
            label={labelWithCount('Delete by type', selectedSubsetsCount)}
            onChange={() => {
              setTakeSelectionType('subset')
            }}
          />
          <SpacerVertical tiny />
          <Block paddingLeft="20px">
            <Checkbox
              disabled={takeSelectionType !== 'subset'}
              checked={voiceTypes.includes('ai')}
              label={labelWithCount(
                'Generated by AI text-to-speech',
                expectedResult?.voiceType?.ai
              )}
              onChange={(checked: boolean) => setVoiceType('ai', checked)}
            />
            <Checkbox
              disabled={takeSelectionType !== 'subset'}
              checked={voiceTypes.includes('voice')}
              label={labelWithCount('Uploaded by voice artists', expectedResult?.voiceType?.voice)}
              onChange={(checked: boolean) => setVoiceType('voice', checked)}
            />
            <Checkbox
              disabled={takeSelectionType !== 'subset'}
              checked={voiceTypes.includes('user')}
              label={labelWithCount('Uploaded by project members', expectedResult?.voiceType?.user)}
              onChange={(checked: boolean) => setVoiceType('user', checked)}
            />
          </Block>
          <SpacerVertical />
          <RadioButton
            checked={takeSelectionType === 'all'}
            label={labelWithCount('Delete all takes', expectedResult?.total)}
            onChange={() => {
              setTakeSelectionType('all')
            }}
          />
          <SpacerVertical large />
        </>
      )}
    </Dialog>
  )
}
