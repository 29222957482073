import { useEffect, useState } from 'preact/hooks'
import { get } from './api'
import { Event } from './types'

export const useEvent = (initialEventId?: string) => {
  const [eventId, setEventId] = useState<string | undefined>(initialEventId)
  const [event, setEvent] = useState<Event | undefined>()

  useEffect(() => {
    if (eventId && (!event || eventId !== event.id)) {
      get(`/events/${eventId}`).then(({ data: event }) => setEvent(event))
    }
    if (!eventId) {
      setEvent(undefined)
    }
  }, [eventId])

  return {
    eventId,
    setEventId,
    event,
    setEvent: (event?: Event) => {
      setEvent(event)
      if (event) {
        setEventId(event.id)
      } else {
        setEventId(undefined)
      }
    },
    clearEvent: () => {
      setEventId(undefined)
      setEvent(undefined)
    }
  }
}
