const validateBitsPerSample = (bitsPerSample: number) => {
  const valid = [8, 16, 24, 32]
  if (valid.indexOf(bitsPerSample) < 0) {
    throw new Error(
      `Only ${valid.join(', ')} bits per sample are supported – sample size: ${bitsPerSample}`
    )
  }
}

type Options = {
  sampleSize?: 8 | 16 | 24 | 32
}

/**
 * http://soundfile.sapp.org/doc/WaveFormat/
 * https://russellgood.com/how-to-convert-audiobuffer-to-audio-file/
 * https://stackoverflow.com/questions/22560413/html5-web-audio-convert-audio-buffer-into-wav-file
 * @param audioBuffer
 * @returns Blob of type 'audio/wav'
 */
export const bufferToWave = (audioBuffer: AudioBuffer, options?: Options) => {
  const bitsPerSample = options?.sampleSize ?? 16

  const numChannels = audioBuffer.numberOfChannels
  const numSamples = audioBuffer.length
  const sampleRate = audioBuffer.sampleRate

  validateBitsPerSample(bitsPerSample)

  const subChunk1Size = 16
  const subChunk2Size = numSamples * numChannels * (bitsPerSample / 8)
  const chunkSize = 4 + (8 + subChunk1Size) + (8 + subChunk2Size)
  const length = chunkSize + 8

  const buffer = new ArrayBuffer(length)
  const view = new DataView(buffer)

  let pos = 0

  function setUint16(data: number) {
    view.setUint16(pos, data, true)
    pos += 2
  }

  function setUint32(data: number) {
    view.setUint32(pos, data, true)
    pos += 4
  }

  const channels = []

  // write WAVE header
  setUint32(0x46464952) // "RIFF"
  setUint32(chunkSize)
  setUint32(0x45564157) // "WAVE"

  setUint32(0x20746d66) // "fmt "
  setUint32(subChunk1Size)
  setUint16(1) // AudioFormat, PCM=1
  setUint16(numChannels)
  setUint32(sampleRate)
  setUint32(sampleRate * numChannels * (bitsPerSample / 8)) // avg. bytes/sec
  setUint16(numChannels * (bitsPerSample / 8)) // block-align
  setUint16(bitsPerSample) // 8, 16, 24, 32

  setUint32(0x61746164)
  setUint32(subChunk2Size)

  // write interleaved data
  for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
    channels.push(audioBuffer.getChannelData(i))
  }

  let offset = 0
  while (pos < length) {
    for (let i = 0; i < numChannels; i++) {
      // interleave channels
      let sample = Math.max(-1, Math.min(1, channels[i][offset])) // clamp
      sample =
        (0.5 + sample < 0
          ? sample * Math.pow(2, bitsPerSample - 1)
          : sample * (Math.pow(2, bitsPerSample - 1) - 1)) | 0
      switch (bitsPerSample) {
        case 8:
          view.setInt8(pos, sample)
          break
        case 16:
          view.setInt8(pos, sample)
          view.setInt8(pos + 1, sample >> 8)
          break
        case 24:
          view.setInt8(pos, sample)
          view.setInt8(pos + 1, sample >> 8)
          view.setInt8(pos + 2, sample >> 16)
          break
        case 32:
          view.setInt8(pos, sample)
          view.setInt8(pos + 1, sample >> 8)
          view.setInt8(pos + 2, sample >> 16)
          view.setInt8(pos + 3, sample >> 24)
          break
        default:
          throw new Error(
            `Only 8, 16, 24 and 32 bits per sample are supported – sample size: ${bitsPerSample}`
          )
      }
      pos += bitsPerSample / 8
    }
    offset++ // Next sample
  }

  return new Blob([buffer], { type: 'audio/wav' })
}
