type WaveFormOptions = {
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D
  audioBuffer: AudioBuffer
  color: string
  zoom: number
  position: number
  x: number
  y: number
  width: number
  height: number
}

export function drawJaggedChunkedWaveform({
  ctx,
  audioBuffer,
  color,
  // FIXME MR: Remove unused params zoom, position
  zoom,
  position,
  x,
  y,
  width,
  height
}: WaveFormOptions) {
  ctx.save()
  ctx.translate(x, y)

  ctx.fillStyle = color
  const bufferLength = audioBuffer.length
  const zoomLength = bufferLength / zoom

  const start = Math.max(0, bufferLength * position - zoomLength / 2)
  const end = Math.min(bufferLength, start + zoomLength)

  const rawAudioData = audioBuffer.getChannelData(0).slice(start, end)

  const audioChunkSize = Math.max(1, rawAudioData.length / width)
  const numChunks = width // One audio chunk per pixel

  for (let i = 0; i < numChunks; i++) {
    const audioChunkStart = Math.floor(i * audioChunkSize)
    const audioChunkEnd = Math.min(
      rawAudioData.length,
      Math.floor(audioChunkStart + audioChunkSize)
    )
    const audioChunk = rawAudioData.slice(audioChunkStart, audioChunkEnd)

    // calculate the total positive and negative area
    let positive = 0
    let negative = 0
    let positiveMax = 0
    let negativeMin = 0

    audioChunk.forEach((val) => {
      if (val > 0) {
        positive = positive + val
        if (val > positiveMax) positiveMax = val
      } else if (val < 0) {
        negative = negative + val
        if (val < negativeMin) negativeMin = val
      }
    })

    negative = negative / audioChunk.length
    positive = positive / audioChunk.length

    // calculate amplitude of the wave
    const chunkAmp = -(negativeMin - positiveMax)

    // draw the bar corresponding to this pixel
    const xOffset = i
    const ampWidth = 1
    ctx.fillRect(xOffset, height / 2, ampWidth, -Math.max(1, chunkAmp * (height / 2)))
    ctx.fillRect(xOffset, height / 2 - 1, ampWidth, Math.max(1, chunkAmp * (height / 2)))
  }

  ctx.restore()
}

export function drawSmoothedChunkedWaveform({
  ctx,
  audioBuffer,
  color,
  zoom,
  position,
  x,
  y,
  width,
  height
}: WaveFormOptions) {
  ctx.save()
  ctx.translate(x, y)

  ctx.fillStyle = color
  // calculate displayed part of audio
  // and slice audio buffer to only process that part
  const bufferLength = audioBuffer.length
  const zoomLength = bufferLength / zoom

  const start = Math.max(0, bufferLength * position - zoomLength / 2)
  const end = Math.min(bufferLength, start + zoomLength)

  const rawAudioData = audioBuffer.getChannelData(0).slice(start, end)

  // process chunks corresponding to 1 pixel width
  const chunkSize = Math.max(1, Math.floor(rawAudioData.length / width))

  const chunkPadding = 500

  // TODO: This loop is incorrect because of chunkSize flooring. See drawJaggedChunkedWaveform for solution.
  for (let x = 0; x < width; x++) {
    const start = x * chunkSize
    const end = start + chunkSize
    const startPadded = Math.max(0, start - chunkPadding)
    const endPadded = Math.min(rawAudioData.length, end + chunkPadding)
    const chunk = rawAudioData.slice(startPadded, endPadded)

    // calculate the total positive and negative area
    let positive = 0
    let negative = 0
    let positiveMax = 0
    let negativeMin = 0
    chunk.forEach((val) => {
      if (val > 0) {
        positive += val
        if (val > positiveMax) positiveMax = val
      } else if (val < 0) {
        negative += val
        if (val < negativeMin) negativeMin = val
      }
    })

    negative /= chunk.length
    positive /= chunk.length

    // calculate amplitude of the wave
    const chunkAmp = -(negative - positive)

    // draw the bar corresponding to this pixel
    ctx.fillRect(x, height / 2 - chunkAmp * height + 2, 1, Math.max(1, chunkAmp * height))
    ctx.fillRect(x, height / 2 + chunkAmp * height - 2, 1, Math.min(1, chunkAmp * -1 * height))
  }

  ctx.restore()
}

export function drawRawWaveForm({
  ctx,
  audioBuffer,
  color,
  zoom,
  position,
  x,
  y,
  width,
  height
}: WaveFormOptions) {
  ctx.save()
  ctx.translate(x, y)

  ctx.fillStyle = color
  // calculate displayed part of audio
  // and slice audio buffer to only process that part
  const bufferLength = audioBuffer.length
  const zoomLength = bufferLength / zoom

  const start = Math.max(0, bufferLength * position - zoomLength / 2)
  const end = Math.min(bufferLength, start + zoomLength)

  const rawAudioData = audioBuffer.getChannelData(0).slice(start, end)

  ctx.beginPath()
  ctx.moveTo(0, height / 2)

  for (let i = 0; i < rawAudioData.length; i++) {
    const val = rawAudioData[i]

    ctx.lineTo((i / rawAudioData.length) * width, height / 2 - val * (height / 2))
  }

  ctx.strokeStyle = color
  ctx.stroke()

  ctx.restore()
}
