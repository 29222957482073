import { Fragment, h } from 'preact'
import { useRef, useState } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import {
  BasicDialog,
  Button,
  CheckmarkIcon,
  List,
  ListItem,
  SpacerHorizontal,
  SpacerVertical,
  WarningIcon
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { get } from '../api'
import { setError, uploadTakes } from '../actions'
import { getFileHash } from '../get-file-hash'

import Page from './Page'
import { useStore } from '../store'
import { ShortlistedVoice, User } from '../types'
import { FakeSelect } from './FakeSelect'
import Avatar from './Avatar'
import { SelectTakeUploader, SelectUploaderParams } from './Line/SelectTakeUploader'
import { formatLanguage } from '../format-language'

const parseFilename = (path = '') => {
  let filename = path
  let ext = ''
  const dotIndex = filename.lastIndexOf('.')
  if (dotIndex > 0) {
    filename = path.substring(0, dotIndex)
    ext = path.substring(dotIndex)
  }
  const re = new RegExp('_(alt|take)[_\\d]*$', 'i')
  const isAltTake = re.test(filename)
  if (isAltTake) {
    filename = filename.replace(re, '')
  }
  return { filename, ext, isAltTake }
}

type Line = {
  id: string
  filename: string
  take?: {
    hash: string
  }
}

export const UploadAudio = () => {
  const { currentGame, isUploadingTakes, currentUser, currentLanguage } = useStore(
    'currentGame',
    'isUploadingTakes',
    'currentUser',
    'currentLanguage'
  )
  const fileInput = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<File[]>()
  const [lines, setLines] = useState<Line[]>()
  const [showFiles, setShowFiles] = useState('')

  const [userUploader, setUserUploader] = useState<User | undefined>(currentUser!)
  const [voiceUploader, setVoiceUploader] = useState<ShortlistedVoice | undefined>()

  const [isFetchingLines, setIsFetchingLines] = useState(false)

  const matchingFiles: File[] = []
  const nonMatchingFiles: File[] = []

  if (files && lines) {
    for (let file of files) {
      const { filename } = parseFilename(file.name)
      const isMatch = lines.some((line) => line.filename === filename)
      if (isMatch) {
        matchingFiles.push(file)
      } else {
        nonMatchingFiles.push(file)
      }
    }
  }

  const onBack = () => goBack('/lines')

  const handleUpload = async () => {
    const lineFiles = []
    for (let file of matchingFiles) {
      const { filename, isAltTake } = parseFilename(file.name)
      const hash = await getFileHash(file)
      const line = lines?.find((line) => line.filename === filename)
      if (isAltTake || line?.take?.hash !== hash) {
        lineFiles.push({
          lineId: line?.id,
          file,
          hash,
          makeDefault: !isAltTake,
          userId: userUploader?.id,
          voiceId: voiceUploader?.id,
          language: currentLanguage
        })
      }
    }
    if (lineFiles.length === 0) {
      return onBack()
    }
    if (await uploadTakes(lineFiles)) {
      onBack()
    }
  }

  const fetchLines = async (files: File[]) => {
    const filenames = files.map((file) => {
      const { filename } = parseFilename(file.name)
      return filename
    })
    setIsFetchingLines(true)
    try {
      const { data: lines } = await get(`/games/${currentGame!.id}/lines`, {
        limit: filenames.length,
        filenames
      })
      setLines(lines)
      setFiles(files)
    } catch (err) {
      setError(err)
    } finally {
      setIsFetchingLines(false)
    }
  }

  const getFilenames = () => {
    const files = showFiles === 'matching' ? matchingFiles : nonMatchingFiles
    return files.map((file) => file.name).sort()
  }

  const selectUploader = ({ user, voice }: SelectUploaderParams) => {
    setUserUploader(user)
    setVoiceUploader(voice)
  }

  const uploader = voiceUploader || userUploader

  return (
    <Page
      title="Upload audio"
      onBack={onBack}
      primaryActionText="Continue"
      primaryActionLoading={isUploadingTakes}
      primaryActionDisabled={matchingFiles.length === 0}
      onPrimaryActionClick={handleUpload}
      secondaryActionText="Cancel"
      onSecondaryActionClick={() => setFiles(undefined)}
    >
      <Block maxWidth="300px">
        <Button
          outlined
          onClick={() => fileInput?.current?.click()}
          loading={isFetchingLines}
          disabled={isUploadingTakes}
        >
          {currentGame?.secondaryLanguages && currentGame?.secondaryLanguages.length > 0
            ? `Select ${formatLanguage(currentLanguage)} audio files`
            : 'Select audio files'}
        </Button>
        <SpacerVertical />
        <FakeSelect
          label="Voice"
          visual={
            uploader ? <Avatar size={30} src={uploader.picture} name={uploader.name} /> : undefined
          }
          value={uploader?.name}
          onClick={() => routeTo(`/upload-audio/select-uploader`)}
        />
      </Block>
      <input
        multiple
        type="file"
        ref={fileInput}
        accept="audio/*"
        onChange={() => {
          fetchLines(Array.from(fileInput!.current!.files || []))
          fileInput!.current!.value = ''
        }}
        style={{ display: 'none' }}
      />
      {matchingFiles.length > 0 && (
        <Fragment>
          <SpacerVertical />
          <Block>
            <CheckmarkIcon fill="var(--ok)" />
            <SpacerHorizontal />
            {matchingFiles.length} file{matchingFiles.length !== 1 ? 's' : ''} matched a line.{' '}
            <Button onClick={() => setShowFiles('matching')}>Show</Button>
          </Block>
        </Fragment>
      )}
      {nonMatchingFiles.length > 0 && (
        <Fragment>
          <SpacerVertical />
          <Block>
            <WarningIcon fill="var(--warning)" />
            <SpacerHorizontal />
            {nonMatchingFiles.length} file{nonMatchingFiles.length !== 1 ? 's' : ''} did not match a
            line. <Button onClick={() => setShowFiles('non-matching')}>Show</Button>
          </Block>
        </Fragment>
      )}
      {matchingFiles.length > 0 && (
        <Fragment>
          <SpacerVertical />
          <Block color="var(--on-surface-light)">
            Note: File will not be uploaded if it is identical to the selected take of the
            corresponding line.
          </Block>
        </Fragment>
      )}

      {showFiles && (
        <BasicDialog
          title={showFiles === 'matching' ? 'Matching files' : 'Non-matching files'}
          onClose={() => setShowFiles('')}
          action1Text="Close"
          onAction1Click={() => setShowFiles('')}
        >
          <List>
            {getFilenames().map((filename) => {
              return <ListItem text={filename} />
            })}
          </List>
        </BasicDialog>
      )}

      <Route
        path={`/upload-audio/select-uploader`}
        render={() => (
          <SelectTakeUploader onClose={() => goBack(`/upload-audio`)} onSelect={selectUploader} />
        )}
      />
    </Page>
  )
}

export default UploadAudio
