import { store } from '../store'
import { get, post, patch, put, del } from '../api'

export const fetchScenes = async (params) => {
  let cancelled = false
  store.setState({ isFetchingScenes: true })
  const gameId = store.getState().currentGame.id
  get(`/games/${gameId}/scenes`, params)
    .then(({ data: scenes, meta: { total } }) => {
      if (!cancelled) {
        store.setState({ scenes, totalScenes: total, isFetchingScenes: false })
      }
    })
    .catch((error) => {
      if (!cancelled) {
        store.setState({ error, isFetchingScenes: false })
      }
    })
  return () => {
    store.setState({ isFetchingScenes: false })
    cancelled = true
  }
}

export const fetchScene = async (sceneId) => {
  try {
    store.setState({ isFetchingScene: true })
    const { data: scene } = await get(`/scenes/${sceneId}`)
    store.setState({ scene, isFetchingScene: false })
    return scene
  } catch (error) {
    store.setState({ error, isFetchingScene: false })
  }
}

export const createScene = async (params) => {
  try {
    store.setState({ isCreatingScene: true })
    const gameId = store.getState().currentGame.id
    const { data: scene } = await post(`/games/${gameId}/scenes`, params)
    store.setState({ scene, isCreatingScene: false })
    return scene
  } catch (error) {
    store.setState({ error, isCreatingScene: false })
  }
}

export const updateScene = async (params) => {
  try {
    store.setState({ isUpdatingScene: true })
    const sceneId = store.getState().scene.id
    const { data: scene } = await patch(`/scenes/${sceneId}`, params)
    store.setState({ scene, isUpdatingScene: false })
    return scene
  } catch (error) {
    store.setState({ error, isUpdatingScene: false })
  }
}

export const updateSceneSeqNumber = async (scene, line, newSeqNumber) => {
  try {
    store.setState({ isUpdatingScene: true })
    const sceneId = store.getState().scene.id
    const { data: scene } = await patch(`/scenes/${sceneId}/${line.id}/seq-number`, {
      sceneSeqNumber: newSeqNumber
    })
    store.setState({ scene, isUpdatingScene: false })
    return scene
  } catch (error) {
    store.setState({ error, isUpdatingScene: false })
  }
}

export const deleteScene = async () => {
  try {
    store.setState({ isDeletingScene: true })
    const sceneId = store.getState().scene.id
    await del(`/scenes/${sceneId}`)
    store.setState({ isDeletingScene: false })
    return true
  } catch (error) {
    store.setState({ error, isDeletingScene: false })
  }
}

export const deleteSceneLine = async (lineId, params) => {
  try {
    store.setState({ isDeletingSceneLine: true })
    const sceneId = store.getState().scene.id
    const { data: scene } = await del(`/scenes/${sceneId}/lines/${lineId}`, params)
    store.setState({ scene, isDeletingSceneLine: false })
    return scene
  } catch (error) {
    store.setState({ error, isDeletingSceneLine: false })
  }
}

export const addLinesToScene = async (sceneId, lineIds) => {
  try {
    store.setState({ isAddingLinesToScene: true })
    const { data: scene } = await put(`/scenes/${sceneId}/lines`, { lineIds })
    store.setState({ scene, isAddingLinesToScene: false })
    return scene
  } catch (error) {
    store.setState({ error, isAddingLinesToScene: false })
  }
}
