import { useEffect, useState } from 'preact/hooks'
import { get } from './api'
import { useStore } from './store'
import { Voice } from './types'

export const useVoice = (initialVoiceId?: string) => {
  const { currentGame } = useStore('currentGame')
  const [voiceId, setVoiceId] = useState<string | undefined>(initialVoiceId)
  const [voice, setVoice] = useState<Voice | undefined>()

  useEffect(() => {
    if (voiceId && (!voice || voice.id !== voiceId) && currentGame) {
      get(`/voices/${voiceId}`, { gameId: currentGame.id }).then(({ data: voice }) =>
        setVoice(voice)
      )
    }
    if (!voiceId) {
      setVoice(undefined)
    }
  }, [voiceId])

  return {
    voiceId,
    setVoiceId,
    voice,
    setVoice: (voice: Voice | undefined) => {
      setVoice(voice)
      if (voice) {
        setVoiceId(voice.id)
      } else {
        setVoiceId(undefined)
      }
    },
    clearVoice: () => {
      setVoiceId(undefined)
      setVoice(undefined)
    }
  }
}
