import { useState, useEffect } from 'preact/hooks'

const audio = new Audio()
audio.crossOrigin = 'anonymous'

type Params = {
  trackTime?: boolean
  onEnded?: () => void
}

export const usePlayer = (
  params: Params = {
    trackTime: false
  }
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [playingUri, setPlayingUri] = useState<string | null>()
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [onEndedCallback, setOnEndedCallback] = useState<Function>()

  useEffect(() => {
    setIsLoading(false)
    if (playingUri) {
      setIsLoading(true)
      let done = false

      const onEnded = () => {
        setPlayingUri(undefined)
        setCurrentTime(0)

        done = true

        if (params.onEnded) {
          params.onEnded()
        } else if (onEndedCallback) {
          // console.log('onEndedCallback called')
          onEndedCallback()
        }
      }
      const onCanPlay = () => {
        setIsLoading(false)
        audio.play()
        setDuration(audio.duration)
        if (navigator.mediaSession) {
          navigator.mediaSession.playbackState = 'playing'
        }

        if (params.trackTime) {
          const updateTime = () => {
            if (!done) {
              setCurrentTime(audio.currentTime)
              requestAnimationFrame(updateTime)
            }
          }
          requestAnimationFrame(updateTime)
        }
      }
      audio.addEventListener('ended', onEnded)
      audio.addEventListener('canplay', onCanPlay)
      audio.src = playingUri
      return () => {
        audio.removeEventListener('ended', onEnded)
        audio.removeEventListener('canplay', onCanPlay)
        audio.pause()
      }
    }
  }, [playingUri, params.trackTime, onEndedCallback])

  const play = (uri: string) => {
    setPlayingUri(uri)
  }

  const playRandom = (uris?: string[]) => {
    if (uris?.length) {
      const index = Math.floor(Math.random() * uris.length)
      play(uris[index])
    }
  }

  const stop = () => {
    setPlayingUri(undefined)
  }

  const togglePlaying = (uri?: string, onEnded?: Function) => {
    //console.log('togglePLaying', uri, onEnded)

    if (playingUri === uri) {
      setPlayingUri(null)
    } else {
      setPlayingUri(uri)
      setOnEndedCallback(onEnded)
    }
  }

  const isPlaying = (uri?: string) => playingUri !== undefined && playingUri === uri

  return {
    play,
    playRandom,
    stop,
    togglePlaying,
    isPlaying,
    playingUri,
    isLoading,
    duration,
    currentTime,
    setCurrentTime: (currentTime: number) => (audio.currentTime = currentTime)
  }
}
