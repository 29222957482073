import dateFormat from 'dateformat'
import { LineAttributeValue } from 'src/types'
import { lineAttributeToVariable } from '../WwiseExport/substitution-variables'

export type FilenameExampleOptions = {
  gameName: string
  characterName: string
  eventName: string
  sceneName: string
  filename: string
  ending: string
  lineAttributes?: LineAttributeValue[]
}

export const getFilenameExample = (
  folder = '',
  ending = 'wav',
  options?: FilenameExampleOptions
) => {
  let filenameExample = []
  if (folder) {
    folder = folder
      .replaceAll('$CHARACTER', options?.characterName || 'Some character')
      .replaceAll('$EVENT', options?.eventName || 'Some event')
      .replaceAll('$SCENE', options?.sceneName || 'Some scene')
      .replaceAll('$GAME', options?.gameName || 'Some game')
      .replaceAll('$DATE', dateFormat('yyyy-mm-dd'))
      .replaceAll('$TIME', dateFormat('HH:MM:ss'))

    if (options?.lineAttributes) {
      for (let la of options.lineAttributes) {
        if (la.value && typeof la.value === 'string') {
          folder = folder.replaceAll(lineAttributeToVariable(la), la.value)
        } else {
          folder = folder.replaceAll(lineAttributeToVariable(la), `Some ${la.name}`)
        }
      }
    }

    for (let part of folder.split('/')) {
      if (part.trim()) {
        filenameExample.push(part)
      }
    }
  }
  filenameExample.push((options?.filename ?? 'filename') + '.' + ending)
  return filenameExample.join('/')
}
