const scripts: Record<string, Promise<string>> = {}

export const loadScript = (src: string) => {
  if (!scripts[src]) {
    scripts[src] = new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => resolve(src)
      script.onerror = (err) => reject(err)
      document.getElementsByTagName('head')[0].appendChild(script)
    })
  }
  return scripts[src]
}

const links: Record<string, Promise<string>> = {}
export const loadCss = (href: string) => {
  if (!links[href]) {
    links[href] = new Promise((resolve, reject) => {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = href
      link.onload = () => resolve(href)
      link.onerror = (err) => reject(err)
      document.getElementsByTagName('head')[0].appendChild(link)
    })
  }
  return links[href]
}
