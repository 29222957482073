import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import { Button, Form, H1, SpacerHorizontal, SpacerVertical, TextField } from '@sodra/bongo-ui'

import { forgotPassword } from '../actions'

import LandingPage from './LandingPage'
import { goBack } from '@sodra/prutt'
import { useStore } from '../store'

const ForgotPassword = () => {
  const { isForgettingPassword } = useStore('isForgettingPassword')

  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [emailError, setEmailError] = useState('')

  const handleSubmit = async () => {
    if (!email || email.trim() === '') {
      setEmailError('Please enter email')
    } else {
      if (await forgotPassword({ email })) {
        setEmailSent(true)
      }
    }
  }

  return (
    <LandingPage>
      <H1 size="6">{emailSent ? 'Email sent' : 'Forgot password'}</H1>
      <SpacerVertical />
      {!emailSent && (
        <Form>
          <Block>Enter your email and we’ll send you a link to reset your password</Block>
          <SpacerVertical />
          <TextField
            width="100%"
            autocomplete="off"
            type="email"
            label="Email"
            value={email}
            onInput={setEmail}
            errorText={emailError}
          />
          <SpacerVertical />
          <Row alignItems="center">
            <Button contained type="submit" loading={isForgettingPassword} onClick={handleSubmit}>
              Continue
            </Button>
            <SpacerHorizontal />
            <Button text onClick={() => goBack('/')}>
              Cancel
            </Button>
          </Row>
        </Form>
      )}
      {emailSent && (
        <Block>Please check your inbox and follow the instructions to reset your password</Block>
      )}
    </LandingPage>
  )
}

export default ForgotPassword
