import { h } from 'preact'
import { Body1, InfoRoundIcon, SpacerHorizontal } from '@sodra/bongo-ui'

export const ErrorMessage = ({ children, ...style }: { children: any }) => (
  <Body1 color="var(--error)" {...style}>
    <InfoRoundIcon fill="var(--error)" size={20} marginTop="-2px" />
    <SpacerHorizontal tiny />
    {children}
  </Body1>
)
