import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Dialog, SpacerVertical } from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'

import { useStore } from '../../store'
import { fetchLineLabels } from '../../actions'
import { Label } from '../Line/Label'
import { LineLabel } from 'src/types'

type Props = {
  selectedLabels: string[]
  onClose: () => void
  onSelect: (labels: string[]) => void
}

export const SelectLabels = ({ selectedLabels: initialLabels, onClose, onSelect }: Props) => {
  const { lineLabels = [] } = useStore('currentGame', 'isNarrow', 'lineLabels')
  const [selectedLineLabels, setSelectedLineLabels] = useState<LineLabel[]>([])

  useEffect(() => {
    fetchLineLabels()
  }, [])

  useEffect(() => {
    const selectedLabels = lineLabels.filter((label) =>
      initialLabels.some((labelName) => labelName === label.name)
    )
    setSelectedLineLabels(selectedLabels)
  }, [lineLabels])

  const updateSelectedLineLabels = (label: LineLabel) => {
    setSelectedLineLabels((selectedLineLabels) => {
      const isSelected = selectedLineLabels.some((selectedLabel) => selectedLabel.id === label.id)
      if (isSelected) {
        return selectedLineLabels.filter((selectedLabel) => selectedLabel.id !== label.id)
      } else {
        return [...selectedLineLabels, label]
      }
    })
  }

  return (
    <Dialog
      title="Select labels"
      onClose={onClose}
      actions={[
        {
          text: 'Apply',
          type: 'contained',
          onClick: () => {
            onSelect(
              selectedLineLabels
                .map((label) => label.name)
                .sort((l1, l2) => l1.toLowerCase().localeCompare(l2.toLowerCase()))
            )
            onClose()
          },
          disabled:
            initialLabels.length === selectedLineLabels.length &&
            initialLabels.every((initialLabel) =>
              selectedLineLabels.some((selectedLabel) => selectedLabel.name === initialLabel)
            )
        },
        { text: 'Cancel', onClick: onClose }
      ]}
    >
      <Block fontSize="14px" color="var(--on-surface-light)">
        Select one or more labels to use as filter. Lines must match all selected labels to be
        visible.
      </Block>
      <SpacerVertical large />
      <Row alignItems="center" flexWrap="wrap" gap="10px">
        {lineLabels.map((label) => {
          return (
            <Label
              label={label.name}
              active={selectedLineLabels.some((selectedLabel) => selectedLabel.id === label.id)}
              onClick={() => {
                updateSelectedLineLabels(label)
              }}
            />
          )
        })}
      </Row>
      <SpacerVertical large />
    </Dialog>
  )
}
