import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { Route, routeTo, Switch } from '@sodra/prutt'

import { fetchCharacter } from '../../actions'
import { useEffectSkipFirst } from '../../use-effect-skip-first'

import Spinner from '../Spinner'

import EditDetails from './EditDetails'
import DeleteCharacter from './DeleteCharacter'
import CloneCharacter from './CloneCharacter'
import { useStore } from '../../store'

type Props = {
  characterId: string
}

export const Character = ({ characterId }: Props) => {
  const { currentGame, character } = useStore('currentGame', 'character')

  // Handle switch game
  useEffectSkipFirst(() => {
    routeTo('/characters', true)
  }, [currentGame!.id])

  useEffect(() => {
    fetchCharacter(characterId)
  }, [characterId])

  if (!character || character.id !== characterId) {
    return <Spinner />
  }

  return (
    <Switch>
      <Route path="/character/:characterId/delete" component={DeleteCharacter} />
      <Route path="/character/:characterId/clone" component={CloneCharacter} />
      <EditDetails />
    </Switch>
  )
}

export default Character
