import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import {
  Button,
  Dialog,
  Link,
  MemoryIcon,
  P,
  SpacerVertical,
  TabItem,
  Tabs,
  VoiceIcon
} from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

import { get } from '../../api'
import { setError, updateGame } from '../../actions'

import Page from '../Page'
import Spinner from '../Spinner'
import { StatsCard } from './StatsCard'
import { SessionsCard } from './SessionCard'
import { ActivityLog } from '../ActivityLog'
import { useStore } from '../../store'
import { AiQuota, AiQuotaType, BillingAccount, Session } from '../../types'
import { ResourcesUsage } from './ResourcesUsage'
import { urlSearchParams } from '../../url-search-params'
import { deleteSearchParam } from '../../delete-search-param'
import { BuyExtraAiQuotaTimeDialog } from '../BuyExtraAiQuotaTimeDialog'
import { fetchBillingAccount, fetchBillingAccounts } from '../../actions/billing-accounts'
import { checkBuyAiTimePreconditions } from '../check-buy-ai-time-preconditions'
import SelectBillingAccount from '../SelectBillingAccount'
import { confirmSelectBillingAccount } from '../confirmSelectBillingAccount'
import { formatCurrencyNoDecimals } from 'lib'

type Stats = {
  characters: number
  castAIVoices: number
  castUsers: number
  events: number
  scenes: number
  lines: number
  aiTakes: number
  userTakes: number
  aiVoices: number
}

export type GameAiQuota = {
  game?: AiQuota
  billingAccount?: {
    [type in AiQuotaType]: AiQuota[]
  }
}

export const GameDashboard = () => {
  const { currentGame, isNarrow, billingAccount, billingAccounts } = useStore(
    'currentGame',
    'isNarrow',
    'billingAccount',
    'billingAccounts'
  )

  const [active, setActive] = useState('Overview')
  const isActive = (label: string) => label === active

  const [stats, setStats] = useState<Stats>()
  const [sessions, setSessions] = useState<Session[]>()

  const [aiQuota, setAiQuota] = useState<GameAiQuota | undefined>(undefined)

  const [buyExtraTimeAiQuota, setBuyExtraTimeAiQuota] = useState<AiQuota | undefined>(undefined)
  const [showSelectBillingAccountDialog, setShowSelectBillingAccountDialog] = useState(false)

  const params = urlSearchParams()
  const tab = params.get('tab')

  useEffect(() => {
    fetchBillingAccounts()
  }, [])

  useEffect(() => {
    if (tab) {
      setActive(tab)
      deleteSearchParam('tab')
    }
  }, [tab])

  useEffect(() => {
    get(`/games/${currentGame!.id}/stats`)
      .then(({ data: stats }) => setStats(stats))
      .catch(setError)

    const now = new Date()
    now.setUTCHours(now.getUTCHours() - 1)
    get(`/games/${currentGame!.id}/sessions`, {
      scheduledAfter: now,
      orderBy: 'scheduled',
      sortOrder: 'asc'
    })
      .then(({ data: sessions }) => setSessions(sessions))
      .catch(setError)

    fetchGameAiQuota()
  }, [currentGame!.id])

  useEffect(() => {
    if (currentGame?.billingAccount?.id) {
      fetchBillingAccount(currentGame.billingAccount.id)
    }
  }, [currentGame?.billingAccount?.id])

  const fetchGameAiQuota = async () => {
    get(`/games/${currentGame!.id}/ai-quota`)
      .then(({ data: aiQuota }) => setAiQuota(aiQuota))
      .catch(setError)
  }

  const buyExtraAiTimeButtonClicked = async (aiQuota: AiQuota) => {
    if (!currentGame) {
      return
    }
    await checkBuyAiTimePreconditions({
      game: currentGame,
      onCreditCardRequired: addCreditCard,
      onBillingAccountRequired: () => {
        if (billingAccounts?.length) {
          setShowSelectBillingAccountDialog(true)
        } else {
          createBillingAccount()
        }
      },
      onSuccess: () => {
        // Open a dialog to buy extra time
        setBuyExtraTimeAiQuota(aiQuota)
      }
    })
  }

  const addCreditCard = () => {
    if (!currentGame?.billingAccount) {
      return
    }
    const params = new URLSearchParams({
      onSuccessUrl: `/?game=${currentGame.shortId}&tab=Usage`
    })
    routeTo(
      `/settings/billing/${currentGame.billingAccount.id}/add-credit-card?${params.toString()}`
    )
  }

  const createBillingAccount = () => {
    if (!currentGame) {
      return
    }
    const params = new URLSearchParams({
      onSuccessUrl: `/?game=${currentGame.shortId}&tab=Usage`
    })
    routeTo(`/settings/billing/add-billing-account?${params.toString()}`)
  }

  if (!currentGame || !stats || !sessions) {
    return <Spinner />
  }

  if (stats.characters === 0 && stats.events === 0 && stats.scenes === 0) {
    return (
      <Page title={currentGame.name}>
        Welcome to your new game! <br /> <br />
        Here are a few things that should get you started:
        <br />
        <br />
        <ul style="list-style:none; margin: 0; padding: 0">
          <li>
            🧑‍🎤{' '}
            <Link to="/characters" onRoute={routeTo}>
              Create your first character
            </Link>
          </li>
          <li>
            📜{' '}
            <Link to="/upload-excel" onRoute={routeTo}>
              Import/upload your script
            </Link>
          </li>
          <li>
            🔎{' '}
            <Link to="/voices" onRoute={routeTo}>
              Browse the voice library
            </Link>
          </li>
        </ul>
      </Page>
    )
  }

  const Overview = () => (
    <Fragment>
      <Row gap="20px" flexWrap="wrap">
        <StatsCard
          title="Characters"
          wide={isNarrow}
          value={stats.characters}
          link={{
            href: `/characters`,
            onRoute: routeTo
          }}
          iconNumbers={[
            { icon: MemoryIcon, value: stats.castAIVoices },
            { icon: VoiceIcon, value: stats.castUsers }
          ]}
        />
        <StatsCard
          title="Events"
          wide={isNarrow}
          value={stats.events}
          link={{
            href: `/events`,
            onRoute: routeTo
          }}
        />
        <StatsCard
          title="Scenes"
          wide={isNarrow}
          value={stats.scenes}
          link={{
            href: `/scenes`,
            onRoute: routeTo
          }}
        />
        <StatsCard
          title="Lines"
          wide={isNarrow}
          value={stats.lines}
          link={{
            href: `/lines`,
            onRoute: routeTo
          }}
          iconNumbers={[
            { icon: MemoryIcon, value: stats.aiTakes },
            { icon: VoiceIcon, value: stats.userTakes }
          ]}
        />
      </Row>
      {sessions.length > 0 && (
        <Fragment>
          <SpacerVertical />
          <SessionsCard game={currentGame} sessions={sessions} wide={isNarrow} />
        </Fragment>
      )}
    </Fragment>
  )

  const aiHourPrice = !billingAccount || billingAccount.plan === 'starter' ? 29 : 24

  return (
    <Page title={currentGame!.name}>
      {currentGame.demoGame && (
        <Fragment>
          <Block border="4px solid var(--on-surface)" padding="20px 40px">
            <P>Welcome to the Speechless demo game.</P>
            <SpacerVertical />
            <P>
              To get started, let’s{' '}
              <Link
                to={`${
                  import.meta.env.VITE_HOMEPAGE_URL
                }/support-articles/demo-project-nightmare-squad`}
                target="_blank"
                title="Speechless demo game"
              >
                explore
              </Link>{' '}
              more about this game and what you can do next.
            </P>
          </Block>
          <SpacerVertical />
          {location.search.includes('welcome') && (
            <Dialog
              dismissable={false}
              disableAutofocus
              title="Welcome to Speechless!"
              onClose={() => routeTo('/')}
              actions={[
                { text: 'Open demo game', onClick: () => routeTo('/') },
                { text: 'Create a new game', onClick: () => routeTo('/new-game') }
              ]}
            >
              We have created a demo game called "{currentGame.name}" with a few game characters and
              lines for you. Feel free to play around and familiarize yourself with the application.
              <SpacerVertical large />
              What do you want to do?
              <SpacerVertical />
            </Dialog>
          )}
        </Fragment>
      )}

      <Tabs type="fixed-left">
        <TabItem
          text="Overview"
          active={isActive('Overview')}
          onClick={() => setActive('Overview')}
        />
        <TabItem
          text="AI voices quota"
          active={isActive('Usage')}
          onClick={() => setActive('Usage')}
        />
        <TabItem
          text="Activity"
          active={isActive('Activity')}
          onClick={() => setActive('Activity')}
        />
      </Tabs>
      <SpacerVertical />

      {active === 'Overview' && <Overview />}

      {active === 'Usage' && (
        <>
          {aiQuota?.billingAccount && (
            <>
              {aiQuota.billingAccount.subscription.length > 0 && (
                <>
                  <ResourcesUsage
                    infoText={'This quota will be refilled at the beginning of every month.'}
                    billingAccount={currentGame.billingAccount}
                    aiQuota={aiQuota.billingAccount.subscription[0]}
                  />
                  <SpacerVertical small />
                </>
              )}
              {aiQuota.billingAccount.basic.length > 0 && (
                <>
                  <ResourcesUsage
                    infoText={'This quota will be used only if your subscription quota is empty.'}
                    billingAccount={currentGame.billingAccount}
                    aiQuota={aiQuota.billingAccount.basic[0]}
                  />
                  <SpacerVertical />
                  <Button
                    contained
                    onClick={() => buyExtraAiTimeButtonClicked(aiQuota.billingAccount!.basic[0])}
                  >
                    Buy extra hours
                  </Button>
                  <SpacerVertical />
                  <P color="var(--on-surface-light)">
                    AI voice quota cost: {formatCurrencyNoDecimals(aiHourPrice)} per hour
                  </P>
                  <SpacerVertical small />
                </>
              )}
            </>
          )}
          {!aiQuota?.billingAccount && aiQuota?.game && (
            <>
              <ResourcesUsage aiQuota={aiQuota.game} />
              <SpacerVertical />
              <Button contained onClick={() => buyExtraAiTimeButtonClicked(aiQuota.game!)}>
                Buy extra hours
              </Button>
              <SpacerVertical />
              <P color="var(--on-surface-light)">
                AI voice quota cost: {formatCurrencyNoDecimals(aiHourPrice)} per hour
              </P>
              <SpacerVertical small />
            </>
          )}
          {showSelectBillingAccountDialog && billingAccounts?.length && (
            <SelectBillingAccount
              billingAccounts={billingAccounts}
              onCreateBillingAccount={createBillingAccount}
              onClose={() => setShowSelectBillingAccountDialog(false)}
              onSelect={async (billingAccount) => {
                if (await confirmSelectBillingAccount({ billingAccount })) {
                  updateGame({ billingAccountId: billingAccount.id }).then(fetchGameAiQuota)
                  return true
                }
                return false
              }}
            />
          )}
        </>
      )}

      {active === 'Activity' && (
        <Block maxWidth="800px">
          <ActivityLog parameters={{ gameId: currentGame!.id }} logLevel={20} />
        </Block>
      )}

      {buyExtraTimeAiQuota && billingAccount && (
        <>
          <BuyExtraAiQuotaTimeDialog
            aiQuota={buyExtraTimeAiQuota}
            billingAccount={billingAccount}
            onSuccess={(aiQuota: AiQuota, hours: number) => {
              fetchGameAiQuota()
            }}
            onClose={() => {
              setBuyExtraTimeAiQuota(undefined)
            }}
          />
        </>
      )}
    </Page>
  )
}

export default GameDashboard
