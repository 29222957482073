import { store } from '../store'
import { get, patch, del, post } from '../api'

export const fetchMembers = async (params: any = {}) => {
  let cancelled = false
  store.setState({ isFetchingMembers: true })
  const gameId = store.getState().currentGame!.id
  get(`/games/${gameId}/members`, params)
    .then(({ data: members }) => {
      if (!cancelled) {
        store.setState({ members, isFetchingMembers: false })
      }
    })
    .catch((error) => {
      if (!cancelled) {
        store.setState({ error, isFetchingMembers: false })
      }
    })
  return () => {
    store.setState({ isFetchingMembers: false })
    cancelled = true
  }
}

export const updateMember = async (userId: string, params: any) => {
  try {
    store.setState({ isUpdatingMember: true })
    const gameId = store.getState().currentGame!.id
    const { data: members } = await patch(`/games/${gameId}/members/${userId}`, params)
    store.setState({ members, isUpdatingMember: false })
    return true
  } catch (error: any) {
    store.setState({ error, isUpdatingMember: false })
  }
}

export const removeMember = async (userId: string) => {
  try {
    store.setState({ isRemovingMember: true })
    const gameId = store.getState().currentGame!.id
    const { data: members } = await del(`/games/${gameId}/members/${userId}`)
    store.setState({ members, isRemovingMember: false })
    return true
  } catch (error: any) {
    store.setState({ error, isRemovingMember: false })
  }
}

type CreateVirtualMemberParams = {
  firstName: string
  lastName: string
  picture?: string
}

export const createVirtualMember = async (params: CreateVirtualMemberParams) => {
  try {
    const gameId = store.getState().currentGame!.id
    const { data: user } = await post(`/games/${gameId}/members/virtual-member`, params)
    return user
  } catch (error: any) {
    store.setState({ error, isUpdatingUser: false })
  }
}
