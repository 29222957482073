import dateFormat from 'dateformat'
import { LineAttribute, LineAttributeValue } from 'src/types'

type PathElement = { name: string; id: string }

export type PathOptions = {
  game?: PathElement
  character?: PathElement
  event?: PathElement
  scene?: PathElement
  lineAttributes?: LineAttributeValue[]
}

export const getPath = (folder = '', options: PathOptions, example: boolean = false) => {
  let path: PathElement[] = []
  if (folder) {
    path = applySubstitutionVariablesPath(folder, options, example)
  }
  return path
}

export function lineAttributeToVariable(la: LineAttribute) {
  return `$${la.name.toUpperCase().split(' ').join('_')}`
}

export function applySubstitutionVariablesString(
  str: string,
  options?: PathOptions,
  example: boolean = false
) {
  let now = new Date()

  if (options?.lineAttributes) {
    for (let la of options.lineAttributes) {
      if (la.value && typeof la.value === 'string') {
        str = str.replaceAll(lineAttributeToVariable(la), la.value)
      } else {
        str = str.replaceAll(lineAttributeToVariable(la), example ? `Some ${la.name}` : '')
      }
    }
  }

  return str
    .replaceAll('$CHARACTER', options?.character?.name ?? (example ? 'Some character' : ''))
    .replaceAll('$EVENT', options?.event?.name ?? (example ? 'Some event' : ''))
    .replaceAll('$SCENE', options?.scene?.name ?? (example ? 'Some scene' : ''))
    .replaceAll('$GAME', options?.game?.name ?? (example ? 'Some game' : ''))
    .replaceAll('$DATE', dateFormat(now, 'yyyy-mm-dd'))
    .replaceAll('$TIME', dateFormat(now, 'HH:MM:ss'))
}

function createPathElement(part: string, key: string, option: PathElement, example: boolean): PathElement | null{
  if(option && part.includes(`$${key.toUpperCase()}`)){
    return {
      name: option.name ?? (example ? `Some ${key.toLowerCase()}` : ''),
      id: option.id
    };
  }

  return null;
}

export function applySubstitutionVariablesPath(
  str: string,
  options: PathOptions,
  example: boolean = false
): PathElement[] {
  let now = new Date()

  const parts = str.split('\\').filter(Boolean)
  const path: PathElement[] = []

  for (let part of parts) {
    /**
     * By keeping track of whether we've matched a variable, we limit each part to only one substitution.
     * This is not the case with the string function above.
     * This limitation is on purpose as we can't keep track of the id's otherwise (unless we want to create a more complicated structure).
     */
    let matched = false
    const elements = ['character', 'event', 'scene', 'game'] as const;

    //TODO: Handle line attributes again
    // if (options?.lineAttributes) {
    //   for (let la of options.lineAttributes) {
    //     if (la.value && typeof la.value === 'string') {
    //       path.push({ name: part.replaceAll(lineAttributeToVariable(la), la.value), id: la.id })
    //     } else {
    //       path.push({
    //         name: part.replaceAll(lineAttributeToVariable(la), example ? `Some ${la.name}` : ''),
    //         id: la.id
    //       })
    //     }
    //   }
    // }

    for (let element of elements) {
      const option = options[element]
      if(!option) continue;
   
      const pathElement = createPathElement(part, element, option, example);
      if(pathElement){
        path.push(pathElement);
        matched = true;
        break;
      }
    }

    if (!matched) {
      path.push({ name: part, id: part })
    }
  }

  return path
}
