import { Checkbox, TextArea, TextField } from '@sodra/bongo-ui'
import { LineAttribute, LineAttributeType } from '../../types'
import dateFormat from 'dateformat'
import { Fragment } from 'preact'
import { Block } from 'jsxstyle/preact'

type Props = {
  attribute: LineAttribute
  value: LineAttributeType | undefined
  onChange: (value: LineAttributeType | undefined) => void
  errorText?: string
}

export const LineAttributeInput = ({ attribute, value, onChange, errorText }: Props) => {
  const handleNumberChange = (value: string) => {
    onChange(value ? parseFloat(value) : undefined)
  }

  const requiredStr = attribute.required ? ' *' : ''
  const label = `${attribute.name}${requiredStr}`

  if (attribute.type === 'boolean') {
    return (
      <>
        <Checkbox label={label} checked={value} onChange={onChange} errorText={errorText} />
        {attribute.description && (
          <Block color="var(--on-surface-light)" fontSize="14px" lineHeight="16px">
            {attribute.description}
          </Block>
        )}
      </>
    )
  }
  if (attribute.type === 'date') {
    return (
      <TextField
        label={label}
        type="date"
        value={value ? dateFormat(value as string, 'yyyy-mm-dd') : undefined}
        onInput={onChange}
        infoText={attribute.description}
        errorText={errorText}
      />
    )
  }
  if (attribute.type === 'number') {
    return (
      <TextField
        type="number"
        autocomplete="off"
        width="100%"
        label={label}
        value={value}
        onInput={handleNumberChange}
        infoText={attribute.description}
        errorText={errorText}
      />
    )
  }
  if (attribute.type === 'url') {
    return (
      <TextField
        type="text"
        autocomplete="off"
        width="100%"
        label={label}
        value={value}
        onInput={onChange}
        infoText={attribute.description}
        errorText={errorText}
      />
    )
  }
  return (
    <TextArea
      autocomplete="off"
      width="100%"
      autoHeight
      minRows={3}
      maxRows={10}
      label={label}
      value={value}
      onInput={onChange}
      infoText={attribute.description}
      errorText={errorText}
    />
  )
}
