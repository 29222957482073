import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import {
  AddIcon,
  Button,
  ChatBubbleIcon,
  ContentCopyIcon,
  DeleteIcon,
  EditIcon,
  IconButton,
  List,
  ListItem,
  PdfIcon,
  PlayIcon,
  SpacerHorizontal,
  SpacerVertical,
  StopIcon
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import {
  deleteExampleLine,
  fetchChats,
  generateCharacterAITakes,
  removeFromShortlist,
  setError,
  showSnackbar,
  updateCharacter,
  updateGame
} from '../../actions'

import Avatar from '../Avatar'
import Page from '../Page'
import SelectAIVoice from '../SelectAIVoice'

import CreateExampleLine from './CreateExampleLine'
import EditExampleLine from './EditExampleLine'
import { buildSpeakerUri } from '../../speak'
import PictureDialog from '../PictureDialog'
import { ActivityLog } from '../ActivityLog'
import { createChat } from '../../actions'

import { useStore } from '../../store'
import { AIVoice, AiQuota, Voice } from '../../types'
import { useAudioPlayer, SingleAudioTrack } from 'lib/src/hooks/use-audio-player'
import { confirmBillingAccount } from '../confirmBillingAccount'
import { confirm } from 'lib'
import { SuperForm } from '../SuperForm'
import { AIAvatar } from '../AIAvatar'
import { formatLanguage } from '../../format-language'
import AuditionDetails, { AuditionDetailsSubmitParams } from '../AuditionDetails'
import { GameAiQuota } from '../GameDashboard'
import { get } from '../../api'
import { fetchBillingAccount, fetchBillingAccounts } from '../../actions/billing-accounts'
import { isGameAiQuotaEmpty } from '../is-game-ai-quota-empty'
import { checkBuyAiTimePreconditions } from '../check-buy-ai-time-preconditions'
import { BuyExtraAiQuotaTimeDialog } from '../BuyExtraAiQuotaTimeDialog'
import { deleteSearchParam } from '../../delete-search-param'
import SelectBillingAccount from '../SelectBillingAccount'
import { confirmSelectBillingAccount } from '../confirmSelectBillingAccount'

export const EditDetails = () => {
  const {
    character,
    isUpdatingCharacter,
    isCreatingChat,
    isNarrow,
    currentGame,
    currentLanguage,
    billingAccount,
    billingAccounts
  } = useStore(
    'character',
    'isUpdatingCharacter',
    'isCreatingChat',
    'isNarrow',
    'currentGame',
    'currentLanguage',
    'billingAccount',
    'billingAccounts'
  )

  const { player } = useAudioPlayer()
  if (!player) {
    return null
  }
  const { hidePlayer, initPlayer, play, playerState, showPlayer, stopPlayer } = player

  const [picture, setPicture] = useState(character!.picture)
  const [aiVoice, _setAIVoice] = useState<AIVoice | undefined>(character!.aiVoices[currentLanguage])
  const [isDownloadingCharacterSheet, setIsDownloadingCharacterSheet] = useState<boolean>(false)

  const [gameAiQuota, setGameAiQuota] = useState<GameAiQuota | undefined>()
  const [buyExtraTimeAiQuota, setBuyExtraTimeAiQuota] = useState<AiQuota | undefined>(undefined)

  const [showSelectBillingAccountDialogType, setShowSelectBillingAccountDialogType] = useState<
    'ai' | 'chat' | undefined
  >(undefined)

  useEffect(() => {
    _setAIVoice(character!.aiVoices[currentLanguage])
  }, [currentLanguage])

  useEffect(() => {
    if (currentGame) {
      fetchGameAiQuota()

      if (currentGame.billingAccount) {
        fetchBillingAccount(currentGame.billingAccount.id)
      }

      fetchBillingAccounts()
    }
  }, [currentGame])

  const fetchGameAiQuota = async (): Promise<GameAiQuota | undefined> => {
    if (currentGame) {
      return get(`/games/${currentGame.id}/ai-quota`).then(({ data: aiQuota }) => {
        setGameAiQuota(aiQuota)
        return aiQuota
      })
    }
  }

  const setAIVoice = (aiVoice?: AIVoice) => {
    _setAIVoice(aiVoice)
    updateCharacter({
      aiVoices: [{ language: currentLanguage, aiVoiceId: aiVoice?.id || null }]
    }).then(() => {
      showSnackbar('Voice AI saved')
      if (aiVoice) {
        generateAITakes()
      }
    })
  }

  const onBack = () => goBack('/characters')

  const closeDialog = () => goBack(`/character/${character!.id}`)

  const secondaryLanguage =
    currentLanguage !== currentGame?.primaryLanguage ? currentLanguage : undefined

  const getCreateBillingAccountUrlSearchParams = (context: 'ai' | 'chat'): URLSearchParams => {
    if (context === 'ai') {
      return new URLSearchParams({
        onSuccessUrl: `/character/${character!.id}?game=${
          currentGame!.shortId
        }&autoGenerateAiTakes=true`
      })
    } else if (context === 'chat') {
      // NOTE: No credit card needs to be added to initiate a chat
      return new URLSearchParams({
        skipCreditCard: 'true',
        onSuccessUrl: `${location.pathname}?initiateChat=true&voiceId=${voiceId}`
      })
    }

    return new URLSearchParams()
  }

  const createBillingAccount = ({ urlSearchParams }: { urlSearchParams: URLSearchParams }) => {
    if (!currentGame) {
      return
    }

    routeTo(`/settings/billing/add-billing-account?${urlSearchParams.toString()}`)
  }

  const addCreditCardForAiTimePurchase = () => {
    if (!currentGame?.billingAccount) {
      return
    }
    const params = new URLSearchParams({
      onSuccessUrl: `/character/${character!.id}?game=${
        currentGame.shortId
      }&autoGenerateAiTakes=true`
    })
    routeTo(
      `/settings/billing/${currentGame.billingAccount.id}/add-credit-card?${params.toString()}`
    )
  }

  const buyExtraAiTime = async () => {
    if (!currentGame) {
      return
    }
    await checkBuyAiTimePreconditions({
      game: currentGame,
      onCreditCardRequired: addCreditCardForAiTimePurchase,
      onBillingAccountRequired: () => {
        if (billingAccounts?.length) {
          setShowSelectBillingAccountDialogType('ai')
        } else {
          createBillingAccount({ urlSearchParams: getCreateBillingAccountUrlSearchParams('ai') })
        }
      },
      onSuccess: async () => {
        if (!gameAiQuota) {
          // This if block is just a workaround to fix problem that gameAiQuota is sometimes undefined....
          const aiQuota = await fetchGameAiQuota()
          if (aiQuota?.billingAccount?.basic?.[0]) {
            setBuyExtraTimeAiQuota(aiQuota?.billingAccount.basic[0])
          }
        } else {
          // Open a dialog to buy extra time
          if (gameAiQuota?.billingAccount?.basic?.[0]) {
            setBuyExtraTimeAiQuota(gameAiQuota.billingAccount.basic[0])
          }
        }
      }
    })
  }

  const generateAITakes = async () => {
    const confirmTitle = secondaryLanguage
      ? `Generate ${formatLanguage(secondaryLanguage)} AI takes`
      : 'Generate AI takes'

    if (
      await confirm({
        title: confirmTitle,
        message: 'Do you want to generate new AI takes for all character lines?',
        confirmText: 'Yes',
        rejectText: 'No'
      })
    ) {
      if (!gameAiQuota || isGameAiQuotaEmpty(gameAiQuota)) {
        if (
          await confirm({
            title: 'Your AI voices quota is empty',
            message: 'Would you like to buy some more hours to keep the magic going?',
            confirmText: 'Buy more hours'
          })
        ) {
          await buyExtraAiTime()
        }
        return
      }

      if (await generateCharacterAITakes(character!.id, currentLanguage)) {
        showSnackbar('Started generating AI takes')
      }
    }
  }

  const handleInitiateChat = async (voiceId: string) => {
    const billingAccount = currentGame?.billingAccount

    // Billing account has to be set before creating a chat
    if (!billingAccount) {
      if (
        await confirmBillingAccount({
          message: 'To open a chat your game needs a billing account.'
        })
      ) {
        const createBillingAccountParams = getCreateBillingAccountUrlSearchParams('chat')

        if (billingAccounts?.length) {
          setShowSelectBillingAccountDialogType('chat')
        } else {
          createBillingAccount({ urlSearchParams: createBillingAccountParams })
        }
      }
      return
    }

    // Ask for audition details
    routeTo(`/character/${character?.id}/audition-details/${voiceId}`)
  }

  const initiateChat = async (
    voiceId: string,
    auditionDetails?: AuditionDetailsSubmitParams,
    shareCharacterSheet?: boolean
  ) => {
    const chat = await createChat(voiceId, auditionDetails, shareCharacterSheet)
    if (chat) {
      await fetchChats()
      routeTo(`/chats/${chat.id}`)
    }
  }

  const params = new URLSearchParams(location.search)
  const doInitiateChat = params.get('initiateChat') === 'true'
  const voiceId = params.get('voiceId')
  const autoGenerateAiTakes = params.get('autoGenerateAiTakes') === 'true'

  useEffect(() => {
    if (autoGenerateAiTakes) {
      deleteSearchParam('autoGenerateAiTakes')
      generateAITakes()
    }
  }, [autoGenerateAiTakes])

  useEffect(() => {
    if (doInitiateChat && voiceId) {
      params.delete('initiateChat')
      params.delete('voiceId')
      routeTo(location.pathname + '?' + params.toString(), true)
      handleInitiateChat(voiceId)
    }
  }, [doInitiateChat, voiceId])

  const handleSubmit = async (values: any) => {
    if (await updateCharacter(values)) {
      showSnackbar('Character details saved')
      onBack()
    }
  }

  const updatePicture = async (url: string) => {
    await updateCharacter({ picture: url })
    setPicture(url)
    return true
  }

  const removePicture = async () => {
    await updateCharacter({ picture: '' })
    setPicture('')
    return true
  }

  const downloadCharacterSheet = async () => {
    setIsDownloadingCharacterSheet(true)
    try {
      const url = `${import.meta.env.VITE_API_URL}/v1/characters/${character!.id}.pdf`
      const res = await fetch(url, { credentials: 'include' })
      if (!res.ok) {
        const errorMessage = await res.text()
        throw new Error(errorMessage)
      }
      location.href = url
    } catch (error) {
      setError(error)
    } finally {
      setIsDownloadingCharacterSheet(false)
    }
  }

  const primaryLanguageAiVoice = character?.aiVoices[currentGame!.primaryLanguage]

  useEffect(() => {
    if (!primaryLanguageAiVoice) {
      return
    }

    const audioTracks =
      character!.exampleLines!.map((exampleLine): SingleAudioTrack => {
        const uri = buildSpeakerUri({
          aiVoiceId: primaryLanguageAiVoice!.id,
          text: exampleLine.line
        })

        return {
          uri,
          name: exampleLine.line,
          picture: character?.picture,
          metaData: {
            exampleLineId: exampleLine.id
          }
        }
      }) || []

    if (audioTracks.length > 0) {
      initPlayer({ audioTracks })
    }
  }, [character?.exampleLines, primaryLanguageAiVoice?.id])

  return (
    <Page
      title={character!.name}
      actions={[
        {
          label: 'Character sheet',
          icon: PdfIcon,
          loading: isDownloadingCharacterSheet,
          onClick: downloadCharacterSheet
        },
        {
          label: 'Clone',
          icon: ContentCopyIcon,
          onClick: () => routeTo(`/character/${character!.id}/clone`)
        },
        {
          label: 'Delete',
          icon: DeleteIcon,
          onClick: () => routeTo(`/character/${character!.id}/delete`)
        }
      ]}
      onBack={onBack}
    >
      <Row gap="30px" flexDirection={isNarrow ? 'column' : 'row'}>
        <Block maxWidth="500px" flexBasis="500px">
          <Avatar
            actionIcon={EditIcon}
            onActionClick={() => routeTo(`/character/${character!.id}/picture`)}
            size={150}
            src={picture}
            name={name}
          />
          <SpacerVertical />

          <SuperForm
            disableSubmitIfNoChanges={true}
            onSubmit={handleSubmit}
            onCancel={onBack}
            submitText="Save"
            loading={isUpdatingCharacter}
            elements={[
              {
                element: 'TextField',
                label: 'Name',
                name: 'name',
                value: character!.name,
                required: true
              },
              {
                element: 'TextField',
                label: 'Archetype',
                placeholder: 'E.g. The hero',
                name: 'archetype',
                value: character!.archetype
              },
              {
                element: 'TextField',
                label: 'Gender',
                placeholder: 'E.g. Female',
                name: 'gender',
                value: character!.gender
              },
              {
                element: 'TextField',
                label: 'Age',
                placeholder: 'E.g. 20s',
                name: 'age',
                value: character!.age
              },
              {
                element: 'TextField',
                label: 'Accent',
                placeholder: 'E.g. Received Pronunciation',
                name: 'accent',
                value: character!.accent
              },
              {
                element: 'TextField',
                label: 'Style',
                placeholder: 'E.g. Perky/Dark, Moody, Detached, Slightly mad',
                name: 'style',
                value: character!.style
              },
              {
                element: 'TextArea',
                label: 'Backstory',
                name: 'backstory',
                value: character!.backstory
              },
              {
                element: 'TextArea',
                label: 'Personality',
                name: 'personality',
                value: character!.personality
              }
            ]}
          />
        </Block>

        <Block maxWidth="500px" flexBasis="500px">
          <Row alignItems="center" gap="10px">
            <Block fontSize="20px">AI voice</Block>
            {secondaryLanguage && (
              <Block color="var(--on-surface-light)" fontSize="14px">
                ({formatLanguage(secondaryLanguage)})
              </Block>
            )}
            <SpacerHorizontal small />
            <Button
              link={{
                href: `/character/${character!.id}/select-ai-voice`,
                onRoute: routeTo
              }}
            >
              Select
            </Button>
            {aiVoice && <Button onClick={generateAITakes}>Generate AI takes</Button>}
          </Row>
          {aiVoice && (
            <List>
              <ListItem
                visual={<AIAvatar size={30} src={aiVoice.picture} name={aiVoice.name} />}
                text={aiVoice.name}
                link={{
                  href: `/ai-voice/${aiVoice.id}`,
                  onRoute: routeTo
                }}
                actionIcon={DeleteIcon}
                onActionClick={() => setAIVoice(undefined)}
              />
            </List>
          )}
          <SpacerVertical large />
          <Row alignItems="center">
            <Block fontSize="20px">Voice talent shortlist</Block>
            <SpacerHorizontal small />
            <Button
              link={{
                href: `/voices`,
                onRoute: routeTo
              }}
            >
              Voice library
            </Button>
          </Row>
          <List>
            {character!.shortlist?.map((voice: Voice) => {
              return (
                <ListItem
                  visual={<Avatar size={30} src={voice.picture} name={voice.name} />}
                  link={{
                    href: `/voice/${voice.id}`,
                    onRoute: routeTo
                  }}
                  text={
                    <Row alignItems="center" gap="10px">
                      <Block flex="1">
                        <Block>{voice.name}</Block>
                      </Block>
                      {voice.chatId && (
                        <IconButton
                          small
                          icon={ChatBubbleIcon}
                          color="var(--on-surface)"
                          link={{
                            href: `/chats/${voice.chatId}`,
                            onRoute: routeTo
                          }}
                        />
                      )}

                      {!voice.chatId && (
                        <Button
                          small
                          outlined
                          loading={isCreatingChat === voice.id}
                          icon={ChatBubbleIcon}
                          color="var(--on-surface)"
                          borderColor="var(--on-surface)"
                          onClick={() => handleInitiateChat(voice.id)}
                        >
                          Initiate chat
                        </Button>
                      )}

                      <IconButton
                        small
                        color="var(--on-surface)"
                        icon={DeleteIcon}
                        onClick={() => {
                          removeFromShortlist(character!.id, voice.id)
                        }}
                      />
                    </Row>
                  }
                />
              )
            })}
          </List>
          <SpacerVertical large />
          <Row alignItems="center" gap="10px">
            <Block fontSize="20px">Audition lines</Block>
            {secondaryLanguage && (
              <Block color="var(--on-surface-light)" fontSize="14px">
                ({formatLanguage(currentGame!.primaryLanguage)})
              </Block>
            )}
            <SpacerHorizontal small />
            <Button
              icon={AddIcon}
              link={{
                href: `/character/${character!.id}/create-example-line`,
                onRoute: routeTo
              }}
            >
              Add line
            </Button>
          </Row>
          <SpacerVertical />
          <List>
            {character!.exampleLines!.map((exampleLine, i) => {
              const isPlayingCurrentTrack =
                playerState.value.isPlaying &&
                playerState.value.currentTrack?.metaData?.exampleLineId === exampleLine.id

              const togglePlay = () => {
                if (isPlayingCurrentTrack) {
                  stopPlayer()
                  hidePlayer()
                } else {
                  showPlayer()
                  play({
                    audioTrackIndex: i
                  })
                }
              }

              const primaryLanguageAiVoice = character?.aiVoices[currentGame!.primaryLanguage]

              return (
                <ListItem
                  text={
                    <Row gap="10px" alignItems="center">
                      <Block flex="1">{exampleLine.line}</Block>
                      <IconButton
                        small
                        color="var(--on-surface)"
                        disabled={!primaryLanguageAiVoice}
                        icon={
                          isPlayingCurrentTrack
                            ? () => StopIcon({ fill: 'var(--accent)' })
                            : PlayIcon
                        }
                        loading={isPlayingCurrentTrack && playerState.value.loading}
                        onClick={togglePlay}
                      />

                      <IconButton
                        small
                        color="var(--on-surface)"
                        icon={DeleteIcon}
                        onClick={async () => {
                          if (
                            await confirm({
                              title: 'Delete audition line?',
                              message: `"${exampleLine.line}"`,
                              confirmText: 'Delete',
                              rejectText: 'Cancel'
                            })
                          ) {
                            deleteExampleLine(exampleLine.id)
                          }
                        }}
                      />
                    </Row>
                  }
                  secondaryText={exampleLine.description}
                  link={{
                    href: `/character/${character!.id}/example-lines/${exampleLine.id}`,
                    onRoute: routeTo
                  }}
                />
              )
            })}
          </List>
          <SpacerVertical large />
        </Block>
      </Row>
      <SpacerVertical large />

      <Block maxWidth="800px">
        <ActivityLog
          parameters={{
            gameId: currentGame!.id,
            characterId: character!.id
          }}
        />
      </Block>

      <Route
        path="/character/:characterId/select-ai-voice"
        render={() => <SelectAIVoice onClose={closeDialog} onSelect={setAIVoice} />}
      />
      <Route
        path="/character/:characterId/create-example-line"
        render={() => <CreateExampleLine onClose={closeDialog} />}
      />
      <Route
        path="/character/:characterId/example-lines/:lineId"
        render={(props: any) => {
          const line = character!.exampleLines?.find(
            (exampleLine) => exampleLine.id === props.lineId
          )
          if (line) {
            return <EditExampleLine exampleLine={line} onClose={closeDialog} />
          }
        }}
      />
      <Route
        path="/character/:characterId/picture"
        render={() => (
          <PictureDialog
            title="Character picture"
            url={picture}
            onUpdate={updatePicture}
            onRemove={removePicture}
            onClose={() => goBack(`/character/${character!.id}`)}
          />
        )}
      />
      <Route
        path="/character/:characterId/audition-details/:voiceId"
        render={(props: any) => {
          const voice = character!.shortlist?.find((v: Voice) => v.id === props.voiceId)
          if (voice) {
            return (
              <AuditionDetails
                onSubmit={(auditionParams, shareCharacterSheet) =>
                  initiateChat(voice.id, auditionParams, shareCharacterSheet)
                }
                onSkip={() => initiateChat(voice.id)}
                onClose={() => routeTo(`/character/${character?.id}`)}
              />
            )
          }
        }}
      />

      {buyExtraTimeAiQuota && billingAccount && (
        <>
          <BuyExtraAiQuotaTimeDialog
            aiQuota={buyExtraTimeAiQuota}
            billingAccount={billingAccount}
            onSuccess={(aiQuota: AiQuota, hours: number) => {
              fetchGameAiQuota()
            }}
            onClose={() => {
              setBuyExtraTimeAiQuota(undefined)
            }}
          />
        </>
      )}

      {showSelectBillingAccountDialogType && billingAccounts?.length && (
        <SelectBillingAccount
          billingAccounts={billingAccounts}
          onCreateBillingAccount={() => {
            createBillingAccount({
              urlSearchParams: getCreateBillingAccountUrlSearchParams(
                showSelectBillingAccountDialogType
              )
            })
          }}
          onClose={() => {
            setShowSelectBillingAccountDialogType(undefined)
          }}
          onSelect={async (billingAccount) => {
            if (await confirmSelectBillingAccount({ billingAccount })) {
              updateGame({ billingAccountId: billingAccount.id }).then(fetchGameAiQuota)
              return true
            }
            return false
          }}
        />
      )}
    </Page>
  )
}

export default EditDetails
