import { h, JSX } from 'preact'
import { DropdownMenu } from '@sodra/bongo-ui'

export type Position = {
  x: number
  y: number
}

export type PopupMenuOption = {
  divider?: boolean
  icon?: any
  label: string
  onClick?: (data?: any) => void
  link?: {
    href: string
    onRoute: (path: string, options?: any) => void
  }
  disabled?: boolean | ((data?: any) => boolean)
  visible?: boolean | ((data?: any) => boolean)
  options?: PopupMenuOption[]
}

type Props = {
  position?: Position
  positionElement?: HTMLElement
  data?: any
  options?: PopupMenuOption[]
  onClose?: () => void
}

const isOptionVisible = (option: PopupMenuOption, data?: any): boolean => {
  if (typeof option.visible === 'function') {
    return option.visible(data)
  }
  return option.visible !== false
}

export const PopupMenu = ({ position, positionElement, data, options, onClose }: Props) => {
  const items = options
    ?.filter((option) => isOptionVisible(option, data))
    .map((option) => {
      const { divider, label: text, onClick, link, disabled, options } = option
      let isDisabled = !!disabled
      if (typeof disabled === 'function') {
        isDisabled = disabled(data)
      }
      return {
        divider,
        text,
        onClick: () => {
          if (onClick) {
            onClick(data)
          }
          if (onClose) {
            onClose()
          }
        },
        link,
        disabled: isDisabled,
        items: options?.map((option) => {
          const { divider, label: text, onClick, link, disabled } = option
          return {
            divider,
            text,
            onClick: (data: any) => {
              if (onClick) {
                onClick(data)
              }
              if (onClose) {
                onClose()
              }
            },
            link,
            disabled: isDisabled
          }
        })
      }
    })

  return (
    <DropdownMenu
      items={items}
      position={position}
      positionElement={positionElement}
      onClose={onClose}
    />
  )
}
