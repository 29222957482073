import md5 from 'md5'

export const getFileHash = (file: File) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        const hash = md5(new Uint8Array(e.target.result as ArrayBuffer))
        resolve(hash)
      }
    }
    fileReader.onerror = (err) => reject(err)
    fileReader.readAsArrayBuffer(file)
  })
