import { store } from '../store'

const systemUrl = import.meta.env.VITE_SYSTEM_URL + '/v1'

export async function fetchConfig() {
  const response = await fetch(`${systemUrl}/config/global`)
  if (!response.ok) {
    store.setState({ error: new Error('Failed to fetch config') })
    return
  }
  const json = await response.json()
  const { data: config } = json

  store.setState({
    config
  })
}
