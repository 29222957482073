import dateFormat from 'dateformat'

export const formatDuration = (date?: string | Date) => {
  if (!date) {
    return '–'
  }
  const now = new Date()
  const then = new Date(date)
  if (dateFormat(now, 'yyyy-mm-dd') !== dateFormat(then, 'yyyy-mm-dd')) {
    return dateFormat(then, 'yyyy-mm-dd')
  }
  const duration = now.getTime() - then.getTime()
  const seconds = Math.round(duration / 1000)
  if (seconds < 60) {
    return `just now`
  }
  const minutes = Math.round(seconds / 60)
  if (minutes < 60) {
    return `${minutes} min ago`
  }
  const hours = Math.round(minutes / 60)
  if (hours < 3) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`
  }
  return 'Today ' + dateFormat(date, 'HH:MM')
}
