import { useEffect } from 'preact/hooks'

export type SSEType =
  | 'line-updated'
  | 'line-restored'
  | 'take-created'
  | 'take-updated'
  | 'take-deleted'
  | 'take-restored'
  | 'character-created'
  | 'character-deleted'
  | 'character-updated'
  | 'export-target-updated'
  | 'import-source-updated'
  | 'remote-control-request'
  | 'remote-control-response'
  | 'stop-remote-control'
  | 'remote-control-action'
  | 'session-updated'

export type SSERemoteControlAction =
  | 'select-line'
  | 'start-recording'
  | 'recording-started'
  | 'stop-recording'
  | 'recording-stopped'
  | 'select-character'
  | 'play-take'
  | 'stop-take'
  | 'show-all-takes'
  | 'play-reference-audio'
  | 'stop-reference-audio'

export type SSERemoteControlRequestUser = {
  name: string
  picture: string
}

type Params = {
  gameId?: string
  sessionId?: string
  chatId?: string
  enabled?: boolean
  onLineUpdated?: (lineId: string) => void
  onLineRestored?: (lineId: string) => void
  onTakeCreated?: (takeId: string) => void
  onTakeUpdated?: (takeId: string) => void
  onTakeDeleted?: (takeId: string) => void
  onTakeRestored?: (takeId: string) => void
  onCharacterCreated?: (characterId: string) => void
  onCharacterUpdated?: (characterId: string) => void
  onCharacterDeleted?: (characterId: string) => void
  onExportTargetUpdated?: (exportTargetId: string) => void
  onImportSourceUpdated?: (importSourceId: string) => void
  onRemoteControlRequest?: (requestId: string, user: SSERemoteControlRequestUser) => void
  onRemoteControlResponse?: (requestId: string, accept: boolean) => void
  onStopRemoteControl?: (requestId: string) => void
  onRemoteControlAction?: (requestId: string, action: SSERemoteControlAction, rest: any) => void
  onSessionUpdated?: (sessionId: string) => void
}

const buildUrl = (params: Params) => {
  const url = new URL(import.meta.env.VITE_SSE_URL)
  if (params.gameId) {
    url.searchParams.set('gameId', params.gameId)
  }
  if (params.sessionId) {
    url.searchParams.set('sessionId', params.sessionId)
  }
  if (params.chatId) {
    url.searchParams.set('chatId', params.chatId)
  }
  if (params.onLineUpdated) {
    url.searchParams.append('event', 'line-updated')
  }
  if (params.onLineRestored) {
    url.searchParams.append('event', 'line-restored')
  }
  if (params.onTakeCreated) {
    url.searchParams.append('event', 'take-created')
  }
  if (params.onTakeUpdated) {
    url.searchParams.append('event', 'take-updated')
  }
  if (params.onTakeDeleted) {
    url.searchParams.append('event', 'take-deleted')
  }
  if (params.onTakeRestored) {
    url.searchParams.append('event', 'take-restored')
  }
  if (params.onCharacterCreated) {
    url.searchParams.append('event', 'character-created')
  }
  if (params.onCharacterUpdated) {
    url.searchParams.append('event', 'character-updated')
  }
  if (params.onCharacterDeleted) {
    url.searchParams.append('event', 'character-deleted')
  }
  if (params.onExportTargetUpdated) {
    url.searchParams.append('event', 'export-target-updated')
  }
  if (params.onImportSourceUpdated) {
    url.searchParams.append('event', 'import-source-updated')
  }
  if (params.onRemoteControlRequest) {
    url.searchParams.append('event', 'remote-control-request')
  }
  if (params.onRemoteControlResponse) {
    url.searchParams.append('event', 'remote-control-response')
  }
  if (params.onRemoteControlAction) {
    url.searchParams.append('event', 'remote-control-action')
  }
  if (params.onStopRemoteControl) {
    url.searchParams.append('event', 'stop-remote-control')
  }
  if (params.onSessionUpdated) {
    url.searchParams.append('event', 'session-updated')
  }
  return url.toString()
}

export const useSSE = ({ enabled = true, ...params }: Params) => {
  useEffect(() => {
    if (enabled && params.gameId) {
      const url = buildUrl(params)
      //console.log(url)
      const evtSource = new EventSource(url)
      evtSource.onmessage = (e: any) => {
        const message = JSON.parse(e.data)
        const event = message.event as SSEType
        if (event === 'line-updated' && params.onLineUpdated) {
          params.onLineUpdated(message.lineId)
        } else if (event === 'line-restored' && params.onLineRestored) {
          params.onLineRestored(message.lineId)
        } else if (event === 'take-created' && params.onTakeCreated) {
          params.onTakeCreated(message.takeId)
        } else if (event === 'take-updated' && params.onTakeUpdated) {
          params.onTakeUpdated(message.takeId)
        } else if (event === 'take-deleted' && params.onTakeDeleted) {
          params.onTakeDeleted(message.takeId)
        } else if (event === 'take-restored' && params.onTakeRestored) {
          params.onTakeRestored(message.takeId)
        } else if (event === 'character-created' && params.onCharacterCreated) {
          params.onCharacterCreated(message.characterId)
        } else if (event === 'character-updated' && params.onCharacterUpdated) {
          params.onCharacterUpdated(message.characterId)
        } else if (event === 'character-deleted' && params.onCharacterDeleted) {
          params.onCharacterDeleted(message.characterId)
        } else if (event === 'export-target-updated' && params.onExportTargetUpdated) {
          params.onExportTargetUpdated(message.exportTargetId)
        } else if (event === 'import-source-updated' && params.onImportSourceUpdated) {
          params.onImportSourceUpdated(message.importSourceId)
        } else if (event === 'remote-control-request' && params.onRemoteControlRequest) {
          const { requestId, user } = message
          params.onRemoteControlRequest(requestId, user)
        } else if (event === 'remote-control-response' && params.onRemoteControlResponse) {
          params.onRemoteControlResponse(message.requestId, message.accept)
        } else if (event === 'remote-control-action' && params.onRemoteControlAction) {
          const { requestId, action, ...rest } = message
          params.onRemoteControlAction(requestId, action, rest)
        } else if (event === 'stop-remote-control' && params.onStopRemoteControl) {
          params.onStopRemoteControl(message.requestId)
        } else if (event === 'session-updated' && params.onSessionUpdated) {
          params.onSessionUpdated(message.sessionId)
        }
      }
      return () => evtSource.close()
    }
  }, [enabled, ...Object.values(params)])
}
