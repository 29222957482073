import { h } from 'preact'
import { useRef, useState } from 'preact/hooks'
import { Col } from 'jsxstyle/preact'
import { DropArea } from './DropArea'
import { AccountCircleIcon, Button, P } from '@sodra/bongo-ui'
import { post } from '../../api'

type Props = {
  onImageUploaded: (uri: string) => void
}

export const UploadImage = ({ onImageUploaded }: Props) => {
  const fileInput = useRef<HTMLInputElement>(null)
  const [status, setStatus] = useState('')

  const upload = async (file: File) => {
    setStatus('uploading')
    const {
      data: { signedUrl, uri }
    } = await post('/sign-url', { prefix: 'images-tmp', contentType: file.type })
    await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })
    onImageUploaded(uri)
  }

  const handleDrop = (file: File) => {
    upload(file)
  }

  const handleFileInputChange = (e: Event) => {
    const target = e.target as HTMLInputElement
    if (target.files?.length) {
      upload(target.files[0])
    }
  }

  return (
    <Col alignItems="center" justifyContent="center" height="90%" position="relative">
      <DropArea onDrop={handleDrop} loading={status === 'uploading'}>
        <AccountCircleIcon size={264} fill="var(--on-surface-lighter)" />
        <P textAlign="center">
          Drop photo here
          <br />
          or
        </P>
        <Button onClick={() => fileInput.current?.click()}>Choose file to upload</Button>
        <input
          type="file"
          ref={fileInput}
          accept="image/*"
          onChange={handleFileInputChange}
          style={{ display: 'none' }}
        />
      </DropArea>
    </Col>
  )
}
