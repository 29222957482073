import { goBack, routeTo } from '@sodra/prutt'
import { Block, Row } from 'jsxstyle/preact'
import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import dateFormat from 'dateformat'
import { fetchImportSource } from '../../actions'
import { useStore } from '../../store'
import { useEffectSkipFirst } from '../../use-effect-skip-first'
import { useFetchResult } from '../../use-fetch-result'
import Page from '../Page'
import Spinner from '../Spinner'
import {
  Button,
  DataTable,
  ErrorIcon,
  IconButton,
  InfoCircleIcon,
  OpenInNewIcon,
  RefreshIcon,
  SpacerVertical,
  WarningIcon
} from '@sodra/bongo-ui'

type Props = {
  importSourceId: string
}

type LogLevel = 'info' | 'warn' | 'error'

type LogEntry = {
  timestamp: Date
  syncId: string
  lineId?: string
  takeId?: string
  logLevel: LogLevel
  message: string
}

type LogLevelIconProps = {
  logLevel: LogLevel
}

const LogLevelIcon = (props: LogLevelIconProps) => {
  const { logLevel, ...rest } = props
  if (logLevel === 'error') {
    return <ErrorIcon fill="var(--error)" {...rest} />
  }
  if (logLevel === 'warn') {
    return <WarningIcon fill="var(--warning)" {...rest} />
  }
  return <InfoCircleIcon fill="var(--on-surface)" {...rest} />
}

export const ImportSourceLogs = ({ importSourceId }: Props) => {
  const { currentGame, importSource } = useStore('currentGame', 'importSource')

  const [maxEntries, setMaxEntries] = useState(1000)

  const {
    data: logs = [],
    isFetching,
    refetch
  } = useFetchResult<LogEntry[]>(`/import-sources/${importSourceId}/logs`, {})

  // Handle switch game
  useEffectSkipFirst(() => {
    routeTo('/import-sources', true)
  }, [currentGame!.id])

  useEffect(() => {
    fetchImportSource(importSourceId)
  }, [importSourceId])

  if (!importSource || importSource.id !== importSourceId) {
    return <Spinner />
  }

  const header = [
    { label: '', style: { whiteSpace: 'nowrap' } },
    { label: 'Timestamp', style: { whiteSpace: 'nowrap' } },
    { label: 'Sync ID', style: { whiteSpace: 'nowrap' } },
    { label: 'Summary', style: { whiteSpace: 'nowrap' } }
  ]

  const body = logs.slice(0, maxEntries).map((log) => {
    return [
      <LogLevelIcon logLevel={log.logLevel} />,
      <Block>{dateFormat(log.timestamp, 'yyyy-mm-dd HH:MM:ss')}</Block>,
      <Block>{log.syncId}</Block>,
      <Block>{log.message}</Block>
    ]
  })

  return (
    <Page
      title={`${importSource.name} · Logs`}
      onBack={() => goBack(`/import-sources/${importSourceId})`)}
      actions={[{ label: 'Update', icon: RefreshIcon, onClick: refetch, loading: isFetching }]}
    >
      <Block width="max-content">
        <DataTable header={header} body={body} />
        {maxEntries < logs.length && (
          <>
            <SpacerVertical small />
            <Row alignItems="center" justifyContent="center">
              <Button onClick={() => setMaxEntries(maxEntries + 1000)}>Show more entries</Button>
            </Row>
          </>
        )}
      </Block>
    </Page>
  )
}
