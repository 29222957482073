import { batch, computed, ReadonlySignal, Signal, signal } from '@preact/signals'
import { useEffect, useState } from 'preact/hooks'
import { trimAudio } from './trimAudio'

type RecorderState = ReadonlySignal<{
  selectedDevice: string | null
  selectedDeviceLabel: string | null
  mediaStream: MediaStream | null
  mediaRecorderState: RecordingState | null
}>

export type Recorder = {
  state: RecorderState
  setSelectedDevice: (deviceId: string) => Promise<void>
  startRecording: () => boolean
  stopRecording: (handleAudioBuffer?: ((audioBuffer?: AudioBuffer) => void) | undefined) => boolean
}

export function createRecorder(): Recorder {
  const selectedDevice = signal<string | null>(null)
  const selectedDeviceLabel = signal<string | null>(null)
  const mediaStream = signal<MediaStream | null>(null)
  const mediaRecorder = signal<MediaRecorder | null>(null)
  const mediaRecorderState = signal<RecordingState | null>(null)
  const audioContext = signal<AudioContext | null>(null)
  const chunks = signal<Blob[]>([])

  const state = computed(() => {
    return {
      selectedDevice: selectedDevice.value,
      selectedDeviceLabel: selectedDeviceLabel.value,
      mediaStream: mediaStream.value,
      mediaRecorderState: mediaRecorderState.value
    }
  })

  async function setSelectedDevice(deviceId: string) {
    if (mediaStream.value) {
      stopTracks(mediaStream.value)
    }
    const stream = await getMediaStream(deviceId)
    const _mediaRecorder = createMediaRecorder(
      stream,
      (blob) => chunks.value.push(blob),
      (state) => (mediaRecorderState.value = state)
    )

    batch(() => {
      mediaRecorder.value = _mediaRecorder
      mediaStream.value = stream
      selectedDevice.value = deviceId
      audioContext.value = new AudioContext()
    })
    const options = await getAudioDeviceOptions()
    selectedDeviceLabel.value =
      options.find((option) => option.value === selectedDevice.value)?.text ?? null
  }

  function startRecording() {
    if (mediaRecorder.value) {
      if (mediaRecorderState.value === 'inactive') {
        mediaRecorder.value.start()
        return true
      }
    }
    return false
  }

  function stopRecording(handleAudioBuffer?: (audioBuffer?: AudioBuffer) => void) {
    if (mediaRecorder.value) {
      if (mediaRecorderState.value === 'recording') {
        mediaRecorder.value.stop()

        async function handleStop() {
          if (handleAudioBuffer) {
            if (audioContext.value) {
              const audioBuffer = await chunksToAudioBuffer(chunks.value, audioContext.value)
              chunks.value = []
              if (audioBuffer) {
                handleAudioBuffer(audioBuffer)
              }
            }
          }

          mediaRecorder.value?.removeEventListener('stop', handleStop)
        }

        mediaRecorder.value.addEventListener('stop', handleStop)

        return true
      }
    }

    return false
  }

  return {
    state,
    setSelectedDevice,
    startRecording,
    stopRecording
  }
}

export function useRecorder() {
  const [recorder, setRecorder] = useState<Recorder | null>(null)

  useEffect(() => {
    setRecorder(createRecorder())
    return () => {
      if (recorder) {
        if (recorder.state.value.mediaStream) {
          stopTracks(recorder.state.value.mediaStream)
          recorder.state.value.mediaStream = null
        }
      }
    }
  }, [])

  return recorder
}

async function chunksToAudioBuffer(chunks: Blob[], audioContext: AudioContext) {
  let blob: Blob | undefined = undefined
  let arrayBuffer: ArrayBuffer | undefined = undefined
  let audioBuffer: AudioBuffer | undefined = undefined
  try {
    blob = new Blob(chunks)
    arrayBuffer = await blob.arrayBuffer()
    audioBuffer = await audioContext.decodeAudioData(arrayBuffer)

    // Remove first part of audio to get rid of keyboard sounds
    const autoTrimTime = 0.15
    if (audioBuffer.duration <= autoTrimTime) {
      return undefined
    }
    const trimmed = await trimAudio(audioBuffer, autoTrimTime, audioBuffer.duration)

    return trimmed
  } catch (e) {
    console.error(e, {
      chunks,
      audioContext,
      blob,
      arrayBuffer,
      audioBuffer
    })
  }
}

function createMediaRecorder(
  stream: MediaStream,
  onDataAvailable: (blob: Blob) => void,
  onUpdateState: (recordingState: RecordingState) => void
) {
  const mediaRecorder = new MediaRecorder(stream)
  // NOTE: Daniel testing
  // const mediaRecorder = new MediaRecorder(stream, {
  //   mimeType: 'audio/webm;codecs=opus'
  // })

  function dataAvailable(e: BlobEvent) {
    if (e.data.size > 0) {
      onDataAvailable(e.data)
    }
  }

  function updateState() {
    onUpdateState(mediaRecorder.state)
  }

  updateState()
  mediaRecorder.addEventListener('start', updateState)
  mediaRecorder.addEventListener('stop', updateState)
  mediaRecorder.addEventListener('pause', updateState)
  mediaRecorder.addEventListener('resume', updateState)

  mediaRecorder.addEventListener('dataavailable', dataAvailable)

  return mediaRecorder
}

async function getMediaStream(deviceId: string) {
  const mediaStream = await navigator.mediaDevices.getUserMedia({
    audio: {
      deviceId,
      channelCount: 1,
      autoGainControl: false,
      echoCancellation: false,
      noiseSuppression: false,
      sampleSize: 24 // NOTE: Seems to not be respected
    },
    video: false
  })

  // DEBUG: List track capabilities
  for (let track of mediaStream.getAudioTracks()) {
    // console.log('Audio track capabilities', track.getCapabilities())
    //console.log(track.getSettings())
  }

  return mediaStream
}

export function stopTracks(stream: MediaStream) {
  stream.getTracks().forEach((track) => track.stop())
}

export type DeviceOption = {
  text: string
  value: string
}

export async function getAudioDeviceOptions(): Promise<DeviceOption[]> {
  const devices = await navigator.mediaDevices.enumerateDevices()

  const options = devices
    .filter((device) => device.kind === 'audioinput')
    .map((device): DeviceOption => {
      return {
        text: device.label,
        value: device.deviceId
      }
    })

  return options
}
