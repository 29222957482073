import { Block } from 'jsxstyle/preact'
import { Session } from './types'

export const formatSessionStatus = (session: Session) => {
  if (session.voiceAccepted) {
    return <Block color="var(--ok)">Accepted</Block>
  }
  if (session.voiceDeclined) {
    return <Block color="var(--error)">Declined</Block>
  }
  return <Block color="var(--warning)">Awaiting acceptance</Block>
}
