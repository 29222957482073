import { formatCurrencyNoDecimals } from 'lib'
import { Order } from '../../types'

type Rate = {
  id: string
  label: string
  rate: number
  buyout: number
}

type RatesByID = { [id: string]: Rate }

type CalcSessionOrderDetailsParams = {
  budgetSegment: string
  duration?: number
  includeVAT: boolean
  config: {
    rates: Rate[]
    ratesById: RatesByID
    platformFee: number
    platformFeeMultiplier: number
  }
}

export const calcSessionOrderDetails = ({
  budgetSegment,
  duration,
  includeVAT,
  config
}: CalcSessionOrderDetailsParams) => {
  const rate = config!.ratesById[budgetSegment].rate
  const segmentLabel = config!.ratesById[budgetSegment].label

  let voiceActorFee = 0
  let description = '–'
  if (rate && duration) {
    voiceActorFee = rate * duration
    description = `${duration} hours × ${formatCurrencyNoDecimals(rate)} (${segmentLabel})`
  }

  let platformCost = duration ? rate * duration * config!.platformFee : 0

  const total = voiceActorFee + platformCost

  const vatPercent = includeVAT ? 0.25 : 0
  const vat = total * vatPercent

  const totalIncVAT = total + vat

  return {
    description,
    items: [
      { label: 'Session hours', cost: voiceActorFee },
      { label: 'Platform fee (25%)', cost: platformCost }
    ],
    total,
    includeVAT,
    vat,
    vatPercent,
    totalIncVAT
  }
}

type CalcBuyoutOrderDetailsParams = {
  budgetSegment: string
  includeVAT: boolean
  config: {
    rates: Rate[]
    ratesById: RatesByID
    platformFee: number
    platformFeeMultiplier: number
  }
}

export const calcBuyoutOrderDetails = ({
  budgetSegment,
  includeVAT,
  config
}: CalcBuyoutOrderDetailsParams) => {
  let buyoutCost = config!.ratesById[budgetSegment].buyout

  let platformCost = buyoutCost * config!.platformFee

  let total = buyoutCost + platformCost

  const vatPercent = includeVAT ? 0.25 : 0

  let vat = total * vatPercent

  let totalIncVAT = total + vat

  return {
    description:
      'Full title buy-out of rights. Paid only once per voice actor per game title and includes setup.',
    items: [
      { label: 'Buyout', cost: buyoutCost },
      { label: 'Platform fee (25%)', cost: platformCost }
    ],
    total,
    includeVAT,
    vat,
    totalIncVAT,
    vatPercent
  }
}

export const getOrderDetails = (order: Order) => {
  // If the order has been finalized, use the orders billing account
  // Otherwise use the games billing account (can be updated)
  const billingAccount = order.billingAccount ? order.billingAccount : order.game?.billingAccount

  // VAT
  const hasVAT = billingAccount?.address.country === 'Sweden'
  const vatPercent = hasVAT ? 0.25 : 0

  const items = order.items.map((item) => ({
    label: item.description,
    cost: item.quantity * item.unitPrice
  }))
  const total = items.map((item) => item.cost).reduce((prev, current) => prev + current, 0)
  const vat = total * vatPercent
  const totalIncVAT = total + vat

  return {
    description: order.description,
    items,
    total,
    vat,
    totalIncVAT: hasVAT ? totalIncVAT : total,
    vatPercent: hasVAT ? vatPercent : undefined
  }
}
