import { h } from 'preact'

import { goBack, Route, routeTo } from '@sodra/prutt'

import { useStore } from '../../../store'
import Page from '../../Page'
import { SuperForm } from '../../SuperForm'
import {
  addBillingAccountUser,
  fetchBillingAccount,
  removeBillingAccountUser
} from '../../../actions/billing-accounts'
import { showSnackbar } from '../../../actions'
import Spinner from '../../Spinner'
import { useEffect } from 'preact/hooks'
import { AddIcon, DeleteIcon, Dialog, List, ListItem } from '@sodra/bongo-ui'
import Avatar from '../../Avatar'
import { User } from '../../../types'
import { confirm } from 'lib'

type Props = {
  accountId: string
}

export const ManageBillingAccountAccess = ({ accountId }: Props) => {
  const { currentGame, currentUser, billingAccount, isUpdatingBillingAccount } = useStore(
    'currentGame',
    'currentUser',
    'billingAccount',
    'isUpdatingBillingAccount'
  )

  useEffect(() => {
    fetchBillingAccount(accountId)
  }, [accountId])

  if (!billingAccount) {
    return <Spinner />
  }

  const handleAddUser = async (values: any) => {
    const email = values['email']
    if (await addBillingAccountUser(accountId, email)) {
      showSnackbar('User added to billing account')
      closeDialog()
    }
  }

  const handleRemoveUser = async (user: User) => {
    if (await confirm(`Remove user ${user.name} from billing account?`)) {
      if (await removeBillingAccountUser(accountId, user.id)) {
        showSnackbar(`User ${user.name} removed from billing account`)
      }
    }
  }

  const onClose = () => goBack(`/settings/billing/${billingAccount.id}`)

  const closeDialog = () => goBack(`/settings/billing/${billingAccount.id}/manage-access`)

  return (
    <Page
      title={`Access · ${billingAccount.name}`}
      onBack={onClose}
      actions={[
        {
          label: 'Add user',
          icon: AddIcon,
          link: {
            href: `/settings/billing/${billingAccount.id}/manage-access/add-user`,
            onRoute: routeTo
          }
        }
      ]}
    >
      <List maxWidth="500px">
        {billingAccount.users?.map((user) => {
          return (
            <ListItem
              visual={<Avatar size={30} src={user.picture} name={user.name} />}
              text={user.name}
              actionIcon={DeleteIcon}
              actionDisabled={user.id === currentUser?.id}
              onActionClick={() => handleRemoveUser(user)}
            />
          )
        })}
      </List>
      <Route
        path="/settings/billing/:accountId/manage-access/add-user"
        render={() => {
          return (
            <Dialog title="Add user" onClose={closeDialog}>
              <SuperForm
                onSubmit={handleAddUser}
                onCancel={closeDialog}
                loading={isUpdatingBillingAccount}
                elements={[{ element: 'TextField', type: 'email', name: 'email', label: 'Email' }]}
              />
            </Dialog>
          )
        }}
      />
    </Page>
  )
}
