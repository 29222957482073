import { AudioPlayer } from 'lib/src/hooks/use-audio-player'

type SpeakParams = {
  aiVoiceId?: string
  type?: string
  player: AudioPlayer
  text: string
  extraParams: object
}

export const speak = ({ aiVoiceId, type, player, text, extraParams }: SpeakParams) => {
  const uri = buildSpeakerUri({ aiVoiceId, type, text, extraParams })

  const { initPlayer, play } = player

  initPlayer({
    audioTracks: [{ name: text, uri }]
  })
  play()
}

export const buildSpeakerUri = ({
  text,
  aiVoiceId,
  type,
  extraParams
}: {
  text: string
  aiVoiceId?: string
  type?: string
  extraParams?: object
}) => {
  const params = new URLSearchParams()
  params.set('text', text)
  if (type) {
    params.set('type', type)
  } else if (aiVoiceId) {
    params.set('aiVoiceId', aiVoiceId)
  }
  if (extraParams) {
    params.set('extraParams', JSON.stringify(extraParams))
  }
  return import.meta.env.VITE_API_URL + `/v1/ai-voices/speak?` + params
}
