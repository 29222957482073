export const urlSearchParams = () => {
  const params = new URLSearchParams(location.search)

  return {
    set: (key: string, value: string) => params.set(key, value),
    get: (key: string, defaultValue?: string) => {
      const value = params.get(key)
      if (value !== null) {
        return value
      }
      return defaultValue
    },
    getInt: (key: string, defaultValue: number) => {
      const value = params.get(key)
      if (value !== null) {
        return parseInt(value, 10)
      }
      return defaultValue
    },
    getFloat: (key: string, defaultValue: number) => {
      const value = params.get(key)
      if (value !== null) {
        return parseFloat(value)
      }
      return defaultValue
    }
  }
}
