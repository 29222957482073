import { useState } from 'preact/hooks'
import { useStore } from '../../store'
import { Dialog, TextArea } from '@sodra/bongo-ui'
import { updateAPIKey } from '../../actions'

type Props = {
  apiKeyId: string
  onClose: () => void
}

export const EditApiKey = ({ apiKeyId, onClose }: Props) => {
  const { apiKeys = [], isUpdatingAPIKey } = useStore('apiKeys', 'isUpdatingAPIKey')

  const apiKey = apiKeys.find((apiKey) => apiKey.id === apiKeyId)

  const [comment, setComment] = useState(apiKey?.comment)

  const onSave = async () => {
    if (await updateAPIKey(apiKeyId, { comment })) {
      onClose()
    }
  }

  return (
    <Dialog
      title="Edit comment"
      onClose={onClose}
      actions={[
        { text: 'Save', onClick: onSave, loading: isUpdatingAPIKey },
        { text: 'Cancel', onClick: onClose }
      ]}
    >
      <TextArea
        width="100%"
        rows="3"
        label="Comment"
        placeholder="E.g what will this API key be used for?"
        value={comment}
        onInput={setComment}
      />
    </Dialog>
  )
}
