import { h } from 'preact'
import { useState } from 'preact/hooks'
import { AddIcon, Dialog, List, ListItem, SpacerVertical } from '@sodra/bongo-ui'

import { useFetchResult } from '../../use-fetch-result'

import Avatar from '../Avatar'

import SearchTextField from '../SearchTextField'
import CreateScene from './CreateScene'
import { useStore } from '../../store'
import { Scene } from '../../types'
import { Block } from 'jsxstyle/preact'

type Props = {
  onClose: () => void
  onSelect: (scene?: Scene) => void
  canCreate?: boolean
}

export const SelectScene = ({ onClose, onSelect, canCreate = false }: Props) => {
  const { currentGame, isNarrow } = useStore('currentGame', 'isNarrow')
  const [query, setQuery] = useState('')
  const [createSceneVisible, setCreateSceneVisible] = useState(false)

  const { data: scenes } = useFetchResult<Scene[]>(`/games/${currentGame!.id}/scenes`, {
    query
  })

  return (
    <Dialog
      large
      full={isNarrow}
      title="Select scene"
      onClose={onClose}
      actions={[{ text: 'Cancel', onClick: onClose }]}
      secondaryActions={
        canCreate
          ? [{ text: 'New scene', icon: AddIcon, onClick: () => setCreateSceneVisible(true) }]
          : undefined
      }
    >
      <SearchTextField value={query} onChange={setQuery} />
      <SpacerVertical />
      {scenes && (
        <List>
          <ListItem
            visual={<Avatar size={30} name="×" />}
            text={
              <Block color="var(--on-surface-light)">
                <em>No scene</em>
              </Block>
            }
            onClick={() => {
              onSelect(undefined)
              onClose()
            }}
          />
          {scenes.map((scene) => {
            return (
              <ListItem
                visual={<Avatar size={30} src={scene.picture} name={scene.name} />}
                text={scene.name}
                onClick={() => {
                  onSelect(scene)
                  onClose()
                }}
              />
            )
          })}
        </List>
      )}
      <SpacerVertical />
      {createSceneVisible && (
        <CreateScene
          onClose={() => setCreateSceneVisible(false)}
          onCreate={(scene) => {
            onSelect(scene)
            onClose()
          }}
        />
      )}
    </Dialog>
  )
}

export default SelectScene
