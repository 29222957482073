import { useEffect, useState } from 'preact/hooks'
import { get } from './api'
import { Character } from './types'

export const useCharacter = (initialCharacterId?: string) => {
  const [characterId, setCharacterId] = useState(initialCharacterId)
  const [character, setCharacter] = useState<Character | undefined>()

  useEffect(() => {
    if (characterId && (!character || character.id !== characterId)) {
      get(`/characters/${characterId}`).then(({ data: character }) => setCharacter(character))
    }
    if (!characterId) {
      setCharacter(undefined)
    }
  }, [characterId])

  return {
    characterId,
    setCharacterId,
    character,
    setCharacter: (character?: Character) => {
      setCharacter(character)
      if (character) {
        setCharacterId(character.id)
      } else {
        setCharacterId(undefined)
      }
    },
    clearCharacter: () => {
      setCharacterId(undefined)
      setCharacter(undefined)
    }
  }
}
