import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { SelectButton, SpacerVertical } from '@sodra/bongo-ui'
import { fetchTimeZones } from '../time-zone/fetch-time-zones'
import { Block } from 'jsxstyle/preact'
import { ErrorMessage } from './ErrorMessage'
import { formatTimeZone } from '../time-zone/format-time-zone'

type Props = {
  label?: string
  timeZone?: string
  onClick: () => void
  errorText?: string
  props?: any
}

export const TimeZoneSelectButton = ({
  label = 'Time zone',
  timeZone,
  onClick,
  errorText,
  props
}: Props) => {
  const [timeZoneName, setTimeZoneName] = useState<string>()

  useEffect(() => {
    if (timeZone) {
      fetchTimeZones(timeZone).then((timeZones) => {
        if (timeZones.length === 1) {
          const tz = timeZones[0]
          setTimeZoneName(`${formatTimeZone(tz)} (GMT${tz.offset})`)
        }
      })
    }
  }, [timeZone])

  return (
    <>
      <SelectButton
        label={label}
        selected={
          timeZone
            ? {
                text: timeZoneName || timeZone
              }
            : undefined
        }
        value={timeZone}
        onClick={onClick}
        width="100%"
        props={props}
      />
      {errorText && (
        <Block marginLeft="15px">
          <SpacerVertical small />
          <ErrorMessage>{errorText}</ErrorMessage>
        </Block>
      )}
    </>
  )
}
