import { h } from 'preact'
import { Checkbox, Dialog, Form, SpacerVertical, TextArea, TextField } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { useState } from 'preact/hooks'
import { showSnackbar, updateLineAttribute } from '../../../actions'
import { useStore } from '../../../store'

type Props = {
  attributeId: string
}

export const UpdateLineAttribute = ({ attributeId }: Props) => {
  const { lineAttributes } = useStore('lineAttributes')

  const lineAttribute = lineAttributes?.find((attribute) => attribute.id === attributeId)

  const onBack = () => goBack('/lines/line-attributes')

  if (!lineAttribute) {
    onBack()
    return
  }

  const [name, setName] = useState(lineAttribute.name)
  const [nameError, setNameError] = useState('')
  const [description, setDescription] = useState(lineAttribute.description)
  const [required, setRequired] = useState(lineAttribute.required)

  const handleSubmit = async () => {
    if (name?.trim() === '') {
      setNameError('Please enter a name')
      return
    }

    if (await updateLineAttribute(attributeId, { name, description, required })) {
      showSnackbar(`Line attribute ${name} updated`)
      onBack()
    }
  }

  return (
    <Dialog
      title="Edit line attribute"
      onClose={onBack}
      actions={[
        {
          text: 'Save',
          contained: true,
          onClick: handleSubmit
        },
        {
          text: 'Cancel',
          onClick: onBack
        }
      ]}
    >
      <Form onSubmit={handleSubmit}>
        <TextField
          width="100%"
          autoComplete="off"
          label="Name"
          value={name}
          onInput={setName}
          errorText={nameError}
        />
        <SpacerVertical />
        <TextArea
          width="100%"
          autoComplete="off"
          label="Description"
          value={description}
          onInput={setDescription}
          autoHeight
          minRows={3}
          maxRows={10}
        />
        <SpacerVertical />
        <Checkbox label="Required" checked={required} onChange={setRequired} />
      </Form>
    </Dialog>
  )
}
