import { useSignal } from '@preact/signals'
import { Button, Select, VoiceIcon } from '@sodra/bongo-ui'
import { useEffect } from 'preact/hooks'
import { useLocalStorageState } from '../../lib/use-local-storage-state'
import { recorder } from '../LineRecorder'
import { DeviceOption, getAudioDeviceOptions } from './recorder'
import { usePermissionState } from './usePermissionState'

type Props = {
  onError?: (e: Error) => void
}

export function AudioDeviceSelector({ onError }: Props) {
  const [deviceId, setDeviceId] = useLocalStorageState<string | null>(`speechless:deviceId`, null)

  useEffect(() => {
    if (!recorder) return

    setDeviceId(recorder.value?.state.value.selectedDevice ?? null)
  }, [recorder.value?.state.value.selectedDevice])

  const options = useSignal<DeviceOption[]>([])

  const { permissionState } = usePermissionState('microphone')

  async function updateOptions() {
    const _options = await getAudioDeviceOptions()
    options.value = _options
  }

  const mySetSelectedDevice = (deviceId: string) => {
    setDeviceId(deviceId)
    recorder.value?.setSelectedDevice(deviceId)
  }

  useEffect(() => {
    async function setSelectedDevice() {
      await updateOptions()
      const optionsMissingLabel = options.value.some((option) => option.text === '')
      if (permissionState.value === 'granted' || !optionsMissingLabel) {
        if (deviceId !== null && options.value.some((value) => value.value === deviceId)) {
          mySetSelectedDevice(deviceId)
        } else if (options.value.length > 0) {
          mySetSelectedDevice(options.value[0].value)
        }
      }
    }
    setSelectedDevice()
  }, [])

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', updateOptions)
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', updateOptions)
    }
  }, [])

  const grantAccess = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: deviceId
          ? {
              deviceId,
              channelCount: 1,
              autoGainControl: false,
              noiseSuppression: false,
              echoCancellation: false
            }
          : true,
        video: false
      })
      .then(async (res) => {
        await updateOptions()
        const tracks = res.getTracks()
        const track = tracks[0]
        if (track && track.getCapabilities) {
          const { deviceId } = track.getCapabilities()
          if (deviceId) {
            mySetSelectedDevice(deviceId)
          }
        }
        // Firefox does not support MediaStreamTrack.getCapabilities()
        else {
          const option = options.value.find((o) => o.text === track.label)
          if (option) {
            mySetSelectedDevice(option.value)
          }
        }
        tracks.forEach((track) => {
          track.stop()
        })
      })
      .catch((err) => {
        console.error(err)
        if (onError) {
          onError(new Error('Unable to get access to microphone'))
        }
      })
  }

  const optionsMissingLabel = options.value.some((option) => option.text === '')

  if (permissionState.value === 'prompt' || optionsMissingLabel) {
    return (
      <>
        <Button onClick={grantAccess} contained>
          Allow microphone access to start recording
        </Button>
      </>
    )
  }

  return (
    <Select
      text
      icon={VoiceIcon}
      options={options.value}
      value={deviceId}
      onChange={mySetSelectedDevice}
      placeholder={{ text: 'Select audio input' }}
      zIndex="3"
    />
  )
}
