import { signal } from '@preact/signals'
import { Dialog } from '@sodra/bongo-ui'

export type ConfirmMessage = {
  title?: string
  message: any
  confirmText?: string | null
  confirmDisabled?: boolean
  rejectText?: string | null
}

const confirmMessage = signal<ConfirmMessage | undefined>(undefined)

let resolveConfirm: (value: boolean | PromiseLike<boolean>) => void

export const confirm = (message: string | ConfirmMessage) => {
  if (typeof message === 'string') {
    confirmMessage.value = { message }
  } else {
    confirmMessage.value = message
  }
  const promise = new Promise<boolean>((resolve) => {
    resolveConfirm = resolve
  })
  return promise
}

export const Confirm = () => {
  if (!confirmMessage.value) {
    return null
  }

  const close = () => (confirmMessage.value = undefined)

  const confirm = () => {
    resolveConfirm(true)
    close()
  }

  const reject = () => {
    resolveConfirm(false)
    close()
  }

  if (!confirmMessage.value) {
    return null
  }

  const actions = []

  if (confirmMessage.value.confirmText !== null) {
    actions.push({
      text: confirmMessage.value.confirmText || 'Ok',
      contained: true,
      disabled: confirmMessage.value.confirmDisabled || false,
      onClick: confirm
    })
  }
  if (confirmMessage.value.rejectText !== null) {
    actions.push({ text: confirmMessage.value.rejectText || 'Cancel', onClick: reject })
  }

  return (
    <Dialog
      disableAutofocus
      title={confirmMessage.value.title || 'Please confirm'}
      onClose={reject}
      actions={actions}
    >
      {confirmMessage.value.message}
    </Dialog>
  )
}
