import { Fragment, h } from 'preact'
import {
  Button,
  Dialog,
  DownIcon,
  List,
  ListItem,
  SpacerHorizontal,
  SpacerVertical
} from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'
import { useState } from 'preact/hooks'

import Avatar from './Avatar'
import { store, useStore } from '../store'
import { formatLanguage } from '../format-language'

export const LanguageSelect = () => {
  const { currentGame, currentLanguage } = useStore('currentGame', 'currentLanguage')

  const [showDialog, setShowDialog] = useState(false)

  const onClose = () => setShowDialog(false)

  if (!currentGame || !currentLanguage) {
    return null
  }

  const languageCodes = [currentGame.primaryLanguage, ...(currentGame.secondaryLanguages || [])]

  return (
    <Fragment>
      <Button name="language-select" onClick={() => setShowDialog(true)}>
        <Row alignItems="center">
          <Block color="var(--on-surface)">{formatLanguage(currentLanguage)}</Block>
          <SpacerHorizontal tiny />
          <DownIcon fill="var(--on-surface)" />
        </Row>
      </Button>
      {showDialog && (
        <Dialog
          title="Select language"
          action1Text="Close"
          onAction1Click={onClose}
          onClose={onClose}
        >
          <List>
            {languageCodes.map((code: string) => {
              return (
                <ListItem
                  visual={<Avatar size={40} name={code.toUpperCase().split('').join(' ')} />}
                  text={formatLanguage(code)}
                  metaText={code === currentGame?.primaryLanguage ? 'Primary' : ''}
                  onClick={() => {
                    store.setState({ currentLanguage: code })
                    setShowDialog(false)
                  }}
                />
              )
            })}
          </List>
          <SpacerVertical />
        </Dialog>
      )}
    </Fragment>
  )
}
