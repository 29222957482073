import { Fragment, h } from 'preact'
import { Block, Grid } from 'jsxstyle/preact'
import { ChatBubbleIcon, Dialog, H2, Link, P, SpacerVertical } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import Page from '../Page'
import ShortlistVoice from '../ShortlistVoice'
import { createChat, fetchChats, fetchVoice, updateGame } from '../../actions'

import { Characters } from './Characters'
import { VoiceDemoList } from './VoiceDemoList'
import { Equipment } from './Equipment'
import { StudioQualityDemo } from './StudioQualityDemo'
import { Credentials } from './Credentials'
import { Languages } from './Languages'
import { Title } from './Title'
import { formatVoiceName } from '../../format-voice-name'
import { useStore } from '../../store'
import { ExampleScenes } from './ExampleScenes'
import { PageActionType } from '../PageAction'

import { Sessions } from './Sessions'
import { useEffect, useState } from 'preact/hooks'
import { confirmBillingAccount } from '../confirmBillingAccount'
import { TimeZone } from './TimeZone'
import AuditionDetails, { AuditionDetailsSubmitParams } from '../AuditionDetails'
import { useAudioPlayer } from 'lib/src/hooks/use-audio-player'
import { ShowreelList } from './ShowreelList'
import { confirmCreditCard } from '../confirmCreditCard'
import { fetchBillingAccounts } from 'src/actions/billing-accounts'
import SelectBillingAccount from '../SelectBillingAccount'
import { confirmSelectBillingAccount } from '../confirmSelectBillingAccount'

export const VoiceDetails = () => {
  const { currentGame, voice, config, isCreatingChat, billingAccounts } = useStore(
    'currentGame',
    'voice',
    'config',
    'isCreatingChat',
    'billingAccounts'
  )

  const [showSelectBillingAccountDialog, setShowSelectBillingAccountDialog] = useState(false)

  useEffect(() => {
    fetchBillingAccounts()
  }, [])

  const { player } = useAudioPlayer({ useAsMainPlayer: true })
  if (!player) {
    return null
  }

  const initiateChat = async (
    auditionDetails?: AuditionDetailsSubmitParams,
    shareCharacterSheet?: boolean
  ) => {
    const chat = await createChat(voice!.id, auditionDetails, shareCharacterSheet)
    if (chat) {
      await fetchChats()
      routeTo(`/chats/${chat.id}`)
    }
  }

  const createBillingAccount = () => {
    // NOTE: No credit card needs to be added
    const params = new URLSearchParams({
      skipCreditCard: 'true',
      onSuccessUrl: `${location.pathname}?initiateChat=true`
    })
    routeTo(`/settings/billing/add-billing-account?${params.toString()}`)
  }

  const handleOpenChat = async () => {
    if (voice!.chat) {
      // Voice has chat
      return routeTo(`/chats/${voice!.chat?.id}`)
    }

    const voiceIsShortlisted = voice!.characters?.length > 0

    const billingAccount = currentGame?.billingAccount
    if (!billingAccount) {
      if (
        await confirmBillingAccount({
          message: 'To open a chat your game needs a billing account.'
        })
      ) {
        if (billingAccounts?.length) {
          setShowSelectBillingAccountDialog(true)
        } else {
          createBillingAccount()
        }
      }
      return
    }

    if (voiceIsShortlisted) {
      return routeTo(`/voice/${voice!.id}/audition-details`)
    } else if (!voiceIsShortlisted) {
      return routeTo(`/voice/${voice!.id}/shortlist-and-chat`)
    }
  }

  const params = new URLSearchParams(location.search)
  const doInitiateChat = params.get('initiateChat') === 'true'
  useEffect(() => {
    if (doInitiateChat) {
      params.delete('initiateChat')
      routeTo(location.pathname + '?' + params.toString(), true)
      handleOpenChat()
    }
  }, [doInitiateChat])

  const actions: PageActionType[] = []
  actions.push({
    label: 'Shortlist',
    onClick: () => routeTo(`/voice/${voice!.id}/shortlist`),
    type: 'outlined'
  })
  actions.push({
    label: voice?.chat?.id ? 'Open chat' : 'Initiate chat',
    icon: ChatBubbleIcon,
    loading: isCreatingChat !== undefined && isCreatingChat === voice?.chat?.id,
    onClick: handleOpenChat
  })

  return (
    <Page title={formatVoiceName(voice!.name)} onBack={() => goBack('/voices')} actions={actions}>
      <Grid
        maxWidth="1400px"
        height="calc(100% + 40px)"
        margin="-20px"
        gridTemplateColumns="auto minmax(300px, 500px)"
      >
        <Block padding="40px">
          <Title voice={voice} />
          <SpacerVertical />
          <VoiceDemoList voice={voice!} player={player} />

          <SpacerVertical large />
          <H2 size="7">Example scenes</H2>
          <SpacerVertical />
          <ExampleScenes voice={voice!} player={player} />

          <SpacerVertical large />
          {voice?.showreels && voice?.showreels.length > 0 && (
            <Fragment>
              <H2 size="7">Showreels</H2>
              <SpacerVertical />
              <ShowreelList voice={voice!} player={player} />
            </Fragment>
          )}
        </Block>

        <Block padding="40px" borderLeft="1px solid var(--container-outline)">
          <Sessions voiceId={voice!.id} />
          <SpacerVertical />

          <Characters voice={voice} />
          <SpacerVertical />

          <TimeZone voice={voice} />
          <SpacerVertical />

          <Languages voice={voice} />
          <SpacerVertical />

          <Block fontSize="14px" marginBottom="1rem" color="var(--on-surface-light)">
            Microphones
          </Block>
          <P margin="1rem 0">{voice!.microphones?.join(' · ') || ' — '}</P>

          <SpacerVertical />
          <Equipment voice={voice} />

          <SpacerVertical />
          <StudioQualityDemo voice={voice!} player={player} />

          <SpacerVertical />
          <Credentials voice={voice} />

          <SpacerVertical large />
        </Block>
      </Grid>

      {voice && (
        <>
          <Route
            path="/voice/:voiceId/shortlist"
            render={() => {
              return (
                <ShortlistVoice
                  voice={voice}
                  onClose={() => goBack(`/voice/${voice.id}`)}
                  onSuccess={() => fetchVoice(voice.id)}
                />
              )
            }}
          />
          <Route
            path="/voice/:voiceId/shortlist-and-chat"
            render={() => {
              return (
                <ShortlistVoice
                  title={`Chat with ${voice.name} about…`}
                  voice={voice}
                  onClose={() => goBack(`/voice/${voice.id}`)}
                  onSuccess={async () => {
                    await fetchVoice(voice.id)
                    routeTo(`/voice/${voice!.id}/audition-details`)
                  }}
                />
              )
            }}
          />
          <Route
            path="/voice/:voiceId/audition-details"
            render={() => {
              return (
                <AuditionDetails
                  onClose={() => goBack(`/voice/${voice.id}`)}
                  onSubmit={(params: AuditionDetailsSubmitParams, shareCharacterSheet: boolean) =>
                    initiateChat(params, shareCharacterSheet)
                  }
                  onSkip={() => initiateChat()}
                />
              )
            }}
          />
        </>
      )}

      {showSelectBillingAccountDialog && billingAccounts?.length && (
        <SelectBillingAccount
          billingAccounts={billingAccounts}
          onCreateBillingAccount={createBillingAccount}
          onClose={() => setShowSelectBillingAccountDialog(false)}
          onSelect={async (billingAccount) => {
            if (await confirmSelectBillingAccount({ billingAccount })) {
              updateGame({ billingAccountId: billingAccount.id })
              return true
            }
            return false
          }}
        />
      )}
    </Page>
  )
}

export default VoiceDetails
