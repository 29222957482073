import { store } from '../store'
import { get, post, patch, put, del } from '../api'

export const fetchSessions = async (params) => {
  let cancelled = false
  store.setState({ isFetchingSessions: true })
  const gameId = store.getState().currentGame.id
  get(`/games/${gameId}/sessions`, params)
    .then(({ data: sessions, meta: { total } }) => {
      if (!cancelled) {
        store.setState({ sessions, totalSessions: total, isFetchingSessions: false })
      }
    })
    .catch((error) => {
      if (!cancelled) {
        store.setState({ error, isFetchingSessions: false })
      }
    })
  return () => {
    store.setState({ isFetchingSessions: false })
    cancelled = true
  }
}

export const fetchSession = async (sessionId) => {
  try {
    store.setState({ isFetchingSession: true })
    const { data: session } = await get(`/sessions/${sessionId}`)
    store.setState({ session, isFetchingSession: false })
    return session
  } catch (error) {
    store.setState({ error, isFetchingSession: false })
  }
}

export const createSession = async (params) => {
  try {
    store.setState({ isCreatingSession: true })
    const gameId = store.getState().currentGame.id
    const {
      meta: { chatId, chatWasCreated },
      data: session
    } = await post(`/games/${gameId}/sessions`, params)
    store.setState({ session, isCreatingSession: false })
    return { chatId, chatWasCreated, session }
  } catch (error) {
    store.setState({ error, isCreatingSession: false })
  }
}

export const updateSession = async (params) => {
  try {
    store.setState({ isUpdatingSession: true })
    const sessionId = store.getState().session.id
    const {
      meta: { chatId, chatWasCreated },
      data: session
    } = await patch(`/sessions/${sessionId}`, params)
    store.setState({ session, isUpdatingSession: false })
    return { chatId, chatWasCreated, session }
  } catch (error) {
    store.setState({ error, isUpdatingSession: false })
  }
}

export const deleteSession = async () => {
  try {
    store.setState({ isDeletingSession: true })
    const sessionId = store.getState().session.id
    await del(`/sessions/${sessionId}`)
    store.setState({ isDeletingSession: false })
    return true
  } catch (error) {
    store.setState({ error, isDeletingSession: false })
  }
}

export const deleteSessionLine = async (lineId, params) => {
  try {
    store.setState({ isDeletingSessionLine: true })
    const sessionId = store.getState().session.id
    const { data: session } = await del(`/sessions/${sessionId}/lines/${lineId}`, params)
    store.setState({ session, isDeletingSessionLine: false })
    return session
  } catch (error) {
    store.setState({ error, isDeletingSessionLine: false })
  }
}

export const addLinesToSession = async (sessionId, lineIds) => {
  try {
    store.setState({ isAddingLinesToSession: true })
    const { data: session } = await put(`/sessions/${sessionId}/lines`, { lineIds })
    store.setState({ session, isAddingLinesToSession: false })
    return session
  } catch (error) {
    store.setState({ error, isAddingLinesToSession: false })
  }
}

export const removeLinesFromSession = async (sessionId, lineIds) => {
  try {
    store.setState({ isRemovingLinesFromSession: true })
    const { data: session } = await del(`/sessions/${sessionId}/lines`, { lineIds })
    store.setState({ session, isRemovingLinesFromSession: false })
    return session
  } catch (error) {
    store.setState({ error, isRemovingLinesFromSession: false })
  }
}

export const addSessionUser = async (sessionId, userId) => {
  try {
    store.setState({ isAddingSessionUser: userId })
    const { data: session } = await put(`/sessions/${sessionId}/users/${userId}`)
    store.setState({ session, isAddingSessionUser: null })
    return session
  } catch (error) {
    store.setState({ error, isAddingSessionUser: null })
  }
}

export const removeSessionUser = async (sessionId, userId) => {
  try {
    store.setState({ isRemovingSessionUser: userId })
    const { data: session } = await del(`/sessions/${sessionId}/users/${userId}`)
    store.setState({ session, isRemovingSessionUser: null })
    return session
  } catch (error) {
    store.setState({ error, isRemovingSessionUser: null })
  }
}
