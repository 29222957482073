export const formatTimeZone = (timeZone: string, date?: Date) => {
  const timeFormat = new Intl.DateTimeFormat('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'shortOffset',
    timeZone,
    hour12: false
  })

  if (date) {
    return timeFormat.format(date).split(' ').pop()
  }
  return timeFormat.format().split(' ').pop()
}
