import { h } from 'preact'
import { BasicDialog, List, ListItem, SpacerVertical } from '@sodra/bongo-ui'

import Avatar from '../Avatar'
import { useStore } from '../../store'
import { useFetchResult } from '../../use-fetch-result'
import { Line, ShortlistedVoice, User } from '../../types'
import { Block } from 'jsxstyle/preact'

export type SelectUploaderParams = {
  user?: User
  voice?: ShortlistedVoice
}

type Props = {
  onClose: () => void
  onSelect: (params: SelectUploaderParams) => void
  line?: Line
}

export const SelectTakeUploader = ({ onSelect, onClose, line }: Props) => {
  const { currentGame } = useStore('currentGame')

  const { data: shortlistedVoices } = useFetchResult<ShortlistedVoice[]>(
    `/games/${currentGame!.id}/shortlisted-voices`,
    {}
  )
  const { data: members } = useFetchResult<User[]>(`/games/${currentGame!.id}/members`, {})

  if (!shortlistedVoices || !members) {
    return null
  }

  const voices = line
    ? shortlistedVoices.filter((voice) =>
        voice.characters.some((character) => character.id === line?.character?.id)
      )
    : shortlistedVoices

  const setUploader = async (params: SelectUploaderParams) => {
    onSelect(params)
    onClose()
  }

  return (
    <BasicDialog
      title="Select voice"
      disableAutofocus
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      {voices.length > 0 && (
        <>
          <Block color="var(--on-surface-light)">Shortlisted voices</Block>
          <SpacerVertical small />
          <List>
            {voices.map((voice) => {
              return (
                <ListItem
                  // onClick={() => setUploader({ voiceId: voice.id, userId: null, aiVoiceId: null, ...voice })}
                  onClick={() => setUploader({ voice })}
                  text={voice.name}
                  visual={<Avatar src={voice.picture} name={voice.name} />}
                />
              )
            })}
          </List>
          <SpacerVertical />
        </>
      )}
      <Block color="var(--on-surface-light)">Game members</Block>
      <SpacerVertical small />
      <List>
        {members.map((member) => {
          return (
            <ListItem
              onClick={() => setUploader({ user: member })}
              text={member.name}
              visual={<Avatar src={member.picture} name={member.name} />}
            />
          )
        })}
      </List>
    </BasicDialog>
  )
}
