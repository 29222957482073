import { Checkbox, Dialog, H2, P, Select, SpacerVertical } from '@sodra/bongo-ui'
import { Block, Col, Grid, Row } from 'jsxstyle/preact'
import { h } from 'preact'
import { AudioDeviceSelector } from '../AudioRecorder'
import { OscilloScope } from '../WaveForm/OscilloScope'
import { lineRecorder, LineRecorderRemoteControl, recorder } from './LineRecorder'

type Props = {
  onClose: () => void
  showSampleSize?: boolean
  remoteControl?: LineRecorderRemoteControl
}

export function LineRecorderSettingsDialog({
  onClose,
  showSampleSize = false,
  remoteControl
}: Props) {
  if (!lineRecorder.value) {
    return null
  }

  return (
    <Dialog
      title="Recording settings"
      onClose={onClose}
      actions={[{ text: 'Close', onClick: onClose }]}
      large
    >
      <Col gap="30px">
        <Col>
          <P paddingLeft="15px">Audio input</P>
          <Row alignItems="center" gap="30px">
            <AudioDeviceSelector />
            {recorder.value?.state.value.mediaStream && (
              <Block width="150px" height="60px">
                <OscilloScope stream={recorder.value?.state.value.mediaStream} />
              </Block>
            )}
          </Row>
        </Col>
        {showSampleSize && (
          <Row>
            <Select
              minWidth="150px"
              label="Sample size"
              value={lineRecorder.value.sampleSize.value}
              options={[
                { value: 16, text: '16 bit' },
                { value: 24, text: '24 bit' },
                { value: 32, text: '32 bit' }
              ]}
              onChange={(value: 16 | 24 | 32) => {
                lineRecorder.value!.sampleSize.value = value
              }}
            />
          </Row>
        )}
        <Col paddingLeft="15px">
          <Checkbox
            checked={lineRecorder.value.pushToRecord.value}
            onChange={() => {
              lineRecorder.value!.pushToRecord.value = !lineRecorder.value!.pushToRecord.value
            }}
            label="Hold Enter to record"
          />
          {lineRecorder.value.pushToRecord.value && <P>Holding down Enter will record audio.</P>}
          {!lineRecorder.value.pushToRecord.value && <P>Enter key toggles recording on/off.</P>}
        </Col>
        <Col paddingLeft="15px">
          <Checkbox
            checked={lineRecorder.value.autoAdvance.value}
            onChange={() => {
              lineRecorder.value!.autoAdvance.value = !lineRecorder.value!.autoAdvance.value
            }}
            label="Auto advance"
          />
          {lineRecorder.value.autoAdvance.value && (
            <P>When stopping recording, select the next line automatically.</P>
          )}
          {!lineRecorder.value.autoAdvance.value && (
            <P>When stopping recording, stay on the selected line.</P>
          )}
        </Col>
        {lineRecorder.value.sessionId.value && (
          <Col paddingLeft="15px">
            <Checkbox
              label="Show all takes"
              checked={lineRecorder.value.showAllTakes.value}
              onChange={() =>
                (lineRecorder.value!.showAllTakes.value = !lineRecorder.value!.showAllTakes.value)
              }
            />
            {lineRecorder.value.showAllTakes.value && (
              <P>Include takes recorded or uploaded outside of this session.</P>
            )}
            {!lineRecorder.value.showAllTakes.value && (
              <P>Show only takes recorded in this session.</P>
            )}
          </Col>
        )}
        {remoteControl && (
          <Col paddingLeft="15px">
            <Checkbox
              label="Enable remote playback"
              checked={lineRecorder.value.remotePlaybackEnabled.value}
              onChange={() =>
                (lineRecorder.value!.remotePlaybackEnabled.value =
                  !lineRecorder.value!.remotePlaybackEnabled.value)
              }
            />
            {lineRecorder.value.remotePlaybackEnabled.value && (
              <P>Takes will be played back locally and also on the remote-controlled device.</P>
            )}
            {!lineRecorder.value.remotePlaybackEnabled.value && (
              <P>Takes will only be played back locally.</P>
            )}
          </Col>
        )}
        <SpacerVertical />
        <Col paddingLeft="15px" gap="10px">
          <H2 size={'5'}>Keyboard shortcuts</H2>
          <SpacerVertical tiny />
          <Grid gridTemplateColumns="auto 1fr" columnGap="20px" rowGap="10px">
            <strong>Up/Down</strong>
            <span>Next/Previous line</span>
            <strong>Left/Right</strong>
            <span> Next/Previous take within the active line</span>
            <strong>Enter</strong>
            <span> Record (toggle/hold depending on setting)</span>
            <strong>Space</strong>
            <span> Play active take</span>
            <strong>S</strong>
            <span>Set active take as the selected take</span>
            <strong>L</strong>
            <span>Lock/unlock trim on active take</span>
            <strong>F</strong>
            <span>Open active take in focus mode</span>
            <strong>Z + Left/Right</strong>
            <span>Adjust trim start on active take</span>
            <strong>X + Left/Right</strong>
            <span>Adjust trim end on active take</span>
          </Grid>
          {lineRecorder.value.scenes.value.length > 0 && (
            <>
              <SpacerVertical small />
              <H2 size={'6'}>Additional shortcuts for dialogue recording</H2>
              <SpacerVertical tiny />
              <Grid gridTemplateColumns="auto 1fr" columnGap="20px" rowGap="10px">
                <strong>N</strong>
                <span>Play/Stop next line</span>
                <strong>P</strong>
                <span> Play/Stop previous line</span>
                <strong>Q</strong>
                <span> Queue me in. Play previous line, then start recording selected line.</span>
              </Grid>
            </>
          )}
          <SpacerVertical />
        </Col>
      </Col>
    </Dialog>
  )
}
