import { h } from 'preact'

import { goBack, routeTo } from '@sodra/prutt'

import { useStore } from '../../../store'
import Page from '../../Page'
import { fetchBillingAccount } from '../../../actions/billing-accounts'
import Spinner from '../../Spinner'
import { useEffect, useState } from 'preact/hooks'
import { ResourcesUsage } from '../../GameDashboard/ResourcesUsage'
import { AiQuota } from '../../../types'
import { setError } from '../../../actions'
import { get } from '../../../api'
import { Button, H2, P, SpacerVertical } from '@sodra/bongo-ui'
import { checkBuyAiTimePreconditions } from '../../check-buy-ai-time-preconditions'
import { BuyExtraAiQuotaTimeDialog } from '../../BuyExtraAiQuotaTimeDialog'
import { formatCurrencyNoDecimals } from 'lib'

type Props = {
  accountId: string
}

type BillingAccountAiQuota = {
  basic?: AiQuota[]
  subscription?: AiQuota[]
}

export const BillingAccountUsage = ({ accountId }: Props) => {
  const { billingAccount, currentGame } = useStore('billingAccount', 'currentGame')

  const [aiQuota, setAiQuota] = useState<BillingAccountAiQuota | undefined>(undefined)
  const [buyExtraTimeAiQuota, setBuyExtraTimeAiQuota] = useState<AiQuota | undefined>(undefined)

  useEffect(() => {
    fetchBillingAccount(accountId)
  }, [accountId])

  useEffect(() => {
    if (!billingAccount) {
      return
    }

    fetchBillingAccountAiQuota()
  }, [billingAccount])

  const addCreditCard = () => {
    if (!currentGame?.billingAccount) {
      return
    }
    const params = new URLSearchParams({
      onSuccessUrl: `/settings/billing/${accountId}/usage?game=${currentGame.shortId}`
    })
    routeTo(
      `/settings/billing/${currentGame.billingAccount.id}/add-credit-card?${params.toString()}`
    )
  }

  const buyExtraAiTimeButtonClicked = async (aiQuota: AiQuota) => {
    if (!currentGame) {
      return
    }
    await checkBuyAiTimePreconditions({
      game: currentGame,
      onCreditCardRequired: addCreditCard,
      onBillingAccountRequired: () => {},
      onSuccess: () => {
        // Open a dialog to buy extra time
        setBuyExtraTimeAiQuota(aiQuota)
      }
    })
  }

  const fetchBillingAccountAiQuota = async () => {
    await get(`/billing-accounts/${accountId}/ai-quota`)
      .then(({ data: aiQuota }) => setAiQuota(aiQuota))
      .catch(setError)
  }

  if (!billingAccount) {
    return <Spinner />
  }

  const onClose = () => goBack(`/settings/billing/${billingAccount.id}`)

  const aiHourPrice = billingAccount.plan === 'starter' ? 29 : 24

  return (
    <Page title={`AI voices quota · ${billingAccount.name}`} onBack={onClose}>
      {aiQuota?.subscription?.[0] && (
        <>
          <ResourcesUsage
            billingAccount={billingAccount}
            aiQuota={aiQuota.subscription[0]}
            infoText={'This quota will be refilled at the beginning of every month.'}
          />
        </>
      )}
      {aiQuota?.basic?.[0] && (
        <>
          <ResourcesUsage
            billingAccount={billingAccount}
            aiQuota={aiQuota.basic[0]}
            infoText={'This quota will be used only if your subscription quota is empty.'}
          />
          <SpacerVertical />
          <Button contained onClick={() => buyExtraAiTimeButtonClicked(aiQuota?.basic?.[0]!)}>
            Buy extra hours
          </Button>
          <SpacerVertical />
          <P color="var(--on-surface-light)">
            AI voice quota cost: {formatCurrencyNoDecimals(aiHourPrice)} per hour
          </P>
        </>
      )}
      {buyExtraTimeAiQuota && (
        <>
          <BuyExtraAiQuotaTimeDialog
            aiQuota={buyExtraTimeAiQuota}
            billingAccount={billingAccount}
            onSuccess={(aiQuota: AiQuota, hours: number) => {
              fetchBillingAccountAiQuota()
            }}
            onClose={() => {
              setBuyExtraTimeAiQuota(undefined)
            }}
          />
        </>
      )}
    </Page>
  )
}
