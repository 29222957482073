export const setSearchParam = (name: string, value: string) => {
  if (value !== null && value !== undefined) {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get(name) !== value) {
      searchParams.set(name, value)
      history.replaceState(null, '', location.pathname + '?' + searchParams.toString())
    }
  }
}

export const deleteSearchParam = (name: string) => {
  const searchParams = new URLSearchParams(location.search)
  if (searchParams.has(name)) {
    searchParams.delete(name)
    const qs = searchParams.toString()
    if (qs) {
      history.replaceState(null, '', location.pathname + '?' + qs)
    } else {
      history.replaceState(null, '', location.pathname)
    }
  }
}
