import { store } from '../store'
import { get, del, post, patch } from '../api'

export const fetchImportSources = async (params) => {
  let cancelled = false
  store.setState({ isFetchingImportSources: true })
  const gameId = store.getState().currentGame.id
  get(`/games/${gameId}/import-sources`, params)
    .then(({ data: importSources }) => {
      if (!cancelled) {
        store.setState({ importSources, isFetchingImportSources: false })
      }
    })
    .catch((error) => {
      if (!cancelled) {
        store.setState({ error, isFetchingImportSources: false })
      }
    })
  return () => {
    store.setState({ isFetchingImportSources: false })
    cancelled = true
  }
}

export const fetchImportSource = async (importSourceId) => {
  try {
    store.setState({ isFetchingImportSource: true })
    const { data: importSource } = await get(`/import-sources/${importSourceId}`)
    store.setState({ importSource, isFetchingImportSource: false })
    return importSource
  } catch (error) {
    store.setState({ error, isFetchingImportSource: false })
  }
}

export const createImportSource = async (params) => {
  try {
    store.setState({ isCreatingImportSource: true })
    const gameId = store.getState().currentGame.id
    const { data: importSource } = await post(`/games/${gameId}/import-sources`, params)
    const { data: importSources } = await get(`/games/${gameId}/import-sources`)
    store.setState({ importSource, importSources, isCreatingImportSource: false })
    return importSource
  } catch (error) {
    store.setState({ error, isCreatingImportSource: false })
  }
}

export const updateImportSource = async (params) => {
  try {
    store.setState({ isUpdatingImportSource: true })
    const importSourceId = store.getState().importSource.id
    const { data: importSource } = await patch(`/import-sources/${importSourceId}`, params)
    store.setState({ importSource, isUpdatingImportSource: false })
    return importSource
  } catch (error) {
    store.setState({ error, isUpdatingImportSource: false })
  }
}

export const updateImportSourceById = async (importSourceId, params) => {
  try {
    store.setState({ isUpdatingImportSource: true })
    await patch(`/import-sources/${importSourceId}`, params)
    store.setState({ isUpdatingImportSource: false })
    return true
  } catch (error) {
    store.setState({ error, isUpdatingImportSource: false })
    return false
  }
}

export const syncImportSource = async (importSourceId, params) => {
  try {
    const gameId = store.getState().currentGame.id
    store.setState({ isSyncingImportSource: true })
    const { data: result } = await post(`/import-sources/${importSourceId}`, params)
    const { data: importSource } = await get(`/import-sources/${importSourceId}`)
    const { data: importSources } = await get(`/games/${gameId}/import-sources`)
    store.setState({ importSources, importSource, isSyncingImportSource: false })
    return result
  } catch (error) {
    store.setState({ error, isSyncingImportSource: false })
  }
}

export const deleteImportSource = async (importSourceId) => {
  try {
    store.setState({ isDeletingImportSource: true })
    const gameId = store.getState().currentGame.id
    await del(`/import-sources/${importSourceId}`)
    const { data: importSources } = await get(`/games/${gameId}/import-sources`)
    store.setState({ importSources, isDeletingImportSource: false })
    return true
  } catch (error) {
    store.setState({ error, isDeletingImportSource: false })
  }
}
