import {
  ErrorIcon,
  WarningIcon,
  StopIcon,
  PlayIcon,
  IconButton,
  ProgressCircular,
  Avatar,
  MoreIcon,
  DataTable,
  Switch
} from '@sodra/bongo-ui'
import { Row, Block } from 'jsxstyle/preact'
import { setError } from '../../actions'
import { formatDuration } from '../../format-duration'
import { useStore } from '../../store'
import { Take } from '../../types'
import { AIAvatar } from '../AIAvatar'
import Waveform from '../Lines/Waveform'
import { PopupMenuOption } from '../PopupMenu'
import { usePopupMenu, BuiClickEvent } from '../use-popup-menu'
import { isTakeOutdated } from '../Lines/is-take-outdated'
import { AudioPlayer } from 'lib/src/hooks/use-audio-player'

const getTakeUploader = (take: Take) => {
  if (take.voice) {
    return take.voice
  }
  if (take.aiVoice) {
    return take.aiVoice
  }
  if (take.user) {
    return take.user
  }
}

type TakeWaveformProps = {
  take: Take
  player: AudioPlayer
}

const TakeWaveform = ({ take, player }: TakeWaveformProps) => {
  const { playerState, play, initPlayer } = player

  const isCurrentTrack = playerState.value.currentTrackUri == take.uri

  return (
    <Waveform
      uri={take.uri}
      width={120}
      height={20}
      duration={isCurrentTrack ? playerState.value.duration : 0}
      currentTime={isCurrentTrack ? playerState.value.currentTime : 0}
      onClick={(_, startTimeFraction?: number) => {
        initPlayer({ audioTracks: [{ uri: take.uri }] })
        play({ audioTrackUri: take.uri, startTimeFraction })
      }}
    />
  )
}

type TakesTableProps = {
  takes: Take[]
  takesLanguage: string
  selectedTake?: Take
  popupMenuOptions?: PopupMenuOption[]
  onSelectTake?: (take?: Take) => void
  onShowOutdatedTake?: (take: Take) => void
  player: AudioPlayer
}

export const TakesTable = ({
  popupMenuOptions,
  takes,
  takesLanguage,
  selectedTake,
  onSelectTake,
  onShowOutdatedTake,
  player
}: TakesTableProps) => {
  const { line } = useStore('line')

  const { playerState, initPlayer, play, stopPlayer } = player

  const hasTakesWithWarnings = takes.some(
    (take) => isTakeOutdated(take, line!, takesLanguage) && !take.suppressWarnings
  )

  const header: any[] = [{ label: '', style: { textAlign: 'center' } }]
  if (hasTakesWithWarnings) {
    header.push({ label: '', style: { textAlign: 'center' } })
  }

  header.push(
    { label: '', style: { textAlign: 'center' } },
    {
      label: '',
      style: { whiteSpace: 'nowrap' }
    },
    {
      label: 'Voice',
      style: { whiteSpace: 'nowrap' }
    }
  )
  if (onSelectTake) {
    header.push({ label: 'Selected' })
  }
  if (popupMenuOptions) {
    header.push({ label: '' })
  }

  const { PopupMenu, showMenu } = usePopupMenu({ options: popupMenuOptions })

  const body = takes?.map((take) => {
    const uri = take.processedUri

    const isPlayingCurrentTrack =
      playerState.value.currentTrackUri === uri && playerState.value.isPlaying

    const togglePlay = () => {
      if (isPlayingCurrentTrack) {
        stopPlayer()
      } else {
        initPlayer({ audioTracks: [{ uri: take.uri }] })
        play({ audioTrackUri: take.uri })
      }
    }
    let playIcon
    let onPlayClick
    let playIconColor = ''

    if (take.error) {
      playIcon = ErrorIcon
      playIconColor = 'var(--error)'
      onPlayClick = () => setError({ message: take.error })
    } else if (isPlayingCurrentTrack) {
      playIcon = StopIcon
      onPlayClick = () => togglePlay()
    } else if (uri) {
      playIcon = PlayIcon
      onPlayClick = () => togglePlay()
    }

    const uploader = getTakeUploader(take)

    const cols = []
    if (hasTakesWithWarnings) {
      cols.push(
        isTakeOutdated(take, line!, takesLanguage) && !take.suppressWarnings ? (
          <IconButton
            onClick={() => {
              if (onShowOutdatedTake) {
                onShowOutdatedTake(take)
              }
            }}
            tooltipText={`The line has changed since this take was ${
              take.aiVoice ? 'generated' : 'uploaded'
            }.`}
            icon={WarningIcon}
            color="var(--warning)"
          />
        ) : (
          <Block />
        )
      )
    }
    cols.push(
      <Block color="var(--on-surface-light)">
        {take.seqLanguage ? `${take.seqLanguage}.` : ''}
      </Block>
    )
    cols.push(
      playIcon ? (
        <IconButton icon={playIcon} onClick={onPlayClick} color={playIconColor} />
      ) : (
        <ProgressCircular size="24" />
      ),
      <TakeWaveform take={take} player={player} />,
      <Row alignItems="center" gap="10px">
        {take.aiVoice && <AIAvatar size={30} src={uploader?.picture} name={uploader?.name} />}
        {!take.aiVoice && <Avatar size={30} src={uploader?.picture} name={uploader?.name} />}
        <Block>
          <Block>{uploader?.name}</Block>
          <Block fontSize="12px" color="var(--on-surface-light)">
            {formatDuration(take.created)}
          </Block>
        </Block>
      </Row>
    )
    if (onSelectTake) {
      cols.push(
        <Switch
          on={take.id === selectedTake?.id}
          onChange={() => onSelectTake(selectedTake?.id !== take.id ? take : undefined)}
        />
      )
    }

    if (popupMenuOptions) {
      cols.push(
        <IconButton
          icon={MoreIcon}
          onClick={(e: BuiClickEvent) => showMenu(e, take)}
          color="var(--on-surface-light)"
        />
      )
    }

    return cols
  })

  return (
    <>
      <DataTable compact header={header} body={body} />
      {popupMenuOptions && PopupMenu}
    </>
  )
}
