import { store } from '../store'
import { get, post, patch, del } from '../api'
import { State } from '../types'

export const fetchLines = async (params: any) => {
  let cancelled = false
  store.setState({ isFetchingLines: true })
  const gameId = store.getState().currentGame!.id
  get(`/games/${gameId}/lines`, params)
    .then(({ data: lines, meta: { total } }) => {
      if (!cancelled) {
        store.setState({ lines, totalLines: total, isFetchingLines: false })
      }
    })
    .catch((error) => {
      if (!cancelled) {
        store.setState({ error, isFetchingLines: false })
      }
    })
  return () => {
    store.setState({ isFetchingLines: false })
    cancelled = true
  }
}

/**
 * Refetches one or more specific lines and replaces only these lines in the store.
 */
export const refetchLines = async (lineIds: string[], params: any) => {
  let cancelled = false
  store.setState({ isRefetchingLines: true })
  const gameId = store.getState().currentGame!.id
  get(`/games/${gameId}/lines`, { ...params, lineIds })
    .then(({ data: fetchedLines }) => {
      if (!cancelled) {
        // Replace existing stored lines with fetched lines. Fetched lines that don't exist in store are ignored.
        let hasReplaced = false
        const updatedLines = (store.getState().lines ?? []).map((storedLine) => {
          const matchingFetchedLine = fetchedLines.find(
            (fetchedLine: any) => fetchedLine.id === storedLine.id
          )
          if (matchingFetchedLine) {
            hasReplaced = true
            return matchingFetchedLine
          }
          return storedLine
        })
        const newState: Partial<State> = { isRefetchingLines: false }
        if (hasReplaced) {
          newState.lines = updatedLines
        }
        store.setState(newState)
      }
    })
    .catch((error) => {
      if (!cancelled) {
        store.setState({ error, isRefetchingLines: false })
      }
    })
  return () => {
    store.setState({ isRefetchingLines: false })
    cancelled = true
  }
}

export const fetchLine = async (lineId: string) => {
  try {
    store.setState({ isFetchingLine: true })
    const { data: line } = await get(`/lines/${lineId}`)
    store.setState({ line, isFetchingLine: false })
    return line
  } catch (error: any) {
    store.setState({ error, isFetchingLine: false })
  }
}

export const createLine = async (params: any) => {
  try {
    store.setState({ isCreatingLine: true })
    const gameId = store.getState().currentGame!.id
    const { data: line } = await post(`/games/${gameId}/lines`, params)
    store.setState({ line, isCreatingLine: false })
    return line
  } catch (error: any) {
    store.setState({ error, isCreatingLine: false })
  }
}

export const updateLine = async (lineId: string, params: any) => {
  try {
    store.setState({ isUpdatingLine: true })
    const { data: line } = await patch(`/lines/${lineId}`, params)
    store.setState({ line, isUpdatingLine: false })
    return line
  } catch (error: any) {
    store.setState({ error, isUpdatingLine: false })
  }
}

export const createRephrasedLineCopies = async (lineId: string, newPhrases: string[]) => {
  try {
    const { data: lines } = await post(`/lines/${lineId}/rephrase`, { phrases: newPhrases })
    return lines
  } catch (error: any) {
    store.setState({ error })
  }
}

export const updateLineLabels = async (labels: string[]) => {
  try {
    store.setState({ isUpdatingLineLabels: true })
    const lineId = store.getState().line!.id
    const { data: line } = await patch(`/lines/${lineId}`, { labels })
    store.setState({ line, isUpdatingLineLabels: false })
    return line
  } catch (error: any) {
    store.setState({ error, isUpdatingLineLabels: false })
  }
}

export const updateLines = async (lineIds: string[], params: any) => {
  try {
    store.setState({ isUpdatingLines: true })
    const gameId = store.getState().currentGame?.id
    await patch(`/lines`, { gameId, lineIds, ...params })
    store.setState({ isUpdatingLines: false })
    return true
  } catch (error: any) {
    store.setState({ error, isUpdatingLines: false })
  }
}

export const translateLines = async (lineIds: string[] | undefined, params: any) => {
  try {
    store.setState({ isTranslatingLines: true })
    const gameId = store.getState().currentGame?.id
    await post(`/lines/translate`, { gameId, lineIds, ...params })
    store.setState({ isTranslatingLines: false })
    return true
  } catch (error: any) {
    store.setState({ error, isTranslatingLines: false })
  }
}

export const deleteLines = async (
  lineIds: string[],
  { deletePermanently }: { deletePermanently: boolean }
) => {
  try {
    store.setState({ isDeletingLines: true })
    const gameId = store.getState().currentGame!.id
    await del(`/games/${gameId}/lines`, { lineIds, deletePermanently })
    store.setState({ isDeletingLines: false })
    return true
  } catch (error: any) {
    store.setState({ error, isDeletingLines: false })
  }
}

export const restoreLines = async (lineIds: string[], dryRun: boolean = false) => {
  try {
    store.setState({ isRestoringLines: true })
    const { data: restoredLineIds } = await post(`/lines/restore`, { lineIds, dryRun })
    store.setState({ isRestoringLines: false })
    return restoredLineIds
  } catch (error: any) {
    store.setState({ error, isDeletingLines: false })
  }
}

export const uploadExcel = async (params: any) => {
  try {
    store.setState({ isUploadingExcel: true })
    const gameId = store.getState().currentGame!.id
    await post(`/games/${gameId}/lines.xlsx`, params)
    store.setState({ isUploadingExcel: false })
    return true
  } catch (error: any) {
    store.setState({ error, isUploadingExcel: false })
  }
}
