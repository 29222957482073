import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { FilterListIcon, SelectChip } from '@sodra/bongo-ui'

import { get } from '../../api'

import { usePopupMenu } from '../use-popup-menu'

export const AccentSelectChip = ({ value, onChange }) => {
  const [accents, setAccents] = useState([])

  useEffect(() => {
    get('/audio-accents').then(({ data: accents }) => setAccents(accents))
  }, [])

  const { PopupMenu, showMenu } = usePopupMenu({
    options: accents.map((accent) => {
      return {
        label: accent,
        onClick: () => onChange(accent)
      }
    })
  })

  return (
    <Fragment>
      <SelectChip
        label="Accent"
        icon={FilterListIcon}
        value={value}
        onClick={showMenu}
        onClear={() => onChange()}
        whiteSpace="nowrap"
      />
      {PopupMenu}
    </Fragment>
  )
}
