import { h } from 'preact'
import { useStore } from '../store'
import { Order } from '../types'
import { SessionCost } from './SessionCost'
import { H2, Link, ProgressCircular, SpacerVertical } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import {
  calcSessionOrderDetails,
  getOrderDetails
} from './CreateSession/calc-session-order-details'

type Props = {
  budgetSegment: string
  duration?: number
  includeVAT: boolean
  order?: Order
}

export const SessionFee = ({ budgetSegment, duration, includeVAT, order }: Props) => {
  const { config } = useStore('config')

  if (order) {
    const orderDetails = getOrderDetails(order)
    return (
      <>
        <SessionCost
          description={orderDetails.description}
          items={orderDetails.items}
          total={orderDetails.total}
          includeVat={includeVAT}
          vatPercent={orderDetails.vatPercent}
          vat={orderDetails.vat}
          totalIncVat={orderDetails.totalIncVAT}
        />
        <SpacerVertical />
        <Link to={`/orders/${order.id}`} onRoute={routeTo}>
          View order
        </Link>
      </>
    )
  }

  if (!config) {
    return (
      <>
        <H2 size="5">Session cost</H2>
        <SpacerVertical />
        <ProgressCircular size={24} />
      </>
    )
  }

  const orderDetails = calcSessionOrderDetails({
    budgetSegment,
    duration,
    includeVAT,
    config: config!
  })

  return (
    <SessionCost
      description={orderDetails.description}
      items={orderDetails.items}
      total={orderDetails.total}
      includeVat={orderDetails.includeVAT}
      vat={orderDetails.vat}
      totalIncVat={orderDetails.totalIncVAT}
      vatPercent={orderDetails.vatPercent}
    />
  )
}
