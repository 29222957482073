import { cloneElement } from 'preact'
import { store, resetStore } from '../store'
import { get, post } from '../api'
import { routeTo } from '@sodra/prutt'

export * from './user'
export * from './image'
export * from './games'
export * from './members'
export * from './invitations'
export * from './characters'
export * from './events'
export * from './scenes'
export * from './lines'
export * from './sessions'
export * from './ai-voices'
export * from './player'
export * from './takes'
export * from './export-targets'
export * from './import-sources'
export * from './voices'
export * from './chat'
export * from './files'
export * from './line-attributes'
export * from './line-labels'
export * from './api-keys'

export const login = async ({ email, password }) => {
  try {
    store.setState({ isLoggingIn: true })
    const { data: currentUser } = await post('/login', { email, password })
    store.setState({ currentUser, isLoggingIn: false })
    return currentUser
  } catch (error) {
    store.setState({ error, isLoggingIn: false })
  }
}

export const loginWithSSO = async (domain) => {
  try {
    store.setState({ isLoggingIn: true })
    const { data: name } = await get(`/sso`, { domain })
    return name
  } catch (error) {
    store.setState({ error, isLoggingIn: false })
  }
}

export const loginWithToken = async (token) => {
  try {
    store.setState({ isLoggingIn: true })
    const { data: currentUser } = await post('/login-with-token', { token })
    store.setState({ currentUser, isLoggingIn: false })
    return currentUser
  } catch (error) {
    store.setState({ error, isLoggingIn: false })
  }
}

export const logout = async () => {
  try {
    store.setState({ isLoggingOut: true })
    await post('/logout')
    routeTo('/')
    resetStore()
    return true
  } catch (error) {
    store.setState({ error, isLoggingOut: false })
  }
}

export const forgotPassword = async (params) => {
  try {
    store.setState({ isForgettingPassword: true })
    await post('/forgot-password', params)
    store.setState({ isForgettingPassword: false })
    return true
  } catch (error) {
    store.setState({ error, isForgettingPassword: false })
  }
}

export const resetPassword = async (params) => {
  try {
    store.setState({ isResettingPassword: true })
    const { data: currentUser } = await post('/reset-password', params)
    store.setState({ currentUser, isResettingPassword: false })
    return currentUser
  } catch (error) {
    store.setState({ error, isResettingPassword: false })
  }
}

export const validateSignUpToken = async (token) => {
  try {
    store.setState({ isValidatingSignUpToken: true })
    const { data: email } = await post('/validate-sign-up', { token })
    store.setState({ isValidatingSignUpToken: false })
    return email
  } catch (error) {
    store.setState({ error, isValidatingSignUpToken: false })
  }
}

export const completeSignUp = async (params) => {
  try {
    store.setState({ isCompletingSignUp: true })
    const { data } = await post('/complete-sign-up', params)
    const currentUser = data.user
    const currentGame = data.game
    store.setState({ currentUser, currentGame, isCompletingSignUp: false })
    return currentUser
  } catch (error) {
    store.setState({ error, isCompletingSignUp: false })
  }
}

export const verifyUser = async (userId) => {
  try {
    store.setState({ isVerifyingUser: true })
    await post(`/users/${userId}/verify`)
    store.setState({ isVerifyingUser: false })
    return true
  } catch (error) {
    store.setState({ error, isVerifyingUser: false })
    return false
  }
}

export const setError = (error) => store.setState({ error })

export const clearError = () => setError(null)

const addSnackbar = ({ message, action, visibleTime }) => {
  const snackbarItems = store.getState().snackbarItems || []
  store.setState({ snackbarItems: [...snackbarItems, { message, action, visibleTime }] })
}

export const removeSnackbar = () => {
  const snackbarItems = store.getState().snackbarItems || []
  store.setState({ snackbarItems: snackbarItems.slice(1) })
}

export const dismissSnackbar = () => {
  const snackbarItems = store.getState().snackbarItems || []
  store.setState({
    snackbarItems: snackbarItems.map((item, index) => {
      if (index === 0) {
        return { ...item, dismiss: true }
      } else {
        return item
      }
    })
  })
}

export const showSnackbar = (message, action, visibleTime) =>
  addSnackbar({ message, action, visibleTime })
