import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { FaceIcon, List, ListItem, MoreIcon, PersonAddIcon } from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'

import {
  fetchInvitations,
  fetchMembers,
  removeMember,
  deleteInvitation,
  showSnackbar
} from '../../actions'

import { BuiClickEvent, usePopupMenu } from '../use-popup-menu'

import Page from '../Page'
import Avatar from '../Avatar'
import InviteMember from './InviteMember'
import { useStore } from '../../store'
import { Invitation, User } from '../../types'

const formatRole = (role: string = '') => role.charAt(0).toUpperCase() + role.slice(1)

export const ManageAccess = () => {
  const {
    currentGame,
    members = [],
    invitations = []
  } = useStore('currentGame', 'members', 'invitations')

  const [showInviteMemberDialog, setShowInviteMemberDialog] = useState(false)

  useEffect(() => {
    fetchMembers()
  }, [currentGame!.id])

  useEffect(() => {
    fetchInvitations()
  }, [currentGame!.id])

  const handleRemoveMember = async (user: User) => {
    if (await confirm(`Remove user ${user.name} from the game?`)) {
      if (await removeMember(user.id)) {
        showSnackbar(`User ${user.name} removed`)
      }
    }
  }

  const handleDeleteInvitation = async (invitation: Invitation) => {
    if (await confirm(`Delete invitation to ${invitation.email}?`)) {
      if (await deleteInvitation(invitation.id)) {
        showSnackbar(`Invitation to ${invitation.email} deleted`)
      }
    }
  }

  const { PopupMenu: MembersPopupMenu, showMenu: showMemberMenu } = usePopupMenu({
    options: [{ label: 'Remove', onClick: handleRemoveMember }]
  })

  const { PopupMenu: InvitationPopupMenu, showMenu: showInvitationMenu } = usePopupMenu({
    options: [{ label: 'Delete invitation', onClick: handleDeleteInvitation }]
  })

  const onBack = () => goBack('/settings')

  return (
    <Page
      title="Manage access"
      onBack={onBack}
      actions={[
        {
          label: 'Add member',
          icon: PersonAddIcon,
          onClick: () => setShowInviteMemberDialog(true)
        },
        {
          label: 'Create virtual member',
          icon: FaceIcon,
          onClick: () => routeTo('/settings/manage-access/create-virtual-game-member')
        }
      ]}
    >
      <List maxWidth="500px">
        {members.map((user) => {
          return (
            <ListItem
              visual={<Avatar size={30} src={user.picture} name={user.name} />}
              text={user.name}
              secondaryText={user.email}
              metaText={formatRole(user.role)}
              actionIcon={MoreIcon}
              onActionClick={(e: BuiClickEvent) => showMemberMenu(e, user)}
            />
          )
        })}
      </List>
      <List maxWidth="500px">
        {invitations.map((invitation) => {
          return (
            <ListItem
              visual={<Avatar size={30} name={invitation.email.toUpperCase()} />}
              text={invitation.email}
              secondaryText="Pending"
              metaText={formatRole(invitation.role)}
              actionIcon={MoreIcon}
              onActionClick={(e: BuiClickEvent) => showInvitationMenu(e, invitation)}
            />
          )
        })}
      </List>
      {MembersPopupMenu}
      {InvitationPopupMenu}
      {showInviteMemberDialog && <InviteMember onClose={() => setShowInviteMemberDialog(false)} />}
    </Page>
  )
}

export default ManageAccess
