import { h } from 'preact'
import { Dialog, SpacerVertical } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { createLineLabel, showSnackbar } from '../../../actions'
import { capitalize } from '../../capitalize'
import { SuperForm } from '../../SuperForm'
import { useStore } from '../../../store'

export const CreateLineLabel = () => {
  const { isCreatingLineLabel } = useStore('isCreatingLineLabel')

  const onBack = () => goBack('/lines/line-labels')

  const handleSubmit = async (params: any) => {
    if (await createLineLabel(params.name)) {
      showSnackbar(`Line label ${capitalize(params.name)} created`)
      onBack()
    }
  }

  return (
    <Dialog title="Add line label" onClose={onBack}>
      <SuperForm
        onSubmit={handleSubmit}
        onCancel={onBack}
        loading={isCreatingLineLabel}
        elements={[
          {
            element: 'TextField',
            name: 'name',
            label: 'Name',
            required: true
          }
        ]}
      />
      <SpacerVertical />
    </Dialog>
  )
}
