import { Fragment, h } from 'preact'
import { H2, Link, P, SpacerVertical } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

export const NoChatsYet = () => {
  return (
    <Fragment>
      <H2 size="6">No chats yet</H2>
      <SpacerVertical />
      <P>
        Shortlist a voice talent in the{' '}
        <Link to="/voices" onRoute={(href: string) => routeTo(href)}>
          voice library
        </Link>{' '}
        for a character to initiate a chat.
      </P>
    </Fragment>
  )
}
