import { h, Fragment } from 'preact'
import { Block, Inline, Row } from 'jsxstyle/preact'
import { SpacerHorizontal, SpacerVertical } from '@sodra/bongo-ui'
import Avatar from '../Avatar'
import { formatVoiceName } from '../../format-voice-name'

export const Title = ({ voice }) => (
  <Row alignItems="center" margin="0 30px">
    <Avatar size="150" src={voice.picture} name={voice.name} />
    {/* <SpacerHorizontal />
    <Block fontSize="1.953125rem">{formatVoiceName(voice.name)}</Block> */}
  </Row>
)
