import { Block, Col, Grid, Row } from 'jsxstyle/preact'
import { lineRecorder, recorder } from './LineRecorder'
import { Line, Scene, Take, TakeUser } from './lineRecorderState'
import { Button, PlayIcon, SpacerVertical, StopIcon, TabItem, Tabs } from '@sodra/bongo-ui'
import { VNode } from 'preact'
import { TakeDots } from './TakeDots'
import { useEffect, useState } from 'preact/hooks'
import { audioBufferFromURI } from '../../lib/audioBufferFromURI'
import { useAudioBufferPlayer } from '../../lib/useAudioBufferPlayer'
import { blurActiveElement } from './blur-active-element'
import { formatLanguage } from 'lib'

type Character = {
  id: string
  name: string
  picture?: string
}

type LinesContext = {
  scenes: Scene[]
  lines: Line[]
  characters: Character[]
}

const createLinesContext = (allLines: Line[], scenes: Scene[]): LinesContext => {
  const independentLines: Line[] = allLines.filter((line) => {
    const isLineInAnyScene = scenes.some((scene) =>
      scene.lines?.some((sceneLine) => sceneLine.id === line.id)
    )
    return !isLineInAnyScene
  })

  const characters = allLines
    .reduce<Character[]>((characters, line) => {
      const characterId = line.character?.id
      if (characterId && !characters.some((c) => c.id === characterId)) {
        characters.push(line.character!)
      }
      return characters
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name))

  return {
    scenes,
    characters,
    lines: independentLines
  }
}

type LinesContextLineParams = {
  line: Line
  language: string
  lineNumber?: number
  isRecordable: boolean
  isActive: boolean
  activeLineRecorderTake?: Take
  takes?: Take[]
  onPlayReferenceAudio: (line: Line) => void
  onStopReferenceAudio: (line: Line) => void
}

// FIXME MR: Move to separate file
const LinesContextLine = ({
  line,
  language,
  lineNumber,
  isRecordable,
  isActive,
  activeLineRecorderTake,
  takes,
  onPlayReferenceAudio,
  onStopReferenceAudio
}: LinesContextLineParams) => {
  const [referenceAudioBuffer, setReferenceAudioBuffer] = useState<AudioBuffer | undefined>(
    undefined
  )

  let referenceAudioUri = line.translations[language]?.referenceAudioUri

  // This bit of weird logic is to decide if the Listen button should show up or not in the lines overview.
  //
  // For recordable lines in normal scenes (not example scenes), the selected take is the reference audio and it should only be playable if that take is currently visible.
  // This means that if we're only showing takes for a specific session, a selected take recorded outside of the session should not be playable as reference audio.
  // Otherwise it is very confusing to be able to play a take that can't be seen anywhere.
  //
  // For example scenes it's a little different, where the reference audio is not the same as the selected take.
  // In that case, we only want to play the reference audio for the non-recordable lines.
  if (isRecordable) {
    // Must locate selected take from the takes array, because the selectedTake found in translations might be outdated
    const selectedTake = takes?.find(
      (take) => take.id === line.translations?.[language]?.selectedTake?.id
    )

    if (selectedTake) {
      // For example scenes: Make Listen button play the recorded selected take instead of the reference audio.
      // Note: Can't use processedUri because it has not always been updated properly
      if (referenceAudioUri !== selectedTake.uri) {
        referenceAudioUri = selectedTake.uri
      }
    } else {
      // If selected take is hidden, the reference audio is never playable
      referenceAudioUri = undefined
    }
  }

  useEffect(() => {
    if (!referenceAudioUri) {
      return
    }

    audioBufferFromURI(referenceAudioUri).then(setReferenceAudioBuffer)
  }, [referenceAudioUri])

  const referenceAudioPlayer = useAudioBufferPlayer({
    audioBuffer: referenceAudioBuffer ?? null,
    isRecording: false,
    onPlay: () => {}
  })
  if (line.translations[language]) {
    line.translations[language].referenceAudioBufferPlayer = referenceAudioPlayer
  }

  const onLineClick = (e: MouseEvent) => {
    if (recorder.value?.state.value.mediaRecorderState !== 'recording') {
      lineRecorder.value!.activeLineId.value = line.id
    }
  }

  const currentLanguageLine = line.translations[lineRecorder.value!.language.value]?.line
  const primaryLanguage = lineRecorder.value!.game.value.primaryLanguage
  const primaryLanguageLine = line.translations[primaryLanguage]?.line

  const fontColor = isRecordable
    ? 'var(--on-surface)'
    : referenceAudioPlayer?.state === 'playing'
    ? 'var(--warning)'
    : 'var(--on-surface-light)'

  const listenPlayButton =
    referenceAudioPlayer && referenceAudioUri ? (
      <Button
        tiny
        icon={PlayIcon}
        outlined
        borderColor="var(--container-outline)"
        color="var(--on-surface-light)"
        onClick={() => {
          referenceAudioPlayer.play()
          onPlayReferenceAudio(line)
        }}
        onFocus={blurActiveElement}
        focusable={false}
        width="75px"
      >
        Listen
      </Button>
    ) : undefined

  const listenStopButton =
    referenceAudioPlayer && referenceAudioUri ? (
      <Button
        tiny
        icon={StopIcon}
        outlined
        borderColor="var(--container-outline)"
        color="var(--on-surface-light)"
        onClick={() => {
          referenceAudioPlayer.stop()
          onStopReferenceAudio(line)
        }}
        onFocus={blurActiveElement}
        focusable={false}
        width="75px"
      >
        Stop
      </Button>
    ) : undefined

  return (
    <Block
      padding="20px 20px"
      hoverBackgroundColor={isRecordable ? 'var(--surface-alternative)' : undefined}
      backgroundColor={isActive ? 'var(--surface-floating)' : undefined}
      borderRadius="3px"
      gap="5px"
      cursor={!isActive && isRecordable ? 'pointer' : undefined}
      scrollMarginTop="40vh"
      key={line.id}
      props={{
        onClick: isRecordable ? onLineClick : undefined,
        id: line.id
      }}
    >
      {line.character && (
        <>
          <Block paddingLeft="100px" fontSize="16px" color={fontColor} textTransform="uppercase">
            {line.character.name}
          </Block>
          <SpacerVertical />
        </>
      )}
      <Grid gridTemplateColumns="30px auto" rowGap="20px">
        <Block color={!currentLanguageLine ? 'var(--warning)' : fontColor}>
          {lineNumber ? `${lineNumber}.` : ''}
        </Block>
        <Block color={fontColor}>
          {line.description && (
            <>
              <Block>({line.description ?? 'acting notes'})</Block>
              <SpacerVertical tiny />
            </>
          )}
          <Block whiteSpace="pre-line" color={!currentLanguageLine ? 'var(--warning)' : undefined}>
            {currentLanguageLine
              ? currentLanguageLine
              : `Not translated to ${formatLanguage(lineRecorder.value!.language.value)}`}
            {!currentLanguageLine && primaryLanguageLine && (
              <Block color="var(--on-surface-light)" whiteSpace="pre-line">
                <SpacerVertical tiny />
                {formatLanguage(primaryLanguage)}: {primaryLanguageLine}
              </Block>
            )}
          </Block>
        </Block>
      </Grid>

      {!isRecordable && (
        <Block marginLeft="30px">
          {referenceAudioPlayer?.state === 'stopped' && (
            <>
              <SpacerVertical small />
              {listenPlayButton}
            </>
          )}
          {referenceAudioPlayer?.state === 'playing' && (
            <>
              <SpacerVertical small />
              {listenStopButton}
            </>
          )}
          {!referenceAudioUri && (
            <>
              <SpacerVertical tiny />
              <Block fontSize="14px" color="var(--on-surface-lighter)">
                Not recorded
              </Block>
            </>
          )}
        </Block>
      )}

      {isRecordable && (
        <>
          <SpacerVertical tiny />
          <Block paddingLeft="30px">
            {takes && takes.length > 0 && (
              <TakeDots
                takes={takes ?? []}
                selectedTake={line.translations[language]?.selectedTake}
                activeLineRecorderTake={activeLineRecorderTake}
              />
            )}
            {!takes?.length && (
              <Block fontSize="14px" color="var(--warning)">
                Not recorded
              </Block>
            )}
            {referenceAudioUri && (
              <>
                <SpacerVertical small />
                {referenceAudioPlayer?.state === 'stopped' && listenPlayButton}
                {referenceAudioPlayer?.state === 'playing' && listenStopButton}
              </>
            )}
          </Block>
        </>
      )}
    </Block>
  )
}

type LinesContextSceneParams = {
  scene: Scene
  allLines: Line[]
  linesToRecord: Line[]
  activeLineRecorderTake?: Take
  onPlayReferenceAudio: (line: Line) => void
  onStopReferenceAudio: (line: Line) => void
}

// FIXME MR: Move to separate file
const LinesContextScene = ({
  scene,
  allLines,
  linesToRecord,
  activeLineRecorderTake,
  onPlayReferenceAudio,
  onStopReferenceAudio
}: LinesContextSceneParams) => {
  return (
    <Col padding="20px 40px">
      <Block fontSize="20px" textTransform="uppercase">
        {scene.name}
      </Block>
      <SpacerVertical tiny />
      <Block fontSize="16px" color="var(--on-surface-light)" textTransform="uppercase">
        {scene.location}
      </Block>
      <SpacerVertical />
      <Block color="var(--on-surface-light)">{scene.description}</Block>
      <SpacerVertical large />
      <Block>
        {scene.lines?.map((sceneLine) => {
          const shouldRecordLine = linesToRecord.some(
            (lineToRecord) => lineToRecord.id === sceneLine.id
          )

          // FIXME MR: This is copy-pasted from LinesList. Feels ugly to find takes this way. Refactor?
          const lineTakes =
            lineRecorder.value?.takes.value.filter((take) => {
              const isLineTake = take.lineId === sceneLine.id
              if (!isLineTake) return false
              if (!lineRecorder.value?.sessionId.value) return true
              return (
                lineRecorder.value.showAllTakes.value ||
                take.sessionId === lineRecorder.value.sessionId.value
              )
            }) ?? []

          let lineElem: VNode

          if (shouldRecordLine) {
            const lineIndex = allLines.findIndex((line) => line.id === sceneLine.id)
            if (lineIndex < 0) {
              throw new Error(`Could not find index of line to record: ${sceneLine.id}`)
            }

            // Note: allLines contains latest line data, while scenes.lines may contain old data
            const lineToRecord = allLines[lineIndex]

            const isRecordable =
              !lineRecorder.value?.selectedCharacter.value ||
              lineRecorder.value?.selectedCharacter.value === lineToRecord.character?.id

            lineElem = (
              <LinesContextLine
                line={lineToRecord}
                language={lineRecorder.value?.language.value ?? 'en'}
                isRecordable={isRecordable}
                isActive={lineRecorder.value?.activeLineId.value === sceneLine.id}
                lineNumber={lineIndex + 1}
                activeLineRecorderTake={activeLineRecorderTake}
                takes={lineTakes}
                onPlayReferenceAudio={onPlayReferenceAudio}
                onStopReferenceAudio={onStopReferenceAudio}
              />
            )
          } else {
            lineElem = (
              <LinesContextLine
                line={sceneLine}
                language={lineRecorder.value?.language.value ?? 'en'}
                isRecordable={false}
                isActive={false}
                onPlayReferenceAudio={onPlayReferenceAudio}
                onStopReferenceAudio={onStopReferenceAudio}
              />
            )
          }

          return lineElem
        })}
      </Block>
    </Col>
  )
}

type LinesContextViewParams = {
  scenes: Scene[]
  allLines: Line[]
  activeLineRecorderTake: Take | undefined
  onPlayReferenceAudio: (line: Line) => void
  onStopReferenceAudio: (line: Line) => void
}
export const LinesContextView = ({
  scenes,
  allLines,
  activeLineRecorderTake,
  onPlayReferenceAudio,
  onStopReferenceAudio
}: LinesContextViewParams) => {
  if (!lineRecorder.value) {
    return null
  }

  const context: LinesContext = createLinesContext(allLines, scenes)

  const showTabs = context.characters.length > 1

  const tabs = context.characters.map((character) => {
    const isSelected = character.id === lineRecorder.value?.selectedCharacter.value
    return (
      <TabItem
        key={character.id}
        text={character.name}
        avatar={{ src: character.picture, name: character.name }}
        tooltipText={`${character.name} lines`}
        active={isSelected}
        onClick={() => {
          if (isSelected) {
            lineRecorder.value!.selectedCharacter.value = null
          } else {
            lineRecorder.value!.selectedCharacter.value = character.id
          }
        }}
      />
    )
  })

  tabs.unshift(
    <TabItem
      text={'All'}
      tooltipText={`Lines for all characters`}
      active={lineRecorder.value.selectedCharacter.value === null}
      onClick={() => {
        if (lineRecorder.value!.selectedCharacter.value !== null) {
          lineRecorder.value!.selectedCharacter.value = null
        }
      }}
    />
  )

  const independentLines = [
    ...context.lines.filter(
      (line) =>
        !lineRecorder.value?.selectedCharacter.value ||
        lineRecorder.value?.selectedCharacter.value === line.character?.id
    )
  ]

  const independentLinesScene: Scene = {
    id: 'independent-lines-scene',
    name: 'Other lines',
    description: 'The following lines are not part of any scene',
    lines: independentLines
  }

  return (
    <Col position="relative">
      {showTabs && (
        <Block position="sticky" top="0" background="var(--surface)">
          <Tabs type="scrollable">{tabs}</Tabs>
        </Block>
      )}
      {context.scenes.map((scene) => {
        const selectedCharacter = lineRecorder.value?.selectedCharacter.value

        const sceneLines = scene.lines ?? []
        const linesToRecord = sceneLines.filter(
          (sceneLine) =>
            allLines.some((line) => line.id === sceneLine.id) &&
            (!selectedCharacter || sceneLine.character?.id === selectedCharacter)
        )

        if (linesToRecord.length === 0) {
          return null
        }

        return (
          <LinesContextScene
            scene={scene}
            allLines={allLines}
            linesToRecord={linesToRecord}
            activeLineRecorderTake={activeLineRecorderTake}
            onPlayReferenceAudio={onPlayReferenceAudio}
            onStopReferenceAudio={onStopReferenceAudio}
          />
        )
      })}
      {independentLines.length > 0 && (
        // Render lines that are not part of any scene
        // NOTE: Quick fix for now is to create a "dummy scene" containing the independent lines
        <LinesContextScene
          scene={independentLinesScene}
          allLines={allLines}
          linesToRecord={context.lines}
          activeLineRecorderTake={activeLineRecorderTake}
          onPlayReferenceAudio={onPlayReferenceAudio}
          onStopReferenceAudio={onStopReferenceAudio}
        />
      )}
    </Col>
  )
}
