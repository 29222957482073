import { Select } from '@sodra/bongo-ui'
import { getData } from 'country-list'
import { useCallback, useMemo } from 'preact/hooks'

const replaceNames = new Map()
replaceNames.set('BO', 'Bolivia')
replaceNames.set('CD', 'Congo Kinshasa')
replaceNames.set('CG', 'Congo Brazzaville')
replaceNames.set('IR', 'Iran')
replaceNames.set('KP', 'North Korea')
replaceNames.set('KR', 'South Korea')

replaceNames.set('GB', 'United Kingdom')
replaceNames.set('TR', 'Turkey')
replaceNames.set('TW', 'Taiwan')
replaceNames.set('VA', 'Vatican City')
replaceNames.set('VE', 'Venezuela')
replaceNames.set('TZ', 'Tanzania')
replaceNames.set('PS', 'Palestinian Territories')
replaceNames.set('MD', 'Moldova')
replaceNames.set('FM', 'Micronesia')

const alternativeNames = new Map()
alternativeNames.set('AE', ['UAE'])
alternativeNames.set('GB', ['Great Britain'])
alternativeNames.set('SY', ['Syria'])
alternativeNames.set('TR', ['Turkey'])
alternativeNames.set('US', ['USA', 'United States'])
alternativeNames.set('TR', ['Türkiye'])
alternativeNames.set('VA', ['Holy See'])
alternativeNames.set('VN', ['Vietnam'])

const getAlternativeNames = (countryCode: string) => alternativeNames.get(countryCode) || []

const countryOptions = getData()
  .map((country) => {
    // Replace silly country names
    if (replaceNames.get(country.code)) {
      return {
        code: country.code,
        name: replaceNames.get(country.code)
      }
    }
    return { code: country.code, name: country.name }
  })
  .sort((c1, c2) => {
    // Sort by name
    return c1.name.localeCompare(c2.name)
  })
  .map((country) => {
    // Set keywords
    const keywords = [country.name]
    // Add alternative names as keywords
    for (let name of getAlternativeNames(country.code)) {
      keywords.push(name)
    }
    return { value: country.code, text: country.name, keywords }
  })

type Props = {
  label?: string
  placeholder?: string
  countryCode?: string | undefined
  errorText?: string
  onChange: (countryCode: string) => void
  props: any
}

export const CountrySelect = ({
  label,
  placeholder,
  countryCode,
  errorText,
  onChange,
  props
}: Props) => {
  const handleOnChange = useCallback(
    (value: string) => {
      if (onChange) {
        onChange(value)
      }
    },
    [onChange, countryOptions]
  )

  return (
    <Select
      label={label}
      placeholder={placeholder}
      value={countryCode}
      options={countryOptions}
      onChange={handleOnChange}
      errorText={errorText}
      props={props}
    />
  )
}
