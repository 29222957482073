import { store } from '../store'
import { get, patch } from '../api'

export const fetchOrders = async (params) => {
  let cancelled = false
  store.setState({ isFetchingOrders: true })
  const gameId = store.getState().currentGame.id
  get(`/games/${gameId}/orders`, params)
    .then(({ data: orders, meta: { total } }) => {
      if (!cancelled) {
        store.setState({ orders, totalOrders: total, isFetchingOrders: false })
      }
    })
    .catch((error) => {
      if (!cancelled) {
        store.setState({ error, isFetchingOrders: false })
      }
    })
  return () => {
    store.setState({ isFetchingOrders: false })
    cancelled = true
  }
}

export const fetchOrder = async (orderId) => {
  try {
    store.setState({ isFetchingOrder: true })
    const { data: order } = await get(`/orders/${orderId}`)
    store.setState({ order, isFetchingOrder: false })
    return order
  } catch (error) {
    store.setState({ error, isFetchingOrder: false })
  }
}

export const acceptOrder = async (orderId) => {
  try {
    store.setState({ isUpdatingOrder: true })
    const { data: order } = await patch(`/orders/${orderId}/accept`)
    store.setState({ order, isUpdatingOrder: false })
    return order
  } catch (error) {
    store.setState({ error, isUpdatingOrder: false })
  }
}
