import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { BasicDialog, Checkbox, NativeSelect, SpacerVertical } from '@sodra/bongo-ui'

import { fetchLineLabels, setError } from '../../actions'
import { post } from '../../api'
import dateFormat from 'dateformat'
import { pluralize } from 'lib'
import { useStore } from '../../store'
import { useLocalStorageState } from '../../use-local-storage-state'

type ExportLinesParams = {
  onClose: () => void
  lineIds: string[]
  character?: {
    name: string
  }
  event?: {
    name: string
  }
  scene?: {
    name: string
  }
  sortOrder: string
  orderBy: string
  columns: string[]
}

export const ExportLines = ({
  onClose,
  lineIds,
  character,
  event,
  scene,
  sortOrder,
  orderBy,
  columns
}: ExportLinesParams) => {
  const [format, setFormat] = useLocalStorageState<string>(
    'speechless:export-lines:format',
    'excel'
  )
  const [delimiter, setDelimiter] = useLocalStorageState<string>(
    'speechless:export-lines:delimiter',
    ','
  )
  const [isDownloading, setIsDownloading] = useState(false)
  const [splitLabels, setSplitLabels] = useState(false)
  const {
    currentGame,
    currentLanguage,
    lineLabels = []
  } = useStore('currentGame', 'currentLanguage', 'lineLabels')

  useEffect(() => {
    fetchLineLabels()
  }, [])

  const handleDownload = async () => {
    setIsDownloading(true)
    try {
      const { data: uri } = await post(`/games/${currentGame!.id}/export-lines`, {
        format,
        lineIds,
        sortOrder,
        orderBy,
        columns,
        splitLabels,
        language: currentLanguage,
        delimiter: format === 'csv' ? delimiter : undefined
      })

      let filenameParts = [currentGame!.name]

      if (character) {
        filenameParts.push(character.name)
      }
      if (event) {
        filenameParts.push(event.name)
      }
      if (scene) {
        filenameParts.push(scene.name)
      }
      filenameParts.push(dateFormat('yyyy-mm-dd HH:MM'))

      const filename = filenameParts.join(' - ') + (format === 'excel' ? '.xlsx' : '.csv')

      onClose()
      location.href = `${import.meta.env.VITE_API_URL}/v1/download?uri=${uri}&filename=${filename}`
    } catch (err) {
      setError((err as Error).message)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      action1Text="Cancel"
      onAction1Click={onClose}
      title={`Export ${lineIds.length} ${pluralize('line', lineIds.length)}`}
      primaryActionText="Export"
      primaryActionLoading={isDownloading}
      onPrimaryActionClick={handleDownload}
      onClose={onClose}
    >
      <NativeSelect
        width="100%"
        label="Format"
        value={format}
        onChange={setFormat}
        options={[
          { label: 'Excel', value: 'excel' },
          { label: 'CSV', value: 'csv' }
        ]}
      />
      {format === 'csv' && (
        <>
          <SpacerVertical />
          <NativeSelect
            width="100%"
            label="Delimiter"
            value={delimiter}
            onChange={setDelimiter}
            options={[
              { label: ',', value: ',' },
              { label: ';', value: ';' },
              { label: '|', value: '|' }
            ]}
          />
        </>
      )}
      {lineLabels.length > 0 && (
        <>
          <SpacerVertical />
          <Checkbox
            label="Separate columns for each label"
            checked={splitLabels}
            onChange={setSplitLabels}
          />
        </>
      )}
    </BasicDialog>
  )
}
