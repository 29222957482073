import { Fragment, h, options } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { FilterListIcon, SelectChip } from '@sodra/bongo-ui'
import { usePopupMenu } from '../use-popup-menu'
import { get } from '../../api'
import { useStore } from '../../store'

type Props = {
  value?: string
  onChange: (value?: string) => void
}

export const ContentTypeSelectChip = ({ value, onChange }: Props) => {
  const [contentTypes, setContentTypes] = useState([])
  const { currentGame } = useStore('currentGame')

  useEffect(() => {
    get('/files/content-types', { gameId: currentGame?.id }).then(({ data: contentTypes }) =>
      setContentTypes(contentTypes)
    )
  }, [])

  const options = [
    { value: 'image', label: 'Images', onClick: () => onChange('image') },
    { value: 'audio', label: 'Audio', onClick: () => onChange('audio') },
    ...contentTypes.map((contentType) => {
      return {
        value: contentType,
        label: contentType,
        onClick: () => onChange(contentType)
      }
    })
  ]

  const { PopupMenu, showMenu } = usePopupMenu({ options })

  return (
    <Fragment>
      <SelectChip
        label="Type"
        icon={FilterListIcon}
        value={options.find((option) => option.value === value)?.label}
        onClick={showMenu}
        onClear={() => onChange(undefined)}
      />
      {PopupMenu}
    </Fragment>
  )
}
