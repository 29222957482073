import { h } from 'preact'
import { routeTo } from '@sodra/prutt'
import { useCallback, useEffect } from 'preact/hooks'

import { useEffectSkipFirst } from '../../use-effect-skip-first'

import Spinner from '../Spinner'

import { useSSE } from 'lib'
import { ExportTarget } from 'src/types'
import { useFetchResult } from 'src/use-fetch-result'
import { useStore } from '../../store'
import EditGoogleDriveDetails from './EditGoogleDriveDetails'
import { EditWwiseDetails } from './EditWwiseDetails'
import { fetchLineFilters } from 'src/actions/line-filters'

type Props = {
  exportTargetId: string
}

const ExportTargetSettings = ({ exportTargetId }: Props) => {
  const { currentGame, lineFilters } = useStore('currentGame', 'lineFilters')

  useEffect(() => {
    fetchLineFilters()
  }, [])

  // Handle switch game
  useEffectSkipFirst(() => {
    routeTo('/export-targets', true)
  }, [currentGame!.id])

  const { data: exportTarget, refetch } = useFetchResult<ExportTarget>(
    `/export-targets/${exportTargetId}`,
    {}
  )

  useSSE({
    gameId: currentGame!.id,
    onExportTargetUpdated: useCallback(
      (updatedExportTargetId: string) => {
        if (updatedExportTargetId === exportTargetId) {
          refetch()
        }
      },
      [exportTargetId]
    )
  })

  if (!exportTarget || exportTarget?.id !== exportTargetId) {
    return <Spinner />
  }

  if (exportTarget.type === 'google-drive') {
    return <EditGoogleDriveDetails exportTarget={exportTarget} lineFilters={lineFilters ?? []} />
  }

  if (exportTarget.type === 'wwise') {
    return (
      <EditWwiseDetails
        exportTarget={exportTarget}
        gameId={currentGame!.id}
        lineFilters={lineFilters ?? []}
      />
    )
  }
}

export default ExportTargetSettings
