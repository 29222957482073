import { store } from '../store'
import { get, del, post } from '../api'

import { fetchGames } from './games'

export const fetchInvitations = async (params) => {
  let cancelled = false
  store.setState({ isFetchingInvitations: true })
  const gameId = store.getState().currentGame.id
  get(`/games/${gameId}/invitations`, params)
    .then(({ data: invitations }) => {
      if (!cancelled) {
        store.setState({ invitations, isFetchingInvitations: false })
      }
    })
    .catch((error) => {
      if (!cancelled) {
        store.setState({ error, isFetchingInvitations: false })
      }
    })
  return () => {
    store.setState({ isFetchingInvitations: false })
    cancelled = true
  }
}

export const deleteInvitation = async (invitationId) => {
  try {
    store.setState({ isDeletingInvitation: true })
    const gameId = store.getState().currentGame.id
    await del(`/invitations/${invitationId}`)
    const { data: invitations } = await get(`/games/${gameId}/invitations`)
    store.setState({ invitations, isDeletingInvitation: false })
    return true
  } catch (error) {
    store.setState({ error, isDeletingInvitation: false })
  }
}

export const acceptInvitation = async (invitationId, gameId, params) => {
  try {
    store.setState({ isAcceptingInvitation: true })
    const { data: currentUser } = await post(`/invitations/${invitationId}`, params)
    const { data: games } = await get(`/games`)
    const currentGame = games.find((game) => game.id === gameId)
    store.setState({ currentUser, games, currentGame, isAcceptingInvitation: false })
    return true
  } catch (error) {
    store.setState({ error, isAcceptingInvitation: false })
  }
}

export const createInvitation = async (params) => {
  try {
    store.setState({ isCreatingInvitation: true })
    const gameId = store.getState().currentGame.id
    await post(`/games/${gameId}/invitations`, params)
    const { data: invitations } = await get(`/games/${gameId}/invitations`)
    store.setState({ invitations, isCreatingInvitation: false })
    return true
  } catch (error) {
    store.setState({ error, isCreatingInvitation: false })
  }
}
