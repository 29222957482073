import { Link } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import { Block } from 'jsxstyle'

type Params = {
  billingAccountId: string
  orderId: string
}

export const CreditCardMissing = ({ billingAccountId, orderId }: Params) => {
  const params = new URLSearchParams({
    onSuccessUrl: `/orders/${orderId}/approve`
  })
  return (
    <Block>
      Your billing account is missing a credit card. Please{' '}
      <Link
        to={`/settings/billing/${billingAccountId}/add-credit-card?${params.toString()}`}
        onRoute={routeTo}
      >
        add a credit card
      </Link>{' '}
      and try again.
    </Block>
  )
}
