import { Fragment, h } from 'preact'
import { FilterListIcon, SelectChip } from '@sodra/bongo-ui'

import { usePopupMenu } from '../use-popup-menu'

const soundsLikeOptions = [
  { value: 'child', label: 'Child' },
  { value: 'teen', label: 'Teen' },
  { value: 'young-adult', label: 'Young adult' },
  { value: 'middle-aged', label: 'Middle aged' },
  { value: 'senior', label: 'Senior' }
]

const formatSoundsLike = (value = '') => {
  for (let option of soundsLikeOptions) {
    if (option.value === value) {
      return 'Sounds like ' + option.label.toLowerCase()
    }
  }
  return ''
}

type Props = {
  value?: string
  onChange: (value?: string) => void
}

export const AgeSelectChip = ({ value, onChange }: Props) => {
  const { PopupMenu, showMenu } = usePopupMenu({
    options: [
      { label: 'Sounds like', disabled: true },
      ...soundsLikeOptions.map((option) => {
        return {
          label: option.label,
          onClick: () => onChange(option.value)
        }
      })
    ]
  })

  return (
    <Fragment>
      <SelectChip
        label="Age"
        icon={FilterListIcon}
        value={formatSoundsLike(value)}
        onClick={showMenu}
        onClear={() => onChange()}
        whiteSpace="nowrap"
      />
      {PopupMenu}
    </Fragment>
  )
}
