import { confirm } from 'lib'

type Params = {
  title?: string
  message?: any
  confirmText?: string
}

export const confirmCreditCard = ({ title, message, confirmText }: Params = {}) =>
  confirm({
    title: title || 'Credit card required',
    message:
      message ||
      'To start booking voice actors please assign a credit card to your billing account.',
    confirmText: confirmText || 'Add credit card'
  })
