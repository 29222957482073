import { h, Fragment } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Button, P } from '@sodra/bongo-ui'
import { getFullText } from '../../text/get-full-text'
import { getIsPreviewNeeded, getPreview } from '../../text/get-preview'

export const CollapsableText = ({ text }) => {
  const [lines, setLines] = useState()
  const [collapsed, setCollapsed] = useState()

  useEffect(() => {
    const isPreviewNeeded = getIsPreviewNeeded(text)
    if (isPreviewNeeded) {
      setLines(getPreview(text))
      setCollapsed(true)
    } else {
      setLines(getFullText(text))
      setCollapsed(false)
    }
  }, [text])

  const handleExpand = () => {
    setLines(getFullText(text))
    setCollapsed(false)
  }

  return (
    <Fragment>
      <P margin="1rem 0">
        {lines &&
          lines.map((line, index) => {
            return (
              <Fragment>
                {index > 0 && <br />}
                {line}
              </Fragment>
            )
          })}
        {}
      </P>
      {collapsed && (
        <Button small margin="0 -10px" onClick={handleExpand}>
          Read more
        </Button>
      )}
    </Fragment>
  )
}
