import { Dialog, TextArea } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'
import { useState } from 'preact/hooks'
import { useStore } from '../../store'
import { createAPIKey } from '../../actions'

export const AddApiKey = () => {
  const [comment, setComment] = useState('')

  const { isCreatingAPIKey } = useStore('isCreatingAPIKey')

  const onClose = () => goBack('/settings/api-keys')

  const onCreate = async () => {
    if (await createAPIKey({ comment })) {
      onClose()
    }
  }

  return (
    <Dialog
      title="Create API key"
      onClose={onClose}
      actions={[
        { text: 'Create', onClick: onCreate, loading: isCreatingAPIKey },
        { text: 'Cancel', onClick: onClose }
      ]}
    >
      <TextArea
        width="100%"
        rows="3"
        label="Comment"
        placeholder="E.g what will this API key be used for?"
        value={comment}
        onInput={setComment}
      />
    </Dialog>
  )
}
