import { h } from 'preact'
import { useState } from 'preact/hooks'
import { BasicDialog, SelectChip } from '@sodra/bongo-ui'

import { useSession } from '../../use-session'
import { removeLinesFromSession, showSnackbar } from '../../actions'

import { SelectSession } from '../SelectSession'
import { routeTo } from '@sodra/prutt'
import { useStore } from '../../store'

type Props = {
  onClose: () => void
  onSuccess: () => void
  lineIds: string[]
}

export const RemoveFromSession = ({ onClose, onSuccess, lineIds = [] }: Props) => {
  const { isRemovingLinesFromSession } = useStore('isRemovingLinesFromSession')
  const { sessionId, session, setSession } = useSession()
  const [selectSessionVisible, setSelectSessionVisible] = useState(false)

  if (lineIds.length === 0) {
    onClose()
  }

  const handleSubmit = async () => {
    if (await removeLinesFromSession(sessionId, lineIds)) {
      showSnackbar('Session updated', {
        text: 'Open',
        onClick: () => routeTo(`/session/${sessionId}`)
      })
      if (onSuccess) {
        onSuccess()
      }
      if (onClose) {
        onClose()
      }
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      title="Remove lines from session"
      primaryActionText="Remove lines"
      primaryActionLoading={isRemovingLinesFromSession}
      primaryActionDisabled={!sessionId}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      {session && (
        <SelectChip
          value={session.scheduled}
          onClick={() => setSelectSessionVisible(true)}
          onClear={() => setSession()}
        />
      )}
      {!session && (
        <SelectChip label="Select Session" onClick={() => setSelectSessionVisible(true)} />
      )}
      {selectSessionVisible && (
        <SelectSession
          onClose={() => setSelectSessionVisible(false)}
          onSelect={(session) => {
            setSession(session)
            setSelectSessionVisible(false)
          }}
        />
      )}
    </BasicDialog>
  )
}
