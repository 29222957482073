import { store } from '../store'
import { get, post, patch, del, put } from '../api'

export const fetchCharacters = async (params) => {
  let cancelled = false
  store.setState({ isFetchingCharacters: true })
  const gameId = store.getState().currentGame.id
  get(`/games/${gameId}/characters`, params)
    .then(({ data: characters, meta: { total } }) => {
      if (!cancelled) {
        store.setState({ characters, totalCharacters: total, isFetchingCharacters: false })
      }
    })
    .catch((error) => {
      if (!cancelled) {
        store.setState({ error, isFetchingCharacters: false })
      }
    })
  return () => {
    store.setState({ isFetchingCharacters: false })
    cancelled = true
  }
}

export const fetchCharacter = async (characterId) => {
  try {
    store.setState({ isFetchingCharacter: true })
    const { data: character } = await get(`/characters/${characterId}`)
    store.setState({ character, isFetchingCharacter: false })
    return character
  } catch (error) {
    store.setState({ error, isFetchingCharacter: false })
  }
}

export const createCharacter = async (params) => {
  try {
    store.setState({ isCreatingCharacter: true })
    const gameId = store.getState().currentGame.id
    const { data: character } = await post(`/games/${gameId}/characters`, params)
    store.setState({ character, isCreatingCharacter: false })
    return character
  } catch (error) {
    store.setState({ error, isCreatingCharacter: false })
  }
}

export const cloneCharacter = async (params) => {
  try {
    store.setState({ isCloningCharacter: true })
    const characterId = store.getState().character.id
    const { data: character } = await post(`/characters/${characterId}/clone`, params)
    store.setState({ character, isCloningCharacter: false })
    return character
  } catch (error) {
    store.setState({ error, isCloningCharacter: false })
  }
}

export const updateCharacter = async (params) => {
  try {
    store.setState({ isUpdatingCharacter: true })
    const characterId = store.getState().character.id
    const { data: character } = await patch(`/characters/${characterId}`, params)
    store.setState({ character, isUpdatingCharacter: false })
    return character
  } catch (error) {
    store.setState({ error, isUpdatingCharacter: false })
  }
}

export const deleteCharacter = async () => {
  try {
    store.setState({ isDeletingCharacter: true })
    const characterId = store.getState().character.id
    await del(`/characters/${characterId}`)
    store.setState({ isDeletingCharacter: false })
    return true
  } catch (error) {
    store.setState({ error, isDeletingCharacter: false })
  }
}

export const createExampleLine = async (params) => {
  try {
    store.setState({ isCreatingExampleLine: true })
    const characterId = store.getState().character.id
    const { data: character } = await post(`/characters/${characterId}/example-lines`, params)
    store.setState({ character, isCreatingExampleLine: false })
    return character
  } catch (error) {
    store.setState({ error, isCreatingExampleLine: false })
  }
}

export const updateExampleLine = async (lineId, params) => {
  try {
    store.setState({ isUpdatingExampleLine: true })
    const characterId = store.getState().character.id
    const { data: character } = await patch(
      `/characters/${characterId}/example-lines/${lineId}`,
      params
    )
    store.setState({ character, isUpdatingExampleLine: false })
    return character
  } catch (error) {
    store.setState({ error, isUpdatingExampleLine: false })
  }
}

export const deleteExampleLine = async (lineId) => {
  try {
    store.setState({ isDeletingExampleLine: true })
    const characterId = store.getState().character.id
    const { data: character } = await del(`/characters/${characterId}/example-lines/${lineId}`)
    store.setState({ character, isDeletingExampleLine: false })
    return character
  } catch (error) {
    store.setState({ error, isDeletingExampleLine: false })
  }
}

export const addToShortlist = async (characterId, voiceId) => {
  try {
    store.setState({ isAddingToShortlist: true })
    const { data: character } = await put(`/characters/${characterId}/shortlist/${voiceId}`)
    store.setState({ character, isAddingToShortlist: false })
    return character
  } catch (error) {
    store.setState({ error, isAddingToShortlist: false })
  }
}

export const removeFromShortlist = async (characterId, voiceId) => {
  try {
    store.setState({ isRemovingFromShortlist: true })
    const { data: character } = await del(`/characters/${characterId}/shortlist/${voiceId}`)
    store.setState({ character, isRemovingFromShortlist: false })
    return character
  } catch (error) {
    store.setState({ error, isRemovingFromShortlist: false })
  }
}

export const updateShortlist = async (characterId, voiceId, params) => {
  try {
    const key = `${characterId}-${voiceId}`
    store.setState({ isUpdatingShortlist: key })
    const { data: character } = await patch(
      `/characters/${characterId}/shortlist/${voiceId}`,
      params
    )
    store.setState({ character, isUpdatingShortlist: null })
    return character
  } catch (error) {
    store.setState({ error, isUpdatingShortlist: null })
  }
}

export const generateCharacterAITakes = async (characterId, language) => {
  try {
    store.setState({ isGeneratingAITakes: true })
    await post(`/characters/${characterId}/ai-takes`, { language })
    store.setState({ isGeneratingAITakes: false })
    return true
  } catch (error) {
    store.setState({ error, isGeneratingAITakes: false })
    return false
  }
}
