import { Block } from 'jsxstyle/preact'
import { h } from 'preact'

export const HelpCustomFilenameFormat = ({ fontSize }: { fontSize?: string }) => {
  return (
    <Block color="var(--on-surface-light)" fontSize={fontSize ?? ''}>
      The custom filename format can be used when importing lines to automatically generate your
      filenames using the given format.
      <br />
      <br />
      You can use a mix of free text and subtitution variables to create your own custom filename
      format. The substitution variables will be dynamically replaced during import.
      <br />
      <br />
      <Block fontSize="12px">Example format:</Block>
      my_format_$CHARACTER_$SCENE_$COUNTER:COMBINED
      <br />
      <br />
      <Block fontSize="12px">Example generated filename:</Block>
      my_format_Hero_GameOver_000001
    </Block>
  )
}
