import { h } from 'preact'

import { Select, SpacerHorizontal, SpacerVertical, TextField } from '@sodra/bongo-ui'

import { TaxIdType, taxIdTypes } from './stripe-tax-id-types'
import { useEffect, useMemo, useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import { CountryFlag } from './CountryFlag'
import { ErrorMessage } from '../../../ErrorMessage'

export type Type = {
  countryCode: string
  type: string
}

const calcUniqueTypeId = (type?: Type) => {
  if (!type) {
    return
  }
  if (!type.countryCode || !type.type) {
    return
  }
  return `${type.countryCode.toLowerCase()}-${type.type.toLowerCase()}`
}

const findTaxIdType = (uniqueTypeId: string): TaxIdType | undefined => {
  return taxIdTypes.find((item) => {
    return calcUniqueTypeId({ countryCode: item.countryCode, type: item.type }) === uniqueTypeId
  })
}

const getOptions = (countryCode?: string) => {
  let types = taxIdTypes
  if (countryCode) {
    types = taxIdTypes.filter((type) => type.countryCode === countryCode)
    // NOTE: if no types are found, show all.
    if (types.length === 0) {
      types = taxIdTypes
    }
  }
  return types.map((item: TaxIdType) => ({
    value: calcUniqueTypeId({
      countryCode: item.countryCode,
      type: item.type
    }),
    text: item.humanReadableType,
    textWidth: '100px',
    metaText: item.country,
    metaTextAlign: 'left',
    metaTextWidth: '125px',
    keywords: [item.country, item.humanReadableType],
    visual: CountryFlag({ countryCode: item.countryCode })
  }))
}

const placeholder = {
  text: 'Tax id type'
}

const equal = (t1: Type | undefined, t2: Type | undefined) => {
  if (t1 === t2) {
    return true
  }
  if (t1 && !t2) {
    return false
  } else if (!t1 && t2) {
    return false
  } else if (t1 && t2) {
    return t1.countryCode === t2.countryCode && t1.type === t2.type
  }
}

type Props = {
  countryCode?: string // Limit options to the specified country code
  type: Type | undefined
  value: string | undefined
  onTypeChange: (type: Type | undefined) => void
  onValueChange: (value: string | undefined) => void
  onValueInput: (value: string | undefined) => void
  errorText: string | undefined
}

export const TaxIdInput = ({
  countryCode,
  type: initialType,
  value: initialValue,
  onTypeChange,
  onValueChange,
  onValueInput,
  errorText
}: Props) => {
  const [type, setType] = useState<Type | undefined>(initialType)
  const [value, setValue] = useState<string | undefined>(initialValue)
  const [selectedUniqueTypeId, setSelectedUniqueTypeId] = useState<string | undefined>(
    calcUniqueTypeId(initialType)
  )

  useEffect(() => {
    // Reset selected tax id type, if limited countryCode is not the same as the
    // currently selected tax id type countryCode.
    if (countryCode && type?.countryCode) {
      if (countryCode !== type?.countryCode) {
        onTypeChange(undefined)
      }
    }
  }, [countryCode])

  const options = getOptions(countryCode)

  useEffect(() => {
    if (!equal(initialType, type)) {
      setType(initialType)
      setSelectedUniqueTypeId(calcUniqueTypeId(initialType))
    }
    if (initialValue !== value) {
      setValue(initialValue && initialValue.trim() !== '' ? initialValue : undefined)
    }
  }, [initialType, initialValue])

  const handleOnTypeChange = useMemo(() => {
    return (uniqueTypeId: string) => {
      const taxIdType = findTaxIdType(uniqueTypeId)
      const type = taxIdType
        ? { countryCode: taxIdType.countryCode, type: taxIdType.type }
        : undefined
      setType(type)
      setSelectedUniqueTypeId(uniqueTypeId)
      if (onTypeChange) {
        onTypeChange(type)
      }
    }
  }, [onTypeChange])

  const handleOnValueInput = useMemo(() => {
    return (value: string | undefined) => {
      setValue(value)
      if (onValueInput) {
        onValueInput(value)
      }
    }
  }, [onValueInput])

  const handleOnValueChange = useMemo(() => {
    return (value: string | undefined) => {
      setValue(value)
      if (onValueChange) {
        onValueChange(value)
      }
    }
  }, [onValueChange])

  const taxIdExample = selectedUniqueTypeId
    ? findTaxIdType(selectedUniqueTypeId)?.example
    : undefined

  return (
    <>
      <Row alignItems="center">
        <Select
          value={selectedUniqueTypeId}
          options={options}
          placeholder={placeholder}
          onChange={handleOnTypeChange}
          height="54px"
          width="200px"
        />
        <SpacerHorizontal small />
        <TextField
          name="Tax id"
          value={value}
          onInput={handleOnValueInput}
          onChange={handleOnValueChange}
          placeholder={taxIdExample}
        />
      </Row>
      {errorText && (
        <Block padding="0 15px">
          <SpacerVertical tiny />
          <ErrorMessage>{errorText}</ErrorMessage>
        </Block>
      )}
    </>
  )
}
