import { useEffect } from 'preact/hooks'

//defaultValues = { page: 0, query: '', orderBy: 'name', sortOrder: 'asc'}

export const useUrlParams = (
  params: Record<string, any> = {},
  defaultValues: Record<string, any> = {}
) => {
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    for (let [name, value] of Object.entries(params)) {
      if (value === undefined || value === null || value === defaultValues[name]) {
        searchParams.delete(name)
      } else {
        searchParams.set(name, value)
      }
    }
    const searchParamsStr = searchParams.toString()

    /*
      setTimeout because Firefox and Chrome seems to handle history.back timing slightly differently.
    */
    setTimeout(() => {
      if (searchParamsStr) {
        history.replaceState(null, '', location.pathname + '?' + searchParamsStr)
      } else {
        history.replaceState(null, '', location.pathname)
      }
    }, 1)
  }, Object.values(params))
}
