export const formatAIVoiceType = (type: string) => {
  if (type === 'google') {
    return 'Google'
  }
  if (type === 'elevenlabs') {
    return 'ElevenLabs'
  }
  if (type === 'playht') {
    return 'Play.ht'
  }
  if (type === 'replica') {
    return 'Replica'
  }
  if (type === 'azuretts') {
    return 'Azure TTS'
  }
  return 'Unknown'
}
