import { h } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'
import { Block, Col, InlineBlock, Row } from 'jsxstyle/preact'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Link,
  NativeSelect,
  RadioButton,
  Select,
  SpacerHorizontal,
  SpacerVertical,
  TextField
} from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'

import { loadScript } from '../../load-script'
import { get, post } from '../../api'
import { createExportTarget } from '../../actions'

import Page from '../Page'

import { outputFiles } from './output-files'
import { loudnessOptions } from './loudness-options'

import dropbox from './dropbox.svg'
import googleDriveSvg from './google-drive.svg'
import oneDriveSvg from './one-drive.svg'
import githubSvg from './github.svg'
import wwiseSvg from './wwise.svg'
import { useStore } from '../../store'
import { FilenameExampleOptions, getFilenameExample } from './get-filename-example'
import { SubstitutionVariables } from '../ExportTarget/SubstitutionVariables'
import { ExportTargetProcessAudioTakeType, LineAttribute, LineFilter } from 'src/types'
import { TextFieldSuggest } from '../WwiseExport/TextFieldSuggest'
import { lineAttributeToVariable } from '../WwiseExport/substitution-variables'
import { WwiseSettings } from '../WwiseExport/WwiseSettings'
import { createWwiseExportTarget, defaultSettings } from '../WwiseExport/create-export-target'
import type { WwiseSettings as WwiseSettingsType } from '../WwiseExport/create-export-target'
import { fetchLineFilters } from 'src/actions/line-filters'

export const requiredScopes = ['https://www.googleapis.com/auth/drive.file']

declare var google: any
declare var gapi: any

const typeOptions = [
  { value: 'google-drive', label: 'Google Drive', picture: googleDriveSvg },
  // { value: 'one-drive', label: 'OneDrive', picture: oneDriveSvg },
  // { value: 'dropbox', label: 'Dropbox', picture: dropbox },
  // { value: 'github', label: 'GitHub', picture: githubSvg },
  {
    value: 'wwise',
    label: 'Wwise',
    picture: new URL('./wwise-logo-white.png', import.meta.url).href
  }
]

type TypeOptionProps = {
  label: string
  picture: string
  onClick: () => void
}

const TypeOption = ({ label, picture, onClick }: TypeOptionProps) => {
  return (
    <InlineBlock
      background="var(--container-background)"
      padding="20px"
      textAlign="center"
      cursor="pointer"
      border="solid 1px transparent"
      // @ts-ignore
      hoverBorder="solid 1px var(--accent)"
      boxSizing="border-box"
      margin="5px"
      props={{ onClick }}
    >
      <img width="80" height="80" src={picture} />
      <SpacerVertical />
      <Block>{label}</Block>
    </InlineBlock>
  )
}

type GoogleDriveTargetParams = {
  lineFilters: LineFilter[]
}
const GoogleDriveTarget = ({ lineFilters }: GoogleDriveTargetParams) => {
  const { currentGame, isCreatingExportTarget } = useStore('currentGame', 'isCreatingExportTarget')

  const [type, setType] = useState('')
  const [name, setName] = useState('')

  const [processAudio, setProcessAudio] = useState(false)
  const [autoSync, setAutoSync] = useState(false)

  const [folderId, setFolderId] = useState('')
  const [folderName, setFolderName] = useState('')
  const [folder, setFolder] = useState('$CHARACTER/$EVENT')
  const [exampleLine, setExampleLine] = useState<FilenameExampleOptions>()
  const [lineAttributes, setLineAttributes] = useState<LineAttribute[]>([])
  const [substitutionVariablesVisible, setSubstitutionVariablesVisible] = useState(false)

  const [accessToken, setAccessToken] = useState('')
  const [refreshToken, setRefreshToken] = useState('')

  const [pickerApiLoaded, setPickerApiLoaded] = useState(true)

  const [format, setFormat] = useState('mp3')
  const [bitrate, setBitrate] = useState('112')
  const [ending, setEnding] = useState('mp3')
  const [loudness, setLoudness] = useState('-16')
  const [silenceStart, setSilenceStart] = useState(0)
  const [silenceEnd, setSilenceEnd] = useState(0)
  const [processAudioTakeType, setProcessAudioTakeType] =
    useState<ExportTargetProcessAudioTakeType>('all')

  const [lineFilter, setLineFilter] = useState<LineFilter | undefined>(undefined)

  const formatOptions = Object.keys(outputFiles).map((value) => {
    const text = outputFiles[value].display_name
    return { value, label: text }
  })

  const bitrateOptions = outputFiles[format].bitrates.map((value: string, i: string) => {
    const text = outputFiles[format].bitrate_strings[i]
    return { value, label: text }
  })

  const endingOptions = outputFiles[format].endings.map((value: string) => {
    return { value, label: value }
  })

  useEffect(() => {
    async function init() {
      await loadScript('https://accounts.google.com/gsi/client')
      const client = google.accounts.oauth2.initCodeClient({
        client_id: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID,
        scope: requiredScopes.join(','),
        ux_mode: 'popup',
        callback: async (response: any) => {
          const {
            data: { accessToken, refreshToken }
          } = await post('/google-access-tokens', { code: response.code })
          setAccessToken(accessToken)
          setRefreshToken(refreshToken)
        }
      })
      client.requestCode()
      await loadScript('https://apis.google.com/js/api.js')
      gapi.load('picker', () => setPickerApiLoaded(true))
    }
    init()
  }, [])

  useEffect(() => {
    get(`/games/${currentGame?.id}/example-line`).then(({ data: exampleLine }) =>
      setExampleLine(exampleLine)
    )
    if (currentGame?.id) {
      get('/line-attributes', {
        gameId: currentGame.id
      }).then(({ data: lineAttributes }) => {
        setLineAttributes(lineAttributes)
      })
    } else {
      // Clear line attributes
      setLineAttributes([])
    }
  }, [currentGame?.id])

  useEffect(() => {
    setBitrate(outputFiles[format].default_bitrate)
    setEnding(endingOptions[0].value)
  }, [format])

  useEffect(() => {
    loadScript('https://accounts.google.com/gsi/client')
    loadScript('https://apis.google.com/js/api.js')
  }, [])

  let filenameExample = useMemo(() => {
    return getFilenameExample(
      folder,
      processAudio ? ending : 'wav',
      exampleLine ? { ...exampleLine } : undefined
    )
  }, [exampleLine, folder, processAudio, ending])

  const handleSubmit = async () => {
    const params = {
      type: 'google-drive',
      name,
      processAudio,
      autoSync,
      refreshToken,
      scopes: requiredScopes,
      folder,
      folderId,
      folderName,
      format,
      bitrate: Number(bitrate),
      loudness: Number(loudness),
      ending,
      silenceStart: Number(silenceStart),
      silenceEnd: Number(silenceEnd),
      processAudioTakeType,
      lineFilterId: lineFilter?.id
    }
    if (await createExportTarget(params)) {
      onClose()
    }
  }

  const onClose = () => goBack('/export-targets')

  const openPicker = () => {
    const pickerCallback = async (data: any) => {
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        const doc = data[google.picker.Response.DOCUMENTS][0]
        //const url = doc[google.picker.Document.URL]
        setFolderId(doc.id)
        setFolderName(doc.name)
      }
    }
    const docsView = new google.picker.DocsView()
      .setIncludeFolders(true)
      .setMimeTypes('application/vnd.google-apps.folder')
      .setSelectFolderEnabled(true)
      .setOwnedByMe(true)
    const sharedView = new google.picker.DocsView()
      .setIncludeFolders(true)
      .setMimeTypes('application/vnd.google-apps.folder')
      .setSelectFolderEnabled(true)
      .setEnableDrives(true)

    const picker = new google.picker.PickerBuilder()
      .addView(docsView)
      .addView(sharedView)
      .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
      .setOAuthToken(accessToken)
      .setDeveloperKey(import.meta.env.VITE_GOOGLE_API_KEY)
      .setCallback(pickerCallback)
      .build()
    picker.setVisible(true)
  }

  const showSubstitutionVariables = (e: Event) => {
    e.preventDefault()
    setSubstitutionVariablesVisible(true)
  }

  if (!refreshToken) {
    return (
      <Page title="Create export target" onBack={onClose}>
        <Col>Select Google account in the popup window</Col>
      </Page>
    )
  }

  const lineFilterOptions: { text: string; value: string }[] = [
    { text: '-- No line filter --', value: '' }
  ]
  lineFilterOptions.push(
    ...lineFilters
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((filter) => ({
        text: filter.name,
        value: filter.id
      }))
  )

  return (
    <Page title="Create export target" onBack={onClose}>
      <Form maxWidth="500px" onSubmit={handleSubmit}>
        <TextField width="100%" label="Name" value={name} onInput={setName} />
        <SpacerVertical />
        <Row alignItems="center">
          <TextField width="100%" label="Root folder" value={folderName || folderId} />
          <SpacerHorizontal />
          <Button disabled={!pickerApiLoaded} onClick={openPicker}>
            Browse
          </Button>
        </Row>
        <SpacerVertical />
        <TextFieldSuggest
          suggestions={[
            { text: '$GAME', secondaryText: 'Game name' },
            { text: '$CHARACTER', secondaryText: 'Character name' },
            { text: '$EVENT', secondaryText: 'Event name' },
            { text: '$SCENE', secondaryText: 'Scene name' },
            ...(lineAttributes?.map((attribute) => ({
              text: lineAttributeToVariable(attribute),
              secondaryText: attribute.description
            })) ?? [])
          ]}
          label="Folder structure"
          value={folder}
          onInput={setFolder}
        />
        <SpacerVertical small />
        <Block color="var(--on-surface-light)" fontSize="12px">
          Note: You can use{' '}
          <a href="" onClick={showSubstitutionVariables}>
            substitution variables
          </a>{' '}
          to build your folder structure.
          <SpacerVertical small />
          Example filename with current settings:
          <SpacerVertical small />
          {filenameExample}
        </Block>
        <SpacerVertical large />
        <Row>
          <Select
            label="Line filter"
            value={lineFilter?.id ?? ''}
            options={lineFilterOptions}
            onChange={(selectedFilterId: string) => {
              setLineFilter(
                selectedFilterId === ''
                  ? undefined
                  : lineFilters.find((filter) => filter.id === selectedFilterId)
              )
            }}
          />
        </Row>
        <SpacerVertical />
        <Block color="var(--on-surface-light)" fontSize="12px">
          Note: Select a line filter to export only a subset of all lines.
          <SpacerVertical small />
          Line filters are created from the{' '}
          <Link to="/lines" onRoute={routeTo}>
            Lines
          </Link>{' '}
          section.
        </Block>
        <SpacerVertical large />
        <Checkbox label="Auto sync (beta)" checked={autoSync} onChange={setAutoSync} />
        <SpacerVertical />
        <Checkbox label="Mastering" checked={processAudio} onChange={setProcessAudio} />
        <SpacerVertical />
        <Block paddingLeft="20px">
          <Block color={processAudio ? 'var(--on-surface-light)' : 'var(--on-surface-lighter)'}>
            Apply mastering to:
          </Block>
          <SpacerVertical tiny />
          <Row alignItems="center" paddingLeft="20px">
            <RadioButton
              checked={processAudioTakeType === 'all'}
              label="All takes"
              onChange={() => setProcessAudioTakeType('all')}
              disabled={!processAudio}
            />
            <SpacerHorizontal large />
            <RadioButton
              checked={processAudioTakeType === 'ai'}
              label="AI only"
              onChange={() => setProcessAudioTakeType('ai')}
              disabled={!processAudio}
            />
            <SpacerHorizontal large />
            <RadioButton
              checked={processAudioTakeType === 'non-ai'}
              label="Recorded only"
              onChange={() => setProcessAudioTakeType('non-ai')}
              disabled={!processAudio}
            />
          </Row>
          <SpacerVertical />
          <Row alignItems="center">
            <NativeSelect
              width="100%"
              label="Format"
              options={formatOptions}
              value={format}
              onChange={setFormat}
              disabled={!processAudio}
            />
            <SpacerHorizontal />
            <NativeSelect
              width="100%"
              label="Ending"
              options={endingOptions}
              value={ending}
              onChange={setEnding}
              disabled={!processAudio}
            />
          </Row>
          <SpacerVertical />
          <NativeSelect
            width="100%"
            label="Bitrate"
            options={bitrateOptions}
            value={bitrate}
            onChange={setBitrate}
            disabled={!processAudio}
          />
          <SpacerVertical />
          <NativeSelect
            width="100%"
            label="Loudness"
            options={loudnessOptions}
            value={loudness}
            onChange={setLoudness}
            disabled={!processAudio}
          />
          <SpacerVertical />
          <TextField
            width="100%"
            label="Add silence at start (ms)"
            value={silenceStart}
            onInput={setSilenceStart}
            disabled={!processAudio}
          />
          <SpacerVertical />
          <TextField
            width="100%"
            label="Add silence at end (ms)"
            value={silenceEnd}
            onInput={setSilenceEnd}
            disabled={!processAudio}
          />
        </Block>
        <SpacerVertical large />
        <Row alignItems="center">
          <Button type="submit" contained loading={isCreatingExportTarget}>
            Create
          </Button>
          <SpacerHorizontal />
          <Button onClick={onClose}>Cancel</Button>
        </Row>
      </Form>
      {substitutionVariablesVisible && (
        <SubstitutionVariables
          lineAttributes={lineAttributes}
          onSelect={(variable) => {
            setFolder((folder) => {
              // Skip separator if folder name is empty or ends with '/', '_' or '-'
              const separator = folder.length === 0 || '/_-'.includes(folder.slice(-1)) ? '' : '/'

              return folder + separator + variable
            })
            setSubstitutionVariablesVisible(false)
          }}
          onClose={() => setSubstitutionVariablesVisible(false)}
        />
      )}
    </Page>
  )
}

type WwiseTargetParams = {
  lineFilters: LineFilter[]
}

const WwiseTarget = ({ lineFilters }: WwiseTargetParams) => {
  const onClose = () => goBack('/export-targets')
  const { currentGame } = useStore('currentGame', 'isCreatingExportTarget')

  const [name, setName] = useState('')

  const [lineFilter, setLineFilter] = useState<LineFilter | undefined>(undefined)

  async function handleSave(settings: WwiseSettingsType) {
    await createWwiseExportTarget({ name, settings, lineFilter })
    onClose()
  }

  const lineFilterOptions: { text: string; value: string }[] = [
    { text: '-- No line filter --', value: '' }
  ]
  lineFilterOptions.push(
    ...lineFilters
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((filter) => ({
        text: filter.name,
        value: filter.id
      }))
  )

  return (
    <Page title="Create Wwise export target" onBack={onClose}>
      <Col gap="0px" maxWidth="700px">
        <TextField label="Name" value={name} onInput={setName} />
        <SpacerVertical large />
        <Row>
          <Select
            label="Line filter"
            value={lineFilter?.id ?? ''}
            options={lineFilterOptions}
            onChange={(selectedFilterId: string) => {
              setLineFilter(
                selectedFilterId === ''
                  ? undefined
                  : lineFilters.find((filter) => filter.id === selectedFilterId)
              )
            }}
          />
        </Row>
        <SpacerVertical small />
        <Block color="var(--on-surface-light)" fontSize="12px">
          Note: Select a line filter to export only a subset of all lines.
          <SpacerVertical small />
          Line filters are created from the{' '}
          <Link to="/lines" onRoute={routeTo}>
            Lines
          </Link>{' '}
          section.
        </Block>
        <SpacerVertical large />
        <Divider />
        <SpacerVertical large />
        <WwiseSettings
          {...defaultSettings}
          gameId={currentGame!.id}
          onSave={(settings) => {
            handleSave(settings)
          }}
        />
      </Col>
    </Page>
  )
}

export const CreateExportTarget = () => {
  const { lineFilters } = useStore('lineFilters')
  const [type, setType] = useState('')

  useEffect(() => {
    fetchLineFilters()
  }, [])

  const onClose = () => goBack('/export-targets')

  const handleSelectType = async (type: string) => {
    setType(type)
  }

  if (!type) {
    return (
      <Page title="Create export target" onBack={onClose}>
        {typeOptions.map((typeOption) => {
          return <TypeOption {...typeOption} onClick={() => handleSelectType(typeOption.value)} />
        })}
      </Page>
    )
  }

  if (type === 'google-drive') {
    return (
      <>
        <GoogleDriveTarget lineFilters={lineFilters ?? []} />
      </>
    )
  }

  if (type === 'wwise') {
    return (
      <>
        <WwiseTarget lineFilters={lineFilters ?? []} />
      </>
    )
  }
}

export default CreateExportTarget
