import { h } from 'preact'
import { IconButton, PlayIcon, StopIcon } from '@sodra/bongo-ui'
import { getCropperUrl } from '../../get-cropper-url'
import { File } from '../../types'
import { AudioPlayer, SingleAudioTrack, useAudioPlayer } from 'lib/src/hooks/use-audio-player'

type PlayAudioProps = {
  uri: string
  player: AudioPlayer
}

const PlayAudio = ({ uri, player }: PlayAudioProps) => {
  const { playerState, initPlayer, play, stopPlayer } = player

  const isPlayingCurrentTrack =
    playerState.value.isPlaying && playerState.value.currentTrackUri === uri

  const togglePlay = () => {
    if (isPlayingCurrentTrack) {
      stopPlayer()
    } else {
      initPlayer({ audioTracks: [{ uri }] })
      play({
        audioTrackUri: uri
      })
    }
  }

  return <IconButton icon={isPlayingCurrentTrack ? StopIcon : PlayIcon} onClick={togglePlay} />
}

type ThumbnailProps = {
  file: File
}

export const Thumbnail = ({ file }: ThumbnailProps) => {
  const { player } = useAudioPlayer()
  if (!player) {
    return null
  }

  if (file.contentType === 'image/svg+xml') {
    return <img style="max-width:30px;max-height:30px" src={file.uri} />
  }

  if (file.contentType.startsWith('image')) {
    const src = getCropperUrl({ url: file.uri, width: 30, height: 30, fit: 'contain' })
    return <img width="30" height="30" src={src} />
  }

  if (file.contentType.startsWith('audio')) {
    return <PlayAudio uri={file.uri} player={player} />
  }

  if (file.contentType === 'application/pdf') {
    return <embed src={file.uri} width="30px" height="30px" />
  }

  return null
}
