import { Fragment, h } from 'preact'
import { useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import {
  Button,
  DeleteIcon,
  EditIcon,
  Form,
  NativeSelect,
  PlayIcon,
  SpacerHorizontal,
  SpacerVertical,
  StopIcon,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'

import { showSnackbar, updateAIVoice } from '../../actions'
import { buildSpeakerUri } from '../../speak'

import Avatar from '../Avatar'
import Page from '../Page'
import MinMaxSlider from '../MinMaxSlider'
import { useTextToSpeech } from '../use-text-to-speech'
import { useStore } from '../../store'
import { useAudioPlayer } from 'lib/src/hooks/use-audio-player'

export const EditGoogleDetails = () => {
  const { aiVoice, isUpdatingAIVoice, isNarrow } = useStore(
    'aiVoice',
    'isUpdatingAIVoice',
    'isNarrow'
  )

  if (!aiVoice) {
    return null
  }

  const { player } = useAudioPlayer()
  if (!player) {
    return null
  }
  const { initPlayer, play, playerState, resetPlayer, stopPlayer } = player

  const [name, setName] = useState(aiVoice.name)
  const [description, setDescription] = useState(aiVoice.description)
  const [sampleText, setSampleText] = useState(aiVoice.sampleText)

  const {
    languageCode,
    setLanguageCode,
    gender,
    setGender,
    voiceName,
    setVoiceName,
    speakingRate,
    setSpeakingRate,
    pitch,
    setPitch,
    textToSpeechParams,
    languageCodeOptions,
    genderOptions,
    voiceNameOptions
  } = useTextToSpeech(aiVoice.data)

  const onBack = () => goBack('/ai-voices')

  const handleSave = async () => {
    const params = {
      name,
      description,
      sampleText,
      data: textToSpeechParams
    }

    if (await updateAIVoice(params)) {
      showSnackbar('AI voice details saved')
      onBack()
    }
  }

  const uri = buildSpeakerUri({
    aiVoiceId: aiVoice.id,
    text: sampleText || `My name is ${name}. ${description}`,
    extraParams: textToSpeechParams
  })

  return (
    <Page
      title={aiVoice.name}
      actions={[
        {
          label: 'Delete',
          icon: DeleteIcon,
          onClick: () => routeTo(`/ai-voice/${aiVoice.id}/delete`),
          visible: !aiVoice.shared
        }
      ]}
      onBack={onBack}
    >
      <Block maxWidth="500px">
        <Avatar
          actionIcon={aiVoice.shared ? undefined : EditIcon}
          onActionClick={
            aiVoice.shared ? undefined : () => routeTo(`/ai-voice/${aiVoice.id}/picture`)
          }
          size={150}
          src={aiVoice.picture}
          name={name}
        />
        <SpacerVertical />
        <Form onSubmit={handleSave} maxWidth="500px">
          <TextField width="100%" label="Name" value={name} onInput={setName} />
          <SpacerVertical />
          <TextArea
            autoHeight
            minRows={1}
            maxRows={5}
            width="100%"
            label="Description"
            value={description}
            onInput={setDescription}
          />
          <SpacerVertical />
          <TextArea
            autoHeight
            minRows={3}
            maxRows={5}
            width="100%"
            label="Sample text"
            value={sampleText}
            onInput={setSampleText}
          />
          <SpacerVertical />
          <>
            <Row alignItems="center">
              <NativeSelect
                flex="1"
                label="Language"
                options={languageCodeOptions}
                value={languageCode}
                onChange={setLanguageCode}
              />
              <SpacerHorizontal />
              <NativeSelect
                flex="1"
                label="Gender"
                options={genderOptions}
                value={gender}
                onChange={setGender}
              />
            </Row>
            <SpacerVertical />
            <NativeSelect
              width="100%"
              label="Voice name"
              options={voiceNameOptions}
              value={voiceName}
              onChange={setVoiceName}
            />
            <SpacerVertical />
            <Row alignItems={isNarrow ? '' : 'center'} flexDirection={isNarrow ? 'column' : 'row'}>
              <Block flex="1">
                <Row alignItems="center" justifyContent="space-between">
                  <Block>Speaking rate {speakingRate.toFixed(2)}</Block>
                  <Button onClick={() => setSpeakingRate(1)}>Reset</Button>
                </Row>
                <MinMaxSlider
                  width="100%"
                  value={speakingRate}
                  minValue={0.25}
                  maxValue={4}
                  middleValue={1}
                  onChange={setSpeakingRate}
                />
              </Block>
              {isNarrow ? <SpacerVertical /> : <SpacerHorizontal />}
              <Block flex="1">
                <Row alignItems="center" justifyContent="space-between">
                  <Block>Pitch {pitch.toFixed(2)}</Block>
                  <Button onClick={() => setPitch(0)}>Reset</Button>
                </Row>
                <MinMaxSlider
                  width="100%"
                  value={pitch}
                  minValue={-20}
                  maxValue={20}
                  onChange={setPitch}
                />
              </Block>
            </Row>
          </>
          <Row alignItems="center">
            <Button type="submit" contained loading={isUpdatingAIVoice} disabled={aiVoice.shared}>
              Save
            </Button>
            <SpacerHorizontal />
            <Button onClick={onBack}>Cancel</Button>
            <Block flex="1" />
            <Button
              type="button"
              role="button"
              icon={playerState.value.isPlaying ? StopIcon : PlayIcon}
              loading={playerState.value.isPlaying && playerState.value.loading}
              onClick={() => {
                if (playerState.value.isPlaying) {
                  stopPlayer()
                  resetPlayer()
                } else {
                  initPlayer({
                    audioTracks: [{ name, uri }]
                  })
                  play()
                }
              }}
            >
              Play sample
            </Button>
          </Row>
        </Form>
      </Block>
    </Page>
  )
}

export default EditGoogleDetails
