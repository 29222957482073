import { h } from 'preact'
import { Button, SpacerHorizontal, SpacerVertical } from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'
import { goBack, routeTo } from '@sodra/prutt'

import { showSnackbar, deleteAIVoice } from '../../actions'

import Page from '../Page'
import { useStore } from '../../store'

const DeleteAIVoice = () => {
  const { aiVoice, isDeletingAIVoice } = useStore('aiVoice', 'isDeletingAIVoice')
  const onBack = () => goBack('/ai-voices')

  const handleDelete = async () => {
    if (await deleteAIVoice()) {
      showSnackbar('AI voice deleted')
      routeTo('/ai-voices', true)
    }
  }

  return (
    <Page title={aiVoice!.name} onBack={onBack}>
      <Block>Delete AI voice?</Block>
      <SpacerVertical />
      <Row alignItems="center">
        <Button contained loading={isDeletingAIVoice} onClick={handleDelete}>
          Delete
        </Button>
        <SpacerHorizontal />
        <Button onClick={onBack}>Cancel</Button>
      </Row>
    </Page>
  )
}

export default DeleteAIVoice
