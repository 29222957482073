import { h, Fragment } from 'preact'
import { Block } from 'jsxstyle/preact'
import { CollapsableText } from './CollapsableText'

export const Credentials = ({ voice }) => {
  return (
    <Fragment>
      <Block fontSize="14px" marginBottom="1rem" color="var(--on-surface-light)">
        Credentials
      </Block>
      <CollapsableText text={voice.videoGameCredentials || '—'} />
    </Fragment>
  )
}
