import { h } from 'preact'
import { AddIcon, FilterListIcon, SelectChip, SpacerVertical } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'
import { useEffect, useState } from 'preact/hooks'

import { fetchEvents } from '../../actions'
import { useUrlParams } from '../../use-url-params'
import { useCharacter } from '../../use-character'

import Page from '../Page'
import Spinner from '../Spinner'
import SearchTextField from '../SearchTextField'
import Avatar from '../Avatar'

import EventsTable from './EventsTable'
import SelectCharacter from '../SelectCharacter'
import { useEffectSkipFirst } from '../../use-effect-skip-first'
import { Block, Col, Row } from 'jsxstyle/preact'
import { useStore } from '../../store'

const pageSize = 10

export const Events = () => {
  const {
    currentGame,
    events,
    totalEvents = 0,
    isFetchingEvents
  } = useStore('currentGame', 'events', 'totalEvents', 'isFetchingEvents')

  const params = new URLSearchParams(location.search)

  const [query, setQuery] = useState(params.get('query') || '')
  const [page, setPage] = useState(parseFloat(params.get('page') || '0'))
  const [orderBy, setOrderBy] = useState(params.get('orderBy') || 'name')
  const [sortOrder, setSortOrder] = useState(params.get('sortOrder') || 'asc')

  const { characterId, character, setCharacter, clearCharacter } = useCharacter(
    params.get('characterId') ?? undefined
  )

  useUrlParams(
    { page, query, orderBy, sortOrder, characterId },
    { page: 0, query: '', orderBy: 'name', sortOrder: 'asc' }
  )

  // Handle switch game
  useEffectSkipFirst(() => {
    clearCharacter()
  }, [currentGame!.id])

  useEffect(() => {
    fetchEvents({
      offset: page * pageSize,
      limit: pageSize,
      query,
      sortOrder,
      orderBy,
      characterId
    })
  }, [currentGame!.id, page, query, sortOrder, orderBy, characterId])

  const closeDialog = () => goBack('/events')

  if (!events) {
    return <Spinner />
  }

  if (characterId && !character) {
    return <Spinner />
  }

  return (
    <Page
      title="Events"
      actions={[
        {
          label: 'Create event',
          icon: AddIcon,
          onClick: () => routeTo('/create-event')
        }
      ]}
    >
      <Col height="100%">
        <SearchTextField
          value={query}
          onChange={(query) => {
            setQuery(query)
            setPage(0)
          }}
        />
        <SpacerVertical small />
        <Row alignItems="center" gap="10px">
          {character && (
            <SelectChip
              visual={<Avatar size={30} src={character.picture} name={character.name} />}
              value={character.name}
              onClick={() => routeTo('/events/select-character')}
              onClear={() => {
                clearCharacter()
                setPage(0)
              }}
            />
          )}
          {!character && (
            <SelectChip
              icon={FilterListIcon}
              label="Character"
              onClick={() => routeTo('/events/select-character')}
            />
          )}
        </Row>
        <SpacerVertical />
        <EventsTable
          loading={isFetchingEvents}
          events={events}
          total={totalEvents}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
      </Col>

      <Route
        path="/events/select-character"
        render={() => <SelectCharacter onClose={closeDialog} onSelect={setCharacter} />}
      />
    </Page>
  )
}

export default Events
