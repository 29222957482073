import {
  AddIcon,
  CheckmarkIcon,
  DataTable,
  ErrorIcon,
  IconButton,
  Link,
  MoreIcon,
  PlayIcon
} from '@sodra/bongo-ui'
import Page from '../Page'
import { routeWithSearchParams } from '../../route-with-search-params'
import { h } from 'preact'

import { usePopupMenu } from '../use-popup-menu'
import { useEffect, useState } from 'preact/hooks'
import { ImportSource } from '../../types'
import { routeTo } from '@sodra/prutt'
import { useStore } from '../../store'
import {
  deleteImportSource,
  fetchImportSources,
  showSnackbar,
  syncImportSource
} from '../../actions'

import googleSheet from './google-sheet.svg'
import { Block, Row } from 'jsxstyle/preact'
import { formatDuration } from '../../format-duration'
import { ConfirmDryRun, DryRunResult } from './ConfirmDryRun'

export const getStatus = (importSource: ImportSource) => {
  if (importSource.error) {
    return (
      <Row alignItems="center" gap="5px">
        <ErrorIcon fill="var(--error)" flexShrink="0" />
        <Block maxWidth="200px" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
          {importSource.error}
        </Block>
      </Row>
    )
  }
  if (importSource.completed) {
    return (
      <Row alignItems="center" gap="5px">
        <CheckmarkIcon fill="var(--ok)" /> Success
      </Row>
    )
  }
  if (importSource.started) {
    return (
      <Row alignItems="center" color="var(--on-surface-light)">
        {importSource.progress}%
      </Row>
    )
  }
  return (
    <Row alignItems="center" color="var(--on-surface-light)" whiteSpace="nowrap">
      Has not run yet
    </Row>
  )
}

export const ImportSources = () => {
  const {
    currentGame,
    importSources = [],
    isSyncingImportSource = false
  } = useStore('currentGame', 'importSources', 'isSyncingImportSource')

  const [selectedImportSource, setSelectedImportSource] = useState<ImportSource | undefined>()
  const [dryRunResult, setDryRunResult] = useState<DryRunResult | undefined>()

  useEffect(() => {
    if (currentGame) {
      fetchImportSources()
    }
  }, [currentGame?.id])

  const handleDeleteImportSource = async (importSource: ImportSource) => {
    if (await confirm(`Delete ${importSource.name}?`)) {
      if (await deleteImportSource(importSource.id)) {
        showSnackbar('Import source deleted')
      }
    }
  }

  const handleSyncNow = async (importSource: ImportSource, dryRun?: boolean) => {
    setSelectedImportSource(importSource)
    if (dryRun) {
      const dryRunResult = await syncImportSource(importSource.id, { dryRun: true })
      setDryRunResult(dryRunResult)
    } else {
      setDryRunResult(undefined)
      if (await syncImportSource(importSource.id, { dryRun: false })) {
        showSnackbar('Import source synced')
      }
    }
  }

  const { PopupMenu, showMenu } = usePopupMenu({
    options: [
      {
        label: 'Sync now',
        onClick: (importSource) => handleSyncNow(importSource, true),
        disabled: (importSource) =>
          selectedImportSource?.id === importSource.id && isSyncingImportSource
      },
      { label: 'Edit', onClick: (importSource) => routeTo(`/import-source/${importSource.id}`) },
      { label: 'Delete', onClick: handleDeleteImportSource }
    ]
  })

  const header = [
    { label: 'Type' },
    { label: 'Name' },
    { label: 'Sync now' },
    { label: 'Status of last sync' },
    { label: 'Last sync' },
    { label: 'Logs' },
    { label: 'Actions' }
  ]

  const body = importSources.map((importSource) => {
    return [
      <Block>
        <img width="30" height="30" src={googleSheet} />
      </Block>,
      <Link to={`/import-source/${importSource.id}`} onRoute={routeTo}>
        {importSource.name}
      </Link>,
      <IconButton
        icon={PlayIcon}
        onClick={() => handleSyncNow(importSource, true)}
        loading={isSyncingImportSource && importSource.id === selectedImportSource?.id}
      />,
      <Block>{getStatus(importSource)}</Block>,
      <Block whiteSpace="nowrap">
        {importSource.started ? formatDuration(importSource.started) : ''}
      </Block>,
      <Link to={`/import-source/${importSource.id}/logs`} onRoute={routeTo}>
        See logs
      </Link>,
      <IconButton icon={MoreIcon} onClick={(e: BuiClickEvent) => showMenu(e, importSource)} />
    ]
  })

  return (
    <Page
      title="Import Sources"
      actions={[
        {
          label: 'Create source',
          icon: AddIcon,
          onClick: () => routeWithSearchParams('/create-import-source')
        }
      ]}
    >
      <DataTable header={header} body={body} emptyText="No import sources found" />
      {PopupMenu}
      {dryRunResult && selectedImportSource && (
        <ConfirmDryRun
          generateAiTakesForNewLines={selectedImportSource.generateAiTakesForNewLines}
          generateAiTakesForUpdatedLines={selectedImportSource.generateAiTakesForUpdatedLines}
          result={dryRunResult}
          onConfirm={() => handleSyncNow(selectedImportSource)}
          onCancel={() => setDryRunResult(undefined)}
        />
      )}
    </Page>
  )
}
