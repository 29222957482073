import { h } from 'preact'
import { useState } from 'preact/hooks'
import { EditIcon, Form, SpacerVertical, TextArea, TextField } from '@sodra/bongo-ui'

import { createScene } from '../../actions'

import LargeDialog from '../LargeDialog'
import Avatar from '../Avatar'
import PictureDialog from '../PictureDialog'
import { useStore } from '../../store'
import { Scene } from '../../types'

type Props = {
  onClose: () => void
  onCreate: (scene: Scene) => void
}

export const CreateScene = ({ onClose, onCreate }: Props) => {
  const { isCreatingScene } = useStore('isCreatingScene')

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [picture, setPicture] = useState('')

  const [pictureDialogVisible, setPictureDialogVisible] = useState(false)

  const handleSubmit = async () => {
    const scene = await createScene({
      name,
      description,
      picture
    })

    if (scene) {
      if (onCreate) {
        onCreate(scene)
      }
      onClose()
    }
  }

  return (
    <LargeDialog
      large
      title="Create scene"
      onClose={onClose}
      primaryActionText="Create"
      primaryActionLoading={isCreatingScene}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <Avatar
          actionIcon={EditIcon}
          onActionClick={() => setPictureDialogVisible(true)}
          size={150}
          src={picture}
          name={name}
        />
        <SpacerVertical />
        <TextField autocomplete="off" width="100%" label="Name" value={name} onInput={setName} />
        <SpacerVertical />
        <TextArea
          autocomplete="off"
          width="100%"
          label="Description"
          value={description}
          onInput={setDescription}
        />
      </Form>
      {pictureDialogVisible && (
        <PictureDialog
          title="Scene picture"
          url={picture}
          name={name}
          onUpdate={(url: string) => {
            setPicture(url)
            return true
          }}
          onRemove={() => {
            setPicture('')
            return true
          }}
          onClose={() => setPictureDialogVisible(false)}
        />
      )}
    </LargeDialog>
  )
}

export default CreateScene
