import { Route, goBack, routeTo } from '@sodra/prutt'
import { Block, Row } from 'jsxstyle/preact'
import {
  AddIcon,
  ContentCopyIcon,
  DataTable,
  DeleteIcon,
  EditIcon,
  EyeIcon,
  IconButton
} from '@sodra/bongo-ui'
import dateFormat from 'dateformat'
import Page from '../Page'
import { useEffect, useState } from 'preact/hooks'
import { useStore } from '../../store'
import { deleteAPIKey, fetchAPIKeys, showSnackbar } from '../../actions'
import Spinner from '../Spinner'
import { formatDuration } from '../../format-duration'
import { AddApiKey } from './AddApiKey'
import { confirm } from 'lib'
import { EditApiKey } from './EditApiKey'

export const ApiKeys = () => {
  const onBack = () => goBack('/settings')

  const {
    currentGame,
    apiKeys = [],
    isFetchingAPIKeys
  } = useStore('currentGame', 'apiKeys', 'isFetchingAPIKeys')

  const [visibleAPIKeys, setVisibleAPiKeys] = useState<string[]>([])
  const [editApiKeyId, setEditApiKeyId] = useState<string | undefined>()

  const toggleVisible = (apiKeyId: string) => {
    setVisibleAPiKeys((visible) =>
      visible.includes(apiKeyId) ? visible.filter((id) => id !== apiKeyId) : [...visible, apiKeyId]
    )
  }

  const handleDelete = async (apiKeyId: string) => {
    if (await confirm('Delete API key?')) {
      deleteAPIKey(apiKeyId)
    }
  }

  useEffect(() => {
    fetchAPIKeys()
  }, [currentGame])

  const header = [
    { label: 'API key' },
    { label: 'Comment' },
    { label: 'Created' },
    { label: 'Last used' },
    { label: 'Delete' }
  ]

  const body = apiKeys.map((apiKey) => {
    const isVisible = visibleAPIKeys.includes(apiKey.id)
    return [
      <Row alignItems="center" gap="5px">
        <Block fontFamily="monospace" whiteSpace="nowrap">
          {isVisible ? apiKey.id : apiKey.id.replace(/\w/g, '●')}
        </Block>
        <Block flexShrink={0}>
          <IconButton icon={EyeIcon} onClick={() => toggleVisible(apiKey.id)} />
          <IconButton
            icon={ContentCopyIcon}
            onClick={() =>
              navigator.clipboard.writeText(apiKey.id).then(() => showSnackbar('API key copied'))
            }
          />
        </Block>
      </Row>,
      <Row alignItems="center" gap="5px">
        {apiKey.comment}
        <Block flexShrink={0}>
          <IconButton icon={EditIcon} onClick={() => setEditApiKeyId(apiKey.id)} />
        </Block>
      </Row>,
      <Block whiteSpace="nowrap">{dateFormat(apiKey.created, 'yyyy-mm-dd HH:MM')}</Block>,
      <Block whiteSpace="nowrap">{apiKey.lastUsed ? formatDuration(apiKey.lastUsed) : '–'}</Block>,
      <IconButton icon={DeleteIcon} onClick={() => handleDelete(apiKey.id)} />
    ]
  })

  return (
    <Page
      title="API keys"
      onBack={onBack}
      actions={[
        {
          label: 'Add',
          icon: AddIcon,
          link: { href: '/settings/api-keys/add-api-key', onRoute: routeTo }
        }
      ]}
    >
      {isFetchingAPIKeys && <Spinner />}
      {!isFetchingAPIKeys && apiKeys.length === 0 && (
        <Block padding="20px" color="var(--on-surface-light)">
          No api keys found
        </Block>
      )}
      {apiKeys.length > 0 && <DataTable header={header} body={body} />}
      <Route path="/settings/api-keys/add-api-key" component={AddApiKey} />
      {editApiKeyId && (
        <EditApiKey apiKeyId={editApiKeyId} onClose={() => setEditApiKeyId(undefined)} />
      )}
    </Page>
  )
}
