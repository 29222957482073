import { h, Fragment } from 'preact'

import { useEffect, useState } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'
import {
  Button,
  Checkbox,
  Form,
  Link,
  P,
  ProgressCircular,
  Select,
  SelectChip,
  SpacerHorizontal,
  SpacerVertical,
  TextArea,
  TextField,
  VoiceIcon
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { deleteSession, setError, showSnackbar, updateGame, updateSession } from '../../actions'

import { Avatar } from '../Avatar'
import { SelectVoice } from '../CreateSession/SelectVoice'
import { isValidUrl } from '../../validate-url'
import { urlSearchParams } from '../../url-search-params'
import { useVoice } from '../../use-voice'
import { DateAndTimeInput } from '../DateAndTimeInput'
import { ErrorMessage } from '../ErrorMessage'
import { SessionFee } from '../SessionFee'
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog'
import { get } from '../../api'
import { useStore } from '../../store'
import { deleteSearchParam, setSearchParam } from '../../delete-search-param'
import { isEqual, roundToNearestMinutes } from 'date-fns'
import { confirmBillingAccount } from '../confirmBillingAccount'
import { confirmVocalStress } from '../confirmVocalStress'
import { formatLanguage } from '../../format-language'
import { SessionStartEnd } from '../CreateSession/SessionStartEnd'
import { formatCurrency, formatScheduled } from 'lib'
import AuditionDetails, { AuditionDetailsSubmitParams } from '../AuditionDetails'
import { BuyoutInfo } from '../BuyoutInfo'
import { Order } from '../../types'
import { confirmCreditCard } from '../confirmCreditCard'
import {
  calcBuyoutOrderDetails,
  calcSessionOrderDetails,
  getOrderDetails
} from '../CreateSession/calc-session-order-details'
import { confirmPurchase } from '../confirmPurchase'
import { fetchBillingAccounts } from 'src/actions/billing-accounts'
import SelectBillingAccount from '../SelectBillingAccount'
import { confirmSelectBillingAccount } from '../confirmSelectBillingAccount'

export const EditDetails = () => {
  const { session, isUpdatingSession, currentGame, config, billingAccounts } = useStore(
    'session',
    'isUpdatingSession',
    'currentGame',
    'config',
    'billingAccounts'
  )

  const [description, setDescription] = useState(session?.description)
  const [scheduled, setScheduled] = useState(
    roundToNearestMinutes(new Date(session?.scheduled ?? new Date()), { nearestTo: 15 })
  )
  const [scheduledError, setScheduledError] = useState('')
  const [duration, setDuration] = useState<number>(session?.duration ?? 1)
  const [durationError, setDurationError] = useState('')
  const [agreementsAccepted, setAgreementsAccepted] = useState<boolean>(
    session?.voice !== undefined
  )
  const [agreementError, setAgreementError] = useState('')
  const [hasChanges, setHasChanges] = useState(false)
  const [hasChangesThatRequiresAccept, setHasChangesThatRequireAccept] = useState(false)

  const params = urlSearchParams()
  const { voice, setVoice, voiceId, setVoiceId } = useVoice(
    session?.voice?.id || params.get('voiceId')
  )
  //const [voice, setVoice] = useState<Voice | undefined>(session?.voice)

  const [meetingLink, setMeetingLink] = useState(session?.meetingLink || '')
  const [meetingLinkError, setMeetingLinkError] = useState('')
  const [vocalStress, setVocalStress] = useState(session?.vocalStress || false)
  const [profanity, setProfanity] = useState(session?.profanity || false)
  const [sensitiveContent, setSensitiveContent] = useState(session?.sensitiveContent || false)

  const [language, setLanguage] = useState<string | null>(session?.language || null)

  const prevScheduled = session?.scheduled ? new Date(session?.scheduled) : undefined

  const [isFetchingBuyoutOrder, setIsFetchingBuyoutOrder] = useState(false)
  const [buyoutOrder, setBuyoutOrder] = useState<Order | undefined>()

  const [showSelectBillingAccountDialogType, setShowSelectBillingAccountDialogType] = useState<
    'select-voice' | 'submit' | undefined
  >(undefined)

  useEffect(() => {
    fetchBillingAccounts()
  }, [])

  useEffect(() => {
    if (!session) {
      return
    }

    const hasChangesThatRequiresAccept =
      (prevScheduled && !isEqual(prevScheduled, scheduled)) ||
      session.duration !== duration ||
      session.vocalStress !== vocalStress ||
      session.profanity !== profanity ||
      session.sensitiveContent !== sensitiveContent ||
      voiceId !== session.voice?.id

    const hasChanges =
      hasChangesThatRequiresAccept ||
      meetingLink !== session.meetingLink ||
      description !== session.description

    setHasChanges(hasChanges)
    setHasChangesThatRequireAccept(hasChangesThatRequiresAccept)
  }, [
    session,
    voiceId,
    scheduled,
    duration,
    vocalStress,
    profanity,
    sensitiveContent,
    description,
    meetingLink
  ])

  useEffect(() => {
    if (hasChangesThatRequiresAccept) {
      setAgreementsAccepted(false)
    }
  }, [hasChangesThatRequiresAccept])

  useEffect(() => {
    setBuyoutOrder(undefined)
    if (currentGame && voiceId) {
      setIsFetchingBuyoutOrder(true)
      get(`/orders/buyout`, { gameId: currentGame.id, voiceId })
        .then(({ data: order }) => setBuyoutOrder(order))
        .catch((error) => () => {
          // Nothing
        })
        .finally(() => setIsFetchingBuyoutOrder(false))
    }
  }, [currentGame, voiceId])

  const link = `${import.meta.env.VITE_REC_URL}/${session?.shortId}`

  // restore=true, if the session state should be loaded from local storage
  const restore = params.get('restore')

  // submit=true, if a credit card has been added and the session should be
  // automatically submitted. NOTE: The session agreements needs to be approved.
  const doSubmit = params.get('submit')

  useEffect(() => {
    if (restore && currentGame) {
      // Restore session state from local storage
      const sessionJSON = localStorage.getItem(`speechless:${currentGame.id}:new-session`)
      if (sessionJSON) {
        const session = JSON.parse(sessionJSON)
        setDescription(session.description)
        if (session.scheduled) {
          setScheduled(new Date(session.scheduled))
        }
        setDuration(session.duration)
        setMeetingLink(session.meetingLink)
        setVocalStress(session.vocalStress)
        setProfanity(session.profanity)
        setSensitiveContent(session.sensitiveContent)
        setLanguage(session.language)
      }
      // Remove restore from query string
      deleteSearchParam('restore')
    }
  }, [restore, currentGame])

  const removeSessionState = () => {
    if (currentGame) {
      // Save session state to local storage
      localStorage.removeItem(`speechless:${currentGame.id}:new-session`)
    }
  }

  const saveSessionState = () => {
    if (currentGame) {
      // Save session state to local storage
      localStorage.setItem(
        `speechless:${currentGame.id}:new-session`,
        JSON.stringify({
          voiceId,
          description,
          scheduled,
          duration,
          meetingLink,
          vocalStress,
          profanity,
          sensitiveContent,
          language
        })
      )
    }
  }

  const onBack = () => goBack('/sessions')

  const handleDelete = async () => {
    if (await deleteSession()) {
      routeTo('/sessions')
      showSnackbar('Session deleted')
    }
  }

  useEffect(() => {
    if (scheduled) {
      setScheduledError('')
    }
  }, [scheduled])

  useEffect(() => {
    if (duration) {
      setDurationError('')
    }
  }, [duration])

  useEffect(() => {
    if (isValidUrl(meetingLink, { allowEmpty: true })) {
      setMeetingLinkError('')
    }
  }, [meetingLink])

  useEffect(() => {
    if (agreementsAccepted) {
      setAgreementError('')
    }
  }, [agreementsAccepted])

  const getCreateBillingAccountUrlSearchParams = (
    context: 'select-voice' | 'submit'
  ): URLSearchParams => {
    if (context === 'select-voice') {
      return new URLSearchParams({
        onSuccessUrl: `/session/${session?.id}/select-voice?restore=true`
      })
    } else if (context === 'submit') {
      return new URLSearchParams({
        onSuccessUrl: `/session/${session?.id}/?voiceId=${voiceId}&restore=true&submit=true`
      })
    }

    return new URLSearchParams()
  }

  const createBillingAccount = ({ urlSearchParams }: { urlSearchParams: URLSearchParams }) => {
    // Save session in local storage before routing – to be restored later
    saveSessionState()
    routeTo(`/settings/billing/add-billing-account?${urlSearchParams.toString()}`)
  }

  const handleSelectVoiceClick = async () => {
    const billingAccount = currentGame?.billingAccount

    // Verify billing account
    if (!billingAccount) {
      if (await confirmBillingAccount()) {
        if (billingAccounts?.length) {
          setShowSelectBillingAccountDialogType('select-voice')
        } else {
          createBillingAccount({
            urlSearchParams: getCreateBillingAccountUrlSearchParams('select-voice')
          })
        }
      }
      return
    }

    routeTo(`/session/${session!.id}/select-voice`)
  }

  const saveSession = async (
    auditionDetails?: AuditionDetailsSubmitParams,
    shareCharacterSheet?: boolean
  ) => {
    // Add time zone to date
    const scheduledDate = scheduled ? new Date(scheduled) : undefined

    const result = await updateSession({
      description,
      scheduled: scheduledDate,
      duration,
      voiceId: voice?.id || null,
      meetingLink,
      vocalStress,
      profanity,
      sensitiveContent,
      language,
      auditionDetails,
      shareCharacterSheet
    })

    if (result) {
      removeSessionState()

      showSnackbar('Session details saved')
      //const { chatId, chatWasCreated, session } = result
      // if (chatWasCreated && chatId) {
      //   routeTo(`/chats/${chatId}`)
      // } else {
      //   onBack()
      // }
      onBack()
    }
  }

  const handleSave = async () => {
    let errorId
    if (voiceId && scheduled && !agreementsAccepted) {
      setAgreementError('Please accept to continue')
      errorId = 'agreement'
    }
    if (!scheduled) {
      setScheduledError('Please enter a date and time')
      errorId = 'schedule'
    }
    if (!duration) {
      setDurationError('Please enter duration')
      errorId = 'duration'
    }
    if (!isValidUrl(meetingLink, { allowEmpty: true })) {
      setMeetingLinkError('Please enter a valid meeting link URL')
      errorId = 'meetingLink'
    }
    if (errorId) {
      const el = document.querySelector(`#${errorId}`)
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      return
    }

    const billingAccount = currentGame?.billingAccount

    // Verify billing account
    if (voiceId && !billingAccount) {
      if (await confirmBillingAccount()) {
        if (billingAccounts?.length) {
          setShowSelectBillingAccountDialogType('submit')
        } else {
          createBillingAccount({
            urlSearchParams: getCreateBillingAccountUrlSearchParams('submit')
          })
        }
      }
      return
    }

    // Verify credit card
    if (voiceId && billingAccount && !billingAccount.invoice && !billingAccount.hasCreditCard) {
      if (await confirmCreditCard()) {
        // Save session in local storage – to be restored
        saveSessionState()
        const params = new URLSearchParams({
          onSuccessUrl: `/session/${session?.id}/?game=${currentGame.shortId}&voiceId=${voiceId}&restore=true&submit=true`
        })
        routeTo(`/settings/billing/${billingAccount.id}/add-credit-card?${params.toString()}`)
      }
      return
    }

    // Confirm vocal stress
    const confirmVocalStressNeeded = vocalStress && duration! > 2 && !session?.voiceAccepted
    const proceedVocalStress = !confirmVocalStressNeeded ? true : await confirmVocalStress()

    // Calculate amount for confirm purchase
    const budgetSegment = currentGame!.budgetSegment

    // NOTE: currentGame.billingAccount has property country
    const includeVAT = billingAccount?.country === 'Sweden'

    const sessionOrderDetails = calcSessionOrderDetails({
      budgetSegment,
      duration,
      includeVAT,
      config: config!
    })

    const buyoutOrderDetails = buyoutOrder
      ? getOrderDetails(buyoutOrder)
      : calcBuyoutOrderDetails({
          budgetSegment,
          includeVAT,
          config: config!
        })

    let totalIncVAT = sessionOrderDetails.totalIncVAT
    if (!buyoutOrder) {
      totalIncVAT += buyoutOrderDetails.totalIncVAT
    }

    if (voiceId && !billingAccount) {
      setError({ message: 'Billing account missing. Please try again.' })
      throw new Error('Billing account missing')
    }

    // Confirm purcase
    const hasAssignedVoice = Boolean(voiceId && !session?.voice)
    const hasDurationChanged = session!.duration !== duration
    const hasSessionCostChanged = hasDurationChanged || hasAssignedVoice
    const isConfirmPurchaseNeeded =
      billingAccount && voiceId && hasSessionCostChanged && !session?.voiceAccepted

    const proceedPurchase = !isConfirmPurchaseNeeded
      ? true
      : await confirmPurchase({
          totalIncVAT,
          billingAccount,
          secondaryMessage: !billingAccount.invoice
            ? `We will reserve ${formatCurrency(
                totalIncVAT
              )} on your card. You have the right to cancel the session until 24 hours before it starts.
          24 hours before the session starts we will debit your card`
            : 'You have the right to cancel the session until 24 hours before it starts.'
        })

    if (proceedVocalStress && proceedPurchase) {
      if (voiceId) {
        // If no chat exists, make sure to get audition details.
        // Before creating session and chat.
        const { data: chatId } = await get(`/chats/get-chat-id`, {
          gameId: currentGame?.id,
          voiceId
        })
        if (chatId) {
          saveSession()
        } else {
          // Ask for audition details before creating chat and saving session
          routeTo(`/session/${session?.id}/audition-details`)
        }
      } else {
        saveSession()
      }
    }
  }

  const checkboxVisible = !!(voice && duration && scheduled)
  const schedulingDisabled = !!(session?.voiceAccepted || session?.voiceDeclined)

  const isLocalized = currentGame?.secondaryLanguages && currentGame.secondaryLanguages.length > 0

  const mapLangCode = (langCode: string) => {
    return {
      value: langCode,
      text: `${formatLanguage(langCode)}${
        langCode === currentGame!.primaryLanguage ? ' (Primary)' : ''
      }`
    }
  }

  const languageOptions: { value?: string; text: string }[] = [...currentGame!.secondaryLanguages!]
    .map(mapLangCode)
    .sort((option1, option2) => option1.text.localeCompare(option2.text))
  languageOptions.unshift(
    { value: 'unspecified', text: '-- No specific language --' },
    mapLangCode(currentGame!.primaryLanguage)
  )

  const selectLanguage = (language: string) => {
    setLanguage(language === 'unspecified' ? null : language)
  }

  useEffect(() => {
    if (doSubmit && currentGame && config) {
      handleSave()
    }
  }, [doSubmit, currentGame, config])

  if (!currentGame || !config) {
    return <ProgressCircular />
  }

  const contentFlags = (
    <>
      {session?.voiceAccepted && profanity && (
        <P>
          Any anticipated profanity or offensive language: <strong>Yes</strong>
        </P>
      )}
      {!session?.voiceAccepted && (
        <Row alignItems="center">
          <Checkbox
            label="Any anticipated profanity or offensive language"
            checked={profanity}
            onChange={setProfanity}
          />
        </Row>
      )}
      {session?.voiceAccepted && sensitiveContent && (
        <P>
          Any content of religious, sexual, racially sensitive, gender sensitive, potentially
          violent that the artist may be required to voice: <strong>Yes</strong>
        </P>
      )}
      {!session?.voiceAccepted && (
        <Row alignItems="center">
          <Checkbox
            disabled={session?.voiceAccepted}
            label="Any content of religious, sexual, racially sensitive, gender sensitive, potentially violent that the artist may be required to voice"
            checked={sensitiveContent}
            onChange={setSensitiveContent}
          />
        </Row>
      )}
      {session?.voiceAccepted && vocalStress && (
        <P>
          Any anticipated vocal stress: <strong>Yes</strong>{' '}
          <Link
            to="https://www.britishvoiceassociation.org.uk/voicecare_stress-emotion-voice.htm"
            target="_blank"
          >
            Read more
          </Link>
        </P>
      )}
      {!session?.voiceAccepted && (
        <Row alignItems="center">
          <Checkbox
            disabled={session?.voiceAccepted}
            label="Any anticipated vocal stress"
            checked={vocalStress}
            onChange={setVocalStress}
          />
          <SpacerHorizontal />
          <Link
            to="https://www.britishvoiceassociation.org.uk/voicecare_stress-emotion-voice.htm"
            target="_blank"
          >
            Read more
          </Link>
        </Row>
      )}
    </>
  )

  return (
    <>
      <Form onSubmit={handleSave}>
        {session?.voice && voice && (
          <>
            {!session.voiceAccepted && !session.voiceDeclined && !session.passedDue && (
              <>
                <Row alignItems="center" gap="10px">
                  <Block color="var(--warning)">
                    {voice.name} has not accepted this session yet.
                  </Block>
                </Row>
                <SpacerVertical />
              </>
            )}
            <Row alignItems="center" gap="10px">
              <Avatar src={voice.picture} name={voice.name} />
              <Col>
                <Block>{voice.name}</Block>
                <Block fontSize="14px">
                  <Link onRoute={routeTo} to={`/voice/${voice.id}`}>
                    view
                  </Link>
                </Block>
              </Col>
            </Row>
          </>
        )}
        {!session?.voice && !voice && (
          <SelectChip icon={VoiceIcon} label="Voice actor" onClick={handleSelectVoiceClick} />
        )}
        {!session?.voice && voice && (
          <Row alignItems="center" gap="8px">
            <Block>
              <SelectChip
                visual={<Avatar size={30} src={voice.picture} name={voice.name} />}
                value={voice.name}
                onClick={handleSelectVoiceClick}
                onClear={() => {
                  deleteSearchParam('voiceId')
                  setVoice(undefined)
                }}
              />
            </Block>
            <Block fontSize="14px">
              <Link onRoute={routeTo} to={`/voice/${voice.id}`}>
                view
              </Link>
            </Block>
          </Row>
        )}
        <SpacerVertical />
        <Row gap="40px">
          <Block minWidth="450px" maxWidth="700px">
            <Row alignItems="center">
              <TextField
                autocomplete="off"
                width="100%"
                label="Meeting link"
                value={meetingLink}
                onInput={setMeetingLink}
                errorText={meetingLinkError}
                props={{ id: 'meetingLink' }}
              />
              <SpacerHorizontal />
              <Button disabled={!isValidUrl(meetingLink)} onClick={() => window.open(meetingLink)}>
                Open
              </Button>
            </Row>
            <SpacerVertical />
            <TextArea
              width="100%"
              autoHeight
              minRows={4}
              maxRows={10}
              label="Description"
              value={description}
              onInput={setDescription}
            />
            <SpacerVertical />
            {isLocalized && (
              <>
                <Select
                  label="Language"
                  value={language}
                  onChange={selectLanguage}
                  options={languageOptions}
                />
                <SpacerVertical />
              </>
            )}
            <Row alignItems="flex-start">
              <DateAndTimeInput
                readonly={schedulingDisabled}
                value={scheduled}
                onChange={setScheduled}
                errorText={scheduledError}
                // timeZone={currentGame?.timeZone}
                props={{ id: 'scheduled' }}
              />
              <SpacerHorizontal />
              <TextField
                type="number"
                readonly={schedulingDisabled}
                autocomplete="off"
                label="Duration (hours)"
                value={duration}
                onInput={(duration: string) => setDuration(parseFloat(duration))}
                errorText={durationError}
                maxWidth="140px"
                props={{ id: 'duration' }}
              />
            </Row>
            {currentGame?.timeZone !== undefined && scheduled && (
              <>
                <SpacerVertical />
                <SessionStartEnd
                  label="Game time"
                  start={scheduled}
                  duration={duration}
                  timeZone={currentGame.timeZone}
                />
              </>
            )}
            {voice?.timeZone !== undefined && scheduled && (
              <>
                <SpacerVertical />
                <SessionStartEnd
                  label="Voice time"
                  start={scheduled}
                  duration={duration}
                  timeZone={voice.timeZone}
                />
              </>
            )}
            <SpacerVertical />
            {contentFlags}
          </Block>
          <Block maxWidth="500px">
            {voice && (
              <Block border="1px solid var(--container-outline)" padding="20px">
                <SessionFee
                  budgetSegment={currentGame!.budgetSegment}
                  duration={duration}
                  includeVAT={currentGame?.billingAccount?.country === 'Sweden'}
                  order={!hasChangesThatRequiresAccept ? session?.sessionOrder : undefined}
                />
                <SpacerVertical large />
                {isFetchingBuyoutOrder && <ProgressCircular size={24} />}
                {!isFetchingBuyoutOrder && (
                  <BuyoutInfo
                    budgetSegment={currentGame!.budgetSegment}
                    includeVAT={currentGame?.billingAccount?.country === 'Sweden'}
                    buyoutOrder={buyoutOrder}
                  />
                )}
              </Block>
            )}
          </Block>
        </Row>
        {checkboxVisible && !session?.voiceAccepted && !session?.voiceDeclined && (
          <Fragment>
            <Checkbox
              onChange={setAgreementsAccepted}
              checked={agreementsAccepted}
              label={
                <Fragment>
                  I accept the session
                  {!buyoutOrder ? ' and buyout' : ''} costs and the{' '}
                  <Link
                    target="_blank"
                    to="https://drive.google.com/file/d/1qmMY30reHNiNZfRasZgAjz-bvMWdcj2h/view"
                  >
                    client terms and conditions
                  </Link>
                  .
                </Fragment>
              }
              props={{ id: 'agreement' }}
            />
            {agreementError && (
              <Fragment>
                <ErrorMessage>{agreementError}</ErrorMessage>
                <SpacerVertical />
              </Fragment>
            )}
            <P color="var(--on-surface-light)" size="2">
              Cancellation can be made up until 24 hours before scheduled time. Sessions cancelled
              later will be fully charged.
            </P>
          </Fragment>
        )}
        <SpacerVertical />

        <Row alignItems="center">
          <Button type="submit" contained loading={isUpdatingSession} disabled={!hasChanges}>
            Save
          </Button>
          <SpacerHorizontal />
          <Button onClick={onBack}>Cancel</Button>
        </Row>
      </Form>

      {session?.voiceAccepted && (
        <Block>
          <SpacerVertical />
          Session accepted by {voice?.name} on {formatScheduled(session.voiceAccepted)}
        </Block>
      )}
      {session?.voiceDeclined && (
        <Block>
          <SpacerVertical />
          Session declined by {voice?.name} on {session.voiceDeclined}
        </Block>
      )}

      <SpacerVertical large />

      <Route
        path="/session/:sessionId/select-voice"
        render={() => {
          return (
            <SelectVoice
              onClose={() => goBack(`/session/${session!.id}`)}
              onSelect={(voice) => {
                setVoiceId(voice.id)
                goBack(`/session/${session!.id}`)
              }}
            />
          )
        }}
      />
      <Route
        path="/session/:sessionId/delete"
        render={() => (
          <ConfirmDeleteDialog
            onConfirm={handleDelete}
            onClose={() => goBack(`/session/${session?.id}`)}
          />
        )}
      />
      <Route
        path="/session/:sessionId/audition-details"
        render={() => {
          return (
            <AuditionDetails
              onClose={() => goBack(`/session/${session?.id}`)}
              onSubmit={async (
                auditionDetails: AuditionDetailsSubmitParams,
                shareCharacterSheet
              ) => {
                await saveSession(auditionDetails, shareCharacterSheet)
                onBack()
              }}
              onSkip={async () => {
                await saveSession()
                onBack()
              }}
            />
          )
        }}
      />

      {showSelectBillingAccountDialogType && billingAccounts?.length && (
        <SelectBillingAccount
          billingAccounts={billingAccounts}
          onCreateBillingAccount={() => {
            createBillingAccount({
              urlSearchParams: getCreateBillingAccountUrlSearchParams(
                showSelectBillingAccountDialogType
              )
            })
          }}
          onClose={() => {
            setShowSelectBillingAccountDialogType(undefined)
          }}
          onSelect={async (billingAccount) => {
            if (await confirmSelectBillingAccount({ billingAccount })) {
              updateGame({ billingAccountId: billingAccount.id })
              return true
            }
            return false
          }}
        />
      )}
    </>
  )
}
