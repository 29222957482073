import { h } from 'preact'
import { Button, EventIcon, Form, Select, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'
import { useEffect, useRef, useState } from 'preact/hooks'

import { uploadFile } from '../../actions'

import Page from '../Page'
import { Row } from 'jsxstyle/preact'
import Avatar from '../Avatar'
import { Session } from '../../types'
import { get } from '../../api'
import { useStore } from '../../store'
import { formatScheduled } from 'lib'

export const UploadFile = () => {
  const params = new URLSearchParams(location.search)

  const { currentGame, isUploadingFile } = useStore('currentGame', 'isUploadingFile')

  const fileInput = useRef<HTMLInputElement>(null)

  const [filename, setFilename] = useState('')
  const [filenameError, setFilenameError] = useState('')

  const [sessionId, setSessionId] = useState<string | undefined>(
    params.get('sessionId') || undefined
  )
  const [sessions, setSessions] = useState<Session[]>([])

  useEffect(() => {
    get(`/games/${currentGame?.id}/sessions`).then(({ data: sessions }) => setSessions(sessions))
  }, [currentGame?.id])

  const handleSelectFile = () => {
    const files = fileInput.current?.files as FileList
    const file = files[0]
    setFilename(file.name)
  }

  const handleSubmit = async () => {
    const files = fileInput.current?.files as FileList
    const file = files[0]
    if (!file) {
      setFilenameError('Please select a file')
      return
    }
    if (filename.trim() === '') {
      setFilenameError('Please enter a valid filename')
      return
    }
    if (await uploadFile(file, { filename, sessionId })) {
      goBack('/files')
    }
  }

  return (
    <Page title="Upload file" onBack={() => goBack('/files')}>
      <input style="display:none" ref={fileInput} type="file" onChange={handleSelectFile} />
      <Form onSubmit={handleSubmit} maxWidth="500px">
        <Row alignItems="center" gap="5px">
          <TextField
            width="100%"
            errorText={filenameError}
            label="Filename"
            value={filename}
            onInput={setFilename}
          />
          <Button outlined onClick={() => fileInput.current?.click()}>
            Browse
          </Button>
        </Row>
        <SpacerVertical />
        <Select
          width="100%"
          label={sessionId ? '' : 'Session (optional)'}
          value={sessionId}
          onChange={setSessionId}
          options={sessions.map((session) => {
            return {
              icon: session.voice ? undefined : EventIcon,
              visual: session.voice ? (
                <Avatar size={30} src={session.voice?.picture} name={session.voice?.name} />
              ) : undefined,
              text: formatScheduled(session.scheduled),
              secondaryText: session.voice
                ? `${session.voice.name} · Session ${session.num}`
                : 'Not assigned',
              value: session.id
            }
          })}
        />
        <SpacerVertical />
        <Button
          type="submit"
          contained
          loading={isUploadingFile}
          disabled={fileInput.current === null}
        >
          Upload
        </Button>
      </Form>
    </Page>
  )
}
