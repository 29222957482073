import { h } from 'preact'
import { Button, SpacerHorizontal, SpacerVertical } from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'
import { goBack, routeTo } from '@sodra/prutt'

import { showSnackbar, deleteScene } from '../../actions'

import Page from '../Page'
import { useStore } from '../../store'

export const DeleteScene = () => {
  const { scene, isDeletingScene } = useStore('scene', 'isDeletingScene')

  const onBack = () => goBack('/scenes')

  const handleDelete = async () => {
    if (await deleteScene()) {
      showSnackbar('Scene deleted')
      routeTo('/scenes', true)
    }
  }

  return (
    <Page title={scene!.name} onBack={onBack}>
      <Block>Delete scene?</Block>
      <SpacerVertical />
      <Row alignItems="center">
        <Button contained loading={isDeletingScene} onClick={handleDelete}>
          Delete
        </Button>
        <SpacerHorizontal />
        <Button onClick={onBack}>Cancel</Button>
      </Row>
    </Page>
  )
}

export default DeleteScene
