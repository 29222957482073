import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import {
  Button,
  DeleteIcon,
  EditIcon,
  Form,
  NativeSelect,
  PlayIcon,
  SpacerHorizontal,
  SpacerVertical,
  StopIcon,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'

import { showSnackbar, updateAIVoice } from '../../actions'
import { buildSpeakerUri } from '../../speak'

import Avatar from '../Avatar'
import Page from '../Page'
import MinMaxSlider from '../MinMaxSlider'
import { useStore } from '../../store'
import { useAudioPlayer } from 'lib/src/hooks/use-audio-player'

export const EditElevenlabsDetails = () => {
  const { aiVoice, isUpdatingAIVoice, isNarrow } = useStore(
    'aiVoice',
    'isUpdatingAIVoice',
    'isNarrow'
  )

  if (!aiVoice) {
    return null
  }

  const { player } = useAudioPlayer()
  if (!player) {
    return null
  }

  const { initPlayer, play, playerState, resetPlayer, stopPlayer } = player

  const [name, setName] = useState(aiVoice.name)
  const [description, setDescription] = useState(aiVoice.description)
  const [sampleText, setSampleText] = useState(aiVoice.sampleText)
  const [stability, setStability] = useState(
    aiVoice.data.stability ? aiVoice.data.stability * 100 : 75
  )
  const [similarityBoost, setSimilarityBoost] = useState(
    aiVoice.data.similarityBoost ? aiVoice.data.similarityBoost * 100 : 75
  )
  const [modelId, setModelId] = useState(aiVoice.data.modelId)

  const onBack = () => goBack('/ai-voices')

  const handleSave = async () => {
    const params = {
      name,
      description,
      sampleText,
      similarityBoost: similarityBoost / 100.0,
      stability: stability / 100.0,
      modelId
    }

    if (await updateAIVoice(params)) {
      showSnackbar('AI voice details saved')
      onBack()
    }
  }

  const uri = buildSpeakerUri({
    aiVoiceId: aiVoice.id,
    text: sampleText || `My name is ${name}. ${description}`,
    extraParams: {
      stability: stability / 100.0,
      similarityBoost: similarityBoost / 100.0,
      modelId
    }
  })

  return (
    <Page
      title={aiVoice.name}
      actions={[
        {
          label: 'Delete',
          icon: DeleteIcon,
          onClick: () => routeTo(`/ai-voice/${aiVoice.id}/delete`),
          visible: !aiVoice.shared
        }
      ]}
      onBack={onBack}
    >
      <Block maxWidth="500px">
        <Avatar
          actionIcon={aiVoice.shared ? undefined : EditIcon}
          onActionClick={
            aiVoice.shared ? undefined : () => routeTo(`/ai-voice/${aiVoice.id}/picture`)
          }
          size={150}
          src={aiVoice.picture}
          name={name}
        />
        <SpacerVertical />
        <Form onSubmit={handleSave} maxWidth="500px">
          <TextField
            readonly={aiVoice.shared}
            width="100%"
            label="Name"
            value={name}
            onInput={setName}
          />
          <SpacerVertical />
          <TextArea
            readonly={aiVoice.shared}
            autoHeight
            minRows={1}
            maxRows={5}
            width="100%"
            label="Description"
            value={description}
            onInput={setDescription}
          />
          <SpacerVertical />
          <TextArea
            autoHeight
            minRows={3}
            maxRows={5}
            width="100%"
            label="Sample text"
            value={sampleText}
            onInput={setSampleText}
          />
          <SpacerVertical />
          <NativeSelect
            width="100%"
            label="Model"
            value={modelId}
            onChange={setModelId}
            options={[
              { label: 'Eleven Multilingual v2', value: 'eleven_multilingual_v2' },
              { label: 'Eleven Multilingual v1', value: 'eleven_multilingual_v1' },
              { label: 'Eleven English v1', value: 'eleven_monolingual_v1' }
            ]}
          />
          <SpacerVertical />

          {!aiVoice.shared && (
            <Row alignItems={isNarrow ? '' : 'center'} flexDirection={isNarrow ? 'column' : 'row'}>
              <Block flex="1">
                <Row alignItems="center" justifyContent="space-between">
                  <Block>Stability {stability.toFixed(0)}%</Block>
                  <Button onClick={() => setStability(aiVoice.data.stability * 100)}>Reset</Button>
                </Row>
                <MinMaxSlider
                  width="100%"
                  value={stability}
                  minValue={0}
                  maxValue={100}
                  middleValue={50}
                  onChange={setStability}
                />
              </Block>
              {isNarrow ? <SpacerVertical /> : <SpacerHorizontal />}
              <Block flex="1">
                <Row alignItems="center" justifyContent="space-between">
                  <Block>Similarity {similarityBoost.toFixed(0)}%</Block>
                  <Button onClick={() => setSimilarityBoost(aiVoice.data.similarityBoost * 100)}>
                    Reset
                  </Button>
                </Row>
                <MinMaxSlider
                  width="100%"
                  value={similarityBoost}
                  minValue={0}
                  maxValue={100}
                  onChange={setSimilarityBoost}
                />
              </Block>
            </Row>
          )}
          <Row alignItems="center">
            {!aiVoice.shared && (
              <>
                <Button
                  type="submit"
                  contained
                  loading={isUpdatingAIVoice}
                  disabled={aiVoice.shared}
                >
                  Save
                </Button>
                <SpacerHorizontal />
                <Button onClick={onBack}>Cancel</Button>
              </>
            )}
            <Block flex="1" />
            <Button
              type="button"
              role="button"
              icon={playerState.value.isPlaying ? StopIcon : PlayIcon}
              loading={playerState.value.isPlaying && playerState.value.loading}
              onClick={() => {
                if (playerState.value.isPlaying) {
                  stopPlayer()
                  resetPlayer()
                } else {
                  initPlayer({
                    audioTracks: [{ name, uri }]
                  })
                  play()
                }
              }}
            >
              Play sample
            </Button>
          </Row>
        </Form>
        <SpacerVertical large />
      </Block>
    </Page>
  )
}

export default EditElevenlabsDetails
