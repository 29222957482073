import { h } from 'preact'
import { ChevronLeftIcon, ChevronRightIcon, IconButton } from "@sodra/bongo-ui"
import { Row } from "jsxstyle/preact"


export const Pager = ({ page, pages, setPage }) => {
    return (
        <Row alignItems="center" justifyContent="flex-end">
            <IconButton icon={ChevronLeftIcon} disabled={page === 1} onClick={() => setPage(page - 1)} />{' '}
            Page {page} of {pages}{' '}
            <IconButton
                icon={ChevronRightIcon}
                disabled={page === pages}
                onClick={() => setPage(page + 1)}
            />
        </Row>
    )
}