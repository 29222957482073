import { h } from 'preact'
import { SpacerHorizontal, SpacerVertical } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'
import { confirm, formatCurrency } from 'lib'
import { get } from 'src/api'

type BillingAccount = {
  id: string
}

type Params = {
  title?: string
  message?: string
  secondaryMessage?: string
  totalIncVAT: number
  billingAccount: BillingAccount
  confirmText?: string
}

export const confirmPurchase = async ({
  title,
  message,
  secondaryMessage,
  totalIncVAT,
  billingAccount,
  confirmText = 'Confirm'
}: Params) => {
  // Fetch full billing account including credit card number
  const { data: fullBillingAccount } = await get(`/billing-accounts/${billingAccount.id}`)

  return confirm({
    title: title || 'Please confirm your purchase',
    message: (
      <>
        <Block>{message}</Block>
        <SpacerVertical />
        <Block paddingLeft="10px">
          <table cellPadding="0" cellSpacing="5" style={{ textAlign: 'left' }}>
            <tr>
              <td>Total:</td>
              <td>
                <SpacerHorizontal />
              </td>
              <td>{formatCurrency(totalIncVAT)}</td>
            </tr>
            <tr>
              <td>Billing account:</td>
              <td>
                <SpacerHorizontal />
              </td>
              <td>{fullBillingAccount.name}</td>
            </tr>
            <tr>
              <td>Payment type:</td>
              <td>
                <SpacerHorizontal />
              </td>
              <td>{fullBillingAccount.invoice ? 'Invoice' : 'Credit card'}</td>
            </tr>
            {!fullBillingAccount.invoice && (
              <tr>
                <td>Card number:</td>
                <td>
                  <SpacerHorizontal />
                </td>
                <td>
                  {fullBillingAccount.creditCard
                    ? `•••• •••• •••• ${fullBillingAccount.creditCard.last4}`
                    : '-'}
                </td>
              </tr>
            )}
          </table>
        </Block>
        {secondaryMessage && (
          <>
            <SpacerVertical large />
            <Block color="var(--on-surface-light)">{secondaryMessage}</Block>
          </>
        )}
        <SpacerVertical large />
      </>
    ),
    confirmText,
    rejectText: 'Cancel'
  })
}
