import { store } from '../store'
import { get, del, post, patch } from '../api'

export const fetchExportTargets = async (params) => {
  let cancelled = false
  store.setState({ isFetchingExportTargets: true })
  const gameId = store.getState().currentGame.id
  get(`/games/${gameId}/export-targets`, params)
    .then(({ data: exportTargets }) => {
      if (!cancelled) {
        store.setState({ exportTargets, isFetchingExportTargets: false })
      }
    })
    .catch((error) => {
      if (!cancelled) {
        store.setState({ error, isFetchingExportTargets: false })
      }
    })
  return () => {
    store.setState({ isFetchingExportTargets: false })
    cancelled = true
  }
}

export const fetchExportTarget = async (exportTargetId) => {
  try {
    store.setState({ isFetchingExportTarget: true })
    const { data: exportTarget } = await get(`/export-targets/${exportTargetId}`)
    store.setState({ exportTarget, isFetchingExportTarget: false })
    console.log('Setting export target in store:', exportTarget)
    return exportTarget
  } catch (error) {
    store.setState({ error, isFetchingExportTarget: false })
  }
}

export const createExportTarget = async (params) => {
  try {
    store.setState({ isCreatingExportTarget: true })
    const gameId = store.getState().currentGame.id
    const { data: exportTarget } = await post(`/games/${gameId}/export-targets`, params)
    const { data: exportTargets } = await get(`/games/${gameId}/export-targets`)
    store.setState({ exportTarget, exportTargets, isCreatingExportTarget: false })
    return exportTarget
  } catch (error) {
    store.setState({ error, isCreatingExportTarget: false })
  }
}

export const updateExportTarget = async (exportTargetId, params) => {
  try {
    store.setState({ isUpdatingExportTarget: true })
    const { data: exportTarget } = await patch(`/export-targets/${exportTargetId}`, params)
    store.setState({ isUpdatingExportTarget: false })
    return exportTarget
  } catch (error) {
    store.setState({ error, isUpdatingExportTarget: false })
  }
}

export const syncExportTarget = async (exportTargetId, params) => {
  try {
    store.setState({ isSyncingExportTarget: true })
    await post(`/export-targets/${exportTargetId}`, params)
    store.setState({ isSyncingExportTarget: false })
    return true
  } catch (error) {
    store.setState({ error, isSyncingExportTarget: false })
  }
}

export const deleteExportTarget = async (exportTargetId) => {
  try {
    store.setState({ isDeletingExportTarget: true })
    const gameId = store.getState().currentGame.id
    await del(`/export-targets/${exportTargetId}`)
    const { data: exportTargets } = await get(`/games/${gameId}/export-targets`)
    store.setState({ exportTargets, isDeletingExportTarget: false })
    return true
  } catch (error) {
    store.setState({ error, isDeletingExportTarget: false })
  }
}
