import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { FilterListIcon, SelectChip } from '@sodra/bongo-ui'

import { get } from '../../api'

import { usePopupMenu } from '../use-popup-menu'

export const LanguageSelectChip = ({ value, onChange }) => {
  const [langauges, setLanguages] = useState([])

  useEffect(() => {
    get('/audio-languages').then(({ data: langauges }) => setLanguages(langauges))
  }, [])

  const { PopupMenu, showMenu } = usePopupMenu({
    options: langauges.map((langauge) => {
      return {
        label: langauge,
        onClick: () => onChange(langauge)
      }
    })
  })

  return (
    <Fragment>
      <SelectChip
        label="Language"
        icon={FilterListIcon}
        value={value}
        onClick={showMenu}
        onClear={() => onChange()}
        whiteSpace="nowrap"
      />
      {PopupMenu}
    </Fragment>
  )
}
