import { SpacerVertical, TabItem, Tabs } from '@sodra/bongo-ui'
import { Block, Col } from 'jsxstyle/preact'
import { TakeUser } from './lineRecorderState'
import { formatLanguage } from 'lib'

import { TakeDots } from './TakeDots'

import { lineRecorder, recorder } from './LineRecorder'

export function LinesList() {
  if (!lineRecorder.value) {
    return null
  }

  const linesCharacters = Array.from(
    lineRecorder.value.lines.value
      .reduce((map, curr) => {
        if (curr.character) {
          map.set(curr.character?.id, curr.character)
        }

        return map
      }, new Map<string, TakeUser>())
      .values()
  ).sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0)

  const showCharacterSelect = linesCharacters.length > 1

  const selectCharacterTabs = linesCharacters.map((character) => {
    const isSelected = character.id === lineRecorder.value?.selectedCharacter.value
    return (
      <TabItem
        key={character.id}
        text={character.name}
        avatar={{ src: character.picture, name: character.name }}
        tooltipText={`${character.name} lines`}
        active={isSelected}
        onClick={() => {
          if (isSelected) {
            lineRecorder.value!.selectedCharacter.value = null
          } else {
            lineRecorder.value!.selectedCharacter.value = character.id
          }
        }}
      />
    )
  })

  selectCharacterTabs.unshift(
    <TabItem
      text={'All'}
      tooltipText={`Lines for all characters`}
      active={lineRecorder.value.selectedCharacter.value === null}
      onClick={() => {
        if (lineRecorder.value!.selectedCharacter.value !== null) {
          lineRecorder.value!.selectedCharacter.value = null
        }
      }}
    />
  )

  return (
    <Col position="relative">
      {showCharacterSelect && (
        <Block position="sticky" top="0" background="var(--surface)">
          <Tabs type="scrollable">{selectCharacterTabs}</Tabs>
        </Block>
      )}
      {lineRecorder.value.activeLines.value.map((line, index) => {
        const isActive = lineRecorder.value?.activeLineId.value === line.id
        function onClick(e: MouseEvent) {
          if (recorder.value?.state.value.mediaRecorderState !== 'recording') {
            lineRecorder.value!.activeLineId.value = line.id
          }
        }

        const lineTakes =
          lineRecorder.value?.takes.value.filter((take) => {
            const isLineTake = take.lineId === line.id
            if (!isLineTake) return false
            if (!lineRecorder.value?.sessionId.value) return true
            return (
              lineRecorder.value.showAllTakes.value ||
              take.sessionId === lineRecorder.value.sessionId.value
            )
          }) ?? []

        const currentLanguageLine = line.translations[lineRecorder.value!.language.value]?.line
        const primaryLanguage = lineRecorder.value!.game.value.primaryLanguage
        const primaryLanguageLine = line.translations[primaryLanguage]?.line

        const selectedTake = line.translations[lineRecorder.value!.language.value]?.selectedTake

        return (
          <Col
            padding="20px 40px"
            hoverBackgroundColor="var(--surface-alternative)"
            backgroundColor={isActive ? 'var(--surface-floating)' : undefined}
            borderRadius="3px"
            gap="5px"
            cursor={!isActive ? 'pointer' : undefined}
            scrollMarginTop="40vh"
            key={line.id}
            props={{
              onClick,
              id: line.id
            }}
          >
            <Block
              whiteSpace="pre-line"
              color={!currentLanguageLine ? 'var(--warning)' : undefined}
            >
              {index + 1}.{' '}
              {currentLanguageLine
                ? currentLanguageLine
                : `Not translated to ${formatLanguage(lineRecorder.value!.language.value)}`}
              {!currentLanguageLine && primaryLanguageLine && (
                <Block color="var(--on-surface-light)" whiteSpace="pre-line">
                  <SpacerVertical tiny />
                  {formatLanguage(primaryLanguage)}: {primaryLanguageLine}
                </Block>
              )}
            </Block>
            {lineTakes.length > 0 && (
              <TakeDots
                takes={lineTakes}
                selectedTake={selectedTake}
                activeLineRecorderTake={lineRecorder.value?.activeTake.value ?? undefined}
              />
            )}
            {lineTakes.length === 0 && (
              <Block fontSize="14px" color="var(--warning)">
                Not recorded
              </Block>
            )}
          </Col>
        )
      })}
    </Col>
  )
}
