import { h } from 'preact'
import { Avatar, BasicDialog, Checkbox, FileInput, Form, SpacerVertical } from '@sodra/bongo-ui'
import { useState } from 'preact/hooks'

import { uploadTake } from '../../actions'
import { getFileHash } from '../../get-file-hash'
import { useLocalStorageState } from '../../use-local-storage-state'
import { useStore } from '../../store'
import { FakeSelect } from '../FakeSelect'
import { goBack, Route, routeTo } from '@sodra/prutt'
import { ShortlistedVoice, User } from '../../types'
import { SelectTakeUploader, SelectUploaderParams } from './SelectTakeUploader'

type Props = {
  onClose: () => void
}

export const UploadTake = ({ onClose }: Props) => {
  const { line, isUpdatingLine, isUploadingTake, currentUser, currentGame, currentLanguage } =
    useStore(
      'line',
      'isUpdatingLine',
      'isUploadingTake',
      'currentUser',
      'currentGame',
      'currentLanguage'
    )
  const [file, setFile] = useState<File>()
  const [userUploader, setUserUploader] = useState<User | undefined>(currentUser!)
  const [voiceUploader, setVoiceUploader] = useState<ShortlistedVoice | undefined>()

  const [makeDefault, setMakeDefault] = useLocalStorageState(
    `speechless:lines:uploadTake:makeDefault`,
    false
  )

  const handleSubmit = async () => {
    if (!file) {
      return
    }
    const hash = await getFileHash(file)

    const uploadTakeParams = {
      language: currentLanguage,
      hash,
      makeDefault,
      userId: userUploader?.id,
      voiceId: voiceUploader?.id
    }

    if (await uploadTake(file, uploadTakeParams)) {
      onClose()
    }
  }

  const selectUploader = ({ user, voice }: SelectUploaderParams) => {
    setUserUploader(user)
    setVoiceUploader(voice)
  }

  const uploader = voiceUploader || userUploader

  return (
    <BasicDialog
      title="Upload take"
      primaryActionText="Upload"
      primaryActionLoading={isUpdatingLine || isUploadingTake}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
      disableAutofocus
    >
      <SpacerVertical />
      <Form onSubmit={handleSubmit}>
        <FileInput label="Select file" accept="audio/*" value={file} onChange={setFile} />
        <SpacerVertical />
        <FakeSelect
          label="Voice"
          visual={
            uploader ? <Avatar size={30} src={uploader.picture} name={uploader.name} /> : undefined
          }
          value={uploader?.name}
          onClick={() => routeTo(`/line/${line!.id}/upload-take/select-uploader`)}
        />
        <SpacerVertical />
        <Checkbox label="Use as selected take" checked={makeDefault} onChange={setMakeDefault} />
        <SpacerVertical />
      </Form>

      <Route
        path={`/line/:lineId/upload-take/select-uploader`}
        render={() => (
          <SelectTakeUploader
            line={line}
            onClose={() => goBack(`/line/${line!.id}/upload-take`)}
            onSelect={selectUploader}
          />
        )}
      />
    </BasicDialog>
  )
}

export default UploadTake
