import { h } from 'preact'
import { goBack } from '@sodra/prutt'

import Page from './Page'
import { get, post } from '../api'
import {
  Avatar,
  Button,
  Form,
  List,
  ListItem,
  SpacerHorizontal,
  SpacerVertical,
  TextField
} from '@sodra/bongo-ui'
import { useEffect, useState } from 'preact/hooks'
import { Row } from 'jsxstyle/preact'
import { useLocalStorageState } from '../use-local-storage-state'
import { useStore } from '../store'

type ElevenlabsVoice = {
  voice_id: string
  name: string
  category: string
  labels: Record<string, string>[]
  high_quality_base_model_ids: string[]
}

const avatars: string[] = []

for (let i = 0; i < 51; i++) {
  avatars.push(`https://storage.googleapis.com/speechless-dev/pixel-avatars/${i}.jpg`)
}

export const ImportAIVoices = () => {
  const { currentGame } = useStore('currentGame')

  const [apiKey, setApiKey] = useLocalStorageState('')
  const [isFetching, setIsFetching] = useState(false)
  const [voices, setVoices] = useState<ElevenlabsVoice[]>([])
  const [existingVoices, setExistingVoices] = useState<string[]>([])
  const [importingVoiceId, setImportingVoiceId] = useState<string | undefined>()

  useEffect(() => {
    fetchExistingVoices()
  }, [currentGame?.id])

  useEffect(() => {
    if (apiKey) {
      fetchVoices()
    }
  }, [])

  console.log(currentGame, voices, existingVoices)

  const getPicture = (voiceId: string) => {
    const hash = voiceId.split('').reduce((hash, char) => hash + char.charCodeAt(0), 0)
    const index = hash % 51
    return avatars[index]
  }

  const onBack = () => goBack('/ai-voices')

  const fetchExistingVoices = async () => {
    get(`/games/${currentGame?.id}/ai-voices`, { type: 'elevenlabs', limit: 999 }).then(
      ({ data: voices }) => setExistingVoices(voices.map((voice: any) => voice.data.voiceId))
    )
  }

  const fetchVoices = async () => {
    setIsFetching(true)
    try {
      const { data: voices } = await get('/ai-voices/elevenlabs', { apiKey })
      setVoices(voices)
    } finally {
      setIsFetching(false)
    }
  }

  const importVoice = async (voice: ElevenlabsVoice) => {
    setImportingVoiceId(voice.voice_id)
    try {
      await post(`/games/${currentGame?.id}/import-ai-voice`, {
        apiKey,
        name: voice.name,
        voiceId: voice.voice_id,
        picture: getPicture(voice.voice_id),
        modelId: voice.high_quality_base_model_ids[0],
        description: Object.values(voice.labels).join(' ')
      })
      await fetchExistingVoices()
    } finally {
      setImportingVoiceId(undefined)
    }
  }

  return (
    <Page title="Import AI voices" onBack={onBack}>
      <Form maxWidth="500px" onSubmit={fetchVoices}>
        <Row alignItems="center">
          <TextField width="100%" label="Elevenlabs API key" value={apiKey} onInput={setApiKey} />
          <SpacerHorizontal small />
          <Button contained type="submit" loading={isFetching}>
            Fetch
          </Button>
        </Row>
      </Form>
      <SpacerVertical />
      <List maxWidth="800px">
        {voices.map((voice) => {
          const imported = existingVoices.includes(voice.voice_id)
          const picture = getPicture(voice.voice_id)
          return (
            <ListItem
              visual={<Avatar src={picture} name={voice.name} />}
              text={voice.name}
              secondaryText={voice.category + ', ' + Object.values(voice.labels).join(', ')}
              actionText={imported ? 'Imported' : 'Import'}
              actionLoading={importingVoiceId === voice.voice_id}
              onActionClick={() => importVoice(voice)}
              actionDisabled={imported}
            />
          )
        })}
      </List>
    </Page>
  )
}
