import { MemoryIcon } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle'
import Avatar from './Avatar'

type Props = {
  size?: number
  src?: string
  name?: string
}

export const AIAvatar = ({ size = 40, src, name }: Props) => {
  const iconSize = Math.max(Math.floor(size / 3), 12)
  return (
    <Block position="relative" className="AIAvatar" width={`${size}px`} height={`${size}px`}>
      <Avatar size={size} src={src} name={name} />
      <Block
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        background="rgba(0, 0, 0, 0.75)"
        overflow="hidden"
        bottom="-2px"
        right="-2px"
        padding=".5px"
        borderRadius="50%"
        width={`${iconSize}px`}
        height={`${iconSize}px`}
        props={{ title: 'AI voice' }}
      >
        <MemoryIcon margin="0" lineHeight="17px" size={iconSize} fill="var(--accent)" />
      </Block>
    </Block>
  )
}
