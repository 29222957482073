import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import {
  List,
  ListItem,
  NativeSelect,
  PlayIcon,
  PlusIcon,
  SpacerVertical,
  StopIcon
} from '@sodra/bongo-ui'

import { useFetchResult } from '../use-fetch-result'

import LargeDialog from './LargeDialog'
import SearchTextField from './SearchTextField'
import { buildSpeakerUri } from '../speak'
import { useStore } from '../store'
import { useAudioPlayer } from 'lib/src/hooks/use-audio-player'
import { AIAvatar } from './AIAvatar'
import { routeTo } from '@sodra/prutt'
import { AIVoiceType } from '../types'
import { formatAIVoiceType } from 'lib'
import { useLocalStorageState } from '../use-local-storage-state'
import { Block, Row } from 'jsxstyle/preact'
import { capitalize } from './capitalize'

type AIVoice = {
  id: string
  name: string
  type: AIVoiceType
  data: any
  picture?: string
  description?: string
  sampleText?: string
  shared: boolean
  emotions: string[]
  exampleUrl?: string
}

type Props = {
  onClose: () => void
  onSelect: (aiVoice: AIVoice) => void
}

export const SelectAIVoice = ({ onClose, onSelect }: Props) => {
  const { player } = useAudioPlayer()
  if (!player) {
    return null
  }
  const { playerState, hidePlayer, stopPlayer, resetPlayer, initPlayer, play } = player

  const { currentGame } = useStore('currentGame')
  const [query, setQuery] = useLocalStorageState('speechless:selectAIVoice:query', '')
  const [aiVoiceType, setAIVoiceType] = useLocalStorageState(
    'speechless:selectAIVoice:aiVoiceType',
    ''
  )

  const { data: aiVoices } = useFetchResult<AIVoice[]>(`/games/${currentGame!.id}/ai-voices`, {
    query,
    type: aiVoiceType,
    limit: 1000
  })

  useEffect(() => {
    stopPlayer()
    hidePlayer()
  }, [])

  return (
    <LargeDialog
      large
      title="Select AI voice"
      onClose={onClose}
      action1Text="Cancel"
      onAction1Click={onClose}
      secondaryActionText="New AI voice"
      secondaryActionIcon={PlusIcon}
      onSecondaryActionClick={() => routeTo('/create-ai-voice')}
    >
      <Row alignItems="center" gap="5px">
        <Block flex="1">
          <SearchTextField width="100%" value={query} onChange={setQuery} />
        </Block>
        <NativeSelect
          width="max-content"
          label="Type"
          value={aiVoiceType}
          onChange={setAIVoiceType}
          options={[
            { value: '', label: 'Any' },
            { value: 'google', label: formatAIVoiceType('google') },
            { value: 'elevenlabs', label: formatAIVoiceType('elevenlabs') },
            { value: 'azuretts', label: formatAIVoiceType('azuretts') }
          ]}
        />
      </Row>
      <SpacerVertical />
      {aiVoices && (
        <List>
          {aiVoices.map((aiVoice) => {
            const uri =
              aiVoice.exampleUrl ||
              buildSpeakerUri({
                aiVoiceId: aiVoice.id,
                text: aiVoice.sampleText || `My name is ${aiVoice.name}. ${aiVoice.description}`
              })

            const isPlayingCurrentTrack =
              playerState.value.isPlaying && playerState.value.currentTrackUri === uri

            const togglePlay = () => {
              if (isPlayingCurrentTrack) {
                stopPlayer()
                resetPlayer()
              } else {
                initPlayer({
                  audioTracks: [{ uri }]
                })
                play()
              }
            }

            return (
              <ListItem
                visual={<AIAvatar size={30} src={aiVoice.picture} name={aiVoice.name} />}
                text={aiVoice.name}
                secondaryText={
                  <Row>
                    {aiVoice.description} {aiVoice.emotions.length > 0 ? '·' : ''}{' '}
                    {aiVoice.emotions.map(capitalize).join(' · ')}
                  </Row>
                }
                metaText={formatAIVoiceType(aiVoice.type)}
                onClick={() => {
                  onSelect(aiVoice)
                  onClose()
                }}
                actionLoading={playerState.value.loading && isPlayingCurrentTrack}
                actionIcon={
                  isPlayingCurrentTrack ? () => StopIcon({ fill: 'var(--accent)' }) : PlayIcon
                }
                onActionClick={togglePlay}
              />
            )
          })}
        </List>
      )}
      <SpacerVertical />
    </LargeDialog>
  )
}

export default SelectAIVoice
