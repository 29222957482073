import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { parse } from 'qs'

import { loginWithToken } from '../actions'

import { routeTo } from '@sodra/prutt'
import LandingPage from './LandingPage'
import { useStore } from '../store'
import Spinner from './Spinner'

const LoginWithToken = () => {
  const { isLoggingIn } = useStore('isLoggingIn')
  const [invalidToken, setInvalidToken] = useState(false)

  const { token, redirectTo = '/' } = parse(location.search.slice(1))

  useEffect(() => {
    if (token) {
      setInvalidToken(false)
      loginWithToken(token)
        .then(() => routeTo(redirectTo, { replace: true }))
        .catch((error) => {
          console.log('Unable to login using token', error)
          setInvalidToken(true)
        })
    }
  }, [token])

  return (
    <LandingPage>
      {(!token || invalidToken) && <Block>Invalid token</Block>}
      {isLoggingIn && <Spinner />}
    </LandingPage>
  )
}

export default LoginWithToken
