import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Button, Checkbox, Form, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'
import { parse } from 'qs'

import { resetPassword } from '../actions'

import { routeTo } from '@sodra/prutt'
import LandingPage from './LandingPage'
import { useStore } from '../store'

const ResetPassword = () => {
  const { isResettingPassword } = useStore('isResettingPassword')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const { token } = parse(location.search.slice(1))

  const handleSubmit = async () => {
    let errorId
    if (password.trim() === '') {
      setPasswordError('Please choose a password')
      errorId = 'password'
    }
    if (!errorId) {
      if (await resetPassword({ password, token })) {
        routeTo('/', true)
      }
    }
  }

  return (
    <LandingPage>
      <Form onSubmit={handleSubmit}>
        <Block>Please enter your new password</Block>
        <SpacerVertical />
        <TextField
          width="100%"
          type={!showPassword ? 'password' : 'text'}
          label="New password"
          value={password}
          onInput={setPassword}
          errorText={passwordError}
          props={{
            id: 'password'
          }}
        />
        <SpacerVertical small />
        <Checkbox
          label="Show password"
          checked={showPassword}
          onChange={setShowPassword}
          width="100%"
        />
        <SpacerVertical small />
        <Button contained type="submit" loading={isResettingPassword} onClick={handleSubmit}>
          Save
        </Button>
      </Form>
    </LandingPage>
  )
}

export default ResetPassword
