import { h } from 'preact'
import { Snackbar as BongoSnackbar, SnackbarItem } from '@sodra/bongo-ui'

import { removeSnackbar } from '../actions'
import { useStore } from '../store'

export const Snackbar = () => {
  const { snackbarItems } = useStore('snackbarItems')
  if (!snackbarItems) {
    return null
  }
  const items = snackbarItems.map((item) => (
    <SnackbarItem
      text={item.message}
      onClosed={removeSnackbar}
      actionText={item.action ? item.action.text : undefined}
      onAction={item.action ? item.action.onClick : undefined}
      visibleTime={item.visibleTime ? item.visibleTime : undefined}
      dismiss={item.dismiss}
    />
  ))
  return <BongoSnackbar>{items}</BongoSnackbar>
}

export default Snackbar
