import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Block, InlineBlock, Row } from 'jsxstyle/preact'
import {
  Button,
  Checkbox,
  Form,
  SpacerHorizontal,
  SpacerVertical,
  TextField
} from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { createImportSource, setError } from '../../actions'

import Page from '../Page'

import googleSheet from './google-sheet.svg'
import { useStore } from '../../store'
import { MapColumns } from '../ImportSource/MapColumns'
import { Sheet, SheetWithRows, SpreadsheetMeta } from 'src/types'

const typeOptions = [{ value: 'google-sheet', label: 'Google Sheet', picture: googleSheet }]

type TypeOptionProps = {
  label: string
  picture: string
  onClick: () => void
}

const TypeOption = ({ label, picture, onClick }: TypeOptionProps) => {
  return (
    <InlineBlock
      background="var(--container-background)"
      padding="20px"
      textAlign="center"
      cursor="pointer"
      border="solid 1px transparent"
      // @ts-ignore
      hoverBorder="solid 1px var(--accent)"
      boxSizing="border-box"
      margin="5px"
      props={{ onClick }}
    >
      <img width="80" height="80" src={picture} />
      <SpacerVertical />
      <Block>{label}</Block>
    </InlineBlock>
  )
}

const defaultColumns = [
  'externalLineId',
  'filename',
  'characterName',
  'eventName',
  'sceneName',
  'line_en',
  'n/a'
]

export const CreateImportSource = () => {
  const { currentGame, isCreatingImportSource } = useStore('currentGame', 'isCreatingImportSource')

  const [type, setType] = useState<string | undefined>()
  const [sheetUrl, setSheetUrl] = useState<string | undefined>()

  const [sheetWithRows, setSheetWithRows] = useState<SheetWithRows[] | undefined>()
  const [allSheets, setAllSheets] = useState<Sheet[] | undefined>()
  const [sheets, setSheets] = useState<Sheet[]>([])

  const [name, setName] = useState('')
  const [autoSync, setAutoSync] = useState(false)
  const [isLoadingSheet, setIsLoadingSheet] = useState(false)
  const [rows, setRows] = useState<any[] | undefined>()

  const [firstRowIsHeader, setFirstRowIsHeader] = useState(false)
  const [generateUniqueFilenames, setGenerateUniqueFilenames] = useState(false)
  const [generateAiTakesForNewLines, setGenerateAiTakesForNewLines] = useState(false)
  const [generateAiTakesForUpdatedLines, setGenerateAiTakesForUpdatedLines] = useState(false)
  const [useAsSelectedTake, setUseAsSelectedTake] = useState(false)
  const [deleteMissingLines, setDeleteMissingLines] = useState(false)
  const [deleteUnusedCharacters, setDeleteUnusedCharacters] = useState(false)
  const [deleteUnusedEvents, setDeleteUnusedEvents] = useState(false)
  const [useSceneSeqNumber, setUseSceneSeqNumber] = useState(false)

  const [columns, setColumns] = useState<string[] | undefined>()

  const toggleSheet = (sheet: Sheet) => {
    if (sheets?.some((s) => s.sheetId === sheet.sheetId)) {
      setSheets((sheets) => sheets?.filter((s) => s.sheetId !== sheet.sheetId))
    } else {
      setSheets((sheets) => [...sheets, sheet])
    }
  }

  const getSpreadsheetMeta = async (spreadsheetId: string): Promise<SpreadsheetMeta> => {
    const spreadSheetUrl = new URL(`${import.meta.env.VITE_SPREADSHEET_URL}/meta`)
    spreadSheetUrl.searchParams.set('spreadsheetId', spreadsheetId)
    const res = await fetch(spreadSheetUrl, {
      method: 'POST'
    })
    if (!res.ok) {
      const { error } = await res.json()
      throw new Error(error)
    }
    return await res.json()
  }

  const loadSheet = async () => {
    setIsLoadingSheet(true)

    try {
      const url = new URL(sheetUrl!.replace('#', '?'))
      const spreadsheetId = url.pathname.split('/')[3]

      const meta = await getSpreadsheetMeta(spreadsheetId)

      setAllSheets(meta.sheets)

      if (meta.sheets.length === 1) {
        setSheets([meta.sheets[0]])
        await importRows([meta.sheets[0]])
      } else if (sheets) {
        setSheets(sheets.filter((sheet) => meta.sheets.some((s) => s.sheetId === sheet.sheetId)))
      }
    } catch (err: any) {
      setError(
        new Error(`
          Something went wrong. Please check that the URL has format https://docs.google.com/spreadsheets/d/spreadsheetId/edit#gid=sheetId
          and is shared with email ${import.meta.env.VITE_SPREADSHEET_SERVICE_ACCOUNT}.
          ${err.message}
        `)
      )
    } finally {
      setIsLoadingSheet(false)
    }
  }

  const importRows = async (sheets: Sheet[]) => {
    setIsLoadingSheet(true)

    try {
      const url = new URL(sheetUrl!.replace('#', '?'))
      const spreadsheetId = url.pathname.split('/')[3]

      const spreadSheetUrl = new URL(`${import.meta.env.VITE_SPREADSHEET_URL}/rows`)
      spreadSheetUrl.searchParams.set('spreadsheetId', spreadsheetId)
      for (let sheet of sheets) {
        spreadSheetUrl.searchParams.append('sheetId', String(sheet.sheetId))
      }

      const res = await fetch(spreadSheetUrl, {
        method: 'POST'
      })

      if (!res.ok) {
        const { error } = await res.json()
        throw new Error(error)
      }

      const sheetWithRows: SheetWithRows[] = await res.json()

      setSheetWithRows(sheetWithRows)
    } catch (err: any) {
      setError(
        new Error(`
          Something went wrong. Please check that the URL has format https://docs.google.com/spreadsheets/d/spreadsheetId/edit#gid=sheetId
          and is shared with email ${import.meta.env.VITE_SPREADSHEET_SERVICE_ACCOUNT}.
          ${err.message}
        `)
      )
    } finally {
      setIsLoadingSheet(false)
    }
  }

  const handleSubmit = async () => {
    if (
      await createImportSource({
        name,
        type,
        url: sheetUrl,
        data: { sheets },
        firstRowIsHeader,
        generateAiTakesForNewLines,
        generateAiTakesForUpdatedLines,
        generateUniqueFilenames,
        useAsSelectedTake,
        deleteMissingLines,
        deleteUnusedCharacters,
        deleteUnusedEvents,
        useSceneSeqNumber,
        columns,
        autoSync
      })
    ) {
      onClose()
    }
  }

  const onClose = () => goBack('/import-sources')

  if (columns) {
    return (
      <Page title="Create import source" onBack={onClose}>
        <Form maxWidth="500px" onSubmit={handleSubmit}>
          <TextField width="100%" label="Name" value={name} onInput={setName} />
          <SpacerVertical />
          <Checkbox label="Auto sync (beta)" checked={autoSync} onChange={setAutoSync} />
          <SpacerVertical />
          <Row alignItems="center">
            <Button type="submit" contained loading={isCreatingImportSource}>
              Create
            </Button>
            <SpacerHorizontal />
            <Button onClick={() => setColumns(undefined)}>Cancel</Button>
          </Row>
        </Form>
      </Page>
    )
  }

  if (sheetWithRows) {
    return (
      <Page title="Create import source" onBack={onClose}>
        <MapColumns
          sheetWithRows={sheetWithRows}
          saveText="Continue"
          onSave={(params) => {
            setFirstRowIsHeader(params.firstRowIsHeader)
            setGenerateUniqueFilenames(params.generateUniqueFilenames)
            setGenerateAiTakesForNewLines(params.generateAiTakesForNewLines)
            setGenerateAiTakesForUpdatedLines(params.generateAiTakesForUpdatedLines)
            setUseAsSelectedTake(params.useAsSelectedTake)
            setColumns(params.columns)
            setDeleteMissingLines(params.deleteMissingLines)
            setDeleteUnusedCharacters(params.deleteUnusedCharacters)
            setDeleteUnusedEvents(params.deleteUnusedEvents)
            setUseSceneSeqNumber(params.useSceneSeqNumber)
          }}
          onCancel={() => setSheetWithRows(undefined)}
        />
      </Page>
    )
  }

  if (allSheets && allSheets.length > 1) {
    return (
      <Page title="Create import source" onBack={onClose}>
        <Block maxWidth="500px">
          <Block>Select one or more spreadsheet tabs to import</Block>
          <SpacerHorizontal />
          {allSheets.map((sheet) => (
            <Row alignItems="center">
              <Checkbox
                label={sheet.title}
                checked={sheets?.some((s) => s.sheetId === sheet.sheetId)}
                onChange={() => toggleSheet(sheet)}
              />
            </Row>
          ))}
          <SpacerVertical />
          <Row alignItems="center">
            <Button
              contained
              loading={isLoadingSheet}
              disabled={sheets.length === 0}
              onClick={() => importRows(sheets)}
            >
              Continue
            </Button>
            <SpacerHorizontal />
            <Button onClick={onClose}>Cancel</Button>
          </Row>
        </Block>
      </Page>
    )
  }

  if (type) {
    return (
      <Page title="Create import source" onBack={onClose}>
        <Block maxWidth="500px">
          <TextField width="100%" label="Google sheet URL" value={sheetUrl} onInput={setSheetUrl} />
          <SpacerVertical />
          <Block>
            Please share the spreadsheet with Speechless service account email{' '}
            <em>{import.meta.env.VITE_SPREADSHEET_SERVICE_ACCOUNT}</em>. Viewer access is enough.
          </Block>
          <SpacerVertical />
          <Row alignItems="center">
            <Button
              contained
              loading={isLoadingSheet}
              disabled={!sheetUrl || sheetUrl.trim() === ''}
              onClick={loadSheet}
            >
              Continue
            </Button>
            <SpacerHorizontal />
            <Button onClick={() => setType(undefined)}>Cancel</Button>
          </Row>
        </Block>
      </Page>
    )
  }

  return (
    <Page title="Create import source" onBack={onClose}>
      {typeOptions.map((typeOption) => {
        return <TypeOption {...typeOption} onClick={() => setType(typeOption.value)} />
      })}
    </Page>
  )
}
