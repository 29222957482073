import { useSignal, useSignalEffect } from '@preact/signals'
import {
  DeleteIcon,
  ErrorIcon,
  IconButton,
  LockIcon,
  MoreIcon,
  PlayIcon,
  ProgressCircular,
  StopIcon,
  Switch,
  WarningIcon,
  ZoomInIcon
} from '@sodra/bongo-ui'
import { Block, Grid, Row } from 'jsxstyle/preact'
import { Fragment, JSX } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { audioBufferFromURI } from '../../lib/audioBufferFromURI'
import { AudioEditor } from '../AudioEditor'
import { TooltipWrapper } from './TooltipWrapper'
import { Take } from './lineRecorderState'
import { formatDuration } from '../../lib/format-duration'
import { usePopupMenu } from '../../lib/use-popup-menu'
import Avatar from '../Avatar'
import { useAudioBufferPlayer } from '../../lib/useAudioBufferPlayer'
import { blurActiveElement } from './blur-active-element'
import { lineRecorder, recorder } from './LineRecorder'
import { TakeFocusMode } from './TakeFocusMode'

type LineTakeProps = {
  onDeleteTake?: (takeId: string) => Promise<void>
  onUpdateTake?: (takeId: string, take: Partial<Take>) => Promise<void>
  onPlayTake?: (take: Take, startTime?: number) => Promise<void>
  onStopTake?: (take: Take) => Promise<void>
  take: Take
}

function LineTake({ take, onDeleteTake, onUpdateTake, onPlayTake, onStopTake }: LineTakeProps) {
  const audioBuffer = useSignal<AudioBuffer | null>(take.audioBuffer ?? null)

  const uploadTrimTimeout = useRef<NodeJS.Timeout | null>(null)
  const start = useSignal<number>(take.trimStart ?? 0)
  const end = useSignal<number>(take.trimEnd ?? audioBuffer.value?.duration ?? 0)
  const moving = useSignal<boolean>(false)

  const [isFocusModeOpen, setIsFocusModeOpen] = useState(false)

  const isActive = lineRecorder.value?.activeTake.value?.id === take.id

  const uri = take.rawAudioUri

  useEffect(() => {
    if (uri) {
      audioBufferFromURI(uri)
        .then((buffer) => {
          audioBuffer.value = buffer

          // Some takes that have been uploaded/created outside of the recorder
          // may have undefined trim values, so we need to set them to the full buffer duration.
          if (take.trimStart === undefined) {
            start.value = 0
          }
          if (take.trimEnd === undefined) {
            end.value = buffer.duration
          }
        })
        .catch((err) => {
          console.log('Could not get audio buffer from uri', { uri, err })
        })
    } else {
      audioBuffer.value = null
    }
  }, [uri])

  // This is to make sure value stays up to date with new data that comes in while polling.
  useEffect(() => {
    if (
      take.trimStart !== undefined &&
      JSON.stringify({ trimStart: start.value }) !== JSON.stringify({ trimStart: take.trimStart })
    ) {
      start.value = take.trimStart
    }
  }, [take.trimStart])
  useEffect(() => {
    if (
      take.trimEnd !== undefined &&
      JSON.stringify({ trimEnd: end.value }) !== JSON.stringify({ trimEnd: take.trimEnd })
    ) {
      end.value = take.trimEnd
    }
  }, [take.trimEnd])

  useSignalEffect(() => {
    if (!lineRecorder.value || !line) {
      return
    }
    if (
      JSON.stringify({ trimStart: start.value, trimEnd: end.value }) !==
      JSON.stringify({ trimStart: take.trimStart, trimEnd: take.trimEnd })
    ) {
      if (uploadTrimTimeout.current) {
        clearTimeout(uploadTrimTimeout.current)
      }
      if (!moving.value) {
        let updateTrim: () => Promise<void>
        updateTrim = async () => {
          // Delay again if take is currently being updated
          if (take.status === 'loading') {
            uploadTrimTimeout.current = setTimeout(updateTrim, 1000)
          }
          if (lineRecorder.value && audioBuffer.value) {
            lineRecorder.value.updateTrim({
              lineId: line.id,
              takeId: take.id,
              audioBuffer: audioBuffer.value,
              start: Number(start.value),
              end: Number(end.value),
              onUpload: onUpdateTake
                ? async (takeId, uri, start, end) => {
                    await onUpdateTake(takeId, { uri, trimStart: start, trimEnd: end })
                  }
                : undefined
            })
          }
        }

        uploadTrimTimeout.current = setTimeout(updateTrim, 1000)
      }
    }
  })

  const line = lineRecorder.value?.lines.value.find((line) => line.id === take.lineId)

  const { PopupMenu, showMenu } = usePopupMenu({
    options: [
      {
        label: 'Delete take',
        icon: DeleteIcon as unknown as JSX.Element,
        onClick: () => {
          if (lineRecorder.value && line) {
            lineRecorder.value.deleteTake({ lineId: line.id, take, onDelete: onDeleteTake })
          }
        }
      }
    ]
  })

  const isRecording = recorder.value?.state.value.mediaRecorderState === 'recording'

  const audioBufferPlayer = useAudioBufferPlayer({
    audioBuffer: audioBuffer.value ?? null,
    start: start.value,
    end: end.value,
    registerKeyboardEvent: isActive,
    isRecording,
    onPlay: (startTime?: number) => {
      if (onPlayTake) {
        onPlayTake(take, startTime)
      }
    },
    onStop: () => {
      if (onStopTake) {
        onStopTake(take)
      }
    }
  })

  useEffect(() => {
    if (lineRecorder.value) {
      lineRecorder.value.isTakeFocusModeEnabled.value = isFocusModeOpen
    }
  }, [isFocusModeOpen])

  const closeFocusDialog = () => {
    lineRecorder?.value?.stopActiveTake()
    setIsFocusModeOpen(false)
  }

  const trimStart = (step: number) => {
    const newStartValue = start.value + step
    if (newStartValue >= 0 && newStartValue < end.value) {
      start.value = newStartValue
    }
  }

  const trimEnd = (step: number) => {
    const duration = audioBuffer.value?.duration ?? 0
    const newEndValue = end.value + step
    if (newEndValue <= duration && newEndValue > start.value) {
      end.value = newEndValue
    }
  }

  useKeyboardEvents({
    isActive,
    isTrimLocked: !!take.trimLocked,
    takeStatus: take.status,
    trimStart,
    trimEnd,
    isFocusModeOpen,
    onOpenFocusMode: () => {
      if (recorder.value?.state.value.mediaRecorderState !== 'recording') {
        lineRecorder?.value?.stopActiveTake()
        setIsFocusModeOpen(true)
      }
    },
    onSetSelectedTake: () => {
      lineRecorder.value?.setSelectedTake({
        lineId: take.lineId!,
        takeId: take.id
      })
    }
  })

  if (!line) return null

  if (!audioBuffer.value) return null

  if (!lineRecorder.value) return null

  if (!audioBufferPlayer) return null

  // Save reference to the player, to be able to access it from line recorder state
  take.audioBufferPlayer = audioBufferPlayer

  const isSelectedTake =
    take.id === line.translations[lineRecorder.value.language.value].selectedTake?.id
  const setTrimLocked = (locked: boolean) => {
    lineRecorder.value?.setTrimLock({
      lineId: line.id,
      takeId: take.id,
      locked
    })
  }

  const playOnClick = (startValue: number) => {
    if (!isRecording) {
      lineRecorder.value?.selectTake(take.id)
      audioBufferPlayer?.play({ startFrom: startValue })
    }
  }

  const togglePlay = () => {
    if (audioBufferPlayer.state === 'playing') {
      audioBufferPlayer.stop()
    } else {
      if (!isRecording) {
        lineRecorder.value?.selectTake(take.id)
        audioBufferPlayer.play()
      }
    }
  }

  const onSetSelectedTake = () => {
    if (lineRecorder.value) {
      lineRecorder.value.setSelectedTake({
        lineId: line.id,
        takeId: take.id
      })
    }
  }

  const setTrimStart = (value: number, isMoving: boolean) => {
    if (uploadTrimTimeout.current) {
      clearTimeout(uploadTrimTimeout.current)
    }
    lineRecorder.value?.selectTake(take.id)
    start.value = value
    moving.value = isMoving
  }
  const setTrimEnd = (value: number, isMoving: boolean) => {
    if (uploadTrimTimeout.current) {
      clearTimeout(uploadTrimTimeout.current)
    }
    lineRecorder.value?.selectTake(take.id)
    end.value = value
    moving.value = isMoving
  }

  return (
    <>
      {isFocusModeOpen && (
        <TakeFocusMode
          take={take}
          audioBuffer={audioBuffer.value}
          audioBufferPlayer={audioBufferPlayer}
          start={start.value}
          end={end.value}
          onSetStart={setTrimStart}
          onSetEnd={setTrimEnd}
          onTrimStart={trimStart}
          onTrimEnd={trimEnd}
          onSetTrimLocked={setTrimLocked}
          onSetSelectedTake={onSetSelectedTake}
          isSelectedTake={isSelectedTake}
          onClose={closeFocusDialog}
        />
      )}
      <Grid
        backgroundColor={isActive ? 'var(--surface-floating)' : undefined}
        borderBottom="solid 1px var(--container-outline-lighter)"
        borderLeft={`solid 5px ${isActive ? 'var(--on-surface)' : 'transparent'}`}
        padding="0px 15px"
        height="70px"
        key={take.id}
        gridTemplateColumns="50px 40px 40px minmax(200px, 1000px) auto auto 150px 40px 40px"
        alignItems="center"
        props={{
          id: take.id
        }}
      >
        <Row justifyContent="center" alignItems="center">
          <IconButton
            icon={ZoomInIcon}
            tooltipText="Focus"
            color="var(--on-surface-light)"
            disabled={recorder.value?.state.value?.mediaRecorderState === 'recording'}
            onFocus={blurActiveElement}
            focusable={false}
            onClick={() => {
              lineRecorder.value?.selectTake(take.id)
              lineRecorder?.value?.stopActiveTake()
              setIsFocusModeOpen(true)
            }}
          />
        </Row>

        {/* Take index */}
        <TooltipWrapper tooltipText={take.seqLanguage ? `Take ${take.seqLanguage}` : ''}>
          {take.seqLanguage ? `${take.seqLanguage}.` : ''}
        </TooltipWrapper>

        {/* Play button */}
        <IconButton
          color={audioBufferPlayer?.state === 'playing' ? 'var(--accent)' : 'var(--on-surface)'}
          icon={audioBufferPlayer?.state === 'playing' ? StopIcon : PlayIcon}
          onClick={togglePlay}
          tooltipText="Play take"
          onFocus={blurActiveElement}
          focusable={false}
          disabled={isRecording}
        />

        {/* Waveform */}
        <Row alignItems="center">
          <AudioEditor
            height="40px"
            currentTime={
              audioBufferPlayer?.state === 'playing' ? audioBufferPlayer?.currentTime : 0
            }
            audioBuffer={audioBuffer.value}
            start={start.value}
            end={end.value}
            setStart={setTrimStart}
            setEnd={setTrimEnd}
            color="--on-surface"
            selectedColor="--on-surface-lighter"
            progressColor="--accent"
            progressBgColor="--accent-light"
            trimLocked={!!take.trimLocked || isFocusModeOpen}
            isUpdatingTake={take.status === 'loading'}
            onPlay={playOnClick}
            playOffsetTime={audioBufferPlayer?.playOffsetTime}
            disableVisualizerRedraw={isFocusModeOpen}
          />
        </Row>

        <Row justifyContent="center" alignItems="center">
          {take.trimLocked && (
            <IconButton
              icon={LockIcon}
              tooltipText="Unlock"
              color="var(--accent)"
              onClick={() => {
                setTrimLocked(false)
              }}
            />
          )}
          {!take.trimLocked && (
            <IconButton
              icon={LockIcon}
              tooltipText="Lock"
              color="var(--on-surface-light)"
              onClick={() => {
                setTrimLocked(true)
              }}
            />
          )}
        </Row>

        {/* Status icons */}
        <Grid placeItems="center" height="40px" gridTemplateColumns="repeat(2, 40px)">
          <Block>
            {take.peakWarning && (
              <IconButton
                icon={WarningIcon}
                tooltipText="Input peaked during the recording of this take"
                color="var(--warning)"
                onFocus={blurActiveElement}
                focusable={false}
              />
            )}
          </Block>
          <Block>
            {take.status === 'loading' && <ProgressCircular size="15px" />}
            {take.error && (
              <IconButton
                icon={ErrorIcon}
                tooltipText={`${take.error}`}
                color="var(--error)"
                onFocus={blurActiveElement}
                focusable={false}
              />
            )}
          </Block>
        </Grid>

        {/* Avatar */}
        <Row alignItems="center" gap="10px">
          <Avatar
            size={30}
            src={take.aiVoice?.picture ?? take.user?.picture ?? take.voice?.picture}
          />
          <Block>
            <Block fontSize="14px">
              {take.aiVoice?.name ?? take.user?.name ?? take.voice?.name}
            </Block>
            <Block fontSize="12px" color="var(--on-surface-light)">
              {formatDuration(take.created!)}
            </Block>
          </Block>
        </Row>

        {/* Set active button */}
        <Block>
          <Switch
            disabled={take.error}
            onChange={() => {
              if (lineRecorder.value) {
                lineRecorder.value.setSelectedTake({
                  lineId: line.id,
                  takeId: take.id
                })
              }
            }}
            on={isSelectedTake}
            tooltipText="Set selected take"
          />
        </Block>

        {/* More button */}
        <Block>
          <IconButton
            icon={MoreIcon}
            onClick={(e: BuiClickEvent) => showMenu(e, take)}
            color="var(--on-surface-light)"
          />
          {PopupMenu}
        </Block>
      </Grid>
    </>
  )
}

type Props = {
  onDeleteTake?: (takeId: string) => Promise<void>
  onUpdateTake?: (takeId: string, take: Partial<Take>) => Promise<void>
  onPlayTake?: (take: Take, startTime?: number) => Promise<void>
  onStopTake?: (take: Take) => Promise<void>
}

export function LineTakes({ onDeleteTake, onUpdateTake, onPlayTake, onStopTake }: Props) {
  if (!lineRecorder.value) return null

  if (lineRecorder.value.activeLineTakes.value.length === 0) {
    return (
      <Block padding="20px" color="var(--on-surface-light)">
        Recorded takes will show up here
      </Block>
    )
  }

  return (
    <>
      {lineRecorder.value.activeLineTakes.value.map((take, index) => {
        return (
          <Fragment key={take.id}>
            <LineTake
              onDeleteTake={onDeleteTake}
              onUpdateTake={onUpdateTake}
              onPlayTake={onPlayTake}
              onStopTake={onStopTake}
              take={take}
            />
          </Fragment>
        )
      })}
    </>
  )
}

function useKeyboardEvents({
  isActive,
  isTrimLocked,
  trimStart,
  trimEnd,
  takeStatus,
  isFocusModeOpen,
  onOpenFocusMode,
  onSetSelectedTake
}: {
  isActive: boolean
  isTrimLocked: boolean
  trimStart: (step: number) => void
  trimEnd: (step: number) => void
  takeStatus?: 'done' | 'loading'
  isFocusModeOpen: boolean
  onOpenFocusMode: () => void
  onSetSelectedTake: () => void
}) {
  const holding = useSignal<'z' | 'x' | null>(null)
  const step = 0.04

  useEffect(() => {
    if (!isActive) {
      return
    }

    const keyDownHandler = (e: KeyboardEvent) => {
      const keyBindings: Record<string, () => void> = {
        ArrowLeft: () => {
          if (isFocusModeOpen || isTrimLocked || takeStatus === 'loading') {
            return
          }
          if (holding.value === 'z') {
            trimStart(-step)
          }
          if (holding.value === 'x') {
            trimEnd(-step)
          }
        },
        ArrowRight: () => {
          if (isFocusModeOpen || isTrimLocked || takeStatus === 'loading') {
            return
          }
          if (holding.value === 'z') {
            trimStart(step)
          }
          if (holding.value === 'x') {
            trimEnd(step)
          }
        },
        s: onSetSelectedTake,
        f: () => {
          if (!isFocusModeOpen) {
            onOpenFocusMode()
          }
        },
        z: () => {
          if (isFocusModeOpen || isTrimLocked || takeStatus === 'loading') {
            return
          }
          if (holding.value === null) {
            holding.value = 'z'
          }
        },
        x: () => {
          if (isFocusModeOpen || isTrimLocked || takeStatus === 'loading') {
            return
          }
          if (holding.value === null) {
            holding.value = 'x'
          }
        }
      }

      const callback = e.metaKey || e.ctrlKey ? undefined : keyBindings[e.key]

      if (callback) {
        e.preventDefault()
        callback()
      }
    }

    const keyUpHandler = (e: KeyboardEvent) => {
      e.preventDefault()
      const callback = {
        z: () => {
          if (holding.value === 'z') {
            holding.value = null
          }
        },
        x: () => {
          if (holding.value === 'x') {
            holding.value = null
          }
        }
      }[e.key]

      if (callback) {
        e.preventDefault()
        e.stopPropagation()
        callback()
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('keyup', keyUpHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
      document.removeEventListener('keyup', keyUpHandler)
    }
  }, [isActive, isTrimLocked, takeStatus, isFocusModeOpen])
}
