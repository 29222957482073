import { h } from 'preact'
import { useEffect, useMemo, useRef, useState } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'
import { goBack, Route, routeTo } from '@sodra/prutt'
import {
  Button,
  Checkbox,
  Dialog,
  ExpandLessIcon,
  ExpandMoreIcon,
  Link,
  List,
  ListItem,
  NativeSelect,
  ProgressCircular,
  SpacerHorizontal,
  SpacerVertical,
  WarningIcon
} from '@sodra/bongo-ui'

import { loadScript } from '../load-script'
import { fetchLineLabels, setError, showSnackbar, updateGame, uploadExcel } from '../actions'

import Page from './Page'
import { useStore } from '../store'
import { capitalize } from './capitalize'
import { ScrollableDataTable } from './ScrollableDataTable'
import { formatLanguage } from '../format-language'
import { CreateLineAttribute } from './Lines/LineAttributes/CreateLineAttribute'
import { get, post } from '../api'
import { confirm, pluralize } from 'lib'
import { HelpExternalLineId } from './HelpExternalLineId'
import { AiQuota, Character, Event, Sheet, SpreadsheetMeta } from '../types'
import { GameAiQuota } from './GameDashboard'
import { fetchBillingAccount, fetchBillingAccounts } from '../actions/billing-accounts'
import { checkBuyAiTimePreconditions } from './check-buy-ai-time-preconditions'
import { BuyExtraAiQuotaTimeDialog } from './BuyExtraAiQuotaTimeDialog'
import { isGameAiQuotaEmpty } from './is-game-ai-quota-empty'
import { validate as validateUuid } from 'uuid'
import SelectBillingAccount from './SelectBillingAccount'
import { confirmSelectBillingAccount } from './confirmSelectBillingAccount'
import { HelpDeleteMissingLines } from './HelpDeleteMissingLines'
import { useLocalStorageState } from 'src/use-local-storage-state'
import Spinner from './Spinner'
import { HelpUseSceneSeqNumber } from './HelpUseSceneSeqNumber'

const pageSize = 20

export const UploadExcel = () => {
  const {
    currentGame,
    isUploadingExcel,
    lineLabels = [],
    lineAttributes = [],
    billingAccount,
    billingAccounts = []
  } = useStore(
    'currentGame',
    'isUploadingExcel',
    'lineAttributes',
    'lineLabels',
    'billingAccount',
    'billingAccounts'
  )
  const fileInput = useRef<HTMLInputElement>(null)
  const [rows, setRows] = useState<any[]>()
  const [columns, setColumns] = useState<string[]>([])
  const [columnIndex, setColumnIndex] = useState<number>(0)

  const [page, setPage] = useState(0)

  const [firstRowIsHeader, setFirstRowIsHeader] = useLocalStorageState(
    'speechless:lines:import:firstRowIsHeader',
    false
  )
  const [generateAITakesForNewLines, setGenerateAITakesForNewLines] = useLocalStorageState(
    'speechless:lines:import:generateAiTakesForNewLines',
    false
  )
  const [generateAITakesForUpdatedLines, setGenerateAITakesForUpdatedLines] = useLocalStorageState(
    'speechless:lines:import:generateAiTakesForUpdatedLines',
    false
  )
  const [useAsSelectedTake, setUseAsSelectedTake] = useLocalStorageState(
    'speechless:lines:import:useAsSelectedTake',
    false
  )
  const [generateUniqueFilenames, setGenerateUniqueFilenames] = useLocalStorageState(
    'speechless:lines:import:generateUniqueFilenames',
    false
  )
  const [shouldDeleteMissingLines, setShouldDeleteMissingLines] = useLocalStorageState(
    'speechless:lines:import:shouldDeleteMissingLines',
    false
  )
  const [shouldDeleteUnusedCharacters, setShouldDeleteUnusedCharacters] = useLocalStorageState(
    'speechless:lines:import:shouldDeleteUnusedCharacters',
    false
  )
  const [shouldDeleteUnusedEvents, setShouldDeleteUnusedEvents] = useLocalStorageState(
    'speechless:lines:import:shouldDeleteUnusedEvents',
    false
  )
  const [useSceneSeqNumber, setUseSceneSeqNumber] = useLocalStorageState(
    'speechless:lines:import:useSceneSeqNumber',
    false
  )

  const [isDeleteMissingLinesHelpVisible, setIsDeleteMissingLinesHelpVisible] = useState(false)
  const [isExternalLineIdHelpVisible, setIsExternalLineIdHelpVisible] = useState(false)
  const [isUseSceneSeqNumberHelpVisible, setIsUseSceneSeqNumberHelpVisible] = useState(false)

  const [existingExternalLineIds, setExistingExternalLineIds] = useState<string[]>([])

  const [duplicateFilanames, setDuplicateFilenames] = useState<string[] | undefined>()
  const [deletedCharacters, setDeletedCharacters] = useState<Character[] | undefined>()
  const [deletedEvents, setDeletedEvents] = useState<Event[] | undefined>()

  const [gameAiQuota, setGameAiQuota] = useState<GameAiQuota | undefined>()
  const [buyExtraTimeAiQuota, setBuyExtraTimeAiQuota] = useState<AiQuota | undefined>(undefined)

  const [isUpdatingUseExternalLineId, setIsUpdatingUseExternalLineId] = useState(false)
  const [showSelectBillingAccountDialog, setShowSelectBillingAccountDialog] = useState(false)
  const [allSheets, setAllSheets] = useState<Sheet[] | undefined>()
  const [selectedSheets, setSelectedSheets] = useState<Sheet[]>([])
  const [googleDocId, setGoogleDocId] = useState<string | undefined>()
  const [sourceFile, setSourceFile] = useState<File | undefined>()

  const generateAITakes = generateAITakesForNewLines || generateAITakesForUpdatedLines

  useEffect(() => {
    fetchLineLabels()
    fetchBillingAccounts()
  }, [])

  useEffect(() => {
    if (currentGame) {
      fetchGameAiQuota()

      if (currentGame.billingAccount) {
        fetchBillingAccount(currentGame.billingAccount.id)
      }
    }
  }, [currentGame])

  const fetchGameAiQuota = async () => {
    if (currentGame) {
      get(`/games/${currentGame.id}/ai-quota`).then(({ data: aiQuota }) => {
        setGameAiQuota(aiQuota)
      })
    }
  }

  const fetchAllExternalLineIds = async () => {
    const { data: externalLineIds } = await get(`/games/${currentGame!.id}/external-line-ids`)
    setExistingExternalLineIds(externalLineIds)
  }

  useEffect(() => {
    fetchAllExternalLineIds()
  }, [])

  type ColumnOption = {
    value: string
    label: string
    required?: boolean
    multiple?: boolean
  }
  const columnOptions = useMemo(() => {
    const options: ColumnOption[] = [{ value: 'n/a', label: '-- N/A --' }]

    if (currentGame?.useExternalLineId && !currentGame.useFilenameAsExternalLineId) {
      options.push({ value: 'externalLineId', label: 'External line ID*', required: true })
    }

    options.push({ value: 'lineId', label: 'Speechless line ID' })

    if (!generateUniqueFilenames || currentGame?.useFilenameAsExternalLineId) {
      options.push({ value: 'filename', label: `Filename*`, required: true })
    }

    options.push(
      { value: 'characterName', label: 'Character*', required: true },
      { value: 'eventName', label: 'Event' },
      { value: 'sceneName', label: 'Scene' }
    )

    if (useSceneSeqNumber) {
      options.push({ value: 'sceneSeqNumber', label: 'Scene order*', required: true })
    }

    options.push(
      { value: 'eventDescription', label: 'Event description' },
      {
        value: `line_${currentGame?.primaryLanguage}`,
        label:
          currentGame?.secondaryLanguages && currentGame?.secondaryLanguages.length > 0
            ? `Line (${formatLanguage(currentGame.primaryLanguage)})*`
            : 'Line*',
        required: true
      }
    )

    if (currentGame?.secondaryLanguages) {
      for (let language of currentGame.secondaryLanguages) {
        options.push({
          value: `line_${language}`,
          label: `Line (${formatLanguage(language)})`
        })
      }
    }

    options.push({
      value: `ai_line_${currentGame?.primaryLanguage}`,
      label:
        currentGame?.secondaryLanguages && currentGame?.secondaryLanguages.length > 0
          ? `AI TTS prompt (${formatLanguage(currentGame.primaryLanguage)})*`
          : 'AI TTS prompt'
    })
    if (currentGame?.secondaryLanguages) {
      for (let language of currentGame.secondaryLanguages) {
        options.push({
          value: `ai_line_${language}`,
          label: `AI TTS prompt (${formatLanguage(language)})`
        })
      }
    }

    options.push({ value: 'description', label: 'Line description' })

    for (let attribute of lineAttributes) {
      options.push({
        value: attribute.id,
        label: attribute.name + (attribute.required ? '*' : ''),
        required: attribute.required
      })
    }

    options.push({ value: 'labels', label: 'Labels (comma separated)', multiple: true })

    for (let label of lineLabels) {
      options.push({
        value: `label_${label.name}`,
        label: `Label: ${capitalize(label.name)}`
      })
    }

    options.push({ value: 'create-line-attribute', label: '+ New attribute' })

    return options
  }, [
    lineAttributes,
    lineLabels,
    currentGame?.useExternalLineId,
    currentGame?.useFilenameAsExternalLineId,
    currentGame?.primaryLanguage,
    currentGame?.secondaryLanguages,
    generateUniqueFilenames,
    useSceneSeqNumber
  ])

  const setColumnAt = (index: number, value: any) => {
    if (value === 'create-line-attribute') {
      setColumnIndex(index)
      routeTo('/upload-excel/create-line-attribute-for-column')
      return
    }
    const option = columnOptions.find((option) => option.value === value)
    setColumns((columns) => {
      const updatedColumns = columns.map((v, i) => {
        if (i === index) {
          return value
        }
        if (v === value && !option?.multiple) {
          return 'n/a'
        }
        return v
      })
      return updatedColumns
    })
  }

  useEffect(() => {
    if (!currentGame?.useExternalLineId) {
      // Reset selected column to N/A if external line IDs is disabled
      const externalLineIdColIndex = columns.findIndex(
        (columnValue) => columnValue === 'externalLineId'
      )
      if (externalLineIdColIndex > -1) {
        setColumnAt(externalLineIdColIndex, 'n/a')
      }
    }
  }, [currentGame?.useExternalLineId])

  useEffect(() => {
    if (generateUniqueFilenames) {
      // Reset filename to N/A if generate unique filenames is enabled
      const filenameColIndex = columns.findIndex((columnValue) => columnValue === 'filename')
      if (filenameColIndex > -1) {
        setColumnAt(filenameColIndex, 'n/a')
      }
    }
  }, [generateUniqueFilenames])

  const toggleSelectedSheet = (sheet: Sheet) => {
    if (selectedSheets?.some((selectedSheet) => selectedSheet.sheetId === sheet.sheetId)) {
      setSelectedSheets((selectedSheets) =>
        selectedSheets?.filter((selectedSheet) => selectedSheet.sheetId !== sheet.sheetId)
      )
    } else {
      setSelectedSheets((selectedSheets) => [...selectedSheets, sheet])
    }
  }
  const setUseExternalLineId = (useExternalLineId: boolean) => {
    if (!currentGame) {
      return
    }
    setIsUpdatingUseExternalLineId(true)
    // Change in currentGame to reflect change immediately in checkbox
    currentGame.useExternalLineId = useExternalLineId

    // Slight delay just to avoid a "flashing" circular progress
    setTimeout(() => {
      updateGame({ useExternalLineId: useExternalLineId })
        .then(() => setIsUpdatingUseExternalLineId(false))
        .catch(() => {
          // Revert on error
          currentGame.useExternalLineId = !useExternalLineId
        })
    }, 500)
  }

  const setUseFilenameAsExternalLineId = (useFilenameAsExternalLineId: boolean) => {
    if (!currentGame) {
      return
    }
    setIsUpdatingUseExternalLineId(true)
    // Change in currentGame to reflect change immediately in checkbox
    currentGame.useFilenameAsExternalLineId = useFilenameAsExternalLineId

    // Slight delay just to avoid a "flashing" circular progress
    setTimeout(() => {
      updateGame({ useFilenameAsExternalLineId })
        .then(() => setIsUpdatingUseExternalLineId(false))
        .catch(() => {
          // Revert on error
          currentGame.useFilenameAsExternalLineId = !useFilenameAsExternalLineId
        })
    }, 500)
  }

  const createBillingAccount = () => {
    if (!currentGame) {
      return
    }
    const params = new URLSearchParams({
      onSuccessUrl: `/upload-excel?game=${currentGame.shortId}`
    })
    routeTo(`/settings/billing/add-billing-account?${params.toString()}`)
  }

  const addCreditCard = () => {
    if (!currentGame?.billingAccount) {
      return
    }
    const params = new URLSearchParams({
      onSuccessUrl: `/upload-excel?game=${currentGame.shortId}`
    })
    routeTo(
      `/settings/billing/${currentGame.billingAccount.id}/add-credit-card?${params.toString()}`
    )
  }

  const buyExtraAiTime = async () => {
    if (!currentGame) {
      return
    }
    await checkBuyAiTimePreconditions({
      game: currentGame,
      onCreditCardRequired: addCreditCard,
      onBillingAccountRequired: () => {
        if (billingAccounts?.length) {
          setShowSelectBillingAccountDialog(true)
        } else {
          createBillingAccount()
        }
      },
      onSuccess: () => {
        // Open a dialog to buy extra time
        if (gameAiQuota?.billingAccount?.basic?.[0]) {
          setBuyExtraTimeAiQuota(gameAiQuota.billingAccount.basic[0])
        }
      }
    })
  }

  const parseSceneSeqNumber = (colValue: any): number | undefined => {
    if (typeof colValue === 'number') {
      return colValue
    }
    if (typeof colValue === 'string') {
      const match = colValue.trim().match(/(\d+)$/)

      if (match && match.length === 2) {
        return Number.parseInt(match[1])
      }
    }
    return undefined
  }

  const mapDataRow = (row: any[]): Record<string, any> => {
    const res: Record<string, any> = {}
    for (let i = 0; i < columns.length; i++) {
      const columnId = columns[i]
      let columnValue = row[i]
      const columnOption = columnOptions.find((option) => option.value === columnId)

      if (['sceneName', 'eventName'].includes(columnId) && !columnValue?.trim()) {
        columnValue = null
      }

      if (columnId !== 'n/a' && columnValue) {
        if (columnId === 'sceneSeqNumber') {
          res[columnId] = parseSceneSeqNumber(columnValue)
        } else if (res[columnId]) {
          res[columnId].push(columnValue)
        } else if (columnOption?.multiple) {
          res[columnId] = [columnValue]
        } else {
          res[columnId] = columnValue
        }
      }
    }
    return res
  }

  const handleUpload = async () => {
    const missingColumns = columnOptions.filter(
      (option) => option.required && !columns.includes(option.value)
    )

    if (missingColumns.length > 0) {
      setError({
        message: (
          <Block>
            Please provide mappings for the following required columns:
            <SpacerVertical />
            <List>
              {missingColumns.map((option) => {
                return <ListItem text={option.label} />
              })}
            </List>
          </Block>
        )
      })
      return
    }

    if (generateAITakes) {
      if (!gameAiQuota || isGameAiQuotaEmpty(gameAiQuota)) {
        if (
          await confirm({
            title: 'Your AI voices quota is empty',
            message:
              'You need to refill your AI voices quota to generate AI takes for new lines. Would you like to buy some more hours to keep the magic going?',
            confirmText: 'Buy more hours'
          })
        ) {
          await buyExtraAiTime()
        }
        return
      }
    }

    // Warn if it looks like the data contains a header row that has not been "removed"
    if (!firstRowIsHeader && rows?.length) {
      const firstRow = rows[0]

      const probableHeaders = firstRow.filter((value: string) => {
        // Skipping all special characters that can prevent exact match (like '*')
        const onlyWordCharsRegex = /\W/g
        const matchesColumnsHeader = columnOptions.some(
          (option) =>
            option.label.replace(onlyWordCharsRegex, '') === value.replace(onlyWordCharsRegex, '')
        )

        return matchesColumnsHeader
      })

      // Require at least two matching header names to warn, to avoid unnecessary warnings if a single value happens to match a header name
      if (probableHeaders.length > 1) {
        if (
          !(await confirm({
            title: 'Possible header row detected',
            message: (
              <>
                <Row gap="10px" justifyContent="center" alignItems="center">
                  <Block>It looks like the first row of data might be a header row.</Block>
                  <WarningIcon fill="var(--warning)" size="36" />
                </Row>
                <SpacerVertical />
                <Block>
                  Please verify your data and check the box labeled "First row is header" in case
                  your data contains a header row.
                </Block>
                <SpacerVertical />
                <Block>
                  If you are sure that the first row is not a header row, you can ignore this
                  warning and click Continue.
                </Block>
                <SpacerVertical />
              </>
            ),
            confirmText: 'Continue',
            rejectText: 'Cancel'
          }))
        ) {
          return
        }
      }
    }

    const dataRows = firstRowIsHeader ? rows?.slice(1) : rows

    // Warn if trying to import without external line IDs if lines have already been imported with external line IDs
    if (!currentGame?.useExternalLineId && existingExternalLineIds.length > 0) {
      if (
        !(await confirm({
          title: 'Import without external line IDs?',
          message: (
            <>
              <Row gap="10px">
                <Block>
                  {existingExternalLineIds.length}{' '}
                  {pluralize('line', existingExternalLineIds.length)}{' '}
                  {existingExternalLineIds.length === 1 ? 'was' : 'were'} found that{' '}
                  {existingExternalLineIds.length === 1 ? 'has' : 'have'} already been imported to
                  this game using external line IDs.
                </Block>
                <WarningIcon fill="var(--warning)" size="36" />
              </Row>
              <SpacerVertical />
              <Block>
                It is strongly recommended to stick with using external line IDs for all your lines.
              </Block>
              <SpacerVertical />
              <Block>Do you still want to continue with the import?</Block>
              <SpacerVertical />
            </>
          ),
          confirmText: `Continue`,
          rejectText: 'Cancel'
        }))
      ) {
        return
      }
    }

    if (currentGame?.useExternalLineId && !currentGame.useFilenameAsExternalLineId) {
      const externalLineIdColIndex = columns.findIndex((col) => col === 'externalLineId')

      if (externalLineIdColIndex > -1) {
        // Check that every data row contains a unique, non-empty external ID
        const rowsWithoutExternalId =
          dataRows?.filter((row) => !row[externalLineIdColIndex]?.trim()) ?? []

        if (rowsWithoutExternalId.length > 0) {
          const errorRows = rowsWithoutExternalId
            .map(mapDataRow)
            .sort((a, b) => (a.filename?.trim() ?? '').localeCompare(b.filename?.trim() ?? ''))

          setError({
            title: `External line ID missing for ${errorRows.length}
            ${pluralize('line', errorRows.length)}`,
            message: (
              <>
                <Block color="var(--on-surface-light)">
                  External line ID needs to be specified for every line. Please correct the
                  following {pluralize('line', errorRows.length)} in your import data and try again.
                </Block>
                <SpacerVertical />
                <List>
                  {errorRows.map((row) => {
                    const lineText = (
                      <Block overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                        {row[`line_${currentGame?.primaryLanguage ?? 'en'}`]}
                      </Block>
                    )
                    return (
                      <ListItem
                        text={row.filename?.trim() ?? '<No filename>'}
                        secondaryText={lineText}
                      />
                    )
                  })}
                </List>
                <SpacerVertical />
              </>
            )
          })
          return
        }

        const externalLineIds: string[] =
          dataRows?.map((dataRow) => dataRow[externalLineIdColIndex]) ?? []

        const duplicateIds: string[] = [
          ...new Set(
            externalLineIds.filter(
              (id, i) =>
                externalLineIds.slice(i + 1).findIndex((id2, i2) => id2 === id && i2 !== i) >= 0
            )
          )
        ]

        if (duplicateIds.length > 0) {
          setError({
            message: (
              <Block>
                All external line IDs must be unique. The following IDs occur more than once:
                <SpacerVertical />
                <List>
                  {duplicateIds
                    .sort((a, b) => a.localeCompare(b))
                    .map((id) => {
                      return <ListItem text={id} />
                    })}
                </List>
              </Block>
            )
          })
          return
        }
      }
    } else if (currentGame?.useExternalLineId && currentGame.useFilenameAsExternalLineId) {
      // Check that every data row contains a unique filename

      const filenameColIndex = columns.findIndex((col) => col === 'filename')

      const rowsWithoutFilename = dataRows?.filter((row) => !row[filenameColIndex]?.trim()) ?? []

      if (rowsWithoutFilename.length > 0) {
        const errorRows = rowsWithoutFilename
          .map(mapDataRow)
          .sort((a, b) => (a.filename?.trim() ?? '').localeCompare(b.filename?.trim() ?? ''))

        setError({
          title: `Filename is missing for ${errorRows.length}
            ${pluralize('line', errorRows.length)}`,
          message: (
            <>
              <Block color="var(--on-surface-light)">
                A unique filename needs to be specified for every line. Please correct the following{' '}
                {pluralize('line', errorRows.length)} in your import data and try again.
              </Block>
              <SpacerVertical />
              <List>
                {errorRows.map((row) => {
                  const lineText = (
                    <Block overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                      {row[`line_${currentGame?.primaryLanguage ?? 'en'}`]}
                    </Block>
                  )
                  return <ListItem text={lineText} />
                })}
              </List>
              <SpacerVertical />
            </>
          )
        })
        return
      }

      const filenames: string[] = dataRows?.map((dataRow) => dataRow[filenameColIndex]) ?? []

      const duplicateFilenames: string[] = [
        ...new Set(
          filenames.filter(
            (filename, i) =>
              filenames
                .slice(i + 1)
                .findIndex((filename2, i2) => filename2 === filename && i2 !== i) >= 0
          )
        )
      ]

      if (duplicateFilenames.length > 0) {
        setError({
          message: (
            <Block>
              All filenames must be unique when being used as external line IDs. The following
              filenames occur more than once:
              <SpacerVertical />
              <List>
                {duplicateFilenames
                  .sort((a, b) => a.localeCompare(b))
                  .map((filename) => {
                    return <ListItem text={filename} />
                  })}
              </List>
            </Block>
          )
        })
        return
      }
    } else {
      // Verify that "Speechless line ID" column seems to have been correctly selected
      const speechlessLineIdColIndex = columns.findIndex((col) => col === 'lineId')

      if (speechlessLineIdColIndex > -1) {
        // Check that every data row contains a unique, non-empty external ID
        const invalidUuids = (dataRows?.map((row) => row[speechlessLineIdColIndex]) ?? []).filter(
          (uuid) => uuid && !validateUuid(uuid?.trim())
        )

        if (invalidUuids.length > 0) {
          setError({
            message: (
              <Block>
                Please check that you have selected the correct column for "Speechless line ID" and
                that "First row is header" is checked if your sheet contains a header row.
                <SpacerVertical />
                The following values are not valid line IDs:
                <SpacerVertical />
                <List>
                  {invalidUuids
                    .sort((a, b) => (a ?? '').localeCompare(b))
                    .splice(0, 10)
                    .map((id) => {
                      return <ListItem text={id} />
                    })}
                </List>
              </Block>
            )
          })
          return
        }
      }
    }

    if (useSceneSeqNumber) {
      const sceneSeqNumberColIndex = columns.findIndex((col) => col === 'sceneSeqNumber')
      const sceneNameColIndex = columns.findIndex((col) => col === 'sceneName')

      // Check that all lines that belongs to a scene also has a valid value for the scene order column
      const rowsWithoutValidSeqNumber =
        dataRows?.filter((row) => {
          const belongsToScene = !!row[sceneNameColIndex]?.trim()
          const sceneSeqNumber = parseSceneSeqNumber(row[sceneSeqNumberColIndex])

          return belongsToScene && sceneSeqNumber === undefined
        }) ?? []

      if (rowsWithoutValidSeqNumber.length > 0) {
        const errorRows = rowsWithoutValidSeqNumber
          .map(mapDataRow)
          .sort((a, b) => (a.filename?.trim() ?? '').localeCompare(b.filename?.trim() ?? ''))

        setError({
          title: `Scene order is missing for ${errorRows.length}
            ${pluralize('line', errorRows.length)}`,
          message: (
            <>
              <Block color="var(--on-surface-light)">
                Scene order needs to be specified for every line that belongs to a scene.
                <br />
                <br />
                Allowed values are numbers or text values with a numerical suffix (e.g. "scene1_001"
                where 001 is the numerical suffix).
                <br />
                <br />
                Please correct the following {pluralize('line', errorRows.length)} in your import
                data and try again.
              </Block>
              <SpacerVertical />
              <List>
                {errorRows.map((row) => {
                  const lineText = (
                    <Block overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                      {row[`line_${currentGame?.primaryLanguage ?? 'en'}`]}
                    </Block>
                  )
                  return (
                    <ListItem
                      text={row.filename?.trim() ?? '<No filename>'}
                      secondaryText={lineText}
                    />
                  )
                })}
              </List>
              <SpacerVertical />
            </>
          )
        })
        return
      }
    }

    const params = {
      createCharacters: true,
      createEvents: true,
      createScenes: true,
      generateAITakesForNewLines,
      generateAITakesForUpdatedLines,
      useAsSelectedTake,
      deleteMissingLines: shouldDeleteMissingLines,
      deleteUnusedCharacters: shouldDeleteUnusedCharacters,
      deleteUnusedEvents: shouldDeleteUnusedEvents,
      columns,
      useSceneSeqNumber,
      generateFilenames: generateUniqueFilenames,
      rows: dataRows?.map(mapDataRow)
    }

    let dryRun: any
    try {
      const res = await post(`/games/${currentGame!.id}/lines.xlsx`, {
        ...params,
        dryRun: true
      })

      dryRun = res?.data
    } catch (err: any) {
      // Specific error handling when not enough AI quota
      if (err.message.includes('AI quota')) {
        if (
          await confirm({
            title: 'Not enough AI voices quota',
            message: (
              <>
                <Block>
                  You don't have enough AI voices quota left to generate AI takes for all your
                  lines.
                </Block>
                <SpacerVertical />
                <Block>
                  Please buy more hours to keep the magic going, or contact our sales team at
                  support@speechless.games if you have any questions.
                </Block>
                <SpacerVertical />
              </>
            ),
            confirmText: 'Buy more hours'
          })
        ) {
          // routeTo(`/?tab=Usage`)
          await buyExtraAiTime()
        }
      } else {
        setError(err)
      }
      return
    }

    const {
      createdCharacters,
      deletedCharacters,
      deletedEvents,
      createdEvents,
      createdScenes,
      createdLabels,
      generatedAiTakes,
      numCreatedLines,
      numUpdatedLines,
      numDeletedLines,
      numRestoredLines,
      duplicateFilenames,
      duplicateFilenamesInSource
    }: {
      createdCharacters: string[]
      deletedCharacters: Character[]
      deletedEvents: Event[]
      createdEvents: string[]
      createdScenes: string[]
      createdLabels: string[]
      generatedAiTakes: { [language: string]: string[] }
      numCreatedLines: number
      numUpdatedLines: number
      numDeletedLines: number
      numRestoredLines: number
      duplicateFilenames: string[]
      duplicateFilenamesInSource: string[]
    } = dryRun

    const totalGeneratedAiTakes = Object.values(generatedAiTakes).reduce(
      (total, takes) => total + takes.length,
      0
    )
    const isMissingAiTakes = Object.values(generatedAiTakes).some(
      (takes) => takes.length < numCreatedLines
    )

    if (
      await confirm({
        title: 'Please confirm',
        confirmText: 'Import',
        message: (
          <Block>
            <Block>
              {numCreatedLines} {pluralize('line', numCreatedLines)} will be created.
            </Block>
            {duplicateFilenames.length > 0 && (
              <>
                <SpacerVertical tiny />
                <Block color="var(--on-surface-light)">
                  {duplicateFilenames.length} filename{duplicateFilenames.length !== 1 ? 's' : ''}{' '}
                  already exist.{' '}
                  <Link onClick={() => setDuplicateFilenames(duplicateFilenames)}>Show</Link>
                </Block>
              </>
            )}
            {duplicateFilenamesInSource.length > 0 && (
              <>
                <SpacerVertical tiny />
                <Block color="var(--on-surface-light)">
                  Source file contains {duplicateFilenamesInSource.length} duplicate filename
                  {duplicateFilenamesInSource.length !== 1 ? 's' : ''}.{' '}
                  <Link onClick={() => setDuplicateFilenames(duplicateFilenamesInSource)}>
                    Show
                  </Link>
                </Block>
              </>
            )}
            <SpacerVertical />
            <Block>
              {numUpdatedLines} {pluralize('line', numUpdatedLines)} will be updated.
            </Block>
            <SpacerVertical />
            <Block>
              {numRestoredLines} deleted {pluralize('line', numRestoredLines)} will be restored.
            </Block>
            <SpacerVertical />
            <Block color={numDeletedLines ? 'var(--warning)' : ''}>
              {numDeletedLines ?? 0} {pluralize('line', numDeletedLines ?? 0)} will be deleted.
            </Block>
            <SpacerVertical />
            <Block>
              {createdCharacters.length} {pluralize('character', createdCharacters.length)} will be
              created.
            </Block>
            {createdCharacters.length > 0 && (
              <>
                <SpacerVertical tiny />
                <Block color="var(--on-surface-light)">{createdCharacters.join(', ')}</Block>
              </>
            )}
            <SpacerVertical />
            <Block color={deletedCharacters.length ? 'var(--warning)' : ''}>
              {deletedCharacters.length ?? 0} unused{' '}
              {pluralize('character', deletedCharacters?.length)} will be deleted.{' '}
              {deletedCharacters.length > 0 && (
                <Link onClick={() => setDeletedCharacters(deletedCharacters)}>Show</Link>
              )}
            </Block>
            <SpacerVertical />
            <Block>
              {createdEvents.length} {pluralize('event', createdEvents.length)} will be created.
            </Block>
            {createdEvents.length > 0 && (
              <>
                <SpacerVertical tiny />
                <Block color="var(--on-surface-light)">{createdEvents.join(', ')}</Block>
              </>
            )}
            <SpacerVertical />
            <Block color={deletedEvents.length ? 'var(--warning)' : ''}>
              {deletedEvents.length ?? 0} unused {pluralize('event', deletedEvents?.length)} will be
              deleted.{' '}
              {deletedEvents.length > 0 && (
                <Link onClick={() => setDeletedEvents(deletedEvents)}>Show</Link>
              )}
            </Block>
            <SpacerVertical />
            <Block>
              {createdScenes.length} {pluralize('scene', createdScenes.length)} will be created.
            </Block>
            {createdScenes.length > 0 && (
              <>
                <SpacerVertical tiny />
                <Block color="var(--on-surface-light)">{createdScenes.join(', ')}</Block>
              </>
            )}
            <SpacerVertical />
            <Block>
              {createdLabels.length} {pluralize('label', createdLabels.length)} will be created.
            </Block>
            {createdLabels.length > 0 && (
              <>
                <SpacerVertical tiny />
                <Block color="var(--on-surface-light)">{createdLabels.join(', ')}</Block>
              </>
            )}
            <SpacerVertical />
            <Block>
              {totalGeneratedAiTakes} AI {pluralize('take', totalGeneratedAiTakes)} will be
              generated.
            </Block>
            {generateAITakes && Object.keys(generatedAiTakes).length > 1 && (
              <>
                {Object.entries(generatedAiTakes)
                  .sort(([lang1], [lang2]) =>
                    formatLanguage(lang1).localeCompare(formatLanguage(lang2))
                  )
                  .map(([language, aiTakes]) => {
                    const numSkippedLines = numCreatedLines - aiTakes.length

                    return (
                      <>
                        <SpacerVertical tiny />
                        <Block color="var(--on-surface-light)">
                          {aiTakes.length} {formatLanguage(language)}{' '}
                          {pluralize('take', aiTakes.length)}
                          {numSkippedLines > 0
                            ? ` · Skipping ${numSkippedLines} ${pluralize('line', numSkippedLines)}`
                            : ''}
                        </Block>
                      </>
                    )
                  })}
              </>
            )}
            {generateAITakes && isMissingAiTakes && (
              <>
                <SpacerVertical small />
                <Row color="var(--on-surface-light)" fontSize="14px" gap="10px" alignItems="top">
                  <Block>
                    <WarningIcon fill="var(--on-surface-light)" size="24" />
                  </Block>
                  <Block>
                    AI takes will not be generated for all lines. Make sure that all the lines you
                    wish to import contain text, and that you have assigned AI voices to your
                    characters in Speechless before importing. You can also generate AI takes later.
                  </Block>
                </Row>
              </>
            )}
            <SpacerVertical />
          </Block>
        )
      })
    ) {
      if (await uploadExcel(params)) {
        showSnackbar('Import done!')
        routeTo('/lines', true)
      }
    }
  }

  const onBack = () => goBack('/lines')

  const parseRows = (rows: any) => {
    // Filter out empty rows that might exist in spreadsheet file
    setRows(
      rows.filter((row: any) => {
        for (const colValue of row) {
          // Any value in any column makes the entire row "valid"
          if (colValue !== undefined && colValue !== '' && colValue !== null) {
            return true
          }
        }
        return false
      })
    )

    // Try to map columns based on the values of the first row.
    // Will only work properly if the import sheet contains a header row.
    const possibleHeaderRow = rows[0] as string[]
    const columnMappings = new Array(possibleHeaderRow.length).fill('n/a')

    possibleHeaderRow.forEach((colHeader, i) => {
      const columnOption = columnOptions.find((option) => {
        if (!option.label || !colHeader) {
          return false
        }

        // Skipping all special characters that can prevent exact match (like '*')
        const onlyWordCharsRegex = /\W/g
        const exactMatch =
          option.label.replace(onlyWordCharsRegex, '') === colHeader.replace(onlyWordCharsRegex, '')

        if (exactMatch) {
          return true
        }

        if (colHeader.toLowerCase() === 'labels' && option.label.startsWith('Labels')) {
          return true
        }

        return false
      })
      if (columnOption) {
        const colName = columnOption.value
        if (!columnMappings.includes(colName) || columnOption.multiple) {
          columnMappings[i] = colName
        }
      }
    })

    setColumns(columnMappings)
  }

  const importGoogleSheetRows = async (spreadsheetId: string, sheets: Sheet[]) => {
    const rows: any[] = []

    for (const [i, sheet] of sheets.entries()) {
      // @ts-ignore: global google
      const response = await gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId,
        range: sheet.title || `Sheet${i + 1}`
      })

      const sheetRows = response.result.values || []
      rows.push(...sheetRows)
    }

    parseRows(rows)
  }

  const importRows = async (sheets: Sheet[]) => {
    if (googleDocId) {
      importGoogleSheetRows(googleDocId, sheets)
      return
    }

    if (sourceFile) {
      importExcelFileRows(sourceFile, sheets)
    }
  }

  const pickGoogleSheet = async () => {
    try {
      await loadScript('https://apis.google.com/js/api.js')
      await new Promise<void>((resolve) => {
        // @ts-ignore: global
        gapi.load('picker', resolve)
      })
      await new Promise<void>((resolve) => {
        // @ts-ignore: global
        gapi.load('client', async () => {
          // @ts-ignore: global
          await gapi.client.init({
            apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
            discoveryDocs: [
              'https://sheets.googleapis.com/$discovery/rest?version=v4',
              'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
            ]
          })
          resolve()
        })
      })
      await loadScript('https://accounts.google.com/gsi/client')
      // @ts-ignore: global
      const tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID,
        scope:
          'https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/drive.readonly',
        callback: (response: any) => {
          if (response.error !== undefined) {
            throw response
          }
          async function pickerCallback(data: any) {
            // @ts-ignore: global google
            if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
              // @ts-ignore: global google
              let doc = data[google.picker.Response.DOCUMENTS][0]

              // Save doc id to allow delayed import after selecting sheets
              setGoogleDocId(doc.id)
              setSourceFile(undefined)

              if (doc.mimeType === 'application/vnd.google-apps.spreadsheet') {
                // @ts-ignore: global google
                let response = await gapi.client.sheets.spreadsheets.get({
                  spreadsheetId: doc.id
                })

                const sheets: Sheet[] = response.result.sheets.map((sheet: any) => sheet.properties)
                setAllSheets(sheets)
                setSelectedSheets([])
                if (sheets.length === 1) {
                  return await importGoogleSheetRows(doc.id, sheets)
                } else if (sheets.length === 0) {
                  setError(`No sheets found in document`)
                  return
                }

                // Return and delay import until user has selected one or ore sheets/tabs
                return
              }

              try {
                setGoogleDocId(undefined)

                // @ts-ignore: global google
                const file = await gapi.client.drive.files
                  .get({
                    fileId: doc.id,
                    alt: 'media'
                  })
                  .then((res: any) => {
                    const charArray = new Array(res.body.length)
                    for (let i = 0; i < res.body.length; i++) {
                      charArray[i] = res.body.charCodeAt(i)
                    }
                    const typedArray = new Uint8Array(charArray)
                    return new File([typedArray], doc.name, { type: doc.mimeType })
                  })

                if (file.type === 'text/csv') {
                  await parseCSVFile(file)
                } else if (
                  file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                ) {
                  const fileSheets = await getExcelFileSheets(file)

                  if (fileSheets.length === 1) {
                    await importExcelFileRows(file)
                  } else if (fileSheets.length > 1) {
                    // Save sheets and source file in state to allow delayed import after selecting sheets
                    setAllSheets(fileSheets)
                    setSourceFile(file)
                  } else {
                    setError(`No sheets found in document`)
                  }
                } else {
                  throw new Error('Unsupported file type: ' + file.type)
                }
              } catch (err: any) {
                let message = err.message || 'Unsupported file type.'
                setError({ message: 'Unable to import lines: ' + message })
              }
            }
          }
          // @ts-ignore: global google
          const picker = new google.picker.PickerBuilder()
            // @ts-ignore: global google
            .addView(google.picker.ViewId.DOCS)
            .setOAuthToken(response.access_token)
            .setDeveloperKey(import.meta.env.VITE_GOOGLE_API_KEY)
            .setCallback(pickerCallback)
            .build()
          picker.setVisible(true)
        }
      })
      tokenClient.requestAccessToken({ prompt: 'consent' })
    } catch (err) {
      console.warn(err)
      setError(err)
    }
  }

  const parseCSVFile = async (file: File) => {
    try {
      await loadScript('https://unpkg.com/papaparse@5.3.2/papaparse.min.js')
      // @ts-ignore: global
      Papa.parse(file, {
        complete: (res: any) => {
          parseRows(res.data)
        }
      })
    } catch (err) {
      console.warn(err)
      setError(err)
    }
  }

  const getExcelFileSheets = async (file: File): Promise<Sheet[]> => {
    await loadScript('https://unpkg.com/read-excel-file@4.x/bundle/read-excel-file.min.js')
    // @ts-ignore: global readXlsxFile
    const fileSheets: any[] = await readXlsxFile(file, { getSheets: true })

    return fileSheets ? fileSheets.map((sheet, i) => ({ sheetId: i, title: sheet.name })) : []
  }

  const importExcelFileRows = async (file: File, sheets?: Sheet[]) => {
    try {
      await loadScript('https://unpkg.com/read-excel-file@4.x/bundle/read-excel-file.min.js')

      // If no sheets specified, import all sheets from file
      if (!sheets) {
        sheets = await getExcelFileSheets(file)
      }

      const rows: any[] = []

      for (let sheet of sheets ?? []) {
        // @ts-ignore: global readXlsxFile
        const sheetRows = await readXlsxFile(file, { sheet: sheet.title, dateFormat: 'd-mmm-yy' })
        if (sheetRows?.length) {
          rows.push(...sheetRows)
        }
      }
      if (rows.length === 0) {
        throw new Error('Document is empty')
      }
      parseRows(rows)
    } catch (err) {
      console.warn(err)
      setError(err)
    }
  }

  if (!rows && allSheets && allSheets.length > 1) {
    return (
      <Page title="Create import source" onBack={onBack}>
        <Block maxWidth="500px">
          <Block>Select one or more spreadsheet tabs to import</Block>
          <SpacerHorizontal />
          {allSheets.map((sheet) => (
            <Row alignItems="center">
              <Checkbox
                label={sheet.title}
                checked={selectedSheets?.some(
                  (selectedSheet) => selectedSheet.sheetId === sheet.sheetId
                )}
                onChange={() => toggleSelectedSheet(sheet)}
              />
            </Row>
          ))}
          <SpacerVertical />
          <Row alignItems="center">
            <Button
              contained
              disabled={selectedSheets.length === 0}
              onClick={() => importRows(selectedSheets)}
            >
              Continue
            </Button>
            <SpacerHorizontal />
            <Button onClick={onBack}>Cancel</Button>
          </Row>
        </Block>
      </Page>
    )
  }

  if (!rows) {
    return (
      <Page title="Import lines" onBack={onBack}>
        <Row gap="10px">
          <Button outlined onClick={() => fileInput.current!.click()}>
            Upload file
          </Button>
          <Button outlined onClick={pickGoogleSheet}>
            Select from Google Drive
          </Button>
        </Row>
        <input
          type="file"
          ref={fileInput}
          onChange={async () => {
            if (fileInput?.current!.files?.length) {
              const file = fileInput.current.files[0]
              if (file.type === 'text/csv') {
                parseCSVFile(file)
              } else if (
                file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              ) {
                const fileSheets = await getExcelFileSheets(file)
                if (fileSheets.length === 1) {
                  importExcelFileRows(file)
                } else if (fileSheets.length > 1) {
                  // Save sheets and source file in state to allow delayed import after selecting sheets
                  setAllSheets(fileSheets)
                  setSourceFile(file)
                } else {
                  setError(`No sheets found in document`)
                }
              } else {
                setError('Unsupported file type: ' + file.type)
              }
              fileInput.current!.value = ''
            }
          }}
          style={{ display: 'none' }}
        />
      </Page>
    )
  }

  const numColumns = rows[0].length

  const header = []
  for (let i = 0; i < numColumns; i++) {
    header.push({
      label: (
        <NativeSelect
          width="200px"
          options={columnOptions}
          value={columns[i]}
          onChange={(value: string) => setColumnAt(i, value)}
        />
      )
    })
  }

  const dataRows = firstRowIsHeader ? rows.slice(1) : rows

  const tableRows = dataRows.slice(page * pageSize, page * pageSize + pageSize)

  const body = tableRows.map((row) => {
    return row.map((value: any) => (
      <Block maxWidth="200px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {value?.toString()}
      </Block>
    ))
  })

  return (
    <Page
      title="Import lines"
      onBack={onBack}
      primaryActionText="Import"
      primaryActionLoading={isUploadingExcel}
      onPrimaryActionClick={handleUpload}
      secondaryActionText="Cancel"
      secondaryActionDisabled={isUploadingExcel}
      onSecondaryActionClick={onBack}
    >
      <Col height="100%">
        <Row>
          <Block minWidth="300px">
            <Checkbox
              label="First row is header"
              checked={firstRowIsHeader}
              onChange={setFirstRowIsHeader}
            />
            <br />
            <Checkbox
              label="Generate AI take for new lines"
              checked={generateAITakesForNewLines}
              onChange={setGenerateAITakesForNewLines}
            />
            <br />
            <Checkbox
              label="Generate AI take for updated lines"
              checked={generateAITakesForUpdatedLines}
              onChange={setGenerateAITakesForUpdatedLines}
            />
            <br />
            <Checkbox
              label="Use as selected take"
              checked={useAsSelectedTake}
              onChange={setUseAsSelectedTake}
              disabled={!generateAITakes}
              marginLeft="25px"
            />
          </Block>
          <SpacerHorizontal large />
          <Block minWidth="350px" maxWidth="350px">
            <Row alignItems="center" gap="10px">
              <Checkbox
                label="Delete missing lines"
                checked={shouldDeleteMissingLines}
                onChange={setShouldDeleteMissingLines}
              />
              <Button
                tiny
                icon={isDeleteMissingLinesHelpVisible ? ExpandLessIcon : ExpandMoreIcon}
                onClick={() =>
                  isDeleteMissingLinesHelpVisible
                    ? setIsDeleteMissingLinesHelpVisible(false)
                    : setIsDeleteMissingLinesHelpVisible(true)
                }
              >
                {isDeleteMissingLinesHelpVisible ? 'Hide help' : 'Show help'}
              </Button>
            </Row>
            {isDeleteMissingLinesHelpVisible && (
              <>
                <HelpDeleteMissingLines fontSize="14px" />
                <SpacerVertical />
              </>
            )}
            <Row alignItems="center" gap="10px">
              <Checkbox
                width="350px"
                label="Delete unused characters"
                checked={shouldDeleteUnusedCharacters}
                onChange={setShouldDeleteUnusedCharacters}
              />
            </Row>
            <Row alignItems="center" gap="10px">
              <Checkbox
                width="350px"
                label="Delete unused events"
                checked={shouldDeleteUnusedEvents}
                onChange={setShouldDeleteUnusedEvents}
              />
            </Row>
            <Row alignItems="center" gap="10px">
              <Checkbox
                label="Specify order in scenes"
                checked={useSceneSeqNumber}
                onChange={setUseSceneSeqNumber}
              />
              <Button
                tiny
                icon={isUseSceneSeqNumberHelpVisible ? ExpandLessIcon : ExpandMoreIcon}
                onClick={() =>
                  isUseSceneSeqNumberHelpVisible
                    ? setIsUseSceneSeqNumberHelpVisible(false)
                    : setIsUseSceneSeqNumberHelpVisible(true)
                }
                whiteSpace="nowrap"
              >
                {isUseSceneSeqNumberHelpVisible ? 'Hide help' : 'Show help'}
              </Button>
            </Row>
            {isUseSceneSeqNumberHelpVisible && (
              <>
                <HelpUseSceneSeqNumber fontSize="14px" />
                <SpacerVertical />
              </>
            )}
          </Block>
          <SpacerHorizontal large />
          <Block minWidth="350px" maxWidth="350px">
            <Row alignItems="center" gap="10px">
              <Checkbox
                label="Generate filenames using custom format"
                checked={generateUniqueFilenames && currentGame?.customFilenameFormat}
                onChange={setGenerateUniqueFilenames}
                disabled={
                  !currentGame?.customFilenameFormat ||
                  (currentGame?.useExternalLineId && currentGame.useFilenameAsExternalLineId)
                }
              />
            </Row>
            <Block marginLeft="40px">
              <Block color="var(--on-surface-light)" wordWrap="break-word" fontSize="14px">
                {currentGame?.customFilenameFormat
                  ? `${currentGame.customFilenameFormat}`
                  : 'No custom format specified'}
              </Block>
              <br />
              <Block fontSize="14px">
                <Link to="/settings" onRoute={routeTo}>
                  Edit custom filename format
                </Link>
              </Block>
              <SpacerVertical />
            </Block>
            <Row alignItems="center" gap="10px">
              <Checkbox
                label="Use external line IDs"
                checked={currentGame?.useExternalLineId}
                onChange={setUseExternalLineId}
                disabled={isUpdatingUseExternalLineId}
              />
              {isUpdatingUseExternalLineId && <ProgressCircular size="24" />}
              <Button
                tiny
                icon={isExternalLineIdHelpVisible ? ExpandLessIcon : ExpandMoreIcon}
                onClick={() =>
                  isExternalLineIdHelpVisible
                    ? setIsExternalLineIdHelpVisible(false)
                    : setIsExternalLineIdHelpVisible(true)
                }
              >
                {isExternalLineIdHelpVisible ? 'Hide help' : 'Show help'}
              </Button>
            </Row>
            <Row alignItems="center" gap="10px">
              <Checkbox
                width="225px"
                label="Filename is line ID"
                checked={currentGame?.useFilenameAsExternalLineId}
                onChange={setUseFilenameAsExternalLineId}
                disabled={!currentGame?.useExternalLineId || isUpdatingUseExternalLineId}
                marginLeft="25px"
              />
            </Row>
            {isExternalLineIdHelpVisible && (
              <>
                <HelpExternalLineId fontSize="14px" />
                <SpacerVertical />
              </>
            )}
          </Block>
        </Row>
        <SpacerVertical />
        <Block color="var(--on -surface-light)">* Required column</Block>
        <SpacerVertical />
        <Block flex="1" position="relative">
          <ScrollableDataTable
            compact
            header={header}
            body={body}
            pagination={{
              page,
              numRowsPerPage: pageSize,
              numRows: dataRows.length,
              onPageChange: setPage
            }}
          />
        </Block>
      </Col>
      <Route
        path="/upload-excel/create-line-attribute-for-column"
        render={() => {
          return (
            <CreateLineAttribute
              backUrl="/upload-excel"
              onSuccess={(lineAttribute) => {
                setColumnAt(columnIndex, lineAttribute.id)
              }}
            />
          )
        }}
      />
      {duplicateFilanames && (
        <Block position="absolute" zIndex={100}>
          <Dialog
            title="Duplicate filenames"
            actions={[
              {
                text: 'Close',
                contained: true,
                onClick: () => setDuplicateFilenames(undefined)
              }
            ]}
            onClose={() => setDuplicateFilenames(undefined)}
          >
            <List>
              {duplicateFilanames.map((filename) => {
                return <ListItem text={filename} />
              })}
            </List>
          </Dialog>
        </Block>
      )}

      {deletedCharacters && (
        <Block position="absolute" zIndex={100}>
          <Dialog
            title="Characters to delete"
            actions={[
              {
                text: 'Close',
                contained: true,
                onClick: () => setDeletedCharacters(undefined)
              }
            ]}
            onClose={() => setDeletedCharacters(undefined)}
          >
            <Block color="var(--on-surface-light)">
              The following {pluralize('character', deletedCharacters.length)} will be deleted
              because {deletedCharacters.length === 1 ? 'it is' : 'they are'} not used by any lines.
              <SpacerVertical />
              If you do not wish to delete any characters, please make sure that "Delete unused
              characters" is not checked before importing.
              <SpacerVertical />
            </Block>
            <List>
              {deletedCharacters
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((character) => {
                  return <ListItem text={character.name} />
                })}
            </List>
            <SpacerVertical />
          </Dialog>
        </Block>
      )}

      {deletedEvents && (
        <Block position="absolute" zIndex={100}>
          <Dialog
            title="Events to delete"
            actions={[
              {
                text: 'Close',
                contained: true,
                onClick: () => setDeletedEvents(undefined)
              }
            ]}
            onClose={() => setDeletedEvents(undefined)}
          >
            <Block color="var(--on-surface-light)">
              The following {pluralize('event', deletedEvents.length)} will be deleted because{' '}
              {deletedEvents.length === 1 ? 'it is' : 'they are'} not used by any lines.
              <SpacerVertical />
              If you do not wish to delete any events, please make sure that "Delete unused events"
              is not checked before importing.
              <SpacerVertical />
            </Block>
            <List>
              {deletedEvents
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((event) => {
                  return <ListItem text={event.name} />
                })}
            </List>
            <SpacerVertical />
          </Dialog>
        </Block>
      )}

      {buyExtraTimeAiQuota && billingAccount && (
        <>
          <BuyExtraAiQuotaTimeDialog
            aiQuota={buyExtraTimeAiQuota}
            billingAccount={billingAccount}
            onSuccess={(aiQuota: AiQuota, hours: number) => {
              fetchGameAiQuota()
            }}
            onClose={() => {
              setBuyExtraTimeAiQuota(undefined)
            }}
          />
        </>
      )}

      {showSelectBillingAccountDialog && billingAccounts?.length && (
        <SelectBillingAccount
          billingAccounts={billingAccounts}
          onCreateBillingAccount={createBillingAccount}
          onClose={() => setShowSelectBillingAccountDialog(false)}
          onSelect={async (billingAccount) => {
            if (await confirmSelectBillingAccount({ billingAccount })) {
              updateGame({ billingAccountId: billingAccount.id }).then(fetchGameAiQuota)
              return true
            }
            return false
          }}
        />
      )}
      {isUploadingExcel && (
        <Dialog dismissable={false}>
          <Block textAlign="center">Importing lines…</Block>
          <Spinner />
        </Dialog>
      )}
    </Page>
  )
}

export default UploadExcel
