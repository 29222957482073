import { h } from 'preact'

import { goBack, routeTo } from '@sodra/prutt'

import { useStore } from '../../../store'
import Page from '../../Page'
import {
  deleteBillingAccount,
  fetchBillingAccount,
  updateBillingAccount
} from '../../../actions/billing-accounts'
import { showSnackbar, updateGame } from '../../../actions'
import Spinner from '../../Spinner'
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks'
import {
  AddIcon,
  Button,
  CheckmarkIcon,
  DeleteIcon,
  Form,
  GamesIcon,
  H2,
  MemoryIcon,
  P,
  PeopleIcon,
  SpacerHorizontal,
  SpacerVertical,
  TextField
} from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'
import { confirm } from 'lib'
import { validateVatId } from './validate-vat-id'
import { getCode, getName, getNames } from 'country-list'
import { TaxIdInput, Type } from './TaxIdInput'
import { CountrySelect } from './CountrySelect'
import { getStripeAddressFormat } from './stripe-address-formats'
import { isValidUSState } from './is-valid-us-state'
import { isValidUSZip } from './is-valid-us-zip'

type Props = {
  accountId: string
}

const formatBrand = (brand: string) => {
  if (brand.length > 0) {
    return brand[0].toUpperCase() + brand.substring(1)
  }
  return brand
}

export const EditBillingAccount = ({ accountId }: Props) => {
  const { currentGame, billingAccount, isUpdatingBillingAccount } = useStore(
    'currentGame',
    'billingAccount',
    'isUpdatingBillingAccount'
  )

  // Relative url
  const onSuccessUrl = new URLSearchParams(location.search).get('onSuccessUrl')

  const [name, setName] = useState<string>()
  const [businessName, setBusinessName] = useState<string>()
  const [country, setCountry] = useState<string>()
  const [addressLine1, setAddressLine1] = useState<string>()
  const [addressLine2, setAddressLine2] = useState<string>()
  const [city, setCity] = useState<string>()
  const [postalCode, setPostalCode] = useState<string>()
  const [state, setState] = useState<string>()
  const [taxIdType, setTaxIdType] = useState<Type | undefined>()
  const [taxIdValue, setTaxIdValue] = useState<string | undefined>()
  const [invoiceEmail, setInvoiceEmail] = useState<string>()
  const [primary, setPrimary] = useState(!currentGame?.billingAccount)

  const [nameError, setNameError] = useState('')
  const [businessNameError, setBusinessNameError] = useState('')
  const [countryError, setCountryError] = useState('')
  const [addressError, setAddressError] = useState('')
  const [cityError, setCityError] = useState('')
  const [postalCodeError, setPostalCodeError] = useState('')
  const [stateError, setStateError] = useState('')
  const [taxIdError, setTaxIdError] = useState('')
  const [invoiceEmailError, setInvoiceEmailError] = useState('')

  const countryCode = useMemo(() => {
    if (country) {
      return getCode(country)
    } else {
      return undefined
    }
  }, [country])

  const addressFormat = useMemo(() => {
    if (countryCode) {
      return getStripeAddressFormat(countryCode)
    }
  }, [countryCode])

  useEffect(() => {
    fetchBillingAccount(accountId)
  }, [accountId])

  if (!billingAccount || billingAccount.id !== accountId) {
    return <Spinner />
  }

  useEffect(() => {
    if (billingAccount) {
      setName(billingAccount.name)
      setBusinessName(billingAccount.businessName)
      setCountry(billingAccount.address.country)
      setAddressLine1(billingAccount.address.line1)
      setAddressLine2(billingAccount.address.line2)
      setPostalCode(billingAccount.address.postalCode)
      setCity(billingAccount.address.city)
      setState(billingAccount.address.state)
      setTaxIdType({
        countryCode: billingAccount.taxId.country,
        type: billingAccount.taxId.type
      })
      setTaxIdValue(billingAccount.taxId.value)
      setInvoiceEmail(billingAccount.invoiceEmail)
    }
  }, [billingAccount])

  const onClose = () => goBack('/settings/billing')

  const handleTypeChange = useCallback(
    (type: Type | undefined) => {
      setTaxIdType(type)
    },
    [setTaxIdType]
  )

  const handleValueChange = useCallback(
    (value: string | undefined) => {
      setTaxIdValue(value)
    },
    [setTaxIdValue]
  )

  const clearErrors = () => {
    setNameError('')
    setBusinessNameError('')
    setCountryError('')
    setAddressError('')
    setCityError('')
    setPostalCodeError('')
    setStateError('')
    setTaxIdError('')
    setInvoiceEmailError('')
  }

  const validate = () => {
    clearErrors()

    let errorId

    const taxIdTypeValid = (taxIdType: Type) => {
      if (!taxIdType) {
        return false
      }
      return taxIdType.countryCode && taxIdType.type
    }

    // Tax registration
    if (!taxIdType || !taxIdTypeValid(taxIdType) || !taxIdValue || taxIdValue.trim() === '') {
      setTaxIdError('Please enter tax id')
      errorId = 'taxId'
    } else {
      if (!validateVatId(taxIdValue, taxIdType.countryCode)) {
        setTaxIdError('Please enter a valid tax id')
        errorId = 'taxId'
      }
    }

    // Invoice email
    if (!invoiceEmail || invoiceEmail.trim() === '') {
      setInvoiceEmailError('Please enter an email address')
      errorId = 'invoiceEmail'
    }

    // State
    if (addressFormat?.usingState) {
      // State missing
      if (!state || state.trim() === '') {
        setStateError(`Please enter ${addressFormat.stateLabel}`)
        errorId = 'state'
      }

      // US state incorrect
      if (countryCode === 'US') {
        const isValidState = isValidUSState(state!)
        if (!isValidState) {
          setStateError('Please enter a valid US State – eg. CA')
          errorId = 'state'
        }
      }
    }

    // City
    if (addressFormat?.usingCity && (!city || city.trim() === '')) {
      setCityError('Please enter city')
      errorId = 'city'
    }

    // Postal code
    if (addressFormat?.usingPostalCode) {
      // Postal code missing
      if (!postalCode || postalCode.trim() === '') {
        setPostalCodeError(`Please enter ${addressFormat.postalCodeLabel}`)
        errorId = 'postalCode'
      }

      // US zip incorrect
      if (countryCode === 'US') {
        var isValidZip = isValidUSZip(postalCode!)
        if (!isValidZip) {
          setPostalCodeError('Please enter a valid ZIP – eg. 94115')
          errorId = 'postalCode'
        }
      }
    }

    // Address
    if (!addressLine1 || addressLine1.trim() === '') {
      setAddressError('Please enter address')
      errorId = 'address'
    }

    // Country
    if (!country || country.trim() === '') {
      setCountryError('Please enter country')
      errorId = 'country'
    }

    // Business name
    if (!businessName || businessName.trim() === '') {
      setBusinessNameError('Please enter business name')
      errorId = 'businessName'
    }

    // Account name
    if (!name || name.trim() === '') {
      setNameError('Please enter account name')
      errorId = 'accountName'
    }

    if (errorId) {
      const el = document.querySelector(`#${errorId}`)
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      return false
    }

    return true
  }

  useEffect(() => {
    if (name) {
      setNameError('')
    }
    if (businessName) {
      setBusinessNameError('')
    }
    if (country) {
      setCountryError('')
      setAddressError('')
      setCityError('')
      setPostalCodeError('')
      setStateError('')
    }
    if (addressLine1) {
      setAddressError('')
    }
    if (city) {
      setCityError('')
    }
    if (postalCode) {
      setPostalCodeError('')
    }
    if (state) {
      setStateError('')
    }
    if (invoiceEmail) {
      setInvoiceEmailError('')
    }
    if (taxIdType && taxIdValue) {
      setTaxIdError('')
    }
  }, [
    name,
    businessName,
    country,
    addressLine1,
    city,
    postalCode,
    state,
    invoiceEmail,
    taxIdType,
    taxIdValue
  ])

  const handleSubmit = async (values: Record<string, any>) => {
    if (!validate()) {
      return
    }

    const fixedTaxIdValue = taxIdValue?.replace(/\W/g, '')
    let gameId
    if (primary) {
      gameId = currentGame?.id
    }
    const billingAccount = await updateBillingAccount(accountId, {
      name,
      businessName,
      country,
      addressLine1,
      addressLine2,
      postalCode: addressFormat?.usingPostalCode ? postalCode : '',
      city: addressFormat?.usingCity ? city : '',
      state: addressFormat?.usingState ? state : '',
      taxIdCountry: taxIdType?.countryCode,
      taxIdType: taxIdType?.type,
      taxId: fixedTaxIdValue,
      invoiceEmail,
      primary,
      gameId
    })

    if (billingAccount) {
      showSnackbar(`Billing account ${name} updated`)
      if (onSuccessUrl) {
        routeTo(onSuccessUrl)
      } else {
        onClose()
      }
    }
  }

  const handleDelete = async () => {
    if (
      await confirm({
        message: `Delete billing account ${billingAccount.name}?`,
        confirmText: 'Delete'
      })
    ) {
      if (await deleteBillingAccount(accountId)) {
        showSnackbar(`Billing account ${billingAccount.name} deleted`)
        onClose()
      }
    }
  }

  const handleMakePrimary = async () => {
    if (await confirm(`Make ${billingAccount.name} the primary billing account?`)) {
      if (await updateGame({ billingAccountId: accountId })) {
        showSnackbar('Primary billing account updated')
        onClose()
      }
    }
  }

  const isPrimary = accountId === currentGame?.billingAccount?.id

  return (
    <Page
      title={billingAccount.name}
      onBack={onClose}
      actions={[
        {
          label: 'Manage access',
          icon: PeopleIcon,
          link: { href: `/settings/billing/${accountId}/manage-access`, onRoute: routeTo }
        },
        {
          label: 'Manage games',
          icon: GamesIcon,
          link: { href: `/settings/billing/${accountId}/manage-games`, onRoute: routeTo }
        },
        {
          label: 'AI quota',
          icon: MemoryIcon,
          link: { href: `/settings/billing/${accountId}/usage`, onRoute: routeTo }
        },
        {
          label: 'Make primary',
          icon: CheckmarkIcon,
          onClick: handleMakePrimary,
          disabled: isPrimary
        },
        { label: 'Delete', icon: DeleteIcon, onClick: handleDelete, disabled: isPrimary }
      ]}
    >
      <Row alignItems="flex-start" gap="40px">
        <Block>
          {isPrimary && (
            <>
              <Row
                alignItems="center"
                color="var(--accent)"
                border="solid 4px var(--accent)"
                padding="10px"
                maxWidth="500px"
              >
                Primary billing account
              </Row>
              <SpacerVertical />
            </>
          )}
          {billingAccount.taxLocationStatus === 'unrecognized_location' && (
            <>
              <Row
                alignItems="center"
                border="solid 4px var(--error)"
                padding="10px"
                maxWidth="500px"
              >
                Stripe: The address isn’t valid for determining a tax location.
              </Row>
              <SpacerVertical />
            </>
          )}

          <Form onSubmit={handleSubmit} maxWidth="500px">
            <TextField
              label="Account name"
              value={name}
              onInput={setName}
              width="100%"
              props={{ id: 'accountName' }}
              errorText={nameError}
            />
            <SpacerVertical small />
            <TextField
              label="Business name"
              value={businessName}
              onInput={setBusinessName}
              width="100%"
              props={{ id: 'businessName' }}
              errorText={businessNameError}
            />

            <SpacerVertical large />

            <H2 size="7">Address</H2>
            <SpacerVertical />
            <CountrySelect
              label="Country"
              countryCode={countryCode}
              onChange={(countryCode) => setCountry(getName(countryCode))}
              errorText={countryError}
              props={{ id: 'country' }}
            />

            <SpacerVertical large />
            <TextField
              label="Address line 1"
              value={addressLine1}
              onInput={setAddressLine1}
              errorText={addressError}
              width="100%"
              props={{ id: 'address' }}
            />
            <SpacerVertical small />
            <TextField
              label="Address line 2"
              value={addressLine2}
              onInput={setAddressLine2}
              width="100%"
            />
            {addressFormat?.usingPostalCode && (
              <>
                <SpacerVertical small />
                <TextField
                  label={addressFormat.postalCodeLabel || 'ZIP or postal code'}
                  value={postalCode}
                  onInput={setPostalCode}
                  // infoText="ZIP or postal code"
                  errorText={postalCodeError}
                  props={{ id: 'postalCode' }}
                />
              </>
            )}
            {addressFormat?.usingCity && (
              <>
                <SpacerVertical small />
                <TextField
                  label={addressFormat.cityLabel || 'City, district, suburb, town, or village'}
                  value={city}
                  onInput={setCity}
                  // infoText="City, district, suburb, town, or village"
                  errorText={cityError}
                  props={{ id: 'city' }}
                  width="100%"
                />
              </>
            )}
            {addressFormat?.usingState && (
              <>
                <SpacerVertical small />
                <TextField
                  label={addressFormat.stateLabel || 'State, county, province, or region'}
                  value={state}
                  onInput={setState}
                  infoText={
                    countryCode === 'US' ? 'Two letter state abbreviation – eg. CA' : undefined
                  }
                  // infoText={addressFormat.stateLabel ? undefined : 'State, county, province, or region'}
                  errorText={stateError}
                  props={{ id: 'state' }}
                  width="100%"
                />
              </>
            )}

            <SpacerVertical large />

            <H2 size="7">Email</H2>
            <SpacerVertical />

            <TextField
              placeholder="Enter email address"
              value={invoiceEmail}
              onInput={setInvoiceEmail}
              autocomplete="email"
              errorText={invoiceEmailError}
              props={{ id: 'invoiceEmail' }}
              width="100%"
            />

            <SpacerVertical large />

            <Block props={{ id: 'taxId' }}>
              <H2 size="7">Tax registration</H2>
              <SpacerVertical />
              <TaxIdInput
                countryCode={countryCode}
                type={taxIdType}
                value={taxIdValue}
                onTypeChange={handleTypeChange}
                onValueChange={handleValueChange}
                onValueInput={handleValueChange}
                errorText={taxIdError}
              />
            </Block>

            <SpacerVertical large />

            <Row alignItems="center">
              <Button contained type="submit" loading={isUpdatingBillingAccount}>
                Update billing account
              </Button>
              <SpacerHorizontal small />
              <Button onClick={onClose}>Cancel</Button>
            </Row>
          </Form>
        </Block>
        {!billingAccount.invoice && (
          <Block>
            <H2 size="6">Payment method</H2>
            <SpacerVertical />
            <Block
              maxWidth="300px"
              padding="20px 30px"
              borderRadius="3px"
              border="1px solid var(--container-outline)"
            >
              {!billingAccount.creditCard && (
                <Button
                  width="100%"
                  icon={AddIcon}
                  link={{
                    href: `/settings/billing/${
                      billingAccount.id
                    }/add-credit-card?onSuccessUrl=${encodeURIComponent(
                      location.pathname + location.search
                    )}`,
                    onRoute: routeTo
                  }}
                >
                  Add credit card
                </Button>
              )}
              {billingAccount.creditCard && (
                <>
                  <Block>
                    {formatBrand(billingAccount.creditCard.brand)} •••• •••• ••••{' '}
                    {billingAccount.creditCard.last4}
                  </Block>
                  <SpacerVertical tiny />
                  <Block fontSize="14px" color="var(--on-surface-light)">
                    Expires {billingAccount.creditCard.expMonth}/{billingAccount.creditCard.expYear}
                  </Block>
                </>
              )}
            </Block>
            {billingAccount.creditCard && (
              <>
                <SpacerVertical small />
                <Button
                  link={{
                    href: `/settings/billing/${
                      billingAccount.id
                    }/add-credit-card?onSuccessUrl=${encodeURIComponent(
                      location.pathname + location.search
                    )}`,
                    onRoute: routeTo
                  }}
                >
                  Use another credit card
                </Button>
              </>
            )}
            <SpacerVertical />
            <P size="2" color="var(--on-surface-light)">
              Contact us if you want to be invoiced instead of paying with credit card.
            </P>
          </Block>
        )}
      </Row>

      <SpacerVertical large />
    </Page>
  )
}
