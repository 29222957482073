import { h } from 'preact'
import { Block, Col, Row } from 'jsxstyle/preact'
import { useEffect } from 'preact/hooks'
import { history } from '@sodra/prutt'
import { LeftMenuContent } from './LeftMenuContent'
import { CloseIcon, IconButton } from '@sodra/bongo-ui'

type Props = {
  visible?: boolean
  onClose?: () => void
}

export const LeftMenu = ({ visible, onClose }: Props) => {
  useEffect(() => {
    return history.listen(onClose)
  }, [])

  return (
    <Block
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
      background={visible ? 'rgba(0, 0, 0, 0.75)' : 'rgba(0, 0, 0, 0)'}
      pointerEvents={visible ? 'all' : 'none'}
      transition="background 0.18s cubic-bezier(0, 0, .2, 1)"
      transitionDelay={visible ? '0' : '.18s'}
      props={{ onClick: onClose }}
      zIndex={2}
    >
      <Block
        position="absolute"
        top="0"
        left={visible ? 0 : '-250px'}
        bottom="0"
        width="250px"
        background="var(--surface-floating)"
        transition="left .18s cubic-bezier(0, 0, .2, 1)"
        transitionDelay={visible ? '.18s' : '0'}
        props={{ onClick: (e: any) => e.stopPropagation() }}
      >
        <Col height="100%" overflowY="auto">
          <Row height="60px" alignItems="center" marginLeft="15px" flexShrink={0}>
            <IconButton color="var(--on-surface)" icon={CloseIcon} onClick={onClose} />
          </Row>
          <LeftMenuContent />
        </Col>
      </Block>
    </Block>
  )
}

export default LeftMenu
