import { h } from 'preact'
import {
  Button,
  CheckmarkIcon,
  Dialog,
  ErrorIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  IconButton,
  MemoryIcon,
  PlayIcon,
  ProgressCircular,
  SpacerHorizontal,
  SpacerVertical,
  StopIcon
} from '@sodra/bongo-ui'

import { Line, Take } from '../../types'
import { Block, InlineBlock, Row } from 'jsxstyle/preact'
import { regenerateAITake, showSnackbar, updateTake } from '../../actions'
import { AudioPlayer, SingleAudioTrack, useAudioPlayer } from 'lib/src/hooks/use-audio-player'
import Waveform from './Waveform'
import { TakeUploader } from './TakeUploader'
import { formatDuration } from '../../format-duration'
import { diffWords } from 'diff'
import { useState } from 'preact/hooks'

type TakeProps = {
  take: Take
  player: AudioPlayer
}

const PlayableTake = ({ take, player }: TakeProps) => {
  if (take.error) {
    return <IconButton icon={ErrorIcon} color="var(--error)" />
  }
  let uri = take.processedUri || take.uri

  const { playerState, initPlayer, play, stopPlayer } = player

  if (uri) {
    const currentTrack = playerState.value.currentTrack as SingleAudioTrack | undefined
    const isActive = currentTrack && currentTrack.uri === uri
    const { isPlaying } = playerState.value

    const togglePlay = () => {
      if (isPlaying && isActive) {
        stopPlayer()
      } else {
        initPlayer({
          audioTracks: [
            {
              uri
            }
          ]
        })
        play({ audioTrackUri: uri })
      }
    }

    return <IconButton icon={isActive && isPlaying ? StopIcon : PlayIcon} onClick={togglePlay} />
  }
  return (
    <Row width="40px" height="40px" alignItems="center" justifyContent="center">
      <ProgressCircular size="20" />
    </Row>
  )
}

const TakeWaveform = ({ take, player }: { take: Take; player: AudioPlayer }) => {
  const { playerState, initPlayer, play } = player

  const currentTrack = playerState.value.currentTrack as SingleAudioTrack | undefined
  const isActive = currentTrack && currentTrack.uri === take.uri

  let uri = take.processedUri || take.uri

  return (
    <Waveform
      uri={uri}
      width={120}
      height={20}
      duration={isActive ? playerState.value.duration : 0}
      currentTime={isActive ? playerState.value.currentTime : 0}
      onClick={(_, startTimeFraction?: number) => {
        initPlayer({ audioTracks: [{ uri }] })
        play({ audioTrackUri: uri, startTimeFraction })
      }}
    />
  )
}

const getTakeUploader = (take: Take) => {
  if (take.voice) {
    return take.voice
  }
  if (take.aiVoice) {
    return take.aiVoice
  }
  if (take.user) {
    return take.user
  }
}

type OutdatedTakeDialogProps = {
  take: Take
  line: Line
  language: string
  onClose: () => void
  onRegenerateAITake?: () => void
  onHideWarning?: () => void
}

export const OutdatedTakeDialog = ({
  take,
  line,
  language,
  onClose,
  onRegenerateAITake,
  onHideWarning
}: OutdatedTakeDialogProps) => {
  const [showDiff, setShowDiff] = useState<boolean>(false)

  const { player } = useAudioPlayer()
  if (!player) {
    return null
  }

  const dialogActions: any = [
    {
      text: 'Hide warning',
      outlined: true,
      icon: CheckmarkIcon,
      onClick: async () => {
        if (await updateTake(take.id, { suppressWarnings: true })) {
          if (onHideWarning) {
            onHideWarning()
          }
        }
        onClose()
      }
    }
  ]

  if (take.aiVoice) {
    dialogActions.unshift({
      text: 'Update AI take',
      contained: true,
      icon: MemoryIcon,
      onClick: async () => {
        if (await regenerateAITake(take)) {
          if (onRegenerateAITake) {
            onRegenerateAITake()
          }
          showSnackbar('Generating new AI take...')
        }
        onClose()
      }
    })
  }
  const secondaryDialogActions = [
    {
      text: 'Cancel',
      onClick: onClose
    }
  ]

  const currentLine = line.translations[language].line
  const diff = diffWords(take.line, currentLine)

  return (
    <Dialog
      title="Outdated take"
      disableAutofocus
      onClose={onClose}
      actions={dialogActions}
      secondaryActions={secondaryDialogActions}
    >
      <Block color="var(--on-surface-light)">
        The line has changed since this take was {take.aiVoice ? 'generated' : 'uploaded'}.
      </Block>
      <SpacerVertical large />
      <Row alignItems="center" gap="10px">
        <PlayableTake take={take} player={player} />
        {(take.processedUri || take.uri) && (
          <>
            <TakeWaveform take={take} player={player} />
            <SpacerHorizontal />
          </>
        )}
        <TakeUploader take={take} size={40} />
        <Block>
          <Block>{getTakeUploader(take)?.name}</Block>
          <Block fontSize="12px" color="var(--on-surface-light)">
            {formatDuration(take.created)}
          </Block>
        </Block>
      </Row>
      <SpacerVertical large />
      <Block>
        This take:
        <SpacerVertical small />
        <Block color="var(--on-surface-light)">
          <em>"{take.line}"</em>
        </Block>
      </Block>
      <SpacerVertical />
      <Block>
        Current line:
        <SpacerVertical small />
        <Block color="var(--on-surface-light)">
          <em>"{currentLine}"</em>
        </Block>
      </Block>
      <SpacerVertical />
      {!showDiff && (
        <Button small icon={ExpandMoreIcon} onClick={() => setShowDiff(true)}>
          Show changes
        </Button>
      )}
      {showDiff && (
        <>
          <Button small icon={ExpandLessIcon} onClick={() => setShowDiff(false)}>
            Hide changes
          </Button>
          <Block>
            <SpacerVertical />
            Changes:
            <SpacerVertical small />
            <>
              {diff.map((diff) => (
                <InlineBlock
                  margin="0 2px"
                  textDecoration={diff.removed ? 'line-through' : ''}
                  textOverflow="ellipsis"
                  color={
                    diff.added
                      ? 'var(--accent)'
                      : diff.removed
                      ? 'var(--warning)'
                      : 'var(--on-surface-light)'
                  }
                >
                  {diff.value}
                </InlineBlock>
              ))}
            </>
          </Block>
        </>
      )}
      <SpacerVertical large />
    </Dialog>
  )
}
