import { useEffect, useState } from 'preact/hooks'
import { LogRow, WwiseConnection } from './WwiseConnection'

export function useWwise(options: {
  enabled: boolean
  shouldConnect?: boolean
  port?: number
  onImport?: (message: string) => void
  onImportError?: (message: string) => void
  onConnectionClosed?: () => void
}) {
  const [wwiseConnection, setWwiseConnection] = useState<WwiseConnection | null>(null)

  const [isOpen, setIsOpen] = useState(wwiseConnection?.isOpen ?? false)
  const [messages, setMessages] = useState<string[]>([])
  const [connectionError, setConnectionError] = useState<string | null>(null)
  const [progress, setProgress] = useState<number | null>(null)
  const [log, setLog] = useState<LogRow[]>([])

  useEffect(() => {
    if (wwiseConnection) {
      return () => {
        wwiseConnection.close()
      }
    }
  }, [wwiseConnection])

  function handleConnect() {
    if (wwiseConnection) {
      wwiseConnection.close()
    }
    const _wwiseConnection = new WwiseConnection({
      debug: true,
      port: options.port
    })

    _wwiseConnection.setMessageHandler((message) => {
      if (message.type === 'connection') {
        setMessages((messages) => [...messages, message.message])
      }
      if (message.type === 'import') {
        setProgress(null)
        options.onImport?.(message.message)
      }
    })
    _wwiseConnection.setErrorHandler((error) => {
      if (error.type === 'connection') {
        setConnectionError(error.message)
      }
      if (error.type === 'import') {
        console.error(error.message)
        options.onImportError?.(error.message)
      }
    })
    _wwiseConnection.setOpenHandler(() => {
      setConnectionError(null)
      setIsOpen(true)
    })
    _wwiseConnection.setCloseHandler(() => {
      setIsOpen(false)
      options.onConnectionClosed?.()
    })
    _wwiseConnection.setProgressHandler((progress) => {
      setProgress(progress)
    })
    _wwiseConnection.setLogHandler((logRow) => {
      if (logRow === null) {
        setLog([])
      } else {
        setLog((logs) => [...logs, logRow])
      }
    })

    setWwiseConnection(_wwiseConnection)
  }

  useEffect(() => {
    if (!wwiseConnection && options.shouldConnect) {
      handleConnect()
    }
  }, [options.shouldConnect, wwiseConnection])

  return {
    isOpen,
    messages,
    connectionError,
    progress,
    wwiseInfo: wwiseConnection?.wwiseInfo,
    projectInfo: wwiseConnection?.projectInfo,
    close: wwiseConnection?.close,
    getSoundStatus: wwiseConnection?.compareSoundsToWwiseContent,
    batchImportSounds: wwiseConnection?.batchImportSounds,
    undo: wwiseConnection?.undo,
    connect: handleConnect,
    log
  }
}
