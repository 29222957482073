import { useEffect, useState } from 'preact/hooks'
import { get } from './api'
import { Scene } from './types'

export const useScene = (initialSceneId?: string) => {
  const [sceneId, setSceneId] = useState<string | undefined>(initialSceneId)
  const [scene, setScene] = useState<Scene | undefined>()

  useEffect(() => {
    if (sceneId && (!scene || sceneId !== scene.id)) {
      get(`/scenes/${sceneId}`).then(({ data: scene }) => setScene(scene))
    }
    if (!sceneId) {
      setScene(undefined)
    }
  }, [sceneId])

  return {
    sceneId,
    setSceneId,
    scene,
    setScene: (scene?: Scene) => {
      setScene(scene)
      if (scene) {
        setSceneId(scene.id)
      } else {
        setSceneId(undefined)
      }
    },
    clearScene: () => {
      setSceneId(undefined)
      setScene(undefined)
    }
  }
}
