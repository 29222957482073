import { goBack } from '@sodra/prutt'
import { Block } from 'jsxstyle/preact'
import { SSERemoteControlAction, useSSE } from 'lib'
import { lineRecorder, LineRecorder, RemoteRecordingStatus } from 'line-recorder'
import { useCallback, useEffect, useState } from 'preact/hooks'
import {
  createLineTake,
  deleteTake,
  fetchScene,
  fetchSession,
  setError,
  updateLine,
  updateTake,
  uploadAudioBuffer
} from '../../../actions'
import { del, get, post } from '../../../api'
import { useStore } from '../../../store'
import { Line, Session, Take } from '../../../types'
import { useLocalStorageState } from '../../../use-local-storage-state'
import Spinner from '../../Spinner'
import { SAMPLE_SIZE } from '../../../feature-flags'
import { Scene } from 'line-recorder/src/components/LineRecorder/lineRecorderState'

type Props = {
  sessionId?: string
}

const getLineIdsFromQuery = () => {
  const params = new URLSearchParams(location.search)
  const lineIds = params.get('lineIds')
  if (lineIds) {
    return lineIds.split(',')
  }
}

export function RecordLines({ sessionId }: Props) {
  const { currentGame, currentLanguage, currentUser } = useStore(
    'currentGame',
    'currentLanguage',
    'currentUser'
  )

  const [session, setSession] = useState<Session | undefined>(undefined)

  const [lines, setLines] = useState<Line[]>()
  const [scenes, setScenes] = useState<Scene[]>([])

  useEffect(() => {
    if (sessionId) {
      fetchSession(sessionId).then((session: Session) => setSession(session))
    }
  }, [])

  if (!currentGame || (sessionId && !session)) {
    return <Spinner />
  }

  const [remoteControlRequestId, setRemoteControlRequestId] = useLocalStorageState<
    string | undefined
  >(`speechless:RecordLines:${sessionId}:remoteControlRequestId`)

  const [isRemoteControlAccepted, setIsRemoteControlAccepted] = useLocalStorageState<boolean>(
    `speechless:RecordLines:${sessionId}:isRemoteControlAccepted`,
    false
  )

  const [isRequesting, setIsRequesting] = useState(false)

  const [remoteRecordingStatus, setRemoteRecordingStatus] =
    useState<RemoteRecordingStatus>('not-recording')

  useEffect(() => {
    if (isRemoteControlAccepted) {
      const characterId = lineRecorder.value?.selectedCharacter.value
      if (characterId) {
        post(`/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`, {
          action: 'select-character',
          characterId
        })
      }
      const lineId = lineRecorder.value?.activeLineId.value
      if (lineId) {
        post(`/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`, {
          action: 'select-line',
          lineId: lineRecorder.value?.activeLineId.value
        })
      }
      const showAllTakes = lineRecorder.value?.showAllTakes.value ?? false
      post(`/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`, {
        action: 'show-all-takes',
        showAllTakes
      })
    }
  }, [isRemoteControlAccepted])

  useSSE({
    gameId: currentGame.id,
    sessionId,
    enabled: !!(currentGame && sessionId && remoteControlRequestId),
    onRemoteControlResponse: useCallback(
      (requestId: string, accept: boolean) => {
        if (requestId === remoteControlRequestId) {
          setIsRemoteControlAccepted(accept)
          setIsRequesting(false)
        }
      },
      [remoteControlRequestId]
    ),
    onStopRemoteControl: useCallback(
      (requestId: string) => {
        if (requestId === remoteControlRequestId) {
          setIsRemoteControlAccepted(false)
          setRemoteControlRequestId(undefined)
          setIsRequesting(false)
        }
      },
      [remoteControlRequestId]
    ),
    onRemoteControlAction: useCallback(
      (requestId: string, action: SSERemoteControlAction, rest: any) => {
        if (requestId === remoteControlRequestId) {
          if (action === 'recording-started') {
            if (remoteRecordingStatus === 'recording-requested') {
              setRemoteRecordingStatus('recording')
            }
          }
          if (action === 'recording-stopped') {
            // We don't check current remoteRecordingStatus here because recording might have been stopped by the voice
            // without a stop request being sent by the customer.
            setRemoteRecordingStatus('not-recording')
          }
        }
      },
      [remoteControlRequestId, remoteRecordingStatus]
    )
  })

  useSSE({
    gameId: currentGame.id,
    onLineUpdated: useCallback(
      (lineId: string) => {
        if (lines?.some((line) => line.id === lineId)) {
          fetchLines()
        }
      },
      [lines]
    )
  })

  const fetchLines = async () => {
    const storedRecorderLineIds = sessionStorage.getItem('recorderLineIds')

    const lineIds: string[] =
      getLineIdsFromQuery() || (storedRecorderLineIds ? JSON.parse(storedRecorderLineIds) : [])

    const params: any = {
      lineIds: sessionId ? undefined : lineIds,
      sessionId,
      includeTakes: true,
      orderBy: 'created',
      sortOrder: 'asc'
    }

    const lines = await get(`/games/${currentGame.id}/lines`, params).then(
      ({ data: lines }) => lines as Line[]
    )

    setLines(lines)
  }

  useEffect(() => {
    fetchLines()
  }, [])

  const fetchScenes = async (sceneIds: string[]) => {
    const scenes: Scene[] = await Promise.all(sceneIds.map((sceneId) => fetchScene(sceneId)))
    setScenes(scenes.sort((a, b) => a.name.localeCompare(b.name)))
  }

  useEffect(() => {
    if (lines?.length) {
      const sceneIds: string[] = [...new Set(lines.map((line) => line.scene?.id))].filter(
        Boolean
      ) as string[]

      // No need to fetch scenes again if already fetched
      const hasFetchedAllScenes = sceneIds.every((sceneId) =>
        scenes.some((scene) => scene.id === sceneId)
      )
      if (!hasFetchedAllScenes) {
        fetchScenes(sceneIds)
      }
    } else {
      setScenes([])
    }
  }, [lines])

  // If opening recorder without a session, use current language
  let language = currentLanguage

  // If opening recorder for a session, use language of that session or game, ignoring current language
  if (sessionId) {
    language = session?.language ?? currentGame.primaryLanguage
  }

  // Stop spinning after 5 sec
  useEffect(() => {
    if (isRequesting) {
      const timeout = setTimeout(() => setIsRequesting(false), 5000)
      return () => clearTimeout(timeout)
    }
  }, [isRequesting])

  return (
    <Block position="fixed" zIndex={10} inset="0" background="var(--surface)">
      <LineRecorder
        sessionId={sessionId}
        language={language}
        title={`${currentGame.name} · Lines`}
        game={currentGame}
        lines={lines}
        scenes={scenes}
        voice={currentUser}
        onBack={() => {
          goBack(`/lines`)
        }}
        onCreateTake={async (lineId, { id, uri, peakWarning, trimStart, trimEnd }) => {
          const line = await createLineTake(lineId, {
            lineId,
            takeId: id,
            uri,
            language: language,
            userId: currentUser?.id,
            makeDefault: false,
            peakWarning,
            trimStart,
            trimEnd,
            sessionId
          })

          const newTake = line.translations?.[language]?.takes?.find((take: any) => take.id === id)

          return newTake ? (newTake as Take) : undefined
        }}
        onDeleteTake={async (takeId) => {
          await deleteTake(takeId)
          //fetchLines()
        }}
        onUpdateTake={async (takeId, take) => {
          await updateTake(takeId, take)
          //fetchLines()
        }}
        onUpdateLine={async (lineId, line) => {
          await updateLine(lineId, {
            selectedTakes: line.selectedTakes
          })
          //fetchLines()
        }}
        onError={(e) => {
          setError(e)
        }}
        uploadAudioBuffer={async (audioBuffer) => {
          let options
          if (SAMPLE_SIZE) {
            options = {
              sampleSize: lineRecorder.value?.sampleSize.value
            }
          }
          const uri = uploadAudioBuffer(audioBuffer, options)
          return uri
        }}
        showSampleSize={SAMPLE_SIZE}
        isRequesting={isRequesting}
        onRemoteControlRequest={
          !isRemoteControlAccepted
            ? async () => {
                const { data: requestId } = await post(
                  `/sessions/${sessionId}/remote-control-requests`
                )
                setRemoteControlRequestId(requestId)
                setIsRequesting(true)
              }
            : undefined
        }
        onRemoteControlStop={
          isRemoteControlAccepted
            ? async () => {
                await del(
                  `/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`
                )
                setIsRemoteControlAccepted(false)
                setRemoteControlRequestId(undefined)
                setRemoteRecordingStatus('not-recording')
              }
            : undefined
        }
        remoteControl={
          isRemoteControlAccepted
            ? {
                onSelectLine: (lineId: string) => {
                  post(`/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`, {
                    action: 'select-line',
                    lineId
                  })
                },
                onSelectCharacter: (characterId?: string) => {
                  post(`/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`, {
                    action: 'select-character',
                    characterId
                  })
                },
                onPlayReferenceAudio: ({ lineId }) => {
                  post(`/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`, {
                    action: 'play-reference-audio',
                    lineId
                  })
                },
                onStopReferenceAudio: ({ lineId }) => {
                  post(`/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`, {
                    action: 'stop-reference-audio',
                    lineId
                  })
                },
                onPlayTake: ({ lineId, takeId, startTime }) => {
                  post(`/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`, {
                    action: 'play-take',
                    lineId,
                    takeId,
                    startTime
                  })
                },
                onStopTake: ({ lineId, takeId }) => {
                  post(`/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`, {
                    action: 'stop-take',
                    lineId,
                    takeId
                  })
                },
                onRecStart: (lineId: string) => {
                  post(`/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`, {
                    action: 'start-recording',
                    lineId
                  })
                  setRemoteRecordingStatus('recording-requested')
                },
                onRecStop: (lineId: string) => {
                  post(`/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`, {
                    action: 'stop-recording',
                    lineId
                  })
                  setRemoteRecordingStatus('stop-requested')
                },
                onShowAllTakes: (showAllTakes: boolean) => {
                  post(`/sessions/${sessionId}/remote-control-requests/${remoteControlRequestId}`, {
                    action: 'show-all-takes',
                    showAllTakes
                  })
                },
                recordingStatus: remoteRecordingStatus
              }
            : undefined
        }
      />
    </Block>
  )
}
