import { Fragment, h } from 'preact'
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks'
import { Block, Row, InlineBlock } from 'jsxstyle/preact'
import {
  AddIcon,
  ArrowDownwardIcon,
  ArrowUpwardIcon,
  BasicDialog,
  DeleteIcon,
  EditIcon,
  ErrorIcon,
  IconButton,
  Link,
  PlayIcon,
  ProgressCircular,
  SkipNextIcon,
  SkipPrevIcon,
  SpacerHorizontal,
  SpacerVertical,
  StopIcon,
  VoiceIcon
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { setError, showSnackbar, updateScene, updateSceneSeqNumber } from '../../actions'
import { useUrlParams } from '../../use-url-params'

import { usePlayer } from '../use-player'

import Page from '../Page'
import Avatar from '../Avatar'

import DeleteLine from './DeleteLine'
import { Line as LineType, Take } from '../../types'
import { store, useStore } from '../../store'
import { formatLanguage } from 'lib'
import { patch } from 'src/api'

const linesDiff = (lines?: LineType[], lines2?: LineType[]) => {
  if (!lines && !lines2) {
    return false
  }
  if (lines && lines2) {
    return (
      lines.length !== lines2.length || lines.some((line, index) => line.id !== lines2[index].id)
    )
  }
  return true
}

type PlayButtonProps = {
  take: Take
  togglePlaying: (uri: string) => void
  isPlaying: (uri: string) => boolean
}

const PlayButton = ({ take, togglePlaying, isPlaying }: PlayButtonProps) => {
  if (!take) {
    return <IconButton disabled icon={PlayIcon} />
  }
  if (take.error) {
    return (
      <IconButton
        icon={ErrorIcon}
        color="var(--error)"
        onClick={() => setError({ message: take.error })}
      />
    )
  }
  if (take.uri) {
    return (
      <IconButton
        icon={isPlaying(take.uri) ? StopIcon : PlayIcon}
        onClick={() => togglePlaying(take.uri)}
      />
    )
  }
  return <ProgressCircular size="20" />
}

type LineProps = {
  line: LineType
  onClick: (line: LineType) => void
  onMoveUp?: () => void
  onMoveDown?: () => void
  onDelete: () => void
  selected: boolean
  player: any
  language: string
  primaryLanguage: string
}

const Line = ({
  line,
  onClick,
  onMoveUp,
  onMoveDown,
  onDelete,
  selected,
  player,
  language,
  primaryLanguage
}: LineProps) => {
  const currentLanguageTranslation = line.translations[language]
  const primaryLanguageTranslation = line.translations[primaryLanguage]

  if (!currentLanguageTranslation && !primaryLanguageTranslation) {
    return null
  }

  return (
    <Row alignItems="flex-start" gap="10px" scrollMargin="200px" class={line.id}>
      <Avatar
        size={30}
        src={line!.character?.picture}
        name={line!.character?.name}
        link={{
          href: `/character/${line!.character?.id}`,
          onRoute: routeTo
        }}
      />
      <Block
        marginTop="-15px"
        background="var(--surface)"
        padding="20px"
        borderRadius="10px"
        border={selected ? 'solid 1px var(--accent)' : ''}
        props={{
          onClick: (e: MouseEvent) => {
            e.stopPropagation()
            onClick(line)
          }
        }}
      >
        <Block color={!currentLanguageTranslation?.line ? 'var(--warning)' : undefined}>
          {currentLanguageTranslation?.line
            ? currentLanguageTranslation.line
            : `Not translated to ${formatLanguage(language)}`}
          {!currentLanguageTranslation?.line && primaryLanguageTranslation?.line && (
            <Block color="var(--on-surface-light)">
              <SpacerVertical tiny />
              {formatLanguage(primaryLanguage)}: {primaryLanguageTranslation.line}
            </Block>
          )}
        </Block>
        {line.description && (
          <Fragment>
            <SpacerVertical tiny />
            <Block fontSize="14px" fontStyle="italic" color="var(--on-surface-lighter)">
              {line.description}
            </Block>
          </Fragment>
        )}
        {selected && (
          <Fragment>
            <SpacerVertical tiny />
            <Row alignItems="center" marginLeft="-10px">
              <PlayButton take={currentLanguageTranslation.selectedTake} {...player} />
              <IconButton small icon={ArrowUpwardIcon} disabled={!onMoveUp} onClick={onMoveUp} />
              <IconButton
                small
                icon={ArrowDownwardIcon}
                disabled={!onMoveDown}
                onClick={onMoveDown}
              />
              <IconButton
                small
                icon={EditIcon}
                link={{
                  href: `/line/${line.id}`,
                  onRoute: routeTo
                }}
              />
              <IconButton small icon={DeleteIcon} onClick={onDelete} />
            </Row>
          </Fragment>
        )}
      </Block>
    </Row>
  )
}

export const ManageScene = () => {
  const { scene, currentGame, currentLanguage } = useStore(
    'scene',
    'currentGame',
    'currentLanguage'
  )
  const params = new URLSearchParams(location.search)

  const [lineId, setLineId] = useState(params.get('lineId') || undefined)
  const [lines, setLines] = useState<LineType[]>(scene!.lines || [])
  const [isPlaying, setIsPlaying] = useState(false)

  const linesWithTake = useMemo(
    () =>
      lines?.filter((line) => line.translations[currentGame!.primaryLanguage]?.selectedTake?.uri) ||
      [],
    [lines]
  )

  const line = lineId ? lines?.find((line) => line.id === lineId) : undefined

  const lineTranslation = line?.translations[currentGame!.primaryLanguage]

  const canPlay = lineTranslation ? lineTranslation.selectedTake?.uri : linesWithTake?.length

  useUrlParams({ lineId })

  const onEnded = useCallback(() => {
    if (isPlaying) {
      const index = linesWithTake.findIndex((line) => line.id === lineId) || 0
      if (index >= 0 && index < linesWithTake.length - 1) {
        const lineId = linesWithTake[index + 1].id
        setLineId(lineId)
        document
          .getElementsByClassName(lineId)[0]
          .scrollIntoView({ behavior: 'smooth', block: 'end' })
      } else {
        setLineId(undefined)
      }
    }
  }, [isPlaying, lineId, linesWithTake])

  const player = usePlayer({ onEnded })

  useEffect(() => {
    setLines(scene!.lines?.sort((a, b) => (a.sceneSeqNumber ?? 0) - (b.sceneSeqNumber ?? 0)) || [])
  }, [scene])

  useEffect(() => {
    if (!lineId) {
      player.stop()
      setIsPlaying(false)
    }
    if (lineId && isPlaying) {
      const line = lines?.find((line) => line.id === lineId)
      if (lineTranslation?.selectedTake?.uri) {
        player.play(lineTranslation.selectedTake.uri)
      }
    }
  }, [lineId, isPlaying])

  const onBack = () => goBack('/scenes')

  const closeDialog = () => goBack(`/scene/${scene!.id}`)

  const onPlay = () => {
    if (!lineId && linesWithTake.length > 0) {
      setLineId(linesWithTake[0].id)
    }
    setIsPlaying(true)
  }

  const stopPlaying = () => {
    player.stop()
    setIsPlaying(false)
  }

  const onSkipPrev = () => {
    if (lineId) {
      const index = linesWithTake.findIndex((line) => line.id === lineId)
      if (index > 0) {
        setLineId(linesWithTake[index - 1].id)
      }
    }
  }

  const onSkipNext = () => {
    if (lineId) {
      const index = linesWithTake.findIndex((line) => line.id === lineId)
      if (index >= 0 && index < linesWithTake.length - 1) {
        setLineId(linesWithTake[index + 1].id)
      }
    }
  }

  const moveLine = async (line: LineType, newSceneSeqNumber: number) => {
    updateSceneSeqNumber(scene, line, newSceneSeqNumber)
  }

  const moveUp = (index: number) => {
    const lineToMove = lines[index]
    if (index > 0 && lineToMove?.sceneSeqNumber !== undefined) {
      moveLine(lineToMove, lineToMove.sceneSeqNumber - 1)
    }
  }

  const moveDown = (index: number) => {
    const lineToMove = lines[index]
    if (index < lines.length - 1 && lineToMove?.sceneSeqNumber !== undefined) {
      moveLine(lineToMove, lineToMove.sceneSeqNumber + 1)
    }
  }

  return (
    <Page
      title={scene!.name}
      actions={[
        {
          label: 'Edit details',
          icon: EditIcon,
          link: {
            href: `/scene/${scene!.id}/edit`,
            onRoute: routeTo
          }
        },
        {
          label: 'Create line',
          icon: AddIcon,
          link: {
            href: `/create-line?sceneId=${scene!.id}`,
            onRoute: routeTo
          }
        },
        {
          label: 'Add existing line',
          icon: AddIcon,
          link: {
            href: `/scene/${scene!.id}/add-existing-lines`,
            onRoute: routeTo
          }
        },
        {
          label: 'Record',
          icon: VoiceIcon,
          link: {
            href: '/record-lines',
            onRoute: (path) => {
              const lineIds = lines.map((line) => line.id)
              sessionStorage.setItem('recorderLineIds', JSON.stringify(lineIds))
              routeTo(path)
            }
          }
        }
      ]}
      footer={
        <Row alignItems="center" justifyContent="center" width="100%">
          <IconButton icon={SkipPrevIcon} disabled={!isPlaying} onClick={onSkipPrev} />
          <SpacerHorizontal />
          {isPlaying && <IconButton outlined icon={StopIcon} onClick={stopPlaying} />}
          {!isPlaying && (
            <IconButton outlined icon={PlayIcon} disabled={!canPlay} onClick={onPlay} />
          )}
          <SpacerHorizontal />
          <IconButton icon={SkipNextIcon} disabled={!isPlaying} onClick={onSkipNext} />
        </Row>
      }
      onBack={onBack}
    >
      <Block>{scene!.description}</Block>
      <SpacerVertical large />
      {lines.length === 0 && (
        <Block color="var(--on-surface-lighter)" fontStyle="italic">
          No lines
        </Block>
      )}
      {lines.length > 0 && (
        <Fragment>
          <Block
            minHeight="400px"
            background="var(--surface-alternative)"
            padding="40px 20px"
            props={{
              onClick: () => setLineId(undefined)
            }}
          >
            {lines.map((line, index) => {
              const selected = lineId === line.id
              return (
                <Fragment>
                  <Line
                    line={line}
                    selected={selected}
                    onClick={() => {
                      if (!selected) {
                        stopPlaying()
                        setLineId(line.id)
                      }
                    }}
                    onMoveUp={index > 0 ? () => moveUp(index) : undefined}
                    onMoveDown={index < lines.length - 1 ? () => moveDown(index) : undefined}
                    onDelete={() => routeTo(`/scene/${scene!.id}/delete-line/${line.id}`)}
                    player={player}
                    language={currentLanguage}
                    primaryLanguage={currentGame?.primaryLanguage!}
                  />
                  {index < lines.length - 1 && <SpacerVertical large />}
                </Fragment>
              )
            })}
          </Block>
        </Fragment>
      )}
      <Route
        path="/scene/:sceneId/add-existing-lines"
        render={() => {
          return (
            <BasicDialog
              disableAutofocus
              title="Add existing lines"
              primaryActionText="Select lines"
              onPrimaryActionClick={() => routeTo('/lines')}
              action1Text="Cancel"
              onAction1Click={closeDialog}
              onClose={closeDialog}
            >
              On the <Link to="/lines">lines page</Link>, select the lines you want to add to the
              scene and click the{' '}
              <InlineBlock padding="0 10px">
                <pre>Add to…</pre>
              </InlineBlock>{' '}
              button.
            </BasicDialog>
          )
        }}
      />
      <Route path="/scene/:sceneId/delete-line/:lineId" component={DeleteLine} />
    </Page>
  )
}

export default ManageScene
