import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'
import {
  AddIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  EventIcon,
  FilterListIcon,
  ImageIcon,
  LabelIcon,
  Link,
  RecordVoiceOverIcon,
  MemoryIcon,
  SelectChip,
  SettingsIcon,
  SpacerVertical,
  StarIcon,
  UploadIcon,
  VoiceIcon,
  Button,
  SpacerHorizontal,
  PlayIcon,
  ListIcon
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import {
  DeleteTakesResult,
  RestoreTakesResult,
  fetchLines,
  refetchLines,
  showSnackbar,
  updateLine
} from '../../actions'
import { useUrlParams } from '../../use-url-params'
import { useEvent } from '../../use-event'
import { useCharacter } from '../../use-character'
import { useScene } from '../../use-scene'
import { isUUID } from '../../is-uuid'

import Page from '../Page'
import Spinner from '../Spinner'
import SearchTextField from '../SearchTextField'
import Avatar from '../Avatar'
import ColumnDisplayOptions from '../ColumnDisplayOptions'
import SelectEvent from '../SelectEvent'
import SelectCharacter from '../SelectCharacter'
import SelectScene from '../SelectScene'

import LinesTable from './LinesTable'
import DeleteLines from './DeleteLines'
import AddToScene from './AddToScene'
import AddToSession from './AddToSession'
import { DownloadAudio } from './DownloadAudio'
import { useSession } from '../../use-session'
import { SelectSession } from '../SelectSession'
import { useEffectSkipFirst } from '../../use-effect-skip-first'
import { get } from '../../api'
import { ExportLines } from './ExportLines'
import { AddToEvent } from './AddToEvent'
import { GenerateManyAITakes } from './GenerateManyAITakes'
import { store, useStore } from '../../store'
import {
  Character,
  Event,
  Line,
  Take,
  Scene,
  Game,
  LineFilter,
  LineFilterTakeStatus,
  LineFilterStatus,
  LineFilterSettings
} from '../../types'
import { capitalize } from '../capitalize'
import { SelectLabels } from '../SelectLabels'
import { AddLabelDialog } from './AddLabelDialog'
import { RemoveLabelDialog } from './RemoveLabelDialog'
import { RemoveFromSession } from './RemoveFromSession'
import { formatLanguage } from '../../format-language'
import { TranslateLines } from './TranslateLines'
import { SingleAudioTrack, formatScheduled, pluralize, useAudioPlayer } from 'lib'
import { OutdatedTakeDialog } from './OutdatedTakeDialog'
import { TakeStatusSelectChip } from './TakeStatusSelectChip'
import { deleteSearchParam } from '../../delete-search-param'
import { WwiseExportDialogLocalStorage } from '../WwiseExport/WwiseExportDialog'
import { DeleteTakes } from './DeleteTakes'
import { RestoreTakes } from './RestoreTakes'
import { LineStatusSelectChip } from './LineStatusSelectChip'
import RestoreLines from './RestoreLines'
import { EditNotesDialog } from './EditNotesDialog'
import { fetchLineFilters } from 'src/actions/line-filters'
import ManageLineFilters from './LineFilters/ManageLineFilters'
import {
  LinesViewOptions,
  defaultLinesViewOptions,
  getLocalStorageLinesViewOptions,
  updateLocalStorageLinesViewOptions
} from './lines-view-options'

export const LinesPage = () => {
  const {
    currentGame,
    lines,
    lineAttributes,
    totalLines = 0,
    isFetchingLines,
    currentLanguage,
    lineFilters
  } = useStore(
    'currentGame',
    'lines',
    'lineAttributes',
    'totalLines',
    'isFetchingLines',
    'currentLanguage',
    'lineFilters'
  )
  const params = new URLSearchParams(location.search)

  const secondaryLanguage =
    currentLanguage !== currentGame?.primaryLanguage ? currentLanguage : undefined

  const [query, setQuery] = useState(params.get('query') ?? '')

  const [outdatedTake, setOutdatedTake] = useState<
    { take: Take; line: Line; language: string } | undefined
  >(undefined)

  const [notesLineId, setNotesLineId] = useState<string | undefined>()

  const [selectedLineIds, setSelectedLineIds] = useState<string[]>([])
  const [hasSelectedAllLines, setHasSelectedAllLines] = useState(false)

  // Start - View options that are also saved in local storage
  const [showFilters, setShowFilters] = useState(defaultLinesViewOptions.showFilters)
  const [columns, setColumns] = useState<string[]>(defaultLinesViewOptions.columns)
  const { characterId, setCharacterId, character, setCharacter, clearCharacter } =
    useCharacter(undefined)
  const { eventId, setEventId, event, setEvent, clearEvent } = useEvent(undefined)
  const { sceneId, setSceneId, scene, setScene, clearScene } = useScene(undefined)
  const { sessionId, setSessionId, session, setSession, clearSession } = useSession(undefined)
  const [labels, setLabels] = useState<string[] | undefined>(undefined)
  const [takeStatus, setTakeStatus] = useState<LineFilterTakeStatus | undefined>(undefined)
  const [lineStatus, setLineStatus] = useState<LineFilterStatus | undefined>(undefined)
  const [orderBy, setOrderBy] = useState<string>(defaultLinesViewOptions.orderBy)

  const [sortOrder, setSortOrder] = useState(defaultLinesViewOptions.sortOrder)
  const [page, setPage] = useState(defaultLinesViewOptions.page)
  const [pageSize, setPageSize] = useState(defaultLinesViewOptions.pageSize)
  // End - View options

  const [hasReadFromLocalStorage, setHasReadFromLocalStorage] = useState(false)

  useEffect(() => {
    fetchLineFilters()
  }, [])

  // When any view options has changed, update local storage
  useEffectSkipFirst(() => {
    updateLocalStorageLinesViewOptions(currentGame!.shortId, {
      showFilters,
      columns,
      characterId,
      eventId,
      sessionId,
      sceneId,
      labels,
      takeStatus,
      lineStatus,
      orderBy,
      sortOrder,
      page,
      pageSize
    })
  }, [
    showFilters,
    columns,
    characterId,
    eventId,
    sceneId,
    sessionId,
    labels,
    takeStatus,
    lineStatus,
    orderBy,
    sortOrder,
    page,
    pageSize
  ])

  const updateLinesViewOptionsFromLocalStorage = (
    game: Game,
    { useParams }: { useParams: boolean }
  ) => {
    const linesViewOptions =
      getLocalStorageLinesViewOptions(game.shortId) ?? defaultLinesViewOptions

    setHasReadFromLocalStorage(true)

    setShowFilters(linesViewOptions.showFilters)

    const characterId = (useParams && params.get('characterId')) || linesViewOptions.characterId
    characterId ? setCharacterId(characterId) : clearCharacter()

    const eventId = (useParams && params.get('eventId')) || linesViewOptions.eventId
    eventId ? setEventId(eventId) : clearEvent()

    const sessionId = (useParams && params.get('sessionId')) || linesViewOptions.sessionId
    sessionId ? setSessionId(sessionId) : clearSession()

    const sceneId = (useParams && params.get('sceneId')) || linesViewOptions.sceneId
    sceneId ? setSceneId(sceneId) : clearScene()

    // Remove column if it referes to a deleted line attribute
    const columns = linesViewOptions.columns.filter((column) => {
      if (isUUID(column) && lineAttributes && !lineAttributes.some((attr) => attr.id === column)) {
        return false
      }
      return true
    })
    setColumns(columns)

    const labels =
      useParams && params.get('labels') ? params.get('labels')?.split(',') : linesViewOptions.labels
    setLabels(labels)
    setTakeStatus(
      (useParams && (params.get('takeStatus') as LineFilterTakeStatus)) ||
        linesViewOptions.takeStatus
    )
    setLineStatus(
      (useParams && (params.get('lineStatus') as LineFilterStatus)) || linesViewOptions.lineStatus
    )

    let orderBy = (useParams && params.get('orderBy')) || linesViewOptions.orderBy

    // Check if orderBy referes to a deleted line attribute
    if (
      orderBy.endsWith('_value') &&
      lineAttributes &&
      !lineAttributes.some((attr) => orderBy.startsWith(attr.id))
    ) {
      setOrderBy('created')
    } else {
      setOrderBy(orderBy)
    }

    setSortOrder((useParams && params.get('sortOrder')) || linesViewOptions.sortOrder)

    const page = useParams ? Number(params.get('page')) : undefined
    setPage(page !== undefined ? page : linesViewOptions.page)

    setPageSize(linesViewOptions.pageSize)
  }

  useEffect(() => {
    if (!hasReadFromLocalStorage && lineAttributes) {
      updateLinesViewOptionsFromLocalStorage(currentGame!, { useParams: false })
    }
  }, [currentGame, hasReadFromLocalStorage, lineAttributes])

  useEffectSkipFirst(() => {
    if (!currentGame?.id) {
      return
    }
    // This will trigger a read from local storage, which in turn will trigger a refetch of the lines with correct filters/sorting
    setHasReadFromLocalStorage(false)
  }, [currentGame?.id])

  useEffect(() => {
    if (!currentGame || !lineAttributes) {
      return
    }
    updateLinesViewOptionsFromLocalStorage(currentGame, { useParams: true })
  }, [lineAttributes])

  const restore = params.get('restore')

  let numActiveFilters = 0
  if (characterId) {
    numActiveFilters += 1
  }
  if (eventId) {
    numActiveFilters += 1
  }
  if (sceneId) {
    numActiveFilters += 1
  }
  if (sessionId) {
    numActiveFilters += 1
  }
  if (labels?.length) {
    numActiveFilters += 1
  }
  if (takeStatus !== undefined) {
    numActiveFilters += 1
  }
  if (lineStatus !== undefined) {
    numActiveFilters += 1
  }

  const closeDialog = () => goBack('/lines')

  useUrlParams(
    {
      page,
      query,
      orderBy,
      sortOrder,
      eventId,
      characterId,
      sceneId,
      sessionId,
      labels,
      takeStatus,
      lineStatus
    },
    { page: 0, query: '', orderBy: 'created', sortOrder: 'desc' }
  )

  useEffect(() => {
    if (lines && !hasSelectedAllLines) {
      setSelectedLineIds(
        lines.filter((line) => selectedLineIds.includes(line.id)).map((line) => line.id)
      )
    }
  }, [lines])

  const restoreSessionState = () => {
    if (currentGame) {
      const sessionJSON = localStorage.getItem(`speechless:${currentGame.id}:lines`)
      if (sessionJSON) {
        const session = JSON.parse(sessionJSON)

        setSelectedLineIds(session.selectedIds ?? [])
      }
    }
  }

  const saveSessionState = () => {
    if (currentGame) {
      localStorage.setItem(
        `speechless:${currentGame.id}:lines`,
        JSON.stringify({
          selectedIds: selectedLineIds
        })
      )
    }
  }

  useEffect(() => {
    if (restore) {
      restoreSessionState()
      deleteSearchParam('restore')
    }
  }, [restore])

  const doFetchLines = async () => {
    fetchLines({
      offset: page * pageSize,
      limit: pageSize,
      query,
      sortOrder,
      orderBy,
      eventId,
      characterId,
      sceneId,
      sessionId,
      labels,
      language: secondaryLanguage,
      takeStatus,
      lineStatus,
      includeTakes: true
    })
  }

  const doRefetchLines = async () => {
    const lineIds = lines?.map((line) => line.id)
    if (lineIds) {
      refetchLines(lineIds, {
        limit: pageSize,
        language: secondaryLanguage,
        includeTakes: true
      })
    }
  }

  useEffect(() => {
    const inProgress =
      lines &&
      lines.some((line) => {
        const lineTranslation = line.translations[currentLanguage]
        return lineTranslation && lineTranslation.takes?.some((take) => !take.uri && !take.error)
      })
    if (inProgress) {
      const timeout = setTimeout(doRefetchLines, 5000)
      return () => clearTimeout(timeout)
    }
  }, [lines])

  useEffect(() => {
    if (!hasReadFromLocalStorage) {
      return
    }

    doFetchLines()
  }, [
    page,
    pageSize,
    query,
    sortOrder,
    orderBy,
    eventId,
    characterId,
    sceneId,
    sessionId,
    labels,
    secondaryLanguage,
    outdatedTake,
    takeStatus,
    lineStatus,
    hasReadFromLocalStorage
  ])

  const { player } = useAudioPlayer({ useAsMainPlayer: true })

  if (!player) {
    return <Spinner />
  }

  const { initPlayer, play, stopPlayer, showPlayer, hidePlayer } = player

  if (!lines) {
    return <Spinner />
  }

  if (eventId && !event) {
    return <Spinner />
  }

  if (characterId && !character) {
    return <Spinner />
  }

  if (sceneId && !scene) {
    return <Spinner />
  }

  if (sessionId && !session) {
    return <Spinner />
  }

  const selectedRows: number[] = []
  for (let i = 0; i < lines.length; i++) {
    if (selectedLineIds.includes(lines[i].id)) {
      selectedRows.push(i)
    }
  }

  const fetchAllIds = async () => {
    const { data: lineIds } = await get(`/games/${currentGame!.id}/line-ids`, {
      query,
      sortOrder,
      orderBy,
      eventId,
      characterId,
      sceneId,
      sessionId,
      language: secondaryLanguage,
      takeStatus,
      lineStatus,
      labels
    })

    setSelectedLineIds(lineIds)
    setHasSelectedAllLines(true)
  }

  const handleSelectedRowsChange = (selectedRows: number[]) => {
    const selectedIds = lines
      .filter((_, index) => selectedRows.includes(index))
      .map((line) => line.id)
    setSelectedLineIds(selectedIds)
    setHasSelectedAllLines(false)
  }

  const handleSelectedTakeChange = async (lineId: string, takeId?: string) => {
    const updatedLine = await updateLine(lineId, {
      selectedTakes: [{ language: currentLanguage, takeId: takeId || null }]
    })

    if (updatedLine) {
      // Update stored lines, replacing the single updated line to avoid having to refetch any lines
      const updatedStoreLines = (store.getState().lines || []).map((line) =>
        line.id === updatedLine?.id ? updatedLine : line
      )
      store.setState({
        lines: updatedStoreLines
      })
    }
  }

  const handleDeleteLines = async (deletedLineIds: string[]) => {
    // If all lines on any page but the first are deleted, we reset to page 0
    // This way we don't risk ending up on a page that's "out of bounds" after deleting lines
    if (page > 0 && deletedLineIds.length >= lines.length) {
      // (Reset of page will automatically refetch lines)
      setPage(0)
    } else {
      doFetchLines()
    }
  }

  const handleRestoreLines = async (restoredLineIds: string[]) => {
    if (restoredLineIds.length > 0) {
      showSnackbar(
        `Restored ${restoredLineIds.length} ${pluralize('line', restoredLineIds.length)}`
      )
    } else {
      showSnackbar(`No lines to restore`)
    }
    doFetchLines()
  }

  const hasManageAccess = currentGame?.role === 'owner' || currentGame?.role === 'admin'

  const selectedLinesPlaylist: SingleAudioTrack[] = selectedLineIds
    .map((lineId) => {
      const line = lines.find((line) => line.id === lineId)
      const selectedTake = line?.translations[currentLanguage].selectedTake
      if (line && selectedTake?.uri) {
        return {
          uri: selectedTake.uri,
          picture:
            selectedTake.voice?.picture ||
            selectedTake.aiVoice?.picture ||
            selectedTake.user?.picture,
          isAiVoice: !!selectedTake.aiVoice,
          name: line.character?.name,
          description: line.translations[currentLanguage].line
        }
      }
      return undefined
    })
    .filter(Boolean) as SingleAudioTrack[]

  const playSelectedLines = () => {
    if (selectedLinesPlaylist.length > 0) {
      initPlayer({
        audioTracks: selectedLinesPlaylist,
        options: {
          autoplayNextTrack: true
        }
      })
      showPlayer()
      play({ audioTrackIndex: 0 })
    }
  }

  const loadLineFilter = (filter: LineFilter) => {
    // First, update local storage with the filter settings
    updateLocalStorageLinesViewOptions(
      currentGame!.shortId,
      {
        ...filter.settings,
        showFilters: true,
        page: 0,
        pageSize
      },
      { overwrite: true }
    )

    // Then, update local state with newly saved local storage data
    updateLinesViewOptionsFromLocalStorage(currentGame!, { useParams: false })
  }

  const getCurrentLineFilterSettings = (): LineFilterSettings => {
    const parseLineFilterSettings = (viewOptions: LinesViewOptions): LineFilterSettings => {
      // Picking most but not all properties from LinesViewOptions
      return {
        columns: viewOptions.columns,
        characterId: viewOptions.characterId,
        eventId: viewOptions.eventId,
        sceneId: viewOptions.sceneId,
        sessionId: viewOptions.sessionId,
        labels: viewOptions.labels,
        lineStatus: viewOptions.lineStatus,
        takeStatus: viewOptions.takeStatus,
        orderBy: viewOptions.orderBy,
        sortOrder: viewOptions.sortOrder
      }
    }

    const currentLinesViewOptions = getLocalStorageLinesViewOptions(currentGame!.shortId)

    return parseLineFilterSettings(currentLinesViewOptions ?? defaultLinesViewOptions)
  }

  const lineFilterSettings = getCurrentLineFilterSettings()

  return (
    <Page
      title="Lines"
      actions={[
        {
          label: 'Create line',
          icon: AddIcon,
          link: {
            href: '/create-line',
            onRoute: routeTo
          }
        },
        {
          label: 'Edit…',
          icon: EditIcon,
          disabled: selectedLineIds.length === 0,
          options: [
            {
              label: `Add label`,
              icon: LabelIcon,
              link: {
                href: '/lines/add-label',
                onRoute: routeTo
              }
            },
            {
              label: `Remove labels`,
              icon: LabelIcon,
              link: {
                href: '/lines/remove-label',
                onRoute: routeTo
              }
            },
            {
              label: `Remove from session`,
              icon: EventIcon,
              link: {
                href: '/lines/remove-from-session',
                onRoute: routeTo
              }
            },
            {
              label: `Translate to ${formatLanguage(currentLanguage)}`,
              visible: currentGame?.primaryLanguage !== currentLanguage,
              link: {
                href: '/lines/translate-lines',
                onRoute: routeTo
              }
            },
            {
              label: `Delete line${selectedLineIds.length > 1 ? 's' : ''}`,
              icon: DeleteIcon,
              link: {
                href: '/lines/delete-lines',
                onRoute: routeTo
              }
            },
            {
              label: `Restore line${selectedLineIds.length > 1 ? 's' : ''}`,
              link: {
                href: '/lines/restore-lines',
                onRoute: routeTo
              }
            }
          ]
        },
        {
          label: 'Takes…',
          icon: ListIcon,
          disabled: selectedLineIds.length === 0,
          options: [
            {
              label: `Generate AI take${selectedLineIds.length > 1 ? 's' : ''}…`,
              icon: MemoryIcon,
              link: {
                href: '/lines/generate-ai-take',
                onRoute: routeTo
              }
            },
            {
              label: `Play ${
                selectedLinesPlaylist.length > 1 ? selectedLinesPlaylist.length : ''
              } selected ${pluralize('take', selectedLinesPlaylist.length)}`,
              icon: PlayIcon,
              disabled: selectedLinesPlaylist.length === 0,
              onClick: playSelectedLines
            },
            {
              label: `Delete takes…`,
              icon: DeleteIcon,
              link: {
                href: '/lines/delete-takes',
                onRoute: routeTo
              }
            },
            {
              label: `Restore takes…`,
              link: {
                href: '/lines/restore-takes',
                onRoute: routeTo
              }
            }
          ]
        },
        {
          label: `Add to…`,
          icon: AddIcon,
          disabled: selectedLineIds.length === 0,
          options: [
            {
              label: `Add to event`,
              icon: StarIcon,
              link: {
                href: '/lines/add-to-event',
                onRoute: routeTo
              }
            },
            {
              label: `Add to session`,
              icon: RecordVoiceOverIcon,
              link: {
                href: '/lines/add-to-session',
                onRoute: routeTo
              }
            },
            {
              label: `Add to scene`,
              icon: ImageIcon,
              link: {
                href: '/lines/add-to-scene',
                onRoute: routeTo
              }
            }
          ]
        },
        {
          label: 'Export…',
          icon: DownloadIcon,
          disabled: selectedLineIds.length === 0,
          options: [
            {
              label: 'Export lines',
              icon: DownloadIcon,
              link: {
                href: '/lines/export-lines',
                onRoute: routeTo
              }
            },
            {
              label: 'Export audio',
              icon: DownloadIcon,
              link: {
                href: '/lines/download-audio',
                onRoute: routeTo
              }
            },
            {
              label: 'Export to Wwise',
              link: {
                href: '/lines/export-to-wwise',
                onRoute: routeTo
              }
            }
          ]
        },
        {
          label: 'Import…',
          icon: UploadIcon,
          options: [
            {
              label: 'Import lines',
              icon: UploadIcon,
              link: {
                href: '/upload-excel',
                onRoute: routeTo
              }
            },
            {
              label: 'Import audio',
              icon: UploadIcon,
              link: {
                href: '/upload-audio',
                onRoute: routeTo
              }
            }
          ]
        },
        {
          label: 'Customize…',
          icon: SettingsIcon,
          visible: hasManageAccess,
          options: [
            {
              label: 'Attributes',
              link: {
                href: '/lines/line-attributes',
                onRoute: routeTo
              }
            },
            {
              label: 'Labels',
              link: {
                href: '/lines/line-labels',
                onRoute: routeTo
              }
            }
          ]
        },
        {
          label: 'Record',
          icon: VoiceIcon,
          link: {
            href: '/record-lines',
            onRoute: (path) => {
              const lineIds =
                selectedLineIds.length > 0 ? selectedLineIds : lines.map((line) => line.id)
              sessionStorage.setItem('recorderLineIds', JSON.stringify(lineIds))
              routeTo(path)
            }
          }
        }
      ]}
    >
      <Col height="100%">
        <Row alignItems="center">
          <SearchTextField
            value={query}
            onChange={(query) => {
              setQuery(query)
              setPage(0)
            }}
          />
          <SpacerHorizontal tiny />
          {!showFilters && (
            <Button
              tiny
              icon={FilterListIcon}
              color={numActiveFilters > 0 ? 'var(--on-accent)' : 'var(--on-surface-light)'}
              contained={numActiveFilters > 0}
              onClick={() => setShowFilters(true)}
            >
              Show filters {numActiveFilters > 0 ? `(${numActiveFilters})` : ''}
            </Button>
          )}
          {showFilters && (
            <Button
              tiny
              icon={FilterListIcon}
              color="var(--on-surface-light)"
              onClick={() => setShowFilters(false)}
            >
              Hide filters {numActiveFilters > 0 ? `(${numActiveFilters})` : ''}
            </Button>
          )}
          <SpacerHorizontal />
          <Button
            tiny
            color="var(--on-surface-light)"
            onClick={() => routeTo('/lines/manage-line-filters')}
          >
            Manage saved filters ({lineFilters?.length ?? 0})
          </Button>
        </Row>
        {showFilters && (
          <>
            <SpacerVertical small />
            <Row alignItems="center" gap="5px">
              {character && (
                <SelectChip
                  visual={<Avatar size={30} src={character.picture} name={character.name} />}
                  value={character.name}
                  onClick={() => routeTo('/lines/select-character')}
                  onClear={() => {
                    setCharacter()
                    setPage(0)
                  }}
                />
              )}
              {!character && (
                <SelectChip
                  icon={FilterListIcon}
                  label="Character"
                  onClick={() => routeTo('/lines/select-character')}
                />
              )}
              {event && (
                <SelectChip
                  visual={<Avatar size={30} src={event.picture} name={event.name} />}
                  value={event.name}
                  onClick={() => routeTo('/lines/select-event')}
                  onClear={() => {
                    setEvent()
                    setPage(0)
                  }}
                />
              )}
              {!event && (
                <SelectChip
                  icon={FilterListIcon}
                  label="Event"
                  onClick={() => routeTo('/lines/select-event')}
                />
              )}
              {scene && (
                <SelectChip
                  visual={<Avatar size={30} src={scene.picture} name={scene.name} />}
                  value={scene.name}
                  onClick={() => routeTo('/lines/select-scene')}
                  onClear={() => {
                    setScene()
                    setPage(0)
                  }}
                />
              )}
              {!scene && (
                <SelectChip
                  icon={FilterListIcon}
                  label="Scene"
                  onClick={() => routeTo('/lines/select-scene')}
                />
              )}
              {session && (
                <SelectChip
                  visual={
                    session.voice ? (
                      <Avatar size={30} src={session.voice.picture} name={session.voice.name} />
                    ) : (
                      <EventIcon />
                    )
                  }
                  value={`${formatScheduled(session.scheduled)}`}
                  onClick={() => routeTo('/lines/select-session')}
                  onClear={() => {
                    setSession()
                    setPage(0)
                  }}
                />
              )}
              {!session && (
                <SelectChip
                  icon={FilterListIcon}
                  label="Session"
                  onClick={() => routeTo('/lines/select-session')}
                />
              )}
              {labels && labels.length > 0 && (
                <SelectChip
                  value={capitalize(
                    `${labels[0]}${labels.length > 1 ? ' +' + (labels.length - 1) : ''}`
                  )}
                  onClick={() => routeTo('/lines/select-labels')}
                  onClear={() => {
                    setLabels(undefined)
                    setPage(0)
                  }}
                />
              )}
              {(!labels || labels.length === 0) && (
                <SelectChip
                  icon={FilterListIcon}
                  label="Labels"
                  onClick={() => routeTo('/lines/select-labels')}
                />
              )}
              <LineStatusSelectChip
                value={lineStatus}
                onChange={(lineStatus?: LineFilterStatus) => {
                  setLineStatus(lineStatus)
                  setPage(0)
                }}
              />
              <TakeStatusSelectChip
                value={takeStatus}
                onChange={(takeStatus?: LineFilterTakeStatus) => {
                  setTakeStatus(takeStatus)
                  setPage(0)
                }}
              />
            </Row>
          </>
        )}
        <SpacerVertical small />
        {totalLines > 0 && (
          <>
            <Row alignItems="center" gap="10px">
              {selectedLineIds.length === 0 && <Block>No lines selected</Block>}
              {selectedLineIds.length > 0 && (
                <Block>
                  <strong>{selectedLineIds.length}</strong> of {totalLines} line
                  {totalLines !== 1 ? 's' : ''} selected
                </Block>
              )}
              {selectedLineIds.length !== totalLines && (
                <Link onClick={fetchAllIds}>Select all {totalLines} lines</Link>
              )}
              <SpacerHorizontal small />
              <ColumnDisplayOptions value={columns} onChange={setColumns} />
            </Row>
          </>
        )}
        <Block flex={1} position="relative">
          <LinesTable
            showNotesAndWarnings
            columns={columns}
            loading={isFetchingLines}
            lines={lines}
            total={totalLines}
            selectedRows={selectedRows}
            onSelectedRowsChange={handleSelectedRowsChange}
            labels={labels}
            setLabels={setLabels}
            page={page}
            pageSize={pageSize}
            orderBy={orderBy}
            sortOrder={sortOrder}
            setPage={setPage}
            setPageSize={setPageSize}
            setOrderBy={(orderBy: string) => {
              setOrderBy(orderBy)
              setPage(0)
            }}
            setSortOrder={(sortOrder: string) => {
              setSortOrder(sortOrder)
              setPage(0)
            }}
            event={event}
            setEvent={(event?: Event) => {
              setEvent(event)
              setPage(0)
            }}
            scene={scene}
            setScene={(scene?: Scene) => {
              setScene(scene)
              setPage(0)
            }}
            character={character}
            setCharacter={(character?: Character) => {
              setCharacter(character)
              setPage(0)
            }}
            onSelectedTakeChange={handleSelectedTakeChange}
            onShowOutdatedTake={setOutdatedTake}
            onShowNotes={setNotesLineId}
            player={player}
          />
        </Block>
      </Col>
      <Route
        path="/lines/export-to-wwise"
        render={() => (
          <WwiseExportDialogLocalStorage
            id={currentGame!.id}
            onClose={closeDialog}
            selectedIds={selectedLineIds}
          />
        )}
      />
      <Route
        path="/lines/manage-line-filters"
        render={() => (
          <ManageLineFilters
            onClose={closeDialog}
            filters={lineFilters ?? []}
            filterSettings={lineFilterSettings}
            onNewLineFilter={async (filter: LineFilter) => {
              showSnackbar(`Filter "${filter.name}" created`)
              fetchLineFilters()
            }}
            onOverwriteFilter={async (filter: LineFilter) => {
              showSnackbar(`Filter "${filter.name}" saved`)
              fetchLineFilters()
            }}
            onLoadFilter={async (filter: LineFilter) => {
              loadLineFilter(filter)
              showSnackbar(`Filter "${filter.name}" loaded`)
            }}
            onDeleteFilter={async (filter: LineFilter) => {
              showSnackbar(`Filter "${filter.name}" deleted`)
              fetchLineFilters()
            }}
            onEditFilter={async (filter: LineFilter) => {
              showSnackbar(`Renamed filter to "${filter.name}"`)
              fetchLineFilters()
            }}
          />
        )}
      />
      <Route
        path="/lines/select-character"
        render={() => (
          <SelectCharacter
            onClose={closeDialog}
            onSelect={(character) => {
              setCharacter(character)
              setPage(0)
            }}
          />
        )}
      />
      <Route
        path="/lines/select-event"
        render={() => (
          <SelectEvent
            onClose={closeDialog}
            onSelect={(event) => {
              setEvent(event)
              setPage(0)
            }}
          />
        )}
      />
      <Route
        path="/lines/select-scene"
        render={() => (
          <SelectScene
            onClose={closeDialog}
            onSelect={(scene) => {
              setScene(scene)
              setPage(0)
            }}
          />
        )}
      />
      <Route
        path="/lines/select-session"
        render={() => (
          <SelectSession
            onClose={closeDialog}
            onSelect={(session) => {
              closeDialog()
              setSession(session)
              setPage(0)
            }}
          />
        )}
      />
      <Route
        path="/lines/select-labels"
        render={() => (
          <SelectLabels
            selectedLabels={labels ?? []}
            onClose={closeDialog}
            onSelect={(labels: string[]) => {
              setLabels(labels)
              setPage(0)
            }}
          />
        )}
      />
      <Route
        path="/lines/generate-ai-take"
        render={() =>
          selectedLineIds?.length > 0 && (
            <GenerateManyAITakes
              lineIds={selectedLineIds}
              onSubmit={saveSessionState}
              onSuccess={doFetchLines}
              onClose={closeDialog}
            />
          )
        }
      />
      <Route
        path="/lines/translate-lines"
        render={() => (
          <TranslateLines
            lineIds={selectedLineIds}
            onSuccess={doFetchLines}
            onClose={closeDialog}
          />
        )}
      />
      <Route
        path="/lines/download-audio"
        render={() => {
          return (
            <DownloadAudio
              character={character}
              event={event}
              scene={scene}
              lineIds={selectedLineIds}
              onClose={closeDialog}
            />
          )
        }}
      />
      <Route
        path="/lines/export-lines"
        render={() => {
          // Make sure to always export externalLineId if used even if column has not been selected
          const exportColumns = [...columns]
          if (currentGame?.useExternalLineId && !exportColumns.includes('externalLineId')) {
            exportColumns.push('externalLineId')
          }
          return (
            <ExportLines
              lineIds={selectedLineIds}
              character={character}
              event={event}
              scene={scene}
              sortOrder={sortOrder}
              orderBy={orderBy}
              columns={exportColumns}
              onClose={closeDialog}
            />
          )
        }}
      />
      <Route
        path="/lines/add-to-event"
        render={() => {
          return (
            <AddToEvent lineIds={selectedLineIds} onClose={closeDialog} onSuccess={doFetchLines} />
          )
        }}
      />
      <Route
        path="/lines/add-to-scene"
        render={() => {
          return (
            <AddToScene lineIds={selectedLineIds} onClose={closeDialog} onSuccess={doFetchLines} />
          )
        }}
      />
      <Route
        path="/lines/add-to-session"
        render={() => {
          return (
            <AddToSession
              lineIds={selectedLineIds}
              onClose={closeDialog}
              onSuccess={() => {
                closeDialog()
                doFetchLines()
              }}
            />
          )
        }}
      />
      <Route
        path="/lines/remove-from-session"
        render={() => {
          return (
            <RemoveFromSession
              lineIds={selectedLineIds}
              onClose={closeDialog}
              onSuccess={doFetchLines}
            />
          )
        }}
      />
      <Route
        path="/lines/delete-takes"
        render={() => {
          return (
            <DeleteTakes
              lineIds={selectedLineIds}
              language={currentLanguage}
              onClose={closeDialog}
              onDelete={(result: DeleteTakesResult) => {
                doFetchLines()
                showSnackbar(`${result.total} ${pluralize('take', result.total)} deleted.`)
              }}
            />
          )
        }}
      />
      <Route
        path="/lines/restore-takes"
        render={() => {
          return (
            <RestoreTakes
              lineIds={selectedLineIds}
              language={currentLanguage}
              onClose={closeDialog}
              onRestore={(result: RestoreTakesResult) => {
                doFetchLines()
                showSnackbar(`${result.total} ${pluralize('take', result.total)} restored.`)
              }}
            />
          )
        }}
      />
      <Route
        path="/lines/delete-lines"
        render={() => (
          <DeleteLines
            lineIds={selectedLineIds}
            onClose={closeDialog}
            onSuccess={() => handleDeleteLines(selectedLineIds)}
          />
        )}
      />
      <Route
        path="/lines/restore-lines"
        render={() => (
          <RestoreLines
            lineIds={selectedLineIds}
            onClose={closeDialog}
            onSuccess={(restoredLineIds: string[]) => handleRestoreLines(restoredLineIds)}
          />
        )}
      />
      <Route
        path="/lines/add-label"
        render={() => (
          <AddLabelDialog
            lineIds={selectedLineIds}
            onClose={closeDialog}
            onSuccess={doFetchLines}
          />
        )}
      />
      <Route
        path="/lines/remove-label"
        render={() => (
          <RemoveLabelDialog
            lineIds={selectedLineIds}
            onClose={closeDialog}
            onSuccess={doFetchLines}
          />
        )}
      />
      {outdatedTake && (
        <OutdatedTakeDialog
          take={outdatedTake.take}
          line={outdatedTake.line}
          language={outdatedTake.language}
          onClose={() => setOutdatedTake(undefined)}
        />
      )}
      {notesLineId && (
        <EditNotesDialog
          line={lines.find((line) => line.id === notesLineId)!}
          onSave={doRefetchLines}
          onClose={() => {
            setNotesLineId(undefined)
            setTimeout(() => document.querySelectorAll('button').forEach((el) => el.blur()), 100)
          }}
        />
      )}
    </Page>
  )
}
