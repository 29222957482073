import { h } from 'preact'
import { useCallback, useEffect, useState } from 'preact/hooks'
import {
  AddIcon,
  CheckmarkIcon,
  DataTable,
  ErrorIcon,
  IconButton,
  Link,
  MoreIcon,
  PlayIcon
} from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

import { routeWithSearchParams } from '../../route-with-search-params'

import {
  deleteExportTarget,
  fetchExportTargets,
  showSnackbar,
  updateExportTarget
} from '../../actions'

import { BuiClickEvent, usePopupMenu } from '../use-popup-menu'

import Page from '../Page'
import Spinner from '../Spinner'

import googleDrive from './google-drive.svg'
import { useStore } from '../../store'
import { DriveTargetTypes, ExportTarget } from '../../types'
import { confirm, useSSE } from 'lib'
import { requiredScopes } from './CreateExportTarget'
import { Block, Row } from 'jsxstyle/preact'
import { post } from '../../api'
import { formatDuration } from '../../format-duration'
import { SyncDialog } from '../ExportTarget/SyncDialog'
import { loadScript } from '../../load-script'
import { WwiseExportDialogWithExportTarget } from '../WwiseExport/WwiseExportDialog'

declare var google: any

export const getStatus = (exportTarget: ExportTarget & { type: DriveTargetTypes }) => {
  if (exportTarget.error) {
    return (
      <Row alignItems="center" gap="5px">
        <ErrorIcon fill="var(--error)" flexShrink="0" />
        <Block maxWidth="200px" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
          {exportTarget.error}
        </Block>
      </Row>
    )
  }
  if (exportTarget.completed) {
    return (
      <Row alignItems="center" gap="5px">
        <CheckmarkIcon fill="var(--ok)" /> Success
      </Row>
    )
  }
  if (exportTarget.started) {
    return (
      <Row alignItems="center" color="var(--on-surface-light)">
        {exportTarget.progress}%
      </Row>
    )
  }
  return (
    <Row alignItems="center" color="var(--on-surface-light)" whiteSpace="nowrap">
      Has not run yet
    </Row>
  )
}

export const handleReconnect = async (exportTarget: ExportTarget) => {
  await loadScript('https://accounts.google.com/gsi/client')
  const client = google.accounts.oauth2.initCodeClient({
    client_id: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID,
    scope: requiredScopes.join(','),
    ux_mode: 'popup',
    callback: async (response: any) => {
      const {
        data: { refreshToken }
      } = await post('/google-access-tokens', { code: response.code })
      if (
        await updateExportTarget(exportTarget.id, {
          refreshToken,
          scopes: requiredScopes
        })
      ) {
        showSnackbar('Export target updated')
      }
    }
  })
  client.requestCode()
}

const handleDeleteExportTarget = async (exportTarget: ExportTarget) => {
  if (await confirm(`Delete ${exportTarget.name}?`))
    if (await deleteExportTarget(exportTarget.id)) {
      showSnackbar(`Export target ${exportTarget.name} deleted`)
    }
}

export const ExportTargets = () => {
  const { currentGame, exportTargets } = useStore(
    'currentGame',
    'exportTargets',
    'isSyncingExportTarget',
    'isDeletingExportTarget'
  )

  const [syncExportTarget, setSyncExportTarget] = useState<ExportTarget | undefined>()

  useEffect(() => {
    if (currentGame) {
      fetchExportTargets()
    }
  }, [currentGame!.id])

  useSSE({
    gameId: currentGame!.id,
    onExportTargetUpdated: fetchExportTargets
  })

  const { PopupMenu, showMenu } = usePopupMenu({
    options: [
      {
        label: 'Sync now',
        onClick: (exportTarget) => setSyncExportTarget(exportTarget),
        disabled: (exportTarget) => exportTarget.started && !exportTarget.completed
      },
      { label: 'Edit', onClick: (exportTarget) => routeTo(`/export-target/${exportTarget.id}`) },
      { label: 'Delete', onClick: (exportTarget) => handleDeleteExportTarget(exportTarget) }
    ]
  })

  const getMastering = (exportTarget: ExportTarget & { type: DriveTargetTypes }) => {
    if (exportTarget.processAudio) {
      return (
        <Block whiteSpace="nowrap">
          {exportTarget.format.toUpperCase()}{' '}
          {exportTarget.bitrate !== '0' ? `${exportTarget.bitrate} kbps` : ''}
        </Block>
      )
    }
    return <Block color="var(--on-surface-light)">No mastering</Block>
  }

  if (!exportTargets) {
    return <Spinner />
  }

  const header = [
    { label: 'Type' },
    { label: 'Name' },
    { label: 'Mastering' },
    { label: 'Sync now' },
    { label: 'Status of last sync' },
    { label: 'Last sync' },
    { label: 'Logs' },
    { label: 'Actions' }
  ]

  const body = exportTargets.map((exportTarget) => {
    if (exportTarget.type === 'google-drive') {
      return [
        <Block>
          <img src={googleDrive} width="30" height="30" />
        </Block>,
        <Link to={`/export-target/${exportTarget.id}`} onRoute={routeTo}>
          {exportTarget.name}
        </Link>,
        <Block>{getMastering(exportTarget)}</Block>,
        <IconButton
          icon={PlayIcon}
          onClick={() => setSyncExportTarget(exportTarget)}
          loading={exportTarget.started && !exportTarget.completed}
        />,
        <Block>{getStatus(exportTarget)}</Block>,
        <Block whiteSpace="nowrap">
          {exportTarget.started ? formatDuration(exportTarget.started) : ''}
        </Block>,
        <Link to={`/export-target/${exportTarget.id}/logs`} onRoute={routeTo}>
          See logs
        </Link>,
        <IconButton icon={MoreIcon} onClick={(e: BuiClickEvent) => showMenu(e, exportTarget)} />
      ]
    }

    if (exportTarget.type === 'wwise') {
      return [
        <Block>
          <img
            src={new URL('./wwise-logo-white.png', import.meta.url).href}
            width="30"
            height="30"
          />
        </Block>,
        <Link to={`/export-target/${exportTarget.id}`} onRoute={routeTo}>
          {exportTarget.name}
        </Link>,
        <Block>-</Block>,
        <IconButton icon={PlayIcon} onClick={() => setSyncExportTarget(exportTarget)} />,
        <Block>-</Block>,
        <Block whiteSpace="nowrap">-</Block>,
        <Link to={`/export-target/${exportTarget.id}/logs`} onRoute={routeTo}>
          -
        </Link>,
        <IconButton icon={MoreIcon} onClick={(e: BuiClickEvent) => showMenu(e, exportTarget)} />
      ]
    }
  })

  return (
    <Page
      title="Export targets"
      actions={[
        {
          label: 'Create target',
          icon: AddIcon,
          onClick: () => routeWithSearchParams('/create-export-target')
        }
      ]}
    >
      <DataTable header={header} body={body} emptyText="No export targets found" />
      {PopupMenu}
      {syncExportTarget && syncExportTarget.type === 'google-drive' && (
        <SyncDialog
          exportTarget={syncExportTarget}
          onClose={() => setSyncExportTarget(undefined)}
        />
      )}

      {syncExportTarget && syncExportTarget.type === 'wwise' && (
        <WwiseExportDialogWithExportTarget
          onClose={() => {
            setSyncExportTarget(undefined)
          }}
          exportTargetId={syncExportTarget.id}
        />
      )}
    </Page>
  )
}

export default ExportTargets
