import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, InlineBlock } from 'jsxstyle/preact'
import { Button, Checkbox, Dialog, Form, P, SpacerVertical, TextField } from '@sodra/bongo-ui'

import { acceptInvitation, setError } from '../actions'
import { get } from '../api'

import Spinner from './Spinner'
import LandingPage from './LandingPage'
import Avatar from './Avatar'
import { routeTo } from '@sodra/prutt'
import { useStore } from '../store'
import { Invitation } from '../types'
import NotLoggedIn from './NotLoggedIn'

export const AcceptInvitation = () => {
  const { currentUser, isAcceptingInvitation } = useStore('currentUser', 'isAcceptingInvitation')

  const params = new URLSearchParams(location.search)
  const invitationId = params.get('invitationId')

  const [invitation, setInvitation] = useState<Invitation>()
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const [passwordError, setPasswordError] = useState('')

  const [acceptedWrongEmail, setAcceptedWrongEmail] = useState(false)

  useEffect(() => {
    get(`/invitations/${invitationId}`)
      .then(({ data: invitation }) => setInvitation(invitation))
      .catch((err) => setError(err))
  }, [invitationId])

  if (!invitation) {
    return <Spinner />
  }

  const loggedIn = currentUser?.email === invitation.email

  // if (currentUser && !loggedIn && !acceptedWrongEmail) {
  //   return (
  //     <Dialog
  //       title="Wrong email"
  //       actions={[{ text: 'Ok', onClick: () => setAcceptedWrongEmail(true) }]}
  //     >
  //       You need to be logged in as {invitation.email} to accept the invitation.
  //     </Dialog>
  //   )
  // }

  // if (!loggedIn) {
  //   console.log(invitation.email, currentUser?.email)
  //   return <NotLoggedIn />
  // }

  const existingUser = invitation.user !== undefined

  const handleSubmit = async () => {
    let errorId
    if (!existingUser && password.trim() === '') {
      setPasswordError('Please choose a password')
      errorId = 'password'
    }

    if (!errorId) {
      if (await acceptInvitation(invitationId, invitation.game.id, { password })) {
        if (!existingUser) {
          routeTo('/account', true)
        } else {
          routeTo('/', true)
        }
      }
    }
  }

  return (
    <LandingPage>
      <Block textAlign="center">
        <Block component="h1" fontSize="14px" lineHeight="36px" fontWeight="normal">
          Join
          <br />
          <InlineBlock fontSize="34px" lineHeight="36px">
            {invitation.game.name}
          </InlineBlock>
        </Block>
        {invitation.game.picture && (
          <Avatar src={invitation.game.picture} name={invitation.game.name} size={90} />
        )}
      </Block>
      <SpacerVertical large />
      <Form width="100%" onSubmit={handleSubmit}>
        <P padding="5px 5px 0 15px">
          <Block
            fontSize="12px"
            lineHeight="14px"
            marginBottom="2px"
            color="var(--on-surface-light)"
          >
            Email
          </Block>
          <Block>{invitation.email}</Block>
        </P>
        {!existingUser && (
          <Fragment>
            <SpacerVertical />
            <TextField
              width="100%"
              type={!showPassword ? 'password' : 'text'}
              label="Password"
              name="new-password"
              value={password}
              onInput={setPassword}
              errorText={passwordError}
            />
            <SpacerVertical small />
            <Checkbox
              label="Show password"
              checked={showPassword}
              onChange={setShowPassword}
              margin="0"
            />
          </Fragment>
        )}
        {!existingUser && (
          <Fragment>
            <SpacerVertical />
            <P>
              By signing up you agree to the Speechless{' '}
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1ev23IgNfPYeorSRqyTG0izEa_cyuF9GI/view"
              >
                privacy policy
              </a>{' '}
              and{' '}
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1qmMY30reHNiNZfRasZgAjz-bvMWdcj2h/view"
              >
                client terms and conditions
              </a>
              .
            </P>
          </Fragment>
        )}
        <SpacerVertical large />
        <Button type="submit" contained loading={isAcceptingInvitation}>
          Accept invitation
        </Button>
      </Form>
    </LandingPage>
  )
}

export default AcceptInvitation
