import { h } from 'preact'
import { FilterListIcon, EventIcon, SelectChip, SpacerVertical, UploadIcon } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'
import { useEffect, useState } from 'preact/hooks'
import { Block, Col, Row } from 'jsxstyle/preact'

import { useUrlParams } from '../../use-url-params'
import { deleteFile, fetchFiles, updateFile } from '../../actions'

import Page from '../Page'
import SearchTextField from '../SearchTextField'
import Spinner from '../Spinner'
import { FilesTable } from './FilesTable'
import { useSession } from '../../use-session'
import { useUploader } from '../../use-uploader'

import { SelectSession } from '../SelectSession'
import Avatar from '../Avatar'
import { File as GameFile, Session } from '../../types'
import { ContentTypeSelectChip } from './ContentTypeSelectChip'
import { SelectUploader } from './SelectUploader'
import { useStore } from '../../store'
import { formatScheduled } from 'lib'

const pageSize = 10

export const Files = () => {
  const params = new URLSearchParams(location.search)

  const { currentGame, files, totalFiles, isFetchingFiles } = useStore(
    'files',
    'totalFiles',
    'isFetchingFiles',
    'currentGame'
  )

  const [query, setQuery] = useState(params.get('query') || '')
  const [page, setPage] = useState(parseFloat(params.get('page') || '0'))
  const [orderBy, setOrderBy] = useState(params.get('orderBy') || 'created')
  const [sortOrder, setSortOrder] = useState(params.get('sortOrder') || 'desc')
  const [contentType, setContentType] = useState(params.get('contentType') || undefined)

  const { sessionId, session, setSession } = useSession(params.get('sessionId') || undefined)

  const { uploaderId, uploader, setUploader } = useUploader(params.get('uploaderId') || undefined)

  useUrlParams(
    { sessionId, page, query, contentType, uploaderId, orderBy, sortOrder },
    { page: 0, query: '', orderBy: 'created', sortOrder: 'desc' }
  )

  const refetchFiles = () =>
    fetchFiles({
      gameId: currentGame?.id,
      sessionId,
      offset: page * pageSize,
      limit: pageSize,
      query,
      contentType,
      uploaderId,
      sortOrder,
      orderBy
    })

  useEffect(() => {
    refetchFiles()
  }, [currentGame?.id, sessionId, page, query, contentType, uploaderId, sortOrder, orderBy])

  const handleDeleteFile = async (file: GameFile): Promise<void> => {
    if (await deleteFile(file.id)) {
      refetchFiles()
    }
  }

  const addToSession = async (fileId: string, sessionId: string) => {
    if (await updateFile(fileId, { sessionId })) {
      refetchFiles()
    }
  }

  const removeFromSession = async (fileId: string) => {
    if (await updateFile(fileId, { sessionId: null })) {
      refetchFiles()
    }
  }

  if (!files) {
    return <Spinner />
  }

  return (
    <Page
      title="Files"
      actions={[
        {
          label: 'Upload',
          icon: UploadIcon,
          onClick: () =>
            sessionId ? routeTo(`/upload-file?sessionId=${sessionId}`) : routeTo('/upload-file')
        }
      ]}
    >
      <Col height="100%">
        <SearchTextField
          width="500px"
          value={query}
          onChange={(query: string) => {
            setQuery(query)
            setPage(0)
          }}
        />
        <SpacerVertical />
        <Row alignItems="center" gap="10px">
          <ContentTypeSelectChip
            value={contentType}
            onChange={(contentType?: string) => {
              setContentType(contentType)
              setPage(0)
            }}
          />
          {uploader && (
            <SelectChip
              visual={<Avatar size={30} src={uploader.picture} name={uploader.name} />}
              value={uploader.name}
              onClick={() => routeTo('/files/select-uploader')}
              onClear={() => {
                setUploader(undefined)
                setPage(0)
              }}
            />
          )}
          {!uploader && (
            <SelectChip
              icon={FilterListIcon}
              label="Uploader"
              onClick={() => routeTo('/files/select-uploader')}
            />
          )}
          {session && (
            <SelectChip
              visual={
                session.voice ? (
                  <Avatar size={30} src={session.voice.picture} name={session.voice.name} />
                ) : (
                  <EventIcon />
                )
              }
              value={`${formatScheduled(session.scheduled)}`}
              onClick={() => routeTo('/files/select-session')}
              onClear={() => {
                setSession()
                setPage(0)
              }}
            />
          )}
          {!session && (
            <SelectChip
              icon={FilterListIcon}
              label="Session"
              onClick={() => routeTo('/files/select-session')}
            />
          )}
        </Row>
        <SpacerVertical />
        <FilesTable
          loading={isFetchingFiles}
          files={files}
          total={totalFiles}
          page={page}
          pageSize={pageSize}
          orderBy={orderBy}
          sortOrder={sortOrder}
          setPage={setPage}
          setOrderBy={(orderBy: string) => {
            setOrderBy(orderBy)
            setPage(0)
          }}
          setSortOrder={(sortOrder: string) => {
            setSortOrder(sortOrder)
            setPage(0)
          }}
          popupMenuOptions={[
            {
              label: 'Add to session',
              visible: (file: GameFile) => !file.session,
              onClick: (file: GameFile) => routeTo(`/files/add-to-session/${file.id}`)
            },
            {
              label: 'Remove from session',
              visible: (file: GameFile) => !!file.session,
              onClick: (file: GameFile) => removeFromSession(file.id)
            },
            {
              label: 'Delete',
              onClick: handleDeleteFile
            }
          ]}
        />
      </Col>
      <Route
        path="/files/add-to-session/:fileId"
        render={({ fileId }: { fileId: string }) => (
          <SelectSession
            onClose={() => goBack('/files')}
            onSelect={(session: Session) => {
              addToSession(fileId, session.id)
              goBack('/files')
            }}
          />
        )}
      />
      <Route
        path="/files/select-session"
        render={() => (
          <SelectSession
            onClose={() => goBack('/files')}
            onSelect={(session) => {
              setSession(session)
              goBack('/files')
            }}
          />
        )}
      />
      <Route
        path="/files/select-uploader"
        render={() => <SelectUploader onClose={() => goBack('/files')} onSelect={setUploader} />}
      />
    </Page>
  )
}
