import { Route, Switch } from '@sodra/prutt'
import { AddBillingAccount } from './AddBillingAccount'
import { BillingAccounts } from './BillingAccounts'
import { EditBillingAccount } from './EditBillingAccount'
import { ManageBillingAccountAccess } from './ManageBillingAccountAccess'
import { ManageBillingAccountGames } from './ManageBillingAccountGames'
import { BillingAccountUsage } from './BillingAccountUsage'
import { AddCreditCard } from './AddCreditCard'

export const Billing = () => {
  return (
    <Switch>
      <Route path="/settings/billing/add-billing-account" component={AddBillingAccount} />
      <Route
        path="/settings/billing/:accountId/manage-access"
        component={ManageBillingAccountAccess}
      />
      <Route
        path="/settings/billing/:accountId/manage-games"
        component={ManageBillingAccountGames}
      />
      <Route path="/settings/billing/:accountId/usage" component={BillingAccountUsage} />
      <Route path="/settings/billing/:accountId/add-credit-card" component={AddCreditCard} />
      <Route path="/settings/billing/:accountId" component={EditBillingAccount} />
      <BillingAccounts />
    </Switch>
  )
}
