import { Fragment, h, JSX } from 'preact'

import { EditNoteIcon, FaceIcon, H2, IconButton, SpacerVertical, StarIcon } from '@sodra/bongo-ui'
import { Block, Grid, InlineBlock, InlineCol } from 'jsxstyle/preact'
import { useEffect, useState } from 'preact/hooks'
import { post } from '../../api'
import dateFormat from 'dateformat'
import { Pagination } from './Pagination'
import { useStore } from '../../store'
import { routeTo } from '@sodra/prutt'

const pageSize = 20

const actionStrings = {
  create: 'created',
  delete: 'deleted',
  update: 'updated',
  restore: 'restored'
}

type Activity = {
  activityId: string
  createdAt: string
  crudAction: 'create' | 'update' | 'delete' | 'restore'
  target: string
  status: any
  user?: { id: string; name: string }
  character?: { id: string; name: string }
  line?: { id: string }
  event?: { id: string; name: string }
  level: number
  diff: {
    diff: {
      value: string
      count: number
      removed?: boolean
      added?: boolean
    }[]
  }
}

type ActivityLineProps = {
  activity: Activity
}

function ActivityLine({ activity }: ActivityLineProps) {
  const { createdAt, crudAction, target, user, character, line, event, diff } = activity

  const { currentGame } = useStore('currentGame')

  return (
    <Fragment>
      <InlineCol gridColumn="1" alignItems="end">
        <InlineBlock gridColumn="2">
          {user && user.name} {actionStrings[crudAction]} {target}
        </InlineBlock>
        <Block fontSize="14px" color="var(--on-surface-lighter)">
          {' '}
          {dateFormat(new Date(createdAt), 'yyyy-mm-dd HH:MM')}
        </Block>
      </InlineCol>
      <InlineBlock gridColumn="3" gap="0.5ch">
        {diff.diff && (
          <Fragment>
            {diff.diff.map((diff) => (
              <InlineBlock
                margin="0 2px"
                textDecoration={diff.removed ? 'line-through' : ''}
                textOverflow="ellipsis"
                color={diff.added ? 'var(--accent)' : diff.removed ? 'var(--warning)' : ''}
              >
                {diff.value}
              </InlineBlock>
            ))}
          </Fragment>
        )}
      </InlineBlock>
      <InlineBlock gridColumn="4">
        <Grid gridTemplateColumns="repeat(3, 40px)">
          <InlineBlock>
            {line && (
              <IconButton
                tooltipText="Go to line"
                icon={EditNoteIcon}
                color="var(--on-surface-light)"
                link={{
                  href: `/line/${line.id}`,
                  onRoute: routeTo
                }}
              />
            )}
          </InlineBlock>
          <InlineBlock>
            {character && (
              <IconButton
                tooltipText="Go to character"
                icon={FaceIcon}
                color="var(--on-surface-light)"
                link={{
                  href: `/character/${character.id}`,
                  onRoute: routeTo
                }}
              />
            )}
          </InlineBlock>
          <InlineBlock>
            {event && (
              <IconButton
                tooltipText="Go to event"
                icon={StarIcon}
                color="var(--on-surface-light)"
                link={{
                  href: `/event/${event.id}`,
                  onRoute: routeTo
                }}
              />
            )}
          </InlineBlock>
        </Grid>
      </InlineBlock>
    </Fragment>
  )
}

type Props = {
  parameters: {
    userId?: string
    characterId?: string
    eventId?: string
    lineId?: string
    gameId: string
    takeId?: string
    logLevel?: number
    limit?: number
    offset?: number
  }
  logLevel?: number
}

export function ActivityLog({ parameters, logLevel = 10 }: Props) {
  const [activity, setActivity] = useState<Activity[] | null>(null)
  const [totalRows, setTotalRows] = useState<number | null>(null)
  const [page, setPage] = useState(0)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    post(`/activity`, {
      ...parameters,
      logLevel,
      offset: page * pageSize,
      limit: pageSize
    })
      .then(({ data: { activity, totalRows } }) => {
        setTotalRows(totalRows)
        setActivity(activity)
      })
      .catch((e) => {
        setError('Failed getting activity log.')
      })
  }, [...Object.values(parameters), page])

  if (!activity || !totalRows) {
    return null
  }

  return (
    <Block>
      <H2 size="5">Activity</H2>

      {error && error}

      <SpacerVertical />
      <Grid gridTemplateColumns="auto auto 1fr" columnGap="10px" rowGap="7px">
        {activity.map((activity) => (
          <ActivityLine activity={activity} />
        ))}
      </Grid>
      <SpacerVertical />
      <Pagination
        numRowsPerPage={pageSize}
        page={page}
        numRows={totalRows}
        onPageChange={(newPage) => setPage(newPage)}
      />
    </Block>
  )
}
