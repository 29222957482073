// Stripe address formats
//
// NOTE: Prepared using the Speechless repository stripe-address-formats
//
export const stripeAddressFormats = [
  {
    countryCode: 'AD',
    name: 'Andorra',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'AE',
    name: 'United Arab Emirates',
    altName: 'UAE',
    usingPostalCode: false,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'AF',
    name: 'Afghanistan',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'AG',
    name: 'Antigua and Barbuda',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'AI',
    name: 'Anguilla',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'AL',
    name: 'Albania',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'AM',
    name: 'Armenia',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'AO',
    name: 'Angola',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'AQ',
    name: 'Antarctica',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'AR',
    name: 'Argentina',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'AS',
    name: 'American Samoa',
    usingPostalCode: true,
    postalCodeLabel: 'ZIP',
    usingState: true,
    stateLabel: 'State',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'AT',
    name: 'Austria',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'AU',
    name: 'Australia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'State',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'AW',
    name: 'Aruba',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'AX',
    name: 'Åland Islands',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'AZ',
    name: 'Azerbaijan',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BA',
    name: 'Bosnia and Herzegovina',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BB',
    name: 'Barbados',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Parish',
    usingCity: true
  },
  {
    countryCode: 'BD',
    name: 'Bangladesh',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BE',
    name: 'Belgium',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'BF',
    name: 'Burkina Faso',
    usingPostalCode: false,
    usingState: true,
    fixedStateName: 'BF',
    usingCity: true
  },
  {
    countryCode: 'BG',
    name: 'Bulgaria',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BH',
    name: 'Bahrain',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BI',
    name: 'Burundi',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BJ',
    name: 'Benin',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BL',
    name: 'Saint Barthélemy',
    usingPostalCode: true,
    usingState: true,
    fixedStateName: 'BL',
    usingCity: true
  },
  {
    countryCode: 'BM',
    name: 'Bermuda',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BN',
    name: 'Brunei Darussalam',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BO',
    name: 'Bolivia',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BQ',
    name: 'Bonaire',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BR',
    name: 'Brazil',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'State',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'BS',
    name: 'Bahamas',
    usingPostalCode: false,
    usingState: true,
    stateLabel: 'Island',
    usingCity: true
  },
  {
    countryCode: 'BT',
    name: 'Bhutan',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BV',
    name: 'Bouvet Island',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BW',
    name: 'Botswana',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'BY',
    name: 'Belarus',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Oblast',
    usingCity: true
  },
  {
    countryCode: 'BZ',
    name: 'Belize',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'CA',
    name: 'Canada',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'CC',
    name: 'Cocos (Keeling) Islands',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'CD',
    name: 'Congo Kinshasa',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'CF',
    name: 'Central African Republic',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'CG',
    name: 'Congo Brazzaville',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'CH',
    name: 'Switzerland',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'CI',
    name: "Côte d'Ivoire",
    usingPostalCode: false,
    usingState: true,
    fixedStateName: 'CI',
    usingCity: true
  },
  {
    countryCode: 'CK',
    name: 'Cook Islands',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'CL',
    name: 'Chile',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'CM',
    name: 'Cameroon',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'CN',
    name: 'China',
    usingPostalCode: false,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'CO',
    name: 'Colombia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Department',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'CR',
    name: 'Costa Rica',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'CU',
    name: 'Cuba',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'CV',
    name: 'Cabo Verde',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Island',
    usingCity: true
  },
  {
    countryCode: 'CW',
    name: 'Curaçao',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'CX',
    name: 'Christmas Island',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'CY',
    name: 'Cyprus',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'CZ',
    name: 'Czechia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'DE',
    name: 'Germany',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'DJ',
    name: 'Djibouti',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'DK',
    name: 'Denmark',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'DM',
    name: 'Dominica',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'DO',
    name: 'Dominican Republic',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'DZ',
    name: 'Algeria',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'EC',
    name: 'Ecuador',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'EE',
    name: 'Estonia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'EG',
    name: 'Egypt',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'EH',
    name: 'Western Sahara',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'ER',
    name: 'Eritrea',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'ES',
    name: 'Spain',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'ET',
    name: 'Ethiopia',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'FI',
    name: 'Finland',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'FJ',
    name: 'Fiji',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'FK',
    name: 'Falkland Islands (Malvinas)',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'FM',
    name: 'Micronesia',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'State',
    usingCity: true
  },
  {
    countryCode: 'FO',
    name: 'Faroe Islands',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'FR',
    name: 'France',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'GA',
    name: 'Gabon',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GB',
    name: 'United Kingdom of Great Britain and Northern Ireland',
    altName: 'Great Britain',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'Town or City'
  },
  {
    countryCode: 'GD',
    name: 'Grenada',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GE',
    name: 'Georgia',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GF',
    name: 'French Guiana',
    usingPostalCode: true,
    usingState: true,
    fixedStateName: 'GF',
    usingCity: true
  },
  {
    countryCode: 'GG',
    name: 'Guernsey',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GH',
    name: 'Ghana',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GI',
    name: 'Gibraltar',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GL',
    name: 'Greenland',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GM',
    name: 'Gambia',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GN',
    name: 'Guinea',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GP',
    name: 'Guadeloupe',
    usingPostalCode: true,
    usingState: true,
    fixedStateName: 'GP',
    usingCity: true
  },
  {
    countryCode: 'GQ',
    name: 'Equatorial Guinea',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GR',
    name: 'Greece',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GT',
    name: 'Guatemala',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GU',
    name: 'Guam',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GW',
    name: 'Guinea-Bissau',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'GY',
    name: 'Guyana',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'HK',
    name: 'Hong Kong',
    usingPostalCode: false,
    usingState: true,
    stateLabel: 'Area',
    usingCity: true,
    cityLabel: 'District'
  },
  {
    countryCode: 'HM',
    name: 'Heard Island and McDonald Islands',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'HN',
    name: 'Honduras',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Department',
    usingCity: true
  },
  {
    countryCode: 'HR',
    name: 'Croatia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'HT',
    name: 'Haiti',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'HU',
    name: 'Hungary',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'ID',
    name: 'Indonesia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'IE',
    name: 'Ireland',
    usingPostalCode: true,
    postalCodeLabel: 'Eircode',
    usingState: true,
    stateLabel: 'County',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'IL',
    name: 'Israel',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'IM',
    name: 'Isle of Man',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'IN',
    name: 'India',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'State',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'IO',
    name: 'British Indian Ocean Territory',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'IQ',
    name: 'Iraq',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'IR',
    name: 'Iran',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'IS',
    name: 'Iceland',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'IT',
    name: 'Italy',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'JE',
    name: 'Jersey',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'JM',
    name: 'Jamaica',
    usingPostalCode: false,
    usingState: true,
    stateLabel: 'Parish',
    usingCity: true
  },
  {
    countryCode: 'JO',
    name: 'Jordan',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'JP',
    name: 'Japan',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Prefecture',
    usingCity: true
  },
  {
    countryCode: 'KE',
    name: 'Kenya',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'KG',
    name: 'Kyrgyzstan',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'KH',
    name: 'Cambodia',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'KI',
    name: 'Kiribati',
    usingPostalCode: false,
    usingState: true,
    stateLabel: 'Island',
    usingCity: true
  },
  {
    countryCode: 'KM',
    name: 'Comoros',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'KN',
    name: 'Saint Kitts and Nevis',
    usingPostalCode: false,
    usingState: true,
    stateLabel: 'Island',
    usingCity: true
  },
  {
    countryCode: 'KP',
    name: 'North Korea',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'KR',
    name: 'South Korea',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'KW',
    name: 'Kuwait',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'KY',
    name: 'Cayman Islands',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Island',
    usingCity: true
  },
  {
    countryCode: 'KZ',
    name: 'Kazakhstan',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'LA',
    name: "Lao People's Democratic Republic",
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'LB',
    name: 'Lebanon',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'LC',
    name: 'Saint Lucia',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'LI',
    name: 'Liechtenstein',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'LK',
    name: 'Sri Lanka',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'LR',
    name: 'Liberia',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'LS',
    name: 'Lesotho',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'LT',
    name: 'Lithuania',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'LU',
    name: 'Luxembourg',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'LV',
    name: 'Latvia',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'LY',
    name: 'Libya',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'MA',
    name: 'Morocco',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'MC',
    name: 'Monaco',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    fixedStateName: 'MC',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'MD',
    name: 'Moldova',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'ME',
    name: 'Montenegro',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'MF',
    name: 'Saint Martin',
    usingPostalCode: true,
    usingState: true,
    fixedStateName: 'MF',
    usingCity: true
  },
  {
    countryCode: 'MG',
    name: 'Madagascar',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'MH',
    name: 'Marshall Islands',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Island',
    usingCity: true
  },
  {
    countryCode: 'MK',
    name: 'North Macedonia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'ML',
    name: 'Mali',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'MM',
    name: 'Myanmar',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'MN',
    name: 'Mongolia',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'MO',
    name: 'Macao',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'MP',
    name: 'Northern Mariana Islands',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'State',
    usingCity: true
  },
  {
    countryCode: 'MQ',
    name: 'Martinique',
    usingPostalCode: true,
    usingState: true,
    fixedStateName: 'MQ',
    usingCity: true
  },
  {
    countryCode: 'MR',
    name: 'Mauritania',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'MS',
    name: 'Montserrat',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'MT',
    name: 'Malta',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'MU',
    name: 'Mauritius',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'MV',
    name: 'Maldives',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'MW',
    name: 'Malawi',
    usingPostalCode: true,
    usingState: true,
    fixedStateName: 'MW',
    usingCity: true
  },
  {
    countryCode: 'MX',
    name: 'Mexico',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'State',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'MY',
    name: 'Malaysia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'State',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'MZ',
    name: 'Mozambique',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'NA',
    name: 'Namibia',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'NC',
    name: 'New Caledonia',
    usingPostalCode: true,
    usingState: true,
    fixedStateName: 'NC',
    usingCity: true
  },
  {
    countryCode: 'NE',
    name: 'Niger',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'NF',
    name: 'Norfolk Island',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'NG',
    name: 'Nigeria',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'NI',
    name: 'Nicaragua',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Department',
    usingCity: true
  },
  {
    countryCode: 'NL',
    name: 'Netherlands',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'NO',
    name: 'Norway',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'Town or City'
  },
  {
    countryCode: 'NP',
    name: 'Nepal',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'NR',
    name: 'Nauru',
    usingPostalCode: false,
    usingState: true,
    stateLabel: 'District',
    usingCity: true
  },
  {
    countryCode: 'NU',
    name: 'Niue',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'NZ',
    name: 'New Zealand',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'OM',
    name: 'Oman',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'PA',
    name: 'Panama',
    usingPostalCode: false,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'PE',
    name: 'Peru',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'District'
  },
  {
    countryCode: 'PF',
    name: 'French Polynesia',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Island',
    usingCity: true
  },
  {
    countryCode: 'PG',
    name: 'Papua New Guinea',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'PH',
    name: 'Philippines',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'PK',
    name: 'Pakistan',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'PL',
    name: 'Poland',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'PM',
    name: 'Saint Pierre and Miquelon',
    usingPostalCode: true,
    usingState: true,
    fixedStateName: 'PM',
    usingCity: true
  },
  {
    countryCode: 'PN',
    name: 'Pitcairn',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'PR',
    name: 'Puerto Rico',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'PS',
    name: 'Palestinian Territories',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'PT',
    name: 'Portugal',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'PW',
    name: 'Palau',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'State',
    usingCity: true
  },
  {
    countryCode: 'PY',
    name: 'Paraguay',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'QA',
    name: 'Qatar',
    usingPostalCode: false,
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'RE',
    name: 'Réunion',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'RO',
    name: 'Romania',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'RS',
    name: 'Serbia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'RU',
    name: 'Russian Federation',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Oblast',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'RW',
    name: 'Rwanda',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'SA',
    name: 'Saudi Arabia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'SB',
    name: 'Solomon Islands',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'SC',
    name: 'Seychelles',
    usingPostalCode: false,
    usingState: true,
    stateLabel: 'Island',
    usingCity: true
  },
  {
    countryCode: 'SD',
    name: 'Sudan',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'District',
    usingCity: true
  },
  {
    countryCode: 'SE',
    name: 'Sweden',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'Town or City'
  },
  {
    countryCode: 'SG',
    name: 'Singapore',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: false
  },
  {
    countryCode: 'SH',
    name: 'Saint Helena',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'SI',
    name: 'Slovenia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'SJ',
    name: 'Svalbard and Jan Mayen',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'SK',
    name: 'Slovakia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'SL',
    name: 'Sierra Leone',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'SM',
    name: 'San Marino',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'SN',
    name: 'Senegal',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'SO',
    name: 'Somalia',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'SR',
    name: 'Suriname',
    usingPostalCode: false,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'SS',
    name: 'South Sudan',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'ST',
    name: 'Sao Tome and Principe',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'SV',
    name: 'El Salvador',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'SX',
    name: 'Sint Maarten',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'SY',
    name: 'Syrian Arab Republic',
    altName: 'Syria',
    usingPostalCode: false,
    usingState: false,
    usingCity: true,
    cityLabel: 'District'
  },
  {
    countryCode: 'SZ',
    name: 'Eswatini',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'TC',
    name: 'Turks and Caicos Islands',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'TD',
    name: 'Chad',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'TF',
    name: 'French Southern Territories',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'TG',
    name: 'Togo',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'TH',
    name: 'Thailand',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'TJ',
    name: 'Tajikistan',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'TK',
    name: 'Tokelau',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'TL',
    name: 'Timor-Leste',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'TM',
    name: 'Turkmenistan',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'TN',
    name: 'Tunisia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'TO',
    name: 'Tonga',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'TR',
    name: 'Turkey',
    altName: 'Türkiye',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'TT',
    name: 'Trinidad and Tobago',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'TV',
    name: 'Tuvalu',
    usingPostalCode: false,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'TW',
    name: 'Taiwan',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'TZ',
    name: 'Tanzania',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'UA',
    name: 'Ukraine',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Oblast',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'UG',
    name: 'Uganda',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'UM',
    name: 'United States Minor Outlying Islands',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Island',
    usingCity: true
  },
  {
    countryCode: 'US',
    name: 'United States of America',
    altName: 'USA',
    usingPostalCode: true,
    postalCodeLabel: 'ZIP',
    usingState: true,
    stateLabel: 'State',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'UY',
    name: 'Uruguay',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'Province',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'UZ',
    name: 'Uzbekistan',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'Province',
    usingCity: true
  },
  {
    countryCode: 'VA',
    name: 'Vatican City',
    altName: 'Holy See',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'VC',
    name: 'Saint Vincent and the Grenadines',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'VE',
    name: 'Venezuela',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'State',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'VG',
    name: 'Virgin Islands (British)',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'VI',
    name: 'Virgin Islands (US)',
    usingPostalCode: true,
    usingState: true,
    stateLabel: 'State',
    usingCity: true
  },
  {
    countryCode: 'VN',
    name: 'Viet Nam',
    altName: 'Vietnam',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: true,
    stateLabel: 'State',
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'VU',
    name: 'Vanuatu',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'WF',
    name: 'Wallis and Futuna',
    usingPostalCode: true,
    usingState: true,
    fixedStateName: 'WF',
    usingCity: true
  },
  {
    countryCode: 'WS',
    name: 'Samoa',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'YE',
    name: 'Yemen',
    usingPostalCode: false,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'YT',
    name: 'Mayotte',
    usingPostalCode: true,
    usingState: false,
    usingCity: true
  },
  {
    countryCode: 'ZA',
    name: 'South Africa',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'ZM',
    name: 'Zambia',
    usingPostalCode: true,
    postalCodeLabel: 'Postal code',
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  },
  {
    countryCode: 'ZW',
    name: 'Zimbabwe',
    usingPostalCode: false,
    usingState: false,
    usingCity: true,
    cityLabel: 'City'
  }
]

export const getStripeAddressFormat = (countryCode: string) => {
  return stripeAddressFormats.find((item) => item.countryCode === countryCode)
}
