import { h } from 'preact'
import { useState } from 'preact/hooks'
import { List, ListItem, PlusIcon, SpacerVertical } from '@sodra/bongo-ui'

import { useFetchResult } from '../use-fetch-result'

import Avatar from './Avatar'

import LargeDialog from './LargeDialog'
import SearchTextField from './SearchTextField'
import { routeTo } from '@sodra/prutt'
import { Character } from '../types'
import { useStore } from '../store'

type Props = {
  onClose: () => void
  onSelect: (character: Character) => void
}

export const SelectCharacter = ({ onClose, onSelect }: Props) => {
  const { currentGame } = useStore('currentGame')
  const [query, setQuery] = useState('')

  const { data: characters } = useFetchResult<Character[]>(`/games/${currentGame!.id}/characters`, {
    query
  })

  return (
    <LargeDialog
      large
      title="Select character"
      onClose={onClose}
      action1Text="Cancel"
      onAction1Click={onClose}
      secondaryActionText={'New character'}
      secondaryActionIcon={PlusIcon}
      onSecondaryActionClick={() => routeTo('/create-character')}
    >
      <SearchTextField value={query} onChange={setQuery} />
      <SpacerVertical />
      {characters && (
        <List>
          {characters.map((character) => {
            return (
              <ListItem
                visual={<Avatar size={30} src={character.picture} name={character.name} />}
                text={character.name}
                onClick={() => {
                  onSelect(character)
                  onClose()
                }}
              />
            )
          })}
        </List>
      )}
      <SpacerVertical />
    </LargeDialog>
  )
}

export default SelectCharacter
