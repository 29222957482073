import { Fragment, h } from 'preact'
import { useState } from 'preact/hooks'
import { Button, Checkbox, Form, H1, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { Block, Col, Row } from 'jsxstyle/preact'

import { login, loginWithSSO } from '../actions'
import LandingPage from './LandingPage'
import { routeTo } from '@sodra/prutt'
import { deleteSearchParam } from '../delete-search-param'
import { useStore } from '../store'

const NotLoggedIn = () => {
  const { isLoggingIn } = useStore('isLoggingIn')
  const searchParams = new URLSearchParams(location.search)

  const [mode, setMode] = useState('password')

  const [email, setEmail] = useState(searchParams.get('email') || '')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const handleSubmit = async () => {
    let error = false

    if (mode === 'sso') {
      if (!email || email.trim() === '') {
        setEmailError('Please enter domain or email')
        error = true
      } else {
        setEmailError('')
      }
      const name = await loginWithSSO(email)
      if (name) {
        location.href = `${import.meta.env.VITE_SSO_URL}/${name}?relayState=${
          location.pathname + location.search
        }`
      }
    } else {
      if (!email || email.trim() === '') {
        setEmailError('Please enter email')
        error = true
      } else {
        setEmailError('')
      }
      if (!password || password.trim() === '') {
        setPasswordError('Please enter password')
        error = true
      } else {
        setPasswordError('')
      }
      if (error) return

      if (await login({ email, password })) {
        deleteSearchParam('email')
      }
    }
  }

  return (
    <LandingPage signUpLink={true}>
      <H1 size="6">Login{mode === 'sso' ? ' with SSO' : ''}</H1>
      <SpacerVertical />
      <Form onSubmit={handleSubmit}>
        <Col alignItems="center">
          {mode === 'password' && (
            <Fragment>
              <TextField
                width="100%"
                type="email"
                label="Email"
                value={email}
                onInput={setEmail}
                onChange={setEmail}
                autocomplete="username"
                errorText={emailError}
              />
              <SpacerVertical />
              <TextField
                width="100%"
                type={!showPassword ? 'password' : 'text'}
                label="Password"
                value={password}
                onInput={setPassword}
                onChange={setPassword}
                autocomplete="current-password"
                errorText={passwordError}
              />
              <SpacerVertical small />
              <Checkbox
                label="Show password"
                checked={showPassword}
                onChange={setShowPassword}
                width="100%"
              />
            </Fragment>
          )}
          {mode === 'sso' && (
            <Fragment>
              <TextField
                width="100%"
                label="Domain or email"
                value={email}
                onInput={setEmail}
                onChange={setEmail}
              />
              <SpacerVertical small />
            </Fragment>
          )}
          <SpacerVertical small />
          <Row alignItems="center" width="100%">
            <Button contained type="submit" loading={isLoggingIn}>
              Login
            </Button>
            <Block flex="1" />
            {mode === 'password' && (
              <Fragment>
                <Button
                  link={{
                    href: '/forgot-password',
                    onRoute: routeTo
                  }}
                >
                  Forgot password
                </Button>
                <Button onClick={() => setMode('sso')}>SSO</Button>
              </Fragment>
            )}
            {mode === 'sso' && <Button onClick={() => setMode('password')}>Back</Button>}
          </Row>
        </Col>
      </Form>
    </LandingPage>
  )
}

export default NotLoggedIn
