import { h } from 'preact'
import { AiQuota, BillingPlan } from '../../types'
import { Block } from 'jsxstyle/preact'
import { H2, SpacerHorizontal, SpacerVertical } from '@sodra/bongo-ui'
import { formatHumanReadableDuration } from 'lib'

type BillingAccount = {
  id: string
  name: string
  plan: BillingPlan
}

export const ResourcesUsage = (params: {
  aiQuota: AiQuota
  billingAccount?: BillingAccount
  infoText?: string
}) => {
  const { aiQuota, billingAccount, infoText } = params

  const roundToDecimals = (num: number, decimals: number) => {
    return Math.round((num + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals)
  }

  const formatPercentage = (used: number, limit: number): string => {
    const usagePercent = roundToDecimals(limit ? (used / limit) * 100 : 100, 1)
    return `${Math.min(usagePercent, 100)}%`
  }

  return (
    <Block>
      <SpacerVertical />
      {aiQuota.type === 'basic' && (
        <>
          <h3>
            {billingAccount && billingAccount.plan !== 'starter' ? 'Extra' : ''} AI voices quota
          </h3>
          {infoText && (
            <>
              <Block color="var(--on-surface-light)">{infoText}</Block>
              <SpacerVertical />
            </>
          )}
          <table cellSpacing="5" style={{ textAlign: 'left' }}>
            <tr>
              <th>Total:</th>
              <td>
                <SpacerHorizontal />
              </td>
              <td>{formatHumanReadableDuration(aiQuota.totalSeconds * 1000)}</td>
            </tr>
            <tr>
              <th>Remaining:</th>
              <td>
                <SpacerHorizontal />
              </td>
              <td>{formatHumanReadableDuration(Math.round(aiQuota.remainingSeconds) * 1000)}</td>
            </tr>
            <tr>
              <th>Used:</th>
              <td>
                <SpacerHorizontal />
              </td>
              <td>
                {formatHumanReadableDuration(
                  (aiQuota.totalSeconds - Math.round(aiQuota.remainingSeconds)) * 1000
                )}
              </td>
            </tr>
            <tr>
              <th>Used %:</th>
              <td>
                <SpacerHorizontal />
              </td>
              <td>
                {formatPercentage(
                  aiQuota.totalSeconds - Math.round(aiQuota.remainingSeconds),
                  aiQuota.totalSeconds
                )}
              </td>
            </tr>
            {billingAccount && (
              <tr>
                <th>Billing account:</th>
                <td>
                  <SpacerHorizontal />
                </td>
                <td>{billingAccount.name}</td>
              </tr>
            )}
          </table>
        </>
      )}
      {aiQuota.type === 'subscription' && (
        <>
          <h3>Subscription AI voices quota</h3>
          {infoText && (
            <>
              <Block color="var(--on-surface-light)">{infoText}</Block>
              <SpacerVertical />
            </>
          )}
          <table cellSpacing="5" style={{ textAlign: 'left' }}>
            <tr>
              <th>Monthly limit:</th>
              <td>
                <SpacerHorizontal />
              </td>
              <td>{formatHumanReadableDuration(aiQuota.totalSeconds * 1000)}</td>
            </tr>
            <tr>
              <th>Remaining this month:</th>
              <td>
                <SpacerHorizontal />
              </td>
              <td>{formatHumanReadableDuration(Math.round(aiQuota.remainingSeconds) * 1000)}</td>
            </tr>
            <tr>
              <th>Used this month:</th>
              <td>
                <SpacerHorizontal />
              </td>
              <td>
                {formatHumanReadableDuration(
                  (aiQuota.totalSeconds - Math.round(aiQuota.remainingSeconds)) * 1000
                )}
              </td>
            </tr>
            <tr>
              <th>Used %:</th>
              <td>
                <SpacerHorizontal />
              </td>
              <td>
                {formatPercentage(
                  aiQuota.totalSeconds - Math.round(aiQuota.remainingSeconds),
                  aiQuota.totalSeconds
                )}
              </td>
            </tr>
            {billingAccount && (
              <tr>
                <th>Billing account:</th>
                <td>
                  <SpacerHorizontal />
                </td>
                <td>{billingAccount.name}</td>
              </tr>
            )}
          </table>
        </>
      )}
    </Block>
  )
}
