import { Dialog, Form, SpacerHorizontal, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { AiQuota, BillingAccount } from '../types'
import { useEffect, useState } from 'preact/hooks'
import { Block, Box, Row } from 'jsxstyle/preact'
import { h } from 'preact'
import { confirm, formatCurrency, formatCurrencyNoDecimals, pluralize } from 'lib'
import { useStore } from '../store'
import { post } from '../api'
import { setError } from '../actions'

type ExtraAiQuotaCostProps = {
  hours: number
  hourPrice: number
  vatPercent?: number
  vat?: number
  total: number
  totalIncVat?: number
}

export const ExtraAiQuotaCost = ({
  hours,
  hourPrice,
  vatPercent,
  total,
  vat,
  totalIncVat
}: ExtraAiQuotaCostProps) => {
  const description = `${hours} ${pluralize('hour', hours)} × ${formatCurrencyNoDecimals(
    hourPrice
  )}`

  return (
    <>
      <Block color="var(--on-surface-light)">{description}</Block>
      <SpacerVertical />

      <table cellPadding="0" cellSpacing="0" style="min-width: 200px">
        <Box component="tr" height="1.75rem">
          <Box component="td">Hours:</Box>
          <Box component="td" textAlign="right">
            {total ? formatCurrency(total) : '–'}
          </Box>
        </Box>

        <Box component="tr" height="1.75rem">
          <Box component="td" borderTop="1px solid var(--on-surface-light)">
            <strong>{vatPercent ? 'Total excl. VAT' : 'Total'}</strong>
          </Box>
          <Box component="td" textAlign="right" borderTop="1px solid var(--on-surface-light)">
            <strong>{total ? formatCurrency(total) : '–'}</strong>
          </Box>
        </Box>
        <Box component="tr" height="1.75rem">
          <Box component="td">VAT</Box>
          <Box component="td" textAlign="right">
            {vat ? formatCurrency(vat) : '–'}
          </Box>
        </Box>
        {vatPercent && (
          <Box component="tr" height="1.75rem">
            <Box component="td">Total incl. VAT</Box>
            <Box component="td" textAlign="right">
              {totalIncVat ? formatCurrency(totalIncVat) : '–'}
            </Box>
          </Box>
        )}
      </table>
    </>
  )
}

type BuyExtraAiQuotaTimeDialogPropsProps = {
  aiQuota: AiQuota
  billingAccount: BillingAccount
  onSuccess: (aiQuota: AiQuota, hours: number) => void
  onClose: () => void
}

export const BuyExtraAiQuotaTimeDialog = ({
  aiQuota,
  billingAccount,
  onSuccess,
  onClose
}: BuyExtraAiQuotaTimeDialogPropsProps) => {
  const { currentGame } = useStore('currentGame')

  const [hours, setHours] = useState(1)
  const [hoursError, setHoursError] = useState('')
  const [isBuyingAiQuotaTime, setIsBuyingAiQuotaTime] = useState(false)

  useEffect(() => {
    if (hours && Number.isInteger(hours)) {
      setHoursError('')
    }
  }, [hours])

  const handleClose = () => {
    onClose()
  }

  const buyExtraAiQuota = async (aiQuota: AiQuota, hours: number): Promise<boolean> => {
    // Call api to make purchase of AI time
    if (currentGame?.billingAccount) {
      const { data: updatedAiQuota } = await post(
        `/billing-accounts/${currentGame.billingAccount.id}/buy-ai-quota`,
        { aiQuotaId: aiQuota.quotaId, gameId: currentGame.id, hours }
      )
      return true
    } else {
      console.error('No billing account to buy extra AI hours')
      return false
    }
  }

  const handleBuyTime = async () => {
    setHoursError('')

    if (!hours || !Number.isInteger(hours)) {
      setHoursError('Please enter number of hours')
      return
    }

    setIsBuyingAiQuotaTime(true)

    try {
      if (
        await confirm({
          title: `Please confirm your purchase`,
          message: (
            <>
              <Block>
                Are you sure you want to buy {hours} additional {pluralize('hour', hours)} of AI
                text-to-speech?
              </Block>
              <SpacerVertical />
              <Block paddingLeft="10px">
                <table cellPadding="0" cellSpacing="5" style={{ textAlign: 'left' }}>
                  <tr>
                    <td>Total:</td>
                    <td>
                      <SpacerHorizontal />
                    </td>
                    <td>{formatCurrency(totalIncVat)}</td>
                  </tr>
                  <tr>
                    <td>Billing account:</td>
                    <td>
                      <SpacerHorizontal />
                    </td>
                    <td>{billingAccount.name}</td>
                  </tr>
                  <tr>
                    <td>Payment type:</td>
                    <td>
                      <SpacerHorizontal />
                    </td>
                    <td>{billingAccount.invoice ? 'Invoice' : 'Credit card'}</td>
                  </tr>
                  {!billingAccount.invoice && (
                    <tr>
                      <td>Card number:</td>
                      <td>
                        <SpacerHorizontal />
                      </td>
                      <td>
                        {billingAccount.creditCard
                          ? `**** **** **** ${billingAccount.creditCard.last4}`
                          : '-'}
                      </td>
                    </tr>
                  )}
                </table>
              </Block>
              <SpacerVertical large />
            </>
          ),
          confirmText: 'Confirm',
          rejectText: 'Cancel'
        })
      ) {
        const success = await buyExtraAiQuota(aiQuota, hours)
        onClose()
        if (success) {
          if (onSuccess) {
            onSuccess(aiQuota, hours)
          }
          await confirm({
            title: 'Thanks for your purchase!',
            message: `${hours} ${pluralize('hour', hours)} ${
              hours > 1 ? 'have' : 'has'
            } been added to your AI text-to-speech quota.`,
            rejectText: null
          })
        }
      }
    } catch (err: any) {
      setError(err)
    } finally {
      setIsBuyingAiQuotaTime(false)
    }
  }

  // TODO: Adjust hour price for custom plans
  const hourPrice = billingAccount.plan === 'starter' ? 29 : 24
  const total = hours * hourPrice
  const vatPercent = billingAccount?.address.country === 'Sweden' ? 0.25 : undefined
  const vat = vatPercent ? total * vatPercent : 0
  const totalIncVat = total + vat

  return (
    <Dialog
      dismissable={false}
      title="Buy additional AI text-to-speech hours"
      disableAutofocus
      actions={[
        {
          text: hours ? `Buy ${hours} ${pluralize('hour', hours)}` : 'Buy hours',
          contained: true,
          disabled: !hours,
          loading: isBuyingAiQuotaTime,
          onClick: handleBuyTime
        },
        {
          text: 'Cancel',
          onClick: handleClose,
          disabled: isBuyingAiQuotaTime
        }
      ]}
    >
      <Row>
        <Block>
          <Form onSubmit={handleBuyTime}>
            <Row gap="40px">
              <Block maxWidth="150px">
                <TextField
                  type="number"
                  min="1"
                  autocomplete="off"
                  label="Number of hours"
                  value={hours}
                  onInput={(value: string) =>
                    setHours(value.trim() ? Math.max(0, Math.floor(parseFloat(value))) : 0)
                  }
                  errorText={hoursError}
                  maxWidth="140px"
                  props={{ id: 'duration' }}
                />
              </Block>
            </Row>
          </Form>
        </Block>
        <SpacerHorizontal />
        <Block border="1px solid var(--container-outline)" padding="20px">
          <ExtraAiQuotaCost
            hours={hours}
            hourPrice={hourPrice}
            total={total}
            vat={vat}
            vatPercent={vatPercent}
            totalIncVat={totalIncVat}
          />
        </Block>
      </Row>
      <SpacerVertical />
      <Block fontSize="14px" fontStyle="italic">
        {billingAccount.plan === 'starter' && (
          <Block color="var(--on-surface-light)">
            If you would like to switch to a plan with monthly AI text-to-speech hours included,
            please contact our sales team at support@speechless.games.
          </Block>
        )}
        {billingAccount.plan !== 'starter' && (
          <Block color="var(--on-surface-light)">
            If you would like to upgrade your monthly AI text-to-speech quota, please contact our
            sales team at support@speechless.games.
          </Block>
        )}
      </Block>
      <SpacerVertical />
    </Dialog>
  )
}
