import { useSignal } from '@preact/signals'
import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { createCanvas } from '../../lib/create-canvas'
import { useCanvas } from '../../lib/useCanvas'
import { ColorName, colors } from '../LineRecorder/colors'
import { drawUsingClipPath } from './draw'
import { drawJaggedChunkedWaveform } from './drawWaveForm'

type CreateWaveFormImageParams = {
  width: number
  height: number
  audioBuffer: AudioBuffer
  color: string
  pos?: number
}

function createWaveformImage({
  width,
  height,
  audioBuffer,
  color,
  pos = 0.5
}: CreateWaveFormImageParams) {
  const offlineCanvas = createCanvas(width, height)
  const offlineCtx = offlineCanvas.getContext('2d')
  if (!offlineCtx) return null
  drawJaggedChunkedWaveform({
    ctx: offlineCtx as CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    audioBuffer,
    color,
    zoom: 1,
    position: pos,
    x: 0,
    y: 0,
    width,
    height
  })

  return offlineCanvas
}

type ZoomProps = {
  factor: number
  minFactor: number
  maxFactor: number
  zoomedCanvasHeight: number
  minCanvasHeight: number
  maxCanvasHeight: number
}

type Props = {
  audioBuffer?: AudioBuffer
  height?: string
  pos?: number
  setPos?: (zoom: number) => void
  start?: number
  end?: number
  currentTime?: number
  color?: ColorName
  progressColor?: ColorName
  selectedBgColor?: ColorName
  progressBgColor?: ColorName
  padding?: number
  zoom?: ZoomProps
  disableRedraw?: boolean
}

export function AudioBufferVisualizer({
  audioBuffer,
  height = '100px',
  zoom,
  pos = 0.5,
  start: startTime = 0,
  end: endTime = Infinity,
  currentTime = 0,
  color = '--on-surface',
  progressColor = '--accent',
  selectedBgColor,
  progressBgColor,
  padding = 0,
  disableRedraw
}: Props) {
  const canvasElem = useRef<HTMLCanvasElement | null>(null)

  const audioBufferUri = useSignal<AudioBuffer | null>(null)
  const waveformImage = useSignal<OffscreenCanvas | HTMLCanvasElement | null>(null)

  const [zoomedHeight, setZoomedHeight] = useState(0)

  const buffer = audioBufferUri.value ?? audioBuffer

  const { tick } = useCanvas(
    canvasElem,
    (ctx, width, height) => {
      if (disableRedraw) {
        return
      }
      ctx.clearRect(0, 0, width, height)
      if (buffer && waveformImage.value) {
        drawUsingClipPath({
          ctx,
          waveformImage: waveformImage.value,
          currentTime,
          startTime,
          endTime,
          duration: buffer.duration,
          pos,
          zoom: 1,
          color: colors[progressColor],
          selectedBgColor: selectedBgColor ? colors[selectedBgColor] : undefined,
          progressBgColor: progressBgColor ? colors[progressBgColor] : undefined
        })
      }
    },
    (width, height) => {
      if (buffer) {
        waveformImage.value = createWaveformImage({
          width,
          height,
          audioBuffer: buffer,
          color: colors[color],
          pos
        })
      }
    }
  )

  useEffect(() => {
    tick()
  }, [waveformImage.value])

  useEffect(() => {
    if (!canvasElem.current || !buffer) return
    const { width, height } = canvasElem.current

    waveformImage.value = createWaveformImage({
      width,
      height,
      audioBuffer: buffer,
      color: colors[color],
      pos
    })
  }, [pos])

  return (
    <canvas
      ref={canvasElem}
      style={{
        position: 'relative',
        top: zoom ? (zoom.maxCanvasHeight - zoom.zoomedCanvasHeight) / 2 : undefined,
        width: `${zoom ? 100 * zoom.factor : 100}%`,
        padding: `0 ${padding}px`,
        height: zoom?.zoomedCanvasHeight ? `${zoom.zoomedCanvasHeight}px` : height
      }}
    />
  )
}
