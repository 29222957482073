import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import {
  Button,
  DeleteIcon,
  EditIcon,
  Form,
  NativeSelect,
  PlayIcon,
  SpacerHorizontal,
  SpacerVertical,
  StopIcon,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'

import { showSnackbar, updateAIVoice } from '../../actions'
import { buildSpeakerUri } from '../../speak'

import Avatar from '../Avatar'
import Page from '../Page'
import { useStore } from '../../store'
import { useAudioPlayer } from 'lib/src/hooks/use-audio-player'

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const EditPlayHTDetails = () => {
  const { aiVoice, isUpdatingAIVoice } = useStore('aiVoice', 'isUpdatingAIVoice', 'isNarrow')

  if (!aiVoice) {
    return null
  }

  const { player } = useAudioPlayer()
  if (!player) {
    return null
  }
  const { initPlayer, play, playerState, resetPlayer, stopPlayer } = player

  const [name, setName] = useState(aiVoice.name)
  const [description, setDescription] = useState(aiVoice.description)
  const [sampleText, setSampleText] = useState(aiVoice.sampleText)
  const [emotion, setEmotion] = useState('')

  const onBack = () => goBack('/ai-voices')

  const handleSave = async () => {
    const params = {
      name,
      description,
      sampleText
    }

    if (await updateAIVoice(params)) {
      showSnackbar('AI voice details saved')
      onBack()
    }
  }

  const uri = buildSpeakerUri({
    aiVoiceId: aiVoice.id,
    text: sampleText || `My name is ${name}. ${description}`,
    extraParams: {
      style: emotion
    }
  })

  return (
    <Page
      title={aiVoice.name}
      actions={[
        {
          label: 'Delete',
          icon: DeleteIcon,
          onClick: () => routeTo(`/ai-voice/${aiVoice.id}/delete`),
          visible: !aiVoice.shared
        }
      ]}
      onBack={onBack}
    >
      <Block maxWidth="500px">
        <Avatar
          actionIcon={aiVoice.shared ? undefined : EditIcon}
          onActionClick={
            aiVoice.shared ? undefined : () => routeTo(`/ai-voice/${aiVoice.id}/picture`)
          }
          size={150}
          src={aiVoice.picture}
          name={name}
        />
        <SpacerVertical />
        <Form onSubmit={handleSave} maxWidth="500px">
          <TextField readonly width="100%" label="Name" value={name} onInput={setName} />
          <SpacerVertical />
          <TextArea
            readonly
            autoHeight
            minRows={1}
            maxRows={5}
            width="100%"
            label="Description"
            value={description}
            onInput={setDescription}
          />
          <SpacerVertical />
          <TextArea
            width="100%"
            autoHeight
            minRows={3}
            maxRows={5}
            label="Sample text"
            value={sampleText}
            onInput={setSampleText}
          />
          {aiVoice.emotions.length > 0 && (
            <>
              <SpacerVertical />
              <NativeSelect
                width="100%"
                label="Emotion"
                value={emotion}
                onChange={setEmotion}
                options={[
                  { value: '', label: 'Neutral' },
                  ...aiVoice.emotions.map((emotion) => ({
                    value: emotion,
                    label: capitalize(emotion)
                  }))
                ]}
              />
            </>
          )}
          <SpacerVertical />
          <Row alignItems="center">
            {!aiVoice.shared && (
              <>
                <Button
                  type="submit"
                  contained
                  loading={isUpdatingAIVoice}
                  disabled={aiVoice.shared}
                >
                  Save
                </Button>
                <SpacerHorizontal />
                <Button onClick={onBack}>Cancel</Button>
              </>
            )}
            <Block flex="1" />
            <Button
              type="button"
              role="button"
              icon={playerState.value.isPlaying ? StopIcon : PlayIcon}
              loading={playerState.value.isPlaying && playerState.value.loading}
              onClick={() => {
                if (playerState.value.isPlaying) {
                  stopPlayer()
                  resetPlayer()
                } else {
                  initPlayer({
                    audioTracks: [{ name, uri }]
                  })
                  play()
                }
              }}
            >
              Play sample
            </Button>
          </Row>
        </Form>
      </Block>
    </Page>
  )
}

export default EditPlayHTDetails
