import { ComponentChildren, Fragment, h, JSX } from 'preact'

import { Block, Col, Row } from 'jsxstyle/preact'
import { BackArrowIcon, Button, IconButton, MoreIcon, SpacerHorizontal } from '@sodra/bongo-ui'
import { useState } from 'preact/hooks'

import { useResizeObserver } from '../use-resize-observer.js'
import { usePopupMenu } from './use-popup-menu'
import { PageAction, PageActionType } from './PageAction'
import { PopupMenuOption } from './PopupMenu'
import { routeTo } from '@sodra/prutt'

const getTextWidth = (txt: string, font: string) => {
  const element = document.createElement('canvas')
  const context = element.getContext('2d')
  if (!context) return 0
  context.font = font
  return Math.ceil(context.measureText(txt).width)
}

type PageTab = {
  label: string
  href: string
}

type Props = {
  title: string | JSX.Element
  onBack?: () => void
  children?: ComponentChildren
  actions?: PageActionType[]
  moreActions?: PageActionType[]
  tabs?: PageTab[]
  primaryActionText?: string
  primaryActionIcon?: any
  primaryActionLoading?: boolean
  primaryActionDisabled?: boolean
  onPrimaryActionClick?: () => void
  secondaryActionText?: string
  secondaryActionIcon?: any
  secondaryActionLoading?: boolean
  secondaryActionDisabled?: boolean
  onSecondaryActionClick?: () => void
  footer?: JSX.Element
}

const Page = ({
  title,
  onBack,
  children,
  actions = [],
  moreActions = [],
  tabs = [],
  primaryActionText,
  primaryActionIcon,
  primaryActionLoading,
  primaryActionDisabled,
  onPrimaryActionClick,
  secondaryActionText,
  secondaryActionIcon,
  secondaryActionLoading,
  secondaryActionDisabled,
  onSecondaryActionClick,
  footer
}: Props) => {
  const [maxActions, setMaxActions] = useState<number>(0)

  const visibleActions = actions.filter((action) => action.visible !== false)

  const refCallback = useResizeObserver(
    ({ width: maxWidth }: { width: number }) => {
      let width = 0
      let maxActions = 0
      for (let i = 0; i < visibleActions.length; i++) {
        const label = visibleActions[i].label
        const textWidth = getTextWidth(label, '16px Arial')
        const actionWidth = textWidth + 60
        if (width + actionWidth >= maxWidth) {
          break
        }
        width += actionWidth
        maxActions += 1
      }
      setMaxActions(maxActions)
    },
    [visibleActions]
  )

  const extraActions = visibleActions.slice(maxActions).map((action) => {
    return action as PopupMenuOption
  })
  const { PopupMenu, showMenu } = usePopupMenu({
    options: [...extraActions, ...(moreActions as PopupMenuOption[])]
  })

  return (
    <Col width="100%" height="100%" class="Page">
      <Row
        height="60px"
        alignItems="center"
        padding="0 20px"
        borderBottom="solid 1px var(--container-outline-lighter)"
      >
        {onBack && (
          <Fragment>
            <IconButton icon={BackArrowIcon} onClick={onBack} color="var(--on-surface)" />
            <SpacerHorizontal />
          </Fragment>
        )}
        <Block fontSize="20px">{title}</Block>
        <SpacerHorizontal large />
        <Row flex="1" whiteSpace="nowrap" overflow="hidden" ref={refCallback} gap="10px">
          {maxActions >= 0 &&
            visibleActions
              .slice(0, maxActions)
              .filter((action) => action.visible !== false)
              .map((action) => <PageAction action={action} />)}
        </Row>
        {(moreActions?.length || (maxActions >= 0 && maxActions < visibleActions.length)) && (
          <IconButton flexShrink="0" icon={MoreIcon} onClick={showMenu} color="var(--on-surface)" />
        )}
      </Row>
      {tabs.length > 0 && (
        <Row
          height="60px"
          alignItems="center"
          padding="0 20px"
          gap="20px"
          borderBottom="solid 1px var(--container-outline-lighter)"
        >
          {tabs.map((tab) => {
            const isActive = location.pathname === tab.href
            return (
              <Block
                cursor="pointer"
                borderBottom={isActive ? 'solid 2px var(--accent)' : ''}
                props={{ onClick: () => routeTo(tab.href, { replace: true }) }}
              >
                {tab.label}
              </Block>
            )
          })}
        </Row>
      )}
      <Block class="bui-show-scroll" flex="1" padding="20px" overflowX="hidden" position="relative">
        {children}
      </Block>
      {footer && (
        <Row
          height="60px"
          alignItems="center"
          padding="0 20px"
          borderTop="solid 1px var(--container-outline-lighter)"
        >
          {footer}
        </Row>
      )}
      {!footer && (primaryActionText || secondaryActionText) && (
        <Row
          height="60px"
          alignItems="center"
          padding="0 20px"
          borderTop="solid 1px var(--container-outline-lighter)"
        >
          {primaryActionText && (
            <Button
              contained
              icon={primaryActionIcon}
              loading={primaryActionLoading}
              disabled={primaryActionDisabled}
              onClick={onPrimaryActionClick}
            >
              {primaryActionText}
            </Button>
          )}
          <SpacerHorizontal />
          {secondaryActionText && (
            <Button
              icon={secondaryActionIcon}
              loading={secondaryActionLoading}
              disabled={secondaryActionDisabled}
              onClick={onSecondaryActionClick}
            >
              {secondaryActionText}
            </Button>
          )}
        </Row>
      )}
      {PopupMenu}
    </Col>
  )
}

export default Page
