import { Switch } from '@sodra/prutt'
import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { fetchVoice } from '../../actions'
import { useStore } from '../../store'
import Spinner from '../Spinner'

import { VoiceDetails } from './VoiceDetails'

type Props = {
  voiceId: string
}

export const Voice = ({ voiceId }: Props) => {
  const { voice } = useStore('voice')

  useEffect(() => {
    fetchVoice(voiceId)
  }, [voiceId])

  if (!voice || voice.id !== voiceId) {
    return <Spinner />
  }

  return (
    <Switch>
      {/* <Route path="/character/:characterId/delete" component={DeleteCharacter} />
        <Route path="/character/:characterId/clone" component={CloneCharacter} /> */}
      <VoiceDetails />
    </Switch>
  )
}

export default Voice
