import { pluralize } from './pluralize'

type FormatOptions = { short?: boolean; separator?: string }

export const formatHumanReadableDuration = (ms = 0, options?: FormatOptions) => {
  const absMs = Math.abs(ms)

  const hours = Math.floor(absMs / (1000 * 60 * 60))
  let rest = absMs - hours * 1000 * 60 * 60

  const minutes = Math.floor(rest / (1000 * 60))
  rest -= minutes * 1000 * 60

  const seconds = Math.floor(rest / 1000)

  const strParts: String[] = []
  if (hours > 0) {
    if (options?.short) {
      strParts.push(`${hours}h`)
    } else {
      strParts.push(`${hours} ${pluralize('hour', hours)}`)
    }
  }
  if (minutes > 0) {
    if (options?.short) {
      strParts.push(`${minutes}m`)
    } else {
      strParts.push(`${minutes} ${pluralize('minute', minutes)}`)
    }
  }
  if (seconds > 0 || strParts.length === 0) {
    if (options?.short) {
      strParts.push(`${seconds}s`)
    } else {
      strParts.push(`${seconds} ${pluralize('second', seconds)}`)
    }
  }

  return `${ms < 0 ? '-' : ''}${strParts.join(options?.separator ?? ' ').trim()}`
}
