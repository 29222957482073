import {
  Button,
  Checkbox,
  Form,
  NativeSelect,
  SpacerVertical,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { Block } from 'jsxstyle'
import { Col, Row } from 'jsxstyle/preact'
import { useEffect, useState } from 'preact/hooks'

interface BaseElement<T> {
  name: string
  value?: T
  label: string
  placeholder?: string
  required?: boolean
  validate?: (value: T | undefined, values: any) => boolean
  visible?: boolean | ((values: any) => boolean)
  infoText?: string
  autocomplete?: string
}

interface TextFieldElement extends BaseElement<string> {
  element: 'TextField'
  type?: 'text'
}

interface NumberTextFieldElement extends BaseElement<number> {
  element: 'TextField'
  type: 'number'
}

interface DateTextFieldElement extends BaseElement<Date> {
  element: 'TextField'
  type: 'date'
}

interface EmailTextFieldElement extends BaseElement<string> {
  element: 'TextField'
  type: 'email'
}

interface TextAreaElement extends BaseElement<string> {
  element: 'TextArea'
  value?: string
}

interface SelectElelemt extends BaseElement<string> {
  element: 'Select'
  options: { label: string; value: string }[]
}

interface CheckboxElement extends BaseElement<boolean> {
  element: 'Checkbox'
  value?: boolean
}

type Element =
  | TextFieldElement
  | NumberTextFieldElement
  | DateTextFieldElement
  | EmailTextFieldElement
  | TextAreaElement
  | SelectElelemt
  | CheckboxElement

type Params = {
  disableSubmitIfNoChanges?: boolean
  elements: Element[]
  submitText?: string
  loading?: boolean
  onSubmit: (values: Record<string, any>) => void
  onCancel?: () => void
}

const elementValues = (elements: Element[]) => {
  const values: Record<string, any> = {}
  for (let element of elements) {
    values[element.name] = element.value
  }
  return values
}

const formatLabel = (element: Element) => {
  return `${element.label}${element.required ? ' *' : ''}`
}

export const SuperForm = ({
  disableSubmitIfNoChanges = false,
  elements,
  submitText = 'Save',
  loading,
  onSubmit,
  onCancel
}: Params) => {
  const [values, setValues] = useState<Record<string, any>>(elementValues(elements))
  const [errors, setErrors] = useState<Record<string, string>>({})

  let hasChanges = elements.some((element) => values[element.name] !== element.value)
  let disableSubmit = disableSubmitIfNoChanges && !hasChanges

  // Scroll to first error
  useEffect(() => {
    const hasError = Object.keys(errors).length > 0
    if (hasError) {
      const firstErrorElement = elements.find((element) => !!errors[element.name])
      document.querySelector(`#${firstErrorElement!.name}`)?.scrollIntoView()
    }
  }, [errors])

  const setValue = (name: string, value: any) => {
    setValues((values) => ({
      ...values,
      [name]: value
    }))
  }

  const setError = (name: string, message: string) => {
    setErrors((errors) => ({
      ...errors,
      [name]: message
    }))
  }

  const isVisible = (element: Element): boolean => {
    if (typeof element.visible === 'function') {
      return element.visible(values)
    }
    return element.visible ?? true
  }

  const handleSubmit = () => {
    setErrors({})
    let hasError = false
    for (let element of elements) {
      if (!isVisible(element)) {
        continue
      }
      const value = values[element.name]
      const hasValue = value && (typeof value !== 'string' || value.trim() !== '')
      if (element.required && !hasValue) {
        hasError = true
        setError(element.name, 'Field is required')
      } else if (element.validate && !element.validate(value, values)) {
        hasError = true
        setError(element.name, 'Please enter a valid value')
      }
    }

    if (!hasError) {
      onSubmit(values)
    }
  }

  return (
    <Form disable={disableSubmit} onSubmit={handleSubmit} maxWidth="500px">
      <Col gap="15px" alignItems="flex-start">
        {elements
          .filter((element) => isVisible(element))
          .map((element) => {
            if (element.element === 'TextField') {
              return (
                <TextField
                  props={{ id: element.name }}
                  name={element.name}
                  key={element.name}
                  width="100%"
                  autocomplete={element.autocomplete || 'off'}
                  type={element.type || 'text'}
                  label={formatLabel(element)}
                  placeholder={element.placeholder}
                  value={values[element.name]}
                  onInput={(value: any) => setValue(element.name, value)}
                  infoText={element.infoText}
                  errorText={errors[element.name]}
                />
              )
            }
            if (element.element === 'TextArea') {
              return (
                <TextArea
                  props={{ id: element.name }}
                  key={element.name}
                  width="100%"
                  autocomplete="off"
                  label={formatLabel(element)}
                  placeholder={element.placeholder}
                  value={values[element.name]}
                  onInput={(value: any) => setValue(element.name, value)}
                  infoText={element.infoText}
                  errorText={errors[element.name]}
                />
              )
            }
            if (element.element === 'Select') {
              return (
                <NativeSelect
                  props={{ id: element.name }}
                  key={element.name}
                  width="100%"
                  label={formatLabel(element)}
                  placeholder={element.placeholder}
                  value={values[element.name]}
                  onChange={(value: any) => setValue(element.name, value)}
                  options={element.options}
                  infoText={element.infoText}
                  errorText={errors[element.name]}
                />
              )
            }
            if (element.element === 'Checkbox') {
              return (
                <Checkbox
                  props={{ id: element.name }}
                  key={element.name}
                  width="100%"
                  label={formatLabel(element)}
                  checked={values[element.name]}
                  onChange={(value: any) => setValue(element.name, value)}
                  infoText={element.infoText}
                  errorText={errors[element.name]}
                />
              )
            }
          })}
        {elements.some((element) => element.required) && (
          <Block color="var(--on-surface-light)" fontSize="14px">
            * Required field
          </Block>
        )}
        <SpacerVertical tiny />
        <Row alignItems="center" gap="10px">
          <Button contained type="submit" loading={loading} disabled={disableSubmit}>
            {submitText}
          </Button>
          {onCancel && <Button onClick={onCancel}>Cancel</Button>}
        </Row>
      </Col>
    </Form>
  )
}
