import { Fragment, h } from 'preact'
import { useMatchMedia } from 'jsxstyle'
import { Block, Grid, Row } from 'jsxstyle/preact'
import { useEffect, useState } from 'preact/hooks'
import {
  List,
  ListItem,
  PersonIcon,
  SpacerVertical,
  EventIcon,
  InfoIcon,
  HourglassTopIcon
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo, Switch } from '@sodra/prutt'

import { setLastMessage } from '../../actions'
import { get } from '../../api'
import { useFetchChats } from '../../use-fetch-chats'

import Page from '../Page'
import Spinner from '../Spinner'
import { Chat } from '../Chat/Chat'

import { ChatShortlist } from './ChatShortlist'
import { AssignSession } from './AssignSession'
import { ChatList } from './ChatList'
import { NoChatsYet } from './NoChatsYet'
import { NoChatSelected } from './NoChatSelected'
import { useStore } from '../../store'
import { Session, Voice } from '../../types'
import { formatScheduled } from 'lib'
import Avatar from '../Avatar'

type Props = {
  chatId: string
}

export const Chats = ({ chatId }: Props) => {
  const isNarrow = useMatchMedia('(max-width: 800px)')

  const { currentGame, chats } = useStore('currentGame', 'chats')

  const [voice, setVoice] = useState<Voice>()

  useFetchChats(10000)

  useEffect(() => {
    if (!chats) {
      return
    }

    const chat = chats.find((c) => c.id === chatId)
    if (chat) {
      get(`/voices/${chat.voice.id}`, { gameId: currentGame!.id }).then(({ data: voice }) =>
        setVoice(voice)
      )
    } else {
      setVoice(undefined)
      if (chats.length === 1) {
        routeTo(`/chats/${chats[0].id}`)
      } else {
        routeTo('/chats')
      }
    }
  }, [chats, chatId, currentGame])

  const handleCharacterSheetChanged = (characterId: string, characterSheetUnlocked: boolean) => {
    setVoice((voice) =>
      voice
        ? {
            ...voice,
            characters: voice.characters.map((c) =>
              c.id === characterId
                ? {
                    ...c,
                    characterSheetUnlocked
                  }
                : c
            )
          }
        : undefined
    )
  }

  if (!chats) {
    return <Spinner />
  }

  if (isNarrow) {
    return (
      <Fragment>
        <Switch>
          <Route
            path="/chats/:chatId"
            render={(props: any) => {
              const chat = chats.find((c) => c.id === props.chatId)
              if (!chat) return null
              const pageActions = [
                {
                  icon: PersonIcon,
                  label: 'Show voice profile',
                  onClick: () => routeTo(`/voice/${chat.voice.id}`)
                },
                {
                  icon: EventIcon,
                  label: `Book ${chat.voice.name}`,
                  onClick: () => routeTo(`/chats/${chat.id}/assign-session`)
                }
              ]
              return (
                <Page
                  title={
                    <Row alignItems="center" gap="10px">
                      <Avatar src={chat.voice.picture} name={chat.voice.name} />
                      <Block>{chat.voice.name}</Block>
                    </Row>
                  }
                  onBack={() => goBack('/chats')}
                  actions={pageActions}
                >
                  <Chat
                    chat={chat}
                    onMessage={(message: any) => setLastMessage(chat.id, message)}
                  />
                </Page>
              )
            }}
          />
          <Page title="Chat">
            {chats.length > 0 && <ChatList selectedChatId={chatId} />}
            {chats.length === 0 && <NoChatsYet />}
          </Page>
        </Switch>
        <Route
          path="/chats/:chatId/assign-session"
          render={(props: any) => {
            const chat = chats.find((c) => c.id === props.chatId)
            if (chat) {
              return <AssignSession chat={chat} />
            }
          }}
        />
      </Fragment>
    )
  }

  return (
    <Row height="100%" maxHeight="100%">
      <Block
        width="220px"
        overflowY="auto"
        borderRight="1px solid var(--container-outline)"
        paddingTop="60px"
      >
        <ChatList selectedChatId={chatId} />
      </Block>
      <Block flex={1}>
        <Switch>
          <Route
            path="/chats/:chatId"
            render={(props: any) => {
              const chat = chats.find((c) => c.id === props.chatId)
              if (!chat) return null
              const pageActions = [
                {
                  icon: PersonIcon,
                  label: 'Show voice profile',
                  onClick: () => routeTo(`/voice/${chat.voice.id}`)
                },
                {
                  icon: EventIcon,
                  label: `Book ${chat.voice.name}`,
                  onClick: () => routeTo(`/chats/${chat.id}/assign-session`)
                }
              ]

              const acceptedSessions: Session[] = (voice?.sessions ?? []).filter(
                (session) => session.voiceAccepted && !session.passedDue
              )
              const sessionsAwaitingAcceptance: Session[] = (voice?.sessions ?? []).filter(
                (session) => !session.voiceAccepted && !session.voiceDeclined && !session.passedDue
              )

              return (
                <Page
                  title={
                    <Row alignItems="center" gap="10px">
                      <Avatar src={chat.voice.picture} name={chat.voice.name} />
                      <Block>{chat.voice.name}</Block>
                    </Row>
                  }
                  actions={pageActions}
                >
                  <Row gap="20px" height="100%">
                    <Block flex="1" maxWidth="800px">
                      <Chat
                        chat={chat}
                        onMessage={(message: any) => setLastMessage(chat.id, message)}
                      />
                    </Block>
                    <Block width="340px">
                      {voice && (
                        <Fragment>
                          <Block color="var(--on-surface-light)">Characters</Block>
                          <SpacerVertical small />
                          <ChatShortlist
                            chat={chat}
                            voice={voice}
                            onCharacterSheetChanged={handleCharacterSheetChanged}
                          />
                          <SpacerVertical />
                          {sessionsAwaitingAcceptance.length > 0 && (
                            <Fragment>
                              <SpacerVertical />
                              <Block color="var(--on-surface-light)">
                                Sessions awaiting acceptance
                              </Block>
                              <SpacerVertical small />
                              <List>
                                {sessionsAwaitingAcceptance.map((session) => {
                                  return (
                                    <ListItem
                                      icon={HourglassTopIcon}
                                      text={`Session ${session.num}`}
                                      secondaryText={
                                        <Block>
                                          {`
                                        ${formatScheduled(session.scheduled)} · ${
                                            session.duration
                                          } hour${session.duration !== 1 ? 's' : ''}
                                      `}
                                          <br />
                                          <Block color="var(--warning)">
                                            Waiting for {voice.name} to accept
                                          </Block>
                                        </Block>
                                      }
                                      onClick={() => routeTo(`/session/${session.id}`)}
                                    />
                                  )
                                })}
                              </List>
                            </Fragment>
                          )}
                          {acceptedSessions.length > 0 && (
                            <Fragment>
                              <SpacerVertical />
                              <Block color="var(--on-surface-light)">Upcoming sessions</Block>
                              <SpacerVertical small />
                              <List>
                                {acceptedSessions.map((session) => {
                                  return (
                                    <ListItem
                                      icon={EventIcon}
                                      text={`Session ${session.num}`}
                                      secondaryText={
                                        <Block>
                                          {`
                                          ${formatScheduled(session.scheduled)} · ${
                                            session.duration
                                          } hour${session.duration !== 1 ? 's' : ''}
                                        `}
                                          <br />
                                        </Block>
                                      }
                                      onClick={() => routeTo(`/session/${session.id}`)}
                                    />
                                  )
                                })}
                              </List>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Block>
                  </Row>
                </Page>
              )
            }}
          />
          <Grid height="100%" placeItems="center">
            <Block maxWidth="300px">
              {chats.length === 0 && <NoChatsYet />}
              {chats.length > 0 && <NoChatSelected />}
            </Block>
          </Grid>
        </Switch>
      </Block>
      <Route
        path="/chats/:chatId/assign-session"
        render={(props: any) => {
          const chat = chats.find((c) => c.id === props.chatId)
          if (chat) {
            return <AssignSession chat={chat} />
          }
        }}
      />
    </Row>
  )
}
