export const processLine = (line: string) => {
  let l = line
  // Keep spaces in beginning
  l = l.replace(/^ /g, '\u00a0')
  // Handle rest of spaces
  // Keep regular spaces to make lines wrap
  l = l.replace(/  /g, ' \u00a0')
  // Handle tabs
  l = l.replace(/\t/g, '\u00a0\u00a0\u00a0\u00a0')
  //
  return l
}
