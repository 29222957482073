import { LineFilterSettings } from 'src/types'

export type LinesViewOptions = {
  showFilters: boolean
  page: number
  pageSize: number
} & LineFilterSettings

export const defaultLinesViewOptions: LinesViewOptions = {
  showFilters: false,
  columns: ['filename', 'character', 'event', 'scene', 'line', 'selectedTake'],
  orderBy: 'created',
  sortOrder: 'desc',
  page: 0,
  pageSize: 20
}

type UpdateLocalStorageLinesViewOptionsFlags = { overwrite: boolean }

export const updateLocalStorageLinesViewOptions = (
  gameShortId: string,
  options: LinesViewOptions,
  flags?: UpdateLocalStorageLinesViewOptionsFlags
) => {
  let updatedViewOptions: LinesViewOptions

  if (flags?.overwrite) {
    updatedViewOptions = { ...options }
  } else {
    const currentViewOptions: LinesViewOptions =
      getLocalStorageLinesViewOptions(gameShortId) ?? defaultLinesViewOptions

    updatedViewOptions = {
      ...currentViewOptions,
      ...options
    }
  }
  localStorage.setItem(
    `speechless:${gameShortId}:lines:view-options`,
    JSON.stringify(updatedViewOptions)
  )
}

export const getLocalStorageLinesViewOptions = (
  gameShortId: string
): LinesViewOptions | undefined => {
  const storedString = localStorage.getItem(`speechless:${gameShortId}:lines:view-options`)
  return storedString ? JSON.parse(storedString) : undefined
}
