import { h } from 'preact'
import { Dialog, SpacerVertical } from '@sodra/bongo-ui'
import { showSnackbar, translateLines, updateGame } from '../../actions'
import { useStore } from '../../store'
import { goBack } from '@sodra/prutt'
import { SuperForm } from '../SuperForm'
import * as languages from '../../languages'
import { formatLanguage } from '../../format-language'

export const AddSecondaryLanguage = () => {
  const { currentGame, isUpdatingGame, isTranslatingLines } = useStore(
    'currentGame',
    'isUpdatingGame',
    'isTranslatingLines'
  )

  const onClose = () => goBack('/settings')

  const handleSubmit = async (values: any) => {
    const secondaryLanguages = currentGame?.secondaryLanguages || []
    if (await updateGame({ secondaryLanguages: [...secondaryLanguages, values.language] })) {
      if (values.translate) {
        await translateLines(undefined, { language: values.language })
      }
      showSnackbar(`Added language ${formatLanguage(values.language)}`)
      onClose()
    }
  }

  const languageOptions = languages
    .getCodes()
    .filter(
      (code: string) =>
        currentGame?.primaryLanguage !== code && !currentGame?.secondaryLanguages?.includes(code)
    )
    .map((code: string) => {
      return { value: code, label: formatLanguage(code) }
    })
    .sort((option1, option2) => option1.label.localeCompare(option2.label))

  return (
    <Dialog disableAutofocus title="Select language" onClose={onClose}>
      <SuperForm
        onSubmit={handleSubmit}
        onCancel={onClose}
        loading={isUpdatingGame || isTranslatingLines}
        submitText="Add language"
        elements={[
          {
            element: 'Select',
            label: 'Language',
            name: 'language',
            options: languageOptions,
            required: true
          },
          {
            element: 'Checkbox',
            label: 'Translate existing lines',
            name: 'translate'
          }
        ]}
      />
      <SpacerVertical />
    </Dialog>
  )
}
