import { useEffect } from 'preact/hooks'
import { fetchChats } from './actions'

//@ts-ignore
import favicon from './favicon.png'
//@ts-ignore
import faviconBadge from './favicon-badge.png'
import { useStore } from './store'

export const useFetchChats = (delay: number = 60000) => {
  const { currentGame, currentUser, chats = [] } = useStore('currentGame', 'currentUser', 'chats')

  const hasUnread = chats.some((chat) => chat.hasUnread)

  const updateFavicon = (src: string) => {
    var link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    link.href = src
  }

  useEffect(() => {
    if (currentGame && currentUser) {
      const interval = setInterval(fetchChats, delay)
      fetchChats()
      return () => clearInterval(interval)
    }
  }, [currentGame, currentUser])

  useEffect(() => {
    if (hasUnread) {
      updateFavicon(faviconBadge)
    } else {
      updateFavicon(favicon)
    }
  }, [hasUnread])

  return { hasUnread }
}
