import { TextField } from '@sodra/bongo-ui'
import { useState } from 'preact/hooks'

export function TextFieldSuggest(props: {
  label: string
  placeholder?: string
  value: string
  onInput: (value: string) => void
  suggestions: { text: string; secondaryText?: string }[]
}) {
  const [suggest, setSuggest] = useState<{ text: string }[]>([])
  const [elem, setElem] = useState<HTMLInputElement | null>(null)

  const getStartStop = (elem: HTMLInputElement) => {
    let start = elem.selectionStart ?? elem.value.length
    while (start > 0 && !/[\s\n\\.\/\\]/.test(elem.value.charAt(start - 1))) {
      start = start - 1
    }
    let stop = start + 1
    while (stop < elem.value.length && !/[\s\n\\.\/\\]/.test(elem.value.charAt(stop))) {
      stop = stop + 1
    }
    return { start, stop }
  }

  const handleSuggestSelect = ({ text }: any) => {
    if (!elem) return
    const { start, stop } = getStartStop(elem)
    const newValue = props.value.slice(0, start) + text + props.value.slice(stop)
    props.onInput(newValue)
    setTimeout(() => {
      elem.selectionStart = start + text.length
      elem.selectionEnd = start + text.length
    }, 0)
    setSuggest([])
  }

  const handleSuggestClose = () => {
    setSuggest([])
  }

  const handleSuggest = (value: string, element: HTMLInputElement) => {
    const { start, stop } = getStartStop(element)
    const word = value.slice(start, stop)

    if (word.trim() === '') {
      setSuggest([])
      return
    }
    let suggestItems = props.suggestions.filter((suggestion) =>
      suggestion.text.toLowerCase().startsWith(word.toLowerCase())
    )
    setSuggest(suggestItems)
  }

  const handleInput = (value: any, e: Event) => {
    if (!e.target) return
    const element = e.target as HTMLInputElement
    props.onInput(value)
    setElem(element)

    handleSuggest(value, element)
  }

  return (
    <TextField
      width="100%"
      label={props.label}
      placeholder={props.placeholder}
      value={props.value}
      onInput={handleInput}
      suggest={suggest}
      suggestMode="at-caret"
      onSuggestSelect={handleSuggestSelect}
      onSuggestClose={handleSuggestClose}
      autocomplete="off"
      autofill="off"
    />
  )
}
