import { h } from 'preact'
import { BasicDialog } from '@sodra/bongo-ui'
import { useStore } from '../store'

export const LargeDialog = (props: any) => {
  const { isNarrow } = useStore('isNarrow')
  return (
    <BasicDialog large={!isNarrow} full={isNarrow} flexGrowContent disableAutofocus {...props} />
  )
}

export default LargeDialog
