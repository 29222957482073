import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { goBack, Route, routeTo, Switch } from '@sodra/prutt'

import { fetchLine } from '../../actions'
import { useEffectSkipFirst } from '../../use-effect-skip-first'

import Spinner from '../Spinner'
import EditDetails from './EditDetails'
import { useStore } from '../../store'
import Page from '../Page'
import { Activity } from './Activity'

type Props = {
  lineId: string
}

const Line = ({ lineId }: Props) => {
  const { currentGame, currentLanguage, line } = useStore('currentGame', 'currentLanguage', 'line')
  // Handle switch game
  useEffectSkipFirst(() => {
    routeTo('/lines', true)
  }, [currentGame!.id])

  useEffect(() => {
    fetchLine(lineId)
  }, [lineId])

  useEffect(() => {
    if (line) {
      const takesToCheckIfLoading = []

      if (line.translations[currentLanguage]?.takes) {
        takesToCheckIfLoading.push(...line.translations[currentLanguage].takes!)
      }

      if (currentGame?.primaryLanguage !== currentLanguage) {
        const primaryLanguageSelectedTake =
          line.translations[currentGame!.primaryLanguage].selectedTake

        if (primaryLanguageSelectedTake) {
          takesToCheckIfLoading.push(primaryLanguageSelectedTake)
        }
      }

      const hasLoading = takesToCheckIfLoading.some((take) => !take.processedUri && !take.error)

      if (hasLoading) {
        const timeout = setTimeout(() => fetchLine(line.id), 5000)
        return () => clearTimeout(timeout)
      }
    }
  }, [line, currentGame, currentLanguage])

  const onBack = () => goBack('/lines')

  if (!line || line.id !== lineId) {
    return <Spinner />
  }

  return (
    <Page
      title="Line"
      onBack={onBack}
      tabs={[
        { label: 'Details', href: `/line/${line.id}` },
        { label: 'Activity', href: `/line/${line.id}/activity` }
      ]}
    >
      <Switch>
        <Route path="/line/:lineId/activity" component={Activity} />
        <EditDetails />
      </Switch>
    </Page>
  )
}

export default Line
