import { h, Fragment } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'

import {
  Button,
  Checkbox,
  Form,
  Link,
  P,
  ProgressCircular,
  Select,
  SelectChip,
  SpacerHorizontal,
  SpacerVertical,
  TextArea,
  TextField,
  VoiceIcon
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { createSession, showSnackbar, updateGame } from '../../actions'
import { useVoice } from '../../use-voice'
import { urlSearchParams } from '../../url-search-params'

import Page from '../Page'
import Avatar from '../Avatar'

import { SelectVoice } from './SelectVoice'
import { isValidUrl } from '../../validate-url'
import { ErrorMessage } from '../ErrorMessage'
import { DateAndTimeInput } from '../DateAndTimeInput'
import { SessionFee } from '../SessionFee'
import { get } from '../../api'
import { useStore } from '../../store'
import { deleteSearchParam, setSearchParam } from '../../delete-search-param'
import { confirmBillingAccount } from '../confirmBillingAccount'
import { confirmVocalStress } from '../confirmVocalStress'
import { formatLanguage } from '../../format-language'
import { SessionStartEnd } from './SessionStartEnd'
import AuditionDetails, { AuditionDetailsSubmitParams } from '../AuditionDetails'
import { BuyoutInfo } from '../BuyoutInfo'
import { Order } from '../../types'
import { confirm, formatCurrency } from 'lib'
import { confirmCreditCard } from '../confirmCreditCard'
import { confirmPurchase } from '../confirmPurchase'
import {
  calcBuyoutOrderDetails,
  calcSessionOrderDetails,
  getOrderDetails
} from './calc-session-order-details'
import { fetchBillingAccounts } from 'src/actions/billing-accounts'
import SelectBillingAccount from '../SelectBillingAccount'
import { confirmSelectBillingAccount } from '../confirmSelectBillingAccount'

export const CreateSession = () => {
  const { isCreatingSession, currentGame, config, billingAccounts } = useStore(
    'isCreatingSession',
    'currentGame',
    'config',
    'billingAccounts'
  )

  const [description, setDescription] = useState('')
  const [scheduled, setScheduled] = useState<Date | null>(null)
  const [scheduledError, setScheduledError] = useState('')
  const [duration, setDuration] = useState(1)
  const [durationError, setDurationError] = useState('')
  const [meetingLink, setMeetingLink] = useState('')
  const [meetingLinkError, setMeetingLinkError] = useState('')
  const [vocalStress, setVocalStress] = useState(false)
  const [profanity, setProfanity] = useState(false)
  const [sensitiveContent, setSensitiveContent] = useState(false)
  const [language, setLanguage] = useState<string | null>()

  const [isFetchingBuyoutOrder, setIsFetchingBuyoutOrder] = useState(false)
  const [buyoutOrder, setBuyoutOrder] = useState<Order | undefined>()

  const [agreementsAccepted, setAgreementsAccepted] = useState<boolean>(false)
  const [agreementError, setAgreementError] = useState('')

  const [showSelectBillingAccountDialog, setShowSelectBillingAccountDialog] = useState(false)

  const params = urlSearchParams()
  const { voiceId, setVoiceId, voice, setVoice } = useVoice(params.get('voiceId'))

  // restore=true, if the session state should be loaded from local storage
  const restore = params.get('restore')

  // submit=true, if a credit card has been added and the session should be
  // automatically submitted. NOTE: The session agreements needs to be approved.
  const doSubmit = params.get('submit')

  useEffect(() => {
    if (restore && currentGame) {
      // Restore session state from local storage
      const sessionJSON = localStorage.getItem(`speechless:${currentGame.id}:new-session`)
      if (sessionJSON) {
        const session = JSON.parse(sessionJSON)
        setDescription(session.description)
        setScheduled(session.scheduled ? new Date(session.scheduled) : null)
        setDuration(session.duration)
        setMeetingLink(session.meetingLink)
        setVocalStress(session.vocalStress)
        setProfanity(session.profanity)
        setSensitiveContent(session.sensitiveContent)
        setLanguage(session.language)
      }
      // Remove restore from query string
      deleteSearchParam('restore')
    }
  }, [restore, currentGame])

  useEffect(() => {
    fetchBillingAccounts()
  }, [])

  const removeSessionState = () => {
    if (currentGame) {
      // Save session state to local storage
      localStorage.removeItem(`speechless:${currentGame.id}:new-session`)
    }
  }

  const saveSessionState = () => {
    if (currentGame) {
      // Save session state to local storage
      localStorage.setItem(
        `speechless:${currentGame.id}:new-session`,
        JSON.stringify({
          voiceId,
          description,
          scheduled,
          duration,
          meetingLink,
          vocalStress,
          profanity,
          sensitiveContent,
          language
        })
      )
    }
  }

  const onBack = () => goBack('/sessions')

  useEffect(() => {
    setBuyoutOrder(undefined)
    if (currentGame && voiceId) {
      setIsFetchingBuyoutOrder(true)
      get(`/orders/buyout`, { gameId: currentGame.id, voiceId })
        .then(({ data: order }) => setBuyoutOrder(order))
        .finally(() => setIsFetchingBuyoutOrder(false))
    }
  }, [currentGame, voiceId])

  useEffect(() => {
    if (scheduled) {
      setScheduledError('')
    }
  }, [scheduled])

  useEffect(() => {
    if (duration) {
      setDurationError('')
    }
  }, [duration])

  useEffect(() => {
    if (isValidUrl(meetingLink, { allowEmpty: true })) {
      setMeetingLinkError('')
    }
  }, [meetingLink])

  useEffect(() => {
    if (agreementsAccepted) {
      setAgreementError('')
    }
  }, [agreementsAccepted])

  const handleSelectVoiceClick = async () => {
    routeTo('/create-session/select-voice')
  }

  const saveSession = async (
    auditionDetails?: AuditionDetailsSubmitParams,
    shareCharacterSheet?: boolean
  ) => {
    // Add time zone to date
    const scheduledDate = scheduled ? new Date(scheduled) : undefined

    const result = await createSession({
      description,
      scheduled: scheduledDate,
      duration,
      voiceId,
      meetingLink,
      vocalStress,
      profanity,
      sensitiveContent,
      language,
      auditionDetails,
      shareCharacterSheet
    })

    if (result) {
      removeSessionState()

      const { session } = result
      if (
        await confirm({
          title: 'Session created',
          message: 'Do you want to add lines to the session now?',
          confirmText: 'Add lines',
          rejectText: 'Later'
        })
      ) {
        routeTo(`/session/${session.id}/lines/add-lines`, true)
      } else {
        showSnackbar('Session created', {
          text: 'Open',
          onClick: () => routeTo(`/session/${session.id}`)
        })

        onBack()
      }
    }
  }

  const createBillingAccount = () => {
    if (!currentGame) {
      return
    }
    // Save session in local storage – to be restored
    saveSessionState()
    const params = new URLSearchParams({
      onSuccessUrl: `/create-session/?voiceId=${voiceId}&restore=true`
    })
    routeTo(`/settings/billing/add-billing-account?${params.toString()}`)
  }

  const handleSubmit = async () => {
    let errorId
    if (voiceId && scheduled && !agreementsAccepted) {
      setAgreementError('Please accept to continue')
      errorId = 'agreement'
    }
    if (!scheduled) {
      setScheduledError('Please enter a date and time')
      errorId = 'schedule'
    }
    if (!duration) {
      setDurationError('Please enter duration')
      errorId = 'duration'
    }
    if (!isValidUrl(meetingLink, { allowEmpty: true })) {
      setMeetingLinkError('Please enter a valid meeting link URL')
      errorId = 'meetingLink'
    }
    if (errorId) {
      const el = document.querySelector(`#${errorId}`)
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      return
    }

    const billingAccount = currentGame?.billingAccount

    // Verify billing account
    if (voiceId && !billingAccount) {
      if (await confirmBillingAccount()) {
        if (billingAccounts?.length) {
          setShowSelectBillingAccountDialog(true)
        } else {
          createBillingAccount()
        }
      }
      return
    }

    // Verify credit card
    if (voiceId && billingAccount && !billingAccount.invoice && !billingAccount.hasCreditCard) {
      if (await confirmCreditCard()) {
        // Save session in local storage – to be restored
        saveSessionState()
        const params = new URLSearchParams({
          onSuccessUrl: `/create-session/?game=${currentGame.shortId}&voiceId=${voiceId}&restore=true&submit=true`
        })
        routeTo(`/settings/billing/${billingAccount.id}/add-credit-card?${params.toString()}`)
      }
      return
    }

    const confirmVocalStressNeeded = vocalStress && duration! > 2
    const proceedVocalStress = !confirmVocalStressNeeded ? true : await confirmVocalStress()

    const budgetSegment = currentGame!.budgetSegment

    // NOTE: currentGame.billingAccount has property country
    const includeVAT = billingAccount?.country === 'Sweden'

    const sessionOrderDetails = calcSessionOrderDetails({
      budgetSegment,
      duration,
      includeVAT,
      config: config!
    })

    const buyoutOrderDetails = buyoutOrder
      ? getOrderDetails(buyoutOrder)
      : calcBuyoutOrderDetails({
          budgetSegment,
          includeVAT,
          config: config!
        })

    let totalIncVAT = sessionOrderDetails.totalIncVAT
    if (!buyoutOrder) {
      totalIncVAT += buyoutOrderDetails.totalIncVAT
    }

    const confirmPurchaseNeeded = billingAccount && config && voiceId
    const proceedPurchase = !confirmPurchaseNeeded
      ? true
      : await confirmPurchase({
          totalIncVAT,
          billingAccount,
          secondaryMessage: !billingAccount.invoice
            ? `We will reserve ${formatCurrency(
                totalIncVAT
              )} on your card. You have the right to cancel the session until 24 hours before it starts.
        24 hours before the session starts we will debit your card`
            : 'You have the right to cancel the session until 24 hours before it starts.'
        })

    if (proceedVocalStress && proceedPurchase) {
      if (voiceId) {
        // If no chat exists, make sure to get audition details.
        // Before creating session and chat.

        const { data: chatId } = await get(`/chats/get-chat-id`, {
          gameId: currentGame?.id,
          voiceId
        })

        if (chatId) {
          await saveSession()
        } else {
          // Ask for audition details before creating chat and saving session
          return routeTo(`/create-session/audition-details`)
        }
      } else {
        saveSession()
      }
    }
  }

  const checkboxVisible = voiceId && duration && scheduled

  const isLocalized = currentGame?.secondaryLanguages && currentGame.secondaryLanguages.length > 0

  const mapLangCode = (langCode: string) => {
    return {
      value: langCode,
      text: `${formatLanguage(langCode)}${
        langCode === currentGame!.primaryLanguage ? ' (Primary)' : ''
      }`
    }
  }

  const languageOptions: { value?: string; text: string }[] = [...currentGame!.secondaryLanguages!]
    .map(mapLangCode)
    .sort((option1, option2) => option1.text.localeCompare(option2.text))
  languageOptions.unshift(
    { value: 'unspecified', text: '-- No specific language --' },
    mapLangCode(currentGame!.primaryLanguage)
  )

  const selectLanguage = (language: string) => {
    setLanguage(language === 'unspecified' ? null : language)
  }

  useEffect(() => {
    if (doSubmit && currentGame) {
      handleSubmit()
    }
  }, [doSubmit, currentGame])

  return (
    <Page title="Create session" onBack={onBack}>
      {!currentGame || (!config && <ProgressCircular />)}
      {currentGame && config && (
        <>
          <Form onSubmit={handleSubmit}>
            {voice && (
              <Row alignItems="center" gap="8px">
                <Block>
                  <SelectChip
                    visual={<Avatar size={30} src={voice.picture} name={voice.name} />}
                    value={voice.name}
                    onClick={handleSelectVoiceClick}
                    onClear={() => {
                      deleteSearchParam('voiceId')
                      setVoice(undefined)
                    }}
                  />
                </Block>
                <Block fontSize="14px">
                  <Link onRoute={routeTo} to={`/voice/${voice.id}`}>
                    view
                  </Link>
                </Block>
              </Row>
            )}
            {!voice && (
              <SelectChip icon={VoiceIcon} label="Voice actor" onClick={handleSelectVoiceClick} />
            )}
            <SpacerVertical />
            <Row gap="40px">
              <Block maxWidth="700px">
                <TextField
                  autocomplete="off"
                  width="100%"
                  label="Meeting link"
                  value={meetingLink}
                  onInput={setMeetingLink}
                  errorText={meetingLinkError}
                  props={{ id: 'meetingLink' }}
                />
                <SpacerVertical />
                <TextArea
                  autoHeight
                  minRows={4}
                  maxRows={10}
                  autocomplete="off"
                  width="100%"
                  label="Description"
                  value={description}
                  onInput={setDescription}
                />
                <SpacerVertical />
                {isLocalized && (
                  <>
                    <Select
                      label="Language"
                      value={language}
                      onChange={selectLanguage}
                      options={languageOptions}
                    />
                    <SpacerVertical />
                  </>
                )}
                <Row alignItems="flex-start">
                  <DateAndTimeInput
                    value={scheduled}
                    onChange={setScheduled}
                    errorText={scheduledError}
                    // timeZone={currentGame?.timeZone}
                    props={{ id: 'scheduled' }}
                  />
                  <SpacerHorizontal />
                  <TextField
                    type="number"
                    min="1"
                    autocomplete="off"
                    label="Duration (hours)"
                    value={duration}
                    onInput={setDuration}
                    errorText={durationError}
                    maxWidth="140px"
                    props={{ id: 'duration' }}
                  />
                </Row>
                <SpacerVertical />
                {currentGame?.timeZone !== undefined && scheduled && (
                  <SessionStartEnd
                    label="Game time"
                    start={scheduled}
                    duration={duration}
                    timeZone={currentGame.timeZone}
                  />
                )}
                <SpacerVertical />
                {voice?.timeZone !== undefined && scheduled && (
                  <SessionStartEnd
                    label="Voice time"
                    start={scheduled}
                    duration={duration}
                    timeZone={voice.timeZone}
                  />
                )}
                <SpacerVertical />
                <Row alignItems="center">
                  <Checkbox
                    label="Anticipated profanity or offensive language"
                    checked={profanity}
                    onChange={setProfanity}
                  />
                </Row>
                <Row alignItems="center">
                  <Checkbox
                    label="Content of religious, sexual, racially sensitive, gender sensitive, potentially violent that the artist may be required to voice"
                    checked={sensitiveContent}
                    onChange={setSensitiveContent}
                  />
                </Row>
                <Row alignItems="center">
                  <Checkbox
                    label="Anticipated vocal stress"
                    checked={vocalStress}
                    onChange={setVocalStress}
                  />
                  <SpacerHorizontal />
                  <Link
                    to="https://www.britishvoiceassociation.org.uk/voicecare_stress-emotion-voice.htm"
                    target="_blank"
                  >
                    Read more
                  </Link>
                </Row>
              </Block>

              <Block maxWidth="500px">
                {voice && (
                  <Block border="1px solid var(--container-outline)" padding="20px">
                    <SessionFee
                      budgetSegment={currentGame!.budgetSegment}
                      duration={duration}
                      includeVAT={currentGame?.billingAccount?.country === 'Sweden'}
                    />
                    <SpacerVertical large />
                    <BuyoutInfo
                      budgetSegment={currentGame!.budgetSegment}
                      includeVAT={currentGame?.billingAccount?.country === 'Sweden'}
                      buyoutOrder={buyoutOrder}
                    />
                  </Block>
                )}
              </Block>
            </Row>
            {checkboxVisible && (
              <Fragment>
                <SpacerVertical />
                <Checkbox
                  disabled={isFetchingBuyoutOrder}
                  onChange={setAgreementsAccepted}
                  checked={agreementsAccepted}
                  label={
                    <Fragment>
                      I accept the session
                      {!buyoutOrder ? ' and buyout' : ''} costs and the{' '}
                      <Link
                        target="_blank"
                        to="https://drive.google.com/file/d/1qmMY30reHNiNZfRasZgAjz-bvMWdcj2h/view"
                      >
                        client terms and conditions
                      </Link>
                      .
                    </Fragment>
                  }
                  props={{ id: 'agreement' }}
                />
                {agreementError && (
                  <Fragment>
                    <ErrorMessage>{agreementError}</ErrorMessage>
                    <SpacerVertical />
                  </Fragment>
                )}
                <P color="var(--on-surface-light)" size="2">
                  Cancellation can be made up until 24 hours before scheduled time. Sessions
                  cancelled later will be fully charged.
                </P>
              </Fragment>
            )}
            <SpacerVertical />
            <Row alignItems="center">
              <Button type="submit" contained loading={isCreatingSession}>
                Create
              </Button>
              <SpacerHorizontal />
              <Button onClick={onBack}>Cancel</Button>
            </Row>
          </Form>
          <SpacerVertical large />
          <Route
            path="/create-session/select-voice"
            render={() => {
              return (
                <SelectVoice
                  onClose={() => goBack('/create-session')}
                  onSelect={(voice) => {
                    setVoiceId(voice.id)
                    goBack('/create-session')
                    setTimeout(() => {
                      setSearchParam('voiceId', voice.id)
                    }, 10)
                  }}
                />
              )
            }}
          />
          <Route
            path="/create-session/audition-details"
            render={() => {
              return (
                <AuditionDetails
                  onClose={() => goBack(`/create-session/?voiceId=${voice?.id}`)}
                  onSubmit={async (
                    auditionDetails: AuditionDetailsSubmitParams,
                    shareCharacterSheet
                  ) => {
                    await saveSession(auditionDetails, shareCharacterSheet)
                    onBack()
                  }}
                  onSkip={async () => {
                    await saveSession()
                    onBack()
                  }}
                />
              )
            }}
          />
        </>
      )}

      {showSelectBillingAccountDialog && billingAccounts?.length && (
        <SelectBillingAccount
          billingAccounts={billingAccounts}
          onCreateBillingAccount={createBillingAccount}
          onClose={() => setShowSelectBillingAccountDialog(false)}
          onSelect={async (billingAccount) => {
            if (await confirmSelectBillingAccount({ billingAccount })) {
              updateGame({ billingAccountId: billingAccount.id })
              return true
            }
            return false
          }}
        />
      )}
    </Page>
  )
}
