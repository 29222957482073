import { useEffect, useRef } from 'preact/hooks'

export const useEffectSkipFirst = (fn: Function, deps: any[]) => {
  const first = useRef(true)

  useEffect(() => {
    if (first.current) {
      first.current = false
    } else {
      return fn()
    }
  }, deps)
}
