import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { Route, routeTo, Switch } from '@sodra/prutt'

import { fetchScene } from '../../actions'
import { useEffectSkipFirst } from '../../use-effect-skip-first'

import Spinner from '../Spinner'

import EditDetails from './EditDetails'
import DeleteScene from './DeleteScene'
import ManageScene from './ManageScene'
import { useStore } from '../../store'

type Props = {
  sceneId: string
}

export const Scene = ({ sceneId }: Props) => {
  const { currentGame, scene } = useStore('currentGame', 'scene')

  // Handle switch game
  useEffectSkipFirst(() => {
    routeTo('/scenes', true)
  }, [currentGame!.id])

  useEffect(() => {
    fetchScene(sceneId)
  }, [sceneId])

  useEffect(() => {
    const inProgress =
      scene &&
      scene!.lines?.some((line) => {
        const lineTranslation = line.translations[currentGame?.primaryLanguage!]
        if (!lineTranslation) {
          return false
        }
        return (
          lineTranslation.selectedTake &&
          !lineTranslation.selectedTake.uri &&
          !lineTranslation.selectedTake.error
        )
      })
    if (inProgress) {
      const timeout = setTimeout(() => fetchScene(scene.id), 5000)
      return () => clearTimeout(timeout)
    }
  }, [scene])

  if (!scene || scene.id !== sceneId) {
    return <Spinner />
  }

  return (
    <Switch>
      <Route path="/scene/:sceneId/edit" component={EditDetails} />
      <Route path="/scene/:sceneId/delete" component={DeleteScene} />
      <ManageScene />
    </Switch>
  )
}

export default Scene
