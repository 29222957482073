import { h } from 'preact'
import { useSignal, useSignalEffect } from '@preact/signals'
import { AddIcon, Button, DeleteIcon, ProgressCircular } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import { Block, Col, Row } from 'jsxstyle/preact'
import { useEffect, useState } from 'preact/hooks'
import { fetchSession, setError, showSnackbar, updateLine } from '../../actions'
import { del, get } from '../../api'
import { useStore } from '../../store'
import { Line, Take } from '../../types'
import LinesTable from '../Lines/LinesTable'
import { OutdatedTakeDialog } from '../Lines/OutdatedTakeDialog'
import { signalDependency } from '../../signal-dependency'
import { pluralize, useAudioPlayer } from 'lib'
import { useLocalStorageState } from '../../use-local-storage-state'

type Props = {
  sessionId: string
}

export const SessionLines = ({ sessionId }: Props) => {
  const { currentGame, currentLanguage } = useStore('currentGame', 'currentLanguage')

  const [isFetchingLines, setIsFetchingLines] = useState(false)
  const lines = useSignal<Line[]>([])
  const totalLines = useSignal<number>(lines.value.length)
  const page = useSignal<number>(0)
  const [pageSize, setPageSize] = useLocalStorageState(`speechless:${currentGame!.id}:pageSize`, 20)
  const orderBy = useSignal<string>('created')
  const sortOrder = useSignal<'asc' | 'desc'>('asc')
  const outdatedTake = useSignal<{ take: Take; line: Line; language: string } | undefined>(
    undefined
  )
  const selectedLineIds = useSignal<string[]>([])
  const selectedTableRows = useSignal<number[]>([])

  async function fetchLines() {
    setIsFetchingLines(true)

    const params = {
      gameId: currentGame!.id,
      sessionId,
      limit: pageSize,
      offset: pageSize * page.value,
      orderBy: orderBy.value,
      sortOrder: sortOrder.value,
      includeTakes: true
    }

    try {
      const {
        data,
        meta: { total }
      } = await get(`/games/${params.gameId}/lines`, params)

      lines.value = data
      totalLines.value = total
    } catch (err) {
      setError(err)
    } finally {
      setIsFetchingLines(false)
    }
  }

  useEffect(() => {
    fetchLines()
  }, [sessionId, pageSize])

  useSignalEffect(() => {
    signalDependency(outdatedTake)
    fetchLines()
  })

  const { player } = useAudioPlayer()

  if (!player) {
    return null
  }

  const handleRemoveFromSession = async () => {
    try {
      await del(`/sessions/${sessionId}/lines`, {
        lineIds: selectedLineIds.value
      })
      fetchSession(sessionId)
      await fetchLines()
      showSnackbar(
        `${selectedLineIds.value.length} ${pluralize(
          'line',
          selectedLineIds.value.length
        )} removed from session`
      )
      selectedLineIds.value = []
    } catch (e) {
      setError(e)
    }
  }

  const handleSelectedTakeChange = async (lineId: string, takeId?: string) => {
    if (
      await updateLine(lineId, {
        selectedTakes: [{ language: currentLanguage, takeId: takeId || null }]
      })
    ) {
      fetchLines()
    }
  }

  useSignalEffect(() => {
    selectedTableRows.value = selectedLineIds.value.map((lineId) =>
      lines.value.findIndex((line) => line.id === lineId)
    )
  })

  const handleSelectedRowsChange = (selectedRows: number[]) => {
    const selectedIds = lines.value
      .filter((_, index) => selectedRows.includes(index))
      .map((line) => line.id)
    selectedLineIds.value = selectedIds
  }

  const linesTableColumns = ['character', 'event', 'scene', 'line', 'selectedTake']
  if (currentGame?.useExternalLineId) {
    linesTableColumns.push('externalLineId')
  }

  return (
    <>
      <Col flex={1} gap="10px">
        <Row alignSelf="start" alignItems="start">
          <Button
            color="var(--on-surface)"
            icon={AddIcon}
            link={{ href: `/session/${sessionId}/lines/add-lines`, onRoute: routeTo }}
          >
            Add lines
          </Button>
          <Button
            color="var(--on-surface)"
            icon={DeleteIcon}
            onClick={() => handleRemoveFromSession()}
            disabled={selectedLineIds.value.length === 0}
          >
            Remove selected
          </Button>
        </Row>
        <Block position="relative" height="100%" width="100%" maxWidth="1200px">
          <LinesTable
            showNotesAndWarnings
            loading={isFetchingLines}
            columns={linesTableColumns}
            lines={lines.value}
            onSelectedRowsChange={handleSelectedRowsChange}
            orderBy={orderBy.value}
            page={page.value}
            pageSize={pageSize}
            selectedRows={selectedTableRows.value}
            setOrderBy={(newOrderBy) => (orderBy.value = newOrderBy)}
            setPage={(newPage) => (page.value = newPage)}
            setPageSize={setPageSize}
            setSortOrder={(newSortOrder) => (sortOrder.value = newSortOrder)}
            sortOrder={sortOrder.value}
            total={totalLines.value}
            onSelectedTakeChange={handleSelectedTakeChange}
            setCharacter={() => {}}
            setEvent={() => {}}
            setLabels={() => {}}
            setScene={() => {}}
            onShowOutdatedTake={(take) => (outdatedTake.value = take)}
            customEmptyText={'No lines have been added to this session yet'}
            player={player}
          />
        </Block>
      </Col>
      {outdatedTake?.value && (
        <OutdatedTakeDialog
          take={outdatedTake.value.take}
          line={outdatedTake.value.line}
          language={outdatedTake.value.language}
          onClose={() => (outdatedTake.value = undefined)}
        />
      )}
    </>
  )
}
