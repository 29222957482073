import { h, JSX } from 'preact'

import { Block, Row } from 'jsxstyle/preact'
import { ChevronLeftIcon, ChevronRightIcon, IconButton } from '@sodra/bongo-ui'

// Copy/paste from bongo-ui data table

type Props = {
  numRowsPerPage: number
  page: number
  numRows: number
  onPageChange: (newPage: number) => void
}

export const Pagination = ({ numRowsPerPage, page, numRows, onPageChange }: Props) => {
  const rowMin = page * numRowsPerPage + 1
  const rowMax = Math.min(rowMin + numRowsPerPage - 1, numRows)
  const maxPage = Math.ceil(numRows / numRowsPerPage) - 1

  const hasPrevPage = page > 0
  const hasNextPage = page < maxPage

  return (
    <Row
      height="56px"
      alignItems="center"
      justifyContent="flex-end"
      borderTop="1px solid var(--container-outline-lighter)"
    >
      <Block padding="0 16px 0 32px">
        {rowMin}–{rowMax} of {numRows}
      </Block>
      <Block>
        <IconButton
          color="var(--on-surface)"
          icon={ChevronLeftIcon}
          onClick={() => onPageChange(page - 1)}
          disabled={!hasPrevPage}
          tooltipText="Previous page"
          delayClick
        />
        <IconButton
          color="var(--on-surface)"
          icon={ChevronRightIcon}
          onClick={() => onPageChange(page + 1)}
          disabled={!hasNextPage}
          tooltipText="Next page"
          delayClick
        />
      </Block>
    </Row>
  )
}
