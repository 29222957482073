import { stringify } from 'qs'

const apiUrl = import.meta.env.VITE_API_URL

export const getApiUrl = () => apiUrl + '/v1'

const doFetch = async (
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
  endpoint: string,
  body: any | null,
  params?: any
) => {
  let url = apiUrl + '/v1' + endpoint
  if (params) {
    url = url + '?' + stringify(params, { arrayFormat: 'brackets' })
  }

  const res = await fetch(url, {
    method: method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: body ? JSON.stringify(body) : null
  })

  const json = await res.json()

  if (!res.ok) {
    const error: Error & { title?: string; type?: string } = new Error(json.error)
    error.title = json.title
    error.type = json.type
    throw error
  }

  return json
}

export const postFormData = async (endpoint: string, formData: FormData) => {
  const url = apiUrl + '/v1' + endpoint

  const res = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: formData
  })

  const json = await res.json()

  if (!res.ok) {
    const error: Error & { title?: string; type?: string } = new Error(json.error)
    error.title = json.title
    error.type = json.type
    throw error
  }

  return json
}

export const get = (url: string, params?: any) => doFetch('GET', url, null, params)
export const post = (url: string, data?: any) => doFetch('POST', url, data)
export const patch = (url: string, data: any) => doFetch('PATCH', url, data)
export const put = (url: string, data?: any) => doFetch('PUT', url, data)
export const del = (url: string, data?: any) => doFetch('DELETE', url, data)
