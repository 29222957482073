import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import {
  Button,
  EditIcon,
  Form,
  NativeSelect,
  PlayIcon,
  SpacerHorizontal,
  SpacerVertical,
  StopIcon,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { createAIVoice } from '../actions'
import { speak } from '../speak'

import Page from './Page'
import Avatar from './Avatar'
import { useTextToSpeech } from './use-text-to-speech'
import MinMaxSlider from './MinMaxSlider'
import PictureDialog from './PictureDialog'
import { useStore } from '../store'
import { Checkbox } from '@sodra/bongo-ui'
import { List } from '@sodra/bongo-ui'
import { ListItem } from '@sodra/bongo-ui'
import { IconButton } from '@sodra/bongo-ui'
import { DeleteIcon } from '@sodra/bongo-ui'
import { post } from '../api'
import { useAudioPlayer } from 'lib/src/hooks/use-audio-player'

const audio = new Audio()

export const uploadFile = async (file: File): Promise<string> => {
  const contentType = file.type

  const {
    data: { signedUrl, uri }
  } = await post('/sign-url', { prefix: 'tmp-audio', contentType })

  await fetch(signedUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': contentType
    }
  })

  return uri
}

export const CreateAiVoice = () => {
  const { isCreatingAIVoice, isNarrow } = useStore('isCreatingAIVoice', 'isNarrow')

  const { player } = useAudioPlayer()
  if (!player) {
    return null
  }
  const { playerState, resetPlayer, stopPlayer } = player

  const [type, setType] = useState('google')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [picture, setPicture] = useState('')

  const {
    languageCode,
    setLanguageCode,
    gender,
    setGender,
    voiceName,
    setVoiceName,
    speakingRate,
    setSpeakingRate,
    pitch,
    setPitch,
    textToSpeechParams,
    languageCodeOptions,
    genderOptions,
    voiceNameOptions
  } = useTextToSpeech()

  const fileInput = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<File[]>([])
  const [uris, setUris] = useState<string[]>([])
  const [playingFile, setPlayingFile] = useState<File | undefined>()
  const [stability, setStability] = useState(75)
  const [similarityBoost, setSimilarityBoost] = useState(75)
  const [modelId, setModelId] = useState('eleven_multilingual_v2')
  const [confirmRights, setConfirmRights] = useState(false)

  useEffect(() => {
    audio.onended = () => setPlayingFile(undefined)
  }, [])

  const handleFileChanged = async () => {
    if (fileInput.current?.files) {
      const file = fileInput.current.files[0]
      if (!files.some((f) => f.name === file.name)) {
        const uri = await uploadFile(file)
        setFiles((files) => [...files, file])
        setUris((uris) => [...uris, uri])
      }
      fileInput.current.value = ''
    }
  }

  const deleteFile = (file: File) => {
    const index = files.findIndex((f) => f === file)
    if (index >= 0) {
      setFiles(files.filter((_, i) => i !== index))
      setUris(uris.filter((_, i) => i !== index))
    }
  }

  const playFile = (file: File) => {
    setPlayingFile(file)
    audio.src = URL.createObjectURL(file)
    audio.play()
  }

  const onBack = () => goBack('/ai-voices')

  const handleSubmit = async () => {
    if (type === 'google') {
      if (await createAIVoice({ type, name, description, picture, textToSpeechParams })) {
        onBack()
      }
    }
    if (type === 'elevenlabs') {
      if (
        await createAIVoice({
          type,
          name,
          description,
          picture,
          files: uris,
          stability: stability / 100.0,
          similarityBoost: similarityBoost / 100.0,
          modelId
        })
      ) {
        onBack()
      }
    }
  }

  return (
    <Page title="Create AI voice" onBack={onBack}>
      <Form onSubmit={handleSubmit} maxWidth="500px">
        <Avatar
          actionIcon={EditIcon}
          onActionClick={() => routeTo('create-ai-voice/picture')}
          size={150}
          src={picture}
          name={name}
        />
        <SpacerVertical />
        <NativeSelect
          width="100%"
          label="Type"
          value={type}
          onChange={setType}
          options={[
            { label: 'Google', value: 'google' },
            { label: 'ElevenLabs', value: 'elevenlabs' }
          ]}
        />
        <SpacerVertical />
        <TextField width="100%" label="Name" value={name} onInput={setName} />
        <SpacerVertical />
        <TextArea width="100%" label="Description" value={description} onInput={setDescription} />
        <SpacerVertical />
        {type === 'elevenlabs' && (
          <>
            <NativeSelect
              width="100%"
              label="Model"
              value={modelId}
              onChange={setModelId}
              options={[
                { label: 'Eleven Multilingual v2', value: 'eleven_multilingual_v2' },
                { label: 'Eleven Multilingual v1', value: 'eleven_multilingual_v1' },
                { label: 'Eleven English v1', value: 'eleven_monolingual_v1' }
              ]}
            />
            <SpacerVertical />
            <Button
              outlined
              onClick={() => fileInput.current!.click()}
              disabled={files.length === 5}
            >
              Select audio file
            </Button>
            <input
              multiple
              type="file"
              ref={fileInput}
              accept="audio/*"
              onChange={handleFileChanged}
              style={{ display: 'none' }}
            />
            <SpacerVertical />
            <Block color="var(--on-surface-light)">
              Please select up to 5 audio files. Sample quality is more important than quantity.
              Noisy samples may give bad results. Providing more than 5 minutes of audio in total
              brings little improvement.
            </Block>
            <SpacerVertical />
            <List>
              {files.map((file) => {
                const isPlaying = file === playingFile
                return (
                  <ListItem
                    text={
                      <Row alignItems="center" gap="5px">
                        <Block flex="1" overflow="hidden" textOverflow="ellipsis">
                          {file.name}
                        </Block>
                        {isPlaying && (
                          <IconButton
                            icon={StopIcon}
                            onClick={() => {
                              audio.pause()
                              setPlayingFile(undefined)
                            }}
                          />
                        )}
                        {!isPlaying && (
                          <IconButton icon={PlayIcon} onClick={() => playFile(file)} />
                        )}
                        <IconButton icon={DeleteIcon} onClick={() => deleteFile(file)} />
                      </Row>
                    }
                  />
                )
              })}
            </List>
            <SpacerVertical />
            <Row alignItems={isNarrow ? '' : 'center'} flexDirection={isNarrow ? 'column' : 'row'}>
              <Block flex="1">
                <Row alignItems="center" justifyContent="space-between">
                  <Block>Stability {stability.toFixed(0)}%</Block>
                  <Button onClick={() => setStability(75)}>Reset</Button>
                </Row>
                <MinMaxSlider
                  width="100%"
                  value={stability}
                  minValue={0}
                  maxValue={100}
                  middleValue={50}
                  onChange={setStability}
                />
              </Block>
              {isNarrow ? <SpacerVertical /> : <SpacerHorizontal />}
              <Block flex="1">
                <Row alignItems="center" justifyContent="space-between">
                  <Block>Similarity {similarityBoost.toFixed(0)}%</Block>
                  <Button onClick={() => setSimilarityBoost(75)}>Reset</Button>
                </Row>
                <MinMaxSlider
                  width="100%"
                  value={similarityBoost}
                  minValue={0}
                  maxValue={100}
                  onChange={setSimilarityBoost}
                />
              </Block>
            </Row>
            <SpacerVertical />
            <Checkbox
              label="I hereby confirm that I have all necessary rights or consents to upload and clone these voice samples and that I will not use the platform-generated content for any illegal, fraudulent, or harmful purpose."
              checked={confirmRights}
              onChange={setConfirmRights}
            />
            <SpacerVertical />
          </>
        )}
        {type === 'google' && (
          <>
            <Row alignItems="center">
              <NativeSelect
                flex="1"
                label="Language"
                options={languageCodeOptions}
                value={languageCode}
                onChange={setLanguageCode}
              />
              <SpacerHorizontal />
              <NativeSelect
                flex="1"
                label="Gender"
                options={genderOptions}
                value={gender}
                onChange={setGender}
              />
            </Row>
            <SpacerVertical />
            <NativeSelect
              width="100%"
              label="Voice name"
              options={voiceNameOptions}
              value={voiceName}
              onChange={setVoiceName}
            />
            <SpacerVertical />
            <Row alignItems={isNarrow ? '' : 'center'} flexDirection={isNarrow ? 'column' : 'row'}>
              <Block flex="1">
                <Row alignItems="center" justifyContent="space-between">
                  <Block>Speaking rate {speakingRate.toFixed(2)}</Block>
                  <Button onClick={() => setSpeakingRate(1)}>Reset</Button>
                </Row>
                <MinMaxSlider
                  width="100%"
                  value={speakingRate}
                  minValue={0.25}
                  maxValue={4}
                  middleValue={1}
                  onChange={setSpeakingRate}
                />
              </Block>
              {isNarrow ? <SpacerVertical /> : <SpacerHorizontal />}
              <Block flex="1">
                <Row alignItems="center" justifyContent="space-between">
                  <Block>Pitch {pitch.toFixed(2)}</Block>
                  <Button onClick={() => setPitch(0)}>Reset</Button>
                </Row>
                <MinMaxSlider
                  width="100%"
                  value={pitch}
                  minValue={-20}
                  maxValue={20}
                  onChange={setPitch}
                />
              </Block>
            </Row>
          </>
        )}
        <SpacerVertical />
        <Row alignItems="center">
          <Button type="submit" contained loading={isCreatingAIVoice}>
            Create
          </Button>
          <SpacerHorizontal />
          <Button onClick={onBack}>Cancel</Button>
          {type === 'google' && (
            <>
              <Block flex="1" />
              <Button
                icon={playerState.value.isPlaying ? StopIcon : PlayIcon}
                disabled={!name || !description}
                onClick={() => {
                  if (playerState.value.isPlaying) {
                    stopPlayer()
                    resetPlayer()
                  } else {
                    speak({
                      player,
                      text: `My name is ${name}. ${description}`,
                      type: 'google',
                      extraParams: textToSpeechParams
                    })
                  }
                }}
              >
                Play sample
              </Button>
            </>
          )}
        </Row>
      </Form>

      <Route
        path="/create-ai-voice/picture"
        render={() => (
          <PictureDialog
            title="AI voice picture"
            url={picture}
            name={name}
            onUpdate={(url: string) => {
              setPicture(url)
              return true
            }}
            onRemove={() => {
              setPicture('')
              return true
            }}
            onClose={() => goBack('/create-ai-voice')}
          />
        )}
      />
    </Page>
  )
}

export default CreateAiVoice
