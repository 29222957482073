import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { format, formatISO, roundToNearestMinutes, setHours, setMinutes } from 'date-fns'
import { NativeSelect, Select, SpacerHorizontal, TextField } from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'
import { ErrorMessage } from './ErrorMessage'
import { formatDate, formatTime, formatTimeZone, getTimezoneOffset } from 'lib'

type Props = {
  value?: Date | null
  onChange: (newValue: Date) => void
  errorText?: string
  readonly?: boolean
  props?: any
}

let options: { label: string; value: string }[] = []

const minutes = ['00', '15', '30', '45']
for (let i = 0; i < 24; i++) {
  for (let j = 0; j < minutes.length; j++) {
    const value = `${String(i).padStart(2, '0')}:${minutes[j]}`
    options.push({
      value,
      label: value
    })
  }
}

const getDate = (date: string, time: string) => {
  let newDate = new Date(date) // UTC date

  // Adjust date (set in UTC) regarding current time zone offset
  const timeZoneOffset = newDate.getTimezoneOffset()
  newDate.setMinutes(timeZoneOffset)

  const [hours, minutes] = time.split(':').map((v) => +v)
  newDate = setHours(newDate, hours)
  newDate = setMinutes(newDate, minutes)

  return newDate
}

const minDate = new Date()
minDate.setFullYear(minDate.getFullYear() - 10)
const maxDate = new Date()
maxDate.setFullYear(maxDate.getFullYear() + 10)

export function DateAndTimeInput({ value, onChange, errorText, readonly = false, props }: Props) {
  if (value && isNaN(value.getTime())) {
    throw new Error(`Invalid date/time ${value}`)
  }

  const [date, setDate] = useState(formatISO(value ?? new Date(), { representation: 'date' }))
  const [time, setTime] = useState(
    format(roundToNearestMinutes(value ?? new Date(), { nearestTo: 15 }), 'HH:mm')
  )
  const [dateError, setDateError] = useState('')

  useEffect(() => {
    if (value) {
      setDate(formatISO(value, { representation: 'date' }))
      setTime(format(roundToNearestMinutes(value, { nearestTo: 15 }), 'HH:mm'))
    }
  }, [value])

  useEffect(() => {
    // Skip calling onChange if no date is set
    // TODO: May do this later on. And handle date=undefined.
    if (!date) {
      return
    }

    let newDate = getDate(date, time)

    onChange(newDate)
  }, [date, time])

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

  let timeLabel = 'Time'
  try {
    timeLabel = `Time (${formatTimeZone(tz, getDate(date, time))})`
  } catch (err) {
    // NOTE: Ignore
    //
    // It is possible to use the keyboard in the unput type="date" to
    // select an invalid date – eg 2024-02-31. When an invalid date is
    // selected formatTimeZone throws an error
  }

  return (
    <Block props={props}>
      <Row alignItems="flex-start" justifyContent="flex-start">
        <TextField
          readonly={readonly}
          type="date"
          autocomplete="off"
          label="Date"
          value={date}
          min={formatISO(minDate, { representation: 'date' })}
          max={formatISO(maxDate, { representation: 'date' })}
          onInput={(value: string) => {
            if (value) {
              setDateError('')
            }
            // Check if date is valid
            const date = getDate(value, time)
            if (date < minDate || date > maxDate) {
              //setDateError('Please enter a valid date')
              return
            }

            setDate(value)
          }}
          onBlur={() => {
            if (!date) {
              setDateError('Please enter a valid date')
            }
          }}
          errorText={dateError}
          required
        />
        <SpacerHorizontal />
        {readonly && <TextField readonly type="time" label={timeLabel} value={time} />}
        {!readonly && (
          <NativeSelect
            label={timeLabel}
            value={time}
            options={options}
            onChange={setTime}
            width="120px"
          />
        )}
        <Block flex="1" />
      </Row>
      {errorText && <ErrorMessage>{errorText}</ErrorMessage>}
    </Block>
  )
}
