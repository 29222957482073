import { DataTable } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'

export const ScrollableDataTable = (props: any) => {
  if (props.loading || (props.body && props.body.length > 0)) {
    return (
      <Block position="absolute" inset={0}>
        <DataTable minWidth="500px" maxWidth="100%" height="100%" {...props} />
      </Block>
    )
  }
  if (!props.loading && props.emptyText) {
    return <Block color="var(--on-surface-light)">{props.emptyText || 'No matches found'}</Block>
  }
  return null
}
