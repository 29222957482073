import {
  ProgressCircular,
  Ripples,
  StopIcon,
  Tooltip,
  useKeyPressedHighlight
} from '@sodra/bongo-ui'
import { useMatchMedia } from 'jsxstyle'
import { Block, InlineBlock } from 'jsxstyle/preact'
import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'

type RecordButtonProps = {
  name?: string
  disabled?: boolean
  loading?: boolean
  tooltipText?: string
  state?: RecordingState | null
  size?: number
  showRec?: boolean
  onClick: () => void
  onFocus?: () => void
  onBlur?: () => void
  focusable?: boolean
}

export const RecordButton = ({
  name = 'button',
  loading,
  tooltipText,
  size = 60,
  state,
  disabled,
  showRec = false,
  onClick,
  onFocus,
  onBlur,
  focusable = true
}: RecordButtonProps) => {
  const iconButton = useRef()

  useEffect(() => {
    if (disabled) {
      setFocus(false)
      setHover(false)
    }
  }, [disabled])

  const borderRadius = '50%'
  const color = 'var(--on-surface)'
  const highlightColor = '#fff'

  const [focus, setFocus] = useState<boolean>(false)
  const [hover, setHover] = useState<boolean>(false)
  const [keyPressedHighlights, addKeyPressedHighlight] = useKeyPressedHighlight({
    color: highlightColor,
    borderRadius
  })

  const supportsHover = useMatchMedia('(hover: hover)')
  const clickable = state && !disabled && !loading

  const handleClick = (e: any) => {
    e.stopPropagation()
    onClick()
    setFocus(false)
  }

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault()
      e.stopPropagation()
      addKeyPressedHighlight()
      onClick()
    }
  }

  const handleMouseOver = () => setHover(true)

  const handleMouseOut = () => setHover(false)

  const handleFocus = () => {
    setFocus(true)
    if (onFocus) {
      onFocus()
    }
  }

  const handleBlur = () => {
    setFocus(false)
    if (onBlur) {
      onBlur()
    }
  }

  return (
    <InlineBlock
      class="bui-show-keyboard-focus"
      component="button"
      height={`${size}px`}
      width={`${size}px`}
      padding="0"
      borderRadius="50%"
      background="none"
      border="none"
      position="relative"
      outline="none"
      userSelect="none"
      WebkitUserSelect="none"
      WebkitTapHighlightColor="rgba(0, 0, 0, 0)"
      opacity={clickable ? '1' : '0.5'}
      color={color}
      cursor={clickable ? 'pointer' : undefined}
      props={{
        ref: iconButton,
        name,
        'aria-label': name,
        type: 'button',
        onClick: clickable ? handleClick : null,
        onKeyDown: clickable ? handleKeyDown : null,
        onMouseOver: clickable && supportsHover ? handleMouseOver : null,
        onMouseOut: clickable && supportsHover ? handleMouseOut : null,
        onFocus: clickable ? handleFocus : null,
        onBlur: clickable ? handleBlur : null,
        tabIndex: focusable && clickable ? 0 : -1
      }}
    >
      <Block
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        display="grid"
        placeItems="center"
      >
        <Block
          grid-column="1"
          grid-row="1"
          left="50%"
          borderRadius="50%"
          background="var(--error)"
          width={state === 'recording' ? `${size * 0.9}px` : `${size * 0.65}px`}
          height={state === 'recording' ? `${size * 0.9}px` : `${size * 0.65}px`}
          transition="width .18s cubic-bezier(0, 0, .2, 1), height .18s cubic-bezier(0, 0, .2, 1)"
          willChange="width, height"
        />
        <Block
          grid-column="1"
          grid-row="1"
          border={`${size * 0.08}px solid white`}
          borderRadius="50%"
          width={state === 'recording' ? `${size * 1}px` : `${size * 1}px`}
          height={state === 'recording' ? `${size * 1}px` : `${size * 1}px`}
        />
        {showRec && (
          <Block
            transition="opacity .18s cubic-bezier(0, 0, .2, 1)"
            willChange="opacity"
            opacity={state !== 'recording' ? 1 : 0}
            fontWeight={500}
            grid-column="1"
            grid-row="1"
          >
            REC
          </Block>
        )}
        <Block
          transition="opacity .18s cubic-bezier(0, 0, .2, 1)"
          willChange="opacity"
          opacity={state === 'recording' ? 1 : 0}
          grid-column="1"
          grid-row="1"
        >
          <StopIcon fill="white" size={size * 0.6} />
        </Block>
      </Block>
      <Block
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        transition="opacity .18s cubic-bezier(0, 0, .2, 1)"
        willChange="opacity"
        opacity={state === 'recording' ? 1 : 0}
      ></Block>
      {keyPressedHighlights}
      <Ripples borderRadius={borderRadius} color={highlightColor} disabled={!clickable} />
      {tooltipText && (
        <Tooltip visible={focus || hover} generator={iconButton} text={tooltipText} />
      )}
    </InlineBlock>
  )
}
