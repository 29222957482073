import { useEffect, useState } from 'preact/hooks'
import { Dialog, SearchListItem, SearchList } from '@sodra/bongo-ui'
import { useMatchMedia } from 'jsxstyle'

import { get } from '../../api'
import { TimeZone } from '../../time-zone/types'
import { formatTimeZone } from '../../time-zone/format-time-zone'
import { timeStamp } from 'console'

type Props = {
  title?: string
  onSelect: (timeZone: string) => void
  onClose: () => void
}

export const TimeZoneSelectDialog = ({ title = 'Select time zone', onSelect, onClose }: Props) => {
  const isNarrow = useMatchMedia('(max-width: 600px)')

  const [hideDialog, setHideDialog] = useState(false)

  const [query, setQuery] = useState<string | undefined>(undefined)
  const [isFetchingTimeZones, setIsFetchingTimeZones] = useState(true)

  const [timeZones, setTimeZones] = useState<TimeZone[]>()

  const fetchTimeZones = async (query?: string) => {
    setIsFetchingTimeZones(true)
    const params = { query }
    let { data: timeZones } = await get(`/time-zones`, params)

    // Add suggested time zone if no query has been made
    if (!query) {
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

      const suggestedTimeZone = timeZones?.find(
        (timeZone: TimeZone) => timeZone.name === browserTimeZone
      )

      if (suggestedTimeZone) {
        timeZones = [suggestedTimeZone, ...timeZones]
      }
    }

    setTimeZones(timeZones)
    setIsFetchingTimeZones(false)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchTimeZones(query)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [query])

  const hide = () => setHideDialog(true)

  let actions = [{ text: 'Cancel', onClick: hide }]

  return (
    <Dialog
      title={title}
      onClose={onClose}
      actions={actions}
      large
      full={isNarrow}
      hideDialog={hideDialog}
      dismissable={false}
    >
      <SearchList
        label="Time zone"
        placeholder="Enter time zone to search for"
        width="100%"
        //query={query}
        onQueryUpdate={setQuery}
        onSelect={onSelect}
        loading={isFetchingTimeZones}
      >
        {timeZones?.map((timeZone) => {
          const text = formatTimeZone(timeZone)
          const metaText = `GMT${timeZone.offset}`
          return (
            <SearchListItem
              value={timeZone.name}
              text={text}
              secondaryText={`${timeZone.countryName} · ${timeZone.continentName}`}
              metaText={metaText}
              onClick={() => onSelect(timeZone.name)}
            />
          )
        })}
      </SearchList>
    </Dialog>
  )
}
