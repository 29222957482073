import { h } from 'preact'
import { useState } from 'preact/hooks'
import { routeTo } from '@sodra/prutt'
import { AddIcon, BasicDialog, SelectChip } from '@sodra/bongo-ui'

import { useScene } from '../../use-scene'
import { addLinesToScene, showSnackbar } from '../../actions'

import Avatar from '../Avatar'
import SelectScene from '../SelectScene'
import { useStore } from '../../store'

type Props = {
  onClose: () => void
  onSuccess: () => void
  lineIds?: string[]
}

export const AddToScene = ({ onClose, onSuccess, lineIds = [] }: Props) => {
  const { isAddingLinesToScene } = useStore('isAddingLinesToScene')
  const { sceneId, scene, setScene } = useScene()
  const [selectSceneVisible, setSelectSceneVisible] = useState(false)

  if (lineIds.length === 0) {
    onClose()
  }

  const handleSubmit = async () => {
    if (await addLinesToScene(sceneId, lineIds)) {
      showSnackbar('Scene updated', {
        text: 'Open',
        onClick: () => routeTo(`/scene/${sceneId}`)
      })
      if (onSuccess) {
        onSuccess()
      }
      if (onClose) {
        onClose()
      }
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      title="Add lines to scene"
      primaryActionText="Add lines"
      primaryActionLoading={isAddingLinesToScene}
      primaryActionDisabled={!sceneId}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      {scene && (
        <SelectChip
          visual={<Avatar size={30} src={scene.picture} name={scene.name} />}
          value={scene.name}
          onClick={() => setSelectSceneVisible(true)}
          onClear={() => setScene()}
        />
      )}
      {!scene && <SelectChip label="Select Scene" onClick={() => setSelectSceneVisible(true)} />}
      {selectSceneVisible && (
        <SelectScene
          onClose={() => setSelectSceneVisible(false)}
          onSelect={setScene}
          canCreate={true}
        />
      )}
    </BasicDialog>
  )
}

export default AddToScene
