import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Button, Checkbox, Form, H1, P, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'
import { parse } from 'qs'

import { completeSignUp, validateSignUpToken } from '../actions'

import { routeTo } from '@sodra/prutt'
import LandingPage from './LandingPage'
import { useStore } from '../store'
import Spinner from './Spinner'

type SSO = {
  name: string
  allowPasswords: boolean
}

const CompleteSignUp = () => {
  const { isValidatingSignUpToken, isCompletingSignUp } = useStore(
    'isValidatingSignUpToken',
    'isCompletingSignUp'
  )
  const [sso, setSSO] = useState<SSO>()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [invalidToken, setInvalidToken] = useState(false)

  const { token, redirectTo = '/?welcome' } = parse(location.search.slice(1))

  const includePassword = !sso || sso?.allowPasswords

  useEffect(() => {
    if (token) {
      try {
        setInvalidToken(false)
        validateSignUpToken(token).then((data) => {
          setEmail(data.email)
          if (data.firstName) {
            setFirstName(data.firstName)
          }
          if (data.lastName) {
            setLastName(data.lastName)
          }
          if (data.sso) {
            setSSO(data.sso)
          }
        })
      } catch (error) {
        console.log('Unable to validate sign up token')
        setInvalidToken(true)
      }
    }
  }, [token])

  const handleSubmit = async () => {
    let errorId
    if (!sso && password.trim() === '') {
      setPasswordError('Please choose a password')
      errorId = 'password'
    }
    if (!errorId) {
      if (await completeSignUp({ password, token })) {
        routeTo(redirectTo, true)
      }
    }
  }

  return (
    <LandingPage>
      <H1 size="6">Complete sign up</H1>
      <SpacerVertical />
      {(!token || invalidToken) && <Block>Missing token :-(</Block>}
      {isValidatingSignUpToken && <Spinner />}
      {email && (
        <Form onSubmit={handleSubmit}>
          {!sso && (
            <Fragment>
              <Block>Please enter a password to complete the sign up.</Block>
              <SpacerVertical large />
            </Fragment>
          )}
          {firstName && (
            <TextField
              width="100%"
              type="text"
              label="First name"
              value={firstName}
              readonly
              autocomplete="off"
            />
          )}
          {lastName && (
            <TextField
              width="100%"
              type="text"
              label="Last name"
              value={lastName}
              readonly
              autocomplete="off"
            />
          )}
          <TextField
            width="100%"
            type="text"
            label="Email"
            value={email}
            readonly
            autocomplete="email"
          />
          {includePassword && (
            <Fragment>
              <TextField
                width="100%"
                type={!showPassword ? 'password' : 'text'}
                label={sso ? 'Password (optional)' : 'Password'}
                value={password}
                onInput={setPassword}
                errorText={passwordError}
                autocomplete="new-password"
                props={{
                  id: 'password'
                }}
              />
              <SpacerVertical small />
              <Checkbox
                label="Show password"
                checked={showPassword}
                onChange={setShowPassword}
                width="100%"
              />
            </Fragment>
          )}
          <SpacerVertical />
          <P>
            By signing up you agree to the Speechless{' '}
            <a
              target="_blank"
              href="https://drive.google.com/file/d/1ev23IgNfPYeorSRqyTG0izEa_cyuF9GI/view"
            >
              privacy policy
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              href="https://drive.google.com/file/d/1qmMY30reHNiNZfRasZgAjz-bvMWdcj2h/view"
            >
              client terms and conditions
            </a>
            .
          </P>
          <SpacerVertical large />
          <Button contained type="submit" loading={isCompletingSignUp} onClick={handleSubmit}>
            Sign up
          </Button>
        </Form>
      )}
    </LandingPage>
  )
}

export default CompleteSignUp
