import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Row, Block } from 'jsxstyle/preact'
import dateFormat from 'dateformat'
import {
  Button,
  ChatBubbleIcon,
  CheckmarkIcon,
  ContentCopyIcon,
  DropdownMenu,
  ErrorIcon,
  ExpandMoreIcon,
  IconButton,
  PlayIcon,
  ProgressCircular,
  SpacerHorizontal,
  StopIcon,
  Switch,
  WarningIcon
} from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

import { formatDuration } from '../../format-duration'
import { setError, showSnackbar } from '../../actions'

import Avatar from '../Avatar'

import Waveform from './Waveform'
import {
  Character,
  LineAttribute,
  LineAttributeValue,
  Event,
  Scene,
  Take as TakeType,
  Line
} from '../../types'
import { useStore } from '../../store'
import { Label } from '../Line/Label'
import { ScrollableDataTable } from '../ScrollableDataTable'
import { BuiClickEvent, MenuPosition } from '../use-popup-menu'
import { formatLanguage } from '../../format-language'
import { isTakeOutdated } from './is-take-outdated'
import { TakeUploader } from './TakeUploader'
import { AudioPlayer } from 'lib/src/hooks/use-audio-player'
import { blurActiveElement } from 'line-recorder/src/components/LineRecorder/blur-active-element'

const formatNotes = (notes: string) => {
  return notes
    .trim()
    .split('\n')
    .map((str) => {
      return (
        str || (
          <>
            <br />
            <br />
          </>
        )
      )
    })
}

type TakeProps = {
  take: TakeType
  player: AudioPlayer
}

const Take = ({ take, player }: TakeProps) => {
  if (take.error) {
    return (
      <IconButton
        icon={ErrorIcon}
        color="var(--error)"
        onClick={() => setError({ message: take.error })}
        flexShrink="0"
      />
    )
  }
  let uri = take.processedUri || take.uri

  const { playerState, initPlayer, play, stopPlayer } = player

  if (uri) {
    const isPlayingCurrentTrack =
      playerState.value.currentTrackUri === uri && playerState.value.isPlaying

    const togglePlay = () => {
      if (isPlayingCurrentTrack) {
        stopPlayer()
      } else {
        initPlayer({
          audioTracks: [
            {
              uri
            }
          ]
        })
        play({ audioTrackUri: uri })
      }
    }

    return (
      <IconButton
        icon={isPlayingCurrentTrack ? StopIcon : PlayIcon}
        onClick={togglePlay}
        flexShrink="0"
      />
    )
  }
  return (
    <Row width="40px" height="40px" alignItems="center" justifyContent="center">
      <ProgressCircular size="20" />
    </Row>
  )
}

type TakeWaveformProps = {
  take: TakeType
  clickToPlay?: boolean
  player: AudioPlayer
}

const TakeWaveform = ({ take, player }: TakeWaveformProps) => {
  const { playerState, initPlayer, play } = player
  const isCurrentTrackPlaying =
    playerState.value.isPlaying && playerState.value.currentTrackUri === take.uri

  let uri = take.processedUri || take.uri

  return (
    <Waveform
      uri={uri}
      width={120}
      height={20}
      duration={isCurrentTrackPlaying ? playerState.value.duration : 0}
      currentTime={isCurrentTrackPlaying ? playerState.value.currentTime : 0}
      onClick={(_, startTimeFraction?: number) => {
        if (playerState.value.currentTrackUri !== take.uri) {
          initPlayer({ audioTracks: [{ uri }] })
        }
        play({ audioTrackUri: uri, startTimeFraction })
      }}
    />
  )
}

type LinesTableProps = {
  loading?: boolean
  lines: Line[]
  total: number
  selectedRows: number[]
  labels?: string[]
  setLabels: (labels?: string[]) => void
  onSelectedRowsChange: (selectedRows: number[]) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  page: number
  setPage: (page: number) => void
  orderBy: string
  setOrderBy: (orderBy: string) => void
  sortOrder: string
  setSortOrder: (sortOrder: 'asc' | 'desc') => void
  character?: Character
  setCharacter: (character?: Character) => void
  event?: Event
  setEvent: (event?: Event) => void
  scene?: Scene
  setScene: (scene?: Scene) => void
  columns: string[]
  onSelectedTakeChange: (lineId: string, takeId?: string) => void
  onShowOutdatedTake?: ({
    take,
    line,
    language
  }: {
    take: TakeType
    line: Line
    language: string
  }) => void
  onShowNotes?: (lineId: string) => void
  customEmptyText?: string
  showNotesAndWarnings?: boolean
  player: AudioPlayer
}

const getLineAttributeHeaderStyle = (attribute: LineAttribute) => {
  const width = {
    number: 100,
    boolean: 100,
    date: 100,
    string: 200,
    text: 200,
    url: 200
  }
  const align = {
    number: 'right',
    boolean: 'center',
    date: 'left',
    string: 'left',
    text: 'left',
    url: 'left'
  }
  const wrap = {
    number: false,
    boolean: false,
    date: false,
    string: true,
    text: true,
    url: true
  }

  return {
    textAlign: align[attribute.type] ?? 'left',
    whiteSpace: !wrap[attribute.type] ? 'nowrap' : undefined,
    width: `${width[attribute.type] ?? 100}px`
  }
}

const formatLineAttributeValue = (attribute?: LineAttributeValue) => {
  if (!attribute) {
    return ''
  }
  if (attribute.type === 'date') {
    return attribute.value ? dateFormat(attribute.value as string, 'yyyy-mm-dd') : ''
  }
  if (attribute.type === 'boolean') {
    return attribute.value ? <CheckmarkIcon fill="var(--ok)" /> : ''
  }
  if (attribute.type === 'url') {
    return attribute.value ? (
      <Button
        small
        link={{
          href: attribute.value,
          target: '_blank'
        }}
        style={{
          textDecoration: 'underline'
        }}
        onFocus={blurActiveElement}
        focusable={true}
      >
        {attribute.value}
      </Button>
    ) : (
      ''
    )
  }
  return attribute.value || ''
}

export const LinesTable = ({
  loading,
  lines,
  total,
  selectedRows,
  onSelectedRowsChange,
  labels: selectedLabels,
  setLabels,
  pageSize,
  page,
  setPage,
  setPageSize,
  orderBy,
  setOrderBy,
  sortOrder,
  setSortOrder,
  setCharacter,
  setEvent,
  setScene,
  columns,
  onSelectedTakeChange,
  onShowOutdatedTake,
  onShowNotes,
  customEmptyText,
  showNotesAndWarnings,
  player,
  ...props
}: LinesTableProps) => {
  const {
    lineAttributes = [],
    currentLanguage,
    currentGame
  } = useStore('lineAttributes', 'currentLanguage', 'currentGame')

  const [isHidingMenu, setIsHidingMenu] = useState<boolean>(false)
  const [menuPosition, setMenuPosition] = useState<MenuPosition>()
  const [menuLine, setMenuLine] = useState<Line | undefined>()

  const [hoverFilenameLineId, setHoverFilenameLineId] = useState<string | undefined>(undefined)
  const [hoverPrimaryTranslationLineId, setHoverPrimaryTranslationLineId] = useState<
    string | undefined
  >(undefined)
  const [hoverSecondaryTranslationLineId, setHoverSecondaryTranslationLineId] = useState<
    string | undefined
  >(undefined)

  const secondaryLanguage =
    currentLanguage !== currentGame?.primaryLanguage ? currentLanguage : undefined

  const showMenu = (e: BuiClickEvent, line: Line) => {
    if (e.type === 'keyboard') {
      if (e.target) {
        setMenuPosition({ positionElement: e.target })
      }
    } else {
      setMenuPosition({ position: { x: e.x, y: e.y } })
    }
    setMenuLine(line)
  }

  useEffect(() => {
    if (menuLine) {
      setMenuLine(lines.find((line) => line.id === menuLine.id))
    }
  }, [lines])

  const hideMenu = () => {
    setMenuPosition(undefined)
    setMenuLine(undefined)
  }

  let hasOutdatedTakes = false

  const header = []

  if (showNotesAndWarnings) {
    hasOutdatedTakes = lines.some((line) => {
      const primaryTranslation = line.translations[currentGame!.primaryLanguage]
      const secondaryTranslation = secondaryLanguage
        ? line.translations[secondaryLanguage]
        : undefined
      const currentTranslation = secondaryLanguage ? secondaryTranslation : primaryTranslation

      const selectedTake = currentTranslation?.selectedTake
      if (selectedTake) {
        const isSelectedTakeOutdated = isTakeOutdated(selectedTake, line, currentLanguage)
        return isSelectedTakeOutdated && !selectedTake.suppressWarnings
      }
    })

    if (hasOutdatedTakes) {
      header.push({ label: '', style: { width: '80px' } }) // Notes and Warnings
    } else {
      header.push({ label: '', style: { width: '40px' } }) // Notes
    }
  }

  if (columns.includes('selectedTake')) {
    header.push({ label: 'Selected take', style: { whiteSpace: 'nowrap', width: '280px' } })
  }
  if (columns.includes('filename')) {
    header.push({
      value: 'filename',
      sortable: true,
      label: 'Filename',
      style: { width: '250px', textAlign: 'left' }
    })
  }
  if (columns.includes('line')) {
    header.push({
      value: 'line',
      sortable: false,
      label: secondaryLanguage ? (
        <Block whiteSpace="nowrap">Line ({formatLanguage(currentGame!.primaryLanguage)})</Block>
      ) : (
        'Line'
      ),
      style: { width: '250px', textAlign: 'left' }
    })
    if (secondaryLanguage) {
      header.push({
        value: 'secondaryLine',
        sortable: false,
        label: <Block whiteSpace="nowrap">Line ({formatLanguage(secondaryLanguage)})</Block>,
        style: { width: '250px', textAlign: 'left' }
      })
      if (columns.includes('autoTranslated')) {
        header.push({
          value: 'autoTranslated',
          sortable: true,
          label: <Block whiteSpace="nowrap">Translation type</Block>,
          style: { width: '75px', textAlign: 'center' }
        })
      }
    }
  }
  if (columns.includes('description')) {
    header.push({
      value: 'description',
      sortable: false,
      label: 'Acting notes',
      style: { width: '250px', textAlign: 'left' }
    })
  }
  if (columns.includes('labels')) {
    header.push({
      value: 'labels',
      sortable: false,
      label: 'Labels',
      style: { width: '150px', textAlign: 'left' }
    })
  }
  if (columns.includes('character')) {
    header.push({
      value: 'characterId',
      sortable: true,
      label: 'Character',
      style: { width: '150px', textAlign: 'left' }
    })
  }
  if (columns.includes('event')) {
    header.push({
      value: 'eventId',
      sortable: true,
      label: 'Event',
      style: { width: '150px', textAlign: 'left' }
    })
  }
  if (columns.includes('scene')) {
    header.push({
      value: 'sceneId',
      sortable: true,
      label: 'Scene',
      style: { width: '250px', textAlign: 'left' }
    })
  }
  if (columns.includes('sceneSeqNumber')) {
    header.push({
      value: 'sceneSeqNumber',
      sortable: true,
      label: 'Scene order',
      style: { width: '150px', textAlign: 'left' }
    })
  }
  if (columns.includes('created')) {
    header.push({
      value: 'created',
      sortable: true,
      label: 'Created',
      style: { whiteSpace: 'nowrap', textAlign: 'left', width: '150px' }
    })
  }
  if (columns.includes('lastModified')) {
    header.push({
      value: 'lastModified',
      sortable: true,
      label: 'Last modified',
      style: { whiteSpace: 'nowrap', textAlign: 'left', width: '150px' }
    })
  }
  if (columns.includes('lineId')) {
    header.push({
      value: 'lineId',
      sortable: true,
      label: 'Speechless line ID',
      style: { whiteSpace: 'nowrap', textAlign: 'left', width: '300px' }
    })
  }
  if (currentGame?.useExternalLineId && columns.includes('externalLineId')) {
    header.push({
      value: 'externalLineId',
      sortable: true,
      label: 'External line ID',
      style: { whiteSpace: 'nowrap', textAlign: 'left', width: '300px' }
    })
  }
  for (let attribute of lineAttributes) {
    if (columns.includes(attribute.id)) {
      header.push({
        value: `${attribute.id}_value`,
        sortable: true,
        label: attribute.name,
        style: getLineAttributeHeaderStyle(attribute)
      })
    }
  }

  const copyToClipboard = (str: string) => {
    navigator.clipboard.writeText(str)
    showSnackbar('Copied to clipboard')
  }

  const body = lines.map((line) => {
    const { character, event, scene } = line

    const primaryTranslation = line.translations[currentGame!.primaryLanguage]
    const secondaryTranslation = secondaryLanguage
      ? line.translations[secondaryLanguage]
      : undefined
    const currentTranslation = secondaryLanguage ? secondaryTranslation : primaryTranslation

    const selectedTake = currentTranslation?.selectedTake

    const row = []

    let notesAndWarnings = []

    if (line.notes?.trim()) {
      notesAndWarnings.push(
        <IconButton
          small
          icon={ChatBubbleIcon}
          tooltipText={formatNotes(line.notes)}
          color="var(--accent)"
          flexShrink="0"
          onClick={() => {
            if (onShowNotes) {
              onShowNotes(line.id)
            }
          }}
        />
      )
    } else {
      notesAndWarnings.push(
        <IconButton
          small
          icon={ChatBubbleIcon}
          color="var(--on-surface-lighter)"
          flexShrink="0"
          onClick={() => {
            if (onShowNotes) {
              onShowNotes(line.id)
            }
          }}
        />
      )
    }

    if (selectedTake) {
      const isSelectedTakeOutdated = isTakeOutdated(selectedTake, line, currentLanguage)

      if (isSelectedTakeOutdated && !selectedTake.suppressWarnings) {
        notesAndWarnings.push(
          <IconButton
            small
            onClick={() => {
              if (onShowOutdatedTake) {
                onShowOutdatedTake({ take: selectedTake, line, language: currentLanguage })
              }
            }}
            tooltipText={`The line has changed since this take was ${
              selectedTake.aiVoice ? 'generated' : 'uploaded'
            }.`}
            icon={WarningIcon}
            color="var(--warning)"
            flexShrink="0"
          />
        )
      }
    }

    row.push(<Row alignItems="center">{notesAndWarnings}</Row>)

    if (columns.includes('selectedTake')) {
      if (selectedTake) {
        row.push(
          <Row alignItems="center" gap="5px">
            <Block color="var(--on-surface-light)" width="25px">
              {selectedTake.seqLanguage ? `${selectedTake.seqLanguage}.` : ''}
            </Block>
            <TakeUploader take={selectedTake} />
            <Take take={selectedTake} player={player} />
            <TakeWaveform take={selectedTake} player={player} />
            {currentTranslation?.numTakes !== undefined && currentTranslation.numTakes > 1 && (
              <IconButton
                icon={ExpandMoreIcon}
                onClick={(e: BuiClickEvent) => {
                  if (isHidingMenu) {
                    setIsHidingMenu(false)
                    return
                  }
                  showMenu(e, line)
                }}
                props={{
                  onMouseDown: () => setIsHidingMenu(!!(menuLine && menuPosition))
                }}
                flexShrink="0"
              />
            )}
          </Row>
        )
      } else {
        if (!currentTranslation?.numTakes) {
          row.push(<Block />)
        } else {
          row.push(
            <Row alignItems="center" gap="5px">
              <Block width="25px" />
              <Avatar size={24} />
              <IconButton icon={PlayIcon} disabled flexShrink="0" />
              <Block width="120px" color="var(--on-surface-lighter)">
                No selected take
              </Block>
              {currentTranslation?.numTakes !== undefined && currentTranslation.numTakes >= 1 && (
                <IconButton
                  icon={ExpandMoreIcon}
                  onClick={(e: BuiClickEvent) => {
                    if (isHidingMenu) {
                      setIsHidingMenu(false)
                      return
                    }
                    showMenu(e, line)
                  }}
                  props={{
                    onMouseDown: () => setIsHidingMenu(!!(menuLine && menuPosition))
                  }}
                  flexShrink="0"
                />
              )}
            </Row>
          )
        }
      }
    }

    if (columns.includes('filename')) {
      row.push(
        <Block
          color="var(--on-surface-light)"
          whiteSpace="nowrap"
          minWidth="200px"
          overflow="visible"
          props={{
            onMouseEnter: () => {
              setHoverFilenameLineId(line.id)
            },
            onMouseLeave: () => {
              setHoverFilenameLineId(undefined)
            }
          }}
        >
          <Row alignItems="center">
            <Block textOverflow="ellipsis" overflow="hidden">
              {line.filename}
            </Block>
            <Block flex="1" />
            <Block width="36px">
              <IconButton
                tooltipText="Copy filename"
                icon={() => <ContentCopyIcon size={18} />}
                fill="var(--accent)"
                onClick={() => {
                  if (line.filename) {
                    copyToClipboard(line.filename)
                  }
                }}
                style={{ visibility: hoverFilenameLineId !== line.id ? 'hidden' : undefined }}
              />
            </Block>
          </Row>
        </Block>
      )
    }

    if (columns.includes('line')) {
      row.push(
        <Block
          color="var(--on-surface-light)"
          maxWidth="500px"
          props={{
            onMouseEnter: () => {
              setHoverPrimaryTranslationLineId(line.id)
            },
            onMouseLeave: () => {
              setHoverPrimaryTranslationLineId(undefined)
            }
          }}
        >
          <Row alignItems="center">
            <Block>
              {primaryTranslation?.line && primaryTranslation.line.length > 150
                ? primaryTranslation.line.slice(0, 150) + '…'
                : primaryTranslation.line ?? ''}
            </Block>
            <Block flex="1" />
            <Block width="36px">
              <IconButton
                tooltipText="Copy text"
                icon={() => <ContentCopyIcon size={18} />}
                fill="var(--accent)"
                onClick={() => copyToClipboard(primaryTranslation.line)}
                style={{
                  visibility:
                    hoverPrimaryTranslationLineId !== line.id ||
                    primaryTranslation.line.length === 0
                      ? 'hidden'
                      : undefined
                }}
              />
            </Block>
          </Row>
        </Block>
      )
      if (secondaryLanguage) {
        row.push(
          <Block
            color="var(--on-surface-light)"
            maxWidth="500px"
            props={{
              onMouseEnter: () => {
                setHoverSecondaryTranslationLineId(line.id)
              },
              onMouseLeave: () => {
                setHoverSecondaryTranslationLineId(undefined)
              }
            }}
          >
            <Row alignItems="center">
              <Block>
                {secondaryTranslation?.line ? (
                  secondaryTranslation.line.length > 150 ? (
                    secondaryTranslation.line.slice(0, 150) + '…'
                  ) : (
                    secondaryTranslation.line
                  )
                ) : (
                  <Block color="var(--on-surface-lighter)">Not translated</Block>
                )}
              </Block>
              <Block flex="1" />
              <Block width="36px">
                <IconButton
                  tooltipText="Copy text"
                  icon={() => <ContentCopyIcon size={18} />}
                  fill="var(--accent)"
                  onClick={() => copyToClipboard(secondaryTranslation!.line)}
                  style={{
                    visibility:
                      hoverSecondaryTranslationLineId !== line.id ||
                      !secondaryTranslation?.line.length
                        ? 'hidden'
                        : undefined
                  }}
                />
              </Block>
            </Row>
          </Block>
        )
        if (columns.includes('autoTranslated')) {
          row.push(
            <Block color="var(--on-surface-light)">
              {!secondaryTranslation?.line
                ? ''
                : secondaryTranslation.autoTranslated
                ? 'Auto'
                : 'Manual'}
            </Block>
          )
        }
      }
    }

    if (columns.includes('description')) {
      row.push(
        <Block
          color="var(--on-surface-light)"
          maxWidth="300px"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {line.description}
        </Block>
      )
    }

    if (columns.includes('labels')) {
      row.push(
        <Row alignItems="center" flexWrap="wrap" gap="5px">
          {line.labels.map((label) => {
            const isActive = selectedLabels?.some((selectedLabel) => selectedLabel === label)
            return (
              <Label
                small
                label={label}
                active={isActive}
                onClick={() => {
                  const updatedLabels = isActive
                    ? selectedLabels?.filter((l) => l !== label)
                    : [...(selectedLabels ?? []), label]
                  setLabels(updatedLabels)
                }}
              />
            )
          })}
        </Row>
      )
    }

    if (columns.includes('character')) {
      const isActive = character?.id === props.character?.id
      row.push(
        <Button
          small
          onClick={() => (isActive ? setCharacter(undefined) : setCharacter(character))}
        >
          <Row
            alignItems="center"
            gap="5px"
            whiteSpace="nowrap"
            maxWidth="200px"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <Avatar size={20} src={character?.picture} name={character?.name} />
            {character?.name}
          </Row>
        </Button>
      )
    }
    if (columns.includes('event')) {
      if (event) {
        const isActive = event.id === props.event?.id
        row.push(
          <Button small onClick={() => (isActive ? setEvent(undefined) : setEvent(event))}>
            <Row
              alignItems="center"
              gap="5px"
              whiteSpace="nowrap"
              maxWidth="200px"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <Avatar size={20} src={event.picture} name={event.name} />
              {event.name}
            </Row>
          </Button>
        )
      } else {
        row.push(<Block></Block>)
      }
    }

    if (columns.includes('scene')) {
      if (scene) {
        const isActive = scene.id === props.scene?.id
        row.push(
          <Button small onClick={() => (isActive ? setScene(undefined) : setScene(scene))}>
            <Row
              alignItems="center"
              gap="5px"
              whiteSpace="nowrap"
              maxWidth="200px"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <Avatar size={20} src={scene.picture} name={scene.name} />
              {scene.name}
            </Row>
          </Button>
        )
      } else {
        row.push(<Block></Block>)
      }
    }

    if (columns.includes('sceneSeqNumber')) {
      row.push(<Block>{line.sceneSeqNumber !== undefined ? line.sceneSeqNumber : ''}</Block>)
    }

    if (columns.includes('created')) {
      row.push(
        <Block color="var(--on-surface-light)" whiteSpace="nowrap">
          {formatDuration(line.created)}
        </Block>
      )
    }
    if (columns.includes('lastModified')) {
      row.push(
        <Block color="var(--on-surface-light)" whiteSpace="nowrap">
          {formatDuration(line.lastModified)}
        </Block>
      )
    }

    if (columns.includes('lineId')) {
      row.push(
        <Block color="var(--on-surface-light)" whiteSpace="nowrap">
          {line.id}
        </Block>
      )
    }
    if (currentGame?.useExternalLineId && columns.includes('externalLineId')) {
      row.push(
        <Block color="var(--on-surface-light)" whiteSpace="nowrap">
          {line.externalLineId}
        </Block>
      )
    }

    for (let attribute of lineAttributes) {
      if (columns.includes(attribute.id)) {
        const columnValue = line.lineAttributes?.find((c) => c.id === attribute.id)
        row.push(
          <Block color="var(--on-surface-light)">{formatLineAttributeValue(columnValue)}</Block>
        )
      }
    }

    return row
  })

  const handleSort = (column: string, sortOrder: 'asc' | 'desc') => {
    setOrderBy(column)
    setSortOrder(sortOrder)
    setPage(0)
  }

  return (
    <>
      <ScrollableDataTable
        compact
        loading={loading}
        header={header}
        body={body}
        selectedRows={selectedRows}
        onSelectedRowsChange={onSelectedRowsChange}
        sortedColumn={orderBy}
        sortOrder={sortOrder}
        onSort={handleSort}
        onClick={(index: number) => routeTo(`/line/${lines[index].id}`)}
        pagination={{
          page,
          numRowsPerPage: pageSize,
          numRows: total,
          onPageChange: setPage,
          onNumRowsPerPageChange: setPageSize
        }}
        emptyText={customEmptyText ? customEmptyText : 'No lines found'}
        tableLayout="fixed"
      />
      {menuLine && menuPosition && (
        <DropdownMenu
          compact
          modal={true}
          maxWidth="500px"
          {...menuPosition}
          items={menuLine.translations[currentLanguage]?.takes?.map((take) => {
            const hasOutdatedTakes = menuLine.translations[currentLanguage].takes?.some(
              (take) => isTakeOutdated(take, menuLine, currentLanguage) && !take.suppressWarnings
            )

            const isCurrentTakeOutdated = isTakeOutdated(take, menuLine, currentLanguage)
            const showWarning = isCurrentTakeOutdated && !take.suppressWarnings

            return {
              disableClickHandler: true,
              handleHoverIfNotClickable: true,
              text: (
                <Row alignItems="center" gap="5px">
                  {hasOutdatedTakes && (
                    <>
                      {showWarning && (
                        <Block
                          width="42px"
                          textAlign="center"
                          props={{
                            title: `The line has changed since this take was ${
                              take.aiVoice ? 'generated' : 'uploaded'
                            }.`
                          }}
                        >
                          <WarningIcon fill="var(--warning)" />
                        </Block>
                      )}
                      {!showWarning && <Block width="42px" />}
                    </>
                  )}
                  <Block color="var(--on-surface-light)" width="25px">
                    {take.seqLanguage ? `${take.seqLanguage}.` : ''}
                  </Block>
                  <TakeUploader take={take} />
                  <Take take={take} player={player} />
                  <TakeWaveform take={take} player={player} />
                  <SpacerHorizontal large />
                  <Switch
                    on={take.id === menuLine.translations[currentLanguage]?.selectedTake?.id}
                    onChange={() => {
                      onSelectedTakeChange(
                        menuLine.id,
                        take.id !== menuLine.translations[currentLanguage]?.selectedTake?.id
                          ? take.id
                          : undefined
                      )
                    }}
                  />
                  <SpacerHorizontal tiny />
                </Row>
              )
            }
          })}
          onClose={hideMenu}
        />
      )}
    </>
  )
}

export default LinesTable
