import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import {
  ArrowDownwardIcon,
  Button,
  Checkbox,
  Form,
  MemoryIcon,
  SpacerHorizontal,
  SpacerVertical,
  TextArea,
  TextField
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import { createLine, setError } from '../actions'
import { useEvent } from '../use-event'
import { useScene } from '../use-scene'
import { useCharacter } from '../use-character'
import { useUrlParams } from '../use-url-params'
import { get, post } from '../api'

import Page from './Page'
import Avatar from './Avatar'
import Spinner from './Spinner'
import SelectCharacter from './SelectCharacter'
import SelectEvent from './SelectEvent'
import SelectScene from './SelectScene'
import { useAIVoice } from '../use-ai-voice'
import SelectAIVoice from './SelectAIVoice'
import { useStore } from '../store'
import { LineAttribute, LineAttributeType, LineAttributeValue } from '../types'
import { LineAttributeInput } from './Line/LineAttributeInput'
import { FakeSelect } from './FakeSelect'
import { formatLanguage } from '../format-language'
import { confirm } from 'lib'

const CreateLine = () => {
  const {
    currentGame,
    isCreatingLine,
    lineAttributes = [],
    currentLanguage
  } = useStore('currentGame', 'isCreatingLine', 'lineAttributes', 'currentLanguage')

  const [line, setLine] = useState('')
  const [lineError, setLineError] = useState('')
  const [secondaryLine, setSecondaryLine] = useState('')
  const [description, setDescription] = useState('')
  const [descriptionError] = useState('')
  const [filename, setFilename] = useState('')
  const [filenameError, setFilenameError] = useState('')
  const [filenameModified, setFilenameModified] = useState(false)
  const [characterError, setCharacterError] = useState('')
  const [lineAttributeErrors, setLineAttributeErrors] = useState<Record<string, string>>({})
  const [generateAITake, setGenerateAITake] = useState<boolean>(false)
  const [primaryAIVoiceError, setPrimaryAIVoiceError] = useState('')
  const [secondaryAIVoiceError, setSecondaryAIVoiceError] = useState('')
  const [isTranslating, setIsTranslating] = useState(false)
  const [externalLineId, setExternalLineId] = useState<string | undefined>(undefined)
  const [externalLineIdError, setExternalLineIdError] = useState<string | undefined>()

  const params = new URLSearchParams(location.search)
  const { eventId, event, setEvent } = useEvent(params.get('eventId') ?? undefined)
  const { sceneId, scene, setScene } = useScene(params.get('sceneId') ?? undefined)
  const primaryAIVoice = useAIVoice(params.get('aiVoiceId') ?? undefined)
  const secondaryAIVoice = useAIVoice(params.get('secondaryAIVoiceId') ?? undefined)
  const { characterId, character, setCharacter } = useCharacter(
    params.get('characterId') ?? undefined
  )
  const [lineAttributeValues, setLineAttributeValues] = useState<LineAttributeValue[]>(
    lineAttributes.slice()
  )
  const [hasAiLines, setHasAiLines] = useState(false)
  const [aiLinePrimaryLanguage, setAiLinePrimaryLanguage] = useState<string | undefined>(undefined)
  const [aiLineSecondaryLanguage, setAiLineSecondaryLanguage] = useState<string | undefined>(
    undefined
  )

  const primaryLanguage = currentGame!.primaryLanguage
  const secondaryLanguage = currentLanguage !== primaryLanguage ? currentLanguage : undefined

  const setLineAttributeError = (attributeId: string, errorMessage: string) => {
    setLineAttributeErrors({
      ...lineAttributeErrors,
      [attributeId]: errorMessage
    })
  }

  const getLineAttributeValue = (attributeId: string) => {
    return lineAttributeValues?.find((attribute: LineAttribute) => attribute.id === attributeId)
      ?.value
  }

  const setLineAttributeValue = (attributeId: string, value: LineAttributeType | undefined) => {
    setLineAttributeValues((attributes) => {
      return attributes?.map((attribute) =>
        attribute.id === attributeId ? { ...attribute, value } : attribute
      )
    })
  }

  useEffect(() => {
    if (character) {
      setCharacterError('')
    }
    if (character?.aiVoices[primaryLanguage]) {
      primaryAIVoice.setAIVoice(character.aiVoices[primaryLanguage])
    }
    if (secondaryLanguage && character?.aiVoices[secondaryLanguage]) {
      secondaryAIVoice.setAIVoice(character.aiVoices[secondaryLanguage])
    }
  }, [character])

  useEffect(() => {
    if (
      filenameModified ||
      (sceneId && !scene) ||
      (eventId && !event) ||
      (characterId && !character)
    ) {
      return
    }

    let cancelled = false
    let prefix = ''
    if (character) {
      prefix += character.name
    }
    if (event) {
      prefix += ' ' + event.name
    }
    if (scene) {
      prefix += ' ' + scene.name
    }
    prefix = prefix.toLowerCase().trim().replace(/\s+/g, '_') || 'line'

    get(`/games/${currentGame!.id}/next-line`, { prefix }).then(({ data: filename }) => {
      if (!cancelled) {
        setFilename(filename)
      }
    })

    return () => (cancelled = true)
  }, [currentGame, filenameModified, eventId, event, characterId, character, sceneId, scene])

  useUrlParams({
    eventId,
    characterId,
    sceneId,
    aiVoiceId: primaryAIVoice.aiVoiceId,
    secondaryAIVoiceId: secondaryAIVoice.aiVoiceId
  })

  const translateLine = async () => {
    if (!secondaryLanguage) {
      return
    }
    if (!secondaryLine || (await confirm('Overwrite current translation?'))) {
      setIsTranslating(true)
      try {
        const { data: translation } = await post(`/translate`, {
          gameId: currentGame!.id,
          text: line,
          fromLanguage: primaryLanguage,
          toLanguage: secondaryLanguage
        })
        setSecondaryLine(translation)
      } catch (err) {
        setError(err)
      } finally {
        setIsTranslating(false)
      }
    }
  }

  const onBack = () => goBack('/lines')

  const closeDialog = () => goBack('/create-line')

  const handleSubmit = async () => {
    let error = false
    if (!line) {
      setLineError('Line cannot be empty')
      error = true
    } else {
      setLineError('')
    }
    if (!filename) {
      setFilenameError('Filename cannot be empty')
      error = true
    } else {
      setFilenameError('')
    }
    if (
      currentGame?.useExternalLineId &&
      !currentGame.useFilenameAsExternalLineId &&
      !externalLineId?.trim()
    ) {
      setExternalLineIdError('External line ID cannot be empty')
      error = true
    } else {
      setExternalLineIdError(undefined)
    }

    if (!characterId) {
      setCharacterError('Please select a character')
      error = true
    } else {
      setCharacterError('')
    }

    if (generateAITake) {
      if (!primaryAIVoice.aiVoice) {
        setPrimaryAIVoiceError('Please select an AI voice')
        error = true
      } else {
        setPrimaryAIVoiceError('')
      }
      if (secondaryLanguage && !secondaryAIVoice.aiVoice) {
        setSecondaryAIVoiceError('Please select an AI voice')
        error = true
      } else {
        setSecondaryAIVoiceError('')
      }
    }

    setLineAttributeErrors({})

    for (let lineAttribute of lineAttributes) {
      const attributeValue = lineAttributeValues.find((av) => av.id === lineAttribute.id)
      if (lineAttribute.type !== 'boolean' && lineAttribute.required) {
        error = !attributeValue?.value?.toString().trim()
        if (error) {
          setLineAttributeError(lineAttribute.id, 'Please enter a value')
        }
      }
      if (lineAttribute.type === 'url') {
        const url = attributeValue?.value?.toString().trim()
        if (url) {
          try {
            new URL(url)
          } catch {
            setLineAttributeError(lineAttribute.id, 'Please enter a valid URL')
            error = true
          }
        }
      }
    }

    if (error) {
      return
    }

    const translations = [
      {
        language: primaryLanguage,
        line,
        aiVoiceId: generateAITake ? primaryAIVoice?.aiVoiceId : undefined,
        aiLine:
          hasAiLines && aiLinePrimaryLanguage?.trim().length
            ? aiLinePrimaryLanguage.trim()
            : undefined
      }
    ]
    if (secondaryLanguage) {
      translations.push({
        language: secondaryLanguage,
        line: secondaryLine,
        aiVoiceId: generateAITake ? secondaryAIVoice?.aiVoiceId : undefined,
        aiLine:
          hasAiLines && aiLineSecondaryLanguage?.trim().length
            ? aiLineSecondaryLanguage.trim()
            : undefined
      })
    }

    if (
      await createLine({
        translations,
        description,
        filename,
        eventId,
        characterId,
        sceneId,
        lineAttributes: lineAttributeValues,
        externalLineId
      })
    ) {
      onBack()
    }
  }

  if (eventId && !event) {
    return <Spinner />
  }

  if (characterId && !character) {
    return <Spinner />
  }

  if (sceneId && !scene) {
    return <Spinner />
  }

  if (primaryAIVoice.aiVoiceId && !primaryAIVoice.aiVoice) {
    return <Spinner />
  }

  if (secondaryAIVoice.aiVoiceId && !secondaryAIVoice.aiVoice) {
    return <Spinner />
  }

  return (
    <Page title="Create line" onBack={onBack}>
      <Form onSubmit={handleSubmit} maxWidth="500px">
        <FakeSelect
          visual={
            character ? (
              <Avatar size={30} src={character.picture} name={character.name} />
            ) : undefined
          }
          label="Character *"
          value={character?.name}
          onClick={() => routeTo('/create-line/select-character')}
          errorText={characterError}
        />
        <SpacerVertical />
        <FakeSelect
          visual={event ? <Avatar size={30} src={event.picture} name={event.name} /> : undefined}
          label="Event"
          value={event?.name}
          onClick={() => routeTo('/create-line/select-event')}
        />
        <SpacerVertical />
        <FakeSelect
          visual={scene ? <Avatar size={30} src={scene.picture} name={scene.name} /> : undefined}
          label="Scene"
          value={scene?.name}
          onClick={() => routeTo('/create-line/select-scene')}
        />
        <SpacerVertical />
        <TextArea
          autocomplete="off"
          width="100%"
          autoHeight
          minRows={3}
          maxRows={10}
          label={secondaryLanguage ? `Line (${formatLanguage(primaryLanguage!)}) *` : `Line *`}
          value={line}
          onInput={setLine}
          errorText={lineError}
        />

        {hasAiLines && (
          <>
            <SpacerVertical tiny />
            <TextArea
              autocomplete="off"
              width="100%"
              autoHeight
              minRows={3}
              maxRows={10}
              label={
                secondaryLanguage
                  ? `AI TTS prompt (${formatLanguage(primaryLanguage)}) - For generating AI takes`
                  : `AI TTS prompt - For generating AI takes`
              }
              value={aiLinePrimaryLanguage}
              onInput={(aiLine: string) => setAiLinePrimaryLanguage(aiLine)}
            />
          </>
        )}

        <>
          <SpacerVertical tiny />
          <Row justifyContent="left">
            {!hasAiLines && (
              <Button
                tiny
                icon={MemoryIcon}
                onClick={() => {
                  setHasAiLines(true)

                  // If adding a new AI line, copy line text from translations
                  if (!aiLinePrimaryLanguage?.trim().length) {
                    setAiLinePrimaryLanguage(line)
                  }
                  if (secondaryLanguage && !aiLineSecondaryLanguage?.trim().length) {
                    setAiLineSecondaryLanguage(secondaryLine)
                  }
                }}
                disabled={!line?.trim().length}
              >
                Add AI TTS prompt
              </Button>
            )}
            {hasAiLines && (
              <Button
                tiny
                icon={MemoryIcon}
                onClick={() => {
                  setHasAiLines(false)
                  setAiLinePrimaryLanguage(undefined)
                  setAiLineSecondaryLanguage(undefined)
                }}
              >
                Remove AI TTS prompt
              </Button>
            )}
            {secondaryLanguage && (
              <Row justifyContent="right" flex="1">
                <Button
                  tiny
                  icon={ArrowDownwardIcon}
                  onClick={translateLine}
                  loading={isTranslating}
                  disabled={!line}
                >
                  Translate
                </Button>
              </Row>
            )}
          </Row>
        </>

        {secondaryLanguage && (
          <>
            <SpacerVertical tiny />
            <TextArea
              autocomplete="off"
              width="100%"
              autoHeight
              minRows={3}
              maxRows={10}
              label={`Line (${formatLanguage(secondaryLanguage)})`}
              value={secondaryLine}
              onInput={setSecondaryLine}
            />
            {hasAiLines && (
              <>
                <SpacerVertical small />
                <TextArea
                  autocomplete="off"
                  width="100%"
                  autoHeight
                  minRows={3}
                  maxRows={10}
                  label={`AI TTS prompt (${formatLanguage(
                    secondaryLanguage
                  )}) - For generating AI takes`}
                  value={aiLineSecondaryLanguage}
                  onInput={(aiLine: string) => setAiLineSecondaryLanguage(aiLine)}
                />
              </>
            )}
          </>
        )}
        <SpacerVertical />
        <TextArea
          autocomplete="off"
          width="100%"
          label="Acting notes"
          autoHeight
          minRows={1}
          maxRows={3}
          value={description}
          onInput={setDescription}
          errorText={descriptionError}
        />
        <SpacerVertical />
        <TextField
          autocomplete="off"
          width="100%"
          label="Filename *"
          value={filename}
          onInput={(filename: string) => {
            setFilenameModified(true)
            setFilename(filename)
          }}
          errorText={filenameError}
        />
        <SpacerVertical />
        {lineAttributes.map((attribute) => {
          return (
            <>
              <LineAttributeInput
                attribute={attribute}
                value={getLineAttributeValue(attribute.id)}
                onChange={(newValue: LineAttributeType | undefined) =>
                  setLineAttributeValue(attribute.id, newValue)
                }
                errorText={lineAttributeErrors[attribute.id]}
              />
              <SpacerVertical />
            </>
          )
        })}

        <Checkbox
          label={`Generate AI take${secondaryLanguage ? 's' : ''}`}
          checked={generateAITake}
          onChange={setGenerateAITake}
        />
        <SpacerVertical tiny />
        <FakeSelect
          visual={
            primaryAIVoice.aiVoice ? (
              <Avatar
                size={30}
                src={primaryAIVoice.aiVoice.picture}
                name={primaryAIVoice.aiVoice.name}
              />
            ) : undefined
          }
          label={`AI voice${secondaryLanguage ? ` (${formatLanguage(primaryLanguage)})` : ''}`}
          value={primaryAIVoice.aiVoice?.name}
          onClick={() => routeTo('/create-line/select-primary-ai-voice')}
          errorText={primaryAIVoiceError}
          disabled={!generateAITake}
        />
        <SpacerVertical />
        {secondaryLanguage && (
          <>
            <FakeSelect
              visual={
                secondaryAIVoice.aiVoice ? (
                  <Avatar
                    size={30}
                    src={secondaryAIVoice.aiVoice.picture}
                    name={secondaryAIVoice.aiVoice.name}
                  />
                ) : undefined
              }
              label={`AI voice (${formatLanguage(secondaryLanguage)})`}
              value={secondaryAIVoice.aiVoice?.name}
              onClick={() => routeTo('/create-line/select-secondary-ai-voice')}
              errorText={secondaryAIVoiceError}
              disabled={!generateAITake}
            />
            <SpacerVertical />
          </>
        )}

        {currentGame?.useExternalLineId && !currentGame.useFilenameAsExternalLineId && (
          <>
            <TextField
              autocomplete="off"
              width="100%"
              label="External line ID*"
              value={externalLineId}
              onInput={setExternalLineId}
              errorText={externalLineIdError}
            />
            <SpacerVertical />
          </>
        )}

        <Block color="var(--on-surface-light)" fontSize="14px">
          * Required field
        </Block>
        <SpacerVertical large />

        <Row alignItems="center">
          <Button type="submit" contained loading={isCreatingLine}>
            Create
          </Button>
          <SpacerHorizontal />
          <Button onClick={onBack}>Cancel</Button>
        </Row>
      </Form>
      <Route
        path="/create-line/select-character"
        render={() => <SelectCharacter onClose={closeDialog} onSelect={setCharacter} />}
      />
      <Route
        path="/create-line/select-event"
        render={() => <SelectEvent onClose={closeDialog} onSelect={setEvent} />}
      />
      <Route
        path="/create-line/select-scene"
        render={() => <SelectScene onClose={closeDialog} onSelect={setScene} canCreate={true} />}
      />
      <Route
        path="/create-line/select-primary-ai-voice"
        render={() => <SelectAIVoice onClose={closeDialog} onSelect={primaryAIVoice.setAIVoice} />}
      />
      <Route
        path="/create-line/select-secondary-ai-voice"
        render={() => (
          <SelectAIVoice onClose={closeDialog} onSelect={secondaryAIVoice.setAIVoice} />
        )}
      />
    </Page>
  )
}

export default CreateLine
