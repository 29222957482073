import { store } from '../store'
import { get, post, patch, del } from '../api'
import { setItem } from '../storage'

export const setCurrentGame = (currentGame) => {
  const prevCurrentGame = store.getState().currentGame
  const hasGameChanged = prevCurrentGame?.id !== currentGame?.id

  store.setState({
    currentGame,
    currentLanguage: currentGame?.primaryLanguage
  })
  if (hasGameChanged) {
    store.setState({
      characters: undefined,
      events: undefined,
      scenes: undefined,
      lines: undefined,
      sessions: undefined
    })
  }
  if (currentGame) {
    setItem('speechless:currentGameId', currentGame.id)
  }
}

export const fetchCurrentGame = async () => {
  try {
    store.setState({ isFetchingCurrentGame: true })
    const gameId = store.getState().currentGame.id
    const { data: currentGame } = await get(`/games/${gameId}`)
    store.setState({ currentGame, isFetchingCurrentGame: false })
    return currentGame
  } catch (error) {
    store.setState({ error, isFetchingCurrentGame: false })
  }
}

export const fetchGames = async () => {
  try {
    store.setState({ isFetchingGames: true })
    const { data: games } = await get(`/games`)
    store.setState({ games, isFetchingGames: false })
    return games
  } catch (error) {
    store.setState({ error, isFetchingGames: false })
  }
}

export const createGame = async (params) => {
  try {
    store.setState({ isCreatingGame: true })
    const { data: currentGame } = await post(`/games`, params)
    setCurrentGame(currentGame)
    const games = [...store.getState().games, currentGame]
    store.setState({ games, isCreatingGame: false })
    return currentGame
  } catch (error) {
    store.setState({ error, isCreatingGame: false })
  }
}

export const cloneGame = async (params) => {
  try {
    store.setState({ isCloningGame: true })
    const gameId = store.getState().currentGame.id
    const { data: currentGame } = await post(`/games/${gameId}/clone`, params)
    setCurrentGame(currentGame)
    const games = [...store.getState().games, currentGame]
    store.setState({ games, isCloningGame: false })
    return currentGame
  } catch (error) {
    store.setState({ error, isCloningGame: false })
  }
}

export const updateGame = async (params) => {
  try {
    store.setState({ isUpdatingGame: true })
    const gameId = store.getState().currentGame.id
    const { data: currentGame } = await patch(`/games/${gameId}`, params)
    const games = store
      .getState()
      .games.map((game) => (game.id === currentGame.id ? currentGame : game))
    store.setState({ currentGame, games, isUpdatingGame: false })
    return currentGame
  } catch (error) {
    store.setState({ error, isUpdatingGame: false })
  }
}

export const deleteGame = async (gameId) => {
  try {
    store.setState({ isDeletingGame: true })
    await del(`/games/${gameId}`)
    const games = store.getState().games.filter((game) => game.id !== gameId)
    const currentGame = games.length > 0 ? games[0] : null
    setCurrentGame(currentGame)
    store.setState({ games, isDeletingGame: false })
    return true
  } catch (error) {
    store.setState({ error, isDeletingGame: false })
  }
}

export const restoreGame = async (gameId) => {
  try {
    store.setState({ isRestoringGame: true })
    const { data: currentGame } = await patch(`/games/${gameId}`, { deleted: null })
    const games = [...store.getState().games, currentGame]
    store.setState({ games, currentGame, isRestoringGame: false })
    return true
  } catch (error) {
    store.setState({ error, isRestoringGame: false })
  }
}
