import { Fragment, h } from 'preact'
import { Button, FloatingSheet, List, ListItem, ViewColumnIcon } from '@sodra/bongo-ui'

import { useMemo, useState } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { useStore } from '../store'

type Props = {
  value: string[]
  onChange: (value: string[]) => void
}

type MenuPosition = {
  positionElement?: HTMLInputElement | undefined
  position?: {
    x: number
    y: number
  }
}

export const ColumnDisplayOptions = ({ value, onChange }: Props) => {
  const { currentGame } = useStore('currentGame')
  const [menuPosition, setMenuPosition] = useState<MenuPosition>()

  const { lineAttributes = [] } = useStore('lineAttributes')

  const options = useMemo(() => {
    const options = [
      { value: 'selectedTake', label: 'Selected take' },
      { value: 'filename', label: 'Filename' },
      { value: 'character', label: 'Character' },
      { value: 'labels', label: 'Labels' },
      { value: 'event', label: 'Event' },
      { value: 'scene', label: 'Scene' },
      { value: 'sceneSeqNumber', label: 'Scene order' },
      { value: 'line', label: 'Line' },
      { value: 'autoTranslated', label: 'Translation type' },
      { value: 'description', label: 'Acting notes' },
      { value: 'created', label: 'Created' },
      { value: 'lastModified', label: 'Last modified' },
      { value: 'lineId', label: 'Speechless line ID' }
    ]
    if (currentGame?.useExternalLineId) {
      options.push({ value: 'externalLineId', label: 'External line ID' })
    }

    for (let attribute of lineAttributes) {
      options.push({
        value: attribute.id,
        label: attribute.name
      })
    }

    return options
  }, [lineAttributes])

  const showMenu = (e: any) => {
    if (e.type === 'keyboard') {
      setMenuPosition({ positionElement: e.target })
    } else {
      setMenuPosition({ position: { x: e.x, y: e.y } })
    }
  }

  const hideMenu = () => setMenuPosition(undefined)

  const toggleColumn = (name: string) => {
    onChange(value.includes(name) ? value.filter((n: string) => n !== name) : [...value, name])
  }

  return (
    <Fragment>
      <Button icon={ViewColumnIcon} onClick={showMenu}>
        Edit columns
      </Button>
      {menuPosition && (
        <Block
          position="fixed"
          top="0"
          left="0"
          bottom="0"
          right="0"
          zIndex={5}
          props={{ onClick: hideMenu }}
        >
          <FloatingSheet
            position={menuPosition.position}
            positionElement={menuPosition.positionElement}
          >
            <Block props={{ onClick: (e: Event) => e.stopPropagation() }}>
              <List>
                {options.map((option) => {
                  return (
                    <ListItem
                      checkboxVisible
                      checked={value.includes(option.value)}
                      onClick={() => toggleColumn(option.value)}
                      text={option.label}
                    />
                  )
                })}
              </List>
            </Block>
          </FloatingSheet>
        </Block>
      )}
    </Fragment>
  )
}

export default ColumnDisplayOptions
