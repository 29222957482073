//https://auphonic.com/api/info/output_files.json
export const outputFiles: Record<string, any> = {
  mp3: {
    bitrate_strings: [
      '32 kbps (~14MB/h)',
      '40 kbps (~18MB/h)',
      '48 kbps (~21MB/h)',
      '56 kbps (~25MB/h)',
      '64 kbps (~28MB/h)',
      '80 kbps (~35MB/h)',
      '96 kbps (~42MB/h)',
      '112 kbps (~49MB/h)',
      '128 kbps (~56MB/h)',
      '160 kbps (~70MB/h)',
      '192 kbps (~84MB/h)',
      '224 kbps (~98MB/h)',
      '256 kbps (~112MB/h)',
      '320 kbps (~141MB/h)'
    ],
    endings: ['mp3'],
    display_name: 'MP3',
    bitrates: [
      '32',
      '40',
      '48',
      '56',
      '64',
      '80',
      '96',
      '112',
      '128',
      '160',
      '192',
      '224',
      '256',
      '320'
    ],
    default_bitrate: '112',
    type: 'lossy'
  },
  'mp3-vbr': {
    bitrate_strings: [
      '~32 kbps (~14MB/h)',
      '~40 kbps (~18MB/h)',
      '~48 kbps (~21MB/h)',
      '~56 kbps (~25MB/h)',
      '~64 kbps (~28MB/h)',
      '~80 kbps (~35MB/h)',
      '~96 kbps (~42MB/h)',
      '~112 kbps (~49MB/h)',
      '~128 kbps (~56MB/h)',
      '~160 kbps (~70MB/h)',
      '~192 kbps (~84MB/h)',
      '~224 kbps (~98MB/h)',
      '~256 kbps (~112MB/h)',
      '~320 kbps (~141MB/h)'
    ],
    endings: ['mp3'],
    display_name: 'MP3 Variable Bitrate',
    bitrates: [
      '32',
      '40',
      '48',
      '56',
      '64',
      '80',
      '96',
      '112',
      '128',
      '160',
      '192',
      '224',
      '256',
      '320'
    ],
    default_bitrate: '96',
    type: 'lossy'
  },
  vorbis: {
    bitrate_strings: [
      '~32 kbps (~14MB/h)',
      '~40 kbps (~18MB/h)',
      '~48 kbps (~21MB/h)',
      '~56 kbps (~25MB/h)',
      '~64 kbps (~28MB/h)',
      '~80 kbps (~35MB/h)',
      '~96 kbps (~42MB/h)',
      '~112 kbps (~49MB/h)',
      '~128 kbps (~56MB/h)',
      '~160 kbps (~70MB/h)',
      '~192 kbps (~84MB/h)',
      '~224 kbps (~98MB/h)',
      '~256 kbps (~112MB/h)',
      '~320 kbps (~141MB/h)'
    ],
    endings: ['ogg', 'oga'],
    display_name: 'Ogg Vorbis',
    bitrates: [
      '32',
      '40',
      '48',
      '56',
      '64',
      '80',
      '96',
      '112',
      '128',
      '160',
      '192',
      '224',
      '256',
      '320'
    ],
    default_bitrate: '96',
    type: 'lossy'
  },
  aac: {
    bitrate_strings: [
      '24 kbps, HE AAC (~11MB/h)',
      '32 kbps, HE AAC (~14MB/h)',
      '40 kbps, HE AAC (~18MB/h)',
      '48 kbps, HE AAC (~21MB/h)',
      '56 kbps, HE AAC (~25MB/h)',
      '64 kbps, HE AAC (~28MB/h)',
      '80 kbps, HE AAC (~35MB/h)',
      '96 kbps (~42MB/h)',
      '112 kbps (~49MB/h)',
      '128 kbps (~56MB/h)',
      '160 kbps (~70MB/h)',
      '192 kbps (~84MB/h)',
      '256 kbps (~112MB/h)',
      '320 kbps (~141MB/h)'
    ],
    endings: ['m4a', 'mp4', 'm4b'],
    display_name: 'AAC (M4A, MP4, M4B)',
    bitrates: [
      '24',
      '32',
      '40',
      '48',
      '56',
      '64',
      '80',
      '96',
      '112',
      '128',
      '160',
      '192',
      '256',
      '320'
    ],
    default_bitrate: '80',
    type: 'lossy'
  },
  opus: {
    bitrate_strings: [
      '~6 kbps (~3MB/h)',
      '~12 kbps (~5MB/h)',
      '~16 kbps (~7MB/h)',
      '~20 kbps (~9MB/h)',
      '~24 kbps (~11MB/h)',
      '~32 kbps (~14MB/h)',
      '~40 kbps (~18MB/h)',
      '~48 kbps (~21MB/h)',
      '~56 kbps (~25MB/h)',
      '~64 kbps (~28MB/h)',
      '~80 kbps (~35MB/h)',
      '~96 kbps (~42MB/h)',
      '~112 kbps (~49MB/h)',
      '~128 kbps (~56MB/h)',
      '~160 kbps (~70MB/h)',
      '~192 kbps (~84MB/h)',
      '~256 kbps (~112MB/h)'
    ],
    endings: ['opus'],
    display_name: 'Opus',
    bitrates: [
      '6',
      '12',
      '16',
      '20',
      '24',
      '32',
      '40',
      '48',
      '56',
      '64',
      '80',
      '96',
      '112',
      '128',
      '160',
      '192',
      '256'
    ],
    default_bitrate: '48',
    type: 'lossy'
  },
  flac: {
    bitrates: ['0'],
    bitrate_strings: ['optimal (stereo ~130MB/h, mono ~65MB/h)'],
    default_bitrate: '0',
    endings: ['flac'],
    display_name: 'FLAC',
    type: 'lossless'
  },
  alac: {
    bitrates: ['0'],
    bitrate_strings: ['optimal (stereo ~140MB/h, mono ~70MB/h)'],
    default_bitrate: '0',
    endings: ['m4a', 'mp4'],
    display_name: 'ALAC (M4A, MP4)',
    type: 'lossless'
  },
  wav: {
    bitrates: ['0'],
    bitrate_strings: ['optimal (stereo ~635MB/h, mono ~318MB/h)'],
    default_bitrate: '0',
    endings: ['wav'],
    display_name: 'WAV 16-bit PCM',
    type: 'lossless'
  },
  'wav-24bit': {
    bitrates: ['0'],
    bitrate_strings: ['optimal (stereo ~953MB/h, mono ~476MB/h)'],
    default_bitrate: '0',
    endings: ['wav'],
    display_name: 'WAV 24-bit PCM',
    type: 'lossless'
  }
}
