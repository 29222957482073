import { Fragment, h } from 'preact'
import { Button } from '@sodra/bongo-ui'

import { usePopupMenu } from './use-popup-menu'
import { PopupMenuOption } from './PopupMenu'

export type PageActionType = {
  label: string
  type?: 'text' | 'outlined' | 'contained'
  onClick?: () => void
  link?: {
    href: string
    onRoute?: (path: string) => void
  }
  disabled?: boolean
  visible?: boolean
  icon?: any
  options?: PopupMenuOption[]
  loading?: boolean
}

type Props = {
  action: PageActionType
}

const PageActionWithOptions = ({ action }: Props) => {
  const { PopupMenu, showMenu } = usePopupMenu({ options: action.options })

  return (
    <Fragment>
      <Button
        icon={action.icon}
        disabled={action.disabled}
        onClick={showMenu}
        whiteSpace="nowrap"
        color="var(--on-surface)"
        loading={action.loading}
      >
        {action.label}
      </Button>
      {PopupMenu}
    </Fragment>
  )
}

export const PageAction = ({ action }: Props) => {
  if (action.options) {
    return <PageActionWithOptions action={action} />
  }

  return (
    <Button
      outlined={action.type === 'outlined'}
      contained={action.type === 'contained'}
      icon={action.icon}
      disabled={action.disabled}
      link={action.link}
      onClick={action.onClick}
      whiteSpace="nowrap"
      color={action.type !== 'contained' ? 'var(--on-surface)' : undefined}
      borderColor="var(--on-surface)"
      loading={action.loading}
    >
      {action.label}
    </Button>
  )
}
