import { h } from 'preact'
import { goBack } from '@sodra/prutt'
import { Checkbox, Dialog, Form, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { useState } from 'preact/hooks'
import { cloneGame, showSnackbar } from '../../actions'
import { useStore } from '../../store'

export const CloneGameDialog = () => {
  const { currentGame, isCloningGame } = useStore('currentGame', 'isCloningGame')

  const [name, setName] = useState(currentGame?.name + ' Copy')
  const [copyLines, setCopyLines] = useState(true)

  if (!currentGame) {
    return null
  }

  const handleSubmit = async () => {
    if (await cloneGame({ name, copyLines })) {
      showSnackbar(`New game ${name} created`)
      onClose()
    }
  }

  const onClose = () => goBack('/settings')

  return (
    <Dialog
      disableAutofocus
      title="Clone game"
      onClose={onClose}
      actions={[
        {
          text: 'Clone',
          onClick: handleSubmit,
          type: 'contained',
          loading: isCloningGame
        },
        {
          text: 'Cancel',
          onClick: onClose
        }
      ]}
    >
      <Form onSubmit={handleSubmit}>
        <TextField width="100%" label="Name" value={name} onInput={setName} />
        <SpacerVertical />
        <Checkbox label="Copy lines" checked={copyLines} onChange={setCopyLines} />
      </Form>
    </Dialog>
  )
}
