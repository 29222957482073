import { Fragment, h } from 'preact'
import { H2, P, SpacerVertical } from '@sodra/bongo-ui'

export const NoChatSelected = () => {
  return (
    <Fragment>
      <H2 size="6">No chat selected</H2>
      <SpacerVertical />
      <P>Select one from the list.</P>
    </Fragment>
  )
}
