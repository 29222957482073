import { h, Fragment } from 'preact'
import { Block, InlineRow } from 'jsxstyle/preact'
import { AddIcon, H2, IconButton, SpacerVertical } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'
import Avatar from '../Avatar'

export const Characters = ({ voice }) => {
  if (voice.characters.length === 0) {
    return null
  }

  return (
    <Fragment>
      <Block backgroundColor="var(--surface-alternative)" borderRadius="3px" padding="20px">
        <H2 size="6">Characters</H2>
        <SpacerVertical />
        <InlineRow gap={10}>
          {voice.characters.map((character) => {
            return (
              <Avatar
                src={character.picture}
                name={character.name}
                link={{
                  href: `/character/${character.id}`,
                  onRoute: routeTo
                }}
              />
            )
          })}
          <IconButton
            color="var(--on-surface)"
            icon={AddIcon}
            tooltipText="Shortlist…"
            outlined
            link={{
              href: `/voice/${voice.id}/shortlist`,
              onRoute: routeTo
            }}
          />
        </InlineRow>
      </Block>
      <SpacerVertical />
    </Fragment>
  )
}
