import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import {
  Button,
  DownloadIcon,
  EventIcon,
  IconButton,
  MoreIcon,
  SpacerHorizontal
} from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

import { File } from '../../types'
import { formatDuration } from '../../format-duration'

import { Avatar } from '../Avatar'
import { usePopupMenu, BuiClickEvent } from '../use-popup-menu'
import { PopupMenuOption } from '../PopupMenu'

import { Thumbnail } from './Thumbnail'
import { ScrollableDataTable } from '../ScrollableDataTable'
import { formatScheduled } from 'lib'

type Header = {
  label: string
  value?: string
  sortable?: boolean
  startSortOrder?: 'asc' | 'desc'
  style?: any
}

type Props = {
  loading?: boolean
  files: File[]
  total?: number
  pageSize: number
  page: number
  orderBy: string
  sortOrder: string
  setSortOrder: (sortOrder: string) => void
  setOrderBy: (orderBy: string) => void
  setPage: (page: number) => void
  popupMenuOptions: PopupMenuOption[]
}

function niceBytes(bytes: number) {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let l = 0,
    n = bytes || 0
  while (n >= 1024 && ++l) {
    n = n / 1024
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]
}
export const FilesTable = ({
  loading,
  files,
  total,
  pageSize = 10,
  page,
  setPage,
  orderBy,
  setOrderBy,
  sortOrder,
  setSortOrder,
  popupMenuOptions
}: Props) => {
  const [showSpinner, setShowSpinner] = useState(false)

  const { PopupMenu, showMenu } = usePopupMenu({
    options: popupMenuOptions
  })

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => setShowSpinner(true), 500)
      return () => clearTimeout(timer)
    } else {
      setShowSpinner(false)
    }
  }, [loading])

  const header: Header[] = [
    { label: '' },
    { label: 'Preview' },
    { label: 'Filename', value: 'filename', sortable: true },
    { label: 'Type', value: 'content_type', sortable: true },
    {
      label: 'Size',
      value: 'bytes',
      sortable: true,
      startSortOrder: 'desc'
    },
    { label: 'Uploaded', value: 'created', sortable: true, startSortOrder: 'desc' },
    { label: 'Uploaded by', style: { whiteSpace: 'nowrap' } },
    { label: 'Session' },
    { label: '' }
  ]

  const body = files.map((file) => {
    const row = []
    row.push(
      <IconButton
        icon={DownloadIcon}
        onClick={() =>
          (location.href = `${import.meta.env.VITE_API_URL}/v1/download?uri=${file.uri}&filename=${
            file.filename
          }`)
        }
      />
    )

    row.push(
      <Block textAlign="center">
        <Thumbnail file={file} />
      </Block>
    )
    row.push(
      <Block color="var(--on-surface)" whiteSpace="nowrap">
        {file.filename}
      </Block>
    )
    row.push(
      <Block color="var(--on-surface-light)" whiteSpace="nowrap">
        {file.contentType}
      </Block>
    )
    row.push(
      <Block color="var(--on-surface-light)" whiteSpace="nowrap">
        {niceBytes(file.bytes)}
      </Block>
    )
    row.push(
      <Block color="var(--on-surface-light)" whiteSpace="nowrap">
        {formatDuration(file.created)}
      </Block>
    )
    if (file.fromUser) {
      row.push(
        <Row alignItems="center" whiteSpace="nowrap">
          <Avatar size={30} src={file.fromUser.picture} name={file.fromUser.name} />
          <SpacerHorizontal small />
          {file.fromUser.name}
        </Row>
      )
    } else if (file.fromVoice) {
      row.push(
        <Row alignItems="center" whiteSpace="nowrap">
          <Avatar size={30} src={file.fromVoice.picture} name={file.fromVoice.name} />
          <SpacerHorizontal small />
          {file.fromVoice.name}
        </Row>
      )
    } else if (file.fromAdmin) {
      row.push(
        <Row alignItems="center" whiteSpace="nowrap">
          <Avatar size={30} src={file.fromAdmin.picture} name={file.fromAdmin.name} />
          <SpacerHorizontal small />
          {file.fromAdmin.name}
        </Row>
      )
    } else {
      row.push('')
    }

    if (file.session) {
      row.push(
        <Button onClick={() => routeTo(`/session/${file.session?.id}`)}>
          <Row alignItems="center" gap="10px">
            {file.session.voice ? (
              <Avatar size={30} src={file.session.voice.picture} name={file.session.voice.name} />
            ) : (
              <EventIcon />
            )}
            <Block textAlign="left">
              <Block whiteSpace="nowrap" color="var(--on-surface)">
                {formatScheduled(file.session.scheduled)}
              </Block>
              <Block whiteSpace="nowrap" color="var(--on-surface-light)" fontSize="14px">{`${
                file.session.voice?.name || 'Not assigned'
              } · ${file.session.duration} hour${file.session.duration !== 1 ? 's' : ''}`}</Block>
            </Block>
          </Row>
        </Button>
      )
    } else {
      row.push('')
    }

    row.push(
      <IconButton icon={MoreIcon} onClick={(event: BuiClickEvent) => showMenu(event, file)} />
    )

    return row
  })

  const handleSort = (column: string, sortOrder: 'asc' | 'desc') => {
    setOrderBy(column)
    setSortOrder(sortOrder)
    setPage(0)
  }

  return (
    <Block flex="1" position="relative">
      <ScrollableDataTable
        loading={showSpinner}
        header={header}
        body={body}
        sortedColumn={orderBy}
        sortOrder={sortOrder}
        onSort={handleSort}
        onClick={(index: number) => window.open(files[index].uri, '_blank')}
        pagination={{
          page,
          numRowsPerPage: pageSize,
          numRows: total,
          onPageChange: setPage
        }}
        emptyText="No files found"
      />
      {PopupMenu}
    </Block>
  )
}
