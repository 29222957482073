import { OrderState } from '../../types'

export const formatOrderState = (state: OrderState) => {
  if (state === 'pending-customer-approval') {
    return 'Pending customer approval'
  }
  if (state === 'pending-supplier-approval') {
    return 'Awaiting supplier acceptance'
  }
  if (state === 'pending-finalization') {
    return 'Supplier accepted'
  }
  if (state === 'finalized') {
    return 'Finalized'
  }
  return '—'
}
