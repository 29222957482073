import { useEffect, useState } from 'preact/hooks'
import { createStore } from './unistore'
import { State } from './types'

const initialState: State = {
  isNarrow: window.innerWidth < 800,
  appHasFocus: document.hasFocus(),
  currentLanguage: 'en'
}

export const store = createStore(initialState)

export const resetStore = () => store.setState(initialState, true)

export const useStore = <T extends keyof State>(...depProps: T[]): Pick<State, T> => {
  const [state, setState] = useState<State>(store.getState())

  useEffect(() => {
    const listener = (newState: State) => {
      if (depProps.some((dep) => state[dep] !== newState[dep])) {
        setState(newState)
      }
    }

    return store.subscribe(listener)
  }, [...depProps, state])

  const subState: Pick<State, T> = {} as Pick<State, T>

  for (let depProp of depProps) {
    subState[depProp] = state[depProp]
  }

  return subState
}
