import { Col, Grid } from 'jsxstyle/preact'
import { ComponentChild, h } from 'preact'
import { useRef, useState } from 'preact/hooks'

export function TextOutput(props: { children: ComponentChild[] }) {
  const outputRef = useRef<HTMLDivElement>(null)

  const [prevLength, setPrevLength] = useState(props.children.length)

  if (prevLength !== props.children.length) {
    setPrevLength(props.children.length)

    if (outputRef.current) {
      const tolerance = 20
      const isScrolledToBottom =
        outputRef.current.scrollHeight - outputRef.current.clientHeight <=
        outputRef.current.scrollTop + tolerance
      if (isScrolledToBottom) {
        outputRef.current.scrollTop =
          outputRef.current.scrollHeight - outputRef.current.clientHeight
      }
    }
  }

  return (
    <Col
      class="bui-show-scroll"
      height="100%"
      overflowY="scroll"
      overflowX="auto"
      padding="20px"
      fontSize="14px"
      background="var(--container-background)"
      color="var(--on-surface-light)"
      props={{
        ref: outputRef
      }}
    >
      <Grid columnGap="20px" gridTemplateColumns="auto 1fr auto">
        {props.children}
      </Grid>
    </Col>
  )
}
