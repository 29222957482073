import { h } from 'preact'
import { useState } from 'preact/hooks'
import { goBack, Route, routeTo } from '@sodra/prutt'
import {
  AddIcon,
  Button,
  EditIcon,
  MemoryIcon,
  PlusIcon,
  SpacerVertical,
  TextArea,
  VoiceIcon
} from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'

import {
  deleteTakesById,
  fetchLine,
  showSnackbar,
  regenerateAITake,
  updateLine,
  updateLineLabels,
  updateTake,
  RestoreTakesResult,
  DeleteTakesResult,
  setError
} from '../../actions'

import Avatar from '../Avatar'
import { useStore } from '../../store'
import { LineAttribute, Take as TakeType, Line as LineType } from '../../types'
import { Label } from './Label'
import { AddLabelDialog } from './AddLabelDialog'
import { TakesTable } from './TakesTable'
import { PopupMenuOption } from '../PopupMenu'
import UploadTake from './UploadTake'
import GenerateAITake from '../Lines/GenerateAITake'
import { SelectTakeUploader, SelectUploaderParams } from './SelectTakeUploader'
import { confirm, pluralize, useAudioPlayer } from 'lib'
import { formatLanguage } from '../../format-language'
import { isTakeOutdated } from '../Lines/is-take-outdated'
import { OutdatedTakeDialog } from '../Lines/OutdatedTakeDialog'
import { usePopupMenu } from '../use-popup-menu'
import { DeleteTakes } from '../Lines/DeleteTakes'
import { RestoreTakes } from '../Lines/RestoreTakes'
import { EditLineDetails } from './EditLineDetails'

export const Line = () => {
  const {
    isUpdatingLine,
    isRegeneratingAITake,
    currentGame,
    lineAttributes = [],
    currentLanguage,
    ...storeData
  } = useStore(
    'isUpdatingLine',
    'line',
    'currentGame',
    'lineAttributes',
    'currentLanguage',
    'isRegeneratingAITake',
    'isDeletingTakes'
  )

  const lineNotes = storeData.line?.notes ?? ''

  const [notes, setNotes] = useState(lineNotes)

  const { player } = useAudioPlayer()
  if (!player) {
    return null
  }

  const line = storeData.line!

  const primaryLanguage = currentGame?.primaryLanguage || 'en'
  const secondaryLanguage = currentLanguage !== primaryLanguage ? currentLanguage : undefined

  const [outdatedTake, setOutdatedTake] = useState<
    { take: TakeType; language: string } | undefined
  >(undefined)

  const closeDialog = () => goBack(`/line/${line.id}`)

  const deleteLabelFromLine = async (label: string) => {
    await updateLineLabels(line.labels.filter((lineLabel) => lineLabel !== label))
  }

  const saveNotes = async () => {
    if (await updateLine(line.id, { notes })) {
      showSnackbar('Notes saved')
    } else {
      setError('Error saving notes')
    }
  }

  const takesPopupMenuOptions: PopupMenuOption[] = [
    {
      label: 'Regenerate AI take',
      visible: (take) => take.aiVoice !== undefined,
      disabled: (take) => {
        return !isTakeOutdated(take, line, currentLanguage) || !!isRegeneratingAITake
      },
      onClick: async (take: TakeType) => {
        if (await regenerateAITake(take)) {
          fetchLine(line.id)
        }
      }
    },
    {
      label: 'Hide warnings',
      visible: (take) => isTakeOutdated(take, line, currentLanguage) && !take.suppressWarnings,
      onClick: async (take: TakeType) => {
        if (await updateTake(take.id, { suppressWarnings: true })) {
          fetchLine(line.id)
        }
      }
    },
    {
      label: 'Show warnings',
      visible: (take) => isTakeOutdated(take, line, currentLanguage) && take.suppressWarnings,
      onClick: async (take: TakeType) => {
        if (await updateTake(take.id, { suppressWarnings: false })) {
          fetchLine(line.id)
        }
      }
    },
    {
      label: 'Change voice',
      disabled: (take) => take.aiVoice !== undefined,
      onClick: (take: TakeType) => {
        routeTo(`/line/${line.id}/takes/${take.id}/select-uploader`)
      }
    },
    {
      label: 'Delete take',
      onClick: async (take: TakeType) => {
        if (await confirm('Delete take?')) {
          if (await deleteTakesById([take.id])) {
            showSnackbar('Take deleted')
          }
        }
      }
    }
  ]

  const getTranslationsFromLine = (line: LineType) => {
    return Object.entries(line.translations).map(
      ([language, { line, aiLine, aiLineTemplate }]) => ({
        language,
        line,
        aiLine,
        aiLineTemplate
      })
    )
  }

  const translations = getTranslationsFromLine(line)
  const primaryTranslation = translations.find((t) => t.language === primaryLanguage)
  const secondaryTranslation = translations.find((t) => t.language === secondaryLanguage)

  const currentLanguageTakes = line.translations[currentLanguage]?.takes || []
  const currentLanguageSelectedTake = line.translations[currentLanguage]?.selectedTake
  const primaryLanguageSelectedTake = line.translations[primaryLanguage]?.selectedTake

  const selectCurrentLanguageTake = (selectedTake?: TakeType) => {
    updateLine(line.id, {
      selectedTakes: [{ language: currentLanguage, takeId: selectedTake?.id || null }]
    })
  }

  const showOutdatedTake = (take: TakeType, language: string) => {
    setOutdatedTake({ take, language })
  }

  const getLineAttributeValue = (attributeId: string) =>
    line.lineAttributes?.find((attribute: LineAttribute) => attribute.id === attributeId)?.value

  const editTakesPopupMenuOptions: PopupMenuOption[] = [
    {
      label: 'Delete takes…',
      onClick: () => {
        routeTo(`/line/${line.id}/delete-takes`)
      }
    },
    {
      label: 'Restore takes…',
      // TODO: Disable if there are no takes to restore?
      disabled: false,
      onClick: () => {
        routeTo(`/line/${line.id}/restore-takes`)
      }
    }
  ]

  const { PopupMenu: EditTakesPopupMenu, showMenu: showEditTakesPopupMenu } = usePopupMenu({
    options: editTakesPopupMenuOptions
  })

  return (
    <>
      <Row gap="40px">
        <Block flex="1" maxWidth="500px">
          <Row alignItems="center" gap="10px">
            <Block fontSize="20px">Line details</Block>
            <Button small icon={EditIcon} onClick={() => routeTo(`/line/${line.id}/edit-details`)}>
              Edit…
            </Button>
          </Row>
          <SpacerVertical small />

          {line.character && (
            <>
              <Block fontSize="12px" color="var(--on-surface-light)">
                Character
              </Block>
              <SpacerVertical small />
              <Row alignItems="center" gap="10px">
                <Avatar src={line.character.picture} name={line.character.name} />
                {line.character.name}
              </Row>
              <SpacerVertical />
            </>
          )}

          {line.event && (
            <>
              <Block fontSize="12px" color="var(--on-surface-light)">
                Event
              </Block>
              <SpacerVertical small />
              <Row alignItems="center" gap="10px">
                <Avatar src={line.event.picture} name={line.event.name} />
                {line.event.name}
              </Row>
              <SpacerVertical />
            </>
          )}

          {line.scene && (
            <>
              <Block fontSize="12px" color="var(--on-surface-light)">
                Scene
              </Block>
              <SpacerVertical small />
              <Row alignItems="center" gap="10px">
                <Avatar src={line.scene.picture} name={line.scene.name} />
                {line.scene.name}
              </Row>
              <SpacerVertical />
            </>
          )}

          <Block fontSize="12px" color="var(--on-surface-light)">
            {secondaryLanguage ? `Line (${formatLanguage(primaryLanguage)})` : `Line`}
          </Block>
          <SpacerVertical small />
          <Block whiteSpace="pre-line">{primaryTranslation?.line}</Block>
          <SpacerVertical />

          {primaryTranslation?.aiLine && primaryTranslation?.aiLine !== primaryTranslation?.line && (
            <>
              <Block fontSize="12px" color="var(--on-surface-light)">
                {secondaryLanguage
                  ? `AI TTS prompt (${formatLanguage(primaryLanguage)})`
                  : `AI TTS prompt`}
              </Block>
              <SpacerVertical small />
              <Block whiteSpace="pre-line">{primaryTranslation?.aiLine}</Block>
              <SpacerVertical />
            </>
          )}

          {secondaryLanguage && (
            <>
              <Block fontSize="12px" color="var(--on-surface-light)">
                Line ({formatLanguage(secondaryLanguage)})
              </Block>
              <SpacerVertical small />
              <Block whiteSpace="pre-line">{secondaryTranslation?.line ?? '–'}</Block>
              <SpacerVertical />

              {secondaryTranslation?.aiLine &&
                secondaryTranslation?.aiLine !== secondaryTranslation?.line && (
                  <>
                    <Block fontSize="12px" color="var(--on-surface-light)">
                      AI TTS prompt ({formatLanguage(secondaryLanguage)})
                    </Block>
                    <SpacerVertical small />
                    <Block whiteSpace="pre-line">{secondaryTranslation?.aiLine}</Block>
                    <SpacerVertical />
                  </>
                )}
            </>
          )}

          {line.description && (
            <>
              <Block fontSize="12px" color="var(--on-surface-light)">
                Acting notes
              </Block>
              <SpacerVertical small />
              <Block>{line.description}</Block>
              <SpacerVertical />
            </>
          )}

          {line.emotion && (
            <>
              <Block fontSize="12px" color="var(--on-surface-light)">
                Emotion
              </Block>
              <SpacerVertical small />
              <Block>{line.emotion}</Block>
              <SpacerVertical />
            </>
          )}

          <Block fontSize="12px" color="var(--on-surface-light)">
            Filename
          </Block>
          <SpacerVertical small />
          <Block>{line.filename}</Block>
          <SpacerVertical />

          {lineAttributes.map((attribute) => {
            const lineAttributeValue = getLineAttributeValue(attribute.id)
            if (!lineAttributeValue) {
              return null
            }
            return (
              <>
                <Block fontSize="12px" color="var(--on-surface-light)">
                  {attribute.name}
                </Block>
                <SpacerVertical small />
                {attribute.type === 'url' && (
                  <a target="_blank" href={lineAttributeValue as string}>
                    {lineAttributeValue}
                  </a>
                )}
                {attribute.type !== 'url' && <Block>{lineAttributeValue}</Block>}
                <SpacerVertical />
              </>
            )
          })}

          {currentGame?.useExternalLineId && !currentGame.useFilenameAsExternalLineId && (
            <>
              <Block fontSize="12px" color="var(--on-surface-light)">
                External line ID
              </Block>
              <SpacerVertical small />
              <Block>{line.externalLineId}</Block>
              <SpacerVertical />
            </>
          )}

          <TextArea label="Notes" value={notes} onInput={setNotes} />
          {notes !== lineNotes && (
            <>
              <SpacerVertical small />
              <Row alignItems="center" gap="10px">
                <Button small contained loading={isUpdatingLine} onClick={saveNotes}>
                  Save notes
                </Button>
                <Button small onClick={() => setNotes(lineNotes)}>
                  Cancel
                </Button>
              </Row>
            </>
          )}

          <Route path="/line/:lineId/edit-details" component={EditLineDetails} />
        </Block>
        <Block>
          {secondaryLanguage && (
            <>
              <Row alignItems="center" gap="10px">
                <Block fontSize="20px">{formatLanguage(primaryLanguage)}</Block>
                <Block fontSize="14px" color="var(--on-surface-light)">
                  (Selected take)
                </Block>
              </Row>
              <SpacerVertical small />
              {primaryLanguageSelectedTake && (
                <>
                  <TakesTable
                    takes={[primaryLanguageSelectedTake]}
                    takesLanguage={primaryLanguage}
                    onShowOutdatedTake={(take) => showOutdatedTake(take, primaryLanguage)}
                    player={player}
                  />
                </>
              )}

              {!primaryLanguageSelectedTake && (
                <Block color="var(--on-surface-light)">No take selected</Block>
              )}
              <SpacerVertical />
            </>
          )}
          <Row alignItems="center" gap="10px">
            {!secondaryLanguage && <Block fontSize="20px">Takes</Block>}
            {secondaryLanguage && (
              <>
                <Block fontSize="20px">{formatLanguage(secondaryLanguage)} takes</Block>
              </>
            )}
            <Button
              small
              icon={AddIcon}
              link={{ href: `/line/${line.id}/upload-take`, onRoute: routeTo }}
            >
              Upload
            </Button>
            <Button
              small
              icon={MemoryIcon}
              link={{ href: `/line/${line.id}/generate-ai-take`, onRoute: routeTo }}
              disabled={
                ((secondaryTranslation ?? primaryTranslation)!.line || '').trim().length === 0
              }
            >
              Generate AI take
            </Button>
            <Button
              small
              icon={VoiceIcon}
              link={{
                href: `/record-lines?game=${currentGame?.shortId}&lineIds=${line.id}`,
                onRoute: routeTo
              }}
            >
              Record
            </Button>
            <Button small icon={EditIcon} onClick={showEditTakesPopupMenu}>
              Edit…
            </Button>
          </Row>
          <SpacerVertical small />
          {currentLanguageTakes?.length === 0 && (
            <Block color="var(--on-surface-light)">No takes</Block>
          )}
          {currentLanguageTakes?.length !== 0 && (
            <TakesTable
              takes={currentLanguageTakes}
              takesLanguage={currentLanguage}
              selectedTake={currentLanguageSelectedTake}
              onSelectTake={selectCurrentLanguageTake}
              popupMenuOptions={takesPopupMenuOptions}
              onShowOutdatedTake={(take) => showOutdatedTake(take, currentLanguage)}
              player={player}
            />
          )}
          <SpacerVertical large />
          <Row alignItems="center" gap="10px">
            <Block fontSize="20px">Labels</Block>
            <Button
              small
              icon={PlusIcon}
              link={{ href: `/line/${line.id}/add-label`, onRoute: routeTo }}
            >
              Add
            </Button>
          </Row>
          <SpacerVertical small />
          {line.labels.length === 0 && <Block color="var(--on-surface-light)">No labels</Block>}
          <Row alignItems="center" gap="10px" flexWrap="wrap" maxWidth="500px">
            {line.labels.map((label) => (
              <Label label={label} onClear={() => deleteLabelFromLine(label)} />
            ))}
          </Row>
        </Block>
        <Route
          path="/line/:lineId/upload-take"
          render={() => <UploadTake onClose={closeDialog} />}
        />
        <Route
          path="/line/:lineId/generate-ai-take"
          render={() => (
            <GenerateAITake
              lineId={line.id}
              translations={translations}
              initialAIVoiceId={line.character?.aiVoices[currentLanguage]?.id}
              onClose={closeDialog}
              onSuccess={() => fetchLine(line.id)}
            />
          )}
        />
        <Route
          path="/line/:lineId/takes/:takeId/select-uploader"
          render={(params: any) => (
            <SelectTakeUploader
              line={line}
              onClose={closeDialog}
              onSelect={async ({ user, voice }: SelectUploaderParams) => {
                if (
                  await updateTake(params.takeId, {
                    userId: user ? user.id : null,
                    voiceId: voice ? voice.id : null,
                    aiVoiceId: null
                  })
                ) {
                  showSnackbar(`Voice changed`)
                  fetchLine(params.lineId)
                }
              }}
            />
          )}
        />

        <Route path="/line/:lineId/add-label" component={AddLabelDialog} />

        <Route
          path="/line/:lineId/delete-takes"
          render={() => (
            <DeleteTakes
              lineIds={[line.id]}
              language={currentLanguage}
              onDelete={(result: DeleteTakesResult) => {
                fetchLine(line.id)
                showSnackbar(`${result.total} ${pluralize('take', result.total)} deleted.`)
              }}
              onClose={closeDialog}
            />
          )}
        />
        <Route
          path="/line/:lineId/restore-takes"
          render={() => (
            <RestoreTakes
              lineIds={[line.id]}
              language={currentLanguage}
              onRestore={(result: RestoreTakesResult) => {
                fetchLine(line.id)
                showSnackbar(`${result.total} ${pluralize('take', result.total)} restored.`)
              }}
              onClose={closeDialog}
            />
          )}
        />
      </Row>
      {outdatedTake && (
        <OutdatedTakeDialog
          take={outdatedTake.take}
          line={line!}
          language={outdatedTake.language}
          onClose={() => setOutdatedTake(undefined)}
          onRegenerateAITake={() => fetchLine(line.id)}
          onHideWarning={() => fetchLine(line.id)}
        />
      )}
      {EditTakesPopupMenu}
    </>
  )
}

export default Line
