import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Row, Block } from 'jsxstyle/preact'
import { IconButton, PlayIcon, SpacerHorizontal, StopIcon } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

import { buildSpeakerUri } from '../../speak'

import { AIVoice } from '../../types'
import { useAudioPlayer } from 'lib/src/hooks/use-audio-player'
import { ScrollableDataTable } from '../ScrollableDataTable'
import { AIAvatar } from '../AIAvatar'
import { formatAIVoiceType } from 'lib'
import { capitalize } from '../capitalize'

type Props = {
  loading?: boolean
  aiVoices: AIVoice[]
  total?: number
  pageSize: number
  page: number
  setPage: (page: number) => void
  setPageSize: (pageSize: number) => void
  orderBy: string
  setOrderBy: (orderBy: string) => void
  sortOrder: string
  setSortOrder: (sortOrder: string) => void
}

const AIVoicesTable = ({
  loading,
  aiVoices,
  total,
  page,
  setPage,
  pageSize,
  setPageSize,
  orderBy,
  setOrderBy,
  sortOrder,
  setSortOrder
}: Props) => {
  const [showSpinner, setShowSpinner] = useState(false)

  const { player } = useAudioPlayer({ useAsMainPlayer: false })

  useEffect(() => {
    fetch(import.meta.env.VITE_SPEAKER_URL)
  }, [])

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => setShowSpinner(true), 500)
      return () => clearTimeout(timer)
    } else {
      setShowSpinner(false)
    }
  }, [loading])

  if (!player) {
    return null
  }
  const { initPlayer, play, playerState, stopPlayer } = player

  const header = [
    { value: 'name', sortable: true, label: 'Name' },
    { value: 'type', sortable: true, label: 'Type' },
    { value: 'description', sortable: false, label: 'Description' },
    { value: 'emotions', sortable: false, label: 'Emotions' },
    { label: '' }
  ]

  const body = aiVoices.map((aiVoice) => {
    const uri =
      aiVoice.exampleUrl ||
      buildSpeakerUri({
        aiVoiceId: aiVoice.id,
        text: aiVoice.sampleText || `My name is ${aiVoice.name}. ${aiVoice.description}`
      })

    const isPlayingCurrentTrack =
      playerState.value.isPlaying && playerState.value.currentTrackUri === uri

    const togglePlay = () => {
      if (isPlayingCurrentTrack) {
        stopPlayer()
      } else {
        initPlayer({ audioTracks: [{ uri }] })
        play({ audioTrackUri: uri })
      }
    }

    return [
      <Row alignItems="center">
        <AIAvatar size={30} src={aiVoice.picture} name={aiVoice.name} />
        <SpacerHorizontal small />
        <Block maxWidth="200px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {aiVoice.name}
        </Block>
      </Row>,
      <Block>{formatAIVoiceType(aiVoice.type)}</Block>,
      <Block
        color="var(--on-surface-light)"
        maxWidth="200px"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {aiVoice.description}
      </Block>,
      <Row alignItems="center" gap="5px" flexWrap="wrap" fontSize="10px">
        {aiVoice.emotions.map((emotion) => (
          <Block
            color="var(--on-surface-light)"
            padding="0 2px"
            border="solid 1px var(--on-surface-light)"
            borderRadius="5px"
          >
            {capitalize(emotion)}
          </Block>
        ))}
      </Row>,
      <IconButton
        loading={isPlayingCurrentTrack && playerState.value.loading}
        icon={isPlayingCurrentTrack ? StopIcon : PlayIcon}
        onClick={togglePlay}
        color={isPlayingCurrentTrack ? 'var(--accent)' : 'var(--on-surface)'}
      />
    ]
  })

  const handleSort = (column: string, sortOrder: 'asc' | 'desc') => {
    setOrderBy(column)
    setSortOrder(sortOrder)
    setPage(0)
  }

  return (
    <Block flex="1" position="relative">
      <ScrollableDataTable
        loading={showSpinner}
        header={header}
        body={body}
        sortedColumn={orderBy}
        sortOrder={sortOrder}
        onSort={handleSort}
        onClick={(index: number) => routeTo(`/ai-voice/${aiVoices[index].id}`)}
        pagination={{
          page,
          numRowsPerPage: pageSize,
          numRows: total,
          onPageChange: setPage,
          onNumRowsPerPageChange: setPageSize
        }}
        emptyText="No AI voices found"
      />
    </Block>
  )
}

export default AIVoicesTable
