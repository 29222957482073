import { Block } from 'jsxstyle/preact'
import { h } from 'preact'

export const HelpUseSceneSeqNumber = ({ fontSize }: { fontSize?: string }) => {
  return (
    <Block color="var(--on-surface-light)" fontSize={fontSize ?? ''}>
      If enabled, you are required to select a column called "Scene order" that specifies the order
      of lines within each scene.
      <br />
      <br />
      The selected column must contain numbers or text values with a numerical suffix (e.g.
      "scene1_001" where 001 is the numerical suffix). The lines will then be sorted in ascending
      order within the scene according to the numerical values.
    </Block>
  )
}
