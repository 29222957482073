import { Checkbox, Dialog, P, RadioButton, SpacerVertical } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'
import { useEffect, useState } from 'preact/hooks'
import {
  RestoreTakesFilter,
  RestoreTakesResult,
  VoiceType,
  restoreTakesByFilter
} from '../../actions'
import Spinner from '../Spinner'
import { useLocalStorageState } from 'src/use-local-storage-state'

type TakeSelectionType = 'subset' | 'all'

type RestoreTakesProps = {
  lineIds: string[]
  language: string
  onRestore: (result: RestoreTakesResult) => void
  onClose: () => void
}

export const RestoreTakes = ({ lineIds, language, onRestore, onClose }: RestoreTakesProps) => {
  const [isRestoring, setIsRestoring] = useState(false)

  const [takeSelectionType, setTakeSelectionType] = useLocalStorageState<
    TakeSelectionType | undefined
  >('speechless:lines:restore-takes:takeSelectionType', undefined)
  const [voiceTypes, setVoiceTypes] = useLocalStorageState<VoiceType[]>(
    'speechless:lines:restore-takes:voiceTypes',
    []
  )
  const [expectedResult, setExpectedResult] = useState<RestoreTakesResult | undefined>(undefined)
  const [selectedSubsetsCount, setSelectedSubsetsCount] = useState<number | undefined>(undefined)
  const [isRestoreButtonDisabled, setIsRestoreButtonDisabled] = useState(true)

  useEffect(() => {
    const filter: RestoreTakesFilter = {
      language
    }
    const dryRun = true
    restoreTakesByFilter(lineIds, filter, dryRun).then((result?: RestoreTakesResult) => {
      setExpectedResult(result)
    })
  }, [])

  useEffect(() => {
    const shouldDisable = () => {
      if (isRestoring) {
        return true
      }
      if (!takeSelectionType) {
        return true
      }
      if (takeSelectionType === 'subset' && (selectedSubsetsCount === 0 || !voiceTypes?.length)) {
        return true
      }
      if (takeSelectionType === 'all' && !expectedResult?.total) {
        return true
      }

      return false
    }

    setIsRestoreButtonDisabled(shouldDisable())
  }, [takeSelectionType, voiceTypes, isRestoring, selectedSubsetsCount, expectedResult])

  useEffect(() => {
    if (expectedResult) {
      let count = 0
      for (const vt of voiceTypes) {
        count += expectedResult?.voiceType?.[vt] ?? 0
      }
      setSelectedSubsetsCount(count)
    }
  }, [voiceTypes, expectedResult])

  const setVoiceType = (voiceType: VoiceType, enabled: boolean) => {
    let updatedVoiceTypes = [...voiceTypes]

    if (enabled) {
      if (!updatedVoiceTypes.includes(voiceType)) {
        updatedVoiceTypes.push(voiceType)
      }
    } else {
      updatedVoiceTypes = updatedVoiceTypes.filter((vt) => vt !== voiceType)
    }

    setVoiceTypes(updatedVoiceTypes)
  }

  const restoreAndClose = async () => {
    setIsRestoring(true)

    const filter: RestoreTakesFilter = {
      language,
      voiceType: takeSelectionType === 'subset' ? voiceTypes : undefined
    }
    const dryRun = false
    const result = await restoreTakesByFilter(lineIds, filter, dryRun)

    if (result) {
      onRestore(result)
    }
    setIsRestoring(false)
    onClose()
  }

  const labelWithCount = (label: string, count?: number): string => {
    if (count === undefined) {
      return label
    }
    return `${label} (${count})`
  }

  return (
    <Dialog
      title={`Restore takes${lineIds.length > 1 ? ' for ' + lineIds.length + ' lines' : ''}`}
      disableAutofocus
      actions={[{ text: 'Cancel', onClick: onClose }]}
      secondaryActions={[
        {
          text: 'Restore takes',
          type: 'contained',
          onClick: restoreAndClose,
          disabled: isRestoreButtonDisabled
        }
      ]}
      onClose={onClose}
    >
      {!isRestoring && (
        <>
          <P color="var(--on-surface-light)">Please select which takes you want to restore.</P>
          <SpacerVertical />
        </>
      )}
      {isRestoring && (
        <>
          <Block color="var(--on-surface-light)">Restoring takes…</Block>
          <Block position="relative" height="100px">
            <Spinner />
          </Block>
        </>
      )}
      {!isRestoring && (
        <>
          <RadioButton
            checked={takeSelectionType === 'subset'}
            label={labelWithCount('Restore by type', selectedSubsetsCount)}
            onChange={() => {
              setTakeSelectionType('subset')
            }}
          />
          <SpacerVertical tiny />
          <Block paddingLeft="20px">
            <Checkbox
              disabled={takeSelectionType !== 'subset'}
              checked={voiceTypes.includes('ai')}
              label={labelWithCount(
                'Generated by AI text-to-speech',
                expectedResult?.voiceType?.ai
              )}
              onChange={(checked: boolean) => setVoiceType('ai', checked)}
            />
            <Checkbox
              disabled={takeSelectionType !== 'subset'}
              checked={voiceTypes.includes('voice')}
              label={labelWithCount('Uploaded by voice artists', expectedResult?.voiceType?.voice)}
              onChange={(checked: boolean) => setVoiceType('voice', checked)}
            />
            <Checkbox
              disabled={takeSelectionType !== 'subset'}
              checked={voiceTypes.includes('user')}
              label={labelWithCount('Uploaded by project members', expectedResult?.voiceType?.user)}
              onChange={(checked: boolean) => setVoiceType('user', checked)}
            />
          </Block>
          <SpacerVertical />
          <RadioButton
            checked={takeSelectionType === 'all'}
            label={labelWithCount('Restore all takes', expectedResult?.total)}
            onChange={() => {
              setTakeSelectionType('all')
            }}
          />
          <SpacerVertical large />
        </>
      )}
    </Dialog>
  )
}
