import { store } from '../store'
import { del, get, patch, post } from '../api'
import { LineLabel } from 'src/types'

type FetchLineLabelsParams = {
  lineIds: string[]
}
export const fetchLineLabels = async (
  params?: FetchLineLabelsParams
): Promise<LineLabel[] | undefined> => {
  try {
    store.setState({ isFetchingLineLabels: true })
    const gameId = store.getState().currentGame?.id
    // Note: Must use POST because the request url can get too long for GET if there are many lineIds
    const { data: lineLabels } = await post(`/line-labels/v2`, { gameId, ...params })
    store.setState({ lineLabels, isFetchingLineLabels: false })
    return lineLabels
  } catch (err) {
    const error = err as Error
    store.setState({ error, isFetchingLineLabels: false })
  }
}

export const createLineLabel = async (name: string) => {
  try {
    store.setState({ isCreatingLineLabel: true })
    const gameId = store.getState().currentGame?.id
    const { data: lineLabels } = await post(`/line-labels`, { gameId, name })
    store.setState({ lineLabels, isCreatingLineLabel: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isCreatingLineLabel: false })
  }
}

export const updateLineLabel = async (lineLabelId: string, params: { name: string }) => {
  try {
    store.setState({ isUpdatingLineLabel: true })
    const { data: lineLabels } = await patch(`/line-labels/${lineLabelId}`, params)
    store.setState({ lineLabels, isUpdatingLineLabel: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isUpdatingLineLabel: false })
  }
}

export const deleteLineLabel = async (lineLabelId: string) => {
  try {
    store.setState({ isDeletingLineLabel: true })
    const { data: lineLabels } = await del(`/line-labels/${lineLabelId}`)
    store.setState({ lineLabels, isDeletingLineLabel: false })
    return true
  } catch (err) {
    const error = err as Error
    store.setState({ error, isDeletingLineLabel: false })
  }
}
