export const loudnessOptions = [
  {
    label: '-13 LUFS (very loud)',
    value: '-13'
  },
  {
    label: '-14 LUFS',
    value: '-14'
  },
  {
    label: '-15 LUFS',
    value: '-15'
  },
  {
    label: '-16 LUFS (Podcasts and Mobile)',
    value: '-16'
  },
  {
    label: '-18 LUFS (ReplayGain similarity)',
    value: '-18'
  },
  {
    label: '-19 LUFS',
    value: '-19'
  },
  {
    label: '-20 LUFS',
    value: '-20'
  },
  {
    label: '-23 LUFS, EBU R128 (TV, Europe)',
    value: '-23'
  },
  {
    label: '-24 LUFS, ATSC A/85 (TV, US)',
    value: '-24'
  },
  {
    label: '-26 LUFS',
    value: '-26'
  },
  {
    label: '-27 LUFS (Netflix)',
    value: '-27'
  },
  {
    label: '-31 LUFS (very quiet)',
    value: '-31'
  }
]
