import { h } from 'preact'
import { MutableRef, useEffect, useRef, useState } from 'preact/hooks'
import { useMatchMedia } from 'jsxstyle'
import { useSignal } from '@preact/signals'

const handleSize = { width: 6, height: 25 }
const handleOffset = 6

const LONG_PRESS_MS = 250
const CLICK_SENSITIVITY_THRESHOLD = 0.005

type Bounds = {
  x: number
  y: number
  width: number
  height: number
}

function useBounds(elem: MutableRef<HTMLDivElement | null>, zoom?: ZoomProps) {
  const [bounds, setBounds] = useState<Bounds | null>(null)

  useEffect(() => {
    if (elem.current) {
      const currElem = elem.current
      const b = currElem.getBoundingClientRect()
      const newBounds = {
        x: (b.x || b.left) + (zoom?.scrollOffsetLeft ?? 0),
        y: b.y || b.top,
        width: b.width,
        height: b.height
      }
      setBounds(newBounds)

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === currElem) {
            const b = currElem.getBoundingClientRect()
            const newBounds = {
              x: (b.x || b.left) + (zoom?.scrollOffsetLeft ?? 0),
              y: b.y || b.top,
              width: entry.contentRect.width,
              height: entry.contentRect.height
            }
            setBounds(newBounds)
          }
        }
      })
      resizeObserver.observe(currElem)
      return () => {
        resizeObserver.unobserve(currElem)
      }
    }
  }, [elem.current, zoom?.factor, zoom?.scrollOffsetLeft])

  useEffect(() => {
    function onWindowResize() {
      if (!elem.current) return
      const b = elem.current.getBoundingClientRect()
      const newBounds = {
        x: (b.x || b.left) + (zoom?.scrollOffsetLeft ?? 0),
        y: b.y || b.top,
        width: b.width,
        height: b.height
      }
      setBounds(newBounds)
    }
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [zoom?.factor, zoom?.scrollOffsetLeft])

  return bounds
}

function getViewportTime(duration: number, pos: number) {
  return { min: Math.max(0, duration * pos), max: duration }
}

const getTime = (value: number, audioBuffer: AudioBuffer, pos: number) => {
  // New time
  const { min: viewportMin, max: viewportMax } = getViewportTime(audioBuffer.duration, pos)
  let newTime = viewportMin + value * (viewportMax - viewportMin)
  if (newTime < viewportMin) {
    newTime = viewportMin
  } else if (newTime > viewportMax) {
    newTime = viewportMax
  }
  return newTime
}

type ZoomProps = {
  factor: number
  minFactor: number
  maxFactor: number
  zoomedCanvasHeight: number
  minCanvasHeight: number
  maxCanvasHeight: number
  scrollOffsetLeft: number
}

type Props = {
  audioBuffer: AudioBuffer
  startTime: number
  onChangeStart: (value: number, isMoving: boolean) => void
  endTime: number
  onChangeEnd: (value: number, isMoving: boolean) => void
  pos: number
  zoom?: ZoomProps
  disabled: boolean
  trimLocked: boolean
  isUpdatingTake: boolean
  padding?: number
  onPlay?: (start: number) => void
}

type MouseDownInfo = {
  event: MouseEvent | TouchEvent
  startValue: number
  endValue: number
}

export function AudioSelect({
  audioBuffer,
  startTime,
  onChangeStart,
  endTime,
  onChangeEnd,
  pos,
  zoom,
  disabled,
  trimLocked,
  isUpdatingTake,
  padding = 0,
  onPlay
}: Props) {
  const [mouseDownInfo, setMouseDownInfo] = useState<MouseDownInfo | undefined>()

  function getX(e: TouchEvent | any) {
    if (e && e.touches && e.touches.length === 0) return
    if (!bounds) return

    const clientX = e.touches ? e.touches[0].clientX : e.clientX
    const offset = clientX - bounds.x

    return offset
  }

  function calcValue(e: TouchEvent | any) {
    if (e && e.touches && e.touches.length === 0) return
    if (!bounds) return

    const clientX = e.touches ? e.touches[0].clientX : e.clientX
    const scrollOffset = zoom?.scrollOffsetLeft ?? 0
    const offset = clientX - bounds.x - padding + scrollOffset

    let newValue = offset / (bounds.width - padding * 2)

    if (newValue < 0) {
      return 0
    } else if (newValue > 1) {
      return 1
    }

    return newValue
  }

  function intersectsStartOrEnd(e: MouseEvent | TouchEvent) {
    if (!bounds) return

    const x = getX(e)
    if (!x) return

    const scrollOffset = zoom?.scrollOffsetLeft ?? 0

    const markerStartX =
      padding - scrollOffset - handleOffset + startValue * (bounds.width - padding * 2)
    const markerEndX =
      padding - scrollOffset + handleOffset + endValue * (bounds.width - padding * 2)

    const hitStart =
      x > markerStartX - handleSize.width / 2 - 10 && x < markerStartX + handleSize.width / 2 + 10
    const hitEnd =
      x > markerEndX - handleSize.width / 2 - 10 && x < markerEndX + handleSize.width / 2 + 10

    if (hitStart || hitEnd) {
      // Find closest marker
      const value = calcValue(e)
      if (value === undefined) return
      const dStart = Math.abs(startValue - value)
      const dEnd = Math.abs(endValue - value)
      const closest = Math.min(dStart, dEnd)
      if (closest === dStart) {
        return 'start'
      } else {
        return 'end'
      }
    }
  }

  const supportsHover = useMatchMedia('(hover: hover)')

  const isHovered = useSignal(false)
  const boundsElem = useRef<(HTMLDivElement & { base: HTMLDivElement }) | null>(null)
  const bounds = useBounds(boundsElem, zoom)

  const [startValue, setStartValue] = useState<number>(0)
  const [endValue, setEndValue] = useState<number>(1)

  const [isLongPress, setIsLongPress] = useState(false)
  const [longPressTimer, setLongPressTimer] = useState<NodeJS.Timeout | undefined>(undefined)

  const updateStart = (v: number, isMoving: boolean) => {
    if (!onChangeStart) return

    const value = Math.max(0, Math.min(1, v))
    const time = getTime(value, audioBuffer, pos)
    onChangeStart(time, isMoving)
  }

  const updateEnd = (v: number, isMoving: boolean) => {
    if (!onChangeEnd) return

    const value = Math.max(0, Math.min(1, v))
    const time = getTime(value, audioBuffer, pos)
    onChangeEnd(time, isMoving)
  }

  const [isMoving, setIsMoving] = useState(false)
  const [movingMarker, setMovingMarker] = useState<'start' | 'end' | undefined>()

  const calculateTimePercent = (timeSeconds: number) => {
    const { min: viewportMin, max: viewportMax } = getViewportTime(audioBuffer.duration, pos)
    const timePercent = (timeSeconds - viewportMin) / (viewportMax - viewportMin)
    return timePercent
  }

  // Update selection when external data changes
  useEffect(() => {
    if (!bounds || isMoving) return

    // Start
    const start = calculateTimePercent(startTime)
    setStartValue(start)

    // End
    const end = calculateTimePercent(endTime)
    setEndValue(end)
  }, [bounds, startTime, endTime, isMoving, audioBuffer, pos, zoom])

  function onMouseDown(e: MouseEvent | TouchEvent) {
    const value = calcValue(e)

    if (value === undefined) return

    const marker = intersectsStartOrEnd(e)

    const localMouseDownInfo: MouseDownInfo = {
      event: e,
      startValue,
      endValue
    }
    setMouseDownInfo(localMouseDownInfo)

    const playOnClick = ({
      mouseUpEvent,
      mouseDownEvent
    }: {
      mouseDownEvent: MouseEvent | TouchEvent
      mouseUpEvent: MouseEvent | TouchEvent
    }) => {
      const isLongPress = mouseUpEvent.timeStamp - mouseDownEvent.timeStamp >= LONG_PRESS_MS
      if (isLongPress) {
        return
      }

      const mouseUpNormalizedValue = Math.max(0, Math.min(1, calcValue(mouseUpEvent) ?? 0))
      const mouseUpAbsoluteValue = getTime(mouseUpNormalizedValue, audioBuffer, pos)
      const mouseDownNormalizedValue = Math.max(
        0,
        Math.min(1, calcValue(localMouseDownInfo.event) ?? 0)
      )
      const mouseDownAbsoluteValue = getTime(mouseDownNormalizedValue, audioBuffer, pos)

      const isClick =
        Math.abs(mouseDownNormalizedValue - mouseUpNormalizedValue) < CLICK_SENSITIVITY_THRESHOLD
      if (isClick) {
        if (mouseDownNormalizedValue >= startValue && mouseDownNormalizedValue <= endValue) {
          if (onPlay) {
            onPlay(mouseDownAbsoluteValue)
          }
        }
      }
    }

    if (trimLocked) {
      // Setup a specific mouse up listener when trim is locked, only to be able to start playing from click
      let mouseUpListener: (e: MouseEvent | TouchEvent) => void
      mouseUpListener = (e) => {
        window.removeEventListener('mouseup', mouseUpListener)
        playOnClick({ mouseDownEvent: localMouseDownInfo.event, mouseUpEvent: e })
      }
      window.addEventListener('mouseup', mouseUpListener)
    } else {
      if (marker) {
        setMovingMarker(marker)
        setIsMoving(true)
      } else {
        let mouseUpListener: (e: MouseEvent | TouchEvent) => void

        const timer = setTimeout(() => {
          window.removeEventListener('mouseup', mouseUpListener)
          setLongPressTimer(undefined)

          // Prevent starting a new selection while take is being updated,
          // beause the selection will become incorrect when take is done updating.
          if (!isUpdatingTake) {
            setIsLongPress(true)
            setStartValue(value)
            setEndValue(value)
            updateStart(value, true)
            updateEnd(value, true)
            setMovingMarker('end')
            setIsMoving(true)
          }
        }, LONG_PRESS_MS)

        mouseUpListener = (e) => {
          window.removeEventListener('mouseup', mouseUpListener)
          clearTimeout(timer)
          setLongPressTimer(undefined)

          playOnClick({ mouseDownEvent: localMouseDownInfo.event, mouseUpEvent: e })
        }
        window.addEventListener('mouseup', mouseUpListener)

        setIsLongPress(false)
        setLongPressTimer(timer)
      }
    }
  }

  useEffect(() => {
    if (isMoving) {
      function handleMoving(e: MouseEvent | TouchEvent) {
        const value = calcValue(e)
        if (value === undefined) return

        if (movingMarker === 'start') {
          if (value > endValue) {
            setMovingMarker('end')
            setEndValue(value)
            updateEnd(value, true)
          } else {
            setStartValue(value)
            updateStart(value, true)
          }
        } else if (movingMarker === 'end') {
          if (value < startValue) {
            setMovingMarker('start')
            setStartValue(value)
            updateStart(value, true)
          } else {
            setEndValue(value)
            updateEnd(value, true)
          }
        } else {
          // NOTE: Really needed?!
          setMovingMarker('end')
          setEndValue(value)
          updateEnd(value, true)
        }
      }

      function onMouseUp(e: MouseEvent | TouchEvent) {
        if (!mouseDownInfo) {
          return
        }

        setLongPressTimer(undefined)

        const isClick = Math.abs(startValue - endValue) < CLICK_SENSITIVITY_THRESHOLD
        if (isClick) {
          // Reset start/end to pre-click values if start and end are "the same"
          setStartValue(mouseDownInfo.startValue)
          setEndValue(mouseDownInfo.endValue)
          updateStart(mouseDownInfo.startValue, false)
          updateEnd(mouseDownInfo.endValue, false)
        } else {
          if (movingMarker === 'start') {
            updateStart(startValue, false)
          } else if (movingMarker === 'end') {
            updateEnd(endValue, false)
          }
        }
        // Remove move listeners to prevent being fired
        window.removeEventListener('mousemove', handleMoving)
        window.removeEventListener('touchmove', handleMoving)
        setMovingMarker(undefined)
        setIsMoving(false)
        setMouseDownInfo(undefined)
      }

      window.addEventListener('mousemove', handleMoving)
      window.addEventListener('touchmove', handleMoving)
      window.addEventListener('mouseup', onMouseUp)
      window.addEventListener('touchend', onMouseUp)
      return () => {
        window.removeEventListener('mousemove', handleMoving)
        window.removeEventListener('touchmove', handleMoving)
        window.removeEventListener('mouseup', onMouseUp)
        window.removeEventListener('touchend', onMouseUp)
      }
    }
  }, [bounds, padding, isMoving, startValue, endValue, movingMarker])

  function handleMouseEnter(e: MouseEvent) {
    isHovered.value = true
  }

  function handleMouseLeave(e: MouseEvent) {
    isHovered.value = false
  }

  function handleMouseMove(e: MouseEvent) {
    if (!isMoving) {
      setMovingMarker(intersectsStartOrEnd(e))
    }
  }

  let selectedAreaHeight = 0
  if (bounds) {
    if (zoom) {
      selectedAreaHeight = zoom.zoomedCanvasHeight
    } else {
      selectedAreaHeight = bounds.height
    }
  }

  const boundsElemTop =
    bounds && zoom ? `${zoom.maxCanvasHeight / 2 - selectedAreaHeight / 2}px` : '0'

  return (
    <div
      style={{
        userSelect: 'none',
        position: 'absolute',
        cursor: trimLocked ? 'default' : movingMarker ? 'ew-resize' : 'text',
        overflow: 'visible',
        left: '0',
        width: `${(zoom?.factor ?? 1) * 100}%`,
        top: boundsElemTop,
        height: selectedAreaHeight ? `${selectedAreaHeight}px` : '100%'
      }}
      ref={boundsElem}
      onMouseDown={!disabled ? onMouseDown : undefined}
      onMouseEnter={!disabled && supportsHover ? handleMouseEnter : undefined}
      onMouseLeave={!disabled && isHovered.value ? handleMouseLeave : undefined}
      onMouseMove={!disabled && isHovered.value ? handleMouseMove : undefined}
      onTouchStart={!disabled ? onMouseDown : undefined}
    >
      {bounds && (
        <div>
          {/* Right/End handle */}
          <div
            style={{
              position: 'absolute',
              top: `${bounds.height / 2 - handleSize.height / 2}px`,
              left: `${
                endValue !== null
                  ? padding +
                    handleOffset +
                    endValue * (bounds.width - padding * 2) -
                    handleSize.width / 2
                  : 0
              }px`,
              width: `${handleSize.width}px`,
              height: `${handleSize.height}px`,
              background: 'var(--on-surface)',
              boxShadow: '0px 1px 6px var(--box-shadow-color)',
              borderRadius: '3px',
              visibility: trimLocked ? 'hidden' : undefined
            }}
          />
          {/* Left/start handle */}
          <div
            style={{
              position: 'absolute',
              top: `${bounds.height / 2 - handleSize.height / 2}px`,
              left: `${
                startValue !== null
                  ? padding -
                    handleOffset +
                    startValue * (bounds.width - padding * 2) -
                    handleSize.width / 2
                  : 0
              }px`,
              width: `${handleSize.width}px`,
              height: `${handleSize.height}px`,
              background: 'var(--on-surface)',
              boxShadow: '0px 1px 6px var(--box-shadow-color)',
              borderRadius: '3px',
              visibility: trimLocked ? 'hidden' : undefined
            }}
          />
        </div>
      )}
    </div>
  )
}
