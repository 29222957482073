import { CheckmarkIcon } from '@sodra/bongo-ui'
import { Inline, Row } from 'jsxstyle/preact'
import { lineRecorder } from './LineRecorder'
import { TooltipWrapper } from './TooltipWrapper'

export function LineStats() {
  if (!lineRecorder.value) {
    return null
  }

  const allRecorded =
    lineRecorder.value.numLinesWithSelectedTake.value === lineRecorder.value.numLines.value

  return (
    <TooltipWrapper tooltipText="with selected take / with take / total">
      <Row alignItems="center" gap="8px" padding="10px">
        {allRecorded && <CheckmarkIcon fill="var(--accent)" />}
        Lines recorded:
        <Inline userSelect="none" color={'var(--accent)'}>
          {lineRecorder.value.numLinesWithSelectedTake.value}
        </Inline>
        /
        <Inline userSelect="none" color={'var(--on-surface-light)'}>
          {lineRecorder.value.numLinesWithTakes.value}
        </Inline>
        /
        <Inline userSelect="none" color={'var(--on-surface)'}>
          {lineRecorder.value.numLines.value}
        </Inline>
      </Row>
    </TooltipWrapper>
  )
}
