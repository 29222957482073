import { h } from 'preact'
import { useState } from 'preact/hooks'
import { BasicDialog, Form, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { updateExampleLine } from '../../actions'
import { ExampleLine } from '../../types'
import { useStore } from '../../store'

type Props = {
  exampleLine: ExampleLine
  onClose: () => void
}

export const EditExampleLine = ({ exampleLine, onClose }: Props) => {
  const { isUpdatingExampleLine } = useStore('isUpdatingExampleLine')
  const [line, setLine] = useState(exampleLine.line)
  const [description, setDescription] = useState(exampleLine.description)

  const [lineError, setLineError] = useState('')

  const handleSubmit = async () => {
    setLineError('')

    if (line.trim() === '') {
      setLineError('Please enter line')
      return
    }

    if (await updateExampleLine(exampleLine.id, { line, description })) {
      onClose()
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      dismissable={false}
      title="Edit audition line"
      primaryActionText="Save"
      primaryActionLoading={isUpdatingExampleLine}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <TextField
          autocomplete="off"
          width="100%"
          label="Line"
          value={line}
          onInput={setLine}
          errorText={lineError}
        />
        <SpacerVertical />
        <TextField
          autocomplete="off"
          width="100%"
          label="Acting notes"
          value={description}
          onInput={setDescription}
        />
      </Form>
    </BasicDialog>
  )
}

export default EditExampleLine
