import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import { AddIcon, Button, DeleteIcon, List, ListItem, MoreIcon, PlusIcon } from '@sodra/bongo-ui'
import { goBack, Route, routeTo, Switch } from '@sodra/prutt'

import { deleteLineLabel, fetchLineLabels, showSnackbar } from '../../../actions'

import Page from '../../Page'
import { useStore } from '../../../store'
import { CreateLineLabel } from './CreateLineLabel'
import { UpdateLineLabel } from './UpdateLineLabel'
import { Block } from 'jsxstyle/preact'
import { LineLabel } from '../../../types'
import { confirm, ConfirmMessage } from 'lib'
import { capitalize } from '../../capitalize'
import { BuiClickEvent, usePopupMenu } from '../../use-popup-menu'
import { pluralize } from 'lib'

export const LineLabels = () => {
  const { currentGame, lineLabels } = useStore('currentGame', 'lineLabels')

  useEffect(() => {
    fetchLineLabels()
  }, [currentGame!.id])

  const onBack = () => goBack('/lines')

  const handleDeleteLabel = async (lineLabel: LineLabel) => {
    const confirmMessage: ConfirmMessage = {
      message: `Delete label "${capitalize(lineLabel.name)}"?`,
      confirmText: 'Delete'
    }
    if (lineLabel.numLines > 0) {
      confirmMessage.message += ` It will be removed from ${lineLabel.numLines} lines.`
    }
    if (await confirm(confirmMessage)) {
      if (await deleteLineLabel(lineLabel.id)) {
        showSnackbar(`Label ${capitalize(lineLabel.name)} deleted`)
      }
    }
  }

  const popupMenuOptions = [
    {
      label: 'Edit',
      onClick: (label: LineLabel) => routeTo(`/lines/line-labels/${label.id}`)
    },
    {
      label: 'Delete',
      onClick: handleDeleteLabel
    }
  ]

  const { PopupMenu, showMenu } = usePopupMenu({
    options: popupMenuOptions
  })

  return (
    <Page
      title="Line labels"
      onBack={onBack}
      actions={[
        {
          label: 'Add',
          icon: AddIcon,
          link: {
            href: '/lines/line-labels/add',
            onRoute: routeTo
          }
        }
      ]}
    >
      <Block maxWidth="500px">
        {lineLabels && lineLabels.length === 0 && (
          <Block color="var(--on-surface-light)" fontSize="14px">
            No line labels created yet
          </Block>
        )}
        <List>
          {lineLabels?.map((lineLabel) => {
            let secondaryText
            if (lineLabel.numLines === 0) {
              secondaryText = 'Not used'
            } else {
              secondaryText = `Used by ${lineLabel.numLines} ${pluralize(
                'line',
                lineLabel.numLines
              )}`
            }

            return (
              <ListItem
                text={capitalize(lineLabel.name)}
                secondaryText={secondaryText}
                actionIcon={MoreIcon}
                onActionClick={(event: BuiClickEvent) => showMenu(event, lineLabel)}
              />
            )
          })}
        </List>
        <Button
          icon={PlusIcon}
          link={{
            href: '/lines/line-labels/add',
            onRoute: routeTo
          }}
        >
          Add
        </Button>
      </Block>
      {PopupMenu}
      <Switch>
        <Route path="/lines/line-labels/add" component={CreateLineLabel} />
        <Route path="/lines/line-labels/:lineLabelId" component={UpdateLineLabel} />
      </Switch>
    </Page>
  )
}
