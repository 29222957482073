import { h } from 'preact'
import { Link, List, ListItem, SpacerVertical } from '@sodra/bongo-ui'
import { useState } from 'preact/hooks'
import { routeTo } from '@sodra/prutt'

import { useFetchResult } from '../use-fetch-result'
import { addToShortlist, showSnackbar } from '../actions'

import LargeDialog from './LargeDialog'
import Avatar from './Avatar'
import SearchTextField from './SearchTextField'
import { Block } from 'jsxstyle/preact'
import { Character, Voice } from '../types'
import { useStore } from '../store'

type Props = {
  voice: Voice
  onClose: () => void
  onSuccess: () => void
  title?: string
}

export const ShortlistVoice = ({ voice, onSuccess, onClose, title }: Props) => {
  const { currentGame } = useStore('currentGame')
  const [query, setQuery] = useState('')

  const { data: characters } = useFetchResult<Character[]>(`/games/${currentGame!.id}/characters`, {
    query
  })

  const handleShortlist = async (character: Character) => {
    if (await addToShortlist(character.id, voice.id)) {
      showSnackbar(`${voice.name} shortlisted for ${character.name}`, {
        text: 'Open',
        onClick: () => routeTo(`/character/${character.id}`)
      })
      if (onSuccess) {
        onSuccess()
      }
      onClose()
    }
  }

  return (
    <LargeDialog
      title={title ?? `Shortlist ${voice.name} for…`}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <SearchTextField label="Search character" value={query} onChange={setQuery} />
      <SpacerVertical />
      {characters && characters.length > 0 && (
        <>
          <Block>Select character</Block>
          <SpacerVertical />

          <List>
            {characters.map((character) => {
              return (
                <ListItem
                  visual={<Avatar size={30} src={character.picture} name={character.name} />}
                  text={character.name}
                  onClick={() => handleShortlist(character)}
                />
              )
            })}
          </List>
        </>
      )}

      {characters && characters.length === 0 && (
        <>
          <Block color="var(--on-surface-light)">No characters found</Block>
          <SpacerVertical />
          <Link to="/create-character" onRoute={routeTo}>
            Create new character
          </Link>
        </>
      )}
      <SpacerVertical />
    </LargeDialog>
  )
}

export default ShortlistVoice
