import { h } from 'preact'
import { useState } from 'preact/hooks'
import { BasicDialog, SelectChip } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

import { useEvent } from '../../use-event'
import { addLinesToEvent, showSnackbar } from '../../actions'

import SelectEvent from '../SelectEvent'
import { useStore } from '../../store'

type Props = {
  onClose: () => void
  onSuccess: () => void
  lineIds: string[]
}

export const AddToEvent = ({ onClose, onSuccess, lineIds }: Props) => {
  const { isAddingLinesToEvent } = useStore('currentGame', 'isAddingLinesToEvent')

  const { eventId, event, setEvent } = useEvent()

  const [selectEventVisible, setSelectEventVisible] = useState(false)

  if (lineIds.length === 0) {
    onClose()
  }

  const handleSubmit = async () => {
    if (await addLinesToEvent(eventId, lineIds)) {
      showSnackbar('Event updated', {
        text: 'Open',
        onClick: () => routeTo(`/event/${eventId}`)
      })
      onSuccess()
      onClose()
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      title="Add lines to event"
      primaryActionText="Add lines"
      primaryActionLoading={isAddingLinesToEvent}
      primaryActionDisabled={!eventId}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      {event && (
        <SelectChip
          value={event.name}
          onClick={() => setSelectEventVisible(true)}
          onClear={() => setEvent()}
        />
      )}
      {!event && <SelectChip label="Select Event" onClick={() => setSelectEventVisible(true)} />}
      {selectEventVisible && (
        <SelectEvent onClose={() => setSelectEventVisible(false)} onSelect={setEvent} />
      )}
    </BasicDialog>
  )
}
