import { store } from '../store'
import { get, post, patch, del, put } from '../api'

export const fetchEvents = async (params) => {
  let cancelled = false
  store.setState({ isFetchingEvents: true })
  const gameId = store.getState().currentGame.id
  get(`/games/${gameId}/events`, params)
    .then(({ data: events, meta: { total } }) => {
      if (!cancelled) {
        store.setState({ events, totalEvents: total, isFetchingEvents: false })
      }
    })
    .catch((error) => {
      if (!cancelled) {
        store.setState({ error, isFetchingEvents: false })
      }
    })
  return () => {
    store.setState({ isFetchingEvents: false })
    cancelled = true
  }
}

export const fetchEvent = async (eventId) => {
  try {
    store.setState({ isFetchingEvent: true })
    const { data: event } = await get(`/events/${eventId}`)
    store.setState({ event, isFetchingEvent: false })
    return event
  } catch (error) {
    store.setState({ error, isFetchingEvent: false })
  }
}

export const createEvent = async (params) => {
  try {
    store.setState({ isCreatingEvent: true })
    const gameId = store.getState().currentGame.id
    const { data: event } = await post(`/games/${gameId}/events`, params)
    store.setState({ event, isCreatingEvent: false })
    return event
  } catch (error) {
    store.setState({ error, isCreatingEvent: false })
  }
}

export const updateEvent = async (params) => {
  try {
    store.setState({ isUpdatingEvent: true })
    const eventId = store.getState().event.id
    const { data: event } = await patch(`/events/${eventId}`, params)
    store.setState({ event, isUpdatingEvent: false })
    return event
  } catch (error) {
    store.setState({ error, isUpdatingEvent: false })
  }
}

export const deleteEvent = async () => {
  try {
    store.setState({ isDeletingEvent: true })
    const eventId = store.getState().event.id
    await del(`/events/${eventId}`)
    store.setState({ isDeletingEvent: false })
    return true
  } catch (error) {
    store.setState({ error, isDeletingEvent: false })
  }
}

export const addLinesToEvent = async (eventId, lineIds) => {
  try {
    store.setState({ isAddingLinesToEvent: true })
    const { data: event } = await put(`/events/${eventId}/lines`, { lineIds })
    store.setState({ event, isAddingLinesToEvent: false })
    return event
  } catch (error) {
    store.setState({ error, isAddingLinesToEvent: false })
  }
}
