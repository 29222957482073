import { useEffect, useState } from 'preact/hooks'
import { get } from './api'
import { AIVoice } from './types'

export const useAIVoice = (initialAIVoiceId?: string) => {
  const [aiVoiceId, setAIVoiceId] = useState(initialAIVoiceId)
  const [aiVoice, setAIVoice] = useState<AIVoice | undefined>()

  useEffect(() => {
    if (aiVoiceId && (!aiVoice || aiVoice.id !== aiVoiceId)) {
      get(`/ai-voices/${aiVoiceId}`).then(({ data: aiVoice }) => setAIVoice(aiVoice))
    }
    if (!aiVoiceId) {
      setAIVoice(undefined)
    }
  }, [aiVoiceId])

  return {
    aiVoiceId,
    setAIVoiceId,
    aiVoice,
    setAIVoice: (aiVoice?: AIVoice) => {
      setAIVoice(aiVoice)
      if (aiVoice) {
        setAIVoiceId(aiVoice.id)
      } else {
        setAIVoiceId(undefined)
      }
    },
    clearAIVoice: () => {
      setAIVoiceId(undefined)
      setAIVoice(undefined)
    }
  }
}
