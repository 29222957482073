import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import { routeTo } from '@sodra/prutt'

import Avatar from '../Avatar'
import { Order } from '../../types'
import { ScrollableDataTable } from '../ScrollableDataTable'
import { useStore } from '../../store'
import { formatCurrency, formatDate, formatTimeWithTimezone } from 'lib'
import { Link } from '@sodra/bongo-ui'
import { formatOrderState } from './format-order-state'

type Props = {
  loading: boolean
  orders: Order[]
  total: number
  pageSize?: number
  page: number
  setPage: (newPage: number) => void
  orderBy: string
  setOrderBy: (orderBy: string) => void
  sortOrder: string
  setSortOrder: (sortOrder: 'asc' | 'desc') => void
}

const OrdersTable = ({
  loading,
  orders,
  total,
  pageSize = 10,
  page,
  setPage,
  orderBy,
  setOrderBy,
  sortOrder,
  setSortOrder
}: Props) => {
  const { currentGame } = useStore('currentGame')

  const timeZone = currentGame?.timeZone

  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => setShowSpinner(true), 500)
      return () => clearTimeout(timer)
    } else {
      setShowSpinner(false)
    }
  }, [loading])

  const header = [
    { value: 'created', sortable: true, label: 'Date', startSortOrder: 'desc' },
    { value: 'name', sortable: true, label: 'Order name' },
    {
      value: 'state',
      label: 'State',
      style: { minWidth: '150px' }
    },
    // { value: 'game', label: 'Game', style: { minWidth: '200px' } },
    {
      value: 'amount',
      sortable: true,
      startSortOrder: 'desc',
      label: (
        <Block>
          Billing amount
          <Block fontSize="calc(1rem/1.25)" color="var(--on-surface-light)">
            excl. VAT
          </Block>
        </Block>
      ),
      style: { minWidth: '180px', textAlign: 'right' }
    },
    {
      value: 'voice_name',
      sortable: true,
      label: 'Supplier ref.',
      style: { minWidth: '200px', textAlign: 'left' }
    },
    // { value: 'receipt', label: 'Receipt/invoice details', style: { minWidth: '200px' } },
    { value: 'specification', label: 'Specification' }
  ]

  const body = orders.map((order) => {
    let supplierRef
    if (order.voice) {
      supplierRef = (
        <Row alignItems="center" gap={10}>
          <Avatar size={30} src={order.voice.picture} name={order.voice.name} />
          {order.voice.name}
        </Row>
      )
    }

    let specification
    if (order.specificationUri) {
      specification = (
        <Block
          minWidth="150px"
          props={{
            /* NOTE: Prevent click from opening order */
            onClick: (e: MouseEvent) => e.stopPropagation()
          }}
        >
          <Link to={order.specificationUri} target="_blank" stopPropagation={true}>
            Order no. {order.shortId}
          </Link>
        </Block>
      )
    }

    let invoiceDetails
    if (order.invoiceDetailsUri) {
      invoiceDetails = (
        <Link
          to={order.invoiceDetailsUri}
          onClick={(e: MouseEvent) => {
            console.log('Apa')
            //e.stopPropagation()
            if (order.invoiceDetailsUri) {
              routeTo(order.invoiceDetailsUri)
            }
          }}
          target="_blank"
        >
          Invoice details {order.invoiceNumber}
        </Link>
      )
    }

    const cols = [
      <Block color="var(--on-surface-light)" whiteSpace="nowrap">
        {`${formatDate(order.created, timeZone)} ${formatTimeWithTimezone(
          order.created,
          timeZone
        )}`}
      </Block>,
      <Block minWidth="180px">{order.name}</Block>,
      <Block color="var(--on-surface-light)">{formatOrderState(order.state)}</Block>,
      // order.game?.name,
      formatCurrency(order.amount),
      supplierRef,
      // invoiceDetails,
      specification
    ]
    return cols
  })

  const handleSort = (column: string, sortOrder: 'asc' | 'desc') => {
    setOrderBy(column)
    setSortOrder(sortOrder)
    setPage(0)
  }

  return (
    <Block flex="1" position="relative">
      <ScrollableDataTable
        loading={showSpinner}
        header={header}
        body={body}
        sortedColumn={orderBy}
        sortOrder={sortOrder}
        onSort={handleSort}
        onClick={(index: number) => routeTo(`/orders/${orders[index].id}`)}
        pagination={{
          page,
          numRowsPerPage: pageSize,
          numRows: total,
          onPageChange: setPage
        }}
        emptyText="No orders found"
      />
    </Block>
  )
}

export default OrdersTable
