import { h } from 'preact'
import { useState } from 'preact/hooks'
import { SearchIcon, TextField } from '@sodra/bongo-ui'

import { useEffectSkipFirst } from '../use-effect-skip-first'

type Props = {
  value: string
  onChange: (value: string) => void
  width?: string
  label?: string
}

export const SearchTextField = ({ value, onChange, label, ...props }: Props) => {
  const [v, setV] = useState(value)

  useEffectSkipFirst(() => {
    const timeout = setTimeout(() => onChange(v), 500)
    return () => clearTimeout(timeout)
  }, [v])

  return (
    <TextField
      clearable
      width="500px"
      maxWidth="100%"
      autocomplete="off"
      icon={SearchIcon}
      label={label ?? 'Search…'}
      {...props}
      value={v}
      onChange={onChange}
      onInput={setV}
    />
  )
}

export default SearchTextField
