import { h } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import { Block } from 'jsxstyle/preact'
import { drawOscilloscope } from './drawOscilloScope'

type WaveformProps = {
  color?: string
  stream: MediaStream
  width?: string
  height?: string
  active?: boolean
}

export function OscilloScope({
  stream,
  color = '#eee',
  width = '100%',
  height = '100%'
}: WaveformProps) {
  const frameIdRef = useRef<number>()
  const canvasElem = useRef<HTMLCanvasElement | null>(null)
  const analyserRef = useRef<AnalyserNode | null>(null)
  const dataArrayRef = useRef<Uint8Array | null>(null)

  const draw = () => {
    if (!analyserRef.current) return
    if (!canvasElem.current) return
    if (!dataArrayRef.current) return

    const analyser = analyserRef.current
    const canvas = canvasElem.current
    const canvasCtx = canvas.getContext('2d')
    if (!canvasCtx) return

    const WIDTH = canvas.width
    const HEIGHT = canvas.height

    analyser.getByteFrequencyData(dataArrayRef.current)

    canvasCtx.clearRect(0, 0, WIDTH, HEIGHT)

    canvasCtx.fillStyle = color

    let waveSum = 0
    let waveMax = 0
    for (let i = 0; i < dataArrayRef.current.length; i++) {
      waveSum += dataArrayRef.current[i]
      if (dataArrayRef.current[i] > waveMax) waveMax = dataArrayRef.current[i]
    }

    const w = 2

    canvasCtx.fillRect(WIDTH - w, HEIGHT / 2, w, -(waveSum / 2 / dataArrayRef.current.length))
    canvasCtx.fillRect(WIDTH - w, HEIGHT / 2 - 1, w, waveSum / 2 / dataArrayRef.current.length)

    analyser.getByteTimeDomainData(dataArrayRef.current)

    drawOscilloscope({
      analyser,
      ctx: canvasCtx,
      dataArray: dataArrayRef.current,
      x: 0,
      y: 0,
      width: WIDTH,
      height: HEIGHT
    })
  }

  const tick = () => {
    if (!canvasElem.current) return
    draw()
    frameIdRef.current = requestAnimationFrame(tick)
  }

  // Set up animation loop
  useEffect(() => {
    frameIdRef.current = requestAnimationFrame(tick)
    return () => {
      if (!frameIdRef.current) return
      cancelAnimationFrame(frameIdRef.current)
    }
  }, [draw])

  // Canvas resize observer
  useEffect(() => {
    if (!canvasElem.current) return
    const canvas = canvasElem.current
    canvas.width = canvas.width * window.devicePixelRatio
    canvas.height = canvas.height * window.devicePixelRatio

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === canvasElem.current) {
          canvas.width = entry.contentRect.width * window.devicePixelRatio
          canvas.height = entry.contentRect.height * window.devicePixelRatio
        }
      }
    })
    resizeObserver.observe(canvasElem.current)

    return () => {
      if (!canvasElem.current) return
      resizeObserver.unobserve(canvasElem.current)
    }
  }, [canvasElem.current])

  // Set up audio context and analyser node
  useEffect(() => {
    if (!stream) return

    let audioCtx = new AudioContext()

    const source = audioCtx.createMediaStreamSource(stream)

    const analyser = audioCtx.createAnalyser()
    analyser.smoothingTimeConstant = 0
    analyser.fftSize = 2048
    const bufferLength = analyser.frequencyBinCount
    dataArrayRef.current = new Uint8Array(bufferLength)

    source.connect(analyser)

    analyserRef.current = analyser

    return () => {
      analyser.disconnect()
      source.disconnect()
      audioCtx.close()
      analyserRef.current = null
      dataArrayRef.current = null
    }
  }, [stream])

  return <Block component="canvas" props={{ ref: canvasElem }} width={width} height={height} />
}
