import { h } from 'preact'
import { List, ListItem, PlusIcon, SpacerVertical } from '@sodra/bongo-ui'

import LargeDialog from './LargeDialog'
import { BillingAccount } from '../types'

type Props = {
  onClose: () => void
  billingAccounts: BillingAccount[]
  onSelect: (billingAccount: BillingAccount) => Promise<boolean>
  onCreateBillingAccount: () => void
}

export const SelectBillingAccount = ({
  billingAccounts,
  onClose,
  onSelect,
  onCreateBillingAccount
}: Props) => {
  return (
    <LargeDialog
      large
      title="Select billing account"
      onClose={onClose}
      action1Text="Cancel"
      onAction1Click={onClose}
      secondaryActionText={'New billing account'}
      secondaryActionIcon={PlusIcon}
      onSecondaryActionClick={onCreateBillingAccount}
    >
      <SpacerVertical />
      {billingAccounts && (
        <List>
          {billingAccounts.map((billingAccount) => {
            return (
              <ListItem
                text={billingAccount.name}
                onClick={async () => {
                  if (await onSelect(billingAccount)) {
                    onClose()
                  }
                }}
              />
            )
          })}
        </List>
      )}
      <SpacerVertical />
    </LargeDialog>
  )
}

export default SelectBillingAccount
