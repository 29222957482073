import { h } from 'preact'
import { useState } from 'preact/hooks'
import { Row } from 'jsxstyle/preact'
import { goBack } from '@sodra/prutt'
import { Button, Form, SpacerHorizontal, SpacerVertical, TextField } from '@sodra/bongo-ui'

import { showSnackbar, updateUser } from '../../actions'

import Page from '../Page'
import { useStore } from '../../store'

export const ChangePassword = () => {
  const { isUpdatingUser } = useStore('isUpdatingUser')
  const [password, setPassword] = useState('')

  const onBack = () => goBack('/account')

  const handleSubmit = async () => {
    if (await updateUser({ password })) {
      showSnackbar('Password changed')
      onBack()
    }
  }

  return (
    <Page title="Change password" onBack={onBack}>
      <Form maxWidth="500px" onSubmit={handleSubmit}>
        <TextField
          width="100%"
          type="password"
          name="new-password"
          label="New password"
          value={password}
          onInput={setPassword}
        />
        <SpacerVertical />
        <Row alignItems="center">
          <Button type="submit" contained loading={isUpdatingUser}>
            Save
          </Button>
          <SpacerHorizontal />
          <Button onClick={onBack}>Cancel</Button>
        </Row>
      </Form>
    </Page>
  )
}

export default ChangePassword
