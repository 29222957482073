import {
  Button,
  Checkbox,
  ExpandLessIcon,
  ExpandMoreIcon,
  Link,
  List,
  ListItem,
  NativeSelect,
  ProgressCircular,
  SpacerHorizontal,
  SpacerVertical,
  WarningIcon
} from '@sodra/bongo-ui'
import { Block, Col, Row } from 'jsxstyle/preact'
import { h } from 'preact'
import { confirm, formatLanguage, pluralize } from 'lib'
import { useEffect, useMemo, useState } from 'preact/hooks'
import { useStore } from '../../store'
import { capitalize } from '../capitalize'
import { ScrollableDataTable } from '../ScrollableDataTable'
import { setError, updateGame } from '../../actions'
import { get } from '../../api'
import { validate as validateUuid } from 'uuid'
import { SheetWithRows } from 'src/types'
import { HelpDeleteMissingLines } from '../HelpDeleteMissingLines'
import { HelpExternalLineId } from '../HelpExternalLineId'
import { HelpUseSceneSeqNumber } from '../HelpUseSceneSeqNumber'
import { routeTo } from '@sodra/prutt'

export type MapColumnsParams = {
  firstRowIsHeader: boolean
  generateUniqueFilenames: boolean
  generateAiTakesForNewLines: boolean
  generateAiTakesForUpdatedLines: boolean
  useAsSelectedTake: boolean
  useExternalLineId: boolean
  deleteMissingLines: boolean
  deleteUnusedCharacters: boolean
  deleteUnusedEvents: boolean
  useSceneSeqNumber: boolean
  columns: string[]
}

type Props = {
  firstRowIsHeader?: boolean
  generateUniqueFilenames?: boolean
  generateAiTakesForNewLines?: boolean
  generateAiTakesForUpdatedLines?: boolean
  useAsSelectedTake?: boolean
  useExternalLineId?: boolean
  deleteMissingLines?: boolean
  deleteUnusedCharacters?: boolean
  deleteUnusedEvents?: boolean
  useSceneSeqNumber?: boolean
  columns?: string[]
  sheetWithRows: SheetWithRows[]
  saveText: string
  onSave: (params: MapColumnsParams) => void
  loading?: boolean
  onCancel: () => void
}

type ColumnOption = {
  value: string
  label: string
  required?: boolean
  multiple?: boolean
}

const getAllRows = (sheetWithRows: SheetWithRows[], firstRowIsHeader: boolean) => {
  let rows: string[][] = []

  if (!sheetWithRows) {
    return rows
  }

  for (let sheet of sheetWithRows) {
    if (firstRowIsHeader) {
      rows.push(...sheet.rows.slice(1))
    } else {
      rows.push(...sheet.rows)
    }
  }

  // Filter out empty rows that might exist in spreadsheet file
  rows = rows.filter((row: any) => {
    for (const colValue of row) {
      // Any value in any column makes the entire row "valid"
      if (colValue !== undefined && colValue !== '' && colValue !== null) {
        return true
      }
    }
    return false
  })

  return rows
}

const getNumColumns = (rows: string[][]) => {
  if (!rows || rows.length === 0) {
    return 0
  }
  let numColumns = rows[0].length
  for (let i = 1; i < rows.length; i++) {
    if (rows[i].length > numColumns) {
      numColumns = rows[i].length
    }
  }
  return numColumns
}

const pageSize = 20

export const MapColumns = ({
  sheetWithRows,
  saveText,
  loading,
  onSave,
  onCancel,
  ...props
}: Props) => {
  const {
    currentGame,
    lineLabels = [],
    lineAttributes = []
  } = useStore('currentGame', 'lineLabels', 'lineAttributes')

  const [firstRowIsHeader, setFirstRowIsHeader] = useState(props.firstRowIsHeader ?? false)
  const [generateUniqueFilenames, setGenerateUniqueFilenames] = useState(
    props.generateUniqueFilenames ?? false
  )
  const [generateAiTakesForNewLines, setGenerateAiTakesForNewLines] = useState(
    props.generateAiTakesForNewLines ?? false
  )
  const [generateAiTakesForUpdatedLines, setGenerateAiTakesForUpdatedLines] = useState(
    props.generateAiTakesForUpdatedLines ?? false
  )
  const [useAsSelectedTake, setUseAsSelectedTake] = useState(props.useAsSelectedTake ?? false)
  const [useExternalLineId, setUseExternalLineId] = useState(
    currentGame?.useExternalLineId ?? false
  )
  const [useFilenameAsExternalLineId, setUseFilenameAsExternalLineId] = useState(
    currentGame?.useFilenameAsExternalLineId ?? false
  )
  const [existingExternalLineIds, setExistingExternalLineIds] = useState<string[]>([])
  const [deleteMissingLines, setDeleteMissingLines] = useState(props.deleteMissingLines ?? false)
  const [deleteUnusedCharacters, setDeleteUnusedCharacters] = useState(
    props.deleteUnusedCharacters ?? false
  )
  const [deleteUnusedEvents, setDeleteUnusedEvents] = useState(props.deleteUnusedEvents ?? false)

  const [useSceneSeqNumber, setUseSceneSeqNumber] = useState(props.useSceneSeqNumber ?? false)

  const [columns, setColumns] = useState<string[]>(props.columns ?? [])
  const [columnIndex, setColumnIndex] = useState<number>(0)
  const [page, setPage] = useState(0)

  const [isUpdatingUseExternalLineId, setIsUpdatingUseExternalLineId] = useState(false)
  const [isDeleteMissingLinesHelpVisible, setIsDeleteMissingLinesHelpVisible] = useState(false)
  const [isExternalLineIdHelpVisible, setIsExternalLineIdHelpVisible] = useState(false)
  const [isUseSceneSeqNumberHelpVisible, setIsUseSceneSeqNumberHelpVisible] = useState(false)

  useEffect(() => {
    // Pad the column values with 'n/a' in case the sheet has more columns that have been previously saved for import source

    const rows = getAllRows(sheetWithRows, firstRowIsHeader)

    const numColumns = getNumColumns(rows)

    if (numColumns > columns.length) {
      const paddedColumns = [...columns, ...new Array(numColumns - columns.length).fill('n/a')]
      setColumns(paddedColumns)
    }
  }, [sheetWithRows])

  useEffect(() => {
    if (currentGame && currentGame.useExternalLineId !== useExternalLineId) {
      setIsUpdatingUseExternalLineId(true)
      // Change in currentGame to reflect change immediately in checkbox
      currentGame.useExternalLineId = useExternalLineId

      // Slight delay just to avoid a "flashing" circular progress
      setTimeout(() => {
        updateGame({ useExternalLineId })
          .then(() => setIsUpdatingUseExternalLineId(false))
          .catch(() => {
            // Revert on error
            currentGame.useExternalLineId = !useExternalLineId
          })
      }, 500)
    }

    if (currentGame && currentGame.useFilenameAsExternalLineId !== useFilenameAsExternalLineId) {
      setIsUpdatingUseExternalLineId(true)
      // Change in currentGame to reflect change immediately in checkbox
      currentGame.useFilenameAsExternalLineId = useFilenameAsExternalLineId

      // Slight delay just to avoid a "flashing" circular progress
      setTimeout(() => {
        updateGame({ useFilenameAsExternalLineId })
          .then(() => setIsUpdatingUseExternalLineId(false))
          .catch(() => {
            // Revert on error
            currentGame.useFilenameAsExternalLineId = !useFilenameAsExternalLineId
          })
      }, 500)
    }
  }, [currentGame, useExternalLineId, useFilenameAsExternalLineId])

  useEffect(() => {
    if (currentGame && currentGame.useFilenameAsExternalLineId !== useFilenameAsExternalLineId) {
      updateGame({ useFilenameAsExternalLineId })
    }
  }, [currentGame, useFilenameAsExternalLineId])

  useEffect(() => {
    if (currentGame) {
      setUseExternalLineId(currentGame.useExternalLineId)
      setUseFilenameAsExternalLineId(currentGame.useFilenameAsExternalLineId)
    }
  }, [currentGame])

  useEffect(() => {
    if (currentGame) {
      get(`/games/${currentGame!.id}/external-line-ids`).then(({ data: externalLineIds }) =>
        setExistingExternalLineIds(externalLineIds)
      )
    }
  }, [currentGame])

  const columnOptions = useMemo(() => {
    const options: ColumnOption[] = [{ value: 'n/a', label: '-- N/A --' }]

    if (currentGame?.useExternalLineId && !currentGame.useFilenameAsExternalLineId) {
      options.push({ value: 'externalLineId', label: 'External line ID*', required: true })
    }

    options.push({ value: 'lineId', label: 'Speechless line ID' })

    if (!generateUniqueFilenames || currentGame?.useFilenameAsExternalLineId) {
      options.push({ value: 'filename', label: `Filename*`, required: true })
    }

    options.push(
      { value: 'characterName', label: 'Character*', required: true },
      { value: 'eventName', label: 'Event' },
      { value: 'sceneName', label: 'Scene' }
    )

    if (useSceneSeqNumber) {
      options.push({ value: 'sceneSeqNumber', label: 'Scene order*', required: true })
    }

    options.push(
      { value: 'eventDescription', label: 'Event description' },
      {
        value: `line_${currentGame?.primaryLanguage}`,
        label:
          currentGame?.secondaryLanguages && currentGame?.secondaryLanguages.length > 0
            ? `Line (${formatLanguage(currentGame.primaryLanguage)})*`
            : 'Line*',
        required: true
      }
    )

    if (currentGame?.secondaryLanguages) {
      for (let language of currentGame.secondaryLanguages) {
        options.push({
          value: `line_${language}`,
          label: `Line (${formatLanguage(language)})`
        })
      }
    }

    options.push({
      value: `ai_line_${currentGame?.primaryLanguage}`,
      label:
        currentGame?.secondaryLanguages && currentGame?.secondaryLanguages.length > 0
          ? `AI TTS prompt (${formatLanguage(currentGame.primaryLanguage)})*`
          : 'AI TTS prompt'
    })
    if (currentGame?.secondaryLanguages) {
      for (let language of currentGame.secondaryLanguages) {
        options.push({
          value: `ai_line_${language}`,
          label: `AI TTS prompt (${formatLanguage(language)})`
        })
      }
    }

    options.push({ value: 'description', label: 'Line description' })

    for (let attribute of lineAttributes) {
      options.push({
        value: attribute.id,
        label: attribute.name + (attribute.required ? '*' : ''),
        required: attribute.required
      })
    }

    options.push({ value: 'labels', label: 'Labels (comma separated)', multiple: true })

    for (let label of lineLabels) {
      options.push({
        value: `label_${label.name}`,
        label: `Label: ${capitalize(label.name)}`
      })
    }

    return options
  }, [lineAttributes, lineLabels, currentGame, generateUniqueFilenames, useSceneSeqNumber])

  useEffect(() => {
    if (props.columns) {
      return
    }

    const numColumns = getNumColumns(rows)

    // Try to map columns based on the values of the first row.
    // Will only work properly if the import sheet contains a header row.
    const possibleHeaderRow = rows[0] as string[]
    const columnMappings = new Array(numColumns).fill('n/a')

    possibleHeaderRow.forEach((colHeader, i) => {
      const columnOption = columnOptions.find((option) => {
        if (!option.label || !colHeader) {
          return false
        }

        // Skipping all special characters that can prevent exact match (like '*')
        const onlyWordCharsRegex = /\W/g
        const exactMatch =
          option.label.replace(onlyWordCharsRegex, '') === colHeader.replace(onlyWordCharsRegex, '')

        if (exactMatch) {
          return true
        }

        if (colHeader.toLowerCase() === 'labels' && option.label.startsWith('Labels')) {
          return true
        }

        return false
      })
      if (columnOption) {
        const colName = columnOption.value
        if (!columnMappings.includes(colName) || columnOption.multiple) {
          columnMappings[i] = colName
        }
      }
    })

    setColumns(columnMappings)
  }, [])

  const setColumnAt = (index: number, value: any) => {
    const option = columnOptions.find((option) => option.value === value)

    setColumns((columns) => {
      const updatedColumns = columns.map((v, i) => {
        if (i === index) {
          return value
        }
        if (v === value && !option?.multiple) {
          return 'n/a'
        }
        return v
      })

      return updatedColumns
    })
  }

  const parseSceneSeqNumber = (colValue: any): number | undefined => {
    if (typeof colValue === 'number') {
      return colValue
    }
    if (typeof colValue === 'string') {
      const match = colValue.trim().match(/(\d+)$/)

      if (match && match.length === 2) {
        return Number.parseInt(match[1])
      }
    }
    return undefined
  }

  const mapDataRow = (row: any[]): Record<string, any> => {
    const res: Record<string, any> = {}
    for (let i = 0; i < columns.length; i++) {
      const columnId = columns[i]
      let columnValue = row[i]
      const columnOption = columnOptions.find((option) => option.value === columnId)

      if (['sceneName', 'eventName'].includes(columnId) && !columnValue?.trim()) {
        columnValue = null
      }

      if (columnId !== 'n/a' && columnValue) {
        if (columnId === 'sceneSeqNumber') {
          res[columnId] = parseSceneSeqNumber(columnValue)
        } else if (res[columnId]) {
          res[columnId].push(columnValue)
        } else if (columnOption?.multiple) {
          res[columnId] = [columnValue]
        } else {
          res[columnId] = columnValue
        }
      }
    }
    return res
  }

  const handleSave = async () => {
    const missingColumns = columnOptions.filter(
      (option) => option.required && !columns.includes(option.value)
    )

    if (missingColumns.length > 0) {
      return setError({
        message: (
          <Block>
            Please provide mappings for the following required columns:
            <SpacerVertical />
            <List>
              {missingColumns.map((option) => {
                return <ListItem text={option.label} />
              })}
            </List>
          </Block>
        )
      })
    }

    // Warn if trying to import without external line IDs if lines have already been imported with external line IDs
    if (!currentGame?.useExternalLineId && existingExternalLineIds.length > 0) {
      if (
        !(await confirm({
          title: 'Import without external line IDs?',
          message: (
            <>
              <Row gap="10px">
                <Block>
                  {existingExternalLineIds.length}{' '}
                  {pluralize('line', existingExternalLineIds.length)}{' '}
                  {existingExternalLineIds.length === 1 ? 'was' : 'were'} found that{' '}
                  {existingExternalLineIds.length === 1 ? 'has' : 'have'} already been imported to
                  this game using external line IDs.
                </Block>
                <WarningIcon fill="var(--warning)" size="36" />
              </Row>
              <SpacerVertical />
              <Block>
                It is strongly recommended to stick with using external line IDs for all your lines.
              </Block>
              <SpacerVertical />
              <Block>Do you still want to continue with the import?</Block>
              <SpacerVertical />
            </>
          ),
          confirmText: `Continue`,
          rejectText: 'Cancel'
        }))
      ) {
        return
      }
    }

    if (currentGame?.useExternalLineId && !currentGame.useFilenameAsExternalLineId) {
      const externalLineIdColIndex = columns.findIndex((col) => col === 'externalLineId')

      if (externalLineIdColIndex > -1) {
        // Check that every data row contains a unique, non-empty external ID
        const rowsWithoutExternalId =
          rows?.filter((row) => !row[externalLineIdColIndex]?.trim()) ?? []

        if (rowsWithoutExternalId.length > 0) {
          const errorRows = rowsWithoutExternalId
            .map(mapDataRow)
            .sort((a, b) => (a.filename?.trim() ?? '').localeCompare(b.filename?.trim() ?? ''))

          setError({
            title: `External line ID missing for ${errorRows.length}
            ${pluralize('line', errorRows.length)}`,
            message: (
              <>
                <Block color="var(--on-surface-light)">
                  External line ID needs to be specified for every line. Please correct the
                  following {pluralize('line', errorRows.length)} in your import data and try again.
                </Block>
                <SpacerVertical />
                <List>
                  {errorRows.map((row) => {
                    const lineText = (
                      <Block overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                        {row[`line_${currentGame?.primaryLanguage ?? 'en'}`]}
                      </Block>
                    )
                    return (
                      <ListItem
                        text={row.filename?.trim() ?? '<No filename>'}
                        secondaryText={lineText}
                      />
                    )
                  })}
                </List>
                <SpacerVertical />
              </>
            )
          })
          return
        }

        const externalLineIds: string[] =
          rows?.map((dataRow) => dataRow[externalLineIdColIndex]) ?? []

        const duplicateIds: string[] = [
          ...new Set(
            externalLineIds.filter(
              (id, i) =>
                externalLineIds.slice(i + 1).findIndex((id2, i2) => id2 === id && i2 !== i) >= 0
            )
          )
        ]

        if (duplicateIds.length > 0) {
          setError({
            message: (
              <Block>
                All external line IDs must be unique. The following IDs occur more than once:
                <SpacerVertical />
                <List>
                  {duplicateIds
                    .sort((a, b) => a.localeCompare(b))
                    .map((id) => {
                      return <ListItem text={id} />
                    })}
                </List>
              </Block>
            )
          })
          return
        }
      }
    } else if (currentGame?.useExternalLineId && currentGame.useFilenameAsExternalLineId) {
      // Check that every data row contains a unique filename

      const filenameColIndex = columns.findIndex((col) => col === 'filename')

      const rowsWithoutFilename = rows?.filter((row) => !row[filenameColIndex]?.trim()) ?? []

      if (rowsWithoutFilename.length > 0) {
        const errorRows = rowsWithoutFilename
          .map(mapDataRow)
          .sort((a, b) => (a.filename?.trim() ?? '').localeCompare(b.filename?.trim() ?? ''))

        setError({
          title: `Filename is missing for ${errorRows.length}
              ${pluralize('line', errorRows.length)}`,
          message: (
            <>
              <Block color="var(--on-surface-light)">
                A unique filename needs to be specified for every line. Please correct the following{' '}
                {pluralize('line', errorRows.length)} in your import data and try again.
              </Block>
              <SpacerVertical />
              <List>
                {errorRows.map((row) => {
                  const lineText = (
                    <Block overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                      {row[`line_${currentGame?.primaryLanguage ?? 'en'}`]}
                    </Block>
                  )
                  return <ListItem text={lineText} />
                })}
              </List>
              <SpacerVertical />
            </>
          )
        })
        return
      }

      const filenames: string[] = rows?.map((dataRow) => dataRow[filenameColIndex]) ?? []

      const duplicateFilenames: string[] = [
        ...new Set(
          filenames.filter(
            (filename, i) =>
              filenames
                .slice(i + 1)
                .findIndex((filename2, i2) => filename2 === filename && i2 !== i) >= 0
          )
        )
      ]

      if (duplicateFilenames.length > 0) {
        setError({
          message: (
            <Block>
              All filenames must be unique when being used as external line IDs. The following
              filenames occur more than once:
              <SpacerVertical />
              <List>
                {duplicateFilenames
                  .sort((a, b) => a.localeCompare(b))
                  .map((filename) => {
                    return <ListItem text={filename} />
                  })}
              </List>
            </Block>
          )
        })
        return
      }
    } else {
      // Verify that "Speechless line ID" column seems to have been correctly selected
      const speechlessLineIdColIndex = columns.findIndex((col) => col === 'lineId')

      if (speechlessLineIdColIndex > -1) {
        // Check that every data row contains a unique, non-empty external ID
        const invalidUuids = (rows?.map((row) => row[speechlessLineIdColIndex]) ?? []).filter(
          (uuid) => uuid && !validateUuid(uuid?.trim())
        )

        if (invalidUuids.length > 0) {
          setError({
            message: (
              <Block>
                Please check that you have selected the correct column for "Speechless line ID" and
                that "First row is header" is checked if your sheet contains a header row.
                <SpacerVertical />
                The following values are not valid line IDs:
                <SpacerVertical />
                <List>
                  {invalidUuids
                    .sort((a, b) => (a ?? '').localeCompare(b))
                    .splice(0, 10)
                    .map((id) => {
                      return <ListItem text={id} />
                    })}
                </List>
              </Block>
            )
          })
          return
        }
      }
    }

    if (useSceneSeqNumber) {
      const sceneSeqNumberColIndex = columns.findIndex((col) => col === 'sceneSeqNumber')
      const sceneNameColIndex = columns.findIndex((col) => col === 'sceneName')

      // Check that all lines that belongs to a scene also has a valid value for the scene order column
      const rowsWithoutValidSeqNumber =
        rows?.filter((row) => {
          const belongsToScene = !!row[sceneNameColIndex]?.trim()
          const sceneSeqNumber = parseSceneSeqNumber(row[sceneSeqNumberColIndex])

          return belongsToScene && sceneSeqNumber === undefined
        }) ?? []

      if (rowsWithoutValidSeqNumber.length > 0) {
        const errorRows = rowsWithoutValidSeqNumber
          .map(mapDataRow)
          .sort((a, b) => (a.filename?.trim() ?? '').localeCompare(b.filename?.trim() ?? ''))

        setError({
          title: `Scene order is missing for ${errorRows.length}
            ${pluralize('line', errorRows.length)}`,
          message: (
            <>
              <Block color="var(--on-surface-light)">
                Scene order needs to be specified for every line that belongs to a scene.
                <br />
                <br />
                Allowed values are numbers or text values with a numerical suffix (e.g. "scene1_001"
                where 001 is the numerical suffix).
                <br />
                <br />
                Please correct the following {pluralize('line', errorRows.length)} in your import
                data and try again.
              </Block>
              <SpacerVertical />
              <List>
                {errorRows.map((row) => {
                  const lineText = (
                    <Block overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                      {row[`line_${currentGame?.primaryLanguage ?? 'en'}`]}
                    </Block>
                  )
                  return (
                    <ListItem
                      text={row.filename?.trim() ?? '<No filename>'}
                      secondaryText={lineText}
                    />
                  )
                })}
              </List>
              <SpacerVertical />
            </>
          )
        })
        return
      }
    }

    onSave({
      firstRowIsHeader,
      generateUniqueFilenames,
      useExternalLineId,
      generateAiTakesForNewLines,
      generateAiTakesForUpdatedLines,
      useAsSelectedTake,
      deleteMissingLines,
      deleteUnusedCharacters,
      deleteUnusedEvents,
      useSceneSeqNumber,
      columns
    })
  }

  const rows = getAllRows(sheetWithRows, firstRowIsHeader)

  const numColumns = getNumColumns(rows)

  const header = []
  for (let i = 0; i < numColumns; i++) {
    header.push({
      label: (
        <NativeSelect
          width="200px"
          options={columnOptions}
          value={columns[i]}
          onChange={(value: string) => setColumnAt(i, value)}
        />
      )
    })
  }

  const tableRows = rows.slice(page * pageSize, page * pageSize + pageSize)

  const body = tableRows.map((row) => {
    let cols = []
    for (let i = 0; i < numColumns; i++) {
      if (i < row.length) {
        cols.push(
          <Block maxWidth="200px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {row[i]?.toString()}
          </Block>
        )
      } else {
        cols.push('')
      }
    }
    return cols
  })

  return (
    <Col height="100%">
      <Row>
        <Block minWidth="300px">
          <Checkbox
            label="First row is header"
            checked={firstRowIsHeader}
            onChange={setFirstRowIsHeader}
          />
          <br />
          <Checkbox
            label="Generate AI takes for new lines"
            checked={generateAiTakesForNewLines}
            onChange={setGenerateAiTakesForNewLines}
          />
          <br />
          <Checkbox
            label="Generate AI takes for updated lines"
            checked={generateAiTakesForUpdatedLines}
            onChange={setGenerateAiTakesForUpdatedLines}
          />
          <br />
          <Checkbox
            label="Use as selected take"
            checked={useAsSelectedTake}
            onChange={setUseAsSelectedTake}
            disabled={!generateAiTakesForNewLines && !generateAiTakesForUpdatedLines}
            marginLeft="25px"
          />
        </Block>
        <SpacerHorizontal large />
        <Block minWidth="350px" maxWidth="350px">
          <Row alignItems="center" gap="10px">
            <Checkbox
              label="Delete missing lines"
              checked={deleteMissingLines}
              onChange={setDeleteMissingLines}
            />
            <Button
              tiny
              icon={isDeleteMissingLinesHelpVisible ? ExpandLessIcon : ExpandMoreIcon}
              onClick={() =>
                isDeleteMissingLinesHelpVisible
                  ? setIsDeleteMissingLinesHelpVisible(false)
                  : setIsDeleteMissingLinesHelpVisible(true)
              }
            >
              {isDeleteMissingLinesHelpVisible ? 'Hide help' : 'Show help'}
            </Button>
          </Row>
          {isDeleteMissingLinesHelpVisible && (
            <>
              <HelpDeleteMissingLines fontSize="14px" />
              <SpacerVertical />
            </>
          )}
          <Checkbox
            label="Delete unused characters"
            checked={deleteUnusedCharacters}
            onChange={setDeleteUnusedCharacters}
          />
          <br />
          <Checkbox
            label="Delete unused events"
            checked={deleteUnusedEvents}
            onChange={setDeleteUnusedEvents}
          />
          <Row alignItems="center" gap="10px">
            <Checkbox
              label="Specify order in scenes"
              checked={useSceneSeqNumber}
              onChange={setUseSceneSeqNumber}
            />
            <Button
              tiny
              icon={isUseSceneSeqNumberHelpVisible ? ExpandLessIcon : ExpandMoreIcon}
              onClick={() =>
                isUseSceneSeqNumberHelpVisible
                  ? setIsUseSceneSeqNumberHelpVisible(false)
                  : setIsUseSceneSeqNumberHelpVisible(true)
              }
            >
              {isUseSceneSeqNumberHelpVisible ? 'Hide help' : 'Show help'}
            </Button>
          </Row>
          {isUseSceneSeqNumberHelpVisible && (
            <>
              <HelpUseSceneSeqNumber fontSize="14px" />
              <SpacerVertical />
            </>
          )}
        </Block>
        <SpacerHorizontal large />
        <Block minWidth="350px" maxWidth="350px">
          <Block>
            <Row alignItems="center" gap="10px">
              <Checkbox
                label="Generate filenames using custom format"
                checked={generateUniqueFilenames && currentGame?.customFilenameFormat}
                onChange={setGenerateUniqueFilenames}
                disabled={
                  !currentGame?.customFilenameFormat ||
                  (currentGame?.useExternalLineId && currentGame.useFilenameAsExternalLineId)
                }
              />
            </Row>
            <Block marginLeft="40px">
              <Block color="var(--on-surface-light)" wordWrap="break-word" fontSize="14px">
                {currentGame?.customFilenameFormat
                  ? `${currentGame.customFilenameFormat}`
                  : 'No custom format specified'}
              </Block>
              <br />
              <Block fontSize="14px">
                <Link to="/settings" onRoute={routeTo}>
                  Edit custom filename format
                </Link>
              </Block>
              <SpacerVertical />
            </Block>
            <Row alignItems="center" gap="10px">
              <Checkbox
                label="Use external line IDs"
                checked={useExternalLineId}
                onChange={setUseExternalLineId}
                disabled={isUpdatingUseExternalLineId}
              />
              {isUpdatingUseExternalLineId && <ProgressCircular size="24" />}
              <Button
                tiny
                icon={isExternalLineIdHelpVisible ? ExpandLessIcon : ExpandMoreIcon}
                onClick={() =>
                  isExternalLineIdHelpVisible
                    ? setIsExternalLineIdHelpVisible(false)
                    : setIsExternalLineIdHelpVisible(true)
                }
              >
                {isExternalLineIdHelpVisible ? 'Hide help' : 'Show help'}
              </Button>
            </Row>
            <Checkbox
              width="225px"
              label="Filename is line ID"
              checked={currentGame?.useFilenameAsExternalLineId}
              onChange={setUseFilenameAsExternalLineId}
              disabled={!currentGame?.useExternalLineId || isUpdatingUseExternalLineId}
              marginLeft="25px"
            />
            {isExternalLineIdHelpVisible && (
              <>
                <HelpExternalLineId fontSize="14px" />
                <SpacerVertical />
              </>
            )}
          </Block>
        </Block>
      </Row>
      <SpacerVertical />
      <Block color="var(--on -surface-light)">* Required column</Block>
      <SpacerVertical />
      <Block flex="1" position="relative">
        <ScrollableDataTable
          compact
          header={header}
          body={body}
          pagination={{
            page,
            numRowsPerPage: pageSize,
            numRows: rows.length,
            onPageChange: setPage
          }}
        />
      </Block>
      <SpacerVertical />
      <Row>
        <Button contained onClick={handleSave} loading={loading}>
          {saveText}
        </Button>
        <SpacerHorizontal />
        <Button onClick={onCancel}>Cancel</Button>
      </Row>
    </Col>
  )
}
