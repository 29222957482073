import { store } from '../store'
import { patch, post } from '../api'

export const updateUser = async (params: any) => {
  try {
    store.setState({ isUpdatingUser: true })
    const { data: currentUser } = await patch(`/me`, params)
    store.setState({ currentUser, isUpdatingUser: false })
    return currentUser
  } catch (error: any) {
    store.setState({ error, isUpdatingUser: false })
  }
}
