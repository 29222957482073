export const setItem = (key: string, value: any) => {
  if (value !== undefined) {
    localStorage.setItem(key, JSON.stringify(value))
  } else {
    localStorage.removeItem(key)
  }
}

export const getItem = (key: string, defaultValue?: any) => {
  const value = localStorage.getItem(key)
  return value ? JSON.parse(value) : defaultValue
}

export const removeItem = (key: string) => localStorage.removeItem(key)
