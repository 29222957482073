import { h } from 'preact'
import { useSignal } from '@preact/signals'
import { color } from 'd3-color'
import { Block } from 'jsxstyle/preact'
import { useEffect, useRef } from 'preact/hooks'
import { useCanvas } from '../../lib/useCanvas'
import { colors } from '../LineRecorder/colors'

const d3 = { color }

// References:
// https://stackoverflow.com/questions/44360301/web-audio-api-creating-a-peak-meter-with-analysernode
// https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/getFloatTimeDomainData

type Props = {
  stream: MediaStream
  width?: number
  height?: number
  peakWarningThreshold?: number
  thresholds?: { value: number; colorHsl: string }[]
  onPeakWarning?: () => void
}

export function VuMeter({
  stream,
  width = 6,
  height = 80,
  peakWarningThreshold = -0.1,
  onPeakWarning,
  thresholds = [
    { value: peakWarningThreshold, colorHsl: 'hsl(0, 100%, 50%)' },
    { value: -6, colorHsl: 'hsl(40, 100%, 50%)' },
    // { value: -100, colorHsl: 'hsl(0, 50%, 100%)' }
    { value: -100, colorHsl: 'rgb(83, 249, 97)' }
  ]
}: Props) {
  const canvasElem = useRef<HTMLCanvasElement | null>(null)

  const frameId = useRef<number | null>(null)
  const analyser = useRef<AnalyserNode | null>(null)
  const dataArray = useRef<Float32Array | null>(null)

  const lastTime = useRef<number>(0)
  const markerYAlpha = useRef<number>(1)
  const markerY = useRef<number>(0)
  const maxDb = useRef<number>(-48)
  const peakWarning = useRef(false)

  // Set up audio context and analyser node
  useEffect(() => {
    if (!stream) return
    let audioCtx = new AudioContext()
    const source = audioCtx.createMediaStreamSource(stream)
    const _analyser = audioCtx.createAnalyser()
    _analyser.smoothingTimeConstant = 0.8
    _analyser.fftSize = 2048
    _analyser.maxDecibels = 0
    _analyser.minDecibels = -48
    const bufferLength = _analyser.frequencyBinCount
    dataArray.current = new Float32Array(bufferLength)
    source.connect(_analyser)
    analyser.current = _analyser
    return () => {
      _analyser.disconnect()
      source.disconnect()
      audioCtx.close()
      analyser.current = null
      dataArray.current = null
    }
  }, [stream])

  const { tick } = useCanvas(canvasElem, (ctx, width, height, t) => {
    if (!analyser.current) return
    if (!dataArray.current) return
    ctx.clearRect(0, 0, width, height)

    const timeDelta = (t ?? 0) - lastTime.current

    analyser.current.getFloatTimeDomainData(dataArray.current)

    // Compute average power over the interval.
    let sumOfSquares = 0
    for (let i = 0; i < dataArray.current.length; i++) {
      sumOfSquares += dataArray.current[i] ** 2
    }
    const avgPowerDecibels = 10 * Math.log10(sumOfSquares / dataArray.current.length)

    // Compute peak instantaneous power over the interval.
    let peakInstantaneousPower = 0
    for (let i = 0; i < dataArray.current.length; i++) {
      const power = dataArray.current[i] ** 2
      peakInstantaneousPower = Math.max(power, peakInstantaneousPower)
    }
    const peakInstantaneousPowerDecibels = 10 * Math.log10(peakInstantaneousPower)

    const max = peakInstantaneousPowerDecibels

    ctx.fillStyle = colors['--container-background']
    ctx.fillRect(0, 0, width, height)

    const ratio = 1 - Math.abs(max) / Math.abs(analyser.current.minDecibels)
    const maxY = ratio * height

    if (markerY.current < maxY || markerYAlpha.current < 0.3) {
      // Set new peak
      markerY.current = maxY
      markerYAlpha.current = 1
      lastTime.current = t ?? 0
      maxDb.current = max

      peakWarning.current = maxDb.current >= peakWarningThreshold
      if (peakWarning.current && onPeakWarning) {
        onPeakWarning()
      }
    } else if (timeDelta > 500) {
      // Lower peak marker transparency
      markerYAlpha.current = Math.max(0, markerYAlpha.current - 0.01)
    }

    // peak bar
    let barColor: string | undefined
    let maxThreshold = -Infinity
    // Find matching threshold given current maxDb
    for (const threshold of thresholds) {
      if (threshold.value <= maxDb.current) {
        if (threshold.value > maxThreshold) {
          maxThreshold = threshold.value
          barColor = threshold.colorHsl
        }
      }
    }
    if (!barColor) return
    ctx.fillStyle = barColor
    ctx.fillRect(0, height - maxY, width, maxY)

    // peak marker
    const peakColor = d3.color(barColor)
    if (!peakColor) return
    peakColor.opacity = markerYAlpha.current
    ctx.fillStyle = peakColor.toString()
    ctx.fillRect(0, height - markerY.current, width, 3)
  })

  // Set up animation loop
  useEffect(() => {
    function loop(t: number) {
      tick(t)
      frameId.current = requestAnimationFrame(loop)
    }

    frameId.current = requestAnimationFrame(loop)
    return () => {
      if (!frameId.current) return
      cancelAnimationFrame(frameId.current)
    }
  }, [tick])

  return (
    <Block width={`${width}px`} height={`${height}px`}>
      <canvas ref={canvasElem} style={{ width: '100%', height: '100%' }} />
    </Block>
  )
}
