export async function trimAudio(audioBuffer: AudioBuffer, start: number, end: number) {
  const audioContext = new OfflineAudioContext({
    numberOfChannels: 1, // Always mono
    length: (end - start) * audioBuffer.sampleRate,
    sampleRate: audioBuffer.sampleRate
  })
  const source = audioContext.createBufferSource()
  source.buffer = audioBuffer

  source.connect(audioContext.destination)
  source.start(0, start, end - start)

  const trimmed = await audioContext.startRendering()
  return trimmed
}
