import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { AddIcon, FilterListIcon, SelectChip, SpacerVertical } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'
import { Block, Col, Row } from 'jsxstyle/preact'

import { fetchCharacters } from '../../actions'
import { useUrlParams } from '../../use-url-params'
import { useEvent } from '../../use-event'
import { useScene } from '../../use-scene'
import { useSession } from '../../use-session'

import Page from '../Page'
import Spinner from '../Spinner'
import Avatar from '../Avatar'

import CharactersTable from './CharactersTable'
import SearchTextField from '../SearchTextField'
import SelectEvent from '../SelectEvent'
import SelectScene from '../SelectScene'
import { SelectSession } from '../SelectSession'
import { useEffectSkipFirst } from '../../use-effect-skip-first'
import { useStore } from '../../store'

const pageSize = 10

export const Characters = () => {
  const { currentGame, characters, totalCharacters, isFetchingCharacters } = useStore(
    'currentGame',
    'characters',
    'totalCharacters',
    'isFetchingCharacters'
  )
  const params = new URLSearchParams(location.search)

  const [query, setQuery] = useState(params.get('query') || '')
  const [page, setPage] = useState(parseFloat(params.get('page') || '0'))
  const [orderBy, setOrderBy] = useState(params.get('orderBy') || 'name')
  const [sortOrder, setSortOrder] = useState(params.get('sortOrder') || 'asc')

  const { eventId, event, setEvent, clearEvent } = useEvent(params.get('eventId') ?? undefined)
  const { sceneId, scene, setScene, clearScene } = useScene(params.get('sceneId') ?? undefined)
  const { sessionId, session, setSession, clearSession } = useSession(
    params.get('sessionId') ?? undefined
  )

  useUrlParams(
    { page, query, orderBy, sortOrder, eventId, sceneId, sessionId },
    { page: 0, query: '', orderBy: 'name', sortOrder: 'asc' }
  )

  // Handle switch game
  useEffectSkipFirst(() => {
    clearEvent()
    clearScene()
    clearSession()
  }, [currentGame!.id])

  useEffect(() => {
    fetchCharacters({
      offset: page * pageSize,
      limit: pageSize,
      query,
      sortOrder,
      orderBy,
      eventId,
      sceneId,
      sessionId
    })
  }, [currentGame!.id, page, query, sortOrder, orderBy, eventId, sceneId, sessionId])

  const closeDialog = () => goBack('/characters')

  if (!characters) {
    return <Spinner />
  }

  if (eventId && !event) {
    return <Spinner />
  }

  if (sceneId && !scene) {
    return <Spinner />
  }

  if (sessionId && !session) {
    return <Spinner />
  }

  return (
    <Page
      title="Characters"
      actions={[
        {
          label: 'Create character',
          icon: AddIcon,
          onClick: () => routeTo('/create-character')
        }
      ]}
    >
      <Col height="100%">
        <SearchTextField
          value={query}
          onChange={(query: string) => {
            setQuery(query)
            setPage(0)
          }}
        />
        <SpacerVertical small />
        <Row alignItems="center" gap="10px">
          {event && (
            <SelectChip
              visual={<Avatar size={30} src={event.picture} name={event.name} />}
              value={event.name}
              onClick={() => routeTo('/characters/select-event')}
              onClear={() => {
                clearEvent()
                setPage(0)
              }}
            />
          )}
          {!event && (
            <SelectChip
              icon={FilterListIcon}
              label="Event"
              onClick={() => routeTo('/characters/select-event')}
            />
          )}
          {scene && (
            <SelectChip
              visual={<Avatar size={30} src={scene.picture} name={scene.name} />}
              value={scene.name}
              onClick={() => routeTo('/characters/select-scene')}
              onClear={() => {
                clearScene()
                setPage(0)
              }}
            />
          )}
          {!scene && (
            <SelectChip
              icon={FilterListIcon}
              label="Scene"
              onClick={() => routeTo('/characters/select-scene')}
            />
          )}
          {session && (
            <SelectChip
              value={session.scheduled}
              onClick={() => routeTo('/characters/select-session')}
              onClear={() => {
                clearSession()
                setPage(0)
              }}
            />
          )}
          {!session && (
            <SelectChip
              icon={FilterListIcon}
              label="Session"
              onClick={() => routeTo('/characters/select-session')}
            />
          )}
        </Row>
        <SpacerVertical />
        <CharactersTable
          loading={isFetchingCharacters}
          characters={characters}
          total={totalCharacters}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
      </Col>

      <Route
        path="/characters/select-event"
        render={() => <SelectEvent onClose={closeDialog} onSelect={setEvent} />}
      />
      <Route
        path="/characters/select-scene"
        render={() => <SelectScene onClose={closeDialog} onSelect={setScene} />}
      />
      <Route
        path="/characters/select-session"
        render={() => (
          <SelectSession
            onClose={closeDialog}
            onSelect={(session) => {
              setSession(session)
              closeDialog()
            }}
          />
        )}
      />
    </Page>
  )
}

export default Characters
