import { Dialog, RadioButton } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle'
import { useState } from 'preact/hooks'
import { showSnackbar, syncExportTarget } from '../../actions'
import { DriveTargetTypes, ExportTarget } from '../../types'

type Props = {
  exportTarget: ExportTarget & { type: DriveTargetTypes}
  onClose: () => void
}

export const SyncDialog = ({ exportTarget, onClose }: Props) => {
  const [full, setFull] = useState(exportTarget.numLinesOutOfSync === 0)
  const [isStartingSync, setIsStartingSync] = useState(false)

  const handleSync = async () => {
    setIsStartingSync(true)
    try {
      if (await syncExportTarget(exportTarget.id, { full })) {
        showSnackbar('Sync started')
        onClose()
      }
    } catch (err) {
      setIsStartingSync(false)
    }
  }

  return (
    <Dialog
      title={`Sync to ${exportTarget.name}`}
      actions={[
        { text: 'Continue', onClick: handleSync, contained: true, loading: isStartingSync },
        { text: 'Cancel', onClick: onClose }
      ]}
    >
      <RadioButton
        disabled={exportTarget.numLinesOutOfSync === 0}
        checked={!full}
        label={`Updated lines (${exportTarget.numLinesOutOfSync})`}
        onChange={(e: any) => setFull(!e.checked)}
      />
      <Block />
      <RadioButton checked={full} label="All lines" onChange={(e: any) => setFull(e.checked)} />
    </Dialog>
  )
}
