import { computed, effect, ReadonlySignal, Signal, signal, useSignal } from '@preact/signals'
import { useEffect, useRef, useState } from 'preact/hooks'
import { v4 as uuid } from 'uuid'
import { AudioBufferPlayer, useAudioBufferPlayer } from '../../lib/useAudioBufferPlayer'
import { trimAudio } from '../AudioRecorder/trimAudio'
import { audioBufferFromURI } from '../../lib/audioBufferFromURI'

export type TakeUser = {
  name?: string
  id: string
  picture?: string
}

export type Take = {
  id: string
  uri?: string
  processedUri?: string
  rawAudioUri?: string
  trimStart?: number
  trimEnd?: number
  trimLocked?: boolean
  hash?: string
  created?: Date
  lastModified?: Date
  error?: string
  aiVoice?: TakeUser
  voice?: TakeUser
  user?: TakeUser
  peakWarning?: boolean
} & {
  lineId?: string
  status?: 'done' | 'loading'
  audioBuffer?: AudioBuffer
  audioBufferPlayer?: AudioBufferPlayer
  seq?: number
  seqLanguage?: number
  sessionId?: string
}

export type LineTranslations = {
  [language: string]: {
    line: string
    numTakes?: number
    takes?: Take[]
    selectedTake?: Take
    autoTranslated: boolean
    referenceAudioUri?: string
    referenceAudioBufferPlayer?: AudioBufferPlayer
  }
}

export type Game = {
  id: string
  name: string
  primaryLanguage: string
}

export type Scene = {
  id: string
  name: string
  picture?: string
  description?: string
  location?: string
  lines?: Line[]
}

export type Line = {
  id: string
  character?: {
    id: string
    name: string
    picture?: string
  }
  description?: string
  translations: LineTranslations
}

export type UpdateLine = Partial<
  Line & {
    selectedTakes?: { language: string; takeId: string | null }[]
  }
>

type DeleteTakeProps = {
  lineId: string
  take: Take
  onDelete?: (takeId: string) => Promise<void>
}

type SetSelectedTakeProps = {
  lineId: string
  takeId: string
}

type TrimProps = {
  lineId: string
  takeId: string
  audioBuffer: AudioBuffer
  start: number
  end: number
  onUpload?: (takeId: string, uri: string, start: number, end: number) => Promise<void>
}

type CreateTakeProps = {
  lineId: string
  audioBuffer?: AudioBuffer
  onUpload?: (
    lineId: string,
    take: Required<Pick<Take, 'id' | 'uri' | 'peakWarning' | 'trimStart' | 'trimEnd'>>
  ) => Promise<Take | undefined>
}

type SampleSize = 8 | 16 | 24 | 32

type LineRecorderStateSignals = {
  sessionId: ReadonlySignal<string | undefined>
  activeLine: ReadonlySignal<Line | undefined>
  activeLineId: Signal<string | null>
  activeLineIndex: ReadonlySignal<number>
  activeLines: Signal<Line[]>
  activeLineTakes: ReadonlySignal<Take[]>
  activeTake: ReadonlySignal<Take | null>
  activeTakeId: Signal<string | null>
  autoAdvance: Signal<boolean>
  remotePlaybackEnabled: Signal<boolean>
  game: Signal<Game>
  language: Signal<string>
  lines: Signal<Line[]>
  scenes: Signal<Scene[]>
  numLines: ReadonlySignal<number>
  numLinesWithSelectedTake: ReadonlySignal<number>
  numLinesWithTakes: ReadonlySignal<number>
  pushToRecord: Signal<boolean>
  recordingPeakWarning: Signal<boolean>
  selectedCharacter: Signal<string | null>
  showAllTakes: Signal<boolean>
  takes: ReadonlySignal<Take[]>
  voice: Signal<TakeUser | null>
  sampleSize: Signal<SampleSize>
  isTakeFocusModeEnabled: Signal<boolean>
}

type SetTrimLockParams = {
  lineId: string
  takeId: string
  locked: boolean
}

type PlayPreviousLineFromSceneParams = {
  onEnded?: () => void
}

export type LineRecorderState = LineRecorderStateSignals & {
  deleteTake: (props: DeleteTakeProps) => Promise<void>
  createTake: (props: CreateTakeProps) => Promise<void>
  selectLine: (lineId: string, options?: { scrollBehaviour: ScrollBehavior }) => void
  selectTake: (takeId: string, scrollOptions?: ScrollIntoViewOptions) => void
  selectCharacter: (characterId: string) => void
  playActiveTake: (options?: { startTime?: number }) => void
  stopActiveTake: () => void
  nextLine: (options?: { scrollBehaviour: ScrollBehavior }) => void
  prevLine: (options?: { scrollBehaviour: ScrollBehavior }) => void
  nextTake: () => void
  prevTake: () => void
  setSelectedTake: (props: SetSelectedTakeProps) => Promise<void>
  updateTrim: (props: TrimProps) => Promise<void>
  startRecording: () => void
  stopRecording: () => void
  setTrimLock: (params: SetTrimLockParams) => Promise<void>
  playPreviousLineFromScene: (params?: PlayPreviousLineFromSceneParams) => Promise<void>
  playNextLineFromScene: () => Promise<void>
  playReferenceAudio: (lineId: string) => void
  stopReferenceAudio: (lineId: string) => void
}

type LineRecorderStateProps = {
  sessionId?: string
  game: Game
  language: string
  lines: Line[]
  scenes: Scene[]
  showAllTakes?: boolean
  voice?: TakeUser
  onUploadAudioBuffer?: (audioBuffer: AudioBuffer) => Promise<string>
  onUpdateLine?: (lineId: string, line: UpdateLine) => Promise<void>
  onUpdateTake?: (takeId: string, take: Partial<Take>) => Promise<void>
  onError?: (e: Error) => void
}

const parseSampleSize = (str: string) => {
  try {
    const num = Number.parseFloat(str)
    switch (num) {
      case 8:
        return 8
        break
      case 16:
        return 16
        break
      case 24:
        return 24
        break
      case 32:
        return 32
        break
      default:
        throw new Error(`Invalid sample size: ${str}. Should be one of 8, 16, 24, 32`)
    }
  } catch (e) {
    throw new Error(`Invalid sample size: ${str}. Should be one of 8, 16, 24, 32`)
  }
}

export function createLineRecorderState(props: LineRecorderStateProps): LineRecorderState {
  const sessionId = signal<string | undefined>(props.sessionId)
  const activeLineId = signal<string | null>(null)
  const game = signal(props.game)
  const language = signal(props.language)
  const lines = signal<Line[]>(props.lines)
  const scenes = signal<Scene[]>(props.scenes)
  const recordingPeakWarning = signal(false)
  const selectedCharacter = signal<string | null>(null)
  const voice = signal<TakeUser | null>(props.voice ?? null)

  // Settings
  const autoAdvance = signal(localStorage.getItem('sl-auto-advance') === 'true')
  const remotePlaybackEnabledLocalStorage = localStorage.getItem('sl-remote-playback-enabled')
  const remotePlaybackEnabled = signal(
    !remotePlaybackEnabledLocalStorage || remotePlaybackEnabledLocalStorage === 'true'
  )
  const pushToRecord = signal(localStorage.getItem('sl-push-to-rec') === 'true')
  const showAllTakesLocalStorage = localStorage.getItem('sl-show-all-takes')
  const showAllTakes = signal(
    showAllTakesLocalStorage ? showAllTakesLocalStorage === 'true' : props.showAllTakes ?? false
  )
  const sampleSizeLocalStorage = localStorage.getItem('sl-sample-size')
  let _sampleSize: SampleSize = 24 // Default
  if (sampleSizeLocalStorage) {
    _sampleSize = parseSampleSize(sampleSizeLocalStorage)
  }
  const sampleSize = signal<SampleSize>(_sampleSize)
  const isTakeFocusModeEnabled = signal<boolean>(false)

  effect(() => {
    localStorage.setItem('sl-push-to-rec', JSON.stringify(pushToRecord.value))
  })
  effect(() => {
    localStorage.setItem('sl-auto-advance', JSON.stringify(autoAdvance.value))
  })
  effect(() => {
    localStorage.setItem('sl-remote-playback-enabled', JSON.stringify(remotePlaybackEnabled.value))
  })
  effect(() => {
    localStorage.setItem('sl-show-all-takes', JSON.stringify(showAllTakes.value))
  })

  const activeLine = computed(() => lines.value.find((line) => line.id === activeLineId.value))
  const activeLineIndex = computed<number>(() => {
    const activeIndex = activeLines.value.findIndex((line) => line.id === activeLineId.value)
    return activeIndex
  })

  const activeLines = computed(() => {
    return lines.value.filter((line) => {
      if (!selectedCharacter.value) {
        return true
      } else {
        return line.character?.id === selectedCharacter.value
      }
    })
  })

  const takes = computed(() => {
    if (lines.value.length === 0) {
      return []
    }

    const takes: Take[] = []
    for (const line of lines.value) {
      const lineTakes = line.translations[language.value]?.takes
      if (!lineTakes) {
        continue
      }

      for (const take of lineTakes) {
        if (take.rawAudioUri) {
          takes.push({
            ...take,
            lineId: line.id,
            status: take.status ?? 'done'
          })
        }
      }
    }
    return takes
  })

  const activeLineTakes = computed<Take[]>(() => {
    return takes.value.filter((take) => {
      const isActiveLine = take.lineId === activeLineId.value
      if (!isActiveLine) return false
      if (!sessionId.value) return true

      return showAllTakes.value || take.sessionId === sessionId.value
    })
  })

  const activeTakeId = signal<string | null>(null)
  const activeTake = computed(
    () => activeLineTakes.value.find((take) => take.id === activeTakeId.value) ?? null
  )

  effect(() => {
    if (activeLineId.value !== activeTake.value?.lineId) {
      activeTakeId.value = activeLineTakes.value[0]?.id ?? null
    }
  })

  const playActiveTake = (options?: { startTime?: number }) => {
    activeTake.value?.audioBufferPlayer?.play({ startFrom: options?.startTime ?? 0 })
  }

  const stopActiveTake = () => {
    activeTake.value?.audioBufferPlayer?.stop()
  }

  const findLineInActiveScene = (lineId: string): Line | undefined => {
    const activeScene = scenes.value.find((scene) =>
      scene.lines?.some((line) => line.id === activeLineId.value)
    )

    if (!activeScene) {
      return undefined
    }

    const line = activeScene.lines?.find((line) => line.id === lineId)

    return line
  }

  const playReferenceAudio = (lineId: string) => {
    // For reference audio to work, a line that are is not being recorded must be fetched from the scene
    const line = lines.value.find((line) => line.id === lineId) ?? findLineInActiveScene(lineId)

    if (!line) {
      return
    }

    // If reference audio exists, play it
    const lineTranslation = line.translations[language.value]
    if (lineTranslation && lineTranslation.referenceAudioUri) {
      lineTranslation.referenceAudioBufferPlayer?.play({ startFrom: 0 })
    }
  }

  const stopReferenceAudio = (lineId: string) => {
    // For reference audio to work, a line that are is not being recorded must be fetched from the scene
    const line = lines.value.find((line) => line.id === lineId) ?? findLineInActiveScene(lineId)

    if (!line) {
      return
    }

    // If reference audio exists, play it
    const lineTranslation = line.translations[language.value]
    if (lineTranslation && lineTranslation.referenceAudioUri) {
      lineTranslation.referenceAudioBufferPlayer?.stop()
    }
  }

  // Summary stats
  const numLines = computed<number>(() => {
    return lines.value.length
  })

  const numLinesWithTakes = computed<number>(() => {
    return lines.value.reduce((sum, curr) => {
      const lineTakes =
        curr.translations[language.value]?.takes?.filter((take) => {
          const filterTake = showAllTakes.value || take.sessionId === sessionId.value
          return filterTake
        }) ?? []

      if (lineTakes.length > 0) {
        return sum + 1
      }
      return sum
    }, 0)
  })

  const numLinesWithSelectedTake = computed<number>(() => {
    return lines.value.reduce((sum, curr) => {
      const lineTakes =
        curr.translations[language.value]?.takes?.filter((take) => {
          const filterTake = showAllTakes.value || take.sessionId === sessionId.value
          return filterTake
        }) ?? []

      const hasTakes = lineTakes.length > 0
      const hasSelectedTake = hasTakes && !!curr.translations[language.value].selectedTake

      if (hasTakes && hasSelectedTake) {
        return sum + 1
      }
      return sum
    }, 0)
  })
  // Summary stats

  // Select first line in list when filter changes
  effect(() => {
    if (selectedCharacter.value) {
      if (activeLine.value?.character?.id !== selectedCharacter.value) {
        activeLineId.value = activeLines.value[0].id
      }
    }
  })

  function _createTake(lineId: string, take: Take) {
    lines.value = lines.value.map((line): Line => {
      if (line.id !== lineId) return line
      return {
        ...line,
        translations: {
          ...line.translations,
          [language.value]: {
            ...line.translations[language.value],
            takes: [take, ...(line.translations[language.value]?.takes ?? [])]
          }
        }
      }
    })
  }

  function _updateTake(lineId: string, takeId: string, _take: Partial<Take>) {
    lines.value = lines.value.map((line): Line => {
      if (line.id !== lineId) return line

      const newTakes =
        line.translations[language.value].takes?.map((take): Take => {
          if (take.id !== takeId) return take

          const newTake = {
            ...take,
            ..._take
          }

          return newTake
        }) ?? []

      return {
        ...line,
        translations: {
          ...line.translations,
          [language.value]: {
            ...line.translations[language.value],
            takes: newTakes
          }
        }
      }
    })
  }

  function _deleteTake(take: Take) {
    lines.value = lines.value.map((line): Line => {
      if (line.id !== take.lineId) {
        return line
      }

      return {
        ...line,
        translations: {
          ...line.translations,
          [language.value]: {
            ...line.translations[language.value],
            takes: line.translations[language.value].takes?.filter((_take) => _take.id !== take.id)
          }
        }
      }
    })
  }

  function _updateLine(lineId: string, _line: Partial<Line>) {
    lines.value = lines.value.map((line): Line => {
      if (line.id !== lineId) return line

      return {
        ...line,
        ..._line
      }
    })
  }

  async function deleteTake({ lineId, take, onDelete }: DeleteTakeProps) {
    if (onDelete) {
      _updateTake(lineId, take.id, { status: 'loading' })
      try {
        await onDelete(take.id)
        _deleteTake(take)
      } catch (e: any) {
        _updateTake(lineId, take.id, { error: e.message })
      }
    } else {
      _deleteTake(take)
    }
  }

  async function setSelectedTake({ lineId, takeId }: SetSelectedTakeProps) {
    const line = lines.value.find((line) => line.id === lineId)
    if (!line) {
      throw new Error(`Line ${lineId} not found`)
    }

    const take = line.translations[language.value].takes?.find((take) => take.id === takeId)
    if (!take) {
      throw new Error(`Take ${takeId} on line ${lineId} not found`)
    }

    const newSelectedTake =
      line.translations[language.value].selectedTake?.id !== takeId ? take : undefined

    _updateLine(lineId, {
      translations: {
        ...line.translations,
        [language.value]: {
          ...line.translations[language.value],
          selectedTake: newSelectedTake
        }
      }
    })

    if (props.onUpdateLine) {
      try {
        _updateTake(lineId, takeId, { status: 'loading' })
        await props.onUpdateLine(lineId, {
          selectedTakes: [{ language: language.value, takeId: newSelectedTake?.id ?? null }]
        })

        _updateTake(lineId, takeId, { status: 'done' })
      } catch (e: any) {
        if (props.onError) {
          props.onError(e)
        }
        _updateTake(lineId, takeId, { error: e.message, status: 'done' })
      }
    }
  }

  async function updateTrim({ lineId, onUpload, takeId, audioBuffer, start, end }: TrimProps) {
    if (onUpload && props.onUploadAudioBuffer) {
      _updateTake(lineId, takeId, { status: 'loading' })
      try {
        const trimmed = await trimAudio(audioBuffer, start, end)
        const uri = await props.onUploadAudioBuffer(trimmed)

        await onUpload(takeId, uri, start, end)

        _updateTake(lineId, takeId, {
          uri,
          trimStart: Number(start),
          trimEnd: Number(end),
          status: 'done'
        })
      } catch (e: any) {
        if (props.onError) {
          props.onError(e)
        }
        _updateTake(lineId, takeId, { error: e.message, status: 'done' })
      }
    } else {
      _updateTake(lineId, takeId, { trimStart: Number(start), trimEnd: Number(end) })
    }
  }

  function selectLine(lineId: string, options?: { scrollBehaviour: ScrollBehavior }) {
    activeLineId.value = lineId

    const el = document.getElementById(`${activeLineId.value}`)

    el?.scrollIntoView({
      behavior: options?.scrollBehaviour ?? 'smooth'
    })
  }

  function selectCharacter(characterId?: string) {
    selectedCharacter.value = characterId ?? null
  }

  const setTrimLock = async ({ lineId, takeId, locked }: SetTrimLockParams) => {
    if (props.onUpdateTake) {
      await props.onUpdateTake(takeId, { trimLocked: locked })
      _updateTake(lineId, takeId, { trimLocked: locked })
    }
  }

  const setSampleSize = (newSampleSize: SampleSize) => {
    sampleSize.value = newSampleSize
  }

  function nextLine(options?: { scrollBehaviour: ScrollBehavior }) {
    if (activeLineIndex.value + 1 < activeLines.value.length) {
      activeLineId.value = activeLines.value[activeLineIndex.value + 1].id
    }

    const el = document.getElementById(`${activeLineId.value}`)
    el?.scrollIntoView({
      behavior: options?.scrollBehaviour ?? 'smooth'
    })
  }

  function prevLine(options?: { scrollBehaviour: ScrollBehavior }) {
    if (activeLineIndex.value - 1 >= 0) {
      activeLineId.value = activeLines.value[activeLineIndex.value - 1].id
    }
    const el = document.getElementById(`${activeLineId.value}`)
    el?.scrollIntoView({
      behavior: options?.scrollBehaviour ?? 'smooth'
    })
  }

  function selectTake(takeId: string, scrollOptions?: ScrollIntoViewOptions) {
    activeTakeId.value = takeId
    const el = document.getElementById(`${activeTakeId.value}`)
    if (scrollOptions) {
      el?.scrollIntoView({
        behavior: scrollOptions.behavior ?? 'smooth',
        block: scrollOptions.block
      })
    }
  }

  function nextTake() {
    const activeTakeIndex = activeLineTakes.value.findIndex(
      (take) => take.id === activeTakeId.value
    )

    if (activeTakeIndex >= 0 && activeTakeIndex + 1 < activeLineTakes.value.length) {
      const nextTakeId = activeLineTakes.value[activeTakeIndex + 1].id
      selectTake(nextTakeId, { behavior: 'smooth', block: 'center' })
    }
  }

  function prevTake() {
    const activeTakeIndex = activeLineTakes.value.findIndex(
      (take) => take.id === activeTakeId.value
    )
    if (activeTakeIndex >= 1) {
      const previousTakeId = activeLineTakes.value[activeTakeIndex - 1].id
      selectTake(previousTakeId, { behavior: 'smooth', block: 'center' })
    }
  }

  async function createTake({ lineId, audioBuffer, onUpload }: CreateTakeProps) {
    const peakWarning = recordingPeakWarning.value
    if (!lineId) return

    if (audioBuffer) {
      const takeId = uuid()

      _createTake(lineId, {
        sessionId: sessionId.value,
        id: takeId,
        status: onUpload ? 'loading' : 'done',
        audioBuffer,
        created: new Date(),
        lineId,
        voice: voice.value ?? undefined,
        peakWarning,
        trimStart: 0,
        trimEnd: audioBuffer.duration
      })

      recordingPeakWarning.value = false

      if (onUpload && props.onUploadAudioBuffer) {
        try {
          const uri = await props.onUploadAudioBuffer(audioBuffer)

          const take = await onUpload(lineId, {
            id: takeId,
            uri,
            peakWarning,
            trimStart: 0,
            trimEnd: audioBuffer.duration
          })

          _updateTake(lineId, takeId, {
            status: 'done',
            rawAudioUri: uri,
            uri: take?.uri,
            processedUri: take?.uri,
            seq: take?.seq,
            seqLanguage: take?.seqLanguage
          })

          selectTake(takeId)
        } catch (e: any) {
          if (props.onError) {
            props.onError(e)
          }
          _updateTake(lineId, takeId, {
            status: 'done',
            error: e.message
          })
        }
      }
    }
  }

  const startRecording = () => {}
  const stopRecording = () => {}

  const playPreviousLineFromScene = async ({
    onEnded
  }: PlayPreviousLineFromSceneParams = {}): Promise<void> => {
    // Check if active line is part of a scene
    const activeScene = scenes.value.find((scene) =>
      scene.lines?.some((line) => line.id === activeLineId.value)
    )
    if (!activeScene) {
      return
    }

    // Get previous line from scene
    const lineIndexInScene =
      activeScene.lines?.findIndex((line) => line.id === activeLineId.value) ?? -1
    if (lineIndexInScene <= 0) {
      return
    }
    const previousLine = activeScene.lines![lineIndexInScene - 1]

    // If previous line has reference audio, play it
    const lineTranslation = previousLine.translations[language.value]
    if (lineTranslation && lineTranslation.referenceAudioUri) {
      if (lineTranslation.referenceAudioBufferPlayer?.state === 'playing') {
        lineTranslation.referenceAudioBufferPlayer.stop()
      } else {
        lineTranslation.referenceAudioBufferPlayer?.play({ startFrom: 0, onEnded })
      }
    }
  }

  const playNextLineFromScene = async (): Promise<void> => {
    // Check if active line is part of a scene
    const activeScene = scenes.value.find((scene) =>
      scene.lines?.some((line) => line.id === activeLineId.value)
    )

    if (!activeScene) {
      return
    }

    // Get next line from scene
    const lineIndexInScene =
      activeScene.lines?.findIndex((line) => line.id === activeLineId.value) ?? -1

    if (lineIndexInScene < 0) {
      return
    }
    const nextLineIndex = lineIndexInScene + 1
    if (nextLineIndex >= activeScene.lines!.length) {
      return
    }
    const nextLine = activeScene.lines![nextLineIndex]

    // If next line has reference audio, play it
    const lineTranslation = nextLine.translations[language.value]
    if (lineTranslation && lineTranslation.referenceAudioUri) {
      if (lineTranslation.referenceAudioBufferPlayer?.state === 'playing') {
        lineTranslation.referenceAudioBufferPlayer?.stop()
      } else {
        lineTranslation.referenceAudioBufferPlayer?.play({ startFrom: 0 })
      }
    }
  }

  return {
    sessionId,
    activeLine,
    activeLineId,
    activeLineIndex,
    activeLines,
    activeLineTakes,
    activeTake,
    activeTakeId,
    autoAdvance,
    remotePlaybackEnabled,
    game,
    language,
    lines,
    scenes,
    numLines,
    numLinesWithSelectedTake,
    numLinesWithTakes,
    pushToRecord,
    recordingPeakWarning,
    selectedCharacter,
    showAllTakes,
    takes,
    voice,
    sampleSize,
    isTakeFocusModeEnabled,

    deleteTake,
    createTake,
    selectLine,
    selectTake,
    selectCharacter,
    playActiveTake,
    stopActiveTake,
    nextTake,
    prevTake,
    nextLine,
    prevLine,
    setSelectedTake,
    updateTrim,
    setTrimLock,
    startRecording,
    stopRecording,
    playPreviousLineFromScene,
    playNextLineFromScene,
    playReferenceAudio,
    stopReferenceAudio
  }
}

type KeyboardOptions = {
  isRecording: boolean
  onStartRecording: () => void
  onStopRecording: () => void
}

export function useKeyboardEvents(options: KeyboardOptions, lineRecorder?: LineRecorderState) {
  const holding = useSignal<'z' | 'x' | null>(null)

  useEffect(() => {
    if (!lineRecorder) {
      return
    }

    const startRecording = () => {
      if (lineRecorder.isTakeFocusModeEnabled.value) {
        return
      }
      if (lineRecorder.pushToRecord.value) {
        options.onStartRecording()
      } else {
        if (options.isRecording) {
          options.onStopRecording()
          if (lineRecorder.autoAdvance.value) {
            lineRecorder.nextLine()
          }
        } else {
          options.onStartRecording()
        }
      }
    }

    const keyDownHandler = (e: KeyboardEvent) => {
      const keyBindings: Record<string, () => void> = {
        ArrowUp: () => {
          if (lineRecorder.isTakeFocusModeEnabled.value) {
            return
          }
          lineRecorder.prevLine()
        },
        ArrowDown: () => {
          if (lineRecorder.isTakeFocusModeEnabled.value) {
            return
          }
          lineRecorder.nextLine()
        },
        ArrowLeft: () => {
          if (lineRecorder.isTakeFocusModeEnabled.value) {
            return
          }
          if (!holding.value) {
            lineRecorder.prevTake()
          }
        },
        ArrowRight: () => {
          if (lineRecorder.isTakeFocusModeEnabled.value) {
            return
          }
          if (!holding.value) {
            lineRecorder.nextTake()
          }
        },
        Enter: () => {
          startRecording()
        },
        l: () => {
          if (lineRecorder.activeTake.value) {
            const take = lineRecorder.activeTake.value
            lineRecorder.setTrimLock({
              lineId: take.lineId!,
              takeId: take.id,
              locked: !take?.trimLocked
            })
          }
        },
        z: () => {
          holding.value = 'z'
        },
        x: () => {
          holding.value = 'x'
        },
        n: () => {
          if (lineRecorder.isTakeFocusModeEnabled.value) {
            return
          }
          lineRecorder.playNextLineFromScene()
        },
        p: () => {
          if (lineRecorder.isTakeFocusModeEnabled.value) {
            return
          }
          lineRecorder.playPreviousLineFromScene()
        },
        q: () => {
          if (lineRecorder.isTakeFocusModeEnabled.value) {
            return
          }
          const lineIdToRecord = lineRecorder.activeLineId.value
          lineRecorder.playPreviousLineFromScene({
            onEnded: () => {
              // Start recording if the same line is still active
              if (lineRecorder.activeLineId.value === lineIdToRecord) {
                startRecording()
              }
            }
          })
        }
      }

      const callback = e.metaKey || e.ctrlKey ? undefined : keyBindings[e.key]

      if (callback) {
        e.preventDefault()
        callback()
      }
    }

    const keyUpHandler = (e: KeyboardEvent) => {
      e.preventDefault()
      const callback = {
        Enter: () => {
          if (lineRecorder.pushToRecord.value) {
            options.onStopRecording()
            if (lineRecorder.autoAdvance.value) {
              lineRecorder.nextLine()
            }
          }
        },
        z: () => {
          if (holding.value === 'z') {
            holding.value = null
          }
        },
        x: () => {
          if (holding.value === 'x') {
            holding.value = null
          }
        }
      }[e.key]

      if (callback) {
        e.preventDefault()
        callback()
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('keyup', keyUpHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
      document.removeEventListener('keyup', keyUpHandler)
    }
  }, [
    lineRecorder?.pushToRecord.value,
    options.isRecording,
    options.onStartRecording,
    options.onStopRecording
  ])
}
