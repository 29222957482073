import { h } from 'preact'
import { useState } from 'preact/hooks'
import { BasicDialog, Checkbox } from '@sodra/bongo-ui'
import { goBack } from '@sodra/prutt'

import { deleteSceneLine } from '../../actions'
import { useStore } from '../../store'

type Props = {
  lineId: string
}

export const DeleteLine = ({ lineId }: Props) => {
  const { scene, isDeletingSceneLine } = useStore('scene', 'isDeletingSceneLine')
  const [deletePermanently, setDeletePermanently] = useState(false)

  const onClose = () => goBack(`/scene/${scene!.id}`)

  const handleSubmit = async () => {
    if (await deleteSceneLine(lineId, { deletePermanently })) {
      onClose()
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      title="Remove line from scene"
      primaryActionText="Continue"
      primaryActionLoading={isDeletingSceneLine}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Checkbox
        label="Delete permanently"
        checked={deletePermanently}
        onChange={setDeletePermanently}
      />
    </BasicDialog>
  )
}

export default DeleteLine
