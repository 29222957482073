import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Block, Row } from 'jsxstyle/preact'
import { Button, DataTable, EventIcon, IconButton, SpacerHorizontal } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

import Avatar from '../Avatar'
import { Session } from '../../types'
import { ScrollableDataTable } from '../ScrollableDataTable'
import { formatSessionStatus } from '../../formatSessionStatus'
import { useStore } from '../../store'
import { formatLanguage } from '../../format-language'
import { formatScheduled } from 'lib'
import { getPreview } from '../../text/get-preview'

type Props = {
  loading: boolean
  sessions: Session[]
  total: number
  pageSize?: number
  page: number
  setPage: (newPage: number) => void
  orderBy: string
  setOrderBy: (orderBy: string) => void
  sortOrder: string
  setSortOrder: (sortOrder: 'asc' | 'desc') => void
}

const SessionsTable = ({
  loading,
  sessions,
  total,
  pageSize = 10,
  page,
  setPage,
  orderBy,
  setOrderBy,
  sortOrder,
  setSortOrder
}: Props) => {
  const { currentGame } = useStore('currentGame')
  const isLocalized = currentGame?.secondaryLanguages && currentGame.secondaryLanguages.length > 0

  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => setShowSpinner(true), 500)
      return () => clearTimeout(timer)
    } else {
      setShowSpinner(false)
    }
  }, [loading])

  const header = [
    { value: 'voice_name', sortable: true, label: 'Voice' },
    { value: 'num', sortable: true, label: 'Session' },
    { value: 'status', sortable: false, label: 'Status' },
    { value: 'description', sortable: false, label: 'Description' }
  ]

  const showLanguage = isLocalized && sessions.some((s) => s.language)
  if (showLanguage) {
    header.push({ value: 'language', sortable: true, label: 'Language' })
  }

  header.push(
    { value: 'scheduled', sortable: true, label: 'Start time' },
    { value: 'duration', sortable: true, label: 'Duration' },
    { value: 'characters', sortable: true, label: 'Characters' },
    { value: 'lines', sortable: true, label: 'Lines' }
  )

  const body = sessions.map((session) => {
    let description = session.description
    if (session.description) {
      description = getPreview(session.description, { numChars: 50, numLines: 4 }).join('\n')
    }

    const cols = [
      session.voice ? (
        <Row
          alignItems="center"
          maxWidth="200px"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          gap={10}
        >
          <Avatar size={30} src={session.voice.picture} name={session.voice.name} />
          {session.voice.name}
        </Row>
      ) : (
        <Block color="var(--on-surface-light)">Not assigned</Block>
      ),
      <Block color="var(--on-surface-light)">{session.num}</Block>,
      <Block color="var(--on-surface-light)" whiteSpace="nowrap">
        {formatSessionStatus(session)}
      </Block>,
      <Block color="var(--on-surface-light)" minWidth="200px">
        {description}
      </Block>
    ]
    if (showLanguage) {
      cols.push(<Block>{session.language ? formatLanguage(session.language) : ''}</Block>)
    }
    cols.push(
      <Row alignItems="center">
        <Block whiteSpace="nowrap">{formatScheduled(session.scheduled)}</Block>
        <SpacerHorizontal tiny />
        <IconButton
          flexShrink="0"
          icon={EventIcon}
          tooltipText="Add to calendar"
          onClick={() => {
            location.href = `${import.meta.env.VITE_API_URL}/v1/sessions/${session.id}.ics`
          }}
        />
      </Row>,
      <Block>
        {session.duration} hour{session.duration !== 1 ? 's' : ''}
      </Block>,
      <Button
        whiteSpace="nowrap"
        marginLeft="-15px"
        link={{
          href: `/characters?sessionId=${session.id}`,
          onRoute: routeTo
        }}
      >
        {session.characters} character{session.characters !== 1 ? 's' : ''}
      </Button>,
      <Button
        whiteSpace="nowrap"
        marginLeft="-15px"
        link={{
          href: `/lines?sessionId=${session.id}`,
          onRoute: routeTo
        }}
      >
        {session.lines} line{session.lines !== 1 ? 's' : ''}
      </Button>
    )
    return cols
  })

  const handleSort = (column: string, sortOrder: 'asc' | 'desc') => {
    setOrderBy(column)
    setSortOrder(sortOrder)
    setPage(0)
  }

  return (
    <Block flex="1" position="relative">
      <ScrollableDataTable
        loading={showSpinner}
        header={header}
        body={body}
        sortedColumn={orderBy}
        sortOrder={sortOrder}
        onSort={handleSort}
        onClick={(index: number) => routeTo(`/session/${sessions[index].id}`)}
        pagination={{
          page,
          numRowsPerPage: pageSize,
          numRows: total,
          onPageChange: setPage
        }}
        emptyText="No sessions found"
      />
    </Block>
  )
}

export default SessionsTable
