import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { Row, Block } from 'jsxstyle/preact'
import { Button, DataTable, IconButton, MoreIcon, SpacerHorizontal } from '@sodra/bongo-ui'
import { routeTo } from '@sodra/prutt'

import Avatar from '../Avatar'
import { usePopupMenu } from '../use-popup-menu'
import { ScrollableDataTable } from '../ScrollableDataTable'

const EventsTable = ({
  loading,
  events,
  total,
  pageSize = 10,
  page,
  setPage,
  orderBy,
  setOrderBy,
  sortOrder,
  setSortOrder
}) => {
  const [showSpinner, setShowSpinner] = useState(false)

  const { PopupMenu, showMenu } = usePopupMenu({
    options: [
      { label: 'Edit', onClick: (event) => routeTo(`/event/${event.id}`) },
      { label: 'Clone', onClick: (event) => routeTo(`/event/${event.id}/clone`) },
      { label: 'Delete', onClick: (event) => routeTo(`/event/${event.id}/delete`) }
    ]
  })

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => setShowSpinner(true), 500)
      return () => clearTimeout(timer)
    } else {
      setShowSpinner(false)
    }
  }, [loading])

  const header = [
    { value: 'name', sortable: true, label: 'Name' },
    { value: 'description', sortable: false, label: 'Description' },
    { value: 'characters', sortable: true, label: 'Characters' },
    { value: 'lines', sortable: true, label: 'Lines' },
    { label: '' }
  ]

  const body = events.map((event) => {
    return [
      <Row alignItems="center">
        <Avatar size="30" src={event.picture} name={event.name} />
        <SpacerHorizontal small />
        <Block maxWidth="200px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {event.name}
        </Block>
      </Row>,
      <Block color="var(--on-surface-light)">{event.description}</Block>,
      <Button
        whiteSpace="nowrap"
        marginLeft="-15px"
        link={{
          href: `/characters?eventId=${event.id}`,
          onRoute: routeTo
        }}
      >
        {event.characters} character{event.characters !== 1 ? 's' : ''}
      </Button>,
      <Button
        whiteSpace="nowrap"
        marginLeft="-15px"
        link={{
          href: `/lines?eventId=${event.id}`,
          onRoute: routeTo
        }}
      >
        {event.lines} line{event.lines !== 1 ? 's' : ''}
      </Button>,
      <IconButton icon={MoreIcon} onClick={(e) => showMenu(e, event)} />
    ]
  })

  const handleSort = (column, sortOrder) => {
    setOrderBy(column)
    setSortOrder(sortOrder)
    setPage(0)
  }

  return (
    <Block flex="1" position="relative">
      <ScrollableDataTable
        loading={showSpinner}
        header={header}
        body={body}
        sortedColumn={orderBy}
        sortOrder={sortOrder}
        onSort={handleSort}
        onClick={(index) => routeTo(`/event/${events[index].id}`)}
        pagination={{
          page,
          numRowsPerPage: pageSize,
          numRows: total,
          onPageChange: setPage
        }}
        emptyText="No events found"
      />
      {PopupMenu}
    </Block>
  )
}

export default EventsTable
