import { useEffect, useState } from 'preact/hooks'
import { get } from './api'
import { Session } from './types'

export type UseSessionResponse = {
  sessionId?: string
  setSessionId: (sessionId?: string) => void
  session?: Session
  setSession: (session?: Session) => void
  clearSession: () => void
}

export const useSession = (initialSessionId?: string): UseSessionResponse => {
  const [sessionId, setSessionId] = useState<string | undefined>(initialSessionId)
  const [session, setSession] = useState<Session | undefined>()

  useEffect(() => {
    if (sessionId && (!session || sessionId !== session.id)) {
      get(`/sessions/${sessionId}`).then(({ data: session }) => setSession(session))
    }
    if (!sessionId) {
      setSession(undefined)
    }
  }, [sessionId])

  return {
    sessionId,
    setSessionId,
    session,
    setSession: (session) => {
      setSession(session)
      if (session) {
        setSessionId(session.id)
      } else {
        setSessionId(undefined)
      }
    },
    clearSession: () => {
      setSessionId(undefined)
      setSession(undefined)
    }
  }
}
