import { StateUpdater, useEffect, useState } from 'preact/hooks'
import { getItem, removeItem, setItem } from './storage'

export const useLocalStorageState = <T>(name: string, initialValue?: T): [T, StateUpdater<T>] => {
  const [state, setState] = useState<T>(getItem(name, initialValue))

  useEffect(() => {
    if (state !== undefined) {
      setItem(name, state)
    } else {
      removeItem(name)
    }
  }, [state])

  return [state, setState]
}
