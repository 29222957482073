import { h } from 'preact'
import { Block, Row } from 'jsxstyle/preact'
import { List, ProgressCircular } from '@sodra/bongo-ui'
import { useEffect, useState } from 'preact/hooks'

const LoadingList = ({ loading, ...props }) => {
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    if (loading) {
      const timeout = setTimeout(() => setShowSpinner(true), 500)
      return () => clearTimeout(timeout)
    }
    setShowSpinner(false)
  }, [loading])

  return (
    <Block position="relative">
      <List {...props} />
      <Row
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        right="0"
        background="rgba(0, 0, 0, 0.25)"
        pointerEvents="none"
        opacity={showSpinner ? 1 : 0}
        transition="opacity 0.18s cubic-bezier(0, 0, .2, 1)"
      >
        <ProgressCircular />
      </Row>
    </Block>
  )
}

export default LoadingList
