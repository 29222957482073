import { h } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'
import { Block, Grid, Row } from 'jsxstyle/preact'
import {
  Button,
  Checkbox,
  CheckmarkIcon,
  DeleteIcon,
  ErrorIcon,
  Form,
  Link,
  ListIcon,
  NativeSelect,
  P,
  PlayIcon,
  RadioButton,
  Select,
  SpacerHorizontal,
  SpacerVertical,
  TextField
} from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'

import { deleteExportTarget, showSnackbar, updateExportTarget } from '../../actions'
import { useEffectSkipFirst } from '../../use-effect-skip-first'

import { outputFiles } from '../ExportTargets/output-files'
import { loudnessOptions } from '../ExportTargets/loudness-options'

import Page from '../Page'
import { useStore } from '../../store'
import Spinner from '../Spinner'
import { requiredScopes } from '../ExportTargets/CreateExportTarget'
import { confirm } from 'lib'
import {
  DriveTargetTypes,
  ExportTarget,
  ExportTargetProcessAudioTakeType,
  LineAttribute,
  LineFilter
} from '../../types'
import { formatDuration } from '../../format-duration'
import { SyncDialog } from './SyncDialog'
import { get, post } from '../../api'
import { FilenameExampleOptions, getFilenameExample } from '../ExportTargets/get-filename-example'
import { SubstitutionVariables } from './SubstitutionVariables'
import { lineAttributeToVariable } from '../WwiseExport/substitution-variables'
import { TextFieldSuggest } from '../WwiseExport/TextFieldSuggest'
import { loadScript } from 'src/load-script'

declare var google: any
declare var gapi: any

const getStatus = (exportTarget: ExportTarget & { type: DriveTargetTypes }) => {
  if (exportTarget.error) {
    return (
      <Row alignItems="center" gap="5px">
        <ErrorIcon fill="var(--error)" flexShrink="0" />
        {exportTarget.error}
      </Row>
    )
  }
  if (exportTarget.completed) {
    return (
      <Row alignItems="center" gap="5px">
        <CheckmarkIcon fill="var(--ok)" /> Success
      </Row>
    )
  }
  if (exportTarget.started) {
    return <Block>{exportTarget.progress}%</Block>
  }
  return <Block>Has not run yet</Block>
}

type EditGoogleDriveDetailsParams = {
  exportTarget: ExportTarget & { type: DriveTargetTypes }
  lineFilters: LineFilter[]
}

const EditGoogleDriveDetails = ({ exportTarget, lineFilters }: EditGoogleDriveDetailsParams) => {
  const { currentGame, isUpdatingExportTarget } = useStore('currentGame', 'isUpdatingExportTarget')

  const [name, setName] = useState(exportTarget!.name)
  const [processAudio, setProcessAudio] = useState(exportTarget!.processAudio)
  const [autoSync, setAutoSync] = useState(exportTarget!.autoSync)
  const [folderId, setFolderId] = useState(exportTarget!.folderId)
  const [folderName, setFolderName] = useState(exportTarget!.folderName)
  const [folder, setFolder] = useState(exportTarget!.folder || '$CHARACTER/$EVENT')
  const [exampleLine, setExampleLine] = useState<FilenameExampleOptions>()
  const [lineAttributes, setLineAttributes] = useState<LineAttribute[]>([])
  const [format, setFormat] = useState(exportTarget!.format)
  const [bitrate, setBitrate] = useState(exportTarget!.bitrate)
  const [loudness, setLoudness] = useState(exportTarget!.loudness)
  const [ending, setEnding] = useState(exportTarget!.ending)
  const [silenceStart, setSilenceStart] = useState(exportTarget!.silenceStart)
  const [silenceEnd, setSilenceEnd] = useState(exportTarget!.silenceEnd)
  const [substitutionVariablesVisible, setSubstitutionVariablesVisible] = useState(false)
  const [processAudioTakeType, setProcessAudioTakeType] =
    useState<ExportTargetProcessAudioTakeType>(exportTarget!.processAudioTakeType ?? 'all')

  const [lineFilter, setLineFilter] = useState<LineFilter | undefined>(exportTarget.lineFilter)

  const [syncDialogVisible, setSyncDialogVisible] = useState(false)

  const [pickerApiLoaded, setPickerApiLoaded] = useState(true)

  const formatOptions = Object.keys(outputFiles).map((value) => {
    const text = outputFiles[value].display_name
    return { value, label: text }
  })

  const bitrateOptions = outputFiles[format].bitrates.map((value: string, i: number) => {
    const text = outputFiles[format].bitrate_strings[i]
    return { value, label: text }
  })

  const endingOptions = outputFiles[format].endings.map((value: string) => {
    return { value, label: value }
  })

  const loadPicker = async () => {
    await loadScript('https://accounts.google.com/gsi/client')
    await loadScript('https://apis.google.com/js/api.js')
    gapi.load('picker', () => {})
  }

  useEffect(() => {
    loadPicker()
  }, [])

  useEffectSkipFirst(() => {
    setBitrate(outputFiles[format].default_bitrate)
    setEnding(endingOptions[0].value)
  }, [format])

  useEffect(() => {
    get(`/games/${currentGame?.id}/example-line`).then(({ data: exampleLine }) =>
      setExampleLine(exampleLine)
    )
    if (currentGame?.id) {
      get('/line-attributes', {
        gameId: currentGame.id
      }).then(({ data: lineAttributes }) => {
        setLineAttributes(lineAttributes)
      })
    } else {
      // Clear line attributes
      setLineAttributes([])
    }
  }, [currentGame?.id])

  let filenameExample = useMemo(() => {
    return getFilenameExample(
      folder,
      processAudio ? ending : 'wav',
      exampleLine ? { ...exampleLine } : undefined
    )
  }, [exampleLine, folder, processAudio, ending])

  const onBack = () => goBack('/export-targets')

  const handleSave = async () => {
    if (
      await updateExportTarget(exportTarget.id, {
        name,
        processAudio,
        autoSync,
        folderId,
        folderName,
        folder,
        format,
        bitrate: Number(bitrate),
        loudness: Number(loudness),
        ending,
        silenceStart: Number(silenceStart),
        silenceEnd: Number(silenceEnd),
        processAudioTakeType,
        lineFilterId: lineFilter?.id ?? null
      })
    ) {
      showSnackbar('Export target saved')
      onBack()
    }
  }

  const openPicker = async () => {
    const {
      data: { accessToken }
    } = await post('/google-access-tokens', {
      refreshToken: exportTarget.refreshToken
    })

    const pickerCallback = async (data: any) => {
      if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
        const doc = data[google.picker.Response.DOCUMENTS][0]
        //const url = doc[google.picker.Document.URL]
        setFolderId(doc.id)
        setFolderName(doc.name)
      }
    }
    const docsView = new google.picker.DocsView()
      .setIncludeFolders(true)
      .setMimeTypes('application/vnd.google-apps.folder')
      .setSelectFolderEnabled(true)
      .setOwnedByMe(true)
    const sharedView = new google.picker.DocsView()
      .setIncludeFolders(true)
      .setMimeTypes('application/vnd.google-apps.folder')
      .setSelectFolderEnabled(true)
      .setEnableDrives(true)
    const picker = new google.picker.PickerBuilder()
      .addView(docsView)
      .addView(sharedView)
      .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
      .setOAuthToken(accessToken)
      .setDeveloperKey(import.meta.env.VITE_GOOGLE_API_KEY)
      .setCallback(pickerCallback)
      .build()
    picker.setVisible(true)
  }

  if (!exportTarget) {
    return <Spinner />
  }

  const handleDeleteExportTarget = async () => {
    if (await confirm(`Delete ${exportTarget.name}?`))
      if (await deleteExportTarget(exportTarget.id)) {
        showSnackbar(`Export target ${exportTarget.name} deleted`)
        onBack()
      }
  }

  const showSubstitutionVariables = (e: Event) => {
    e.preventDefault()
    setSubstitutionVariablesVisible(true)
  }

  const needsReconnect =
    exportTarget.scopes === null ||
    requiredScopes.some((scope) => !exportTarget.scopes.includes(scope))

  const isSyncing = exportTarget.started && !exportTarget.completed

  const lineFilterOptions: { text: string; value: string }[] = [
    { text: '-- No line filter --', value: '' }
  ]
  lineFilterOptions.push(
    ...lineFilters
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((filter) => ({
        text: filter.name,
        value: filter.id
      }))
  )

  return (
    <Page
      title={exportTarget!.name}
      onBack={onBack}
      actions={[
        {
          label: 'Sync now',
          icon: PlayIcon,
          onClick: () => setSyncDialogVisible(true),
          loading: isSyncing
        },
        {
          label: 'Logs',
          icon: ListIcon,
          onClick: () => routeTo(`/export-target/${exportTarget.id}/logs`)
        },
        {
          label: 'Delete',
          icon: DeleteIcon,
          onClick: handleDeleteExportTarget
        }
      ]}
    >
      <Grid
        maxWidth="1400px"
        height="calc(100% + 40px)"
        margin="-20px"
        gridTemplateColumns="auto minmax(300px, 500px)"
      >
        <Form onSubmit={handleSave} padding="40px">
          <TextField width="100%" label="Name" value={name} onInput={setName} />
          <SpacerVertical />
          <Row alignItems="center">
            <TextField width="100%" label="Root folder" value={folderName || folderId} />
            <SpacerHorizontal />
            <Button onClick={openPicker}>Browse</Button>
          </Row>
          <SpacerVertical />
          <TextFieldSuggest
            suggestions={[
              { text: '$GAME', secondaryText: 'Game name' },
              { text: '$CHARACTER', secondaryText: 'Character name' },
              { text: '$EVENT', secondaryText: 'Event name' },
              { text: '$SCENE', secondaryText: 'Scene name' },
              ...(lineAttributes?.map((attribute) => ({
                text: lineAttributeToVariable(attribute),
                secondaryText: attribute.description
              })) ?? [])
            ]}
            label="Folder structure"
            value={folder}
            onInput={setFolder}
          />
          <SpacerVertical small />
          <Block color="var(--on-surface-light)" fontSize="12px">
            Note: You can use{' '}
            <a href="" onClick={showSubstitutionVariables}>
              substitution variables
            </a>{' '}
            to build your folder structure.
            <SpacerVertical small />
            Example filename with current settings:
            <SpacerVertical small />
            {filenameExample}
          </Block>
          <SpacerVertical large />
          <Row>
            <Select
              label="Line filter"
              value={lineFilter?.id ?? ''}
              options={lineFilterOptions}
              onChange={(selectedFilterId: string) => {
                setLineFilter(
                  selectedFilterId === ''
                    ? undefined
                    : lineFilters.find((filter) => filter.id === selectedFilterId)
                )
              }}
            />
          </Row>
          <SpacerVertical />
          <Block color="var(--on-surface-light)" fontSize="12px">
            Note: Select a line filter to export only a subset of all lines.
            <SpacerVertical small />
            Line filters are created from the{' '}
            <Link to="/lines" onRoute={routeTo}>
              Lines
            </Link>{' '}
            section.
          </Block>
          <SpacerVertical large />
          <Checkbox label="Auto sync (beta)" checked={autoSync} onChange={setAutoSync} />
          <SpacerVertical />
          <Checkbox label="Mastering" checked={processAudio} onChange={setProcessAudio} />
          <SpacerVertical />
          <Block paddingLeft="20px">
            <Block color={processAudio ? 'var(--on-surface-light)' : 'var(--on-surface-lighter)'}>
              Apply mastering to:
            </Block>
            <SpacerVertical tiny />
            <Row alignItems="center" paddingLeft="20px">
              <RadioButton
                checked={processAudioTakeType === 'all'}
                label="All takes"
                onChange={() => setProcessAudioTakeType('all')}
                disabled={!processAudio}
              />
              <SpacerHorizontal large />
              <RadioButton
                checked={processAudioTakeType === 'ai'}
                label="AI only"
                onChange={() => setProcessAudioTakeType('ai')}
                disabled={!processAudio}
              />
              <SpacerHorizontal large />
              <RadioButton
                checked={processAudioTakeType === 'non-ai'}
                label="Recorded only"
                onChange={() => setProcessAudioTakeType('non-ai')}
                disabled={!processAudio}
              />
            </Row>
            <SpacerVertical />
            <Row alignItems="center">
              <NativeSelect
                width="100%"
                label="Format"
                options={formatOptions}
                value={format}
                onChange={setFormat}
                disabled={!processAudio}
              />
              <SpacerHorizontal />
              <NativeSelect
                width="100%"
                label="Ending"
                options={endingOptions}
                value={ending}
                onChange={setEnding}
                disabled={!processAudio}
              />
            </Row>
            <SpacerVertical />
            <NativeSelect
              width="100%"
              label="Bitrate"
              options={bitrateOptions}
              value={bitrate}
              onChange={setBitrate}
              disabled={!processAudio}
            />
            <SpacerVertical />
            <NativeSelect
              width="100%"
              label="Loudness"
              options={loudnessOptions}
              value={loudness}
              onChange={setLoudness}
              disabled={!processAudio}
            />
            <SpacerVertical />
            <TextField
              width="100%"
              label="Add silence at start (ms)"
              value={silenceStart}
              onInput={setSilenceStart}
              disabled={!processAudio}
            />
            <SpacerVertical />
            <TextField
              width="100%"
              label="Add silence at end (ms)"
              value={silenceEnd}
              onInput={setSilenceEnd}
              disabled={!processAudio}
            />
          </Block>
          <SpacerVertical />
          <Row alignItems="center">
            <Button type="submit" contained loading={isUpdatingExportTarget}>
              Save
            </Button>
            <SpacerHorizontal />
            <Button onClick={onBack}>Cancel</Button>
          </Row>
        </Form>

        <Block padding="40px" borderLeft="1px solid var(--container-outline)">
          <Block fontSize="14px" marginBottom="1rem" color="var(--on-surface-light)">
            Status of last sync
          </Block>
          <P margin="1rem 0">{getStatus(exportTarget)}</P>
          <SpacerVertical />
          <Block fontSize="14px" marginBottom="1rem" color="var(--on-surface-light)">
            Last sync
          </Block>
          <P margin="1rem 0">
            {exportTarget.started ? formatDuration(exportTarget.started) : 'Has not run yet'}
          </P>
        </Block>
      </Grid>
      {syncDialogVisible && (
        <SyncDialog exportTarget={exportTarget} onClose={() => setSyncDialogVisible(false)} />
      )}
      {substitutionVariablesVisible && (
        <SubstitutionVariables
          lineAttributes={lineAttributes}
          onSelect={(variable) => {
            setFolder((folder) => {
              // Skip separator if folder name is empty or ends with '/', '_' or '-'
              const separator = folder.length === 0 || '/_-'.includes(folder.slice(-1)) ? '' : '/'

              return folder + separator + variable
            })
            setSubstitutionVariablesVisible(false)
          }}
          onClose={() => setSubstitutionVariablesVisible(false)}
        />
      )}
    </Page>
  )
}

export default EditGoogleDriveDetails
