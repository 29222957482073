import { h } from 'preact'
import { useState } from 'preact/hooks'
import { BasicDialog, Form, P, SpacerVertical, TextField } from '@sodra/bongo-ui'
import { createExampleLine } from '../../actions'
import { useStore } from '../../store'

type Props = {
  onClose: () => void
}

export const CreateExampleLine = ({ onClose }: Props) => {
  const { isCreatingExampleLine } = useStore('isCreatingExampleLine')
  const [line, setLine] = useState('')
  const [description, setDescription] = useState('')

  const [lineError, setLineError] = useState('')

  const handleSubmit = async () => {
    setLineError('')

    if (line.trim() === '') {
      setLineError('Please enter line')
      return
    }

    if (await createExampleLine({ line, description })) {
      onClose()
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      dismissable={false}
      title="Create audition line"
      primaryActionText="Create"
      primaryActionLoading={isCreatingExampleLine}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      onClose={onClose}
    >
      <Form onSubmit={handleSubmit}>
        <TextField
          autocomplete="off"
          width="100%"
          label="Line"
          value={line}
          onInput={setLine}
          errorText={lineError}
        />
        <SpacerVertical />
        <TextField
          autocomplete="off"
          width="100%"
          label="Acting notes"
          value={description}
          onInput={setDescription}
        />
      </Form>
    </BasicDialog>
  )
}

export default CreateExampleLine
