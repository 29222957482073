import { GameAiQuota } from './GameDashboard'

export const isGameAiQuotaEmpty = (gameAiQuota: GameAiQuota): boolean => {
  const hasRemainingAiQuota = !!(
    gameAiQuota &&
    (gameAiQuota.billingAccount?.basic?.some((quota) => quota.remainingSeconds > 0) ||
      gameAiQuota.billingAccount?.subscription?.some((quota) => quota.remainingSeconds > 0) ||
      (gameAiQuota.game && gameAiQuota.game.remainingSeconds > 0))
  )

  return !hasRemainingAiQuota
}

export const getGameAiQuotaRemainingSeconds = (gameAiQuota?: GameAiQuota): number => {
  if (!gameAiQuota) {
    return 0
  }

  let remainingSeconds = 0

  if (gameAiQuota.billingAccount) {
    for (let aiQuota of gameAiQuota.billingAccount.basic) {
      remainingSeconds += aiQuota.remainingSeconds
    }
    for (let aiQuota of gameAiQuota.billingAccount.subscription) {
      remainingSeconds += aiQuota.remainingSeconds
    }
  } else if (gameAiQuota.game) {
    remainingSeconds = gameAiQuota.game.remainingSeconds
  }

  return remainingSeconds
}
