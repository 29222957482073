import { store } from '../store'
import { get, post } from '../api'

export const createChat = async (voiceId, auditionDetails, shareCharacterSheet) => {
  try {
    store.setState({ isCreatingChat: voiceId })
    const gameId = store.getState().currentGame.id
    const { data: chat } = await post(`/chats`, {
      voiceId,
      gameId,
      auditionDetails,
      shareCharacterSheet
    })
    store.setState({ isCreatingChat: null })
    return chat
  } catch (error) {
    store.setState({ error, isCreatingChat: null })
  }
}

export const fetchChats = async () => {
  try {
    store.setState({ isFetchingChats: true })
    const gameId = store.getState().currentGame.id
    const { data: chats } = await get(`/chats`, { gameId })
    store.setState({ chats, isFetchingChats: false })
    return chats
  } catch (error) {
    store.setState({ error, isFetchingChats: false })
  }
}

export const setLastMessage = (chatId, message) => {
  store.setState({
    chats: store
      .getState()
      .chats.map((chat) => {
        if (chat.id === chatId) {
          return {
            ...chat,
            lastMessage: message
          }
        }
        return chat
      })
      .sort((a, b) => {
        if (a.lastMessage && !b.lastMessage) return -1
        if (b.lastMessage && !a.lastMessage) return 1

        if (a.lastMessage && b.lastMessage) {
          return a.lastMessage.seq < b.lastMessage.seq ? 1 : -1
        }

        return 0
      })
  })
}
