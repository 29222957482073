import { h } from 'preact'
import { Route, Switch } from '@sodra/prutt'

import ChangePassword from './ChangePassword'
import EditDetails from './EditDetails'

export const Account = () => {
  return (
    <Switch>
      <Route path="/account/change-password" component={ChangePassword} />
      <Route path="/account" component={EditDetails} />
    </Switch>
  )
}

export default Account
