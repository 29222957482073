import { h, Fragment } from 'preact'
import { Block } from 'jsxstyle/preact'
import { Button, PlayIcon, StopIcon } from '@sodra/bongo-ui'
import { AudioPlayer, SingleAudioTrack, useAudioPlayer } from 'lib/src/hooks/use-audio-player'
import { Voice } from '../../types'

export const StudioQualityDemo = ({ voice, player }: { voice: Voice; player: AudioPlayer }) => {
  const { resetPlayer, stopPlayer, play, playerState, initPlayer, hidePlayer, showPlayer } = player

  const currentTrack = playerState.value.currentTrack as SingleAudioTrack | undefined
  const isActive = currentTrack && currentTrack.uri === voice.studioQualityDemo?.uri
  const { isPlaying } = playerState.value
  const color = isActive ? 'var(--accent)' : 'var(--on-surface)'

  return (
    <Fragment>
      <Block fontSize="14px" marginBottom="1rem" color="var(--on-surface-light)">
        Studio quality demo
      </Block>
      {!voice.studioQualityDemo && '—'}
      {voice.studioQualityDemo && (
        <Button
          color={color}
          onClick={() => {
            if (!voice.studioQualityDemo?.uri) {
              return
            }
            if (isPlaying && isActive) {
              stopPlayer()
              hidePlayer()
              resetPlayer()
            } else {
              if (!isActive) {
                initPlayer({
                  audioTracks: [
                    {
                      picture: voice.picture,
                      name: 'Studio quality demo',
                      uri: voice.studioQualityDemo.uri
                    }
                  ]
                })
              }
              showPlayer()
              play()
            }
          }}
          icon={isPlaying && isActive ? StopIcon : PlayIcon}
          marginLeft="-15px"
          marginTop="-8px"
        >
          Play studio quality demo
        </Button>
      )}
    </Fragment>
  )
}
