import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { FilterListIcon, SelectChip } from '@sodra/bongo-ui'

import { get } from '../../api'

import { usePopupMenu } from '../use-popup-menu'

export const ToneSelectChip = ({ value, onChange }) => {
  const [tones, setTones] = useState([])

  useEffect(() => {
    get('/audio-tones').then(({ data: tones }) => setTones(tones))
  }, [])

  const { PopupMenu, showMenu } = usePopupMenu({
    options: tones.map((type) => {
      return {
        label: type,
        onClick: () => onChange(type)
      }
    })
  })

  return (
    <Fragment>
      <SelectChip
        label="Tone"
        icon={FilterListIcon}
        value={value}
        onClick={showMenu}
        onClear={() => onChange()}
        whiteSpace="nowrap"
      />
      {PopupMenu}
    </Fragment>
  )
}
