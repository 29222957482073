import { h } from 'preact'
import { SpacerVertical } from '@sodra/bongo-ui'
import { useEffect, useState } from 'preact/hooks'

import { useUrlParams } from '../../use-url-params'

import Page from '../Page'
import Spinner from '../Spinner'
import SearchTextField from '../SearchTextField'

import { Col } from 'jsxstyle/preact'
import { useStore } from '../../store'
import OrdersTable from './OrdersTable'
import { fetchOrders } from '../../actions/orders'

const pageSize = 10

export const Orders = () => {
  const {
    currentGame,
    orders,
    totalOrders = 0,
    isFetchingOrders
  } = useStore('currentGame', 'orders', 'totalOrders', 'isFetchingOrders')
  const params = new URLSearchParams(location.search)

  const [query, setQuery] = useState(params.get('query') || '')
  const [page, setPage] = useState(parseFloat(params.get('page') || '0'))
  const [orderBy, setOrderBy] = useState(params.get('orderBy') || 'created')
  const [sortOrder, setSortOrder] = useState(params.get('sortOrder') || 'desc')

  useUrlParams(
    { page, query, orderBy, sortOrder },
    { page: 0, query: '', orderBy: 'created', sortOrder: 'desc' }
  )

  useEffect(() => {
    const now = new Date()
    now.setUTCHours(now.getUTCHours() - 1)
    fetchOrders({
      // NOTE: api enpoint uses page=1, app (DataTable) uses page=0
      page: page + 1,
      pageSize,
      query,
      sortOrder,
      orderBy
    })
  }, [currentGame!.id, page, query, sortOrder, orderBy])

  if (!orders) {
    return <Spinner />
  }

  return (
    <Page title="Orders">
      <Col height="100%">
        <SearchTextField
          value={query}
          onChange={(query) => {
            setQuery(query)
            setPage(0)
          }}
        />
        <SpacerVertical />
        <OrdersTable
          loading={!!isFetchingOrders}
          orders={orders}
          total={totalOrders}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
      </Col>
    </Page>
  )
}

export default Orders
