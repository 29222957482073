import { h } from 'preact'
import { useState } from 'preact/hooks'
import { List, ListItem, PlusIcon, SpacerVertical } from '@sodra/bongo-ui'

import { useFetchResult } from '../use-fetch-result'

import Avatar from './Avatar'

import LargeDialog from './LargeDialog'
import SearchTextField from './SearchTextField'
import { useStore } from '../store'
import { Event } from '../types'
import { Block } from 'jsxstyle/preact'
import { routeTo } from '@sodra/prutt'

type Props = {
  onClose: () => void
  onSelect: (event?: Event) => void
}

export const SelectEvent = ({ onClose, onSelect }: Props) => {
  const { currentGame } = useStore('currentGame')
  const [query, setQuery] = useState('')

  const { data: events } = useFetchResult<Event[]>(`/games/${currentGame!.id}/events`, {
    query
  })

  return (
    <LargeDialog
      large
      title="Select event"
      onClose={onClose}
      action1Text="Cancel"
      onAction1Click={onClose}
      secondaryActionText="New event"
      secondaryActionIcon={PlusIcon}
      onSecondaryActionClick={() => routeTo(`/create-event`)}
    >
      <SearchTextField value={query} onChange={setQuery} />
      <SpacerVertical />
      {events && (
        <List>
          <ListItem
            visual={<Avatar size={30} name="×" />}
            text={
              <Block color="var(--on-surface-light)">
                <em>No event</em>
              </Block>
            }
            onClick={() => {
              onSelect(undefined)
              onClose()
            }}
          />
          {events.map((event) => {
            return (
              <ListItem
                visual={<Avatar size={30} src={event.picture} name={event.name} />}
                text={event.name}
                onClick={() => {
                  onSelect(event)
                  onClose()
                }}
              />
            )
          })}
        </List>
      )}
      <SpacerVertical />
    </LargeDialog>
  )
}

export default SelectEvent
