import { store } from '../store'
import { get } from '../api'

export const fetchVoice = async (voiceId) => {
  const { currentGame } = store.getState()
  try {
    store.setState({ isFetchingVoice: true })
    const { data: voice } = await get(`/voices/${voiceId}`, {
      gameId: currentGame.id
    })
    store.setState({ voice, isFetchingVoice: false })
    return voice
  } catch (error) {
    store.setState({ error, isFetchingVoice: false })
  }
}
