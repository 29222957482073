import { Fragment, h } from 'preact'
import { Block, Col, Row } from 'jsxstyle/preact'
import Avatar from '../Avatar'
import { Button, Link, SpacerHorizontal, SpacerVertical } from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'

import { updateShortlist } from '../../actions'
import { Character, Chat, Voice } from '../../types'
import { confirm } from 'lib'

type Props = {
  chat: Chat
  voice: Voice
  onCharacterSheetChanged: (characterId: string, characterSheetUnlocked: boolean) => void
}

export const ChatShortlist = ({ chat, voice, onCharacterSheetChanged }: Props) => {
  const updateCharacterSheet = async (character: Character, characterSheetUnlocked: boolean) => {
    onCharacterSheetChanged(character.id, characterSheetUnlocked)
    if (!(await updateShortlist(character.id, voice.id, { characterSheetUnlocked }))) {
      onCharacterSheetChanged(character.id, !characterSheetUnlocked)
      return false
    }
    return true
  }

  const shareCharacter = async (character: Character) => {
    if (
      await confirm({
        title: 'Share character',
        message: `Do you want to share information about ${character.name} with all participants of this chat?`
      })
    ) {
      updateCharacterSheet(character, true)
    }
  }

  return (
    <Block width="220px">
      {voice.characters.map((character) => {
        return (
          <Fragment>
            <SpacerVertical />
            <Row alignItems="center">
              <Avatar src={character.picture} name={character.name} />
              <SpacerHorizontal />
              <Col>
                <Block>{character.name}</Block>
                <Block>
                  <small>
                    <Link
                      title={`Go to ${character.name} profile`}
                      to={`/character/${character.id}`}
                      onRoute={routeTo}
                    >
                      Show character
                    </Link>
                  </small>
                </Block>
              </Col>
            </Row>
            <SpacerVertical />
            {!character.characterSheetUnlocked && (
              <Button small outlined onClick={() => shareCharacter(character)}>
                Share character
              </Button>
            )}
          </Fragment>
        )
      })}
    </Block>
  )
}
