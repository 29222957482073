export const createStore = <T>(initialState: T) => {
  let state = initialState

  type Listener = (state: T) => void

  let listeners: Listener[] = []

  const unsubscribe = (listener: Listener) => {
    listeners = listeners.filter((l) => l !== listener)
  }

  const subscribe = (listener: Listener) => {
    listeners = [...listeners, listener]
    return () => unsubscribe(listener)
  }

  const setState = (update: Partial<T>, overwrite?: boolean) => {
    if (overwrite) {
      state = update as T
    } else {
      state = {
        ...state,
        ...update
      }
    }
    for (let listener of listeners) {
      listener.call(null, state)
    }
  }

  const getState = () => state

  return {
    subscribe,
    unsubscribe,
    getState,
    setState
  }
}
