import { h } from 'preact'

import { Button, P, TextField } from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'

type Props = {
  port: number
  onPortChange: (port: number) => void
  onConnect: () => void
  connectionError?: string | null
}

export function ConnectionSettings(props: Props) {
  return (
    <>
      <Row alignItems="center" justifyContent="start" gap="20px">
        <Block maxWidth="180px">
          <TextField
            maxWidth="180px"
            type="number"
            value={props.port}
            label="WAMP Port"
            onInput={props.onPortChange}
          />
        </Block>
        <Button contained onClick={props.onConnect}>
          Connect
        </Button>
      </Row>
      <Row>
        {props.connectionError && <Block color="var(--warning)">{props.connectionError}</Block>}
      </Row>
    </>
  )
}
