import { h } from 'preact'
import { useState } from 'preact/hooks'
import { EditIcon, SpacerVertical } from '@sodra/bongo-ui'
import { goBack, Route, routeTo } from '@sodra/prutt'

import Page from './Page'
import Avatar from './Avatar'
import { createCharacter } from '../actions'
import PictureDialog from './PictureDialog'
import { useStore } from '../store'
import { SuperForm } from './SuperForm'

export const CreateCharacter = () => {
  const { isCreatingCharacter } = useStore('isCreatingCharacter')

  const [picture, setPicture] = useState('')

  const onClose = () => goBack('/characters')

  const handleSubmit = async (values: Record<string, any>) => {
    if (await createCharacter({ ...values, picture })) {
      onClose()
    }
  }

  return (
    <Page title="Create character" onBack={onClose}>
      <Avatar
        actionIcon={EditIcon}
        onActionClick={() => routeTo('/create-character/picture')}
        size={150}
        src={picture}
        actionName="edit-character-picture"
      />
      <SpacerVertical />
      <SuperForm
        onSubmit={handleSubmit}
        onCancel={onClose}
        submitText="Create character"
        loading={isCreatingCharacter}
        elements={[
          {
            element: 'TextField',
            label: 'Name',
            name: 'name',
            value: '',
            required: true
          },
          {
            element: 'TextField',
            label: 'Archetype',
            placeholder: 'E.g. The hero',
            name: 'archetype'
          },
          { element: 'TextField', label: 'Gender', placeholder: 'E.g. Female', name: 'gender' },
          { element: 'TextField', label: 'Age', placeholder: 'E.g. 20s', name: 'age' },
          {
            element: 'TextField',
            label: 'Accent',
            placeholder: 'E.g. Received Pronunciation',
            name: 'accent'
          },
          {
            element: 'TextField',
            label: 'Style',
            placeholder: 'E.g. Perky/Dark, Moody, Detached, Slightly mad',
            name: 'style'
          },
          {
            element: 'TextArea',
            label: 'Backstory',
            name: 'backstory'
          },
          {
            element: 'TextArea',
            label: 'Personality',
            name: 'personality'
          }
        ]}
      />

      <Route
        path="/create-character/picture"
        render={() => (
          <PictureDialog
            title="Character picture"
            url={picture}
            onUpdate={(url: string) => {
              setPicture(url)
              return true
            }}
            onRemove={() => {
              setPicture('')
              return true
            }}
            onClose={() => goBack('/create-character')}
          />
        )}
      />
    </Page>
  )
}

export default CreateCharacter
