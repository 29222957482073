import { h } from 'preact'
import { BasicDialog, Checkbox, SpacerVertical } from '@sodra/bongo-ui'
import { Block } from 'jsxstyle/preact'

import { deleteLines } from '../../actions'
import { useStore } from '../../store'
import { useState } from 'preact/hooks'
import { confirm, pluralize } from 'lib'

type Props = {
  onClose: () => void
  onSuccess: () => void
  lineIds?: string[]
}

export const DeleteLines = ({ onSuccess, onClose, lineIds = [] }: Props) => {
  const { isDeletingLines } = useStore('isDeletingLines')

  const [deletePermanently, setDeletePermanently] = useState(false)

  if (lineIds.length === 0) {
    onClose()
  }

  const handleSubmit = async () => {
    if (
      !deletePermanently ||
      (await confirm({
        title: 'Are you sure?',
        message: (
          <>
            <Block>
              Do you really want to delete {lineIds.length} {pluralize('line', lineIds.length)}{' '}
              permanently?
            </Block>
            <SpacerVertical />
            <Block>
              All takes and other data connected to the deleted lines will also be deleted and can
              not be restored.
            </Block>
          </>
        ),
        confirmText: `Delete lines`
      }))
    ) {
      if (await deleteLines(lineIds, { deletePermanently })) {
        onSuccess()
        onClose()
      }
    }
  }

  return (
    <BasicDialog
      disableAutofocus
      primaryActionText="Delete"
      primaryActionLoading={isDeletingLines}
      onPrimaryActionClick={handleSubmit}
      action1Text="Cancel"
      onAction1Click={onClose}
      title="Delete lines"
      onClose={onClose}
    >
      <Block>
        Delete {lineIds.length} line{lineIds.length !== 1 ? 's' : ''}?
      </Block>
      <SpacerVertical />
      <Checkbox
        label="Delete permanently"
        checked={deletePermanently}
        onChange={setDeletePermanently}
      />
    </BasicDialog>
  )
}

export default DeleteLines
