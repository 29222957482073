import { store } from '../store'
import { post, del, patch } from '../api'
import { getFileHash } from '../get-file-hash'
import { Take } from '../types'
import { bufferToWave } from 'lib'

export const generateAITakes = async (params: any) => {
  try {
    store.setState({ isGeneratingAITakes: true })
    await post('/generate-ai-takes', params)
    store.setState({ isGeneratingAITakes: false })
    return true
  } catch (error) {
    store.setState({ error: error as Error, isGeneratingAITakes: false })
  }
}

export const regenerateAITake = async (take: Take) => {
  try {
    store.setState({ isRegeneratingAITake: true })
    await patch(`/regenerate-ai-take`, { takeId: take.id })
    store.setState({ isRegeneratingAITake: false })
    return true
  } catch (error) {
    store.setState({ isRegeneratingAITake: false })
    store.setState({ error: error as Error })
  }
}

export const deleteTake = async (takeId: string) => {
  try {
    await del(`/takes/${takeId}`)
    return true
  } catch (error) {
    store.setState({ error: error as Error })
  }
}

export const deleteTakesById = async (takeIds: string[]) => {
  try {
    store.setState({ isDeletingTakes: true })
    const lineId = store.getState().line!.id
    const { data: line } = await del(`/lines/${lineId}/takes`, { takeIds })
    store.setState({ line, isDeletingTakes: false })
    return true
  } catch (error) {
    store.setState({ error: error as Error, isDeletingTakes: false })
  }
}

export type VoiceType = 'voice' | 'user' | 'ai' | 'all'
export type DeleteTakesFilter = {
  voiceType?: VoiceType[]
  language?: string
}
export type DeleteTakesResult = {
  total: number
  voiceType?: {
    [vt in VoiceType]: number
  }
}
export const deleteTakesByFilter = async (
  lineIds: string[],
  deleteTakesFilter: DeleteTakesFilter,
  dryRun: boolean = false
): Promise<DeleteTakesResult | undefined> => {
  try {
    store.setState({ isDeletingTakes: true })
    const { data: result } = await del(`/lines/takes`, { lineIds, dryRun, ...deleteTakesFilter })
    store.setState({ isDeletingTakes: false })
    return result as DeleteTakesResult
  } catch (error) {
    store.setState({ error: error as Error, isDeletingTakes: false })
  }
}

export type RestoreTakesFilter = DeleteTakesFilter
export type RestoreTakesResult = DeleteTakesResult
export const restoreTakesByFilter = async (
  lineIds: string[],
  restoreTakesFilter: RestoreTakesFilter,
  dryRun: boolean = false
) => {
  try {
    store.setState({ isRestoringTakes: true })
    const { data: result } = await post(`/lines/restore-takes`, {
      lineIds,
      dryRun,
      ...restoreTakesFilter
    })
    store.setState({ isRestoringTakes: false })
    return result as RestoreTakesResult
  } catch (error) {
    store.setState({ error: error as Error, isRestoringTakes: false })
  }
}

export const uploadTake = async (file: File, params = {}) => {
  store.setState({ isUploadingTake: true })
  try {
    const lineId = store.getState().line!.id
    const line = await uploadLineTake(lineId, file, params)
    store.setState({ line, isUploadingTake: false })
    return true
  } catch (error) {
    store.setState({ error: error as Error, isUploadingTake: false })
  }
}

export const updateTake = async (takeId: string, params: any = {}) => {
  store.setState({ isUpdatingTake: true })
  try {
    await patch(`/takes/${takeId}`, params)
    store.setState({ isUpdatingTake: false })
    return true
  } catch (error) {
    store.setState({ error: error as Error, isUpdatingTake: false })
    return false
  }
}

export const uploadTakes = async (files: any[]) => {
  store.setState({ isUploadingTakes: true })
  try {
    for (let { lineId, file, ...params } of files) {
      await uploadLineTake(lineId, file, params)
    }
    store.setState({ isUploadingTakes: false })
    return true
  } catch (error) {
    store.setState({ error: error as Error, isUploadingTakes: false })
  }
}

const uploadLineTake = async (lineId: string, file: File, params: any = {}) => {
  const ext = file.name.lastIndexOf('.') >= 0 ? file.name.slice(file.name.lastIndexOf('.') + 1) : ''

  const {
    data: { signedUrl, uri }
  } = await post('/sign-url', {
    prefix: 'audio',
    contentType: file.type,
    ext
  })

  await fetch(signedUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type
    }
  })

  if (!params.hash) {
    params.hash = getFileHash(file)
  }

  params.uri = uri

  return await createLineTake(lineId, params)
}

export const createLineTake = async (lineId: string, take: any) => {
  const { data: line } = await post(`/lines/${lineId}/takes`, take)
  return line
}

export const createTake = async (params: any) => {
  try {
    store.setState({ isUpdatingLine: true })
    const lineId = store.getState().line?.id
    const { data: line } = await post(`/lines/${lineId}/takes`, params)
    store.setState({ line, isUpdatingLine: false })
    return line
  } catch (error: any) {
    store.setState({ error, isUpdatingLine: false })
  }
}

type Options = {
  sampleSize?: 8 | 16 | 24 | 32
}

export const uploadAudioBuffer = async (audioBuffer: AudioBuffer, options?: Options) => {
  try {
    const waveBlob = bufferToWave(audioBuffer, options)
    const {
      data: { signedUrl, uri }
    } = await post('/sign-url', {
      prefix: 'voice-take',
      contentType: 'audio/wav',
      ext: 'wav'
    })

    await fetch(signedUrl, {
      method: 'PUT',
      body: waveBlob,
      headers: {
        'Content-Type': 'audio/wav'
      }
    })

    return uri
  } catch (error) {
    store.setState({ error: error as Error })
    return Promise.reject(error)
  }
}
