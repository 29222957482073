import { h, JSX } from 'preact'
import { Block, Row } from 'jsxstyle/preact'
import { Avatar as BongoAvatar, IconButton, PhotoCameraIcon } from '@sodra/bongo-ui'
import { getCropperUrl } from '../get-cropper-url'

type Props = {
  size?: number
  src?: string
  actionIcon?: any
  actionSize?: 'small'
  actionName?: string
  onActionClick?: () => void
  [key: string]: any
}

export const Avatar = ({
  size = 40,
  src,
  actionIcon = undefined,
  actionSize = undefined,
  onActionClick = undefined,
  actionName = undefined,
  ...rest
}: Props) => {
  const cropperUrl = src ? getCropperUrl({ url: src, width: size, height: size }) : undefined
  if (actionIcon) {
    return (
      <Block position="relative" width={`${size}px`} height={`${size}px`}>
        <BongoAvatar
          src={cropperUrl}
          size={size}
          {...rest}
          background="var(--container-background)"
        />
        <IconButton
          small={actionSize === 'small'}
          outlined
          backgroundColor="var(--surface)"
          color="var(--on-surface)"
          icon={actionIcon}
          position="absolute"
          right="0"
          bottom="0"
          onClick={onActionClick}
          name={actionName}
        />
      </Block>
    )
  }
  return (
    <BongoAvatar src={cropperUrl} size={size} {...rest} background="var(--container-background)" />
  )
}

export default Avatar
