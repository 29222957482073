import { formatDate, formatTime } from './format-scheduled'

const sameDay = (d1: Date, d2: Date, timeZone: string) => {
  const d1Str = formatDate(d1, timeZone)
  const d2Str = formatDate(d2, timeZone)
  return d1Str === d2Str
}

type SessionStartEndDateTime = {
  timeZone: string
  startDate: string
  startTime: string
  endDate?: string
  endTime?: string
}

export const getSessionStartEndDateTime = (
  start: Date,
  duration: number,
  timeZone: string
): SessionStartEndDateTime => {
  const end = new Date(start)
  end.setTime(end.getTime() + duration * 3600000)

  let endDate
  if (duration > 0 && !sameDay(start, end, timeZone)) {
    endDate = formatDate(end, timeZone)
  }

  return {
    timeZone: timeZone,
    startDate: formatDate(start, timeZone),
    startTime: formatTime(start, timeZone),
    endTime: duration > 0 ? formatTime(end, timeZone) : undefined,
    endDate
  }
}
