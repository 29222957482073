import autobahn from 'autobahn-browser'
import type { Connection, Session } from 'autobahn'
import { ak } from './waapi'
import { ProjectInfo, WwiseInfo } from './wwiseResponseTypes'
import { hierarchyFromPath } from './hierarchyFromPath'
import merge from 'lodash/merge'

const DEFAULT_PORT = 8080
const MAX_RECONNECT_ATTEMPTS = 30
const RECONNECT_INTERVAL = 10000 // 10 seconds
const DEFAULT_IMPORT_LANGUAGE = 'SFX'
const DEFAULT_IMPORT_LOCATION = 'Actor-Mixer Hierarchy'
const DEFAULT_WORK_UNIT = 'Default Work Unit'
const IMPORT_OPERATION = 'useExisting'

type MessageHandler = (message: { type: 'connection' | 'import'; message: string }) => void
type ErrorHandler = (error: { type: 'connection' | 'import'; message: string }) => void
type OpenHandler = () => void
type CloseHandler = () => void
type ProgressHandler = (progress: number) => void
type LogHandler = (logRow: LogRow | null) => void

async function getBase64FromUrl(url: string) {
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(`Failed to fetch ${url} with status ${response.status}`)
  }
  const blob = await response.blob()
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (typeof reader.result !== 'string') {
        reject(new Error('reader.result is not a string'))
        return
      }
      resolve(reader.result.split(',')[1])
    }
    reader.readAsDataURL(blob)
  })
}

export type LogRow = {
  message: string
  type: 'error' | 'warning' | 'regular' | 'success'
  verb?:
    | 'work unit created'
    | 'added sound'
    | 'updated sound'
    | 'moved sound'
    | 'skipped sound'
    | 'added event'
    | 'updated event'
    | 'skipped event'
    | 'moved event'
    | 'failed'
    | 'done'
}

export type ImportSound = {
  name: string
  url: string
  note?: string
  meta?: any
  metaIdKey?: string
  folderStructure: {
    path?: { name: string; id: string }[]
    workUnit?: string
  }
}

export type ImportError = { message: string; sound: ImportSound[]; error?: any }

type WwiseError = {
  error: string
}

function isWwiseError(error: unknown): error is WwiseError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'error' in error &&
    typeof (error as Record<string, unknown>).error === 'string'
  )
}

const getWwiseError = (error: unknown): WwiseError | undefined => {
  if (isWwiseError(error)) {
    return error
  }
}

type PathOptions = {
  includeImportLocation?: boolean
  includeWorkUnit?: boolean
  includeName?: boolean
}
type PathPresets = 'full' | 'regular' | 'slim'
const pathPresets: Record<PathPresets, PathOptions> = {
  full: {
    includeImportLocation: true,
    includeWorkUnit: true,
    includeName: true
  },
  regular: {
    includeImportLocation: true,
    includeWorkUnit: true,
    includeName: false
  },
  slim: {
    includeImportLocation: false,
    includeWorkUnit: true,
    includeName: false
  }
}
function getSoundPath(sound: ImportSound, options?: PathOptions | PathPresets) {
  const defaultPreset: PathPresets = 'slim'

  if (typeof options === 'string') {
    options = pathPresets[options]
  } else {
    options = { ...pathPresets[defaultPreset], ...options }
  }

  let parts: string[] = []

  if (options.includeImportLocation) {
    parts.push(`\\${DEFAULT_IMPORT_LOCATION}`)
  }

  if (options.includeWorkUnit) {
    parts.push(`\\${sound.folderStructure.workUnit || DEFAULT_WORK_UNIT}`)
  }

  parts.push(
    `${sound.folderStructure.path?.reduce((acc, curr) => acc + '\\' + curr.name, '') || ''}`
  )

  if (options.includeName) {
    parts.push(`\\${sound.name}`)
  }

  return parts.join('')
}

export class WwiseConnection {
  private connection: Connection | null = null
  private session: Session | null = null
  private debug = false

  private messageHandler: MessageHandler = () => {}
  private errorHandler: ErrorHandler = () => {}
  private openHandler: OpenHandler = () => {}
  private closeHandler: CloseHandler = () => {}
  private progressHandler: ProgressHandler = () => {}
  private logHandler: LogHandler = () => {}

  public port = DEFAULT_PORT
  public wwiseInfo: WwiseInfo | null = null
  public projectInfo: ProjectInfo | null = null

  constructor(options: {
    port?: number
    shouldConnect?: boolean // Defaults to true
    debug?: boolean
  }) {
    this.port = options.port ?? this.port
    this.debug = options.debug ?? this.debug

    if (options.shouldConnect ?? options.shouldConnect === undefined) {
      this.initializeConnection()
    }
  }

  private log(...args: any[]) {
    if (this.debug) console.log(...args)
  }

  private initializeConnection = () => {
    this.log('Initializing connection', this.port)

    const connection = new autobahn.Connection({
      url: `ws://localhost:${this.port}/waapi`,
      realm: 'realm1',
      protocols: ['wamp.2.json']
    }) as Connection
    this.connection = connection

    connection.onclose = this.connectionOnClose
    connection.onopen = this.connectionOnOpen
    connection.open()
  }

  private connectionOnClose = (reason: string, details: string) => {
    this.log('Closed connection', reason, details)
    this.session = null
    this.connection = null

    this.closeHandler()

    if (reason === 'unreachable') {
      this.errorHandler({
        type: 'connection',
        message: `Could not connect to Wwise on port ${this.port}. Make sure Wwise is running and that the Wwise Authoring API is enabled.`
      })
    } else {
      this.messageHandler({ type: 'connection', message: 'Wwise connection closed' })
    }

    return true
  }

  private connectionOnOpen = async (session: Session) => {
    this.log('Opened connection')
    this.session = session

    try {
      const { kwargs: wwiseInfo } = await this.session.call<{ kwargs: WwiseInfo }>(
        ak.wwise.core.getInfo,
        [],
        {}
      )
      this.wwiseInfo = wwiseInfo
      this.log('Got Wwise info', wwiseInfo)

      const { kwargs: projectInfo } = await this.session.call<{ kwargs: ProjectInfo }>(
        ak.wwise.core.getProjectInfo,
        [],
        {}
      )
      this.projectInfo = projectInfo
      this.log('Got project info', projectInfo)

      this.openHandler()
    } catch (error: unknown) {
      const wwiseError = getWwiseError(error)
      if (wwiseError && wwiseError.error === 'ak.wwise.locked') {
        this.errorHandler({
          type: 'connection',
          message: `Wwise is locked. Please close User Preferences in Wwise and try again.`
        })
      } else {
        this.errorHandler({
          type: 'connection',
          message: `Could not connect to Wwise on port ${this.port}. Make sure Wwise is running and that the Wwise Authoring API is enabled.`
        })
      }
    }
  }

  public setMessageHandler = (handler: MessageHandler) => {
    this.messageHandler = handler
  }
  public setErrorHandler = (handler: ErrorHandler) => {
    this.errorHandler = handler
  }
  public setOpenHandler = (handler: OpenHandler) => {
    this.openHandler = handler
  }
  public setCloseHandler = (handler: CloseHandler) => {
    this.closeHandler = handler
  }
  public setProgressHandler = (handler: ProgressHandler) => {
    this.progressHandler = handler
  }
  public setLogHandler = (handler: LogHandler) => {
    this.logHandler = handler
  }

  public get isOpen() {
    return this.connection?.isOpen ?? false
  }

  public close = () => {
    this.log('Closing connection', this.port)
    this.connection?.close()
    this.connection = null
  }

  public reconnect = () => {
    this.log('Reconnecting', this.port)

    this.connection?.close()
    this.initializeConnection()
  }

  /**
   *
   */
  private wwiseObjectExists = async (waqlQuery: string) => {
    let objectExists = false
    try {
      type QueryResult = {
        kwargs: { return: { id: string; name: string }[] }
      }

      /**
       * Query Wwise for the object
       */
      this.log(waqlQuery)
      const q = await this.session?.call<QueryResult>(
        'ak.wwise.core.object.get',
        [],
        {
          waql: waqlQuery
        },
        {
          // @ts-ignore (autobahn typings is not aware of available Wwise options)
          return: ['id', 'name']
        }
      )

      if (q) {
        objectExists = true
      }
    } catch (e) {
      /**
       * An error will be thrown if the object is not found.
       */
      this.log(e)
    }
    return objectExists
  }

  /**
   * Compares an array of sounds to Wwise content and returns an object containing the result of the comparison.
   * This is used as a 'dry run' before importing sounds to Wwise. Shows the user what the effect of the export will be.
   /**
   * Compare an array of sounds to Wwise content.
   * @param sounds An array of sounds to compare to Wwise content
   * @param options An object containing options for the comparison
   * @param options.metaCompare A function for comparing ImportSound metadata with existing metadata in Wwise object (if any). If it returns true the sound will be skipped.
   * @param options.createEvents If true, events will be created for the sounds.
   * @param options.moveExisting If true, existing Wwise objects will be moved to the correct location, otherwise new objects will be created.
   * @param options.force If true, checks to see if object exists in Wwise will be skipped and all sounds will be imported.
   * @returns
   */
  public compareSoundsToWwiseContent = async (
    sounds: ImportSound[],
    options: {
      metaCompare: (a: any, b: any) => boolean
      createEvents?: boolean
      moveExisting?: boolean
      force?: boolean
    } = {
      metaCompare: () => false,
      createEvents: false,
      moveExisting: false,
      force: false
    }
  ) => {
    /**
     * A task is an object describing what action should be taken for a sound.
     * The list of tasks is used to show the user what the effect of the export will be.
     * The list of tasks then also used to perform the actual export.
     */
    type Task = (
      | {
          status: 'new' | 'skip'
        }
      | {
          status: 'update'
          updateActions: ('content' | 'move')[]
        }
    ) &
      (
        | {
            type: 'sound' | 'event'
            sound: ImportSound
          }
        | {
            type: 'work-unit'
            workUnit: string
          }
      ) & {
        message?: {
          type: 'warning'
          text: string
        }
      }
    const tasks: Task[] = []

    const checkedWorkUnits = new Set<string>()
    let numSkip = 0
    let numUpdate = 0
    let numNew = 0
    for (const sound of sounds) {
      if (!sound.meta) {
        continue
      }

      if (sound.folderStructure.workUnit && !checkedWorkUnits.has(sound.folderStructure.workUnit)) {
        const waql = `\"\\${DEFAULT_IMPORT_LOCATION}\\${
          sound.folderStructure.workUnit || DEFAULT_WORK_UNIT
        }\"`
        if (!(await this.wwiseObjectExists(waql))) {
          tasks.push({
            type: 'work-unit',
            status: 'new',
            workUnit: sound.folderStructure.workUnit
          })
          checkedWorkUnits.add(sound.folderStructure.workUnit)
        }
      }

      if (options.force) {
        tasks.push({ type: 'sound', sound, status: 'new' })
        if (options.createEvents) {
          tasks.push({ type: 'event', sound, status: 'new' })
        }
        numNew += 1
        continue
      }

      try {
        type QueryResult = {
          kwargs: { return: { id: string; name: string; notes: string; path: string }[] }
        }

        /**
         * Query Wwise for the sound object
         */
        let waql = ''
        if (options.moveExisting && sound.meta && sound.metaIdKey) {
          waql = `from search \"${sound.meta[sound.metaIdKey]}\" where type =\"Sound\"`
        } else {
          waql = `\"${getSoundPath(sound, 'full')}\"`
        }

        this.log(waql)
        const q = await this.session?.call<QueryResult>(
          'ak.wwise.core.object.get',
          [],
          {
            waql
          },
          {
            // @ts-ignore (autobahn typings is not aware of available Wwise options)
            return: ['id', 'name', 'notes', 'path']
          }
        )

        if (!q || q.kwargs?.return?.length === 0) {
          throw new Error('Object not found')
        }

        this.log(q)

        let nSoundMatches = 0
        for (const match of q.kwargs.return) {
          let message: Task['message'] | undefined
          nSoundMatches++

          if (nSoundMatches > 1) {
            message = {
              type: 'warning',
              text: `Multiple matches found for ${sound.name}.`
            }
          }

          /**
           * Compare the metadata of the sound object with the metadata of the sound we want to import.
           */
          const updateActions: ('content' | 'move')[] = []

          const meta = JSON.parse(match.notes)
          const metaDiff = !options.metaCompare(sound.meta, meta)
          if (metaDiff) {
            updateActions.push('content')
          }

          if (options.moveExisting) {
            const fullPath = getSoundPath(sound, 'full')
            const pathDiff = fullPath !== match.path
            if (pathDiff) {
              updateActions.push('move')
            }
          }

          if (updateActions.length > 0) {
            tasks.push({ type: 'sound', sound, status: 'update', updateActions, message })
            numUpdate += 1
          } else {
            tasks.push({ type: 'sound', sound, status: 'skip', message })
            numSkip += 1
          }
        }
      } catch (e) {
        /**
         * An error will be thrown if the object is not found. Either by Wwise if we query for an absolute path, or by us if the result is empty.
         * This is expected to happen for all new sounds and we can simply continue to the next one.
         */
        this.log(e)
        tasks.push({ type: 'sound', sound, status: 'new' })
        numNew += 1
      }

      if (options.createEvents) {
        try {
          type QueryResult = {
            kwargs: { return: { id: string; name: string; notes: string; path: string }[] }
          }

          /**
           * Query Wwise for the event object
           */
          let waql = ''
          if (sound.meta && sound.metaIdKey) {
            let where: string[] = []
            if (!options.moveExisting) {
              where.push(`path: \"\\Events${getSoundPath(sound, 'slim')}\"`)
            }
            where.push(`notes : \"*${sound.meta[sound.metaIdKey]}*\"`)

            waql = `from type Event select this where ${where.join(' and ')}`
          } else {
            waql = `\"\\Events${getSoundPath(sound, 'slim')}\\Play_${sound.name}_event\"`
          }

          const q = await this.session?.call<QueryResult>(
            'ak.wwise.core.object.get',
            [],
            {
              waql
            },
            {
              // @ts-ignore (autobahn typings is not aware of available Wwise options)
              return: ['id', 'name', 'notes', 'path']
            }
          )

          if (!q || q.kwargs?.return?.length === 0) {
            throw new Error('Object not found')
          }

          this.log(q)

          // Fixa
          let nEventMatches = 0
          for (const match of q.kwargs.return) {
            let message: Task['message'] | undefined
            nEventMatches++

            if (nEventMatches > 1) {
              message = {
                type: 'warning',
                text: `Multiple matches found for Play_${sound.name}_event. It will not be possible to decide which to move`
              }
            }
            /**
             * Compare the metadata of the event object with the metadata of the event we want to create.
             */
            const updateActions: 'move'[] = []

            if (options.moveExisting) {
              const fullPath = `\\Events${getSoundPath(sound, 'slim')}\\Play_${sound.name}_event`
              const pathDiff = fullPath !== match.path
              if (pathDiff) {
                updateActions.push('move')
              }

              console.log('pathDiff', pathDiff, fullPath, match.path)
            }

            if (updateActions.length > 0) {
              tasks.push({ type: 'event', sound, status: 'update', updateActions, message })
            } else {
              tasks.push({ type: 'event', sound, status: 'skip', message })
            }
          }
        } catch (e) {
          /**
           * An error will be thrown if the object is not found. Either by Wwise if we query for an absolute path, or by us if the result is empty.
           * This is expected to happen for all new sounds and we can simply continue to the next one.
           */
          this.log(e)
          tasks.push({ type: 'event', sound, status: 'new' })
        }
      }
    }
    return {
      tasks,
      numNew,
      numUpdate,
      numSkip
    }
  }

  private getWwiseUIDByMeta = async (
    meta: any,
    metaIdKey: string,
    type?: 'Event' | 'Sound' | 'Folder',
    category?: string
  ) => {
    const whereConditions = []

    if (type) {
      whereConditions.push(`type =\"${type}\"`)
    }
    if (category) {
      whereConditions.push(`category =\"${category}\"`)
    }

    const whereString = whereConditions.length > 0 ? `where ${whereConditions.join(' and ')}` : ''

    const waql = `from search \"${meta[metaIdKey]}\" ${whereString}`

    type QueryResult = {
      kwargs: { return: { id: string }[] }
    }

    const q = await this.session?.call<QueryResult>(
      'ak.wwise.core.object.get',
      [],
      {
        waql
      },
      {
        // @ts-ignore (autobahn typings is not aware of available Wwise options)
        return: ['id']
      }
    )

    if (!q || q.kwargs?.return?.length === 0) {
      throw new Error('Object not found')
    }

    return q.kwargs.return[0].id
  }

  private moveWwiseObject = async (
    wwiseUID: string,
    workUnit: string,
    targetParentPath: { name: string; id: string }[],
    importLocation: string,
    name: string,
    category?: string
  ) => {
    const fullPath = `\\${importLocation}\\${workUnit}\\`

    /**
     * Loop over path parts from the root and update or create folders as needed.
     */
    let checked: { name: string; id: string }[] = []
    for (const part of targetParentPath) {
      let wwiseUID: string | null
      try {
        wwiseUID = await this.getWwiseUIDByMeta(part, 'id', 'Folder', category)
      } catch (e) {
        console.error('Failed to get Wwise UID for folder', e)
        wwiseUID = null
      }

      /**
       * If the folder exists, move it to the correct location.
       */
      if (wwiseUID) {
        const targetParent = fullPath + checked.map((p) => p.name).join('\\')
        // if (targetParent.length > fullPath.length) {
        await this.session?.call(ak.wwise.core.object.move, [], {
          object: wwiseUID,
          parent: targetParent
        })
        // }

        await this.session?.call(ak.wwise.core.object.setName, [], {
          object: wwiseUID,
          value: part.name
        })
      } else {
        /**
         * If the folder does not exist, create it.
         */
        await this.session?.call(ak.wwise.core.object.create, [], {
          parent: fullPath + checked.map((p) => p.name).join('\\'),
          type: 'Folder',
          name: part.name,
          notes: JSON.stringify({
            ...part,
            meta: 'Created by speechless'
          })
        })
      }
      checked.push(part)
    }

    const targetParent = fullPath + checked.map((p) => p.name).join('\\')
    await this.session?.call(ak.wwise.core.object.move, [], {
      object: wwiseUID,
      parent: targetParent
    })

    await this.session?.call(ak.wwise.core.object.setName, [], {
      object: wwiseUID,
      value: name
    })
  }

  /**
   * Imports multiple sounds into Wwise in batches.
   * @param sounds An array of sounds to import.
   * @param options An optional object containing import options.
   * @param options.batchSize The size of each import batch. Defaults to 10.
   * @param options.importLanguage The target Wwise language of the imported sounds. Fallbacks to SFX if not provided.
   * @param options.createEvents Specifies whether to create events for the imported sounds.
   * @param options.metaCompare A function for comparing ImportSound metadata with existing metadata in Wwise object (if any). If it returns true the sound will be skipped.
   * @param options.moveExisting If true move existing objects in Wwise, if any are found, otherwise new objects will be created.
   * @param options.force Specifies whether to force the import, checks to see if object exists in Wwise will be skipped and all sounds will be imported.
   * @returns An object containing any import errors.
   */
  public batchImportSounds = async (
    sounds: ImportSound[],
    options?: {
      batchSize?: number
      importLanguage?: string
      createEvents?: boolean
      metaCompare?: (a: any, b: any) => boolean
      moveExisting?: boolean
      force?: boolean
    }
  ) => {
    this.log('Starting multiple sound import', sounds)
    this.progressHandler(0)
    this.logHandler(null)
    /**
     * Break the sounds into batches.
     */
    const batches: ImportSound[][] = []
    const batchSize = options?.batchSize ?? 10
    for (let i = 0; i < sounds.length; i += batchSize) {
      batches.push(sounds.slice(i, i + batchSize))
    }

    /**
     * Import each batch.
     */
    for (let [i, batch] of batches.entries()) {
      /**
       * Compare the batch to Wwise content.
       */
      const compareContentResult = await this.compareSoundsToWwiseContent(batch, {
        metaCompare: options?.metaCompare ?? (() => false),
        moveExisting: options?.moveExisting,
        force: options?.force,
        createEvents: options?.createEvents
      })

      for (const task of compareContentResult.tasks) {
        if (task.type === 'work-unit') {
          const { workUnit } = task

          const waql = `\"\\${DEFAULT_IMPORT_LOCATION}\\${workUnit || DEFAULT_WORK_UNIT}\"`
          const workUnitExists = await this.wwiseObjectExists(waql)

          if (!workUnitExists) {
            /**
             * Create the work unit
             */
            await this.session?.call(ak.wwise.core.object.create, [], {
              parent: '\\' + DEFAULT_IMPORT_LOCATION,
              type: 'WorkUnit',
              name: workUnit,
              notes: JSON.stringify({ meta: 'Created by speechless' }),
              onNameConflict: 'merge'
            })

            this.logHandler({
              message: workUnit,
              type: 'regular',
              verb: 'work unit created'
            })
          }
        } else if (task.type === 'sound') {
          const { sound, status } = task

          if (status === 'skip') {
            this.logHandler({
              type: 'regular',
              verb: 'skipped sound',
              message: getSoundPath(sound, 'full').replaceAll('\\', ' \\ ')
            })
            continue
          }

          if (status === 'update' && task.updateActions.includes('move')) {
            if (sound.meta && sound.metaIdKey) {
              /**
               * Move the wwise object
               */
              try {
                const wwiseUID = await this.getWwiseUIDByMeta(sound.meta, sound.metaIdKey, 'Sound')
                await this.moveWwiseObject(
                  wwiseUID,
                  sound.folderStructure.workUnit ?? DEFAULT_WORK_UNIT,
                  sound.folderStructure.path ?? [],
                  DEFAULT_IMPORT_LOCATION,
                  sound.name,
                  'Actor-Mixer Hierarchy'
                )

                this.logHandler({
                  type: 'regular',
                  verb: 'moved sound',
                  message: getSoundPath(sound, 'full').replaceAll('\\', ' \\ ')
                })
              } catch (e) {
                this.log('Failed to move sound', sound.name, e)
                this.logHandler({
                  type: 'error',
                  verb: 'failed',
                  message: `Failed to move sound ${sound.name}`
                })
              }
            }
          }

          if (status === 'new' || (status === 'update' && task.updateActions.includes('content'))) {
            /**
             * Fetch the base64 audio
             */
            let base64Audio: string
            try {
              base64Audio = await getBase64FromUrl(sound.url)
            } catch (e) {
              this.log('Failed to fetch sound', sound.url, e)
              this.logHandler({
                verb: 'failed',
                type: 'error',
                message: `Failed to fetch ${sound.name}: ${sound.url}`
              })
              continue
            }

            if (status === 'update' && task.updateActions.includes('content')) {
              if (sound.meta && sound.metaIdKey) {
                try {
                  type QueryResult = {
                    kwargs: { return: { notes: string }[] }
                  }
                  /**
                   * Query Wwise for the sound object
                   */
                  let waql = `from search \"${sound.meta[sound.metaIdKey]}\" where type =\"Sound\"`

                  const q = await this.session?.call<QueryResult>(
                    'ak.wwise.core.object.get',
                    [],
                    {
                      waql
                    },
                    {
                      // @ts-ignore (autobahn typings is not aware of available Wwise options)
                      return: ['id', 'name', 'notes', 'path']
                    }
                  )

                  if (!q || q.kwargs?.return?.length === 0) {
                    throw new Error('Object not found')
                  }

                  const oldMeta = JSON.parse(q.kwargs.return[0].notes)
                  sound.meta = merge(oldMeta, sound.meta)
                } catch (e) {
                  this.log('Failed to merge meta', sound.name, e)
                  this.logHandler({
                    type: 'error',
                    verb: 'failed',
                    message: `Failed to merge metadata ${sound.name}`
                  })
                }
              }
            }

            /**
             * Import the sound
             */
            let failed = false
            try {
              /**
               * Här uppstår också problem (evt. löst)
               * Folders skapas automatiskt, utan att vi lägger meta på dem
               * fixas med att hierarchyFromPath tar skapar meta
               */

              const workUnitPath = `\\${DEFAULT_IMPORT_LOCATION}\\${
                sound.folderStructure.workUnit ?? DEFAULT_WORK_UNIT
              }`

              await this.session?.call(ak.wwise.core.object.set, [], {
                onNameConflict: 'merge',
                objects: [
                  {
                    object: workUnitPath,
                    children: [hierarchyFromPath(sound.folderStructure.path ?? [])].filter(Boolean)
                  }
                ]
              })

              await this.session?.call(ak.wwise.core.audio.import, [], {
                importOperation: IMPORT_OPERATION,
                default: {
                  importLanguage: options?.importLanguage ?? DEFAULT_IMPORT_LANGUAGE
                },
                imports: [
                  {
                    objectPath: getSoundPath(sound, 'regular') + `\\<Sound>${sound.name}`,
                    audioFileBase64: `\\${sound.name}.wav|` + base64Audio,
                    originalsSubFolder: 'speechless\\' + getSoundPath(sound, 'slim')
                  }
                ]
              })
            } catch (e: any) {
              this.log('Error importing sound', e)

              const type = e.kwargs?.details?.log[0]?.severity === 'Warning' ? 'warning' : 'error'

              if (type === 'error') {
                failed = true
              }
            }
            try {
              await this.session?.call(ak.wwise.core.audio.import, [], {
                importOperation: IMPORT_OPERATION,
                imports: [
                  {
                    objectPath: getSoundPath(sound, 'regular') + `\\<Sound>${sound.name}`,
                    notes: sound.meta ? JSON.stringify(sound.meta) : undefined
                  }
                ]
              })
            } catch (e: any) {
              this.log('Error importing sound', e)

              const type = e.kwargs?.details?.log[0]?.severity === 'Warning' ? 'warning' : 'error'

              if (type === 'error') {
                failed = true
              }
            }

            if (failed) {
              this.logHandler({
                type: 'error',
                verb: 'failed',
                message: `Failed to import sound ${sound.name}`
              })
            } else {
              this.logHandler({
                type: 'success',
                verb: status === 'new' ? 'added sound' : 'updated sound',
                message: getSoundPath(sound, 'full').replaceAll('\\', ' \\ ')
              })
            }
          }
        }
        if (task.type === 'event') {
          const { sound, status } = task

          if (status === 'skip') {
            this.logHandler({
              type: 'regular',
              verb: 'skipped event',
              message: `\\Events${getSoundPath(sound, 'slim')}\\Play_${
                sound.name
              }_event`.replaceAll('\\', ' \\ ')
            })
            continue
          }

          if (status === 'update' && task.updateActions.includes('move')) {
            if (sound.meta && sound.metaIdKey) {
              /**
               * Move the wwise object
               */
              try {
                const wwiseUID = await this.getWwiseUIDByMeta(sound.meta, sound.metaIdKey, 'Event')
                await this.moveWwiseObject(
                  wwiseUID,
                  sound.folderStructure.workUnit ?? DEFAULT_WORK_UNIT,
                  sound.folderStructure.path ?? [],
                  'Events',
                  `Play_${sound.name}_event`,
                  'Events'
                )

                this.logHandler({
                  type: 'regular',
                  verb: 'moved event',
                  message: `\\Events${getSoundPath(sound, 'slim')}\\Play_${
                    sound.name
                  }_event`.replaceAll('\\', ' \\ ')
                })
              } catch (e) {
                this.log('Failed to move event', sound.name, e)
                this.logHandler({
                  type: 'error',
                  verb: 'failed',
                  message: `Failed to move event Play_${sound.name}_event`
                })
              }
            }
          }

          if (status === 'new') {
            /**
             * Check that the sound exists
             */
            const soundExists = await this.wwiseObjectExists(`\"${getSoundPath(sound, 'full')}\"`)

            if (soundExists) {
              /**
               * Create the event
               */
              try {
                const targetPath = getSoundPath(sound, 'full')

                const event = {
                  type: 'Event',
                  name: `Play_${sound.name}_event`,
                  notes: sound.meta ? JSON.stringify(sound.meta) : undefined,
                  children: [
                    {
                      type: 'Action',
                      name: '',
                      '@ActionType': 1,
                      '@Target': targetPath
                    }
                  ]
                }

                await this.session?.call(ak.wwise.core.object.set, [], {
                  onNameConflict: 'merge',
                  objects: [
                    {
                      object: `\\Events`,
                      children: [
                        {
                          type: 'WorkUnit',
                          name: sound.folderStructure.workUnit || DEFAULT_WORK_UNIT,
                          children: [hierarchyFromPath(sound.folderStructure.path ?? [], event)].filter(Boolean)
                        }
                      ]
                    }
                  ]
                })
                this.logHandler({
                  type: 'success',
                  verb: 'added event',
                  message: `\\Events${getSoundPath(sound, 'slim')}\\Play_${sound.name}_event`.replaceAll('\\', ' \\ ')
                })
              } catch (e: any) {
                this.log('Failed to create events', e)
                const message = e.kwargs?.details?.log[0]?.message?.split('-')[0]
                this.logHandler({
                  verb: 'failed',
                  type: 'error',
                  message: `Failed event creation: ${message ? message : ``}`
                })
              }
            } else {
              this.logHandler({
                type: 'error',
                verb: 'failed',
                message: `Failed to create event Play_${sound.name}_event, Sound object not found`
              })
            }
          }
        }

        if (task.message) {
          this.logHandler({
            message: task.message.text,
            type: task.message.type
          })
        }
      }

      this.log(`Imported batch #${i}, ${batch.length} sounds to Wwise with`)
      this.progressHandler((i + 1) / batches.length)
    }

    // this.messageHandler({ type: 'import', message: `Imported to Wwise` + (errors.length ? `, with ${errors.length} errors` : '') })
    this.progressHandler(1)
    this.logHandler({ type: 'success', message: 'Export done', verb: 'done' })
    return
  }

  public undo = async () => {
    await this.session?.call(ak.wwise.core.undo.undo, [], {})
  }
}
