import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import {
  AddIcon,
  Button,
  DeleteIcon,
  List,
  ListItem,
  PlusIcon,
  SpacerVertical
} from '@sodra/bongo-ui'
import { goBack, Route, routeTo, Switch } from '@sodra/prutt'

import { deleteLineAttribute, fetchLineAttributes, showSnackbar } from '../../../actions'

import Page from '../../Page'
import { useStore } from '../../../store'
import { CreateLineAttribute, formatType } from './CreateLineAttribute'
import { UpdateLineAttribute } from './UpdateLineAttribute'
import { Block } from 'jsxstyle/preact'
import { LineAttribute } from '../../../types'
import { confirm } from 'lib'

export const LineAttributes = () => {
  const { currentGame, lineAttributes } = useStore('currentGame', 'lineAttributes')

  useEffect(() => {
    fetchLineAttributes()
  }, [currentGame!.id])

  const onBack = () => goBack('/lines')

  const handleDeleteAttribute = async (attribute: LineAttribute) => {
    if (await confirm(`Delete line attribute ${attribute.name}?`)) {
      if (await deleteLineAttribute(attribute.id)) {
        showSnackbar(`Line attribute ${attribute.name} deleted`)
      }
    }
  }

  return (
    <Page
      title="Line attributes"
      onBack={onBack}
      actions={[
        {
          label: 'Add',
          icon: AddIcon,
          link: {
            href: '/lines/line-attributes/add',
            onRoute: routeTo
          }
        }
      ]}
    >
      <Block maxWidth="500px">
        <List>
          <ListItem text="Filename" secondaryText="Text" metaText="* Required" />
          <ListItem text="Line" secondaryText="Text" metaText="* Required" />
          <ListItem text="Acting notes" secondaryText="Text – Optional acting notes" />
        </List>
        <SpacerVertical />
        <Block color="var(--on-surface-light)" fontSize="14px">
          User defined attributes
        </Block>
        <SpacerVertical small />
        <List>
          {lineAttributes?.map((attribute) => {
            let secondaryText = formatType(attribute.type)
            if (attribute.description && attribute.description.trim() !== '') {
              secondaryText += ` – ${attribute.description}`
            }

            let metaText = attribute.required ? '* Required' : ''

            return (
              <ListItem
                text={attribute.name}
                secondaryText={secondaryText}
                metaText={metaText}
                actionIcon={DeleteIcon}
                onActionClick={() => handleDeleteAttribute(attribute)}
                onClick={() => routeTo(`/lines/line-attributes/${attribute.id}`)}
              />
            )
          })}
        </List>
        <Button
          icon={PlusIcon}
          link={{
            href: '/lines/line-attributes/add',
            onRoute: routeTo
          }}
        >
          Add
        </Button>
      </Block>
      <Switch>
        <Route path="/lines/line-attributes/add" component={CreateLineAttribute} />
        <Route path="/lines/line-attributes/:attributeId" component={UpdateLineAttribute} />
      </Switch>
    </Page>
  )
}
