import { Block } from 'jsxstyle/preact'
import { h } from 'preact'

export const HelpDeleteMissingLines = ({ fontSize }: { fontSize?: string }) => {
  return (
    <Block color="var(--on-surface-light)" fontSize={fontSize ?? ''}>
      If enabled, lines that exist in Speechless but are missing in the imported script will be
      deleted from Speechless.
      <br />
      <br />
      Lines deleted this way can be restored from the Lines section.
    </Block>
  )
}
