import { ArrowDropDownIcon, ErrorIcon } from '@sodra/bongo-ui'
import { Block, Row } from 'jsxstyle/preact'

type Props = {
  label: string
  visual: any
  value?: string
  onClick: () => void
  infoText?: string
  errorText?: string
  disabled?: boolean
}

export const FakeSelect = ({
  label,
  visual,
  value,
  onClick,
  infoText,
  errorText,
  disabled
}: Props) => {
  return (
    <>
      <Row
        alignItems="center"
        position="relative"
        gap="20px"
        height="54px"
        props={{
          onClick: !disabled ? onClick : undefined,
          tabindex: !disabled ? 0 : undefined
        }}
        background="var(--container-background)"
        padding="0 15px"
        borderRadius="3px"
        borderBottom="solid 3px transparent"
        // @ts-ignore
        focusBorderBottom="solid 3px var(--accent)"
        outline="none"
        opacity={disabled ? 0.3 : 1}
      >
        {visual}
        <Block flex="1">
          <Block
            color="var(--on-surface-light)"
            position={value ? 'absolute' : 'relative'}
            top={value ? '7px' : ''}
            fontSize={value ? '12px' : ''}
          >
            {label}
          </Block>
          <Block position="absolute" top="25px">
            {value}
          </Block>
        </Block>
        <ArrowDropDownIcon fill="var(--on-surface)" />
      </Row>
      {infoText && (
        <Block color="var(--on-surface-light)" fontSize="14px" padding="5px 15px">
          {infoText}
        </Block>
      )}
      {errorText && (
        <Row alignItems="center" gap="5px" color="var(--error)" fontSize="14px" padding="5px 15px">
          <ErrorIcon fill="var(--error)" size="20" /> {errorText}
        </Row>
      )}
    </>
  )
}
