import { Block } from 'jsxstyle'
import { useStore } from '../../store'
import { ActivityLog } from '../ActivityLog'

type Props = {
  lineId: string
}

export const Activity = ({ lineId }: Props) => {
  const { currentGame, line } = useStore('currentGame', 'line')
  return (
    <Block maxWidth="800px">
      <ActivityLog
        parameters={{
          gameId: currentGame!.id,
          lineId: line!.id
        }}
      />
    </Block>
  )
}
