import { Block } from 'jsxstyle/preact'
import { h } from 'preact'

export const HelpExternalLineId = ({ fontSize }: { fontSize?: string }) => {
  return (
    <Block color="var(--on-surface-light)" fontSize={fontSize ?? ''}>
      Enable external line IDs if all lines for this game will be created with unique IDs outside of
      Speechless.
      <br />
      <br />
      When importing lines into Speechless, your own line IDs will then be used to identify existing
      lines that need to be updated.
      <br />
      <br />
      You can also choose to use filenames as unique IDs instead of having separate external line
      IDs.
    </Block>
  )
}
