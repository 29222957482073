import { h } from 'preact'
import { Dialog, EventIcon, Link, List, ListItem, SpacerVertical } from '@sodra/bongo-ui'
import { goBack, routeTo } from '@sodra/prutt'
import { useEffect, useState } from 'preact/hooks'

import { Chat, Session } from '../../types'
import { get } from '../../api'
import { setError } from '../../actions'
import { Block } from 'jsxstyle/preact'
import { useStore } from '../../store'
import { formatScheduled } from 'lib'

type Props = {
  chat: Chat
}

export const AssignSession = ({ chat }: Props) => {
  const onClose = () => goBack(`/chats/${chat.id}`)

  const { currentGame } = useStore('currentGame')

  const [sessions, setSessions] = useState<Session[]>([])

  useEffect(() => {
    get(`/games/${currentGame?.id}/sessions`, {
      scheduledAfter: new Date(),
      voiceAssigned: false
    })
      .then(({ data: sessions }) => setSessions(sessions))
      .catch((err) => setError(err))
  }, [])

  if (sessions.length === 0) {
    return (
      <Dialog
        disableAutofocus
        title={`Book ${chat.voice.name}`}
        onClose={onClose}
        actions={[{ text: 'Cancel', onClick: onClose }]}
      >
        <Block color="var(--on-surface-light)">No available sessions</Block>
        <SpacerVertical />
        <Link
          onClick={() => routeTo(`/create-session?voiceId=${chat.voice.id}`, { replace: true })}
        >
          Book new session
        </Link>
      </Dialog>
    )
  }

  return (
    <Dialog
      disableAutofocus
      title={`Book ${chat.voice.name}`}
      onClose={onClose}
      actions={[{ text: 'Cancel', onClick: onClose }]}
    >
      Select a session below or{' '}
      <Link onClick={() => routeTo(`/create-session?voiceId=${chat.voice.id}`, { replace: true })}>
        book new session
      </Link>
      <SpacerVertical />
      <List>
        {sessions.map((session) => {
          return (
            <ListItem
              icon={EventIcon}
              text={`${formatScheduled(session.scheduled)} · ${session.duration} hour${
                session.duration !== 1 ? 's' : ''
              }`}
              link={{
                href: `/session/${session.id}?voiceId=${chat.voice.id}`,
                onRoute: (url: string) => routeTo(url, true)
              }}
            />
          )
        })}
      </List>
    </Dialog>
  )
}
